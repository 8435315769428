import * as yup from 'yup';

import {
    AmsDocument,
    DocumentUpload,
    ProfessionAddOnTrack,
    ProgramAddOnTrack,
    ProgramAddOnTrackCreate,
} from '../../../common/Types';
import {
    Card,
    CardBody,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import React, { useEffect, useState } from 'react';

import { APIUriAms } from '../../../services/HttpHelper';
import { Button } from '../../../components';
import Dropzone from 'react-dropzone';
import { ProgramStatus } from '../../../common/Data';
import { documentService } from '../../../services/DocumentService';
import moment from 'moment';
import { professionService } from '../../../services/ProfessionAdministration';
import { programService } from '../../../services/ProgramService';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { AmsPermissionClaimType, authService } from '../../../services/Auth';

const DropZoneContainer = styled.section`
    border: dashed 5px #cccccc;
    > div {
        height: 80px;
    }
`;

interface AddOnTracksProps {
    programId: number;
    professionId: number;
    institutionId: number;
}

const AddOnTracks = (props: AddOnTracksProps) => {
    const { programId, professionId, institutionId } = props;

    const [professionAddOnTracks, setProfessionAddOnTracks] = useState<ProfessionAddOnTrack[]>();
    const [programAddOnTracks, setProgramAddOnTracks] = useState<ProgramAddOnTrack[]>();
    const [reloadProgramAddOnTracks, setReloadProgramAddOnTracks] = useState<boolean>(true);

    const [addOnTrackEdit, setAddOnTrackEdit] = useState<ProgramAddOnTrack>();
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const toggleEditModal = () => setShowEditModal(!showEditModal);
    const [addOnTrackStatusEdit, setAddOnTrackStatusEdit] = useState<ProgramAddOnTrack>();
    const [showEditStatusModal, setShowEditStatusModal] = useState<boolean>(false);
    const toggleEditStatusModal = () => setShowEditStatusModal(!showEditStatusModal);

    const [newDocument, setNewDocument] = useState<DocumentUpload>();
    const [showNewDocumentModal, setShowNewDocumentModal] = useState<boolean>(false);

    const [removeDocument, setRemoveDocument] = useState<AmsDocument>();
    const [showRemoveDocumentModal, setShowRemoveDocumentModal] = useState<boolean>(false);

    const toggleShowNewDocumentModal = () => setShowNewDocumentModal(!showNewDocumentModal);
    const toggleShowRemoveDocumentModal = () => setShowRemoveDocumentModal(!showRemoveDocumentModal);

    const [showModal, setShowModal] = useState<boolean>(false);
    const toggleModal = () => setShowModal(!showModal);
    const programAddOnTrackSchema = yup.object<ProgramAddOnTrackCreate>({
        addOnID: yup.number().required().default(0),
        programId: yup.number().required().default(programId),
        addOnTrackId: yup.number().required().min(1, 'Add-On Track is required'),
        dateFirstAccredited: yup
            .date()
            .notRequired()
            .typeError('A valid date is required')
            .transform((cv, ov) => {
                return ov === '' ? undefined : cv;
            }),
        statusId: yup
            .number()
            .notRequired()
            .typeError('A valid status is required')
            .transform((cv, ov) => {
                return ov === '' ? undefined : cv;
            }),
        effectiveDate: yup
            .date()
            .notRequired()
            .typeError('A valid date is required')
            .when(['statusId'], (statusId: number | undefined, schema) => {
                if (statusId && statusId > 0) {
                    return schema.required('Effective date is required when a status is selected');
                }
            })
            .transform((cv, ov) => {
                return ov === '' ? undefined : cv;
            }),
        active: yup.boolean().required().default(true),
    });
    const { register, reset, watch, handleSubmit, errors } = useForm({
        validationSchema: programAddOnTrackSchema,
        defaultValues: {} as ProgramAddOnTrackCreate,
    });

    useEffect(() => {
        const getProfessionAddOnTracks = () => {
            if (!professionAddOnTracks) {
                professionService
                    .getAddOnTracksByProfessionId(professionId)
                    .then((results) => {
                        setProfessionAddOnTracks([...results]);
                    })
                    .catch(() => {
                        toast.error('Error loading profession add-on tracks');
                    });
            }
        };

        const getProgramAddOnTracks = () => {
            if (programId && reloadProgramAddOnTracks) {
                setReloadProgramAddOnTracks(false);
                programService
                    .getAddOnTracksByProgramId(programId)
                    .then((results) => {
                        setProgramAddOnTracks([...results]);
                    })
                    .catch(() => {
                        toast.error('Error loading program add-on tracks');
                    });
            }
        };

        getProfessionAddOnTracks();
        getProgramAddOnTracks();
    }, [professionId, setProfessionAddOnTracks, programId, setProgramAddOnTracks, reloadProgramAddOnTracks]);

    useEffect(() => {
        setProfessionAddOnTracks(undefined);
    }, [professionId]);

    const startNewAddOnTrack = () => {
        const newAddOnTrack: ProgramAddOnTrackCreate = {
            active: true,
            programId: programId,
            addOnID: 0,
            addOnTrackId: 0,
        };

        reset(newAddOnTrack);
        toggleModal();
    };

    const submitAddOnTrack = (values: ProgramAddOnTrackCreate) => {
        const toastId = toast.info('Saving program add-on track...');

        programService
            .createAddOnTrack(values)
            .then((result) => {
                toast.update(toastId, {
                    render: 'Add-on track created successfully',
                    type: 'success',
                });
                setReloadProgramAddOnTracks(true);
                toggleModal();
            })
            .catch(() => {
                toast.update(toastId, {
                    render: 'Error creating add-on track',
                    type: 'error',
                });
            });
    };

    const startAddOnTrackEdit = (t: ProgramAddOnTrack) => {
        setAddOnTrackEdit({ ...t });
        toggleEditModal();
    };

    const submitAddOnTrackEdit = () => {
        if (addOnTrackEdit) {
            const toastId = toast.info('Saving program add-on track...');

            programService
                .updateAddOnTrack({
                    ...addOnTrackEdit,
                    addOnId: addOnTrackEdit.addOnID,
                })
                .then((result) => {
                    toast.update(toastId, {
                        render: 'Add-on track saved successfully',
                        type: 'success',
                    });
                    setReloadProgramAddOnTracks(true);
                    toggleEditModal();
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error saving add-on track',
                        type: 'error',
                    });
                });
        }
    };

    const startAddOnTrackEditStatus = (t: ProgramAddOnTrack) => {
        setAddOnTrackStatusEdit({ ...t, effectiveDate: new Date() });
        toggleEditStatusModal();
    };

    const submitAddOnTrackEditStatus = () => {
        if (addOnTrackStatusEdit) {
            const toastId = toast.info('Saving program add-on track status...');

            programService
                .createAddOnTrackStatus({
                    ...addOnTrackStatusEdit,
                    addOnId: addOnTrackStatusEdit.addOnID,
                })
                .then((result) => {
                    toast.update(toastId, {
                        render: 'Status saved successfully',
                        type: 'success',
                    });
                    setReloadProgramAddOnTracks(true);
                    toggleEditStatusModal();
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error saving add-on track status',
                        type: 'error',
                    });
                });
        }
    };

    const startNewDocument = (addOnId: number) => {
        setNewDocument({
            fileUpload: null,
            dto: {
                documentId: 0,
                addOnId: addOnId,
                institutionId: institutionId,
                programId: programId,
                title: '',
                active: true,
                fileUrl: '',
                isAwardLetter: false,
            } as AmsDocument,
        });
        toggleShowNewDocumentModal();
    };

    const addStagedDoc = (dropData) => {
        if (newDocument && dropData && dropData[0]) {
            dropData.fileId = uuidv4();

            setNewDocument({
                fileUpload: dropData[0],
                dto: {
                    ...newDocument.dto,
                    fileUrl: dropData[0].path as string,
                } as AmsDocument,
            });
        }
    };

    const saveNewDocument = () => {
        if (newDocument) {
            const toastId = toast.info('Saving & uploading document...');

            documentService
                .saveAndUploadRelatedDocument(newDocument)
                .then((result) => {
                    toast.update(toastId, {
                        render: 'Document uploaded and saved successfully',
                        type: 'success',
                    });

                    setReloadProgramAddOnTracks(true);
                    toggleShowNewDocumentModal();
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error uploading and saving document',
                        type: 'error',
                    });
                });
        }
    };

    const confirmRemoveDocument = (document: AmsDocument) => {
        setRemoveDocument(document);
        toggleShowRemoveDocumentModal();
    };

    const completeRemoveDocument = () => {
        if (removeDocument) {
            const toastId = toast.info('Removing document...');

            documentService
                .deleteDocument(removeDocument)
                .then((result) => {
                    toast.update(toastId, {
                        render: 'Document removed succesfully',
                        type: 'success',
                    });

                    setReloadProgramAddOnTracks(true);
                    toggleShowRemoveDocumentModal();
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error removing document',
                        type: 'error',
                    });
                });
        }
    };

    const watchAddOnId = watch('addOnID', 0);

    return (
        <>
            <Card className={'d-flex flex-grow-1'}>
                <CardBody>
                    <h4 className={'card-title d-flex align-items-center justify-content-between'}>
                        <span>{`Add-On Tracks`}</span>
                        {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') &&
                            professionAddOnTracks &&
                            professionAddOnTracks.length > 0 && (
                                <div>
                                    <Button
                                        type={'button'}
                                        color={'primary'}
                                        className={'btn-icon'}
                                        onClick={() => startNewAddOnTrack()}
                                    >
                                        <i className={`mdi mdi-plus`} />
                                    </Button>
                                </div>
                            )}
                        {(professionAddOnTracks?.length || 0) === 0 && (programAddOnTracks?.length || 0) > 0 && (
                            <span>{`No Profession Add-On Tracks Configured`}</span>
                        )}
                    </h4>
                    {(professionAddOnTracks?.length || 0) === 0 && (programAddOnTracks?.length || 0) === 0 && (
                        <span>{`No Profession Add-On Tracks Configured`}</span>
                    )}
                    {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') &&
                        (professionAddOnTracks?.length || 0) > 0 &&
                        (programAddOnTracks?.length || 0) === 0 && (
                            <span>{`Click the '+' button to add an add-on track for this program`}</span>
                        )}
                    {professionAddOnTracks && programAddOnTracks && programAddOnTracks.length > 0 && (
                        <Row>
                            {programAddOnTracks
                                .filter(
                                    (aot) =>
                                        professionAddOnTracks.filter((pt) => pt.addOnTrackId === aot.addOnTrackId)
                                            .length > 0,
                                )
                                .map((pt) => (
                                    <Col xs={12} md={6} key={pt.addOnID}>
                                        <Card>
                                            <CardBody className={'d-flex flex-column'}>
                                                <Label className={'card-title'}>
                                                    {
                                                        professionAddOnTracks.find(
                                                            (paot) => paot.addOnTrackId === pt.addOnTrackId,
                                                        )?.title
                                                    }
                                                    <Button
                                                        className={'btn-sm btn-icon btn-outline ml-2'}
                                                        color={'white'}
                                                        onClick={() => startAddOnTrackEdit(pt)}
                                                    >
                                                        <i className={'mdi mdi-pencil'}></i>
                                                    </Button>
                                                </Label>
                                                <div className={'d-flex justify-content-between'}>
                                                    <span>{`Date First Accredited`}</span>
                                                    {pt.dateFirstAccredited && (
                                                        <Label>
                                                            {moment.utc(pt.dateFirstAccredited).format(`MM/DD/YYYY`)}
                                                        </Label>
                                                    )}
                                                    {!pt.dateFirstAccredited && <Label>{`Not Set`}</Label>}
                                                </div>
                                                <div className={'mt-2 d-flex justify-content-between'}>
                                                    <span>
                                                        {`Status`}
                                                        <Button
                                                            className={'btn-sm btn-icon btn-outline ml-2'}
                                                            color={'white'}
                                                            onClick={() => startAddOnTrackEditStatus(pt)}
                                                        >
                                                            <i className={'mdi mdi-pencil'}></i>
                                                        </Button>
                                                    </span>
                                                    {pt.statusId !== 0 && (
                                                        <Label>{ProgramStatus[pt.statusId || 0]}</Label>
                                                    )}
                                                    {!pt.statusId && <Label>{`Not Set`}</Label>}
                                                </div>
                                                {pt.effectiveDate && (
                                                    <div className={'d-flex justify-content-between'}>
                                                        <span>{`Effective Date`}</span>
                                                        <Label>
                                                            {moment.utc(pt.effectiveDate).format(`MM/DD/YYYY`)}
                                                        </Label>
                                                    </div>
                                                )}
                                                <div className={'d-flex flex-column mt-2'}>
                                                    {pt.documents &&
                                                        pt.documents.map((d) => (
                                                            <div
                                                                key={d.documentId}
                                                                className={
                                                                    'd-flex justify-content-between align-items-center'
                                                                }
                                                            >
                                                                <span>{d.title}</span>
                                                                <div>
                                                                    <a
                                                                        className={'btn btn-icon mr-2 btn-success'}
                                                                        href={`${APIUriAms}/document/download/${d.fileUrl}`}
                                                                    >
                                                                        <i className={`mdi mdi-download`} />
                                                                    </a>
                                                                    <Button
                                                                        color={'danger'}
                                                                        className={'btn-icon'}
                                                                        onClick={() => confirmRemoveDocument(d)}
                                                                    >
                                                                        <i className={`mdi mdi-trash-can-outline`} />
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    <Button
                                                        type={'button'}
                                                        color={'info'}
                                                        className={'mt-2'}
                                                        onClick={() => startNewDocument(pt.addOnID)}
                                                    >
                                                        <i className={'mdi mdi-plus'} />
                                                        <span className={'ml-1'}>{`Add Related Document`}</span>
                                                    </Button>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                        </Row>
                    )}
                </CardBody>
            </Card>

            {professionAddOnTracks && (
                <Modal isOpen={showModal} toggle={toggleModal} centered={true}>
                    <ModalHeader>{`New Program Add-On Track`}</ModalHeader>
                    <Form onSubmit={handleSubmit(submitAddOnTrack)}>
                        <ModalBody>
                            <FormGroup>
                                <Label>{`Add-On Track`}</Label>
                                <Input type={'select'} name={'addOnTrackId'} innerRef={register()}>
                                    <option value={0}></option>
                                    {professionAddOnTracks.map((t) => (
                                        <option key={t.addOnTrackId} value={t.addOnTrackId}>
                                            {t.title}
                                        </option>
                                    ))}
                                </Input>
                                {errors && errors.addOnTrackId && errors.addOnTrackId.message && (
                                    <span className={'text-danger'}>{errors.addOnTrackId.message}</span>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label>{`Date First Accredited`}</Label>
                                <Input type="date" name={'dateFirstAccredited'} innerRef={register()} />
                                {errors && errors.dateFirstAccredited && errors.dateFirstAccredited.message && (
                                    <span className={'text-danger'}>{errors.dateFirstAccredited.message}</span>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label>{`Status`}</Label>
                                <Input type={`select`} name={'statusId'} innerRef={register()}>
                                    <option value={0}>{` Select a Status `}</option>
                                    {Object.keys(ProgramStatus)
                                        .filter((ps) => !isNaN(+ps))
                                        .map((ps) => (
                                            <option key={+ps} value={+ps}>
                                                {ProgramStatus[ps]}
                                            </option>
                                        ))}
                                </Input>
                                {errors && errors.statusId && errors.statusId.message && (
                                    <span className={'text-danger'}>{errors.statusId.message}</span>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label>{`Effective Date`}</Label>
                                <Input type="date" name={'effectiveDate'} innerRef={register()} />
                                {errors && errors.effectiveDate && errors.effectiveDate.message && (
                                    <span className={'text-danger'}>{errors.effectiveDate.message}</span>
                                )}
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color={'primary'} className={'mr-2'} type={'submit'}>{`Save`}</Button>
                            <Button color={'link'} onClick={() => toggleModal()} type={'button'}>{`Cancel`}</Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            )}

            {professionAddOnTracks && addOnTrackEdit && (
                <Modal isOpen={showEditModal} toggle={toggleEditModal} centered={true}>
                    <ModalHeader>{`Edit Program Add-On Track`}</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label>{`Add-On Track`}</Label>
                            <Input
                                type={'select'}
                                value={addOnTrackEdit.addOnTrackId}
                                onChange={(e) =>
                                    setAddOnTrackEdit({ ...addOnTrackEdit, addOnTrackId: +e.target.value })
                                }
                            >
                                {professionAddOnTracks.map((t) => (
                                    <option key={t.addOnTrackId} value={t.addOnTrackId}>
                                        {t.title}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>{`Date First Accredited`}</Label>
                            <Input
                                type="date"
                                value={
                                    addOnTrackEdit.dateFirstAccredited
                                        ? moment.utc(addOnTrackEdit.dateFirstAccredited).format('YYYY-MM-DD')
                                        : undefined
                                }
                                onChange={(e) =>
                                    setAddOnTrackEdit({
                                        ...addOnTrackEdit,
                                        dateFirstAccredited: e.target.value
                                            ? moment.utc(e.target.value).toDate()
                                            : undefined,
                                    })
                                }
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color={'primary'}
                            className={'mr-2'}
                            type={'button'}
                            onClick={() => submitAddOnTrackEdit()}
                        >{`Save`}</Button>
                        <Button color={'link'} onClick={() => toggleEditModal()} type={'button'}>{`Cancel`}</Button>
                    </ModalFooter>
                </Modal>
            )}

            {professionAddOnTracks && addOnTrackStatusEdit && (
                <Modal isOpen={showEditStatusModal} toggle={toggleEditStatusModal} centered={true}>
                    <ModalHeader>{`Edit Add-On Track Status`}</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label>{`Status`}</Label>
                            <Input
                                type={`select`}
                                value={addOnTrackStatusEdit.statusId || 0}
                                onChange={(e) =>
                                    setAddOnTrackStatusEdit({ ...addOnTrackStatusEdit, statusId: +e.target.value })
                                }
                            >
                                <option value={0}>{` Remove Status `}</option>
                                {Object.keys(ProgramStatus)
                                    .filter((ps) => !isNaN(+ps))
                                    .map((ps) => (
                                        <option key={+ps} value={+ps}>
                                            {ProgramStatus[ps]}
                                        </option>
                                    ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>{`Effective Date`}</Label>
                            <Input
                                type="date"
                                value={moment.utc(addOnTrackStatusEdit.effectiveDate).format('YYYY-MM-DD')}
                                onChange={(e) =>
                                    setAddOnTrackStatusEdit({
                                        ...addOnTrackStatusEdit,
                                        effectiveDate: e.target.value ? moment.utc(e.target.value).toDate() : undefined,
                                    })
                                }
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color={'primary'}
                            className={'mr-2'}
                            type={'button'}
                            onClick={() => submitAddOnTrackEditStatus()}
                        >{`Save`}</Button>
                        <Button
                            color={'link'}
                            onClick={() => toggleEditStatusModal()}
                            type={'button'}
                        >{`Cancel`}</Button>
                    </ModalFooter>
                </Modal>
            )}

            <Modal isOpen={showNewDocumentModal} toggle={toggleShowNewDocumentModal} className={`modal-lg`}>
                <ModalHeader>{`New Related Document`}</ModalHeader>
                {newDocument && (
                    <ModalBody>
                        <FormGroup>
                            <Label>{`Document Title`}</Label>
                            <Input
                                type={'text'}
                                onChange={(e) =>
                                    setNewDocument({
                                        ...newDocument,
                                        dto: { ...newDocument.dto, title: e.target.value } as AmsDocument,
                                    })
                                }
                            />
                        </FormGroup>
                        <FormGroup className={'d-flex flex-column'}>
                            <Label>{`File Upload`}</Label>
                            {newDocument?.dto?.fileUrl && <span className={'mb-2'}>{newDocument.dto.fileUrl}</span>}
                            <Dropzone onDrop={(acceptedFiles) => addStagedDoc(acceptedFiles)} multiple={false}>
                                {({ getRootProps, getInputProps }) => (
                                    <DropZoneContainer>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <p style={{ textAlign: 'center', lineHeight: '80px' }}>
                                                {`Drag & drop a file here, or click to select a file`}
                                            </p>
                                        </div>
                                    </DropZoneContainer>
                                )}
                            </Dropzone>
                        </FormGroup>
                    </ModalBody>
                )}
                <ModalFooter>
                    <Button
                        color={'primary'}
                        className={'mr-2'}
                        onClick={() => saveNewDocument()}
                        type={'button'}
                    >{`Upload & Save`}</Button>
                    <Button
                        color={'link'}
                        onClick={() => toggleShowNewDocumentModal()}
                        type={'button'}
                    >{`Cancel`}</Button>
                </ModalFooter>
            </Modal>

            {removeDocument && (
                <Modal isOpen={showRemoveDocumentModal} toggle={toggleShowRemoveDocumentModal} centered={true}>
                    <ModalHeader>{`Confirm Remove Related Document`}</ModalHeader>
                    <ModalBody>
                        <p>
                            Please confirm the removal of{' '}
                            <span className={'font-weight-bold'}>
                                {removeDocument.title || removeDocument.fileDownloadName}
                            </span>{' '}
                            by clicking the Confirm button below.
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color={'primary'}
                            className={'mr-2'}
                            onClick={() => completeRemoveDocument()}
                            type={'button'}
                        >{`Confirm`}</Button>
                        <Button
                            color={'link'}
                            onClick={() => toggleShowRemoveDocumentModal()}
                            type={'button'}
                        >{`Cancel`}</Button>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
};

export default AddOnTracks;
