import { Button, Input, PageTitle } from '../../components';
import { Card, CardBody, Col, Form, FormGroup, InputGroup, InputGroupAddon, Label, Modal, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import { Institution } from '../../common/Types';
import { Link } from 'react-router-dom';
import NewInsitution from '../../components/NewInstitution';
import Skeleton from 'react-loading-skeleton';
import { institutionService } from '../../services/InstitutionService';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

const Professions = () => {
    // List of active institutions
    const [institutions, setInstitutions] = useState<Institution[]>();
    // Seperate list to track inactive institutions
    const [inactiveInstitutions, setInactiveInstitutions] = useState<Institution[]>([]);
    // Tracking original list to reset ui after filtering
    const [originalInstitutions, setOriginalInstitutions] = useState<Institution[]>();
    const [searchText, setSearchText] = useState<string>('');
    const [searchId, setSearchId] = useState<number>();

    const [showNewInstitution, setShowNewInstitution] = useState<boolean>(false);
    const toggleShowNewInstitution = () => setShowNewInstitution(!showNewInstitution);

    const { handleSubmit } = useForm({});

    useEffect(() => {
        const getInstitutions = () => {
            institutionService
                .getInstitutions()
                .then((response) => {
                    setOriginalInstitutions(response);
                    setInstitutions(response.filter((x) => x.active));
                    setInactiveInstitutions(response.filter((x) => !x.active));
                })
                .catch(() => {
                    toast.error('Failed to get institution list.');
                });
        };

        getInstitutions();
    }, [setInstitutions, setOriginalInstitutions]);

    const onSearchSubmit = () => {
        if (searchText !== '' && searchText && originalInstitutions) {
            const filteredList =
                originalInstitutions?.filter(
                    (x) =>
                        x.title.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1 ||
                        (x.nameHistory || []).filter(
                            (h) => h.title.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1,
                        ).length > 0,
                ) || [];
            setInstitutions(filteredList);
        } else setInstitutions(originalInstitutions);
    };

    const onIdSearchSubmit = () => {
        if (searchId && !isNaN(searchId) && originalInstitutions) {
            const institution = originalInstitutions?.find((x) => x.institutionId === +searchId);

            if (institution) {
                setInstitutions([{ ...institution }]);
            } else {
                setInstitutions([]);
            }
        } else setInstitutions(originalInstitutions);
    };

    const renderInstitutionLink = (institution: Institution) => {
        return (
            <div key={institution.institutionId} style={{ flexBasis: `calc(25% - 10px)` }}>
                <p>
                    <Link className="link-to-institute" to={`/institution/${institution.institutionId}`}>
                        <div className={'d-flex flex-column'}>
                            <Label className={'mb-0 pb-0'} style={{ cursor: 'pointer' }}>
                                {institution.title}
                            </Label>
                            <span
                                className={'text-muted mt-0 pt-0 pl-2'}
                            >{`${institution.city}, ${institution.state}`}</span>
                        </div>
                    </Link>
                </p>
            </div>
        );
    };

    return (
        <>
            <PageTitle className={`d-flex justify-content-between py-2`}>
                <h4>{`Institutions`}</h4>
                <Button type={'button'} color={'primary'} onClick={() => setShowNewInstitution(true)}>
                    <i className={'mdi mdi-plus'} />
                    <span className={'ml-1'}>{`New Insitution`}</span>
                </Button>
            </PageTitle>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <Row className="mb-3">
                                <Col className={'d-flex justify-content-between'}>
                                    <Form
                                        onSubmit={handleSubmit(onSearchSubmit)}
                                        style={{ flexBasis: 'calc(50% - 20px)' }}
                                    >
                                        <FormGroup>
                                            <Label>{`Search by Name`}</Label>
                                            <InputGroup>
                                                <Input
                                                    placeholder="Institution Name"
                                                    type="text"
                                                    value={searchText}
                                                    onChange={(e) => setSearchText(e.target.value)}
                                                />
                                                <InputGroupAddon addonType={'append'}>
                                                    <Button color="primary" type="submit">
                                                        {`Search`}
                                                    </Button>
                                                    <Button
                                                        color="white"
                                                        className={'ml-2 btn-link'}
                                                        type="submit"
                                                        onClick={(e) => setSearchText('')}
                                                    >
                                                        {`Reset`}
                                                    </Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                    </Form>
                                    <Form
                                        onSubmit={handleSubmit(onIdSearchSubmit)}
                                        style={{ flexBasis: 'calc(25% - 20px)' }}
                                    >
                                        <FormGroup>
                                            <Label>{`Search by ID`}</Label>
                                            <InputGroup>
                                                <Input
                                                    placeholder="Institution ID"
                                                    type={'text'}
                                                    value={searchId || ''}
                                                    onChange={(e) => setSearchId(e.target.value)}
                                                />
                                                <InputGroupAddon addonType={'append'}>
                                                    <Button color="primary" type="submit">
                                                        {`Search`}
                                                    </Button>
                                                    <Button
                                                        color="white"
                                                        className={'ml-2 btn-link'}
                                                        type="submit"
                                                        onClick={(e) => setSearchId(undefined)}
                                                    >
                                                        {`Reset`}
                                                    </Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>

                            {institutions && (
                                <Row>
                                    <Col className={'d-flex flex-wrap justify-content-between'}>
                                        {institutions.map((institution) => renderInstitutionLink(institution))}
                                    </Col>
                                </Row>
                            )}
                            {!institutions && (
                                <Row>
                                    <Col lg={3} md={6}>
                                        <Skeleton count={5} />
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <Skeleton count={5} />
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <Skeleton count={5} />
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <Skeleton count={5} />
                                    </Col>
                                </Row>
                            )}
                        </CardBody>
                    </Card>
                    {inactiveInstitutions?.length > 0 && (
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col xs={12}>
                                        <h5>Inactive Professions</h5>
                                    </Col>
                                </Row>

                                {inactiveInstitutions && (
                                    <Row>
                                        <Col className={'d-flex flex-wrap justify-content-between'}>
                                            {inactiveInstitutions.map((institution) =>
                                                renderInstitutionLink(institution),
                                            )}
                                        </Col>
                                    </Row>
                                )}
                            </CardBody>
                        </Card>
                    )}
                </Col>
            </Row>
            <Modal isOpen={showNewInstitution} toggle={toggleShowNewInstitution} size={'lg'}>
                <NewInsitution onCanceled={toggleShowNewInstitution} onSaved={(i) => console.log(i)} />
            </Modal>
        </>
    );
};

export default Professions;
