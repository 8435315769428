import { Card, CardBody, CardFooter, FormGroup, Label } from 'reactstrap';
import React, { useState } from 'react';

import Button from './Button';
import { RecommendationSatellite as Dto } from '../common/Types';
import Input from './Input';
import { States } from '../common/Data';
import { recommendationService } from '../services/RecommendationService';
import { toast } from 'react-toastify';

interface RecommendationSatelliteProps {
    satellite: Dto;
    onCancel: () => void;
    onSave: () => void;
}

const RecommendationSatellite = (props: RecommendationSatelliteProps) => {
    const [satellite, setSatellite] = useState<Dto>(props.satellite);

    const submit = () => {
        const toastId = toast.info('Saving satellite...');

        recommendationService
            .saveSatellite(satellite)
            .then((s) => {
                toast.update(toastId, {
                    render: 'Satellite saved successfully',
                    type: 'success',
                });

                props.onSave();
            })
            .catch(() => {
                toast.update(toastId, {
                    render: 'Error saving satellite',
                    type: 'error',
                });
            });
    };

    return (
        <>
            <Card className={'mb-0'}>
                <CardBody>
                    <h4 className={'card-title'}>{`${
                        satellite.recommendationSatelliteId > 0 ? 'Edit' : 'New'
                    } Satellite`}</h4>

                    <FormGroup>
                        <Label>{`Name`}</Label>

                        <Input
                            type={'text'}
                            value={satellite.title}
                            onChange={(e) => setSatellite({ ...satellite, title: e.target.value })}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>{`City`}</Label>

                        <Input
                            type={'text'}
                            value={satellite.city}
                            onChange={(e) => setSatellite({ ...satellite, city: e.target.value })}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>{`State`}</Label>

                        <Input
                            type={'select'}
                            value={satellite.state}
                            onChange={(e) => setSatellite({ ...satellite, state: e.target.value })}
                        >
                            <option value={undefined}>{``}</option>
                            {States.map((s) => (
                                <option key={s.abbreviation} value={s.abbreviation}>
                                    {s.abbreviation}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                </CardBody>
                <CardFooter>
                    <Button type={'button'} onClick={() => submit()}>
                        {`Save`}
                    </Button>
                    <Button type={'button'} className={'ml-2'} color={'secondary'} onClick={props.onCancel}>
                        {`Cancel`}
                    </Button>
                </CardFooter>
            </Card>
        </>
    );
};

export default RecommendationSatellite;
