import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';

import { AnnualReportProps } from '../AnnualReport';
import { AnnualReportStatus } from '../../../common/Types';
import { Button } from '../../../components';
import React from 'react';

const Finish = (props: AnnualReportProps) => {
    const isReadyToSubmit = (): boolean => {
        return (props.remainingTabs && props.remainingTabs.length - 1 <= 0) || false;
    };

    return (
        <>
            <Row>
                <Col>
                    <h4 className={'card-title'}>Submit Report</h4>
                </Col>
            </Row>
            <Card>
                <CardBody>
                    {!isReadyToSubmit() && (
                        <>
                            <Row>
                                <Col>
                                    <p>
                                        {`It looks like there are some sections of the report that still need to be completed. 
                                    Please use the buttons below or the navigation menu to the left to jump to any uncompleted 
                                    sections. Uncompleted sections are indicated as such by the lack of a green check mark next to 
                                    the name of the section in the navigation menu to the left.`}
                                    </p>
                                    <p>
                                        {`Once all of the sections are complete and have a green check mark next to them, you can come back 
                                        here to the 'Finish & Submit' section to submit your completed report.`}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={'d-flex flex-wrap justify-content-around'}>
                                    {props.remainingTabs &&
                                        props.remainingTabs
                                            .filter((t) => t.name !== 'Finish & Submit')
                                            .map((rt) => (
                                                <Button
                                                    key={rt.name}
                                                    type={'button'}
                                                    color={'danger'}
                                                    className={'mb-2'}
                                                    style={{ flexBasis: 'calc(25% - 10px)' }}
                                                    onClick={() => props.onGoToTab(rt.priority)}
                                                >
                                                    <span>{rt.name}</span>
                                                </Button>
                                            ))}
                                </Col>
                            </Row>
                        </>
                    )}
                    {isReadyToSubmit() && (
                        <Row>
                            <Col>
                                <p>
                                    {`Congratulations! It looks like you're ready to submit your Annual Report. When you're ready to do 
                            so, click the button at the bottom.`}
                                </p>
                            </Col>
                        </Row>
                    )}
                </CardBody>
                {isReadyToSubmit() && props.canEdit && (
                    <CardFooter className={'d-flex justify-content-around'}>
                        {props.annualReport.reportStatus === AnnualReportStatus.InProgress && (
                            <Button color={'primary'} type={'button'} onClick={() => props.onSubmitAnnualReport()}>
                                <span>Submit Annual Report</span>
                            </Button>
                        )}
                        {props.annualReport.reportStatus === AnnualReportStatus.SentBack && (
                            <Button color={'primary'} type={'button'} onClick={() => props.onSubmitAnnualReport()}>
                                <span>Resubmit Annual Report</span>
                            </Button>
                        )}
                    </CardFooter>
                )}
            </Card>
        </>
    );
};

export default Finish;
