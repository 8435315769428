import AdvisoryCommittee from '../pages/annual-report/report-tabs/AdvisoryCommittee';
import AdvisoryCommitteePage from '../pages/arms-configuration/AdvisoryCommittee';
import AnnualReport from '../pages/annual-report/AnnualReport';
import Auspice from '../pages/system-admin/Auspice';
import ClinicalAffiliates from '../pages/annual-report/report-tabs/ClinicalAffiliates';
import Coa from '../pages/coa/CoA';
import Coas from '../pages/system-admin/Coas';
import Comments from '../pages/annual-report/report-tabs/Comments';
import CredentialingExams from '../pages/annual-report/report-tabs/CredentialingExams';
import Demographics from '../pages/annual-report/report-tabs/Demographics';
import DistanceEducation from '../pages/annual-report/report-tabs/DistanceEducation';
import DocumentCategoriesTab from '../pages/arms-configuration/DocumentCategoriesTab';
import EnrollmentRetention from '../pages/annual-report/report-tabs/EnrollmentRetention';
import Exams from '../pages/arms-configuration/Exams';
import Finish from '../pages/annual-report/report-tabs/Finish';
import GeneralSettingsPage from '../pages/arms-configuration/GeneralSettings';
import GenerateAnnualInvoices from '../pages/billing/GenerateAnnualInvoices';
import GettingStarted from '../pages/annual-report/report-tabs/GettingStarted';
import InstitutionOverview from '../pages/institutions/InstitutionOverview';
import InstitutionTypes from '../pages/system-admin/InstitutionTypes';
import InstitutionalAccreditors from '../pages/system-admin/InstitutionalAccreditors';
import Institutions from '../pages/institutions/Institutions';
import Invoice from '../pages/system-admin/invoice/Invoice';
import Invoices from '../pages/system-admin/Invoices';
import LetterTemplates from '../pages/system-admin/LetterTemplates';
import Login from '../pages/auth/Login';
import Logout from '../pages/auth/Logout';
import MeetingDates from '../pages/system-admin/MeetingDates';
import Overview from '../pages/annual-report/Overview';
import PasswordResetComplete from '../pages/auth/PasswordResetComplete';
import PasswordResetRequest from '../pages/auth/PasswordResetRequest';
import Payments from '../pages/billing/Payments';
import Personnel from '../pages/annual-report/report-tabs/Personnel';
import PersonnelSettingsPage from '../pages/arms-configuration/PersonnelSettings';
import Profession from '../pages/arms-configuration/Profession';
import ProfessionManagement from '../pages/system-admin/profession/Profession';
import { default as ProfessionManagementList } from '../pages/system-admin/Professions';
import Professions from '../pages/arms-configuration/Professions';
import Profile from '../pages/account/Profile';
import ProgramDirectorDashboard from '../pages/annual-report/ProgramDirectorDashboard';
import ProgramInformation from '../pages/annual-report/report-tabs/ProgramInformation';
import ProgramInformationTab from '../pages/arms-configuration/ProgramInformationTab';
import ProgramOverview from '../pages/institutions/ProgramOverview';
import ProgramTabSettings from '../pages/arms-configuration/ProgramTabSettings';
import PublicInvoice from '../pages/billing/PublicInvoice';
import Recommendation from '../pages/recommendations/Recommendation';
import RecommendationsList from '../pages/recommendations/RecommendationList';
import RelatedDocuments from '../pages/annual-report/report-tabs/RelatedDocuments';
import ResourceTab from '../pages/arms-configuration/ResourceTab';
import Resources from '../pages/annual-report/report-tabs/Resources';
import RolesPermissions from '../pages/security/RolesPermissions';
import SendInvoiceEmails from '../pages/billing/SendInvoiceEmails';
import Standards from '../pages/annual-report/report-tabs/Standards';
import SummativeMeasures from '../pages/annual-report/report-tabs/SummativeMeasures';
import SummativeMeasuresTab from '../pages/arms-configuration/SummativeMeasuresTab';
import SurveyWorksheet from '../pages/annual-report/report-tabs/SurveyWorksheet';
import Users from '../pages/security/Users';
import Unpaid from '../pages/billing/Unpaid';
import StandardsReport from '../pages/recommendations/StandardsReport';
import GeneralInformation from '../pages/reports/GeneralInformation';
import AccreditedProgramsReport from '../pages/reports/AccreditedProgramsReport';
import AnnualReportTotalsReport from '../pages/reports/AnnualReportTotalsReport';
import DegreesByAuspiceReport from '../pages/reports/DegreesByAuspiceReport';
import InactiveStatusReport from '../pages/reports/InactiveStatusReport';
import ProbationStatusReport from '../pages/reports/ProbationStatusReport';
import ProfessionSearchCountReport from '../pages/reports/ProfessionSearchCountReport';
import NumberOfActiveProgramsReport from '../pages/reports/NumberOfActiveProgramsReport';
import ProfitVsPublicReport from '../pages/reports/ProfitVsPublicReport';
import WithdrawStatusReport from '../pages/reports/WithdrawStatusReport';
import InstitutionContactReport from '../pages/reports/InstitutionContactReport';
import Hub from '../pages/hub/Hub';
import HubUsers from '../pages/hub/HubUsers';
import HubPublicDownload from '../components/HubPublicDownload';
import InvoicePayment from '../pages/payments/invoice-payment';
import InvoicePaymentThankYou from '../pages/payments/thank-you';
import InvoiceAchInfo from '../pages/payments/ach-info';

export type RouteItem = {
    path: string;
    component: React.Component;
    hasPermission?: boolean;
    name?: string;
};

export const routes = [
    {
        path: '/login',
        component: Login,
    },
    {
        path: '/logout',
        component: Logout,
    },
    {
        path: '/hub/public/:documentSlug',
        component: HubPublicDownload,
        private: false,
    },
    {
        path: '/passwordreset/request',
        component: PasswordResetRequest,
    },
    {
        path: '/passwordreset/complete/:token',
        component: PasswordResetComplete,
    },
    {
        path: '/profile',
        component: Profile,
        private: true,
    },
    {
        path: '/admin/professions',
        component: Professions,
        private: true,
    },
    {
        path: '/admin/profession/:id',
        component: Profession,
        private: true,
        routes: [
            {
                path: '/admin/profession/:id/general-settings',
                component: GeneralSettingsPage,
                name: 'General Settings',
            },
            {
                path: '/admin/profession/:id/exams',
                component: Exams,
                name: 'Credentialing Exams',
            },
            {
                path: '/admin/profession/:id/personnel-settings',
                component: PersonnelSettingsPage,
                name: 'Personnel Settings',
            },
            {
                path: '/admin/profession/:id/advisory-committee',
                component: AdvisoryCommitteePage,
                name: 'Advisory Committee',
            },
            {
                path: '/admin/profession/:id/program-tab-settings',
                component: ProgramTabSettings,
                name: 'Program Tab Settings',
            },
            {
                path: '/admin/profession/:id/program-information-tab',
                component: ProgramInformationTab,
                name: 'Program Information Tab',
            },
            {
                path: '/admin/profession/:id/resource-tab',
                component: ResourceTab,
                name: 'Resources Tab',
            },
            {
                path: '/admin/profession/:id/document-categories',
                component: DocumentCategoriesTab,
                name: 'Document Categories',
            },
            {
                path: '/admin/profession/:id/summative-measures',
                component: SummativeMeasuresTab,
                name: 'Summative Measures',
            },
        ],
    },
    {
        path: '/annual-report/overview',
        component: Overview,
        private: true,
    },
    {
        path: '/annual-report/dashboard',
        component: ProgramDirectorDashboard,
        private: true,
    },
    {
        path: '/annual-report/:professionid/:reportyear/program/:programid',
        component: AnnualReport,
        private: true,
        routes: [
            {
                name: 'Getting Started',
                path: '/annual-report/:professionid/:reportyear/program/:programid/getting-started',
                priority: -1,
                component: GettingStarted,
            },
            {
                name: 'Personnel',
                path: '/annual-report/:professionid/:reportyear/program/:programid/personnel',
                component: Personnel,
            },
            {
                name: 'Advisory Committee',
                path: '/annual-report/:professionid/:reportyear/program/:programid/advisory-commitee',
                component: AdvisoryCommittee,
            },
            {
                name: 'Clinical Affiliates',
                path: '/annual-report/:professionid/:reportyear/program/:programid/affiliates',
                component: ClinicalAffiliates,
            },
            {
                name: 'Enrollment & Retention',
                path: '/annual-report/:professionid/:reportyear/program/:programid/enrollment',
                component: EnrollmentRetention,
            },
            {
                name: 'Credentialing Exams',
                path: '/annual-report/:professionid/:reportyear/program/:programid/exams',
                component: CredentialingExams,
            },
            {
                name: 'Survey Worksheet',
                path: '/annual-report/:professionid/:reportyear/program/:programid/survey-worksheet',
                component: SurveyWorksheet,
            },
            {
                name: 'Program Information',
                path: '/annual-report/:professionid/:reportyear/program/:programid/program-information',
                component: ProgramInformation,
            },
            {
                name: 'Resources',
                path: '/annual-report/:professionid/:reportyear/program/:programid/resources',
                component: Resources,
            },
            {
                name: 'Standards',
                path: '/annual-report/:professionid/:reportyear/program/:programid/standards',
                component: Standards,
            },
            {
                name: 'Comments',
                path: '/annual-report/:professionid/:reportyear/program/:programid/comments',
                component: Comments,
            },
            {
                name: 'Summative Measures',
                path: '/annual-report/:professionid/:reportyear/program/:programid/summative-measures',
                component: SummativeMeasures,
            },
            {
                name: 'Distance Education',
                path: '/annual-report/:professionid/:reportyear/program/:programid/distance-education',
                component: DistanceEducation,
            },
            {
                name: 'Demographics',
                path: '/annual-report/:professionid/:reportyear/program/:programid/demographics',
                component: Demographics,
            },
            {
                name: 'Related Documents',
                path: '/annual-report/:professionid/:reportyear/program/:programid/documents',
                component: RelatedDocuments,
            },
            {
                name: 'Finish & Submit',
                path: '/annual-report/:professionid/:reportyear/program/:programid/finish',
                priority: 100,
                component: Finish,
            },
        ],
    },
    {
        path: '/hub/users',
        component: HubUsers,
        private: true,
    },
    {
        path: '/hub/folders/:folderSlug',
        component: Hub,
        private: true,
    },
    {
        path: '/system-admin/institution-types',
        component: InstitutionTypes,
        private: true,
    },
    {
        path: '/system-admin/auspice',
        component: Auspice,
        private: true,
    },
    {
        path: '/system-admin/meeting-dates',
        component: MeetingDates,
        private: true,
    },
    {
        path: '/system-admin/professions',
        component: ProfessionManagementList,
        private: true,
    },
    {
        path: '/system-admin/profession/:professionId',
        component: ProfessionManagement,
        private: true,
    },
    {
        path: '/system-admin/coas',
        component: Coas,
        private: true,
    },
    {
        path: '/system-admin/coa/:coaId',
        component: Coa,
        private: true,
    },
    {
        path: '/payments/invoice/:invoiceNumber',
        component: InvoicePayment,
        private: false,
    },
    {
        path: '/payments/thank-you',
        component: InvoicePaymentThankYou,
        private: false,
    },
    {
        path: '/payments/ach-info',
        component: InvoiceAchInfo,
        private: false,
    },
    {
        path: '/invoices',
        component: Invoices,
        private: true,
    },
    {
        path: '/system-admin/invoice/:invoiceId',
        component: Invoice,
        private: true,
    },
    {
        path: '/system-admin/institutional-accreditors',
        component: InstitutionalAccreditors,
        private: true,
    },
    {
        path: '/system-admin/letter-templates',
        component: LetterTemplates,
        private: true,
    },
    {
        path: '/security/users',
        component: Users,
        private: true,
    },
    {
        path: '/security/roles-permissions',
        component: RolesPermissions,
        private: true,
    },
    {
        path: '/institutions',
        component: Institutions,
        private: true,
    },
    {
        path: '/institution/:id/program/:programid',
        component: ProgramOverview,
        private: true,
    },
    {
        path: '/institution/:id',
        component: InstitutionOverview,
        private: true,
    },
    {
        path: '/billing/invoice/:invoiceId',
        component: PublicInvoice,
    },
    {
        path: '/billing/payments',
        component: Payments,
        private: true,
    },
    {
        path: '/billing/unpaid',
        component: Unpaid,
        private: true,
    },
    {
        path: '/billing/send-invoice-emails',
        component: SendInvoiceEmails,
        private: true,
    },
    {
        path: '/billing/generate-annual-invoices',
        component: GenerateAnnualInvoices,
        private: true,
    },
    {
        path: '/recommendations/list',
        component: RecommendationsList,
        private: true,
    },
    {
        path: '/recommendation/:recommendationId',
        component: Recommendation,
        private: true,
    },
    {
        path: '/recommendation/:recommendationId/new',
        component: Recommendation,
        private: true,
    },
    {
        path: '/recommendations/standards-report',
        component: StandardsReport,
        private: true,
    },
    {
        path: '/reports/general-information',
        component: GeneralInformation,
        private: true,
    },
    {
        path: '/reports/accredited-programs-report',
        component: AccreditedProgramsReport,
        private: true,
    },
    {
        path: '/reports/annual-report-totals-report',
        component: AnnualReportTotalsReport,
        private: true,
    },
    {
        path: '/reports/degrees-by-auspice',
        component: DegreesByAuspiceReport,
        private: true,
    },
    {
        path: '/reports/inactive-status',
        component: InactiveStatusReport,
        private: true,
    },
    {
        path: '/reports/probation-status',
        component: ProbationStatusReport,
        private: true,
    },
    {
        path: '/reports/profession-search-count',
        component: ProfessionSearchCountReport,
        private: true,
    },
    {
        path: '/reports/active-program-count',
        component: NumberOfActiveProgramsReport,
        private: true,
    },
    {
        path: '/reports/profit-vs-public',
        component: ProfitVsPublicReport,
        private: true,
    },
    {
        path: '/reports/withdraw-status',
        component: WithdrawStatusReport,
        private: true,
    },
    {
        path: '/reports/institution-contact',
        component: InstitutionContactReport,
        private: true,
    },
];
