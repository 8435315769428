import 'react-vertical-timeline-component/style.min.css';
import './index.css';

import * as Sentry from '@sentry/browser';
import * as dotenv from 'dotenv';
import * as serviceWorker from './serviceWorker';

import App from './App';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import configureStore from './store';

dotenv.config();

Sentry.init({ dsn: 'https://aa3b918776bf4891954df9ac28440dad@o375055.ingest.sentry.io/5193936' });

export const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
