import { ArmsPermissionClaimType, authService } from '../../services/Auth';
import { Button, Input } from '../../components';
import { Card, CardBody, CardTitle, Col, Form, FormGroup, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import { Concentration, OutcomesType, ProfessionAddOnTrack } from '../../common/Types';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import MultiSelect from 'react-multi-select-component';
import Skeleton from 'react-loading-skeleton';
import { professionService } from '../../services/ProfessionAdministration';
import styled from 'styled-components';
import { toast } from 'react-toastify';

const StyledMultiSelect = styled(MultiSelect)`
    width: 100%;
    max-width: 100%;
    margin-top: 1em;
    margin-bottom: 2em;
    padding-left: 1em;

    & .dropdown-container {
        width: 100%;
        max-width: 100%;
    }
    & .dropdown-content {
        z-index: 3;
    }
`;

const Exams = (props) => {
    const [exams, setExams] = useState<OutcomesType[]>();
    const [editing, setEditing] = useState(false);
    const [addOnTracks, setAddOnTracks] = useState<ProfessionAddOnTrack[]>();
    const [concentrations, setConcentrations] = useState<Concentration[]>();
    const { control, handleSubmit, register, reset, setValue, getValues, watch } = useForm();
    const { fields, append } = useFieldArray({
        control,
        name: 'exams',
    });

    const toggleEdit = () => {
        setEditing(!editing);
        reset({ exams: exams });
    };

    const remove = (index) => {
        setValue(`exams[${index}]`, { isActive: false });
        reset(getValues({ nest: true }));
    };

    const onSubmit = (formData) => {
        const toastId = toast.info('Saving information...');
        professionService
            .saveExams(formData.exams.map((exp) => new OutcomesType(exp)))
            .then((response) => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Information Saved.',
                });
                toggleEdit();
                setExams(response);
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Failed to save information.',
                });
            });
    };

    useEffect(() => {
        const getExams = () => {
            professionService
                .getExams(props.coa.professionId)
                .then((response) => {
                    append(response);
                    setExams(response);
                })
                .catch(() => {
                    toast.error('Unable to get information.');
                });
        };

        const getAddOnTracks = () => {
            professionService
                .getAddOnTracksByProfessionId(props.coa.professionId)
                .then((results) => {
                    setAddOnTracks(results);
                })
                .catch(() => {
                    toast.error('Error getting add-on track detail');
                });
        };

        const getConcentrations = () => {
            professionService
                .getProfessionConcentrations([props.coa.professionId])
                .then((results) => {
                    setConcentrations(results);
                })
                .catch(() => {
                    toast.error('Error getting concentrations.');
                });
        };

        getExams();
        getAddOnTracks();
        getConcentrations();
    }, [append, props.coa, setAddOnTracks, setExams, setConcentrations]);

    const changeConcentrationIds = (values: any, index: number) => {
        setValue(
            `exams[${index}].concentrationIds`,
            values.map((v) => v.value),
        );
    };

    const watchExams = watch('exams');

    return (
        <>
            <Col xs={12} md={6}>
                <Card style={{ display: 'inline-block' }}>
                    <CardBody>
                        <CardTitle>
                            <h3>Credentialing Exams</h3>
                        </CardTitle>
                        {editing ? (
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup>
                                    {fields.map((exam, i) =>
                                        JSON.parse(exam.isActive) ? (
                                            <div key={i}>
                                                <div
                                                    className={`${
                                                        (concentrations?.length || 0) === 0
                                                            ? `d-flex justify-content-between`
                                                            : ``
                                                    }`}
                                                >
                                                    {addOnTracks && addOnTracks.length > 0 && (
                                                        <Input
                                                            type="select"
                                                            name={`exams[${i}].addOnTrackId`}
                                                            defaultValue={exam.addOnTrackId}
                                                            innerRef={register()}
                                                            className={'mr-2'}
                                                        >
                                                            <option value={''}>Add-On Track, if applicable</option>
                                                            {addOnTracks &&
                                                                addOnTracks.map((track, i) => (
                                                                    <option key={i} value={track.addOnTrackId}>
                                                                        {track.title}
                                                                    </option>
                                                                ))}
                                                        </Input>
                                                    )}
                                                    <InputGroup className="mb-1" style={{ minWidth: 350 }}>
                                                        <Input
                                                            defaultValue={exam.title}
                                                            name={`exams[${i}].title`}
                                                            innerRef={register()}
                                                        />
                                                        {
                                                            <InputGroupAddon addonType="append">
                                                                <Button
                                                                    className="remove"
                                                                    color={'info'}
                                                                    onClick={() => remove(i)}
                                                                >
                                                                    <i className="remixicon-subtract-line" />
                                                                </Button>
                                                            </InputGroupAddon>
                                                        }
                                                    </InputGroup>
                                                    {concentrations && concentrations.length > 0 && (
                                                        <>
                                                            <StyledMultiSelect
                                                                options={concentrations.map((c) => {
                                                                    return {
                                                                        label: c.title,
                                                                        value: c.concentrationId,
                                                                    };
                                                                })}
                                                                disableSearch={true}
                                                                hasSelectAll={false}
                                                                value={concentrations
                                                                    .filter(
                                                                        (c) =>
                                                                            watchExams[i]?.concentrationIds &&
                                                                            JSON.parse(
                                                                                `[${watchExams[i].concentrationIds}]`,
                                                                            ).filter((e) => e === c.concentrationId)
                                                                                .length > 0,
                                                                    )
                                                                    .map((c) => {
                                                                        return {
                                                                            label: c.title,
                                                                            value: c.concentrationId,
                                                                        };
                                                                    })}
                                                                onChange={(e) => changeConcentrationIds(e, i)}
                                                                labelledBy={'Concentration, if applicable'}
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                                {addOnTracks?.length === 0 && (
                                                    <input
                                                        type={'hidden'}
                                                        name={`exams[${i}].addOnTrackId`}
                                                        ref={register()}
                                                    />
                                                )}
                                                <input
                                                    type={'hidden'}
                                                    name={`exams[${i}].concentrationIds`}
                                                    value={watchExams[i]?.concentrationIds}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`exams[${i}].professionId`}
                                                    defaultValue={exam.professionId}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`exams[${i}].activeYear`}
                                                    defaultValue={exam.activeYear}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`exams[${i}].isActive`}
                                                    defaultValue={exam.isActive}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`exams[${i}].customId`}
                                                    defaultValue={exam.customId}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`exams[${i}].outcomeTypeId`}
                                                    defaultValue={exam.outcomeTypeId}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`exams[${i}].threshold`}
                                                    defaultValue={exam.threshold}
                                                    ref={register()}
                                                />
                                            </div>
                                        ) : (
                                            <div key={i}>
                                                <input
                                                    type="hidden"
                                                    name={`exams[${i}].title`}
                                                    defaultValue={exam.title}
                                                    ref={register()}
                                                />
                                                <input
                                                    type={'hidden'}
                                                    name={`exams[${i}].addOnTrackId`}
                                                    defaultValue={exam.addOnTrackId}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`exams[${i}].professionId`}
                                                    defaultValue={exam.professionId}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`exams[${i}].activeYear`}
                                                    defaultValue={exam.activeYear}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`exams[${i}].isActive`}
                                                    defaultValue={exam.isActive}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`exams[${i}].customId`}
                                                    defaultValue={exam.customId}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`exams[${i}].outcomeTypeId`}
                                                    defaultValue={exam.outcomeTypeId}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`exams[${i}].threshold`}
                                                    defaultValue={exam.threshold}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`exams[${i}].isActive`}
                                                    defaultValue={exam.isActive}
                                                    ref={register()}
                                                />
                                            </div>
                                        ),
                                    )}
                                    <Button
                                        color={'info'}
                                        className="add"
                                        onClick={() =>
                                            append({
                                                professionId: props.coa.professionId,
                                                customId: 0,
                                                title: '',
                                                threshold: null,
                                                outcomeTypeId: null,
                                                isActive: true,
                                            })
                                        }
                                    >
                                        <i className="remixicon-add-line" />
                                        <span className="ml-1">Add Exam</span>
                                    </Button>
                                </FormGroup>
                                <Row className="mt-3">
                                    <Col>
                                        <Button type="submit">Save</Button>&nbsp;
                                        <Button outline onClick={() => toggleEdit()}>
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        ) : (
                            <>
                                {exams ? (
                                    <>
                                        {exams.map((exam, i) => {
                                            return exam.isActive ? (
                                                <Row key={i}>
                                                    <Col className="exam-title">{exam.title}</Col>
                                                </Row>
                                            ) : null;
                                        })}
                                        {authService.hasPermission(ArmsPermissionClaimType, 'adminexams') && (
                                            <Row className="mt-3">
                                                <Col>
                                                    <Button onClick={() => toggleEdit()}>Edit</Button>
                                                </Col>
                                            </Row>
                                        )}
                                    </>
                                ) : (
                                    <Skeleton count={5} />
                                )}
                            </>
                        )}
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default Exams;
