import React, { useState, useEffect } from 'react';
import { Col, Card, CardBody, CardTitle, FormGroup, Form, Label, Row, InputGroup, InputGroupAddon } from 'reactstrap';
import { useForm, useFieldArray } from 'react-hook-form';
import { Button, Input } from '../../components';
import { professionService } from '../../services/ProfessionAdministration';
import { toast } from 'react-toastify';
import { TabSetting } from '../../common/Types';
import Skeleton from 'react-loading-skeleton';

const ProgramInformationTab = (props) => {
    const [expenditures, setExpenditures] = useState<TabSetting[]>();
    const [editing, setEditing] = useState(false);
    const { control, handleSubmit, register, reset, setValue, getValues } = useForm();
    const { fields, append } = useFieldArray({
        control,
        name: 'expenditures',
    });

    const remove = (i) => {
        setValue(`expenditures[${i}]`, { isActive: false });
        reset(getValues({ nest: true }));
    };

    const toggleEdit = () => {
        setEditing(!editing);
        reset({ expenditures: expenditures });
    };

    const onSubmit = (formData) => {
        const toastId = toast.info('Saving information...');
        professionService
            .saveProgramInformation(
                props.coa.professionId,
                formData.expenditures.map((exp) => new TabSetting(exp)),
            )
            .then((response) => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Information Saved.',
                });
                toggleEdit();
                setExpenditures(response);
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Failed to save information.',
                });
            });
    };

    useEffect(() => {
        const getExpenditures = () => {
            professionService
                .getProgramInformation(props.coa.professionId)
                .then((response) => {
                    append(response);
                    setExpenditures(response);
                })
                .catch(() => {
                    toast.error('Unable to get information.');
                });
        };

        getExpenditures();
    }, [append, props.coa]);

    return (
        <>
            <Col sm={6} md={5} lg={4}>
                <Card style={{ display: 'inline-block' }}>
                    <CardBody>
                        <CardTitle>
                            <h3>Program Information Tab</h3>
                        </CardTitle>
                        {editing ? (
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup>
                                    <Label>Expenditures:</Label>
                                    {fields.map((expenditure, i) =>
                                        JSON.parse(expenditure.isActive) ? (
                                            <div key={i}>
                                                <InputGroup className="mb-1" style={{ minWidth: 350 }}>
                                                    <Input
                                                        defaultValue={expenditure.title}
                                                        name={`expenditures[${i}].title`}
                                                        innerRef={register()}
                                                    />
                                                    <InputGroupAddon addonType="append">
                                                        <Button
                                                            className="remove"
                                                            color={'info'}
                                                            onClick={() => remove(i)}
                                                        >
                                                            <i className="remixicon-subtract-line" />
                                                        </Button>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                                <input
                                                    type="hidden"
                                                    name={`expenditures[${i}].professionId`}
                                                    defaultValue={expenditure.professionId}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`expenditures[${i}].isActive`}
                                                    defaultValue={expenditure.isActive}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`expenditures[${i}].customId`}
                                                    defaultValue={expenditure.customId}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`expenditures[${i}].requireDetail`}
                                                    defaultValue={expenditure.requireDetail}
                                                    ref={register()}
                                                />
                                            </div>
                                        ) : (
                                            <div key={i}>
                                                <input
                                                    type="hidden"
                                                    name={`expenditures[${i}].title`}
                                                    defaultValue={expenditure.title}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`expenditures[${i}].professionId`}
                                                    defaultValue={expenditure.professionId}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`expenditures[${i}].isActive`}
                                                    defaultValue={expenditure.isActive}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`expenditures[${i}].customId`}
                                                    defaultValue={expenditure.customId}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`expenditures[${i}].requireDetail`}
                                                    defaultValue={expenditure.requireDetail}
                                                    ref={register()}
                                                />
                                            </div>
                                        ),
                                    )}
                                    <Button
                                        color={'info'}
                                        className="add"
                                        onClick={() =>
                                            append({
                                                professionId: props.coa.professionId,
                                                customId: 0,
                                                isActive: true,
                                                title: '',
                                            })
                                        }
                                    >
                                        <i className="remixicon-add-line" />
                                        <span className="ml-1">Add Expenditure</span>
                                    </Button>
                                </FormGroup>
                                <Row className="mt-3">
                                    <Col>
                                        <Button type="submit">Save</Button>&nbsp;
                                        <Button outline onClick={() => toggleEdit()}>
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        ) : (
                            <>
                                <Label>Expenditures:</Label>
                                {expenditures ? (
                                    <>
                                        {expenditures.map((expenditure, i) => {
                                            return (
                                                <Row key={i}>
                                                    <Col className="expenditure-title">{expenditure.title}</Col>
                                                </Row>
                                            );
                                        })}
                                        <Row className="mt-3">
                                            <Col>
                                                <Button onClick={() => toggleEdit()}>Edit</Button>
                                            </Col>
                                        </Row>
                                    </>
                                ) : (
                                    <Skeleton count={5} />
                                )}
                            </>
                        )}
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default ProgramInformationTab;
