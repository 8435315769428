import { BaseGeneralSetting, GeneralSettings, OutcomesType } from '../../common/Types';
import { Button, Input } from '../../components';
import { Card, CardBody, CardTitle, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import ReportingWindowSettings from './ReportingWindowSettings';
import Skeleton from 'react-loading-skeleton';
import { professionService } from '../../services/ProfessionAdministration';
import styled from 'styled-components';
import { toast } from 'react-toastify';

const StyledSettingListItem = styled.div`
    div {
        display: inline-block;
    }

    div:first-of-type {
        width: 300px;
    }
`;

const GeneralSettingsPage = (props) => {
    const [settings, setSettings] = useState<GeneralSettings>();
    const [editing, setEditing] = useState(false);
    const { handleSubmit, register, control, reset } = useForm();
    const { fields, append } = useFieldArray({
        control,
        name: 'outcomeTypesCustomizations',
    });

    const onSubmit = (formData) => {
        const toastId = toast.info('Saving settings...');
        professionService
            .saveGeneralSettings(props.coa.professionId, {
                reportingWindow: new BaseGeneralSetting(props.coa.professionId, formData.reportingWindow),
                outcomeTypesCustomizations: formData.outcomeTypesCustomizations?.map(
                    (customization) => new OutcomesType(customization),
                ),
            })
            .then((response) => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Settings Saved.',
                });
                setSettings(response);
                setEditing(false);
                if (response.outcomeTypesCustomizations)
                    reset({ outcomeTypesCustomizations: response.outcomeTypesCustomizations });
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Failed to save settings.',
                });
            });
    };

    useEffect(() => {
        const getSettings = async () => {
            professionService
                .getGeneralSettings(props.coa.professionId)
                .then((response) => {
                    setSettings(response);
                    if (response.outcomeTypesCustomizations) append(response.outcomeTypesCustomizations);
                })
                .catch(() => {
                    toast.error('Unable to get settings.');
                });
        };

        getSettings();
    }, [setSettings, props.coa, append]);

    return (
        <>
            <Col md={8} lg={7} xl={6}>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h3>General Settings</h3>
                        </CardTitle>

                        {editing ? (
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <input
                                    type="hidden"
                                    id="reportingWindow.standardsTabMeetingCount"
                                    name="reportingWindow.standardsTabMeetingCount"
                                    ref={register()}
                                    defaultValue={settings?.reportingWindow.standardsTabMeetingCount ?? 0}
                                />
                                <ReportingWindowSettings
                                    register={register}
                                    reportingWindow={settings?.originalReportingWindow}
                                />
                                <hr style={{ borderColor: 'black', marginTop: '3rem', marginBottom: '3rem' }} />
                                {fields.map((setting, i) => {
                                    return (
                                        <FormGroup key={i}>
                                            {setting.title === '' ? (
                                                <Input
                                                    type={'text'}
                                                    id={`outcomeTypesCustomizations[${i}].title`}
                                                    name={`outcomeTypesCustomizations[${i}].title`}
                                                    defaultValue={setting.title}
                                                    innerRef={register()}
                                                    style={{ width: '100px' }}
                                                />
                                            ) : (
                                                <>
                                                    <Label for={`outcomeTypesCustomizations[${i}].threshold`}>
                                                        {setting.title}
                                                    </Label>
                                                    <input
                                                        type="hidden"
                                                        name={`outcomeTypesCustomizations[${i}].title`}
                                                        defaultValue={setting.title}
                                                        ref={register()}
                                                    />
                                                </>
                                            )}
                                            <Input
                                                type={'text'}
                                                id={`outcomeTypesCustomizations[${i}].threshold`}
                                                name={`outcomeTypesCustomizations[${i}].threshold`}
                                                innerRef={register()}
                                                defaultValue={setting.threshold}
                                                style={{ width: '100px' }}
                                            />
                                            <input
                                                type="hidden"
                                                name={`outcomeTypesCustomizations[${i}].professionId`}
                                                defaultValue={setting.professionId}
                                                ref={register()}
                                            />
                                            <input
                                                type="hidden"
                                                name={`outcomeTypesCustomizations[${i}].id`}
                                                defaultValue={setting.id}
                                                ref={register()}
                                            />
                                            <input
                                                type="hidden"
                                                name={`outcomeTypesCustomizations[${i}].customId`}
                                                defaultValue={setting.customId}
                                                ref={register()}
                                            />
                                            <input
                                                type="hidden"
                                                name={`outcomeTypesCustomizations[${i}].outcomeTypeId`}
                                                defaultValue={setting.outcomeTypeId}
                                                ref={register()}
                                            />
                                            <input
                                                type="hidden"
                                                name={`outcomeTypesCustomizations[${i}].isActive`}
                                                defaultValue={setting.isActive}
                                                ref={register()}
                                            />
                                            <input
                                                type="hidden"
                                                name={`outcomeTypesCustomizations[${i}].concentrationIds`}
                                                defaultValue={setting.concentrationIds}
                                                ref={register()}
                                            />
                                            <input
                                                type="hidden"
                                                name={`outcomeTypesCustomizations[${i}].addOnTrackId`}
                                                defaultValue={setting.addOnTrackId}
                                                ref={register()}
                                            />
                                        </FormGroup>
                                    );
                                })}
                                <Row className="mt-3">
                                    <Col>
                                        <div>
                                            <Button type="submit">Save</Button>&nbsp;
                                            <Button outline onClick={() => setEditing(!editing)}>
                                                Cancel
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        ) : (
                            <>
                                {settings ? (
                                    <>
                                        <StyledSettingListItem>
                                            <div className="font-weight-bold">Active Year</div>
                                            <div className="setting-val">{settings.reportingWindow.activeYear}</div>
                                        </StyledSettingListItem>
                                        <StyledSettingListItem>
                                            <div className="font-weight-bold">Average Start Year</div>
                                            <div>{settings.reportingWindow.yearAvarageStart}</div>
                                        </StyledSettingListItem>
                                        <StyledSettingListItem>
                                            <div className="font-weight-bold">Reporting Window</div>
                                            <div>{settings.reportingWindow.reportingWindow + ' years'}</div>
                                        </StyledSettingListItem>
                                        <StyledSettingListItem>
                                            <div className="font-weight-bold">Annual Report Mailbox</div>
                                            <div>{settings.reportingWindow.annualReportMailbox}</div>
                                        </StyledSettingListItem>
                                        <hr style={{ borderColor: 'black', marginTop: '3rem', marginBottom: '3rem' }} />

                                        <StyledSettingListItem>
                                            <div className="font-weight-bold">Attrition/Retention Designation</div>
                                            <div>{settings.reportingWindow.outcomeMeasure}</div>
                                        </StyledSettingListItem>
                                        <StyledSettingListItem>
                                            <div className="font-weight-bold">Attrition/Retention Threshold</div>
                                            <div>{settings.reportingWindow.attritionRetentionThreshold}</div>
                                        </StyledSettingListItem>
                                        <StyledSettingListItem>
                                            <div className="font-weight-bold">Positive Placement Threshold</div>
                                            <div>{settings.reportingWindow.positivePlacementThreshold}</div>
                                        </StyledSettingListItem>
                                        <hr style={{ borderColor: 'black', marginTop: '3rem', marginBottom: '3rem' }} />

                                        <StyledSettingListItem>
                                            <div className="font-weight-bold">Survey Questions Label Type</div>
                                            <div>{settings.reportingWindow.questionLabelType}</div>
                                        </StyledSettingListItem>

                                        <hr style={{ borderColor: 'black', marginTop: '3rem', marginBottom: '3rem' }} />

                                        <h4>Employer Survey Settings</h4>

                                        <StyledSettingListItem>
                                            <div className="font-weight-bold">Cognitive Question Count</div>
                                            <div>{settings.reportingWindow.escognitiveQuestions ?? 'None'}</div>
                                        </StyledSettingListItem>

                                        <StyledSettingListItem>
                                            <div className="font-weight-bold">Psychomotor Question Count</div>
                                            <div>{settings.reportingWindow.espsychomotorQuestions ?? 'None'}</div>
                                        </StyledSettingListItem>

                                        <StyledSettingListItem>
                                            <div className="font-weight-bold">Affective Question Count</div>
                                            <div>{settings.reportingWindow.esaffectiveQuestions ?? 'None'}</div>
                                        </StyledSettingListItem>

                                        <StyledSettingListItem>
                                            <div className="font-weight-bold">Measure Type</div>
                                            <div>
                                                {settings.reportingWindow.employeeSurveyMeasureType ?? 'Returned'}
                                            </div>
                                        </StyledSettingListItem>

                                        <StyledSettingListItem>
                                            <div className="font-weight-bold">
                                                {settings.reportingWindow.employeeSurveyMeasureType} Threshold
                                            </div>
                                            <div>{settings.reportingWindow.employerSurveysThreshold ?? 'None'}</div>
                                        </StyledSettingListItem>

                                        <StyledSettingListItem>
                                            <div className="font-weight-bold">Satisfaction Threshold</div>
                                            <div>
                                                {settings.reportingWindow.employeeSurveySatisfactionThreshold ?? 'None'}
                                            </div>
                                        </StyledSettingListItem>

                                        <StyledSettingListItem>
                                            <div className="font-weight-bold">Cut Score</div>
                                            <div>{settings.reportingWindow.employeeSurveyCutScore ?? 'None'}</div>
                                        </StyledSettingListItem>

                                        <hr style={{ borderColor: 'black', marginTop: '3rem', marginBottom: '3rem' }} />

                                        <h4>Graduate Survey Settings</h4>

                                        <StyledSettingListItem>
                                            <div className="font-weight-bold">Cognitive Question Count</div>
                                            <div>{settings.reportingWindow.gscognitiveQuestions ?? 'None'}</div>
                                        </StyledSettingListItem>

                                        <StyledSettingListItem>
                                            <div className="font-weight-bold">Psychomotor Question Count</div>
                                            <div>{settings.reportingWindow.gspsychomotorQuestions ?? 'None'}</div>
                                        </StyledSettingListItem>

                                        <StyledSettingListItem>
                                            <div className="font-weight-bold">Affective Question Count</div>
                                            <div>{settings.reportingWindow.gsaffectiveQuestions ?? 'None'}</div>
                                        </StyledSettingListItem>

                                        <StyledSettingListItem>
                                            <div className="font-weight-bold">Measure Type</div>
                                            <div>
                                                {settings.reportingWindow.graduateSurveyMeasureType ?? 'Returned'}
                                            </div>
                                        </StyledSettingListItem>

                                        <StyledSettingListItem>
                                            <div className="font-weight-bold">
                                                {settings.reportingWindow.graduateSurveyMeasureType} Threshold
                                            </div>
                                            <div>{settings.reportingWindow.graduateSurveysThreshold ?? 'None'}</div>
                                        </StyledSettingListItem>

                                        <StyledSettingListItem>
                                            <div className="font-weight-bold">Satisfaction Threshold</div>
                                            <div>
                                                {settings.reportingWindow.graduateSurveySatisfactionThreshold ?? 'None'}
                                            </div>
                                        </StyledSettingListItem>

                                        <StyledSettingListItem>
                                            <div className="font-weight-bold">Cut Score</div>
                                            <div>{settings.reportingWindow.graduateSurveyCutScore ?? 'None'}</div>
                                        </StyledSettingListItem>

                                        <hr style={{ borderColor: 'black', marginTop: '3rem', marginBottom: '3rem' }} />

                                        {settings.outcomeTypesCustomizations?.map((setting, i) => {
                                            return (
                                                <StyledSettingListItem key={i}>
                                                    <div className="font-weight-bold">{setting.title}</div>
                                                    <div>{setting.threshold}</div>
                                                </StyledSettingListItem>
                                            );
                                        })}

                                        <Row className="mt-3">
                                            <Col>
                                                <Button onClick={() => setEditing(!editing)}>Edit</Button>
                                            </Col>
                                        </Row>
                                    </>
                                ) : (
                                    <div style={{ width: 'min-content' }}>
                                        <Skeleton count={15} width={300} />
                                    </div>
                                )}
                            </>
                        )}
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default GeneralSettingsPage;
