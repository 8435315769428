import { APIUriAms, http } from './HttpHelper';
import { AmsDocument, AuspiceDto, Institution, InstitutionDetail, InstitutionNameHistory, InstitutionPeopleDto, InstitutionTypeDto } from '../common/Types';

class InstitutionService {
    public getInstitution = async (id) => {
        const result = await http<Institution>(APIUriAms, `/institution/${id}`, undefined, undefined, null);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getInstitutionDetails = async (id) => {
        const result = await http<InstitutionDetail>(APIUriAms, `/institution/detail/${id}`, undefined, undefined, null);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getInstitutions = async () => {
        const result = await http<Institution[]>(APIUriAms, `/institution`, undefined, undefined, null);
        if (result.parsedBody) {
            return result.parsedBody.sort((a, b) => a.title.localeCompare(b.title));
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveInstitutions = async (institution: Institution) => {
        const result = await http<Institution>(
            APIUriAms,
            `/institution/${institution.institutionId}`,
            undefined,
            'POST',
            institution,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getInstitutionBillingContact = async (institutionId: number): Promise<InstitutionPeopleDto | null> => {
        const result = await http<InstitutionPeopleDto | null>(
            APIUriAms,
            `/institution/billing-contact/${institutionId}`);

        if (!result.parsedBody) {
            return null;
        } else {
            return result.parsedBody;
        }
    };

    public saveInstitutionPerson = async (person: InstitutionPeopleDto) => {
        const result = await http<InstitutionPeopleDto>(
            APIUriAms,
            `/institution/person`,
            undefined,
            'POST',
            person
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    }

    public removeInstitutionPerson = async (person: InstitutionPeopleDto) => {
        const result = await http<InstitutionPeopleDto>(
            APIUriAms,
            `/institution/person`,
            undefined,
            'DELETE',
            person
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    }

    public getTypes = async () => {
        const result = await http<InstitutionTypeDto[]>(APIUriAms, `/institution/types`, undefined, undefined, null);
        if (result.parsedBody) {
            return result.parsedBody.sort((a, b) => a.title.localeCompare(b.title));
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveTypes = async (types: InstitutionTypeDto[]) => {
        const result = await http<InstitutionTypeDto[]>(
            APIUriAms,
            `/institution/types`,
            undefined,
            'POST',
            types
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    }

    public getAuspice = async () => {
        const result = await http<AuspiceDto[]>(APIUriAms, `/institution/auspice`, undefined, undefined, null);
        if (result.parsedBody) {
            return result.parsedBody.sort((a, b) => a.title.localeCompare(b.title));
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveAuspice = async (auspice: AuspiceDto[]) => {
        const result = await http<AuspiceDto[]>(
            APIUriAms,
            `/institution/auspice`,
            undefined,
            'POST',
            auspice
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    }

    public getDocuments = async (institutionId: number): Promise<AmsDocument[]> => {
        const result = await http<AmsDocument[]>(APIUriAms, `/institution/${institutionId}/documents`, undefined, undefined, null);
        if (result.parsedBody) {
            return result.parsedBody.sort((a, b) => a.title.localeCompare(b.title));
        } else {
            throw new Error(result.statusText);
        }
    };

    public getNameHistory = async (
        institutionId: number
    ): Promise<InstitutionNameHistory[]> => {
        const result = await http<InstitutionNameHistory[]>(
            APIUriAms,
            `/institution/${institutionId}/name-history`);

        if (result.parsedBody) {
            return result
                .parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveNameHistory = async (
        nameHistory: InstitutionNameHistory
    ): Promise<InstitutionNameHistory> => {
        const result = await http<InstitutionNameHistory>(
            APIUriAms,
            `/institution/name-history`,
            undefined,
            'PUT',
            nameHistory);

        if (result.parsedBody) {
            return result
                .parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };
}

export const institutionService = new InstitutionService();
