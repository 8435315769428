import React from 'react';
import { RouteItem } from '../../router/Routes';
import { Nav } from '../../components';

type Props = {
    routes: RouteItem[];
    id?: string;
};

const ProfessionSubNav = (props: Props) => {
    const mappedRoutes = props.routes
        .filter((r) => r.hasPermission === undefined || r.hasPermission === true)
        .map((route) => {
            return { ...route, path: route.path.replace(':id', props.id || '') };
        });
    return <Nav className={'nav-tabs nav-bordered-vertical'} vertical routes={[...mappedRoutes]} />;
};

export default ProfessionSubNav;
