import React from 'react';
import { useForm } from 'react-hook-form';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { invoiceService } from '../../services/InvoiceService';
import { Button } from '../../components';

interface EmailInvoiceFormProps {
    invoiceId: number;
    onSave: () => void;
    onCancel: () => void;
}

const EmailInvoiceForm = (props: EmailInvoiceFormProps) => {
    const schema: yup.ObjectSchema<{ altEmail: string | null | undefined }> = yup
        .object({
            altEmail: yup.string().notRequired().nullable(),
        })
        .defined();

    const { handleSubmit, register } = useForm({
        validationSchema: schema,
    });

    const onSubmit = (formData) => {
        const toastId = toast.info('Emailing Invoice...');

        invoiceService
            .emailInvoices([props.invoiceId], formData.altEmail ? formData.altEmail : null)
            .then(() => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Invoice emailed successfuly',
                });
                props.onSave();
            })
            .catch((e) => {
                if (e.error) {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Error emailing Invoice',
                    });
                } else {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Error emailing Invoice',
                    });
                }
            });
    };
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
                <Label for="altEmail">Alternative Email</Label>
                <Input name="altEmail" innerRef={register()} />
            </FormGroup>
            <div>
                <Button type="button" color={'link'} onClick={props.onCancel}>
                    Cancel
                </Button>
                <Button type="submit">Send</Button>
            </div>
        </Form>
    );
};

export default EmailInvoiceForm;
