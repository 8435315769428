import { http, ApiUriIdentity } from './HttpHelper';
import { isArray } from 'util';

interface ITokenRequestResult {
    access_token: string;
    expires_in: number;
    token_type: string;
    refresh_token: string;
    scope: string;
}

export interface IUserInfo {
    sub: string;
    name: string;
    email: string;
    address: string;
    phone: string;
}

export interface IPasswordResetRequestForm {
    username: string;
}

export interface IPasswordResetCompleteForm {
    username: string;
    token: string;
    password: string;
    confirmPassword: string;
}

export interface IChangePasswordForm {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export const IdentityPermissionClaimType: string = "IdentityServerApi:permission";
export const ArmsPermissionClaimType: string = "caahep-ams-arms-api:permission";
export const AmsPermissionClaimType: string = "caahep-ams-ams-api:permission";

class AuthService {
    private tokenRenewalTimeout: any;

    public authenticated(): boolean {
        const expiresAt = JSON.parse(localStorage.getItem('expires_at')!);
        return new Date().getTime() < expiresAt;
    }

    public login = async (creds): Promise<IUserInfo | null> => {
        const loginResult = await http<ITokenRequestResult>(
            ApiUriIdentity,
            '/connect/token',
            'application/x-www-form-urlencoded',
            'POST',
            `grant_type=password&client_id=caahep-ams-client&username=${encodeURIComponent(creds.email)}&password=${encodeURIComponent(creds.password)}`
        );

        if (loginResult.parsedBody) {
            this.setSession(loginResult.parsedBody);

            const userInfoResult = await http<IUserInfo>(
                ApiUriIdentity,
                '/connect/userinfo',
                'application/x-www-form-urlencoded',
                'POST',
                ``
            );

            if (userInfoResult.parsedBody) {
                this.persistUserProfile(userInfoResult.parsedBody);
                return userInfoResult.parsedBody;
            }
        } else {
            throw new Error(loginResult.statusText);
        }

        return null;
    };

    public logout = (): void => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('scope');
        localStorage.removeItem('profile');
        localStorage.removeItem('expires_at');
    }

    public userHasScopes = (scopes: string[]): boolean => {
        const grantedScopes = JSON.parse(localStorage.getItem('scope')!).split(
            ' ',
        );
        return scopes.every(scope => grantedScopes.includes(scope));
    }

    public getProfile = (): IUserInfo | null => {
        const profile = localStorage.getItem('profile');

        if (profile) {
            return JSON.parse(profile);
        }

        return null;
    }

    public validateInitialAuthentication = (): boolean => {
        const isAuthenticated = this.authenticated();

        if (isAuthenticated) {
            this.scheduleRenewal();
        }

        return isAuthenticated;
    }

    public requestPasswordReset = async (values: IPasswordResetRequestForm) => {
        await http<string>(
            ApiUriIdentity,
            '/account/passwordreset/request',
            undefined,
            'POST',
            values
        );
    }

    public completePasswordReset = async (values: IPasswordResetCompleteForm) => {
        await http<string>(
            ApiUriIdentity,
            '/account/passwordreset/complete',
            undefined,
            'POST',
            values
        );
    }

    public changePassword = async (values: IChangePasswordForm) => {
        await http<string>(
            ApiUriIdentity,
            '/account/changepassword',
            undefined,
            'POST',
            values
        );
    }

    public hasPermission = (permissionClaimType: string, claimValue: string): Boolean => {
        if (this.authenticated()) {
            const token = this.parseJwt(localStorage.getItem('access_token'));

            if (token[permissionClaimType]) {
                if (isArray(token[permissionClaimType])) {
                    return token[permissionClaimType].filter(ct => ct === claimValue).length > 0;
                } else {
                    return token[permissionClaimType] === claimValue;
                }
            }
        }

        return false;
    }

    public getCoaIdList = (): number[] => {
        if (this.authenticated()) {
            const token = this.parseJwt(localStorage.getItem('access_token'));

            if (token["CoaId"]) {
                if (isArray(token["CoaId"])) {
                    return token["CoaId"]
                        .map((c) => {
                            return Number(c);
                        });
                } else {
                    return [
                        Number(token["CoaId"])
                    ];
                }
            }

            return [];
        }

        throw Error('Not authenticated');
    }

    private persistUserProfile = (user: IUserInfo): void => {
        localStorage.setItem('profile', JSON.stringify(user));
    }

    private setSession = (tokenDetail: ITokenRequestResult): void => {
        localStorage.setItem('access_token', tokenDetail.access_token);
        localStorage.setItem('refresh_token', tokenDetail.refresh_token);
        localStorage.setItem('scope', tokenDetail.scope);

        const expiresAt = JSON.stringify(tokenDetail.expires_in * 1000 + new Date().getTime());
        localStorage.setItem('expires_at', expiresAt);

        this.scheduleRenewal();
    };

    private renewToken = async (): Promise<void> => {
        const refreshToken = localStorage.getItem('refresh_token');

        if (refreshToken) {
            const renewalResult = await http<ITokenRequestResult>(
                ApiUriIdentity,
                '/connect/token',
                'application/x-www-form-urlencoded',
                'POST',
                `grant_type=refresh_token&client_id=caahep-ams-client&refresh_token=${refreshToken}`
            );

            if (renewalResult.parsedBody) {
                this.setSession(renewalResult.parsedBody);
            }
        }

        return;
    }

    private scheduleRenewal = (): void => {
        const expiresAt = JSON.parse(localStorage.getItem('expires_at')!);
        const delay = (expiresAt - Date.now() - (60 * 1000));

        if (delay > 0) {
            this.tokenRenewalTimeout = window.setTimeout(() => this.renewToken(), delay);
        }
    }

    private parseJwt = (token): any => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    };
}

export const authService = new AuthService();
