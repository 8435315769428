import * as yup from 'yup';

import { Button, Col, Form, FormGroup, Label, Row } from 'reactstrap';

import { CredentialingExamProgramResult } from '../common/Types';
import { Input } from '.';
import React from 'react';
import { programService } from '../services/ProgramService';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

const FlexFormGroup = styled(FormGroup)`
    display: flex;
    justify-content: start;
    align-items: center;
    & label {
        flex-basis: calc(75% - 10px);
    }
    & div,
    & span {
        flex-basis: calc(25% - 10px);
    }
`;

interface ExamFormProps {
    result: CredentialingExamProgramResult;
    totalGraduates: number;
    onResultUpdated: (outcome: CredentialingExamProgramResult) => void;
    onCancel: () => void;
}

const ExamForm = (props: ExamFormProps) => {
    const { result, totalGraduates, onResultUpdated, onCancel } = props;

    const resultSchema: yup.ObjectSchema<CredentialingExamProgramResult> = yup
        .object({
            id: yup.number().required().default(result.id),
            outcomeTypeId: yup.number().required().default(result.outcomeTypeId),
            programId: yup.number().required().default(result.programId),
            graduatingYear: yup.number().required().default(result.graduatingYear),
            numberOfGradsAttemptingExam: yup
                .number()
                .min(0, 'Must be 0 or more')
                .max(totalGraduates, `Must be at or less than # Graduates ${totalGraduates}`)
                .required()
                .nullable()
                .default(0),
            passing1stAttempt: yup.number().required().min(0, 'Must be 0 or more').nullable().default(0),
            passingSubsequentAttempts: yup.number().min(0, 'Must be 0 or more').required().nullable().default(0),
            reportYear: yup.number().required().default(result.reportYear),
            total: yup
                .number()
                .when(
                    ['numberOfGradsAttemptingExam', 'passing1stAttempt', 'passingSubsequentAttempts'],
                    (
                        numberOfGradsAttemptingExam: number,
                        passing1stAttempt: number,
                        passingSubsequentAttempts: number,
                        schema,
                    ) => {
                        if (passing1stAttempt + passingSubsequentAttempts > numberOfGradsAttemptingExam) {
                            return schema
                                .default(passing1stAttempt + passingSubsequentAttempts)
                                .max(
                                    numberOfGradsAttemptingExam,
                                    `Total passing graduates cannot exceed ${numberOfGradsAttemptingExam}`,
                                );
                        }
                    },
                ),
        })
        .defined();

    const {
        register,
        handleSubmit,
        errors,
        formState: { isSubmitting },
    } = useForm({
        validationSchema: resultSchema,
        defaultValues: result,
    });

    const onSubmit = (values: CredentialingExamProgramResult): Promise<void> => {
        return new Promise((res) => {
            const toastId = toast.info('Saving exam results...');

            programService
                .saveProgramCredentialingExams(values)
                .then((result) => {
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Exam results saved',
                    });

                    onResultUpdated(result);
                })
                .catch(() => {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Error saving exam results',
                    });
                })
                .finally(() => {
                    res();
                });
        });
    };

    console.log(errors);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col className={'d-flex flex-column justify-content-between'}>
                    <FlexFormGroup>
                        <Label>{`# Graduates`}</Label>
                        <span>{totalGraduates}</span>
                    </FlexFormGroup>
                    <FlexFormGroup>
                        <Label>{`# Graduates Attempted`}</Label>
                        <div>
                            <Input type={'number'} innerRef={register()} name={`numberOfGradsAttemptingExam`} />
                            {errors?.numberOfGradsAttemptingExam?.message && (
                                <Label className={'text-danger'}>{errors.numberOfGradsAttemptingExam.message}</Label>
                            )}
                        </div>
                    </FlexFormGroup>
                    <FlexFormGroup>
                        <Label>{`# Graduates Passed - 1st Attempt`}</Label>
                        <div>
                            <Input type={'number'} innerRef={register()} name={`passing1stAttempt`} />
                            {errors?.passing1stAttempt?.message && (
                                <Label className={'text-danger'}>{errors.passing1stAttempt.message}</Label>
                            )}
                        </div>
                    </FlexFormGroup>
                    <FlexFormGroup>
                        <Label>{`# Graduates Passed - Subsequent Attempts`}</Label>
                        <div>
                            <Input type={'number'} innerRef={register()} name={`passingSubsequentAttempts`} />
                            {errors?.passingSubsequentAttempts?.message && (
                                <Label className={'text-danger'}>{errors.passingSubsequentAttempts.message}</Label>
                            )}
                        </div>
                    </FlexFormGroup>
                </Col>
            </Row>
            {errors?.total?.message && (
                <Row className={'mt-2'}>
                    <Col>
                        <Label className={'text-danger'}>{errors?.total?.message}</Label>
                    </Col>
                </Row>
            )}
            <Row className={'mt-2'}>
                <Col>
                    <Button disabled={isSubmitting} type={'submit'} color={'primary'}>{`Submit`}</Button>
                    <Button
                        type={'button'}
                        color={'secondary'}
                        className={'ml-3'}
                        onClick={() => onCancel()}
                    >{`Cancel`}</Button>
                </Col>
            </Row>
        </Form>
    );
};

export default ExamForm;
