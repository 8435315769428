import { CreateFolder, CreateHubDocument, Folder, HubDocument, RemoveFolder, RemoveHubDocument, UpdateFolder, UserManagement } from '../common/Types';
import { APIUriAms, ApiUriIdentity, http, httpDownloadFile, httpFile } from './HttpHelper';

class HubService {
    public getSasUri = async (): Promise<string> => {
        const result = await http<string>(
            APIUriAms,
            `/hub/token`,
            undefined,
            undefined,
            null,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getAllFolders = async (): Promise<Folder[]> => {
        const result = await http<Folder[]>(
            APIUriAms,
            `/hub/folders`,
            undefined,
            undefined,
            null,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getFolderBySlug = async (
        slug: string,
    ): Promise<Folder> => {
        const result = await http<Folder | undefined>(
            APIUriAms,
            `/hub/folder/${slug}`,
            undefined,
            undefined,
            null,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public createFolder = async (
        newFolder: CreateFolder,
    ): Promise<Folder> => {
        const result = await http<Folder>(
            APIUriAms,
            `/hub/folder`,
            undefined,
            'POST',
            newFolder,
        );

        if (result.status === 204) {
            throw new Error(`A folder already exists within this folder with the same name.`);
        } else if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    }

    public createDocument = async (
        document: CreateHubDocument,
    ): Promise<HubDocument> => {
        const result = await http<HubDocument>(
            APIUriAms,
            `/hub/hubdocument`,
            undefined,
            'POST',
            document,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    }

    public updateFolder = async (
        folder: UpdateFolder,
    ): Promise<Folder> => {
        const result = await http<Folder>(
            APIUriAms,
            `/hub/folder`,
            undefined,
            'PATCH',
            folder,
        );

        if (result.status === 204) {
            throw new Error(`A folder already exists with the same name, within the same folder.`);
        } else if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    }

    public removeFolder = async (
        folder: RemoveFolder,
    ): Promise<Folder> => {
        const result = await http<Folder>(
            APIUriAms,
            `/hub/folder`,
            undefined,
            'DELETE',
            folder,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    }

    public removeHubDocument = async (
        document: RemoveHubDocument,
    ): Promise<HubDocument> => {
        const result = await http<HubDocument>(
            APIUriAms,
            `/hub/hubdocument`,
            undefined,
            'DELETE',
            document,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    }

    public hubDocumentBySlugPublic = async (
        slug: string,
    ): Promise<HubDocument> => {
        const result = await http<HubDocument>(
            APIUriAms,
            `/hub/public/document/byslug/${slug}`,
            undefined,
            undefined,
            null,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public downloadHubDocument = async (
        hubDocumentId: number,
    ): Promise<Blob | undefined> => {
        const result = await httpDownloadFile(
            APIUriAms,
            `/hub/public/${hubDocumentId}`,
            undefined,
            "GET",
        );

        if (result.ok) {
            try {
                return result.parsedBody;
            } catch (ex) {
                throw ex;
            }
        } else {
            throw Error(result.statusText);
        }
    };

    public searchHubUsers = async (
    ): Promise<UserManagement[]> => {
        const result = await http<UserManagement[]>(
            ApiUriIdentity,
            `/usermanagement/hub`,
            undefined,
            undefined,
            null,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };
}

export const hubService = new HubService();
