import React, { useState, useEffect } from 'react';
import {
    Card,
    CardBody,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Form,
    FormGroup,
} from 'reactstrap';
import { ProfessionStandardsDesignationDto } from '../../../common/Types';
import styled from 'styled-components';
import { Input, Button } from '../../../components';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { professionService } from '../../../services/ProfessionAdministration';
import SubStandardsCard from './SubStandardsCard';

const FlexRow = styled.div`
    display: flex;
    padding-top: 8px;
    padding-bottom: 8px;
    border-top: 1px solid #dee2e6;

    > div {
        line-height: 40px;
        height: 40px;
    }
`;

const EditRow = styled.div`
    border-top: 1px solid #dee2e6;
    padding-top: 16px;
    padding-bottom: 8px;
`;

type Props = {
    designations: ProfessionStandardsDesignationDto[] | undefined;
    professionId: number;
};

const DesignationCard = (props: Props) => {
    const [designations, setDesignations] = useState<ProfessionStandardsDesignationDto[]>([]);
    // track which button dropdown should be open by standardsDesignationId
    const [dropdownOpenId, setDropdownOpenId] = useState<number>();
    // track which edit form should be open by standardsDesignationId
    const [editId, setEditId] = useState<number>();
    const [deletedId, setDeleteId] = useState<number>();
    // track which designation is showing substandards
    const [designationWSubStandards, setDesignationWSubStandards] = useState<ProfessionStandardsDesignationDto>();

    const versionSchema: yup.ObjectSchema<ProfessionStandardsDesignationDto> = yup
        .object({
            standardsDesignationId: yup.number().required().default(0),
            professionId: yup.number().required(),
            designationNumber: yup.string().required(),
            designationTitle: yup.string().required(),
            designationDescription: yup.string().required(),
            createdBy: yup.string(),
            dateCreated: yup.string(),
            lastEdited: yup.string(),
            editedBy: yup.string(),
            isDeleted: yup.boolean().required().default(false),
            createdByString: yup.string(),
            editedByString: yup.string(),
        })
        .defined();

    const {
        handleSubmit,
        register,
        reset,
        formState: { isSubmitting },
    } = useForm({
        validationSchema: versionSchema,
        defaultValues: {} as ProfessionStandardsDesignationDto,
    });

    const toggleButtonDropdown = (id: number) => {
        if (dropdownOpenId) setDropdownOpenId(undefined);
        else setDropdownOpenId(id);
    };

    const onSubmit = (formData: ProfessionStandardsDesignationDto): Promise<void> => {
        return new Promise((res) => {
            const toastId = toast.info('Saving Standard Designations...');

            if (formData.standardsDesignationId === deletedId) {
                formData.isDeleted = true;
                setDeleteId(undefined);
            }

            professionService
                .saveStandardDesignations([formData])
                .then((response) => {
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Standard Designations saved successfuly',
                    });
                    reset();
                    setEditId(undefined);
                    const replace = designations
                        .map((x) => {
                            if (x.standardsDesignationId === formData.standardsDesignationId) {
                                return formData.isDeleted ? ({} as ProfessionStandardsDesignationDto) : response[0];
                            } else {
                                return x;
                            }
                        })
                        .filter((y) => y.standardsDesignationId !== undefined);
                    setDesignations(replace);
                    res();
                })
                .catch(() => {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Error saving Standard Designations',
                    });
                    res();
                });
        });
    };

    const addDesignation = () => {
        setDesignations([
            {
                standardsDesignationId: 0,
                professionId: props.professionId,
            } as ProfessionStandardsDesignationDto,
            ...designations,
        ]);
        setEditId(0);
    };

    const deleteDesignation = (des, index) => {
        // If it is a new designation just remove it from the list
        if (des.standardsDesignationId) setDeleteId(des.standardsDesignationId);
        else {
            // Delete by index in case use has added multiple
            setDesignations(designations.filter((x, i) => i !== index));
        }
    };

    useEffect(() => {
        if (props.designations?.length) {
            setDesignations(props.designations);
        }
    }, [props.designations, setDesignations]);

    const romanTable = {
        I: 1,
        II: 2,
        III: 3,
        IV: 4,
        V: 5,
        VI: 6,
        VII: 7,
        VIII: 8,
        IX: 9,
        X: 10,
        XI: 11,
        XII: 12,
    };

    return (
        <>
            {designationWSubStandards !== undefined ? (
                <SubStandardsCard
                    designation={designationWSubStandards}
                    close={() => setDesignationWSubStandards(undefined)}
                />
            ) : (
                <Card>
                    <CardBody>
                        <div style={{ display: 'flex' }} className="mb-3">
                            <div style={{ flex: 1 }}>
                                <h5>Designations</h5>
                            </div>
                            <div>
                                <Button onClick={() => addDesignation()}>Add Designation</Button>
                            </div>
                        </div>
                        <div>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                {designations &&
                                    designations
                                        .sort((a, b) => {
                                            const [mainA, secondaryA, tertiaryA, quaternaryA] =
                                                a.designationNumber?.split('.') || [];
                                            const [mainB, secondaryB, tertiaryB, quaternaryB] =
                                                b.designationNumber?.split('.') || [];

                                            if (mainA !== mainB) return romanTable[mainA] - romanTable[mainB];
                                            if (secondaryA !== secondaryB) return secondaryA?.localeCompare(secondaryB);
                                            if (tertiaryA !== tertiaryB) return tertiaryA?.localeCompare(tertiaryB);

                                            return quaternaryA?.localeCompare(quaternaryB);
                                        })
                                        .map((designation, i) => {
                                            return (
                                                <div key={i}>
                                                    {designation.standardsDesignationId === editId ? (
                                                        <EditRow>
                                                            <div style={{ display: 'flex' }}>
                                                                <FormGroup>
                                                                    <Input
                                                                        placeholder="Number"
                                                                        name="designationNumber"
                                                                        innerRef={register()}
                                                                        defaultValue={designation.designationNumber}
                                                                    />
                                                                </FormGroup>
                                                                &nbsp;
                                                                <FormGroup style={{ flex: 1 }}>
                                                                    <Input
                                                                        placeholder="Title"
                                                                        name="designationTitle"
                                                                        innerRef={register()}
                                                                        defaultValue={designation.designationTitle}
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <div>
                                                                <FormGroup>
                                                                    <Input
                                                                        placeholder="Description"
                                                                        name="designationDescription"
                                                                        type="textarea"
                                                                        innerRef={register()}
                                                                        defaultValue={
                                                                            designation.designationDescription
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                            <input
                                                                type="hidden"
                                                                name={`standardsDesignationId`}
                                                                defaultValue={designation.standardsDesignationId}
                                                                ref={register()}
                                                            />
                                                            <input
                                                                type="hidden"
                                                                name={`professionId`}
                                                                defaultValue={designation.professionId}
                                                                ref={register()}
                                                            />
                                                            <input
                                                                type="hidden"
                                                                name={`createdBy`}
                                                                defaultValue={designation.createdBy}
                                                                ref={register()}
                                                            />
                                                            <input
                                                                type="hidden"
                                                                name={`dateCreated`}
                                                                defaultValue={designation.dateCreated}
                                                                ref={register()}
                                                            />
                                                            <input
                                                                type="hidden"
                                                                name={`lastEdited`}
                                                                defaultValue={designation.lastEdited}
                                                                ref={register()}
                                                            />
                                                            <input
                                                                type="hidden"
                                                                name={`editedBy`}
                                                                defaultValue={designation.editedBy}
                                                                ref={register()}
                                                            />
                                                            <input
                                                                type="hidden"
                                                                name={`isDeleted`}
                                                                defaultValue={
                                                                    designation.isDeleted?.toString() ?? 'false'
                                                                }
                                                                ref={register()}
                                                            />
                                                            <input
                                                                type="hidden"
                                                                name={`createdByString`}
                                                                defaultValue={designation.createdByString}
                                                                ref={register()}
                                                            />
                                                            <input
                                                                type="hidden"
                                                                name={`editedByString`}
                                                                defaultValue={designation.editedByString}
                                                                ref={register()}
                                                            />
                                                            <div style={{ display: 'flex' }}>
                                                                <div style={{ flex: 1 }}>
                                                                    <Button
                                                                        color="primary"
                                                                        type="submit"
                                                                        className={'mr-1'}
                                                                        disabled={isSubmitting}
                                                                    >
                                                                        Save
                                                                    </Button>
                                                                    {designation.standardsDesignationId !== 0 && (
                                                                        <Button
                                                                            outline
                                                                            onClick={() => setEditId(undefined)}
                                                                        >
                                                                            Cancel
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    {deletedId ===
                                                                    designation.standardsDesignationId ? (
                                                                        <p className="text-danger">
                                                                            Click Save to delete
                                                                        </p>
                                                                    ) : (
                                                                        <Button
                                                                            color="danger"
                                                                            onClick={() =>
                                                                                deleteDesignation(designation, i)
                                                                            }
                                                                        >
                                                                            Delete Designation
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </EditRow>
                                                    ) : (
                                                        <FlexRow title={designation.designationDescription}>
                                                            <div style={{ flex: 1 }}>
                                                                {designation.designationNumber}
                                                            </div>
                                                            <div style={{ flex: 2 }}>
                                                                {designation.designationTitle}
                                                            </div>
                                                            <div className="text-right">
                                                                <ButtonDropdown
                                                                    className="text-left"
                                                                    isOpen={
                                                                        designation.standardsDesignationId ===
                                                                        dropdownOpenId
                                                                    }
                                                                    toggle={() =>
                                                                        toggleButtonDropdown(
                                                                            designation.standardsDesignationId,
                                                                        )
                                                                    }
                                                                    direction="left"
                                                                >
                                                                    <DropdownToggle caret color="info">
                                                                        <i className={'remixicon-more-2-fill'}></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu>
                                                                        <DropdownItem
                                                                            onClick={() =>
                                                                                setDesignationWSubStandards(designation)
                                                                            }
                                                                        >
                                                                            Sub Standards
                                                                        </DropdownItem>
                                                                        <DropdownItem
                                                                            onClick={() =>
                                                                                setEditId(
                                                                                    designation.standardsDesignationId,
                                                                                )
                                                                            }
                                                                        >
                                                                            Edit
                                                                        </DropdownItem>
                                                                    </DropdownMenu>
                                                                </ButtonDropdown>
                                                            </div>
                                                        </FlexRow>
                                                    )}
                                                </div>
                                            );
                                        })}
                            </Form>
                        </div>
                    </CardBody>
                </Card>
            )}
        </>
    );
};

export default DesignationCard;
