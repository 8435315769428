import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Switch, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RouteItem } from '../../router/Routes';
import { professionService } from '../../services/ProfessionAdministration';
import { PageTitle } from '../../components';
import { ProfessionDto } from '../../common/Types';
import ProfessionSubNav from './ProfessionSubNav';
import CoaRoute from '../../router/CoaRouter';

const Profession = ({ routes }: { routes: RouteItem[] }) => {
    const { id } = useParams();
    const [coa, setCoa] = useState<ProfessionDto>();

    useEffect(() => {
        const getCoa = () => {
            professionService
                .getProfesssion(id)
                .then((response) => {
                    setCoa(response);
                })
                .catch(() => {
                    toast.error('Failed to get professions.');
                });
        };

        getCoa();
    }, [setCoa, id]);

    return (
        <>
            <PageTitle title={`Profession | ${coa ? coa.title : ''}`} />

            <Row>
                <Col md={4} xl={3}>
                    <Card>
                        <CardBody>
                            <ProfessionSubNav id={id} routes={routes} />
                        </CardBody>
                    </Card>
                </Col>
                <Switch>{coa && routes.map((route, i) => <CoaRoute key={i} {...route} coa={{ ...coa }} />)}</Switch>
            </Row>
        </>
    );
};

export default Profession;
