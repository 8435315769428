import React from 'react';
import { Input, IconButton } from './';
import styled from 'styled-components';

const InputRow = styled.div`
    div {
        display: inline-block;
        vertical-align: top;

        i {
            line-height: 38px;
            font-size: 24px;
        }
    }
`;

const InputListItem = (props) => {
    return (
        <InputRow className="mb-1">
            <div>
                <Input
                    defaultValue={`${props.value}`}
                    name={`expenditures[${props.index}].value`}
                    innerRef={props.register()}
                />
            </div>
            <div>
                <IconButton
                    iconClass="remixicon-subtract-line "
                    buttonProps={{ onClick: () => props.remove(props.index) }}
                />
            </div>
        </InputRow>
    );
};

export default InputListItem;
