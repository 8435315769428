import { Button, Input } from '.';
import {
    ButtonGroup,
    Card,
    CardBody,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Table,
} from 'reactstrap';
import { NoteCreateDto, NoteDto } from '../common/Types';
import React, { useEffect, useState } from 'react';

import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import { noteService } from '../services/NoteService';
import { toast } from 'react-toastify';
import { AmsPermissionClaimType, authService } from '../services/Auth';

interface NotesListProps {
    institutionId?: number;
    programId?: number;
}

const NotesList = (props: NotesListProps) => {
    const { institutionId, programId } = props;

    const [notes, setNotes] = useState<NoteDto[]>();
    const [reloadNotes, setReloadNotes] = useState<boolean>(true);

    const [noteEdit, setNoteEdit] = useState<NoteDto>();

    const [showModal, setShowModal] = useState<boolean>(false);
    const toggleModal = () => setShowModal(!showModal);

    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const toggleRemoveModal = () => setShowRemoveModal(!showRemoveModal);

    useEffect(() => {
        const getNotes = () => {
            if (institutionId && !programId && reloadNotes) {
                setReloadNotes(false);
                noteService
                    .getProgramNotesByInstitutionId(institutionId)
                    .then((result) => {
                        setNotes([...result.filter((n) => n.active === true)]);
                    })
                    .catch(() => {
                        toast.error('Error loading notes');
                    });
            }
            if (!institutionId && programId && reloadNotes) {
                setReloadNotes(false);
                noteService
                    .getProgramNotesByProgramId(programId)
                    .then((result) => {
                        setNotes([...result.filter((n) => n.active === true)]);
                    })
                    .catch(() => {
                        toast.error('Error loading notes');
                    });
            }
        };
        getNotes();
    }, [programId, setNotes, reloadNotes]);

    const startNewNote = () => {
        setNoteEdit({
            noteId: 0,
            programId: programId,
            institutionId: institutionId,
            notes: '',
            active: true,
        } as NoteCreateDto);
        toggleModal();
    };

    const startEditNote = (note: NoteDto) => {
        setNoteEdit({
            ...note,
        });
        toggleModal();
    };

    const startRemoveNote = (note: NoteDto) => {
        setNoteEdit({
            ...note,
            active: false,
        });
        toggleRemoveModal();
    };

    const saveNote = () => {
        if (noteEdit && noteEdit.notes) {
            const toastId = toast.info('Saving note...');

            noteService
                .saveProgramNote([noteEdit])
                .then(() => {
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Note saved successfully',
                    });

                    setReloadNotes(true);
                    setShowModal(false);
                    setShowRemoveModal(false);
                })
                .catch(() => {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Error saving note',
                    });
                });
        }
        if (noteEdit && !noteEdit.notes) {
            toast.error('Note content is required');
        }
    };

    const parseCreatedByString = (createdBy?: string): string => {
        if (!createdBy) {
            return 'Unknown';
        }

        try {
            const parsed = JSON.parse(createdBy);

            return parsed?.name || `Unknown`;
        } catch {
            return `Unknown`;
        }
    };

    return (
        <>
            <Card className={'d-flex flex-grow-1'}>
                <CardBody className={'pb-0'}>
                    <h4 className={'card-title d-flex align-items-center justify-content-between'}>
                        <span>{`Notes`}</span>
                        {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') && (
                            <div>
                                <Button
                                    type={'button'}
                                    color={'primary'}
                                    className={'btn-icon'}
                                    onClick={() => startNewNote()}
                                >
                                    <i className={`mdi mdi-plus`} />
                                </Button>
                            </div>
                        )}
                    </h4>
                    {!notes && <Skeleton count={5} />}
                    {notes && notes.length <= 0 && <Label>{`No Notes`}</Label>}
                </CardBody>
                {notes && notes.length > 0 && (
                    <div className={'table-responsive'} style={{ maxHeight: '350px', overflow: 'auto' }}>
                        <Table hover className={'mb-0'}>
                            <thead>
                                <tr>
                                    <th>{`Created On`}</th>
                                    <th>{`By`}</th>
                                    <th>{`Note`}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {notes.map((t) => (
                                    <tr key={t.noteId}>
                                        <td>
                                            {t.dateCreated
                                                ? moment.utc(t.dateCreated).local().format('MM/DD/YYYY h:mm A')
                                                : ''}
                                        </td>
                                        <td>{parseCreatedByString(t.createdByString)}</td>
                                        <td>
                                            <span>{t.notes}</span>
                                        </td>
                                        <td className={'text-right'}>
                                            {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') && (
                                                <ButtonGroup>
                                                    <Button
                                                        type={'button'}
                                                        color={'info'}
                                                        className={'btn-sm'}
                                                        onClick={() => startEditNote(t)}
                                                    >
                                                        <i className={'mdi mdi-pencil'} />
                                                    </Button>
                                                    <Button
                                                        type={'button'}
                                                        color={'danger'}
                                                        className={'btn-sm'}
                                                        onClick={() => startRemoveNote(t)}
                                                    >
                                                        <i className={'mdi mdi-delete'} />
                                                    </Button>
                                                </ButtonGroup>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}
            </Card>

            {noteEdit && (
                <>
                    <Modal isOpen={showModal} toggle={toggleModal} centered={true} size={'lg'}>
                        <ModalHeader>{noteEdit.noteId > 0 ? `Edit Note` : `New Note`}</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label>{`Note`}</Label>
                                <Input
                                    type={'textarea'}
                                    rows={10}
                                    value={noteEdit.notes}
                                    onChange={(e) => setNoteEdit({ ...noteEdit, notes: e.target.value })}
                                />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color={'primary'}
                                className={'mr-2'}
                                type={'button'}
                                onClick={() => saveNote()}
                            >{`Submit`}</Button>
                            <Button color={'link'} onClick={() => toggleModal()} type={'button'}>{`Cancel`}</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={showRemoveModal} toggle={toggleRemoveModal} centered={true}>
                        <ModalHeader>{`Remove Note`}</ModalHeader>
                        <ModalBody>
                            <p>{`Click Confirm below to complete the note removal.`}</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color={'primary'}
                                className={'mr-2'}
                                type={'button'}
                                onClick={() => saveNote()}
                            >{`Submit`}</Button>
                            <Button
                                color={'link'}
                                onClick={() => toggleRemoveModal()}
                                type={'button'}
                            >{`Cancel`}</Button>
                        </ModalFooter>
                    </Modal>
                </>
            )}
        </>
    );
};

export default NotesList;
