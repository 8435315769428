import React, { useState, useEffect } from 'react';
import PageTitle from '../../components/PageTitle';
import { InstitutionTypeDto } from '../../common/Types';
import { institutionService } from '../../services/InstitutionService';
import { toast } from 'react-toastify';
import { Row, Col, Card, CardBody, FormGroup, Form } from 'reactstrap';
import * as yup from 'yup';
import { Input, Button } from '../../components';
import { useForm, useFieldArray } from 'react-hook-form';

interface Types {
    types: InstitutionTypeDto[];
    deletedTypes: InstitutionTypeDto[];
}

const schema: yup.ObjectSchema<InstitutionTypeDto> = yup
    .object({
        institutionTypeId: yup.number().required().default(0),
        title: yup.string().required(),
        isActive: yup.boolean().required().default(true),
    })
    .defined();

const listSchema = yup.object().shape({
    types: yup.array().of(schema),
    deletedTypes: yup.array().of(schema),
});

const InstitutionTypes = () => {
    const [types, setTypes] = useState<InstitutionTypeDto[]>([]);
    const [deletedTypes, setDeletedTypes] = useState<InstitutionTypeDto[]>([]);
    const [editing, setEditing] = useState(false);

    const { control, handleSubmit, register, reset } = useForm({
        validationSchema: listSchema,
        defaultValues: { types: [], deletedTypes: [] } as Types,
    });

    const { fields: activeFields, append, remove } = useFieldArray({
        control,
        name: 'types',
    });

    const { fields: deletedFields, append: deletedAppend, remove: deletedRemove } = useFieldArray({
        control,
        name: 'deletedTypes',
    });

    const deleteTypes = (insType, i) => {
        remove(i);
        insType.isActive = false;
        deletedAppend(insType);
    };

    const onSubmit = (formData: Types) => {
        const toastId = toast.info('Saving Institution Types...');
        const saveObj = [...formData.types, ...(formData.deletedTypes ?? [])];

        institutionService
            .saveTypes(saveObj)
            .then((response) => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Institution Types saved successfuly',
                });
                reset({
                    types: response.filter((x) => x.isActive),
                    deletedTypes: response.filter((x) => !x.isActive) ?? [],
                });
                setTypes(response.filter((x) => x.isActive));
                setDeletedTypes(response.filter((x) => !x.isActive));
                setEditing(false);
            })
            .catch(() =>
                toast.update(toastId, {
                    type: 'error',
                    render: 'Error saving Institution Types',
                }),
            );
    };

    useEffect(() => {
        const getData = () => {
            institutionService
                .getTypes()
                .then((response) => {
                    setTypes(response.filter((x) => x.isActive));
                    setDeletedTypes(response.filter((x) => !x.isActive));
                    reset({
                        types: response.filter((x) => x.isActive),
                        deletedTypes: response.filter((x) => !x.isActive),
                    });
                })
                .catch(() => {
                    toast.error('Failed to get Institution Types.');
                });
        };

        getData();
    }, [setTypes, append, reset]);

    return (
        <>
            <PageTitle title="Institution Types" />
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            {!editing ? (
                                <div>
                                    <h3 className="card-title">Active Types</h3>
                                    {types.map((insType, i) => (
                                        <div key={i}>
                                            <p>{insType.title}</p>
                                        </div>
                                    ))}
                                    <h3 className="card-title">Deleted Types</h3>
                                    {deletedTypes.map((insType, i) => (
                                        <div key={i}>
                                            <p>{insType.title}</p>
                                        </div>
                                    ))}
                                    <Button color="primary" onClick={() => setEditing(true)}>
                                        Edit Types
                                    </Button>
                                </div>
                            ) : (
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <h3 className="card-title">Active Types</h3>
                                    {activeFields.map((insType, i) => (
                                        <div key={insType.id} style={{ display: 'flex' }}>
                                            <FormGroup>
                                                <Input
                                                    name={`types[${i}].title`}
                                                    innerRef={register()}
                                                    defaultValue={insType.title}
                                                    style={{ minWidth: '400px' }}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`types[${i}].institutionTypeId`}
                                                    defaultValue={insType.institutionTypeId}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`types[${i}].isActive`}
                                                    defaultValue={insType.isActive}
                                                    ref={register()}
                                                />
                                            </FormGroup>
                                            &nbsp;
                                            <div>
                                                <Button
                                                    type={'button'}
                                                    color={'danger'}
                                                    onClick={() => deleteTypes(insType, i)}
                                                >
                                                    <i className={'remixicon-delete-bin-line'}></i>
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                    <h3 className="card-title">Deleted Types</h3>
                                    {deletedFields.map((insType, i) => (
                                        <div key={insType.id} style={{ display: 'flex' }}>
                                            <FormGroup>
                                                <Input
                                                    name={`deletedTypes[${i}].title`}
                                                    innerRef={register()}
                                                    defaultValue={insType.title}
                                                    style={{ minWidth: '400px' }}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`deletedTypes[${i}].institutionTypeId`}
                                                    defaultValue={insType.institutionTypeId}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`deletedTypes[${i}].isActive`}
                                                    defaultValue={insType.isActive}
                                                    ref={register()}
                                                />
                                            </FormGroup>
                                            &nbsp;
                                            <div>
                                                <Button
                                                    type={'button'}
                                                    color={'info'}
                                                    onClick={() => {
                                                        insType.isActive = true;
                                                        append(insType);
                                                        deletedRemove(i);
                                                    }}
                                                >
                                                    <i className={'remixicon-eye-line'}></i>
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="mb-2">
                                        <Button
                                            color="info"
                                            onClick={() => append({ institutionTypeId: 0, isActive: true })}
                                        >
                                            Add Type
                                        </Button>
                                    </div>
                                    <div>
                                        <Button color="primary" type="submit">
                                            Save
                                        </Button>
                                        &nbsp;
                                        <Button outline onClick={() => setEditing(false)}>
                                            Cancel
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default InstitutionTypes;
