import * as yup from 'yup';

import { Alert, Card, CardBody, Col, Form, FormGroup, Row } from 'reactstrap';
import { NavLink, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button';
import { IAuthState } from '../../store/auth/Types';
import { IPasswordResetCompleteForm } from '../../services/Auth';
import Input from '../../components/Input';
import Logo from '../../assets/images/logo-blue.png';
import React from 'react';
import { completePasswordReset } from '../../store/auth/Actions';
import { useForm } from 'react-hook-form';

type Props = {
    match: any;
};

const PasswordResetComplete = (props: Props) => {
    const passwordResetCompleteSchema = yup.object<IPasswordResetCompleteForm>({
        username: yup.string().required(`Email is required`),
        token: yup.string().default(decodeURIComponent(props.match.params.token)).defined(),
        password: yup
            .string()
            .min(6, `Your password must be at least 6 characters`)
            .required(`Your password must be at least 6 characters`),
        confirmPassword: yup
            .string()
            .test('match', 'Passwords do not match', function (confirmPassword) {
                if (this?.options['parent']?.password) {
                    return confirmPassword === this?.options['parent']?.password;
                }

                return false;
            })
            .required(),
    });

    const auth = useSelector<IAuthState>((state) => state.auth);
    const { register, handleSubmit, errors, watch } = useForm({
        validationSchema: passwordResetCompleteSchema,
        defaultValues: {} as IPasswordResetCompleteForm,
    });
    const dispatch = useDispatch();

    const onSubmit = (formData: IPasswordResetCompleteForm) => {
        dispatch(completePasswordReset(formData));
    };

    return (
        <>
            {auth.authenticated && <Redirect to="/profile" />}
            <Row className="justify-content-center">
                <Col md="8" lg="6" xl="5">
                    <Card>
                        <CardBody>
                            <div className="text-center w-75 m-auto">
                                <NavLink to="/">
                                    <span>
                                        <img src={Logo} alt="logo" height="150" />
                                    </span>
                                </NavLink>
                                <p className="text-muted mb-4 mt-3">
                                    Please complete the form below to complete your password reset request.
                                </p>
                            </div>

                            {auth.passwordReset && (
                                <Alert color={'success'}>
                                    Your password has been successfully reset. Please click the following link to login:{' '}
                                    <NavLink to="/login">Go to the Login Page</NavLink>
                                </Alert>
                            )}

                            {!auth.passwordReset && auth.passwordResetError && (
                                <Alert color={'danger'}>{auth.passwordResetError}</Alert>
                            )}

                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup className="mb-3">
                                    <label htmlFor="username">Confirm Your Email</label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        id="username"
                                        name="username"
                                        innerRef={register({ required: true })}
                                        placeholder="Confirm your email"
                                    />
                                    {errors?.username && (
                                        <label className={'text-danger'}>{errors.username.message}</label>
                                    )}
                                </FormGroup>

                                <Alert color={'primary'}>{`Passwords must be at least 6 characters long.`}</Alert>

                                <FormGroup className="mb-3">
                                    <label htmlFor="password">New Password</label>
                                    <Input
                                        className="form-control"
                                        type="password"
                                        id="password"
                                        name="password"
                                        innerRef={register({ required: true })}
                                        placeholder="Enter a new password"
                                    />
                                    {errors?.password && (
                                        <label className={'text-danger'}>{errors.password.message}</label>
                                    )}
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <label htmlFor="confirmPassword">Confirm New Password</label>
                                    <Input
                                        className="form-control"
                                        type="password"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        innerRef={register({
                                            required: true,
                                            validate: (value) => value === watch('password'),
                                        })}
                                        placeholder="Confirm the new password"
                                    />
                                    {errors?.confirmPassword && (
                                        <label className={'text-danger'}>{errors.confirmPassword.message}</label>
                                    )}
                                    {errors?.token && <label className={'text-danger'}>{errors.token.message}</label>}
                                </FormGroup>
                                <FormGroup className="mb-0 text-center">
                                    <Button className="btn-block" type="submit">
                                        Complete Password Reset
                                    </Button>
                                </FormGroup>
                            </Form>
                        </CardBody>
                    </Card>

                    <Row className="mt-3">
                        <Col xs={12} className="text-center">
                            <p>
                                <NavLink to="/login" className="ml-1">
                                    Back to Login
                                </NavLink>
                            </p>
                        </Col>
                        <Col xs={12} className="text-center">
                            <p>
                                <NavLink to="/passwordreset/request" className="ml-1">
                                    Request a new Link
                                </NavLink>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default PasswordResetComplete;
