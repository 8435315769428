import { Card, CardBody, Col, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import React, { useState } from 'react';

import { Button } from '../../components';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { AccreditationActionTotalReportDto } from '../../common/Types';
import { reportService } from '../../services/ReportService';
import moment from 'moment';

const AnnualReportTotalsReport = () => {
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [concentrations, setConcentrations] = useState<boolean>(false);
    const [actionTotals, setActionTotals] = useState<AccreditationActionTotalReportDto>();

    const { register, handleSubmit, reset } = useForm();

    const clearSearchForm = () => {
        setStartDate(undefined);
        setEndDate(undefined);
        setConcentrations(false);
        setActionTotals(undefined);
        reset({
            startDate: null,
            endDate: null,
            concentrations: null,
            actionTotals: null,
        });
    };
    const onSubmit = () => {
        const toastId = toast.info('Searching...', {
            autoClose: false,
        });
        reportService
            .getAccreditationActionTotalsReport(moment(startDate).format(), moment(endDate).format(), concentrations)
            .then((response) => {
                setActionTotals(response);
                toast.update(toastId, {
                    type: 'success',
                    autoClose: 500,
                });
            })
            .catch(() => toast.error('Failed to generate report'));
    };

    const exportReport = () => {
        const toastId = toast.info('Generating report, this may take a minute...', {
            autoClose: false,
        });
        reportService
            .exportAccreditationActionTotalsReport(moment(startDate).format(), moment(endDate).format(), concentrations)
            .then((report) => {
                if (report) {
                    const url = window.URL.createObjectURL(report);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `AccreditationActionTotalReport.xlsx`;
                    a.click();
                    toast.update(toastId, {
                        render: 'Report generated successfully.',
                        type: 'success',
                        autoClose: 2500,
                    });
                }
            })
            .catch(() => {
                toast.error(`Unable to generate report`);
            });
    };

    return (
        <>
            <Card>
                <CardBody>
                    <Row>
                        <Col>
                            <div className="mb-3">
                                <h3>Annual Report Totals</h3>
                            </div>
                            <Form onSubmit={handleSubmit(onSubmit)} inline>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                    <div className={'d-flex justify-content-between p-2'}>
                                        <div className={'custom-control custom-radio pr-2'}>
                                            <input
                                                type={'radio'}
                                                id={`radioAll`}
                                                className={'custom-control-input'}
                                                checked={concentrations}
                                                onClick={() => setConcentrations(true)}
                                                value={1}
                                            />
                                            <Label
                                                className={'custom-control-label'}
                                                for={`radioAll`}
                                            >{`With Concentrations`}</Label>
                                        </div>
                                        <div className={'custom-control custom-radio pr-2'}>
                                            <input
                                                type={'radio'}
                                                id={`radioUnmerged`}
                                                className={'custom-control-input'}
                                                checked={!concentrations}
                                                onClick={() => setConcentrations(false)}
                                                value={0}
                                            />
                                            <Label
                                                className={'custom-control-label'}
                                                for={`radioUnmerged`}
                                            >{`Without Concentrations`}</Label>
                                        </div>
                                    </div>
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                    <Label for="startDate" className="mr-sm-2">
                                        Start Date:
                                    </Label>
                                    <Input
                                        type="date"
                                        innerRef={register()}
                                        name="startDate"
                                        onChange={(e) => setStartDate(new Date(e.target.value))}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                    <Label for="endDate" className="mr-sm-2">
                                        End Date:
                                    </Label>
                                    <Input
                                        type="date"
                                        innerRef={register()}
                                        name="endDate"
                                        onChange={(e) => setEndDate(new Date(e.target.value))}
                                        required
                                    />
                                </FormGroup>
                                <div className="mb-2 mr-sm-2 mb-sm-2">
                                    <Button type="submit">Search</Button>
                                </div>
                                <div className=" mr-sm-2">
                                    <Button color="link" onClick={() => clearSearchForm()}>
                                        Clear
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col style={{ display: 'flex' }}>
                            <div className="mr-2">
                                <Button color="info" onClick={() => exportReport()}>
                                    Export Report
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <div className="mt-3">
                        <h4>Accredited Programs</h4>
                    </div>
                </CardBody>
                <Table>
                    <thead>
                        <tr>
                            <th>Professions</th>
                            <th>Initial</th>
                            <th>Continuing</th>
                            <th>Probation</th>
                            <th>Withdrawn and/or Withheld</th>
                            <th>Initial Expire</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {actionTotals?.professionAccreditationActions ? (
                            actionTotals.professionAccreditationActions.map((record) => (
                                <tr key={record.professionId}>
                                    <td>{record.professionTitle}</td>
                                    <td>{record.initialCount}</td>
                                    <td>{record.continuingCount}</td>
                                    <td>{record.probationCount}</td>
                                    <td>{record.withdrawnCount}</td>
                                    <td>{record.expireCount}</td>
                                    <td>{record.totalCount}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={7}>No Results</td>
                            </tr>
                        )}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>Total</th>
                            <th>{actionTotals?.initialTotal}</th>
                            <th>{actionTotals?.continuingTotal}</th>
                            <th>{actionTotals?.probationTotal}</th>
                            <th>{actionTotals?.withdrawnTotal}</th>
                            <th>{actionTotals?.expireTotal}</th>
                            <th>{actionTotals?.totalTotal}</th>
                        </tr>
                    </tfoot>
                </Table>
            </Card>
        </>
    );
};

export default AnnualReportTotalsReport;
