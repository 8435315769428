import { Alert, Card, CardBody, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { Button, FormError, Input, PageTitle } from '../../components';

import DefaultProfilePic from '../../assets/images/default-user.png';
import { IAuthState } from '../../store/auth/Types';
import React from 'react';
import { authService } from '../../services/Auth';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

const Profile = () => {
    const auth: IAuthState = useSelector((state) => state.auth);
    const { register, handleSubmit, errors, watch } = useForm();

    const onSubmit = (formData) => {
        const toastId = toast.info('Resetting password...');

        authService
            .changePassword(formData)
            .then(() => {
                toast.update(toastId, {
                    render: 'Password updated successfully',
                    type: 'success',
                });
            })
            .catch((error) => {
                toast.update(toastId, {
                    render: error[0].description,
                    type: 'error',
                });
            });
    };

    if (auth.passwordResetting && !auth.passwordReset && !auth.passwordResetError) {
        toast.info('Resetting password...');
    }

    if (!auth.passwordResetting && !auth.passwordReset && auth.passwordResetError) {
        toast.error(`Error resetting password: ${auth.passwordResetError}`);
    }

    if (!auth.passwordResetting && auth.passwordReset && !auth.passwordResetError) {
        toast.success('Password reset successfully');
    }

    return (
        <>
            <Row>
                <Col>
                    <PageTitle title="Profile" />
                </Col>
            </Row>
            <Row>
                <Col md="12" lg="6" xl="4">
                    <Card>
                        <CardBody>
                            <div className="text-center w-75 m-auto">
                                <img
                                    src={DefaultProfilePic}
                                    className="rounded-circle avatar-xl img-thumbnail"
                                    alt="profile"
                                />
                                <h4 className="mb-0">{auth.user.name}</h4>
                            </div>

                            <div className="text-left mt-3">
                                <p>
                                    <span className="font-weight-bold">Email:</span>
                                    <span className="ml-2">{auth.user.email}</span>
                                </p>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md="12" lg="6" xl="4">
                    <Card>
                        <CardBody>
                            <h5 className="card-title">Update Password</h5>
                            {auth.passwordReset && (
                                <Alert color="success">Your password has been successfully changed.</Alert>
                            )}
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup className="mb-3">
                                    <Label for="currentpassword">Current Password</Label>
                                    <Input
                                        type="password"
                                        id="currentpassword"
                                        name="currentpassword"
                                        innerRef={register({ required: true })}
                                        placeholder="Enter current password"
                                    />
                                    <FormError
                                        name="currentpassword"
                                        message="Enter your current password"
                                        errors={errors}
                                    />
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <Label for="newPassword">New Password</Label>
                                    <Input
                                        type="password"
                                        id="newPassword"
                                        name="newPassword"
                                        innerRef={register({ required: true })}
                                        placeholder="Enter new password"
                                    />
                                    <FormError name="newPassword" message="Enter a new password" errors={errors} />
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <Label for="confirmPassword">Confirm New Password</Label>
                                    <Input
                                        type="password"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        innerRef={register({
                                            required: true,
                                            validate: (value) => value === watch('newPassword'),
                                        })}
                                        placeholder="Confirm new password"
                                    />
                                    <FormError
                                        name="confirmPassword"
                                        message="Confirm your new password"
                                        errors={errors}
                                    />
                                </FormGroup>
                                <FormGroup className="mb-0 text-center">
                                    <Button id="updatePasswordbtn" className="btn-block" type="submit">
                                        Update
                                    </Button>
                                </FormGroup>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default Profile;
