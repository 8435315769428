import React, { useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { Input } from '../../components';
import { BaseGeneralSetting } from '../../common/Types';

type Props = {
    register: Function;
    reportingWindow: BaseGeneralSetting;
};

const ReportingWindowSettings = (props) => {
    const [employerMeasureType, setEmployerMeasureType] = useState<string>(
        props.reportingWindow.employeeSurveyMeasureType,
    );
    const [graduateMeasureType, setGraduateMeasureType] = useState<string>(
        props.reportingWindow.employeeSurveyMeasureType,
    );

    return (
        <>
            <input type="hidden" name="reportingWindow.id" value={props.reportingWindow.id} ref={props.register()} />
            <input
                type="hidden"
                name="reportingWindow.title"
                value={props.reportingWindow.title ?? ''}
                ref={props.register()}
            />
            <input
                type="hidden"
                name="reportingWindow.cutScore"
                value={props.reportingWindow.cutScore ?? ''}
                ref={props.register()}
            />
            <FormGroup>
                <Label for="reportingWindow.activeYear">Active Year</Label>
                <Input
                    type="text"
                    id="reportingWindow.activeYear"
                    name="reportingWindow.activeYear"
                    innerRef={props.register()}
                    defaultValue={props.reportingWindow.activeYear}
                    style={{ width: '100px' }}
                />
            </FormGroup>
            <FormGroup>
                <Label for="reportingWindow.yearAvarageStart">Average Start Year</Label>
                <Input
                    type="text"
                    id="reportingWindow.yearAvarageStart"
                    name="reportingWindow.yearAvarageStart"
                    innerRef={props.register()}
                    defaultValue={props.reportingWindow.yearAvarageStart}
                    style={{ width: '100px' }}
                />
            </FormGroup>
            <FormGroup>
                <Label for="reportingWindow.reportingWindow">Reporting Window</Label>
                <Input
                    type="select"
                    id="reportingWindow.reportingWindow"
                    name="reportingWindow.reportingWindow"
                    innerRef={props.register()}
                    defaultValue={props.reportingWindow.reportingWindow}
                    style={{ width: '100px' }}
                >
                    <option value="3">3 years</option>
                    <option value="4">4 years</option>
                    <option value="5">5 years</option>
                    <option value="6">6 years</option>
                </Input>
            </FormGroup>
            <FormGroup>
                <Label for="reportingWindow.annualReportMailbox">Annual Report Mailbox</Label>
                <Input
                    type="text"
                    id="reportingWindow.annualReportMailbox"
                    name="reportingWindow.annualReportMailbox"
                    innerRef={props.register()}
                    defaultValue={props.reportingWindow.annualReportMailbox}
                    style={{ width: '100px' }}
                />
            </FormGroup>
            <hr style={{ borderColor: 'black', marginTop: '3rem', marginBottom: '3rem' }} />
            <FormGroup>
                <Label for="reportingWindow.outcomeMeasure">Attrition/Retention Designation</Label>
                <Input
                    type="select"
                    id="reportingWindow.outcomeMeasure"
                    name="reportingWindow.outcomeMeasure"
                    innerRef={props.register()}
                    defaultValue={props.reportingWindow.outcomeMeasure}
                    style={{ width: '150px' }}
                >
                    <option value="Attrition">Attrition</option>
                    <option value="Retention">Retention</option>
                </Input>
            </FormGroup>
            <FormGroup>
                <Label for="reportingWindow.attritionRetentionThreshold">Attrition/Retention Threshold</Label>
                <Input
                    type="number"
                    id="reportingWindow.attritionRetentionThreshold"
                    name="reportingWindow.attritionRetentionThreshold"
                    innerRef={props.register()}
                    defaultValue={props.reportingWindow.attritionRetentionThreshold}
                    style={{ width: '100px' }}
                />
            </FormGroup>
            <FormGroup>
                <Label for="reportingWindow.positivePlacementThreshold">Positive Placement Threshold</Label>
                <Input
                    type="number"
                    id="reportingWindow.positivePlacementThreshold"
                    name="reportingWindow.positivePlacementThreshold"
                    innerRef={props.register()}
                    defaultValue={props.reportingWindow.positivePlacementThreshold}
                    style={{ width: '100px' }}
                />
            </FormGroup>
            <hr style={{ borderColor: 'black', marginTop: '3rem', marginBottom: '3rem' }} />
            <FormGroup>
                <Label for="reportingWindow.questionLabelType">Survey Question Label Type</Label>
                <Input
                    type="select"
                    id="reportingWindow.questionLabelType"
                    name="reportingWindow.questionLabelType"
                    innerRef={props.register()}
                    defaultValue={props.reportingWindow.questionLabelType}
                    style={{ width: '150px' }}
                >
                    <option value="Letters">Letters</option>
                    <option value="Numbers">Numbers</option>
                </Input>
            </FormGroup>
            <hr style={{ borderColor: 'black', marginTop: '3rem', marginBottom: '3rem' }} />
            <h4>Employer Survey Settings</h4>
            <FormGroup>
                <Label for="reportingWindow.escognitiveQuestions">Cognitive Question Count</Label>
                <Input
                    type="number"
                    id="reportingWindow.escognitiveQuestions"
                    name="reportingWindow.escognitiveQuestions"
                    innerRef={props.register()}
                    defaultValue={props.reportingWindow.escognitiveQuestions}
                    style={{ width: '100px' }}
                />
            </FormGroup>
            <FormGroup>
                <Label for="reportingWindow.espsychomotorQuestions">Psychomotor Question Count</Label>
                <Input
                    type="number"
                    id="reportingWindow.espsychomotorQuestions"
                    name="reportingWindow.espsychomotorQuestions"
                    innerRef={props.register()}
                    defaultValue={props.reportingWindow.espsychomotorQuestions}
                    style={{ width: '100px' }}
                />
            </FormGroup>
            <FormGroup>
                <Label for="reportingWindow.esaffectiveQuestions">Affective Question Count</Label>
                <Input
                    type="number"
                    id="reportingWindow.esaffectiveQuestions"
                    name="reportingWindow.esaffectiveQuestions"
                    innerRef={props.register()}
                    defaultValue={props.reportingWindow.esaffectiveQuestions}
                    style={{ width: '100px' }}
                />
            </FormGroup>
            <FormGroup>
                <Label for="reportingWindow.surveyMeasureType">Survey Measure Type</Label>
                <Input
                    type="select"
                    id="reportingWindow.employeeSurveyMeasureType"
                    name="reportingWindow.employeeSurveyMeasureType"
                    innerRef={props.register()}
                    defaultValue={props.reportingWindow.employeeSurveyMeasureType}
                    style={{ width: '100px' }}
                    onChange={(e) => setEmployerMeasureType(e.target.value)}
                >
                    <option value="Returned">Returned</option>
                    <option value="Sent">Sent</option>
                </Input>
            </FormGroup>
            <FormGroup>
                <Label for="reportingWindow.employerSurveysThreshold">{employerMeasureType} Threshold</Label>
                <Input
                    type="number"
                    id="reportingWindow.employerSurveysThreshold"
                    name="reportingWindow.employerSurveysThreshold"
                    innerRef={props.register()}
                    defaultValue={props.reportingWindow.employerSurveysThreshold}
                    style={{ width: '100px' }}
                />
            </FormGroup>
            <FormGroup>
                <Label for="reportingWindow.employerSurveysThreshold">Satisfaction Threshold</Label>
                <Input
                    type="number"
                    id="reportingWindow.employeeSurveySatisfactionThreshold"
                    name="reportingWindow.employeeSurveySatisfactionThreshold"
                    innerRef={props.register()}
                    defaultValue={props.reportingWindow.employeeSurveySatisfactionThreshold}
                    style={{ width: '100px' }}
                />
            </FormGroup>
            <FormGroup>
                <Label for="reportingWindow.employeeSurveyCutScore">Cut Score</Label>
                <Input
                    type="number"
                    id="reportingWindow.employeeSurveyCutScore"
                    name="reportingWindow.employeeSurveyCutScore"
                    innerRef={props.register()}
                    defaultValue={props.reportingWindow.employeeSurveyCutScore}
                    style={{ width: '100px' }}
                />
            </FormGroup>
            <hr style={{ borderColor: 'black', marginTop: '3rem', marginBottom: '3rem' }} />
            <h4>Graduate Survey Settings</h4>
            <FormGroup>
                <Label for="reportingWindow.gscognitiveQuestions">Cognitive Question Count</Label>
                <Input
                    type="number"
                    id="reportingWindow.gscognitiveQuestions"
                    name="reportingWindow.gscognitiveQuestions"
                    innerRef={props.register()}
                    defaultValue={props.reportingWindow.gscognitiveQuestions}
                    style={{ width: '100px' }}
                />
            </FormGroup>
            <FormGroup>
                <Label for="reportingWindow.gspsychomotorQuestions">Psychomotor Question Count</Label>
                <Input
                    type="number"
                    id="reportingWindow.gspsychomotorQuestions"
                    name="reportingWindow.gspsychomotorQuestions"
                    innerRef={props.register()}
                    defaultValue={props.reportingWindow.gspsychomotorQuestions}
                    style={{ width: '100px' }}
                />
            </FormGroup>
            <FormGroup>
                <Label for="reportingWindow.gsaffectiveQuestions">Affective Question Count</Label>
                <Input
                    type="number"
                    id="reportingWindow.gsaffectiveQuestions"
                    name="reportingWindow.gsaffectiveQuestions"
                    innerRef={props.register()}
                    defaultValue={props.reportingWindow.gsaffectiveQuestions}
                    style={{ width: '100px' }}
                />
            </FormGroup>
            <FormGroup>
                <Label for="reportingWindow.graduateSurveyMeasureType">Measure Type</Label>
                <Input
                    type="select"
                    id="reportingWindow.graduateSurveyMeasureType"
                    name="reportingWindow.graduateSurveyMeasureType"
                    innerRef={props.register()}
                    defaultValue={props.reportingWindow.graduateSurveyMeasureType}
                    style={{ width: '100px' }}
                    onChange={(e) => setGraduateMeasureType(e.target.value)}
                >
                    <option value="Returned">Returned</option>
                    <option value="Sent">Sent</option>
                </Input>
            </FormGroup>
            <FormGroup>
                <Label for="reportingWindow.graduateSurveysThreshold">{graduateMeasureType} Threshold</Label>
                <Input
                    type="number"
                    id="reportingWindow.graduateSurveysThreshold"
                    name="reportingWindow.graduateSurveysThreshold"
                    innerRef={props.register()}
                    defaultValue={props.reportingWindow.graduateSurveysThreshold}
                    style={{ width: '100px' }}
                />
            </FormGroup>
            <FormGroup>
                <Label for="reportingWindow.graduateSurveySatisfactionThreshold">Satisfaction Threshold</Label>
                <Input
                    type="number"
                    id="reportingWindow.graduateSurveySatisfactionThreshold"
                    name="reportingWindow.graduateSurveySatisfactionThreshold"
                    innerRef={props.register()}
                    defaultValue={props.reportingWindow.graduateSurveySatisfactionThreshold}
                    style={{ width: '100px' }}
                />
            </FormGroup>
            <FormGroup>
                <Label for="reportingWindow.graduateSurveyCutScore">Cut Score</Label>
                <Input
                    type="number"
                    id="reportingWindow.graduateSurveyCutScore"
                    name="reportingWindow.graduateSurveyCutScore"
                    innerRef={props.register()}
                    defaultValue={props.reportingWindow.graduateSurveyCutScore}
                    style={{ width: '100px' }}
                />
            </FormGroup>
        </>
    );
};

export default ReportingWindowSettings;
