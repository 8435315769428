import React, { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Folder } from '../common/Types';

interface HubNavItemProps {
    folder: Folder;
    allFolders: Folder[];
}

const FolderList = styled.ul`
    padding: 0px 0px 0px 0px;
`;

interface ThisFolderProps {
    isSelected: boolean;
}

const ThisFolderListItem = styled.li<ThisFolderProps>`
    display: flex;
    align-items: center;

    ${(props) =>
        props.isSelected
            ? `
        background-color: #DDDDDD;
        border-radius: 16px 0px 0px 16px;
    `
            : ``}

    & a {
        color: #6c757d;
        font-weight: ${(props) => (props.isSelected ? `700` : `500`)};
        display: flex;
        align-items: center;
    }
`;

const ChildFolderListItem = styled.li`
    padding: 0px 0px 0px 20px;
`;

const ExpandedIcon = styled.i`
    font-size: 32px;
    cursor: pointer;
`;

const FolderIcon = styled.i`
    font-size: 28px;
    margin: 0px 10px 0px 0px;
`;

const FolderLink = styled(NavLink)`
    & span {
        margin: 0px 20px 0px 0px;
    }
`;

const HubNavItem = (props: HubNavItemProps) => {
    const { folderSlug: navFolderSlug } = useParams<{ folderSlug: string }>();
    const {
        folder: { folderId, folderSlug, name },
        allFolders,
    } = props;
    const [expanded, setExpanded] = useState<boolean>(false);
    const toggledExpanded = () => setExpanded(!expanded);

    const childFolders =
        allFolders
            ?.filter((f) => !f.isDeleted && f.parentFolderId === folderId)
            ?.sort((a, b) => a.name.localeCompare(b.name)) || [];

    return (
        <FolderList>
            <ThisFolderListItem isSelected={navFolderSlug === folderSlug}>
                {expanded && childFolders.length > 0 && (
                    <ExpandedIcon onClick={() => toggledExpanded()} className={`mdi mdi-menu-down`} />
                )}
                {!expanded && childFolders.length > 0 && (
                    <ExpandedIcon onClick={() => toggledExpanded()} className={`mdi mdi-menu-right`} />
                )}
                {childFolders.length === 0 && <ExpandedIcon style={{ opacity: 0 }} className={`mdi mdi-menu-right`} />}
                <FolderLink to={`/hub/folders/${folderSlug}`}>
                    <FolderIcon className={`mdi mdi-folder`} />
                    <span>{name}</span>
                </FolderLink>
            </ThisFolderListItem>
            {expanded && (
                <ChildFolderListItem>
                    {childFolders?.map((f) => (
                        <HubNavItem key={f.folderId} allFolders={allFolders} folder={f} />
                    ))}
                </ChildFolderListItem>
            )}
        </FolderList>
    );
};

export default HubNavItem;
