import { Badge, Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Button, PageTitle } from '../../../components';
import { NavLink, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { APIUriAms } from '../../../services/HttpHelper';
import EmailInvoiceForm from '../../billing/EmailInvoiceForm';
import InvoiceForm from './InvoiceForm';
import InvoicePayments from './InvoicePayments';
import { InvoiceStatus } from '../../../common/Data';
import InvoiceTimeline from './InvoiceTimeline';
import { InvoicePaymentsDto, InvoicingDetailDto } from '../../../types';
import { invoiceService } from '../../../services/InvoiceService';
import { toast } from 'react-toastify';

const currencyFormatter = new Intl.NumberFormat(`en-US`, {
    style: `currency`,
    currency: `USD`,
});

const Invoice = () => {
    const [invoice, setInvoice] = useState<InvoicingDetailDto>();
    const [editing, setEditing] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [emailing, setEmailing] = useState(false);
    const [reload, setReload] = useState(false);
    const { invoiceId } = useParams<{ invoiceId: string }>();

    const amountPaid = (payments: InvoicePaymentsDto[]): number => {
        let total = 0;
        payments
            .filter((p) => !p.isDeleted)
            .forEach((p) => {
                total += p.paymentAmount;
            });
        return total;
    };

    useEffect(() => {
        const getData = () => {
            invoiceService
                .getInvoice(+invoiceId)
                .then((response) => setInvoice(response))
                .catch(() => toast.error('Failed to get invoice.'));
        };

        getData();
    }, [setInvoice, invoiceId, reload]);

    const invoiceUpdated = () => {
        setReload(!reload);
        setEditing(false);
        setDeleting(false);
    };

    const paymentUrl = () => {
        if (!!invoice) {
            return `${window.location.origin}/payments/invoice/${invoice.invoiceNumber}`;
        }

        return `Not Set`;
    };

    const deleteInvoice = () => {
        if (invoice !== undefined) {
            const toastId = toast.info('Deleting Invoice...');

            invoiceService
                .saveInvoice(invoice.id, {
                    ...invoice,
                    isDeleted: true,
                })
                .then(() => {
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Invoice deleted successfuly',
                    });
                    invoiceUpdated();
                })
                .catch((e) => {
                    if (e.error) {
                        toast.update(toastId, {
                            type: 'error',
                            render: e.error,
                        });
                    } else {
                        toast.update(toastId, {
                            type: 'error',
                            render: 'Error deleting Invoice',
                        });
                    }
                });
        }
    };

    const formatMoneyOrZero = (amount: number | undefined | null): string => {
        return currencyFormatter.format(amount || 0);
    };

    const showStatus = (pl: {
        id: number;
        title: string;
        isPaid?: boolean;
        amountPaid?: number;
        concentrationsOrAddOnTracks?: string[];
    }) => {
        if (pl.isPaid === true) {
            return <span className={`ml-2 badge badge-primary`}>{`Paid`}</span>;
        }

        if ((pl.amountPaid || 0) >= (invoice?.amount || 0) && (invoice?.amount || 0) > 0) {
            return <span className={`ml-2 badge badge-primary`}>{`Paid`}</span>;
        }

        if ((pl.amountPaid || 0) > 0 && !!invoice?.amount) {
            return (
                <span className={`ml-2 badge badge-warning`}>{`Partially Paid (${formatMoneyOrZero(
                    pl.amountPaid,
                )})`}</span>
            );
        }

        if ((pl.amountPaid || 0) === 0) {
            return <span className={`ml-2 badge badge-danger`}>{`Unpaid`}</span>;
        }
    };

    return (
        <>
            <PageTitle title={`Invoice | ${invoice?.invoiceNumber}`} />

            <Row>
                <Col xl={6} lg={12}>
                    <Card>
                        <CardBody>
                            {invoice?.isDeleted && (
                                <Row>
                                    <Col>
                                        <div className="page-title-box">
                                            <h4 className="page-title" style={{ color: '#f1556c' }}>
                                                DELETED
                                            </h4>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            <div
                                style={{
                                    display: 'flex',
                                    borderBottom: '1px solid',
                                    paddingBottom: '16px',
                                    marginBottom: '6px',
                                }}
                            >
                                <div style={{ flex: 1 }}>
                                    <h2>{invoice?.institutionTitle}</h2>
                                    <p>
                                        <NavLink to={`/institution/${invoice?.institutionId}`}>
                                            Go to institution
                                        </NavLink>
                                    </p>
                                </div>
                                <div className="text-right">
                                    <h2>
                                        <Badge color={invoice?.status === 1 ? 'success' : 'warning'}>
                                            {InvoiceStatus[invoice?.status ?? 3]}
                                        </Badge>
                                    </h2>
                                </div>
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    borderBottom: '1px solid',
                                    paddingBottom: '6px',
                                    marginBottom: '6px',
                                }}
                            >
                                <div style={{ flex: 1 }}>
                                    <h3>Invoice Date:</h3>
                                </div>
                                <div className="text-right">
                                    <h3>{new Date(invoice?.invoiceDate || new Date()).toDateString()}</h3>
                                </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }}>
                                    <h2>Total Due:</h2>
                                </div>
                                <div className="text-right">
                                    <h2>${invoice?.dueAmount?.toFixed(2)}</h2>
                                </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }}>
                                    <h2>Amount Paid:</h2>
                                </div>
                                <div className="text-right">
                                    <h2>${amountPaid(invoice?.invoicePayments || [])?.toFixed(2)}</h2>
                                </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }}>
                                    <h4>Fee Per Discipline:</h4>
                                </div>
                                <div className="text-right">
                                    <h4>${invoice?.amount?.toFixed(2)}</h4>
                                </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }}>
                                    <h4>Administrative Fee:</h4>
                                </div>
                                <div className="text-right">
                                    <h4>${invoice?.lateFee?.toFixed(2) ?? 0}</h4>
                                </div>
                            </div>
                            {!!invoice?.convenienceFee && invoice.convenienceFee > 0 && (
                                <div style={{ display: 'flex' }}>
                                    <div style={{ flex: 1 }}>
                                        <h4>Convenience Fee:</h4>
                                    </div>
                                    <div className="text-right">
                                        <h4>${invoice?.convenienceFee?.toFixed(2) ?? 0}</h4>
                                    </div>
                                </div>
                            )}
                            {!!invoice?.professionListDeserialized && (
                                <div style={{ display: 'flex' }}>
                                    <div style={{ flex: 1 }}>
                                        <h4>Programs:</h4>
                                    </div>
                                    <div className="text-right d-flex flex-column">
                                        {invoice.professionListDeserialized.map((pl) => (
                                            <div className={`d-flex flex-column`} key={pl.id}>
                                                <h4>
                                                    {pl.title} {showStatus(pl)}
                                                </h4>
                                                {!!pl.concentrationsOrAddOnTracks && (
                                                    <div className={`mt-1 mb-1`}>
                                                        {pl.concentrationsOrAddOnTracks.map((c, i) => (
                                                            <div key={i} className={`pl-5`}>
                                                                {c}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }}>
                                    <h5>Billing Contact:</h5>
                                </div>
                                <div className="text-right">
                                    <h4>{invoice?.recipient?.email}</h4>
                                </div>
                            </div>

                            <div>
                                <div>
                                    <h5>Payment URL</h5>
                                </div>
                                <div>
                                    <a rel="noopener noreferrer" target="_blank" href={paymentUrl()}>
                                        {paymentUrl()}
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div>
                                    <h5>Description:</h5>
                                </div>
                                <div>{invoice?.description}</div>
                            </div>
                            {invoice?.isDeleted ? (
                                <div></div>
                            ) : (
                                <div style={{ paddingTop: '24px', display: 'flex' }}>
                                    {(invoice?.dueAmount || 0) > 0 && (
                                        <div className="mr-sm-2">
                                            <Button onClick={() => setEditing(true)}>Edit</Button>
                                        </div>
                                    )}
                                    <div className="mr-sm-2">
                                        <Button onClick={() => setEmailing(true)}>Email Invoice</Button>
                                    </div>
                                    {invoice?.pdfUrl && (
                                        <div
                                            style={{ flex: 1 }}
                                            className={`${(invoice?.status || 0) === 3 && 'mr-sm-2'}`}
                                        >
                                            <a
                                                style={{ lineHeight: '2rem' }}
                                                href={`${APIUriAms}/invoice/${invoice?.id}/pdf/${invoice?.pdfUrl}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Download
                                            </a>
                                        </div>
                                    )}
                                    {(invoice?.status || 0) === 3 && (
                                        <div>
                                            <Button color="danger" onClick={() => setDeleting(true)}>
                                                Delete
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </CardBody>
                    </Card>
                    <InvoicePayments
                        payments={invoice?.invoicePayments ?? []}
                        amountPaid={amountPaid(invoice?.invoicePayments || [])}
                        invoice={invoice}
                        onSave={() => invoiceUpdated()}
                    />
                </Col>
                <Col xl={6}>
                    <InvoiceTimeline activity={invoice?.invoiceActivities ?? []} />
                </Col>
            </Row>

            {invoice && (
                <Modal isOpen={editing} toggle={() => setEditing(!editing)} size="lg">
                    <ModalHeader toggle={() => setEditing(!editing)}>Edit Invoice</ModalHeader>
                    <ModalBody>
                        <InvoiceForm
                            invoice={invoice}
                            onSave={() => invoiceUpdated()}
                            onCancel={() => setEditing(false)}
                        />
                    </ModalBody>
                </Modal>
            )}

            {invoice && (
                <Modal isOpen={emailing} toggle={() => setEmailing(!emailing)} size="lg">
                    <ModalHeader toggle={() => setEmailing(!emailing)}>Email Invoice</ModalHeader>
                    <ModalBody>
                        <p>
                            The invoice will be sent to the billing contact unless you enter an alternative email below.
                        </p>
                        <EmailInvoiceForm
                            invoiceId={invoice.id}
                            onSave={() => setEmailing(false)}
                            onCancel={() => setEmailing(false)}
                        />
                    </ModalBody>
                </Modal>
            )}

            <Modal isOpen={deleting} toggle={() => setDeleting(!deleting)} size="lg">
                <ModalHeader toggle={() => setDeleting(!deleting)}>Email Invoice</ModalHeader>
                <ModalBody>
                    <p>Are you sure you want to delete this invoice?</p>
                    <div>
                        <Button type="button" color={'link'} onClick={() => setDeleting(!deleting)}>
                            No
                        </Button>
                        <Button type="button" color="primary" onClick={() => deleteInvoice()}>
                            Yes
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default Invoice;
