import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../store/auth/Actions';
import { Redirect } from 'react-router-dom';

const Logout = () => {
    const authenticated = useSelector((state) => state.auth.authenticated);
    const dispatch = useDispatch();

    if (authenticated) {
        dispatch(logout());
    }

    return <>{!authenticated && <Redirect to="/login" />}</>;
};

export default Logout;
