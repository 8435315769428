import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Card } from 'reactstrap';

const StyledDateTile = styled(Card)`
    margin-right: 8px;
    font-weight: bold;
    border-color: var(--caahep-secondary);
    color: var(--caahep-secondary);

    &.future {
        border-color: var(--caahep-primary);
        color: var(--caahep-primary);
    }

    > div:first-of-type {
        display: flex;
        padding-top: 8px;
    }

    > div {
        padding-left: 12px;
        padding-right: 12px;
    }

    > div:last-of-type {
        padding-bottom: 8px;
    }
`;

const DateTile = (props) => {
    const [momentDate] = useState(moment(props.meeting.meetingDate));
    const beginningOfDay = new Date();
    beginningOfDay.setHours(0, 0, 0, 0);

    return (
        <StyledDateTile className={props.meeting.meetingDate >= beginningOfDay && 'future'}>
            <div style={{ display: 'flex' }}>
                <div>{momentDate.format('MM')}</div>
                &#47;
                <div>{momentDate.format('DD')}</div>
            </div>
            <div>{momentDate.format('YYYY')}</div>
        </StyledDateTile>
    );
};

export default DateTile;
