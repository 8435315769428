import { AmsDocument, CoaDto, DocumentUpload, SignatureDocument } from '../../common/Types';
import { Button, Input, PageTitle } from '../../components';
import {
    Card,
    CardBody,
    CardFooter,
    Col,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import React, { useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import Dropzone from 'react-dropzone';
import { coaService } from '../../services/CoaAdministration';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

const DropZoneContainer = styled.section`
    border: dashed 5px #cccccc;
    > div {
        height: 80px;
    }
`;

interface CoaParams {
    coaId: string;
}

interface CoaState {
    coa?: CoaDto;
    originalCoa?: CoaDto;
    editingDetail: boolean;
    editingContact: boolean;
}

const Coa = () => {
    const [state, setState] = useState<CoaState>({
        editingDetail: false,
        editingContact: false,
    });

    const [showSignatureModal, setShowSignatureModal] = useState<boolean>(false);
    const toggleShowSignatureModal = () => setShowSignatureModal(!showSignatureModal);
    const [signatureUpload, setSignatureUpload] = useState<DocumentUpload>();

    const [showActiveModal, setShowActiveModal] = useState<boolean>(false);
    const toggleActiveModal = () => setShowActiveModal(!showActiveModal);

    const { coa, originalCoa, editingDetail, editingContact } = state;
    const [signature, setSignature] = useState<string>();
    const { coaId } = useParams<CoaParams>();
    const idNum = +coaId;

    const loadCoa = (id: number) => {
        coaService
            .getCoa(id)
            .then((coa) => {
                setState({
                    ...state,
                    coa: coa,
                    originalCoa: coa,
                });

                coaService
                    .getCoaSignature(id)
                    .then((sig) => {
                        setSignature(sig);
                    })
                    .catch(() => {
                        toast.error('Error loading signature');
                    });
            })
            .catch(() => {
                toast.error('Error loading CoA');
            });
    };

    if (!isNaN(idNum) && !originalCoa) {
        loadCoa(idNum);
    }

    const saveCoa = () => {
        if (coa) {
            const toastId = toast.info('Saving CoA...');

            coaService
                .saveCoas([{ ...coa }])
                .then((coas) => {
                    toast.update(toastId, {
                        render: 'CoA saved successfully',
                        type: 'success',
                    });

                    const updatedCoa = { ...coas[0] };

                    setState({
                        ...state,
                        originalCoa: { ...updatedCoa },
                        coa: { ...updatedCoa },
                        editingContact: false,
                        editingDetail: false,
                    });
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error saving CoA',
                        type: 'error',
                    });
                });
        }
    };

    const toggleActiveState = () => {
        if (coa) {
            const toastId = toast.info('Saving CoA...');

            coaService
                .saveCoas([{ ...coa, isActive: !coa.isActive }])
                .then((coas) => {
                    toast.update(toastId, {
                        render: 'CoA saved successfully',
                        type: 'success',
                    });

                    const updatedCoa = { ...coas[0] };

                    setState({
                        ...state,
                        originalCoa: { ...updatedCoa },
                        coa: { ...updatedCoa },
                        editingContact: false,
                        editingDetail: false,
                    });

                    toggleActiveModal();
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error saving CoA',
                        type: 'error',
                    });
                });
        }
    };

    const addStagedSignatureUpload = (dropData) => {
        if (signatureUpload && dropData && dropData[0]) {
            dropData.fileId = uuidv4();

            setSignatureUpload({
                fileUpload: dropData[0],
                dto: {
                    ...signatureUpload.dto,
                    fileUrl: dropData[0].path as string,
                } as SignatureDocument,
            });
        }
    };

    const saveSignatureUpload = () => {
        if (signatureUpload) {
            const toastId = toast.info('Saving & uploading signature...');

            coaService
                .uploadSignature(signatureUpload)
                .then((result) => {
                    toast.update(toastId, {
                        render: 'Signature uploaded successfully',
                        type: 'success',
                    });

                    toggleShowSignatureModal();
                    setSignature(result);
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error uploading signature',
                        type: 'error',
                    });
                });
        }
    };

    if (isNaN(idNum)) {
        return <Redirect to={`/system-admin/coas`} />;
    } else {
        return (
            <>
                <PageTitle title={`CoA | ${coa?.coaTitle || ''}`} />
                {coa && originalCoa && (
                    <>
                        <Row>
                            <Col xs={12} md={6}>
                                <Row>
                                    <Col xs={12}>
                                        <Card>
                                            <CardBody>
                                                <h4 className={'card-title'}>{`CoA Detail`}</h4>

                                                <FormGroup>
                                                    <Label>{`CoA Title`}</Label>

                                                    {!editingDetail && (
                                                        <div>
                                                            <span>{originalCoa?.coaTitle}</span>
                                                        </div>
                                                    )}
                                                    {editingDetail && (
                                                        <Input
                                                            value={coa?.coaTitle}
                                                            onChange={(e) =>
                                                                setState({
                                                                    ...state,
                                                                    coa: { ...coa, coaTitle: e.target.value },
                                                                })
                                                            }
                                                        />
                                                    )}
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label>{`Acronym`}</Label>

                                                    {!editingDetail && (
                                                        <div>
                                                            <span>{originalCoa?.abb}</span>
                                                        </div>
                                                    )}
                                                    {editingDetail && (
                                                        <Input
                                                            value={coa?.abb}
                                                            onChange={(e) =>
                                                                setState({
                                                                    ...state,
                                                                    coa: { ...coa, abb: e.target.value },
                                                                })
                                                            }
                                                        />
                                                    )}
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label>{`Organizational Sponsor(s)`}</Label>

                                                    {!editingDetail && (
                                                        <div>
                                                            <p style={{ whiteSpace: 'pre-line' }}>
                                                                {originalCoa?.sponsors}
                                                            </p>
                                                        </div>
                                                    )}
                                                    {editingDetail && (
                                                        <Input
                                                            type={'textarea'}
                                                            rows={10}
                                                            value={coa?.sponsors}
                                                            onChange={(e) =>
                                                                setState({
                                                                    ...state,
                                                                    coa: { ...coa, sponsors: e.target.value },
                                                                })
                                                            }
                                                        />
                                                    )}
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label>{`Submit PR To`}</Label>

                                                    {!editingDetail && (
                                                        <div>
                                                            <span>{originalCoa?.submitPrto}</span>
                                                        </div>
                                                    )}
                                                    {editingDetail && (
                                                        <Input
                                                            value={coa?.submitPrto}
                                                            onChange={(e) =>
                                                                setState({
                                                                    ...state,
                                                                    coa: { ...coa, submitPrto: e.target.value },
                                                                })
                                                            }
                                                        />
                                                    )}
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label>{`Chair Full Name`}</Label>

                                                    {!editingDetail && (
                                                        <div>
                                                            <span>{originalCoa?.chairFullName}</span>
                                                        </div>
                                                    )}
                                                    {editingDetail && (
                                                        <Input
                                                            value={coa?.chairFullName}
                                                            onChange={(e) =>
                                                                setState({
                                                                    ...state,
                                                                    coa: { ...coa, chairFullName: e.target.value },
                                                                })
                                                            }
                                                        />
                                                    )}
                                                </FormGroup>
                                            </CardBody>
                                            <CardFooter className={'d-flex justify-content-between'}>
                                                <div>
                                                    {!editingDetail && (
                                                        <>
                                                            <Button
                                                                color={'primary'}
                                                                type={'button'}
                                                                onClick={() =>
                                                                    setState({ ...state, editingDetail: true })
                                                                }
                                                            >
                                                                {`Edit`}
                                                            </Button>
                                                        </>
                                                    )}
                                                    {editingDetail && (
                                                        <>
                                                            <Button
                                                                color={'primary'}
                                                                type={'button'}
                                                                onClick={() => saveCoa()}
                                                            >
                                                                {`Submit`}
                                                            </Button>

                                                            <Button
                                                                color={'secondary'}
                                                                type={'button'}
                                                                className={'ml-2'}
                                                                onClick={() =>
                                                                    setState({
                                                                        ...state,
                                                                        coa: originalCoa,
                                                                        editingDetail: false,
                                                                    })
                                                                }
                                                            >
                                                                {`Cancel`}
                                                            </Button>
                                                        </>
                                                    )}
                                                </div>
                                                <Button
                                                    type={'button'}
                                                    onClick={() => toggleActiveModal()}
                                                    color={`${coa?.isActive === true ? 'danger' : 'warning'}`}
                                                >
                                                    {`${coa?.isActive === true ? 'Remove' : 'Reactivate'}`}
                                                </Button>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                    <Col xs={12}>
                                        <Card>
                                            <CardBody>
                                                <h4 className={'card-title'}>{`Chair Signature`}</h4>

                                                <div>
                                                    {signature && (
                                                        <img
                                                            src={signature}
                                                            alt={'CoA Chair Signature'}
                                                            style={{ maxWidth: '100%' }}
                                                        />
                                                    )}
                                                </div>
                                            </CardBody>
                                            <CardFooter>
                                                <Button
                                                    color={'primary'}
                                                    type={'button'}
                                                    onClick={() => {
                                                        setSignatureUpload({
                                                            dto: {
                                                                documentId: 0,
                                                                coaId: idNum,
                                                                title: '',
                                                                fileDownloadName: '',
                                                                fileUrl: '',
                                                                isPublic: false,
                                                            },
                                                            fileUpload: null,
                                                        });
                                                        toggleShowSignatureModal();
                                                    }}
                                                >
                                                    {`Upload Signature`}
                                                </Button>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={6}>
                                <Card>
                                    <CardBody>
                                        <h4 className={'card-title'}>{`CoA Contact`}</h4>

                                        <Row>
                                            <Col xs={12} md={6}>
                                                <FormGroup>
                                                    <Label>{`Name`}</Label>

                                                    {!editingContact && (
                                                        <div>
                                                            <span>{originalCoa?.fullName}</span>
                                                        </div>
                                                    )}
                                                    {editingContact && (
                                                        <Input
                                                            value={coa?.fullName}
                                                            onChange={(e) =>
                                                                setState({
                                                                    ...state,
                                                                    coa: { ...coa, fullName: e.target.value },
                                                                })
                                                            }
                                                        />
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <FormGroup>
                                                    <Label>{`Title`}</Label>

                                                    {!editingContact && (
                                                        <div>
                                                            <span>{originalCoa?.title}</span>
                                                        </div>
                                                    )}
                                                    {editingContact && (
                                                        <Input
                                                            value={coa?.title}
                                                            onChange={(e) =>
                                                                setState({
                                                                    ...state,
                                                                    coa: { ...coa, title: e.target.value },
                                                                })
                                                            }
                                                        />
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12} md={4}>
                                                <FormGroup>
                                                    <Label>{`RAS Email`}</Label>

                                                    {!editingContact && (
                                                        <div>
                                                            <span>{originalCoa?.email}</span>
                                                        </div>
                                                    )}
                                                    {editingContact && (
                                                        <Input
                                                            value={coa?.email}
                                                            onChange={(e) =>
                                                                setState({
                                                                    ...state,
                                                                    coa: { ...coa, email: e.target.value },
                                                                })
                                                            }
                                                        />
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <FormGroup>
                                                    <Label>{`Phone`}</Label>

                                                    {!editingContact && (
                                                        <div>
                                                            <span>{originalCoa?.phone}</span>
                                                        </div>
                                                    )}
                                                    {editingContact && (
                                                        <Input
                                                            value={coa?.phone}
                                                            onChange={(e) =>
                                                                setState({
                                                                    ...state,
                                                                    coa: { ...coa, phone: e.target.value },
                                                                })
                                                            }
                                                        />
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <FormGroup>
                                                    <Label>{`Fax`}</Label>

                                                    {!editingContact && (
                                                        <div>
                                                            <span>{originalCoa?.fax}</span>
                                                        </div>
                                                    )}
                                                    {editingContact && (
                                                        <Input
                                                            value={coa?.fax}
                                                            onChange={(e) =>
                                                                setState({
                                                                    ...state,
                                                                    coa: { ...coa, fax: e.target.value },
                                                                })
                                                            }
                                                        />
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <FormGroup>
                                            <Label>{`Address`}</Label>

                                            {!editingContact && (
                                                <div>
                                                    <span>{originalCoa?.address}</span>
                                                </div>
                                            )}
                                            {editingContact && (
                                                <Input
                                                    value={coa?.address}
                                                    onChange={(e) =>
                                                        setState({ ...state, coa: { ...coa, address: e.target.value } })
                                                    }
                                                />
                                            )}
                                        </FormGroup>

                                        <FormGroup>
                                            <Label>{`Address 2`}</Label>

                                            {!editingContact && (
                                                <div>
                                                    <span>{originalCoa?.address2}</span>
                                                </div>
                                            )}
                                            {editingContact && (
                                                <Input
                                                    value={coa?.address2}
                                                    onChange={(e) =>
                                                        setState({
                                                            ...state,
                                                            coa: { ...coa, address2: e.target.value },
                                                        })
                                                    }
                                                />
                                            )}
                                        </FormGroup>

                                        <FormGroup>
                                            <Label>{`Address 3`}</Label>

                                            {!editingContact && (
                                                <div>
                                                    <span>{originalCoa?.address3}</span>
                                                </div>
                                            )}
                                            {editingContact && (
                                                <Input
                                                    value={coa?.address3}
                                                    onChange={(e) =>
                                                        setState({
                                                            ...state,
                                                            coa: { ...coa, address3: e.target.value },
                                                        })
                                                    }
                                                />
                                            )}
                                        </FormGroup>

                                        <Row>
                                            <Col xs={12} md={6}>
                                                <FormGroup>
                                                    <Label>{`City`}</Label>

                                                    {!editingContact && (
                                                        <div>
                                                            <span>{originalCoa?.city}</span>
                                                        </div>
                                                    )}
                                                    {editingContact && (
                                                        <Input
                                                            value={coa?.city}
                                                            onChange={(e) =>
                                                                setState({
                                                                    ...state,
                                                                    coa: { ...coa, city: e.target.value },
                                                                })
                                                            }
                                                        />
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <FormGroup>
                                                    <Label>{`State`}</Label>

                                                    {!editingContact && (
                                                        <div>
                                                            <span>{originalCoa?.state}</span>
                                                        </div>
                                                    )}
                                                    {editingContact && (
                                                        <Input
                                                            value={coa?.state}
                                                            onChange={(e) =>
                                                                setState({
                                                                    ...state,
                                                                    coa: { ...coa, state: e.target.value },
                                                                })
                                                            }
                                                        />
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <FormGroup>
                                            <Label>{`Zip`}</Label>

                                            {!editingContact && (
                                                <div>
                                                    <span>{originalCoa?.zip}</span>
                                                </div>
                                            )}
                                            {editingContact && (
                                                <Input
                                                    value={coa?.zip}
                                                    onChange={(e) =>
                                                        setState({ ...state, coa: { ...coa, zip: e.target.value } })
                                                    }
                                                />
                                            )}
                                        </FormGroup>
                                    </CardBody>
                                    <CardFooter>
                                        {!editingContact && (
                                            <>
                                                <Button
                                                    color={'primary'}
                                                    type={'button'}
                                                    onClick={() => setState({ ...state, editingContact: true })}
                                                >
                                                    {`Edit`}
                                                </Button>
                                            </>
                                        )}
                                        {editingContact && (
                                            <>
                                                <Button color={'primary'} type={'button'} onClick={() => saveCoa()}>
                                                    {`Submit`}
                                                </Button>

                                                <Button
                                                    color={'secondary'}
                                                    type={'button'}
                                                    className={'ml-2'}
                                                    onClick={() =>
                                                        setState({ ...state, coa: originalCoa, editingContact: false })
                                                    }
                                                >
                                                    {`Cancel`}
                                                </Button>
                                            </>
                                        )}
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </>
                )}

                <Modal
                    isOpen={showSignatureModal}
                    toggle={toggleShowSignatureModal}
                    className={`modal-lg`}
                    centered={true}
                >
                    <ModalHeader>{`Upload Chair Signature`}</ModalHeader>
                    <ModalBody>
                        <FormGroup className={'d-flex flex-column'}>
                            <Label>{`Signature File`}</Label>
                            {signatureUpload?.dto?.fileUrl && (
                                <span className={'mb-2'}>{signatureUpload.dto.fileUrl}</span>
                            )}
                            <Dropzone
                                onDrop={(acceptedFiles) => addStagedSignatureUpload(acceptedFiles)}
                                multiple={false}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <DropZoneContainer>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <p style={{ textAlign: 'center', lineHeight: '80px' }}>
                                                {`Drag & drop a file here, or click to select a file`}
                                            </p>
                                        </div>
                                    </DropZoneContainer>
                                )}
                            </Dropzone>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color={'primary'}
                            className={'mr-2'}
                            type={'button'}
                            onClick={() => saveSignatureUpload()}
                        >{`Upload & Save`}</Button>
                        <Button
                            color={'link'}
                            onClick={() => toggleShowSignatureModal()}
                            type={'button'}
                        >{`Cancel`}</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={showActiveModal} toggle={toggleActiveModal} centered={true}>
                    <ModalHeader>{`${coa?.isActive === true ? 'Remove' : 'Reactivate'} CoA`}</ModalHeader>
                    <ModalBody>
                        <p>
                            {`Please click Confirm below in order to proceed with ${
                                coa?.isActive === true ? 'removing' : 'reactivating'
                            } this CoA.`}
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color={'primary'}
                            className={'mr-2'}
                            type={'button'}
                            onClick={() => toggleActiveState()}
                        >{`Confirm`}</Button>
                        <Button color={'link'} onClick={() => toggleActiveModal()} type={'button'}>{`Cancel`}</Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
};

export default Coa;
