import { Card, CardBody, Col, Form, FormGroup, Row } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { PageTitle } from '../../components';
import { ProfessionDto } from '../../common/Types';
import { professionService } from '../../services/ProfessionAdministration';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

const ProfessionGrid = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    & div {
        flex-basis: 25%;
    }
    &:after {
        content: '';
        flex: auto;
    }
`;

const Professions = () => {
    // List of active professions
    const [activeProfessions, setActiveProfessions] = useState<ProfessionDto[]>();
    // Seperate list to track inactive professions
    const [inactiveProfessions, setInactiveProfessions] = useState<ProfessionDto[]>([]);
    // Handles how many professions to drop into each column
    const [profPerCol, setProfPerCol] = useState(0);
    // Search form
    const { register: registerSearch, handleSubmit: handleSearchSubmit } = useForm();
    // Tracking original list to reset ui after filtering
    const [originalProfessions, setOriginalProfessions] = useState<ProfessionDto[]>();

    useEffect(() => {
        const getProfessions = () => {
            professionService
                .getProfessions()
                .then((response) => {
                    setOriginalProfessions(response);
                    setActiveProfessions(response.filter((x) => x.armsUser));
                    setInactiveProfessions(response.filter((x) => !x.armsUser));
                    setProfPerCol(Math.round(response.length / 4));
                })
                .catch(() => {
                    toast.error('Failed to get Profession list.');
                });
        };

        getProfessions();
    }, [setActiveProfessions, setInactiveProfessions, setOriginalProfessions, setProfPerCol]);

    const onSearchSubmit = (search) => {
        if (search.text !== '') {
            setActiveProfessions(
                activeProfessions?.filter(
                    (x) => x.title.toLocaleLowerCase().indexOf(search.text.toLocaleLowerCase()) > -1,
                ),
            );
            setInactiveProfessions(
                inactiveProfessions?.filter(
                    (x) => x.title.toLocaleLowerCase().indexOf(search.text.toLocaleLowerCase()) > -1,
                ),
            );
        } else {
            setActiveProfessions(originalProfessions?.filter((x) => !x.isDeleted));
            setInactiveProfessions(originalProfessions?.filter((x) => x.isDeleted) ?? []);
        }
    };

    return (
        <>
            {originalProfessions && originalProfessions.length === 1 && (
                <Redirect to={`/admin/profession/${originalProfessions[0].professionId}/general-settings`} />
            )}
            {originalProfessions && originalProfessions.length > 1 && (
                <>
                    <PageTitle title="Admin | Profession" />
                    {activeProfessions && activeProfessions.length > 0 && (
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <h5 className={`card-title`}>{`Select a Profession to Configure`}</h5>
                                        <h6
                                            className={`card-subtitle text-muted`}
                                        >{`If a profession is not appearing below, 
                                            it needs to be enabled within the System Administration - Professions section. If 
                                            you do not have access to System Administration available, please contact a CAAHEP 
                                            Administrator.`}</h6>
                                        <ProfessionGrid className={`mt-3`}>
                                            {activeProfessions.map((prof, i) => (
                                                <div key={i}>
                                                    <p>
                                                        <Link
                                                            to={`/admin/profession/${prof.professionId}/general-settings`}
                                                        >
                                                            {prof.title}
                                                        </Link>
                                                    </p>
                                                </div>
                                            ))}
                                        </ProfessionGrid>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    {/* {inactiveProfessions && inactiveProfessions.length > 0 &&
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody className={`d-flex justify-content-between flex-wrap`}>
                                        <h5 className={`card-title`}>{`Professions Not Configured to use ARMS`}</h5>
                                        {inactiveProfessions.map((prof, i) => (
                                            <div key={i}></div>
                                        ))}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>} */}
                </>
            )}
        </>
    );
};

export default Professions;
