import * as yup from 'yup';

import { Button, Input } from '.';
import { Card, CardBody, CardFooter, Form, FormGroup, Label } from 'reactstrap';
import { CitationDetailDto, CitationDto, ProfessionStandardsDesignationDto, RecommendationDto } from '../common/Types';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { professionService } from '../services/ProfessionAdministration';
import { recommendationService } from '../services/RecommendationService';
import { toast } from 'react-toastify';

interface CitationFormProps {
    citation: CitationDto;
    professionId: number;
    onCancel: () => void;
    onSave: () => void;
}

const CitationForm = (props: CitationFormProps) => {
    const { citation, professionId, onCancel, onSave } = props;

    const [designations, setDesignations] = useState<ProfessionStandardsDesignationDto[]>();

    const citationSchema: yup.ObjectSchema<CitationDto> = yup
        .object({
            citationId: yup.number().required().default(citation.citationId),
            letterId: yup.number().notRequired(),
            standardsDesignationId: yup.number().required().min(1, 'Please select a standard designation'),
            designationNumber: yup.string().notRequired().nullable(),
            designationTitle: yup.string().notRequired().nullable(),
            designationDescription: yup.string().notRequired().nullable(),
            programId: yup.number().notRequired().nullable().default(citation.programId),
            recommendationId: yup.number().required().default(citation.recommendationId),
            rationale: yup.string().notRequired(),
            suggestedDocumentation: yup.string().notRequired(),
            createdBy: yup.string().notRequired().nullable().default(citation.createdBy),
            dateCreated: yup.date().notRequired().nullable().default(moment(citation.dateCreated).toDate()),
            lastEdited: yup.date().notRequired().nullable().default(moment(citation.lastEdited).toDate()),
            editedBy: yup.string().notRequired().nullable().default(citation.editedBy),
            isDeleted: yup.boolean().required().default(false),
            editedByString: yup.string().notRequired().nullable().default(citation.editedByString),
            createdByString: yup.string().notRequired().nullable().default(citation.createdByString),
            detail: yup.array().of(
                yup
                    .object<CitationDetailDto>({
                        citationDetailId: yup.number().required().default(0),
                        citationId: yup.number().required().default(citation.citationId),
                        subStandardId: yup.number().notRequired().nullable(),
                        rationaleId: yup
                            .number()
                            .notRequired()
                            .nullable()
                            .transform((cv, ov) => {
                                return ov === '' ? undefined : +cv;
                            }),
                        subStandardDescription: yup.string().notRequired().nullable(),
                    })
                    .default([] as CitationDetailDto[])
                    .defined(),
            ),
        })
        .defined();

    const { register, handleSubmit, watch, control, errors, setValue } = useForm({
        validationSchema: citationSchema,
        defaultValues: citation,
    });
    const { append, remove, fields } = useFieldArray({
        name: 'detail',
        control: control,
    });

    useEffect(() => {
        const loadDesignations = () => {
            if (professionId) {
                professionService
                    .getProfessionStandardDesignations(professionId)
                    .then((desgs) => {
                        setDesignations(desgs);
                    })
                    .catch(() => {
                        toast.error('Error loading standard designations');
                    });
            }
        };

        loadDesignations();
    }, [professionId]);

    const submitForm = (values: CitationDto) => {
        const toastId = toast.info('Saving citation...');

        recommendationService
            .saveCitationAndDetail(values)
            .then((result) => {
                toast.update(toastId, {
                    render: 'Citation saved successfully',
                    type: 'success',
                });
                onSave();
            })
            .catch(() => {
                toast.update(toastId, {
                    render: 'Error saving citation',
                    type: 'error',
                });
            });
    };

    const standardsDesignationId = +(watch('standardsDesignationId') || -1);
    const selectedDesignation = designations?.find((d) => d.standardsDesignationId === standardsDesignationId);

    const subStandardChanged = (subStandardId: number, isChecked: boolean) => {
        const existingField = fields?.find((f) => +f.subStandardId === +subStandardId);

        if (isChecked && !!!existingField) {
            append({
                citationDetailId: 0,
                citationId: citation.citationId,
                subStandardId: subStandardId,
                rationaleId: undefined,
                subStandardDescription: undefined,
            });
        }

        if (!isChecked && !!existingField) {
            const index = fields.indexOf(existingField);
            remove(index);
        }
    };

    return (
        <Form onSubmit={handleSubmit(submitForm)}>
            {fields &&
                fields.map((f, i) => (
                    <div key={f.id}>
                        <input
                            type={'hidden'}
                            ref={register()}
                            defaultValue={f.citationDetailId}
                            name={`detail[${i}].citationDetailId`}
                        />
                        <input
                            type={'hidden'}
                            ref={register()}
                            defaultValue={f.citationId}
                            name={`detail[${i}].citationId`}
                        />
                        <input
                            type={'hidden'}
                            ref={register()}
                            defaultValue={f.subStandardId}
                            name={`detail[${i}].subStandardId`}
                        />
                        <input
                            type={'hidden'}
                            ref={register()}
                            defaultValue={f.rationaleId}
                            name={`detail[${i}].rationaleId`}
                        />
                    </div>
                ))}
            <Card className={'mb-0'}>
                <CardBody>
                    <h4 className={'card-title'}>{`${citation.citationId > 0 ? 'Edit' : 'New'} Citation`}</h4>

                    <FormGroup>
                        <Label>{`Standard Designation`}</Label>
                        {designations && (
                            <Input type={'select'} name={'standardsDesignationId'} innerRef={register()}>
                                <option value={-1}>{`Select...`}</option>
                                {designations.map((d) => (
                                    <option key={d.standardsDesignationId} value={d.standardsDesignationId}>
                                        {`${d.designationNumber} - ${d.designationTitle}`}
                                    </option>
                                ))}
                            </Input>
                        )}
                        {errors?.standardsDesignationId?.message && (
                            <span className={'text-danger'}>{errors.standardsDesignationId.message}</span>
                        )}
                    </FormGroup>
                    {selectedDesignation && (
                        <FormGroup>
                            <p>{selectedDesignation.designationDescription}</p>
                        </FormGroup>
                    )}
                    {selectedDesignation?.professionStandardsDesignationSubStandard && (
                        <>
                            <FormGroup>
                                {selectedDesignation.professionStandardsDesignationSubStandard
                                    .filter((ss) => ss.isDeleted !== true)
                                    .map((ss, i) => (
                                        <div key={ss.subStandardId} className={'custom-control custom-checkbox mb-2'}>
                                            <Input
                                                type={'checkbox'}
                                                className={'custom-control-input'}
                                                id={`substandard[${i}]`}
                                                checked={fields?.find((d) => +d.subStandardId === +ss.subStandardId)}
                                                onChange={(e) => subStandardChanged(ss.subStandardId, e.target.checked)}
                                            />
                                            <Label
                                                className={'custom-control-label font-weight-normal'}
                                                for={`substandard[${i}]`}
                                            >
                                                {ss.description}
                                            </Label>
                                        </div>
                                    ))}
                                {errors?.detail?.message && (
                                    <span className={'text-danger'}>{errors.detail.message}</span>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label>{`Rationale`}</Label>
                                <Controller
                                    as={Input}
                                    type={'textarea'}
                                    name={`rationale`}
                                    control={control}
                                    rows={10}
                                />
                                {errors?.rationale?.message && (
                                    <span className={'text-danger'}>{errors.rationale.message}</span>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label>{`Requested Documentation`}</Label>
                                <Controller
                                    as={Input}
                                    type={'textarea'}
                                    name={`suggestedDocumentation`}
                                    control={control}
                                    rows={10}
                                />
                                {errors?.suggestedDocumentation?.message && (
                                    <span className={'text-danger'}>{errors.suggestedDocumentation.message}</span>
                                )}
                            </FormGroup>
                        </>
                    )}
                </CardBody>
                <CardFooter>
                    <Button type={'submit'} color={'primary'}>{`Submit`}</Button>
                    <Button
                        type={'button'}
                        color={'secondary'}
                        className={'ml-3'}
                        onClick={() => onCancel()}
                    >{`Cancel`}</Button>
                </CardFooter>
            </Card>
        </Form>
    );
};

export default CitationForm;
