import * as yup from 'yup';

import { AnnualReportNavigationDirection, AnnualReportProps } from '../AnnualReport';
import { AwardTypes, CreditTypes, Days, Months, SatelliteType } from '../../../common/Data';
import { Button, Input, SatelliteForm } from '../../../components';
import {
    Card,
    CardBody,
    CardFooter,
    Col,
    Form,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from 'reactstrap';
import {
    ProgramInformation as ProgramInfoDto,
    ProgramInformationDetail,
    Satellite,
    TabSetting,
} from '../../../common/Types';
import React, { useEffect, useState } from 'react';

import Skeleton from 'react-loading-skeleton';
import { professionService } from '../../../services/ProfessionAdministration';
import { programService } from '../../../services/ProgramService';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

const FlexFormGroup = styled(FormGroup)`
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

const ProgramInformation = (props: AnnualReportProps) => {
    const [programInformation, setProgramInformation] = useState<ProgramInfoDto>();
    const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
    const [professionExpenditures, setProfessionExpenditures] = useState<TabSetting[]>();
    const [submitDirection, setSubmitDirection] = useState<AnnualReportNavigationDirection>(
        AnnualReportNavigationDirection.Forward,
    );

    const programInformationSchema: yup.ObjectSchema<ProgramInfoDto> = yup
        .object({
            programInfoId: yup
                .number()
                .required('This field is required')
                .default(programInformation?.programInfoId || 0),
            programId: yup.number().required('This field is required').default(props.program.programId),
            activeYear: yup
                .number()
                .required('This field is required')
                .default(+props.reportYear),
            certificateAwarded: yup.string().required('This field is required'),
            typeOfAward: yup.string().required('This field is required'),
            lengthOfProgram: yup
                .number()
                .required('This field is required')
                .typeError('This field is required and must be a number'),
            lengthOfProgramAcademicSession: yup
                .number()
                .required('This field is required')
                .typeError('This field is required and must be a number'),
            totalCreditHoursRequired: yup
                .number()
                .required('This field is required')
                .typeError('This field is required and must be a number'),
            typeOfCredits: yup.string().required('This field is required'),
            totalFeesResident: yup
                .number()
                .required('This field is required')
                .typeError('This field is required and must be a number'),
            totalFeesNonResident: yup
                .number()
                .required('This field is required')
                .typeError('This field is required and must be a number'),
            typeOfAward2: yup.string().notRequired(),
            lengthOfProgram2: yup
                .number()
                .notRequired()
                .transform((val, org) => {
                    return !val || val === '' ? undefined : val;
                })
                .typeError('This field must be a number'),
            lengthOfProgramAcademicSession2: yup
                .number()
                .notRequired()
                .transform((val, org) => {
                    return !val || val === '' ? undefined : val;
                })
                .typeError('This field must be a number'),
            totalCreditHoursRequired2: yup
                .number()
                .notRequired()
                .transform((val, org) => {
                    return !val || val === '' ? undefined : val;
                })
                .typeError('This field must be a number'),
            typeOfCredits2: yup.string().notRequired(),
            totalFeesResident2: yup
                .number()
                .notRequired()
                .transform((val, org) => {
                    return !val || val === '' ? undefined : val;
                })
                .typeError('This field must be a number'),
            totalFeesNonResident2: yup
                .number()
                .notRequired()
                .transform((val, org) => {
                    return !val || val === '' ? undefined : val;
                })
                .typeError('This field must be a number'),
            programFiscalYearBeginsOnMonth: yup
                .number()
                .required('This field is required')
                .typeError('This field is required'),
            programFiscalYearBeginsOnDate: yup
                .number()
                .required('This field is required')
                .typeError('This field is required'),
            totalExpenditures: yup.number().notRequired(),
            isBudgetSufficient: yup
                .boolean()
                .typeError('Please select Yes or No.')
                .required('Please select Yes or No.'),
            detailedAnalysisOfBudget: yup.string().when('isBudgetSufficient', {
                is: false,
                then: yup.string().required(`This field is required`),
                otherwise: yup.string().notRequired().default(undefined),
            }),
            actionPlanForBudget: yup.string().when('isBudgetSufficient', {
                is: false,
                then: yup.string().required(`This field is required`),
                otherwise: yup.string().notRequired().default(undefined),
            }),
            createdBy: yup.string().notRequired(),
            dateCreated: yup.date().notRequired(),
            lastEdited: yup.date().notRequired(),
            editedBy: yup.string().notRequired(),
            totalPreviousExpenditures: yup.number().notRequired(),
            createdByString: yup.string().notRequired(),
            editedByString: yup.string().notRequired(),
            programInformationDetail: yup
                .array()
                .of(
                    yup
                        .object<ProgramInformationDetail>({
                            id: yup.number().required().default(0),
                            programInfoId: yup.number().required().default(0),
                            title: yup.string().required('This field is required'),
                            expenditure: yup
                                .number()
                                .required('This field is required')
                                .typeError('This field is required and must be a number'),
                            previousExpenditure: yup
                                .number()
                                .required('This field is required')
                                .typeError('This field is required and must be a number'),
                        })
                        .required(),
                )
                .notRequired(),
            website: yup.string().required('This field is required'),
        })
        .defined();

    const { handleSubmit, register, reset, watch, errors, getValues, setValue } = useForm({
        validationSchema: programInformationSchema,
        defaultValues: programInformation || ({} as ProgramInfoDto),
    });

    const [satellites, setSatellites] = useState<Satellite[]>([]);
    const [selectedSatellite, setSelectedSatellite] = useState<Satellite>();
    const [reloadSatellites, setReloadSatellites] = useState<boolean>(true);
    const [showModal, setModal] = useState(false);

    const toggleModal = () => setModal(!showModal);

    const watchBudget = watch('isBudgetSufficient');
    const watchDetail = watch('programInformationDetail');

    useEffect(() => {
        programService
            .getProgramInformationByProgramIdAndYear(props.program.programId, props.reportYear)
            .then((result) => {
                setProgramInformation(result);
                reset({
                    ...result,
                });
            })
            .catch(() => {
                toast.error('Error loading program information');
            });

        professionService
            .getProgramInformation(props.profession.professionId)
            .then((results) => {
                setProfessionExpenditures(results);
            })
            .catch(() => {
                toast.error('Error loading expenditures');
            });
    }, [setProgramInformation, setProfessionExpenditures, props, reset]);

    useEffect(() => {
        if (reloadSatellites) {
            programService
                .getSatelliteCampusesByProgramId(props.program.programId)
                .then((results) => {
                    setSatellites([...results.filter((s) => s.satalliteTypeId === SatelliteType['Satellite Campus'])]);
                    setReloadSatellites(false);
                })
                .catch(() => {
                    toast.error('Error getting satellites.');
                });
        }
    }, [reloadSatellites, setSatellites, props]);

    const findExpenditureInDetail = (expenditureTitle: string): ProgramInformationDetail | undefined => {
        if (programInformation && programInformation.programInformationDetail) {
            return programInformation.programInformationDetail.find((d) => d.title === expenditureTitle);
        }

        return undefined;
    };

    const getExpTotal = (): number | undefined => {
        if (watchDetail && watchDetail.length > 0) {
            let totalExp = 0;
            let expHasVal = false;

            watchDetail.forEach((detail) => {
                if (detail.expenditure) {
                    expHasVal = true;
                    totalExp += +detail.expenditure;
                }
            });

            return expHasVal ? totalExp : undefined;
        }

        return undefined;
    };

    const getPrevExpTotal = (): number | undefined => {
        if (watchDetail && watchDetail.length > 0) {
            let totalPrevExp = 0;
            let prevExpHasVal = false;

            watchDetail.forEach((detail) => {
                if (detail.previousExpenditure) {
                    prevExpHasVal = true;
                    totalPrevExp += +detail.previousExpenditure;
                }
            });

            return prevExpHasVal ? totalPrevExp : undefined;
        }

        return undefined;
    };

    const submitForm = (values: ProgramInfoDto) => {
        setFormSubmitting(true);
        const toastId = toast.info('Saving Program Information...');

        programService
            .saveProgramInformation(values)
            .then((result) => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Program information successfully saved',
                });

                if (submitDirection === AnnualReportNavigationDirection.Forward) {
                    props.onSavedSuccessfully(AnnualReportNavigationDirection.Forward);
                }
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Error saving program information',
                });
            })
            .finally(() => {
                setFormSubmitting(false);
            });
    };

    const submitWithoutValidation = () => {
        setFormSubmitting(true);
        const toastId = toast.info('Saving Program Information...');

        programService
            .saveProgramInformation(getValues({ nest: true }))
            .then((result) => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Program information successfully saved',
                });

                setProgramInformation(result);
                reset(result);
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Error saving program information',
                });
            })
            .finally(() => {
                setFormSubmitting(false);
            });
    };

    const handleSatelliteSave = () => {
        setReloadSatellites(true);
        setSelectedSatellite(undefined);
        toggleModal();
    };

    const selectSatellite = (satellite: Satellite) => {
        setSelectedSatellite(satellite);
        toggleModal();
    };

    const newSatellite = () => {
        setSelectedSatellite({
            isActive: true,
            programId: +props.program.programId,
            satelliteId: 0,
        });
        toggleModal();
    };

    return (
        <>
            <Row>
                <Col>
                    <h4 className={'card-title'}>Program Information</h4>
                </Col>
            </Row>
            <Row>
                <Col>
                    {programInformation && professionExpenditures && (
                        <>
                            <Form onSubmit={handleSubmit(submitForm)}>
                                <Card>
                                    <CardBody>
                                        <h5
                                            className={'card-subtitle text-muted mb-2'}
                                        >{`* denotes a required field`}</h5>
                                        <input type={'hidden'} ref={register()} name={'programInfoId'} />
                                        <input type={'hidden'} ref={register()} name={'programId'} />
                                        <input type={'hidden'} ref={register()} name={'activeYear'} />
                                        <input type={'hidden'} ref={register()} name={'dateCreated'} />
                                        <input type={'hidden'} ref={register()} name={'createdByString'} />
                                        <input type={'hidden'} ref={register()} name={'editedByString'} />
                                        <input type={'hidden'} ref={register()} name={'lastEdited'} />
                                        <FlexFormGroup>
                                            <Label>Program Title</Label>
                                            <span>{props.program.title}</span>
                                        </FlexFormGroup>
                                        <FlexFormGroup>
                                            <Label>
                                                Name of certificate or degree awarded
                                                <span className={'text-danger ml-1'}>*</span>
                                            </Label>
                                            <Input name={'certificateAwarded'} innerRef={register()} type={'text'} />
                                            {errors?.certificateAwarded?.message && (
                                                <Label className={'text-danger'}>
                                                    {errors.certificateAwarded.message}
                                                </Label>
                                            )}
                                        </FlexFormGroup>
                                        <FlexFormGroup>
                                            <Label>
                                                {`URL to program summary outcomes results required in Standard V.A.4.`}
                                                <span className={'text-danger ml-1'}>*</span>
                                            </Label>
                                            <Input name={'website'} innerRef={register()} type={'text'} />
                                            {errors?.website?.message && (
                                                <Label className={'text-danger'}>{errors.website.message}</Label>
                                            )}
                                        </FlexFormGroup>
                                        <Row className={'my-2'}>
                                            <Col>
                                                <h4 className={'card-title'}>Program Design</h4>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className={'d-flex flex-column'}>
                                                <h5 className={'card-subtitle mb-2'}>Award Level 1</h5>
                                                <FlexFormGroup>
                                                    <Label>
                                                        Type of award granted
                                                        <span className={'text-danger ml-1'}>*</span>
                                                    </Label>
                                                    <select
                                                        className={'select form-control align-self-stretch'}
                                                        ref={register()}
                                                        name={'typeOfAward'}
                                                    >
                                                        <option value={''}>Select...</option>
                                                        {AwardTypes.map((at) => (
                                                            <option key={at} value={at}>
                                                                {at}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errors?.typeOfAward?.message && (
                                                        <Label className={'text-danger'}>
                                                            {errors.typeOfAward.message}
                                                        </Label>
                                                    )}
                                                </FlexFormGroup>
                                                <FlexFormGroup>
                                                    <Label>
                                                        Length of Program in Months
                                                        <span className={'text-danger ml-1'}>*</span>
                                                    </Label>
                                                    <Input
                                                        name={'lengthOfProgram'}
                                                        innerRef={register()}
                                                        type={'text'}
                                                    />
                                                    {errors?.lengthOfProgram?.message && (
                                                        <Label className={'text-danger'}>
                                                            {errors.lengthOfProgram.message}
                                                        </Label>
                                                    )}
                                                </FlexFormGroup>
                                                <FlexFormGroup>
                                                    <Label>
                                                        Length of Program in Academic Sessions
                                                        <span className={'text-danger ml-1'}>*</span>
                                                    </Label>
                                                    <Input
                                                        name={'lengthOfProgramAcademicSession'}
                                                        innerRef={register()}
                                                        type={'text'}
                                                    />
                                                    {errors?.lengthOfProgramAcademicSession?.message && (
                                                        <Label className={'text-danger'}>
                                                            {errors.lengthOfProgramAcademicSession.message}
                                                        </Label>
                                                    )}
                                                </FlexFormGroup>
                                                <FlexFormGroup>
                                                    <Label>
                                                        Total Credit Hours Required
                                                        <span className={'text-danger ml-1'}>*</span>
                                                    </Label>
                                                    <Input
                                                        name={'totalCreditHoursRequired'}
                                                        innerRef={register()}
                                                        type={'text'}
                                                    />
                                                    {errors?.totalCreditHoursRequired?.message && (
                                                        <Label className={'text-danger'}>
                                                            {errors.totalCreditHoursRequired.message}
                                                        </Label>
                                                    )}
                                                </FlexFormGroup>
                                                <FlexFormGroup>
                                                    <Label>
                                                        Type of Credits<span className={'text-danger ml-1'}>*</span>
                                                    </Label>
                                                    <select
                                                        className={'select form-control align-self-stretch'}
                                                        ref={register()}
                                                        name={'typeOfCredits'}
                                                    >
                                                        <option value={''}>Select...</option>
                                                        {CreditTypes.map((ct) => (
                                                            <option key={ct} value={ct}>
                                                                {ct}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errors?.typeOfCredits?.message && (
                                                        <Label className={'text-danger'}>
                                                            {errors.typeOfCredits.message}
                                                        </Label>
                                                    )}
                                                </FlexFormGroup>
                                                <FlexFormGroup>
                                                    <Label>
                                                        Total Program Tuition and Fees - Resident
                                                        <span className={'text-danger ml-1'}>*</span>
                                                    </Label>
                                                    <Input
                                                        name={'totalFeesResident'}
                                                        innerRef={register()}
                                                        type={'text'}
                                                    />
                                                    {errors?.totalFeesResident?.message && (
                                                        <Label className={'text-danger'}>
                                                            {errors.totalFeesResident.message}
                                                        </Label>
                                                    )}
                                                </FlexFormGroup>
                                                <FlexFormGroup>
                                                    <Label>
                                                        Total Program Tuition and Fees - Non-Resident
                                                        <span className={'text-danger ml-1'}>*</span>
                                                    </Label>
                                                    <Input
                                                        name={'totalFeesNonResident'}
                                                        innerRef={register()}
                                                        type={'text'}
                                                    />
                                                    {errors?.totalFeesNonResident?.message && (
                                                        <Label className={'text-danger'}>
                                                            {errors.totalFeesNonResident.message}
                                                        </Label>
                                                    )}
                                                </FlexFormGroup>
                                            </Col>
                                            <Col className={'d-flex flex-column'}>
                                                <h5 className={'card-subtitle mb-2'}>Award Level 2</h5>
                                                <FlexFormGroup>
                                                    <Label>Type of award granted</Label>
                                                    <select
                                                        className={'select form-control align-self-stretch'}
                                                        ref={register()}
                                                        name={'typeOfAward2'}
                                                    >
                                                        <option value={''}>Select...</option>
                                                        {AwardTypes.map((at) => (
                                                            <option key={at} value={at}>
                                                                {at}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errors?.typeOfAward2?.message && (
                                                        <Label className={'text-danger'}>
                                                            {errors.typeOfAward2.message}
                                                        </Label>
                                                    )}
                                                </FlexFormGroup>
                                                <FlexFormGroup>
                                                    <Label>Length of Program in Months</Label>
                                                    <Input
                                                        name={'lengthOfProgram2'}
                                                        innerRef={register()}
                                                        type={'text'}
                                                    />
                                                    {errors?.lengthOfProgram2?.message && (
                                                        <Label className={'text-danger'}>
                                                            {errors.lengthOfProgram2.message}
                                                        </Label>
                                                    )}
                                                </FlexFormGroup>
                                                <FlexFormGroup>
                                                    <Label>Length of Program in Academic Sessions</Label>
                                                    <Input
                                                        name={'lengthOfProgramAcademicSession2'}
                                                        innerRef={register()}
                                                        type={'text'}
                                                    />
                                                    {errors?.lengthOfProgramAcademicSession2?.message && (
                                                        <Label className={'text-danger'}>
                                                            {errors.lengthOfProgramAcademicSession2.message}
                                                        </Label>
                                                    )}
                                                </FlexFormGroup>
                                                <FlexFormGroup>
                                                    <Label>Total Credit Hours Required</Label>
                                                    <Input
                                                        name={'totalCreditHoursRequired2'}
                                                        innerRef={register()}
                                                        type={'text'}
                                                    />
                                                    {errors?.totalCreditHoursRequired2?.message && (
                                                        <Label className={'text-danger'}>
                                                            {errors.totalCreditHoursRequired2.message}
                                                        </Label>
                                                    )}
                                                </FlexFormGroup>
                                                <FlexFormGroup>
                                                    <Label>Type of Credits</Label>
                                                    <select
                                                        className={'select form-control align-self-stretch'}
                                                        ref={register()}
                                                        name={'typeOfCredits2'}
                                                    >
                                                        <option value={''}>Select...</option>
                                                        {CreditTypes.map((ct) => (
                                                            <option key={ct} value={ct}>
                                                                {ct}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errors?.typeOfCredits2?.message && (
                                                        <Label className={'text-danger'}>
                                                            {errors.typeOfCredits2.message}
                                                        </Label>
                                                    )}
                                                </FlexFormGroup>
                                                <FlexFormGroup>
                                                    <Label>Total Program Tuition and Fees - Resident</Label>
                                                    <Input
                                                        name={'totalFeesResident2'}
                                                        innerRef={register()}
                                                        type={'text'}
                                                    />
                                                    {errors?.totalFeesResident2?.message && (
                                                        <Label className={'text-danger'}>
                                                            {errors.totalFeesResident2.message}
                                                        </Label>
                                                    )}
                                                </FlexFormGroup>
                                                <FlexFormGroup>
                                                    <Label>Total Program Tuition and Fees - Non-Resident</Label>
                                                    <Input
                                                        name={'totalFeesNonResident2'}
                                                        innerRef={register()}
                                                        type={'text'}
                                                    />
                                                    {errors?.totalFeesNonResident2?.message && (
                                                        <Label className={'text-danger'}>
                                                            {errors.totalFeesNonResident2.message}
                                                        </Label>
                                                    )}
                                                </FlexFormGroup>
                                            </Col>
                                        </Row>
                                        <Row className={'my-2'}>
                                            <Col>
                                                <h4 className={'card-title'}>Program Budget</h4>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FlexFormGroup>
                                                    <Label>
                                                        {`Program's fiscal year begins on (month/date)`}
                                                        <span className={'text-danger ml-1'}>*</span>
                                                    </Label>
                                                    <Row>
                                                        <Col xs={12} md={6} xl={3}>
                                                            <select
                                                                className={'select form-control align-self-stretch'}
                                                                ref={register()}
                                                                name={'programFiscalYearBeginsOnMonth'}
                                                            >
                                                                <option value={''}>Select...</option>
                                                                {Object.keys(Months)
                                                                    .filter((m) => isNaN(+m))
                                                                    .map((month) => (
                                                                        <option
                                                                            key={Months[month]}
                                                                            value={Months[month]}
                                                                        >
                                                                            {month}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                            <div>
                                                                {errors?.programFiscalYearBeginsOnMonth?.message && (
                                                                    <Label className={'text-danger'}>
                                                                        {errors.programFiscalYearBeginsOnMonth.message}
                                                                    </Label>
                                                                )}
                                                            </div>
                                                        </Col>
                                                        <Col xs={12} md={6} xl={3}>
                                                            <select
                                                                className={'select form-control align-self-stretch'}
                                                                ref={register()}
                                                                name={'programFiscalYearBeginsOnDate'}
                                                            >
                                                                <option value={''}>Select...</option>
                                                                {Days.map((day) => (
                                                                    <option key={day} value={day}>
                                                                        {day}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <div>
                                                                {errors?.programFiscalYearBeginsOnDate?.message && (
                                                                    <Label className={'text-danger'}>
                                                                        {errors.programFiscalYearBeginsOnDate.message}
                                                                    </Label>
                                                                )}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </FlexFormGroup>
                                            </Col>
                                        </Row>
                                        {professionExpenditures && professionExpenditures.length > 0 && (
                                            <Row>
                                                <Col className={'my-2'} xs={12}>
                                                    <Label>{`Indicate the Program's actual expenditures for:`}</Label>
                                                </Col>
                                                <Col className={'d-flex flex-column'}>
                                                    <h5 className={'card-subtitle mb-2'}>
                                                        {`Current Fiscal Year to Date`}
                                                    </h5>
                                                    {professionExpenditures.map((exp, i) => (
                                                        <FlexFormGroup key={`exp-${i}`}>
                                                            <input
                                                                type={'hidden'}
                                                                ref={register()}
                                                                name={`programInformationDetail[${i}].id`}
                                                                defaultValue={
                                                                    findExpenditureInDetail(exp.title)?.id || 0
                                                                }
                                                            />
                                                            <input
                                                                type={'hidden'}
                                                                ref={register()}
                                                                name={`programInformationDetail[${i}].programInfoId`}
                                                                defaultValue={programInformation.programInfoId}
                                                            />
                                                            <input
                                                                type={'hidden'}
                                                                ref={register()}
                                                                name={`programInformationDetail[${i}].title`}
                                                                defaultValue={
                                                                    findExpenditureInDetail(exp.title)?.title ||
                                                                    exp.title
                                                                }
                                                            />
                                                            <Label>
                                                                {exp.title}
                                                                <span className={'text-danger ml-1'}>*</span>
                                                            </Label>
                                                            <InputGroup>
                                                                <InputGroupAddon addonType={'prepend'}>
                                                                    <InputGroupText>$</InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input
                                                                    name={`programInformationDetail[${i}].expenditure`}
                                                                    innerRef={register()}
                                                                    type={'number'}
                                                                    defaultValue={
                                                                        findExpenditureInDetail(exp.title)
                                                                            ?.expenditure || undefined
                                                                    }
                                                                />
                                                            </InputGroup>
                                                            {errors?.programInformationDetail &&
                                                                errors.programInformationDetail[i]?.expenditure
                                                                    ?.message && (
                                                                    <Label className={'text-danger'}>
                                                                        {
                                                                            errors.programInformationDetail[i]
                                                                                .expenditure?.message
                                                                        }
                                                                    </Label>
                                                                )}
                                                        </FlexFormGroup>
                                                    ))}
                                                    <FormGroup>
                                                        <Label>Total</Label>
                                                        {getExpTotal() && (
                                                            <span className={'ml-2'}>
                                                                {getExpTotal()?.toLocaleString('us', {
                                                                    style: 'currency',
                                                                    currency: 'USD',
                                                                })}
                                                            </span>
                                                        )}
                                                        <input
                                                            type={'hidden'}
                                                            ref={register()}
                                                            name={'totalExpenditures'}
                                                            defaultValue={getExpTotal() || 0}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col className={'d-flex flex-column'}>
                                                    <h5 className={'card-subtitle mb-2'}>Previous Fiscal Year</h5>
                                                    {professionExpenditures.map((exp, i) => (
                                                        <FlexFormGroup key={`prevexp-${i}`}>
                                                            <Label>
                                                                {exp.title}
                                                                <span className={'text-danger ml-1'}>*</span>
                                                            </Label>
                                                            <InputGroup>
                                                                <InputGroupAddon addonType={'prepend'}>
                                                                    <InputGroupText>$</InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input
                                                                    name={`programInformationDetail[${i}].previousExpenditure`}
                                                                    innerRef={register()}
                                                                    type={'number'}
                                                                    defaultValue={
                                                                        findExpenditureInDetail(exp.title)
                                                                            ?.previousExpenditure || undefined
                                                                    }
                                                                />
                                                            </InputGroup>
                                                            {errors?.programInformationDetail &&
                                                                errors.programInformationDetail[i]?.previousExpenditure
                                                                    ?.message && (
                                                                    <Label className={'text-danger'}>
                                                                        {
                                                                            errors.programInformationDetail[i]
                                                                                .previousExpenditure?.message
                                                                        }
                                                                    </Label>
                                                                )}
                                                        </FlexFormGroup>
                                                    ))}
                                                    <FormGroup>
                                                        <Label>Total</Label>
                                                        {getPrevExpTotal() && (
                                                            <span className={'ml-2'}>
                                                                {getPrevExpTotal()?.toLocaleString('us', {
                                                                    style: 'currency',
                                                                    currency: 'USD',
                                                                })}
                                                            </span>
                                                        )}
                                                        <input
                                                            type={'hidden'}
                                                            ref={register()}
                                                            name={'totalPreviousExpenditures'}
                                                            defaultValue={getPrevExpTotal() || 0}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        )}
                                        <Row>
                                            <Col>
                                                <FlexFormGroup>
                                                    <Label>
                                                        {`Is the budget sufficient to ensure achievement of the
                                                        Program's goal and outcomes?`}
                                                        <span className={'text-danger ml-1'}>*</span>
                                                    </Label>
                                                    <div className={'d-flex'}>
                                                        <input
                                                            type="hidden"
                                                            name={'isBudgetSufficient'}
                                                            ref={register()}
                                                        />
                                                        <div className={'custom-control custom-radio'}>
                                                            <Input
                                                                type="radio"
                                                                id={'isBudgetSufficientYes'}
                                                                value={true}
                                                                onChange={(e) =>
                                                                    setValue('isBudgetSufficient', e.target.value)
                                                                }
                                                                checked={watchBudget?.toString() === 'true'}
                                                                className={'custom-control-input'}
                                                            />
                                                            <Label
                                                                for={'isBudgetSufficientYes'}
                                                                className={'custom-control-label'}
                                                            >{`Yes`}</Label>
                                                        </div>
                                                        <div className={'custom-control custom-radio ml-3'}>
                                                            <Input
                                                                type="radio"
                                                                id={'isBudgetSufficientNo'}
                                                                value={false}
                                                                onChange={(e) =>
                                                                    setValue('isBudgetSufficient', e.target.value)
                                                                }
                                                                checked={watchBudget?.toString() === 'false'}
                                                                className={'custom-control-input'}
                                                            />
                                                            <Label
                                                                for={'isBudgetSufficientNo'}
                                                                className={'custom-control-label'}
                                                            >{`No`}</Label>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {errors?.isBudgetSufficient?.message && (
                                                            <Label className={'text-danger'}>
                                                                {errors.isBudgetSufficient.message}
                                                            </Label>
                                                        )}
                                                    </div>
                                                </FlexFormGroup>
                                            </Col>
                                        </Row>
                                        {watchBudget?.toString() === 'false' && (
                                            <Row>
                                                <Col>
                                                    <FlexFormGroup>
                                                        <Label>
                                                            If budget is not sufficient, provide analysis and action
                                                            plan
                                                        </Label>
                                                        <Row>
                                                            <Col xs={12} md={6}>
                                                                <Label>Detailed Analysis of Insufficient Budget</Label>
                                                                <Input
                                                                    name={'detailedAnalysisOfBudget'}
                                                                    innerRef={register()}
                                                                    rows={10}
                                                                    type={'textarea'}
                                                                />
                                                                <div>
                                                                    {errors?.detailedAnalysisOfBudget?.message && (
                                                                        <Label className={'text-danger'}>
                                                                            {errors.detailedAnalysisOfBudget.message}
                                                                        </Label>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6}>
                                                                <Label>Action Plan for Insufficent Budget</Label>
                                                                <Input
                                                                    name={'actionPlanForBudget'}
                                                                    innerRef={register()}
                                                                    rows={10}
                                                                    type={'textarea'}
                                                                />
                                                                <div>
                                                                    {errors?.actionPlanForBudget?.message && (
                                                                        <Label className={'text-danger'}>
                                                                            {errors.actionPlanForBudget.message}
                                                                        </Label>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </FlexFormGroup>
                                                </Col>
                                            </Row>
                                        )}
                                        <Row className={'mt-2'}>
                                            <Col>
                                                <h4 className={'card-title'}>Program Satellites</h4>
                                            </Col>
                                        </Row>
                                        {props.canEdit && (
                                            <Row>
                                                <Col>
                                                    <Button color="primary" onClick={() => newSatellite()}>
                                                        <i className={'mdi mdi-plus'} />
                                                        <span className={'ml-1'}>Add Satellite Campus</span>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        )}
                                        <Row className={'mt-3'}>
                                            <Col
                                                className={`d-flex flex-wrap ${
                                                    satellites.length > 1 ? `justify-content-around` : ``
                                                }`}
                                            >
                                                {satellites &&
                                                    satellites.map((sat) => (
                                                        <Card
                                                            className={'d-flex'}
                                                            style={{ flexBasis: 'calc(33% - 10px)' }}
                                                            key={sat.satelliteId}
                                                        >
                                                            <CardBody className={'d-flex justify-content-between'}>
                                                                <div>
                                                                    <h5 className={'card-title'}>{sat.title}</h5>
                                                                    <h6 className={'card-subtitle text-muted'}>
                                                                        {`${sat.city}, ${sat.state} ${sat.postalCode}`}
                                                                    </h6>
                                                                </div>
                                                                <Button
                                                                    color="info"
                                                                    onClick={() => selectSatellite(sat)}
                                                                >
                                                                    <i className={'remixicon-pencil-line'}></i>
                                                                </Button>
                                                            </CardBody>
                                                        </Card>
                                                    ))}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    {props.canEdit && (
                                        <CardFooter className={'d-flex justify-content-between'}>
                                            <Button
                                                type={'button'}
                                                color={'warning'}
                                                onClick={() => props.onNavigate(AnnualReportNavigationDirection.Back)}
                                            >
                                                <i className={'mdi mdi-arrow-left'} />
                                                <span className={'mr-1'}>{`Previous`}</span>
                                            </Button>

                                            <Button
                                                disabled={formSubmitting}
                                                type={'button'}
                                                color={'primary'}
                                                onClick={() => submitWithoutValidation()}
                                            >
                                                <i className={'mdi mdi-content-save'} />
                                                <span className={'ml-1'}>{`Save`}</span>
                                            </Button>

                                            <Button
                                                disabled={formSubmitting}
                                                type={'submit'}
                                                color={'info'}
                                                onClick={() =>
                                                    setSubmitDirection(AnnualReportNavigationDirection.Forward)
                                                }
                                            >
                                                <span className={'mr-1'}>{`Save & Continue`}</span>
                                                <i className={'mdi mdi-arrow-right'} />
                                            </Button>
                                        </CardFooter>
                                    )}
                                </Card>
                            </Form>

                            <Modal isOpen={showModal} toggle={toggleModal} size={'lg'} centered={true}>
                                <ModalHeader toggle={toggleModal}>{`${
                                    selectedSatellite ? `Edit` : `Add`
                                } Satellite Campus`}</ModalHeader>
                                <ModalBody>
                                    <SatelliteForm
                                        satellite={selectedSatellite}
                                        programId={props.program.programId}
                                        onSave={() => handleSatelliteSave()}
                                        onCancel={() => toggleModal()}
                                    />
                                </ModalBody>
                            </Modal>
                        </>
                    )}
                    {!programInformation && <Skeleton count={30} />}
                </Col>
            </Row>
        </>
    );
};

export default ProgramInformation;
