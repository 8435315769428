import { Card, CardBody, Col, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { InvoicingDetailDto } from '../../types';
import React, { useState } from 'react';

import { Button } from '../../components';
import { invoiceService } from '../../services/InvoiceService';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { generateYears } from '../../common/Utils';
import UnpaidInvoicesTable from './UnpaidInvoicesTable';

const Unpaid = () => {
    const [invoices, setInvoices] = useState<InvoicingDetailDto[]>();
    const [fiscalYear, setFiscalYear] = useState<string>();
    const { register, handleSubmit } = useForm();
    const [reload, setReload] = useState(false);

    const onSubmit = () => {
        const toastId = toast.info('Searching...', {
            autoClose: false,
        });
        invoiceService
            .getUnpaidInvoices(fiscalYear || '')
            .then((response) => {
                setInvoices(response);
                toast.update(toastId, {
                    type: 'success',
                    autoClose: 500,
                });
            })
            .catch(() => toast.error('Failed to get unpaid invoices'));
    };

    const exportReport = () => {
        const toastId = toast.info('Generating unpaid invoice report, this may take a minute...', {
            autoClose: false,
        });

        invoiceService
            .getUnpaidInvoiceReportExport(fiscalYear || '')
            .then((response) => {
                const downloadLink = window.document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(
                    new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
                );
                downloadLink.download = 'Unpaid Invoice Report.xlsx';
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                toast.update(toastId, {
                    render: 'Unpaid Invoice report generated successfully.',
                    type: 'success',
                    autoClose: 2500,
                });
            })
            .catch(() => toast.error('Failed to export report'));
    };

    const reloadInvoices = () => {
        setInvoices(undefined);
        setReload(true);
    };

    return (
        <>
            <Card>
                <CardBody>
                    <Row>
                        <Col>
                            <div className="mb-3">
                                <h3>Unpaid Invoice Report</h3>
                            </div>
                            <Form onSubmit={handleSubmit(onSubmit)} inline>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="fiscalYear" className="mr-sm-2">
                                        Fiscal Year
                                    </Label>
                                    <Input
                                        name="fiscalYear"
                                        innerRef={register()}
                                        defaultValue={fiscalYear}
                                        type="select"
                                        onChange={(e) => setFiscalYear(e.target.value)}
                                    >
                                        <option value="">Select Year</option>
                                        {generateYears().map((ct) => (
                                            <option key={ct} value={ct}>
                                                {ct}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <div>
                                    <Button type="submit">Search</Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col style={{ display: 'flex' }}>
                            <div className="mr-2">
                                <Button color="info" onClick={() => exportReport()}>
                                    Export Report
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <div className="mt-3">
                        <h4>Unpaid Invoices</h4>
                    </div>
                </CardBody>
                {invoices && (
                    <div>
                        <div className="ml-3">
                            <h5>Total: {invoices.length}</h5>
                        </div>
                        <UnpaidInvoicesTable invoices={invoices} onSave={() => reloadInvoices()} />
                    </div>
                )}
                {!invoices && (
                    <Table>
                        <tr></tr>
                    </Table>
                )}
            </Card>
        </>
    );
};

export default Unpaid;
