import {
    Button,
    Card,
    CardBody,
    Col,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import {
    Institution,
    Institution as InstitutionModel,
    InstitutionPeopleDto,
    ProfessionDto,
    ProgramDto,
} from '../../common/Types';
import { NavLink, Redirect, useParams } from 'react-router-dom';
import { Input, PageTitle, RelatedDocumentsList } from '../../components';
import React, { useEffect, useState } from 'react';

import AddOnTracks from './program-tabs/AddOnTracks';
import AnnualReports from './program-tabs/AnnualReports';
import { InstitutionPersonRole } from '../../common/Data';
import Notes from '../../components/NotesList';
import Probation from './program-tabs/Probation';
import Program from './Program';
import ProgramAwardLetter from './program-tabs/ProgramAwardLetter';
import { Program as ProgramModel } from '../../common/Types';
import ProgramPersonnel from './program-tabs/ProgramPersonnel';
import Satellites from './program-tabs/Satellites';
import Skeleton from 'react-loading-skeleton';
import StatusHistory from './program-tabs/StatusHistory';
import { institutionService } from '../../services/InstitutionService';
import { personnelService } from '../../services/Personnel';
import { professionService } from '../../services/ProfessionAdministration';
import { programService } from '../../services/ProgramService';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { register } from '../../serviceWorker';
import { AmsPermissionClaimType, authService } from '../../services/Auth';

const ProgramWidget = styled.div`
    display: flex;
    flex-basis: calc(33% - 10px);

    &.half-widget {
        flex-basis: calc(50% - 10px);
    }

    &.full-widget {
        flex-basis: calc(100% - 10px);
    }
`;

interface ProgramOverviewParams {
    id?: string;
    programid?: string;
}

const ProgramOverview = () => {
    const { id, programid } = useParams<ProgramOverviewParams>();
    const [institution, setInstitution] = useState<InstitutionModel>();
    const [program, setProgram] = useState<ProgramModel>();
    const [profession, setProfession] = useState<ProfessionDto>();
    const [annualReportRequired, setAnnualReportRequired] = useState<boolean | null>(null);
    const [institutionPersonnel, setInstitutionPersonnel] = useState<InstitutionPeopleDto[]>();
    const [otherPrograms, setOtherPrograms] = useState<ProgramDto[]>([]);
    const [reloadProgram, setReloadProgram] = useState<boolean>(true);
    const [reload, setReload] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const toggleRemoveModal = () => setShowRemoveModal(!showRemoveModal);
    const [transferInstitutionId, setTransferInstitutionId] = useState<number>();
    const [showTransferModal, setShowTransferModal] = useState<boolean>(false);
    const toggleTransferModal = () => setShowTransferModal(!showTransferModal);
    const [institutions, setInstitutions] = useState<Institution[]>([]);
    const [navigateToId, setNavigateToId] = useState<number>();

    const institutionId = +(id || 0);
    const programId = +(programid || 0);

    const startRemoveProgram = () => {
        toggleRemoveModal();
    };

    const startTransferProgram = () => {
        toggleTransferModal();
    };

    const removeProgram = () => {
        if (program !== undefined) {
            const toastId = toast.info('Removing program...');
            programService
                .saveProgram({
                    ...program,
                    active: false,
                    visible: false,
                })
                .then(() => {
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Program removed successfully',
                    });
                    toggleRemoveModal();
                    setNavigateToId(program.institutionId);
                })
                .catch(() => {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Error removing program',
                    });
                });
        }
    };
    const transferProgram = () => {
        if (program !== undefined && transferInstitutionId !== undefined) {
            const toastId = toast.info('Transferring program...');
            programService
                .transferProgram(program.programId, program.institutionId, transferInstitutionId)
                .then(() => {
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Program transferred successfully',
                    });
                    toggleTransferModal();
                    setNavigateToId(transferInstitutionId);
                })
                .catch(() => {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Error transferring program',
                    });
                });
        }
    };
    useEffect(() => {
        institutionService
            .getInstitutionDetails(institutionId)
            .then((response) => {
                setInstitution(response);
                if (response.programDto) {
                    setOtherPrograms([
                        ...response.programDto.filter((r) => r.programId !== programId && r.active === true),
                    ]);
                }
            })
            .catch(() => {
                toast.error('Failed to load the institution.');
            });
    }, [setInstitution, setOtherPrograms, institutionId, programId]);

    useEffect(() => {
        institutionService
            .getInstitutions()
            .then((response) => {
                setInstitutions(response);
            })
            .catch(() => {
                toast.error('Error loading institutions');
            });
    }, [setInstitutions]);

    useEffect(() => {
        const getProgram = () => {
            if (reloadProgram) {
                setReloadProgram(false);
                programService
                    .getProgram(programId)
                    .then((response) => {
                        setProgram({ ...new ProgramModel(response) });
                    })
                    .catch(() => {
                        toast.error('Failed to load the program.');
                    });
            }
        };

        getProgram();
    }, [setProgram, programId, reloadProgram]);

    useEffect(() => {
        const getProfession = () => {
            if (program && program.professionId) {
                professionService
                    .getProfesssion(program.professionId)
                    .then((response) => {
                        setProfession(response);
                    })
                    .catch(() => {
                        toast.error('Failed to load the profession.');
                    });
            }
        };

        getProfession();
    }, [setProfession, program]);

    useEffect(() => {
        if (program) {
            programService
                .getProgramsNeedingAnnualReportByProfessionId(program.professionId)
                .then((results) => {
                    setAnnualReportRequired(results.find((p) => p.programId === program.programId) !== undefined);
                })
                .catch(() => {
                    toast.error('Error validating annual report status');
                });
        }
    }, [setAnnualReportRequired, program]);

    useEffect(() => {
        if (institution && program) {
            personnelService
                .getAmsPersonnelByInsitutionId(institution.institutionId)
                .then((results) => {
                    setInstitutionPersonnel([...results.filter((r) => r.programId)]);
                })
                .catch(() => {
                    toast.error('Error loading personnel');
                });
        }
    }, [setInstitutionPersonnel, institution, program]);
    if (navigateToId) {
        return <Redirect to={`/institution/${navigateToId}`} />;
    } else {
        return (
            <>
                {institution && program && (
                    <PageTitle>
                        <div className={'d-flex align-items-center'}>
                            <NavLink
                                style={{ textDecoration: 'underline' }}
                                to={`/institution/${institutionId}`}
                                className={'page-title'}
                            >
                                <h4>{institution.title}</h4>
                            </NavLink>
                            <h4 className={`mx-1`}>{`-`}</h4>
                            <h4 className={`page-title`}>{program.title}</h4>
                        </div>
                    </PageTitle>
                )}

                <Row>
                    <Col xs={12} md={8} className={'d-flex align-items-stretch'}>
                        <Card>
                            <CardBody className={'d-flex flex-column'}>
                                {!program?.active && (
                                    <Row>
                                        <Col>
                                            <div className="page-title-box">
                                                <h4 className="page-title" style={{ color: '#f1556c' }}>
                                                    REMOVED
                                                </h4>
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                <h5 className={'card-title'}>Program Overview</h5>
                                {program && <Program program={program} onSaved={() => setReloadProgram(true)} />}
                                {!program && <Skeleton count={10} />}
                            </CardBody>
                        </Card>
                    </Col>
                    {program && institution && (
                        <Col xs={12} md={4} className={'d-flex flex-wrap justify-content-between'}>
                            {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') && (
                                <ProgramWidget className={'full-widget'}>
                                    <div className="mr-2">
                                        <Button onClick={() => startTransferProgram()} type={'button'} color={'info'}>
                                            <i className={'mdi mdi-pencil'} />
                                            <span>{`Transfer Program`}</span>
                                        </Button>
                                    </div>
                                    <div className="mr-2">
                                        <Button onClick={() => startRemoveProgram()} type={'button'} color={'danger'}>
                                            <i className={'mdi mdi-delete'} />
                                            <span>{`Remove Program`}</span>
                                        </Button>
                                    </div>
                                </ProgramWidget>
                            )}
                            <ProgramWidget className={'full-widget'}>
                                <StatusHistory program={program} onStatusChanged={() => setReloadProgram(true)} />
                            </ProgramWidget>
                            {profession && profession.armsUser && (
                                <ProgramWidget className={'full-widget'}>
                                    {annualReportRequired !== null && (
                                        <AnnualReports program={program} annualReportRequired={annualReportRequired} />
                                    )}
                                </ProgramWidget>
                            )}
                        </Col>
                    )}
                    {program && institution && (
                        <>
                            <Col xs={12}>
                                <Row>
                                    <Col className={'d-flex flex-wrap justify-content-between'}>
                                        {institutionPersonnel && (
                                            <>
                                                <ProgramWidget className={'half-widget'}>
                                                    <ProgramPersonnel
                                                        program={program}
                                                        type={InstitutionPersonRole.Dean}
                                                        personnel={institutionPersonnel}
                                                        otherPrograms={otherPrograms}
                                                    />
                                                </ProgramWidget>
                                                <ProgramWidget className={'half-widget'}>
                                                    <ProgramPersonnel
                                                        program={program}
                                                        type={InstitutionPersonRole['Program Director']}
                                                        personnel={institutionPersonnel}
                                                        otherPrograms={otherPrograms}
                                                    />
                                                </ProgramWidget>
                                            </>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12}>
                                <Row>
                                    <Col className={'d-flex flex-wrap justify-content-between'}>
                                        <ProgramWidget className={'half-widget'}>
                                            <RelatedDocumentsList
                                                institutionId={institution.institutionId}
                                                programId={program.programId}
                                            />
                                        </ProgramWidget>
                                        <ProgramWidget className={'half-widget'}>
                                            <AddOnTracks
                                                professionId={program.professionId}
                                                programId={program.programId}
                                                institutionId={institution.institutionId}
                                            />
                                        </ProgramWidget>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={'d-flex flex-wrap justify-content-between'}>
                                        <ProgramWidget className={'full-widget'}>
                                            <ProgramAwardLetter
                                                institutionId={institutionId}
                                                programId={programId}
                                                programTitle={program.title}
                                            />
                                        </ProgramWidget>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={'d-flex flex-wrap justify-content-between'}>
                                        {program && (
                                            <ProgramWidget className={'full-widget'}>
                                                <Probation
                                                    programId={programId}
                                                    programTitle={program.title}
                                                    isOnProbation={program.onProbation === true}
                                                    onProbationStatusChanged={() => setReloadProgram(true)}
                                                    institutionId={institutionId}
                                                />
                                            </ProgramWidget>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={'d-flex flex-wrap justify-content-between'}>
                                        <ProgramWidget className={'half-widget'}>
                                            <Satellites programId={programId} />
                                        </ProgramWidget>
                                        {program && (
                                            <ProgramWidget className={'half-widget'}>
                                                <Notes programId={program.programId} />
                                            </ProgramWidget>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                        </>
                    )}
                </Row>
                <Modal isOpen={showRemoveModal} toggle={toggleRemoveModal} centered={true}>
                    <ModalHeader>{`Remove Program`}</ModalHeader>
                    <ModalBody>
                        <Label>{`Click confirm to remove ${program?.title}. This cannot be undone. `}</Label>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color={'danger'}
                            className={'mr-2'}
                            type={'button'}
                            onClick={() => removeProgram()}
                        >{`Confirm`}</Button>
                        <Button color={'link'} onClick={() => toggleRemoveModal()} type={'button'}>{`Cancel`}</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={showTransferModal} toggle={toggleTransferModal} centered={true}>
                    <ModalHeader>Transfer Program</ModalHeader>
                    <ModalBody>
                        <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                            <Label>Select Institution to Transfer To</Label>
                            <Input
                                type="select"
                                innerRef={register()}
                                name="transferInstitutionId"
                                style={{ width: '100%' }}
                                onChange={(e) =>
                                    setTransferInstitutionId(
                                        institutions?.find((i) => i.institutionId === +e.target.value)?.institutionId,
                                    )
                                }
                            >
                                <option value={''}>Select...</option>
                                {institutions?.map((i) => (
                                    <option key={i.institutionId} value={i.institutionId}>
                                        {i.title}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color={'primary'}
                            className={'mr-2'}
                            type={'button'}
                            onClick={() => transferProgram()}
                        >{`Submit`}</Button>
                        <Button color={'link'} onClick={() => toggleTransferModal()} type={'button'}>{`Cancel`}</Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
};

export default ProgramOverview;
