import React from 'react';
import { ErrorMessage, NestDataObject, FieldError } from 'react-hook-form';

type Props = {
    className?: string;
    name: string;
    message: string;
    errors: NestDataObject<Record<string, any>, FieldError>;
};

/**
 * Formatted form error
 * @param {Props} props
 */
const FormError = (props: Props) => {
    return (
        <div {...props} role="alert" className={`text-danger ${props.className ?? ''}`}>
            <ErrorMessage errors={props.errors} name={props.name} message={props.message} />
        </div>
    );
};

export default FormError;
