import { ApiUriArms, http, httpDownloadFile } from './HttpHelper';

class AnnualReportService {
    public getPdf = async (professionId: number, programId: number, reportYear: number): Promise<Blob | undefined> => {
        const result = await httpDownloadFile(
            ApiUriArms,
            `/annualreport/generate-pdf/${professionId}/${programId}/${reportYear}`,
            undefined,
        );

        if (result.ok) {
            try {
                return result.parsedBody;
            } catch (ex) {
                console.log(ex);
                throw ex;
            }
        } else {
            throw Error(result.statusText);
        }
    };
}

export const annualReportService = new AnnualReportService();
