import { APIUriAms, http, httpDownloadFile } from './HttpHelper';
import {
    InvoiceSearchDto,
    InvoicingDto,
    InvoicingDetailDto,
    InvoicePaymentsDto,
    PaymentSearchDto,
    InvoicePaymentDetailDto,
    ApplyAdministrativeFeesDto,
    InvoiceGenerationSuccessFailDto,
    InvoicingActivityDto,
} from '../types';
import { PaymentIntent } from '../types/InvoicingDto';

class InvoiceService {
    public getInvoices = async (search: InvoiceSearchDto) => {
        const result = await http<InvoicingDto[]>(APIUriAms, `/invoice`, undefined, 'POST', search);
        const pagingHeader = result.headers.get('X-Pagination');
        const total = pagingHeader ? JSON.parse(pagingHeader).TotalCount : 0;
        if (result.parsedBody) {
            return {
                invoices: result.parsedBody.map((invoice) => {
                    invoice.invoiceDate = new Date(invoice.invoiceDate);
                    invoice.amount = +(invoice.amount?.toFixed(2) ?? 0);
                    invoice.dueAmount = +(invoice.dueAmount?.toFixed(2) ?? 0);
                    return invoice;
                }),
                total: total,
            };
        } else {
            throw new Error(result.statusText);
        }
    };

    public getInvoice = async (invoiceId: number) => {
        const result = await http<InvoicingDetailDto>(APIUriAms, `/invoice/${invoiceId}`, undefined, undefined, null);
        if (result.parsedBody) {
            return {
                ...result.parsedBody,
                invoiceDate: new Date(result.parsedBody.invoiceDate),
                amount: +(result.parsedBody.amount?.toFixed(2) ?? 0),
                dueAmount: +(result.parsedBody.dueAmount?.toFixed(2) ?? 0),
            };
        } else {
            throw new Error(result.statusText);
        }
    };

    public getPublicInvoice = async (invoiceId: number) => {
        const result = await http<InvoicingDetailDto>(
            APIUriAms,
            `/invoice/public/${invoiceId}`,
            undefined,
            undefined,
            null,
        );
        if (result.parsedBody) {
            return {
                ...result.parsedBody,
                invoiceDate: new Date(result.parsedBody.invoiceDate),
                amount: +(result.parsedBody.amount?.toFixed(2) ?? 0),
                dueAmount: +(result.parsedBody.dueAmount?.toFixed(2) ?? 0),
            };
        } else {
            throw new Error(result.statusText);
        }
    };

    public getPublicInvoiceByNumber = async (invoiceNumber: string) => {
        const result = await http<InvoicingDetailDto>(
            APIUriAms,
            `/invoice/public/bynumber/${invoiceNumber}`,
            undefined,
            undefined,
            null,
        );
        if (result.parsedBody) {
            return {
                ...result.parsedBody,
                invoiceDate: new Date(result.parsedBody.invoiceDate),
                amount: +(result.parsedBody.amount?.toFixed(2) ?? 0),
                dueAmount: +(result.parsedBody.dueAmount?.toFixed(2) ?? 0),
            };
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveInvoice = async (invoiceId: number, invoice: InvoicingDto = {} as InvoicingDto) => {
        const result = await http<InvoicingDto>(APIUriAms, `/invoice/${invoiceId}`, undefined, 'POST', invoice);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveInvoicePayment = async (payment: InvoicePaymentsDto) => {
        const result = await http<InvoicePaymentsDto>(APIUriAms, `/invoice/payment`, undefined, 'POST', payment);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getBouncedInvoices = async () => {
        const result = await http<InvoicingDto[]>(APIUriAms, `/invoice/bounced`, undefined, undefined, null);
        if (result.parsedBody) {
            return result.parsedBody.map((invoice) => {
                invoice.invoiceDate = new Date(invoice.invoiceDate);
                invoice.amount = +(invoice.amount?.toFixed(2) ?? 0);
                invoice.dueAmount = +(invoice.dueAmount?.toFixed(2) ?? 0);
                return invoice;
            });
        } else {
            throw new Error(result.statusText);
        }
    };

    public generateAnnualInvoices = async (invoice: InvoicingDto) => {
        const result = await http<InvoiceGenerationSuccessFailDto>(
            APIUriAms,
            `/invoice/generate-annual-invoices`,
            undefined,
            'POST',
            invoice,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getInvoicePayments = async (search: PaymentSearchDto) => {
        const result = await http<InvoicePaymentDetailDto[]>(
            APIUriAms,
            `/invoice/payment-search`,
            undefined,
            'POST',
            search,
        );
        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getCashReportExport = async (search: PaymentSearchDto) => {
        const result = await httpDownloadFile(
            APIUriAms,
            `/invoice/payment-export`,
            JSON.stringify(search),
            'POST',
            'application/json',
        );
        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getUnpaidInvoiceReportExport = async (fiscalYear: string) => {
        const result = await httpDownloadFile(
            APIUriAms,
            `/invoice/unpaid-export/${fiscalYear}`,
            null,
            'POST',
            undefined,
        );
        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public applyAdministrativeFees = async (adminFees: ApplyAdministrativeFeesDto) => {
        const result = await http<InvoicingDetailDto>(
            APIUriAms,
            `/invoice/late-fees/${adminFees.fiscalYear}/${adminFees.lateFee}`,
            undefined,
            'POST',
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public emailInvoices = async (invoiceIds: number[], altEmail: string | null = null) => {
        const result = await http<InvoicingDetailDto>(
            APIUriAms,
            altEmail ? `/invoice/email/${encodeURIComponent(altEmail)}` : `/invoice/email`,
            undefined,
            'POST',
            invoiceIds,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public setProbation = async (invoiceIds: number[]) => {
        const result = await http<InvoicingDetailDto>(
            APIUriAms,
            `/invoice/probation/`,
            undefined,
            'POST',
            invoiceIds,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getUnsentInvoices = async (year: number) => {
        const result = await http<InvoicingDto[]>(APIUriAms, `/invoice/unsent/${year}`, undefined, undefined, null);
        if (result.parsedBody) {
            return result.parsedBody.map((invoice) => {
                invoice.invoiceDate = new Date(invoice.invoiceDate);
                invoice.amount = +(invoice.amount?.toFixed(2) ?? 0);
                invoice.dueAmount = +(invoice.dueAmount?.toFixed(2) ?? 0);
                return invoice;
            });
        } else {
            throw new Error(result.statusText);
        }
    };

    public getUnpaidInvoices = async (year: string) => {
        const result = await http<InvoicingDetailDto[]>(
            APIUriAms,
            `/invoice/unpaid/${year}`,
            undefined,
            undefined,
            null,
        );
        if (result.parsedBody) {
            return result.parsedBody.map((invoice) => {
                invoice.invoiceDate = new Date(invoice.invoiceDate);
                invoice.amount = +(invoice.amount?.toFixed(2) ?? 0);
                invoice.dueAmount = +(invoice.dueAmount?.toFixed(2) ?? 0);
                return invoice;
            });
        } else {
            throw new Error(result.statusText);
        }
    };

    public getFailedInvoices = async () => {
        const result = await http<InvoicingDetailDto[]>(APIUriAms, `/invoice/failed`, undefined, undefined, null);
        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public dismissActivity = async (activityId: number) => {
        const result = await http<InvoicingActivityDto>(
            APIUriAms,
            `/invoice/dismiss-activity/${activityId}`,
            undefined,
            'POST',
        );
        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public dismissBounces = async (invoiceId: number) => {
        const result = await http<InvoicingActivityDto[]>(
            APIUriAms,
            `/invoice/dismiss-bounced-activity/${invoiceId}`,
            undefined,
            'POST',
        );
        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public confirmPayments = async (paymentIds: number[]) => {
        const result = await http<InvoicePaymentsDto[]>(
            APIUriAms,
            `/invoice/confirm-payment`,
            undefined,
            'POST',
            paymentIds,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public createPaymentIntent = async (amount: number, email: string, description: string) => {
        const result = await http<PaymentIntent>(APIUriAms, `/invoice/payment-intent`, undefined, 'POST', {
            amount,
            email,
            description,
        });

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getPaymentIntent = async (stripeInvoiceId: string) => {
        const result = await http<PaymentIntent>(
            APIUriAms,
            `/invoice/payment-intent/${stripeInvoiceId}`,
            undefined,
            undefined,
            null,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public updatePaymentIntent = async (id: string, amount: number, email: string, description: string) => {
        const result = await http<PaymentIntent>(APIUriAms, `/invoice/payment-intent`, undefined, 'PATCH', {
            id,
            amount,
            email,
            description,
        });

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };
}

export const invoiceService = new InvoiceService();
