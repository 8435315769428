import React from 'react';
import styled from 'styled-components';

const RibbonDiv = styled.div`
    position: relative;
    clear: both;
    padding: 5px 12px;
    margin-bottom: 15px;
    box-shadow: 2px 5px 10px rgba(50, 58, 70, 0.15);
    color: white;
    font-size: 13px;
    font-weight: 600;

    &::before {
        content: ' ';
        border-style: solid;
        border-width: 10px;
        display: block;
        position: absolute;
        bottom: -10px;
        left: 0;
        margin-bottom: -10px;
        z-index: -1;
    }
    &.float-left {
        margin-left: -30px;
        border-radius: 0 3px 3px 0;
    }
    &.float-right {
        margin-right: -30px;
        border-radius: 3px 0 0 3px;

        &::before {
            right: 0;
        }
        &.shape {
            clip-path: polygon(2% 0, 100% 1%, 100% 100%, 0 100%, 15% 50%);
            padding-left: 25px;
            margin-right: -24px;
        }
    }
    &.float-center {
        span {
            margin: 0 auto 20px auto;
        }
    }
`;

/**
 * Ribbon
 *
 * Directions:
 * Add .ribbon-box to container to attach
 * CardBody needs a z-index of 0
 *
 * TODO: Make configurable. Color, float
 */

interface RibbonProps {
    color?: string;
    float?: string;
}
const Ribbon = (props) => {
    return (
        <RibbonDiv className={`ribbon-${props.color || 'info'} float-${props.float || 'left'}`}>
            {props.children}
        </RibbonDiv>
    );
};

export default Ribbon;
