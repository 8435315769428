import {
    Alert,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from 'reactstrap';
import { AnnualReportNavigationDirection, AnnualReportProps } from '../AnnualReport';
import { Button, EnrollmentRetentionForm, PageTitle } from '../../../components';
import {
    EnrollmentRetentionDetail,
    EnrollmentRetention as EnrollmentRetentionDto,
    EnrollmentRetentionReportInfo,
    PositivePlacement,
    ProgramOutcomes,
    Satellite,
} from '../../../common/Types';
import React, { useEffect, useState } from 'react';

import { SatelliteType } from '../../../common/Data';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import { programService } from '../../../services/ProgramService';
import { toast } from 'react-toastify';

const EnrollmentRetention = (props: AnnualReportProps) => {
    const [enrollmentRetention, setEnrollmentRetention] = useState<EnrollmentRetentionDto[]>();
    const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
    const [reloadCohorts, setReloadCohorts] = useState<boolean>(false);
    const [selectedCohort, setSelectedCohort] = useState<EnrollmentRetentionDto>();
    const [showModal, setModal] = useState(false);
    const [positivePlacementDetail, setPositivePlacementDetail] = useState<PositivePlacement[]>();
    const [reloadPositivePlacement, setReloadPositivePlacement] = useState<boolean>(true);
    const [attritionRetentionOutcome, setAttritionRetention] = useState<ProgramOutcomes>();
    const [positivePlacementOutcome, setPositivePlacement] = useState<ProgramOutcomes>();
    const [programSatellites, setProgramSatellites] = useState<Satellite[]>();
    const [reportStartYear] = useState<number>(
        props.settings?.reportingWindow?.yearAvarageStart || +props.reportYear - 1,
    );
    const [showAttritionRetentionPlan, setShowAttritionRetentionPlan] = useState<boolean>(false);
    const [showPositivePlacementPlan, setShowPositivePlacementPlan] = useState<boolean>(false);

    const toggleModal = () => setModal(!showModal);

    useEffect(() => {
        const loadReportWindowData = () => {
            if (props.program && props.settings && reportStartYear > 0) {
                programService
                    .getEnrollmentRetentionDataByProgramIdReportYearReportWindow(
                        props.program.programId,
                        props.settings.reportingWindow.activeYear || moment().year(),
                        props.settings.reportingWindow.reportingWindow || 1,
                        reportStartYear,
                    )
                    .then((results) => {
                        if (!results || results.length === 0) {
                            const toastId = toast.info('Looking for prior Annual Report Cohort Information...');
                            programService
                                .initializeEnrollmentRetentionDataByProgramIdReportYearReportWindow(
                                    props.program.programId,
                                    props.settings.reportingWindow.activeYear || moment().year(),
                                    props.settings.reportingWindow.reportingWindow || 1,
                                    reportStartYear,
                                )
                                .then((initialized) => {
                                    toast.update(toastId, {
                                        type: 'success',
                                        render: 'Prior Annual Report Cohort Information loaded.',
                                    });

                                    setEnrollmentRetention(initialized);
                                    setSelectedCohort(
                                        initialized.sort((a, b) => {
                                            return moment(b.enrollmentDate!).unix() - moment(a.enrollmentDate!).unix();
                                        })[0],
                                    );
                                })
                                .catch(() => {
                                    toast.update(toastId, {
                                        type: 'error',
                                        render: 'Error loading prior Annual Report Cohort information.',
                                    });
                                });
                        } else {
                            setEnrollmentRetention(results);
                            setSelectedCohort(
                                results.sort((a, b) => {
                                    return moment(b.enrollmentDate!).unix() - moment(a.enrollmentDate!).unix();
                                })[0],
                            );
                        }
                    })
                    .catch(() => {
                        toast.error('Error loading cohort data.');
                    });
            }
        };

        loadReportWindowData();
    }, [props, setEnrollmentRetention, reportStartYear, setSelectedCohort]);

    useEffect(() => {
        const getCohorts = () => {
            if (props.program && props.settings && reloadCohorts && reportStartYear) {
                setReloadCohorts(false);
                programService
                    .getEnrollmentRetentionDataByProgramIdReportYearReportWindow(
                        props.program.programId,
                        props.settings.reportingWindow.activeYear || moment().year(),
                        props.settings.reportingWindow.reportingWindow || 1,
                        reportStartYear,
                    )
                    .then((results) => {
                        setEnrollmentRetention(results);
                    })
                    .catch(() => {
                        toast.error('Error reloading cohort data.');
                    });
            }
        };

        getCohorts();
    }, [reloadCohorts, setEnrollmentRetention, props.program, props.settings, reportStartYear]);

    useEffect(() => {
        const loadOutcomeDetail = () => {
            if (props.program && props.settings) {
                programService
                    .getProgramOutcomeDetail(props.program.programId, +props.reportYear)
                    .then((results) => {
                        let attRetOutcome = results.find(
                            (o) => o.reportYear === +props.reportYear && o.outcomeTypeId === 4,
                        );
                        let posPlaceOutcome = results.find(
                            (o) => o.reportYear === +props.reportYear && o.outcomeTypeId === 5,
                        );

                        if (attRetOutcome) {
                            attRetOutcome = {
                                ...attRetOutcome,
                                threshold: +props.settings.reportingWindow.attritionRetentionThreshold,
                                reportWindowEndYear: reportStartYear,
                                reportWindowStartYear:
                                    reportStartYear - (props.settings.reportingWindow.reportingWindow || 1) + 1,
                            };
                        }

                        if (posPlaceOutcome) {
                            posPlaceOutcome = {
                                ...posPlaceOutcome,
                                threshold: +props.settings.reportingWindow.attritionRetentionThreshold,
                                reportWindowEndYear: reportStartYear,
                                reportWindowStartYear:
                                    reportStartYear - (props.settings.reportingWindow.reportingWindow || 1) + 1,
                            };
                        }

                        setAttritionRetention(
                            attRetOutcome || {
                                detailId: 0,
                                outcomeTypeId: 4,
                                programId: props.program.programId,
                                reportYear: +props.reportYear,
                                detailedAnalysis: null,
                                actionPlan: null,
                                createdBy: null,
                                dateCreated: null,
                                editedBy: null,
                                lastEdited: null,
                                outcomeType: null,
                                threshold: +props.settings.reportingWindow.attritionRetentionThreshold,
                                reportWindowEndYear: reportStartYear,
                                reportWindowStartYear:
                                    reportStartYear - (props.settings.reportingWindow.reportingWindow || 1) + 1,
                                oneYearAverage: null,
                                threeYearAverage: null,
                                reportWindowAverage: null,
                            },
                        );

                        setPositivePlacement(
                            posPlaceOutcome || {
                                detailId: 0,
                                outcomeTypeId: 5,
                                programId: props.program.programId,
                                reportYear: +props.reportYear,
                                detailedAnalysis: null,
                                actionPlan: null,
                                createdBy: null,
                                dateCreated: null,
                                editedBy: null,
                                lastEdited: null,
                                outcomeType: null,
                                threshold: +props.settings.reportingWindow.positivePlacementThreshold,
                                reportWindowEndYear: reportStartYear,
                                reportWindowStartYear:
                                    reportStartYear - (props.settings.reportingWindow.reportingWindow || 1) + 1,
                                oneYearAverage: null,
                                threeYearAverage: null,
                                reportWindowAverage: null,
                            },
                        );
                    })
                    .catch(() => {
                        toast.error('Error loading attrition / retention outcomes.');
                    });
            }
        };

        loadOutcomeDetail();
    }, [props, setAttritionRetention, setPositivePlacement, reportStartYear]);

    const getEachReportYear = (): number[] => {
        const years = [] as number[];

        for (
            let i = reportStartYear;
            i >= reportStartYear - ((props.settings.reportingWindow.reportingWindow || 1) - 1);
            i--
        ) {
            years.push(i);
        }

        return years;
    };

    useEffect(() => {
        const loadPositivePlacementDetail = () => {
            if (props.program && props.settings && enrollmentRetention && reloadPositivePlacement) {
                setReloadPositivePlacement(false);
                programService
                    .getPositivePlacementDetailByProgramId(props.program.programId)
                    .then((results) => {
                        const ppDetail: PositivePlacement[] = getEachReportYear().map((year) => {
                            const result = results.find((pp) => pp.graduatingYear === year);

                            return (
                                result || {
                                    graduatingYear: year,
                                    id: 0,
                                    programId: props.program.programId,
                                    numberOfGradsEmployed: 0,
                                    numberOfGradsNotEmployed: 0,
                                }
                            );
                        });
                        setPositivePlacementDetail([...ppDetail]);
                    })
                    .catch(() => {
                        toast.error('Error loading positive placement detail.');
                    });
            }
        };

        const loadProgramSatellites = () => {
            if (props.program && enrollmentRetention) {
                programService
                    .getSatelliteCampusesByProgramId(props.program.programId)
                    .then((results) => {
                        setProgramSatellites(
                            results.filter((r) => r.satalliteTypeId === SatelliteType['Satellite Campus']),
                        );
                    })
                    .catch(() => {
                        toast.error('Error loading satellites.');
                    });
            }
        };

        loadPositivePlacementDetail();
        loadProgramSatellites();
    }, [
        props,
        enrollmentRetention,
        setPositivePlacementDetail,
        setProgramSatellites,
        reloadPositivePlacement,
        reloadPositivePlacement,
    ]);

    const getGraduatesByYear = (graduationYear: number): number => {
        if (enrollmentRetention) {
            const cohorts = enrollmentRetention.map((c) => {
                return c.enrollmentRetentionDetailsDto || [];
            });
            const cohortGrads = ([] as EnrollmentRetentionDetail[]).concat
                .apply([], cohorts)
                .filter((c) => c.graduationYear === graduationYear);

            let graduates = 0;

            cohortGrads.forEach((c) => {
                graduates += c.graduated || 0;
            });

            return graduates;
        }

        return 0;
    };

    const getGraduatesByGraduationYear = (graduationYear: number): number => {
        if (enrollmentRetention) {
            const cohorts = enrollmentRetention
                .filter((c) => moment(c.onTimeGraduationDate)?.year() === graduationYear)
                .map((c) => {
                    return c.enrollmentRetentionDetailsDto || [];
                });
            const cohortGrads = ([] as EnrollmentRetentionDetail[]).concat.apply([], cohorts);

            let graduates = 0;

            cohortGrads.forEach((c) => {
                graduates += c.graduated || 0;
            });

            return graduates;
        }

        return 0;
    };

    const getInitiallyEnrolledByYear = (graduationYear: number): number => {
        if (enrollmentRetention) {
            const cohorts = enrollmentRetention.filter(
                (c) => moment(c.onTimeGraduationDate)?.year() === graduationYear,
            );

            let enrolled = 0;

            cohorts.forEach((c) => {
                enrolled += c.numberOfStudentsInitiallyEnrolled || 0;
            });

            return enrolled;
        }

        return 0;
    };

    const getAddedToClassByYear = (graduationYear: number): number => {
        if (enrollmentRetention) {
            const cohorts = enrollmentRetention
                .filter((c) => moment(c.onTimeGraduationDate)?.year() === graduationYear)
                .map((c) => {
                    return c.enrollmentRetentionReportInfoDto || [];
                });
            const cohortInfo = ([] as EnrollmentRetentionReportInfo[]).concat.apply([], cohorts);

            let added = 0;

            cohortInfo.forEach((c) => {
                added += c.numberAddedToClass || 0;
            });

            return added;
        }

        return 0;
    };

    const getAttritionDueToGenEd = (graduationYear: number): number => {
        if (enrollmentRetention) {
            const cohorts = enrollmentRetention
                .filter((c) => moment(c.onTimeGraduationDate)?.year() === graduationYear)
                .map((c) => {
                    return c.enrollmentRetentionReportInfoDto || [];
                });
            const cohortInfo = ([] as EnrollmentRetentionReportInfo[]).concat.apply([], cohorts);

            let attrition = 0;

            cohortInfo.forEach((c) => {
                attrition += c.attritionDueToGeneralEducationCourses || 0;
            });

            return attrition;
        }

        return 0;
    };

    const getAttritionDueToNonAcademic = (graduationYear: number): number => {
        if (enrollmentRetention) {
            const cohorts = enrollmentRetention
                .filter((c) => moment(c.onTimeGraduationDate)?.year() === graduationYear)
                .map((c) => {
                    return c.enrollmentRetentionReportInfoDto || [];
                });
            const cohortInfo = ([] as EnrollmentRetentionReportInfo[]).concat.apply([], cohorts);

            let attrition = 0;

            cohortInfo.forEach((c) => {
                attrition += c.attritionDueToNonAcademicReasons || 0;
            });

            return attrition;
        }

        return 0;
    };

    const getAttritionDueToProfCourses = (graduationYear: number): number => {
        if (enrollmentRetention) {
            const cohorts = enrollmentRetention
                .filter((c) => moment(c.onTimeGraduationDate)?.year() === graduationYear)
                .map((c) => {
                    return c.enrollmentRetentionReportInfoDto || [];
                });
            const cohortInfo = ([] as EnrollmentRetentionReportInfo[]).concat.apply([], cohorts);

            let attrition = 0;

            cohortInfo.forEach((c) => {
                attrition += c.attritionDueToProfessionalCourses || 0;
            });

            return attrition;
        }

        return 0;
    };

    const getDroppedOut = (graduationYear: number): number => {
        if (enrollmentRetention) {
            const cohorts = enrollmentRetention
                .filter((c) => moment(c.onTimeGraduationDate)?.year() === graduationYear)
                .map((c) => {
                    return c.enrollmentRetentionReportInfoDto || [];
                });
            const cohortInfo = ([] as EnrollmentRetentionReportInfo[]).concat.apply([], cohorts);

            let totalAttrition = 0;

            cohortInfo.forEach((c) => {
                totalAttrition += c.droppedOut || 0;
            });

            return totalAttrition;
        }

        return 0;
    };

    const getInProgressOrStoppedOut = (graduationYear: number): number => {
        if (enrollmentRetention) {
            const cohorts = enrollmentRetention
                .filter((c) => moment(c.onTimeGraduationDate)?.year() === graduationYear)
                .map((c) => {
                    return c.enrollmentRetentionReportInfoDto || [];
                });
            const cohortInfo = ([] as EnrollmentRetentionReportInfo[]).concat.apply([], cohorts);

            let inProgress = 0;

            cohortInfo.forEach((c) => {
                inProgress += c.inProgressOrStoppedOut || 0;
            });

            return inProgress;
        }

        return 0;
    };

    const getAttritionPercentageCalcByGraduationYear = (graduationYear: number): number => {
        if (enrollmentRetention) {
            const cohorts = enrollmentRetention
                .filter((c) => moment(c.onTimeGraduationDate)?.year() === graduationYear)
                .map((c) => {
                    return c.enrollmentRetentionReportInfoDto || [];
                });
            const cohortInfo = ([] as EnrollmentRetentionReportInfo[]).concat.apply([], cohorts);

            let totalStudentsInClass = 0;
            let droppedOut = 0;

            cohortInfo.forEach((c) => {
                totalStudentsInClass += c.totalStudentsInClass || 0;
                droppedOut += c.droppedOut || 0;
            });

            if (totalStudentsInClass > 0) {
                if (props.settings.reportingWindow.outcomeMeasure?.toUpperCase() === 'ATTRITION') {
                    return (droppedOut / totalStudentsInClass) * 100;
                } else {
                    return ((totalStudentsInClass - droppedOut) / totalStudentsInClass) * 100;
                }
            } else {
                return 0;
            }
        }

        return 0;
    };

    const selectCohortToEdit = (cohort?: EnrollmentRetentionDto) => {
        setSelectedCohort(cohort);
        toggleModal();
    };

    const initializeNewCohort = (): EnrollmentRetentionDto => {
        return {
            enrollmentRetentionId: 0,
            enrollmentYear: 0,
            programId: props.program.programId,
            dateCreated: new Date(),
            enrollmentDate: new Date(`1/1/${reportStartYear}`),
            onTimeGraduationDate: new Date(`1/1/${reportStartYear}`),
            estimatedNumberOfApplicants: 0,
            maximumNumberOfStudents: 0,
            numberOfStudentsInitiallyEnrolled: 0,
            enrollmentRetentionDetailsDto: [
                {
                    detailId: 0,
                    enrollmentRetentionId: 0,
                    graduationYear: reportStartYear,
                    reportYear: props.reportYear,
                    graduated: 0,
                },
            ],
            enrollmentRetentionReportInfoDto: [
                {
                    enrollmentRetentionId: 0,
                    id: 0,
                    reportYear: props.reportYear,
                    attritionDueToGeneralEducationCourses: 0,
                    attritionDueToNonAcademicReasons: 0,
                    attritionDueToProfessionalCourses: 0,
                    numberAddedToClass: 0,
                },
            ],
        };
    };

    const cohortFormSaved = (cohort?: EnrollmentRetentionDto) => {
        setReloadCohorts(true);
        setReloadPositivePlacement(true);
        setSelectedCohort(cohort);
        toggleModal();
    };

    let totalStudents = 0;
    let totalDroppedOut = 0;
    let totalGraduates = 0;

    let threeYearGraduates = 0;
    let threeYearStudents = 0;
    let threeYearDroppedOut = 0;

    if (enrollmentRetention) {
        getEachReportYear().forEach((year) => {
            const cohorts = enrollmentRetention.filter((er) => moment(er.onTimeGraduationDate)?.year() === year);

            cohorts.forEach((cohort) => {
                if (cohort.enrollmentRetentionReportInfoDto && cohort.enrollmentRetentionReportInfoDto[0]) {
                    totalStudents += cohort.enrollmentRetentionReportInfoDto[0].totalStudentsInClass || 0;
                    totalDroppedOut += cohort.enrollmentRetentionReportInfoDto[0].droppedOut || 0;

                    if (year <= reportStartYear && year >= reportStartYear - 2) {
                        threeYearStudents += cohort.enrollmentRetentionReportInfoDto[0].totalStudentsInClass || 0;
                        threeYearDroppedOut += cohort.enrollmentRetentionReportInfoDto[0].droppedOut || 0;
                    }
                }
            });
        });
    }

    if (enrollmentRetention) {
        getEachReportYear().forEach((year) => {
            enrollmentRetention.forEach((cohort) => {
                if (cohort.enrollmentRetentionDetailsDto) {
                    cohort.enrollmentRetentionDetailsDto.forEach((graduates) => {
                        if (graduates.graduationYear === year) {
                            totalGraduates += graduates.graduated || 0;

                            if (year <= reportStartYear && year >= reportStartYear - 2) {
                                threeYearGraduates += graduates.graduated || 0;
                            }
                        }
                    });
                }
            });
        });
    }

    const attrition = totalStudents > 0 ? (totalDroppedOut / totalStudents) * 100 : 0;
    const retention = totalStudents > 0 ? ((totalStudents - totalDroppedOut) / totalStudents) * 100 : 0;

    const getAttritionRetentionClass = (attrition: number, retention: number): string => {
        if (props.settings.reportingWindow.outcomeMeasure === 'Attrition') {
            if ((attrition || 0) < props.settings.reportingWindow.attritionRetentionThreshold || 0) {
                return 'text-success';
            }
        } else {
            if ((retention || 0) >= props.settings.reportingWindow.attritionRetentionThreshold || 0) {
                return 'text-success';
            }
        }

        return 'text-danger';
    };

    const getThreeYearAttritionRetentionClass = (): string => {
        if (threeYearStudents > 0) {
            if (props.settings.reportingWindow.outcomeMeasure === 'Attrition') {
                const threeYearAttrition = (threeYearDroppedOut / threeYearStudents) * 100;
                if ((threeYearAttrition || 0) < props.settings.reportingWindow.attritionRetentionThreshold || 0) {
                    return 'text-success';
                }
            } else {
                const threeYearRetention = ((threeYearStudents - threeYearDroppedOut) / threeYearStudents) * 100;
                if (threeYearRetention >= props.settings.reportingWindow.attritionRetentionThreshold || 0) {
                    return 'text-success';
                }
            }
        } else {
            return '';
        }

        return 'text-danger';
    };

    const getPositivePlacementEmployedByGraduationYear = (year: number): number => {
        if (positivePlacementDetail) {
            return positivePlacementDetail.find((p) => p.graduatingYear === year)?.numberOfGradsEmployed || 0;
        }

        return 0;
    };

    const getPositivePlacementNotEmployedByGraduationYear = (year: number): number => {
        if (positivePlacementDetail) {
            return positivePlacementDetail.find((p) => p.graduatingYear === year)?.numberOfGradsNotEmployed || 0;
        }

        return 0;
    };

    const calcPositivePlacementByYear = (year: number): number => {
        if (positivePlacementDetail) {
            const employed = getPositivePlacementEmployedByGraduationYear(year);
            const notEmployed = getPositivePlacementNotEmployedByGraduationYear(year);

            return +employed + +notEmployed;
        }

        return 0;
    };

    const calcPositivePlacementPercentageByYear = (year: number): number | undefined => {
        if (positivePlacementDetail) {
            const totalGrads = getGraduatesByYear(year);
            const positivePlacement = calcPositivePlacementByYear(year);

            if (totalGrads > 0) {
                return (+positivePlacement / +totalGrads) * 100;
            } else {
                return 0;
            }
        }

        return 0;
    };

    const updateEmployed = (year: number, newNumber: number): void => {
        if (positivePlacementDetail && newNumber >= 0) {
            let pp = positivePlacementDetail.find((p) => p.graduatingYear === year);
            const maxGrads = getGraduatesByYear(year);

            if (!pp) {
                pp = {
                    graduatingYear: year,
                    id: 0,
                    programId: props.program.programId,
                    numberOfGradsEmployed: newNumber > maxGrads ? maxGrads : newNumber,
                    numberOfGradsNotEmployed: 0,
                } as PositivePlacement;

                setPositivePlacementDetail([...positivePlacementDetail, pp]);
            } else {
                const newList = [...positivePlacementDetail];
                const existingIndex = newList.indexOf(pp);
                pp.numberOfGradsEmployed =
                    (pp.numberOfGradsNotEmployed || 0) + newNumber > maxGrads
                        ? maxGrads - (pp.numberOfGradsNotEmployed || 0)
                        : newNumber;
                newList.splice(existingIndex, 1, pp);
                setPositivePlacementDetail([...newList]);
            }
        }
    };

    const updateNotEmployed = (year: number, newNumber: number): void => {
        if (positivePlacementDetail && newNumber >= 0) {
            let pp = positivePlacementDetail.find((p) => p.graduatingYear === year);
            const maxGrads = getGraduatesByYear(year);

            if (!pp) {
                pp = {
                    graduatingYear: year,
                    id: 0,
                    programId: props.program.programId,
                    numberOfGradsEmployed: 0,
                    numberOfGradsNotEmployed: newNumber > maxGrads ? maxGrads : newNumber,
                } as PositivePlacement;

                setPositivePlacementDetail([...positivePlacementDetail, pp]);

                return;
            } else {
                const newList = [...positivePlacementDetail];
                const existingIndex = newList.indexOf(pp);
                pp.numberOfGradsNotEmployed =
                    (pp.numberOfGradsEmployed || 0) + newNumber > maxGrads
                        ? maxGrads - (pp.numberOfGradsEmployed || 0)
                        : newNumber;

                newList.splice(existingIndex, 1, pp);
                setPositivePlacementDetail([...newList]);
            }
        }
    };

    const totalPositivePlacement = (): number => {
        let total = 0;

        getEachReportYear().forEach((year) => {
            total += calcPositivePlacementByYear(year);
        });

        return total;
    };

    const threeYearPositivePlacement = (): number => {
        let total = 0;

        getEachReportYear().forEach((year) => {
            if (year <= reportStartYear && year >= reportStartYear - 2) {
                total += calcPositivePlacementByYear(year);
            }
        });

        return total;
    };

    const postiivePlacementAverage = (): number => {
        if (totalGraduates > 0) {
            return (totalPositivePlacement() / totalGraduates) * 100;
        }

        return 0;
    };

    const threeYearPositivePlacementAverage = (): number => {
        if (threeYearGraduates > 0) {
            return (threeYearPositivePlacement() / threeYearGraduates) * 100;
        }

        return 0;
    };

    const positivePlacementClass = (): string => {
        if (props.settings) {
            return postiivePlacementAverage() >= props.settings.reportingWindow.positivePlacementThreshold
                ? 'text-success'
                : 'text-danger';
        }

        return 'text-danger';
    };

    const threeYearPositivePlacementClass = (): string => {
        if (props.settings) {
            return threeYearPositivePlacementAverage() >= props.settings.reportingWindow.positivePlacementThreshold
                ? 'text-success'
                : 'text-danger';
        }

        return 'text-danger';
    };

    const isValid = (): boolean => {
        let isValid = true;

        if (
            positivePlacementOutcome &&
            ((positivePlacementOutcome.oneYearAverage || 0) > 100 ||
                (positivePlacementOutcome.reportWindowAverage || 0) > 100)
        ) {
            toast.error('Positive placement average cannot be greater than 100%');
            isValid = false;
        }

        if (enrollmentRetention) {
            enrollmentRetention.forEach((e) => {
                if (e.enrollmentRetentionReportInfoDto) {
                    const negatives = e.enrollmentRetentionReportInfoDto.filter(
                        (i) => (i.inProgressOrStoppedOut || 0) < 0,
                    );
                    if (negatives.length > 0) {
                        toast.error(
                            'Please correct any cohorts that have any negative In Progress or Stopped Out counts.',
                        );
                        isValid = false;
                    }
                }
            });
        }

        return isValid;
    };

    const saveOutcomeAndPositivePlacementDetail = (validate: boolean): void => {
        if (positivePlacementOutcome && attritionRetentionOutcome && positivePlacementDetail) {
            if (validate && !isValid()) {
                return;
            }

            setFormSubmitting(true);
            const toastId = toast.info(
                `Saving ${props.settings.reportingWindow.outcomeMeasure} and positive placement detail`,
            );
            const outcomePromise = programService.saveProgramOutcomeDetail([
                attritionRetentionOutcome,
                positivePlacementOutcome,
            ]);

            const positivePlacementPromises = positivePlacementDetail.map((pp) => {
                return programService.savePositivePlacementDetailByProgramId(props.program.programId, pp);
            });

            Promise.all([outcomePromise, positivePlacementPromises])
                .then(() => {
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Information saved successfully',
                    });

                    if (validate) {
                        props.onSavedSuccessfully(AnnualReportNavigationDirection.Forward);
                    } else {
                        setReloadPositivePlacement(true);
                    }
                })
                .catch(() => {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Error saving information',
                    });
                })
                .finally(() => {
                    setFormSubmitting(false);
                });
        }
    };

    useEffect(() => {
        if (positivePlacementOutcome) {
            if (positivePlacementDetail) {
                let oneYearGrads = 0;
                let oneYearEmployed = 0;
                let oneYearNotEmployed = 0;
                let totalGrads = 0;
                let totalEmployed = 0;
                let totalNotEmployed = 0;
                let threeYearGrads = 0;
                let threeYearEmployed = 0;
                let threeYearNotEmployed = 0;
                const reportYears = getEachReportYear();

                positivePlacementDetail.forEach((d) => {
                    if (reportYears.find((y) => y === d.graduatingYear)) {
                        const grads = getGraduatesByYear(d.graduatingYear);
                        totalGrads += grads;
                        totalEmployed += d.numberOfGradsEmployed || 0;
                        totalNotEmployed += d.numberOfGradsNotEmployed || 0;

                        if (reportStartYear === d.graduatingYear) {
                            oneYearGrads = grads;
                            oneYearEmployed = d.numberOfGradsEmployed || 0;
                            oneYearNotEmployed = d.numberOfGradsNotEmployed || 0;
                        }

                        if (reportStartYear >= d.graduatingYear && d.graduatingYear >= reportStartYear - 2) {
                            threeYearGrads += grads;
                            threeYearEmployed += d.numberOfGradsEmployed || 0;
                            threeYearNotEmployed += d.numberOfGradsNotEmployed || 0;
                        }
                    }
                });

                let oneYearAverage = 0;
                let threeYearAverage = 0;
                let reportWindowAverage = 0;

                if (oneYearGrads > 0) {
                    oneYearAverage = ((oneYearEmployed + oneYearNotEmployed) / oneYearGrads) * 100;
                } else {
                    oneYearAverage = 100;
                }

                if (threeYearGrads > 0) {
                    threeYearAverage = ((threeYearEmployed + threeYearNotEmployed) / threeYearGrads) * 100;
                } else {
                    threeYearAverage = 100;
                }

                if (totalGrads) {
                    reportWindowAverage = ((totalNotEmployed + totalEmployed) / totalGrads) * 100;
                } else {
                    reportWindowAverage = 100;
                }

                const newOutcome = {
                    ...positivePlacementOutcome,
                    threshold: +props.settings.reportingWindow.positivePlacementThreshold,
                    oneYearAverage: oneYearAverage,
                    threeYearAverage: threeYearAverage,
                    reportWindowAverage: reportWindowAverage,
                };
                const thisThreshold = newOutcome.threshold || 0;

                setPositivePlacement(newOutcome);
                setShowPositivePlacementPlan(
                    newOutcome.reportWindowAverage < thisThreshold || newOutcome.oneYearAverage < thisThreshold,
                );
            } else {
                setPositivePlacement({
                    ...positivePlacementOutcome,
                    threshold: +props.settings.reportingWindow.positivePlacementThreshold,
                    oneYearAverage: null,
                    threeYearAverage: null,
                    reportWindowAverage: null,
                });
                setShowPositivePlacementPlan(false);
            }
        }
    }, [positivePlacementDetail, props.settings]);

    useEffect(() => {
        if (attritionRetentionOutcome) {
            if (enrollmentRetention) {
                let oneYearTotalEnrollment = 0;
                let oneYearDroppedOut = 0;
                let totalEnrollment = 0;
                let totalDroppedOut = 0;
                let threeYearTotalEnrollment = 0;
                let threeYearDroppedOut = 0;

                enrollmentRetention.forEach((c) => {
                    if (
                        moment(c.onTimeGraduationDate).year() >=
                            (attritionRetentionOutcome?.reportWindowStartYear || 3000) &&
                        moment(c.onTimeGraduationDate).year() <=
                            (attritionRetentionOutcome?.reportWindowEndYear || 1900)
                    ) {
                        totalEnrollment += c.numberOfStudentsInitiallyEnrolled || 0;

                        if (moment(c.onTimeGraduationDate).year() === reportStartYear) {
                            oneYearTotalEnrollment += c.numberOfStudentsInitiallyEnrolled || 0;
                        }

                        if (
                            moment(c.onTimeGraduationDate).year() <= reportStartYear &&
                            moment(c.onTimeGraduationDate).year() >= reportStartYear - 2
                        ) {
                            threeYearTotalEnrollment += c.numberOfStudentsInitiallyEnrolled || 0;
                        }

                        if (c.enrollmentRetentionReportInfoDto) {
                            c.enrollmentRetentionReportInfoDto.forEach((d) => {
                                totalEnrollment += d.numberAddedToClass || 0;
                                totalDroppedOut += d.droppedOut || 0;

                                if (moment(c.onTimeGraduationDate).year() === reportStartYear) {
                                    oneYearTotalEnrollment += d.numberAddedToClass || 0;
                                    oneYearDroppedOut += d.droppedOut || 0;
                                }

                                if (
                                    moment(c.onTimeGraduationDate).year() <= reportStartYear &&
                                    moment(c.onTimeGraduationDate).year() >= reportStartYear - 2
                                ) {
                                    threeYearTotalEnrollment += d.numberAddedToClass || 0;
                                    threeYearDroppedOut += d.droppedOut || 0;
                                }
                            });
                        }
                    }
                });

                let oneYearAverage = 0;
                let threeYearAverage = 0;
                let reportWindowAverage = 0;

                if (props.settings.reportingWindow.outcomeMeasure?.toUpperCase() === 'ATTRITION') {
                    oneYearAverage = oneYearDroppedOut / oneYearTotalEnrollment;
                    threeYearAverage = threeYearDroppedOut / threeYearTotalEnrollment;
                    reportWindowAverage = totalDroppedOut / totalEnrollment;

                    const newOutcome = {
                        ...attritionRetentionOutcome,
                        threshold: +props.settings.reportingWindow.attritionRetentionThreshold,
                        oneYearAverage: oneYearAverage * 100,
                        threeYearAverage: threeYearAverage * 100,
                        reportWindowAverage: reportWindowAverage * 100,
                    };
                    const thisThreshold = newOutcome.threshold || 0;

                    setAttritionRetention(newOutcome);
                    setShowAttritionRetentionPlan(
                        newOutcome.reportWindowAverage > thisThreshold || newOutcome.oneYearAverage > thisThreshold,
                    );
                } else {
                    oneYearAverage = (oneYearTotalEnrollment - oneYearDroppedOut) / oneYearTotalEnrollment;
                    threeYearAverage = (threeYearTotalEnrollment - threeYearDroppedOut) / threeYearTotalEnrollment;
                    reportWindowAverage = (totalEnrollment - totalDroppedOut) / totalEnrollment;

                    const newOutcome = {
                        ...attritionRetentionOutcome,
                        threshold: +props.settings.reportingWindow.attritionRetentionThreshold,
                        oneYearAverage: oneYearAverage * 100,
                        threeYearAverage: threeYearAverage * 100,
                        reportWindowAverage: reportWindowAverage * 100,
                    };
                    const thisThreshold = newOutcome.threshold || 0;

                    setAttritionRetention(newOutcome);
                    setShowAttritionRetentionPlan(
                        newOutcome.reportWindowAverage < thisThreshold || newOutcome.oneYearAverage < thisThreshold,
                    );
                }
            } else {
                setAttritionRetention({
                    ...attritionRetentionOutcome,
                    threshold: +props.settings.reportingWindow.attritionRetentionThreshold,
                    oneYearAverage: null,
                    threeYearAverage: null,
                    reportWindowAverage: null,
                });
                setShowAttritionRetentionPlan(false);
            }
        }
    }, [enrollmentRetention, props.settings]);

    const hasAnyInProgressOrStoppedOutCohorts = (): boolean => {
        let total = 0;

        getEachReportYear().forEach((y) => {
            total += getInProgressOrStoppedOut(y);
        });

        return total > 0;
    };

    const showThreeYearAverage = (): boolean => {
        return getEachReportYear().length >= 5;
    };

    return (
        <>
            <PageTitle title={'Enrollment & Retention'} />

            {!enrollmentRetention && (
                <Card>
                    <CardBody>
                        <Skeleton count={5} />
                    </CardBody>
                </Card>
            )}

            {enrollmentRetention && (
                <Card>
                    <CardBody>
                        <h4 className={'card-title'}>Cohort Information</h4>
                        <h5 className={'card-subtitle text-muted'}>
                            {enrollmentRetention.length === 0 &&
                                `It looks like this is your program's first time completing the Enrollment & Retention 
                            information. Start by adding any Cohorts whose enrollment dates were within the 
                            ${reportStartYear} calendar year.`}
                            {enrollmentRetention.length > 0 &&
                                `For any Cohorts listed below, please make any necessary updates to Cohorts that had students 
                                In Progress or Stopped out and add any new Cohorts that were enrolled within the 
                                ${reportStartYear} calendar year.`}
                        </h5>

                        {props.canEdit && (
                            <Row className={'my-3'}>
                                <Col>
                                    <Button
                                        color={'info'}
                                        type={'button'}
                                        onClick={() => selectCohortToEdit(undefined)}
                                    >
                                        <i className={'mdi mdi-plus'} />
                                        <span className={'ml-1'}>{`Add Cohort`}</span>
                                    </Button>
                                </Col>
                            </Row>
                        )}

                        {enrollmentRetention && programSatellites && enrollmentRetention.length > 0 && (
                            <Row className={'mb-3 mt-2'}>
                                <Col className={'d-flex align-items-center'}>
                                    <Label className={'mr-2 my-0'}>Cohorts:</Label>
                                    <select
                                        onChange={(e) =>
                                            setSelectedCohort(
                                                enrollmentRetention.find(
                                                    (er) => er.enrollmentRetentionId === +e.target.value,
                                                ),
                                            )
                                        }
                                        className={'form-control my-0 select'}
                                        value={selectedCohort?.enrollmentRetentionId || ''}
                                    >
                                        {enrollmentRetention
                                            .sort((a, b) => {
                                                return (
                                                    moment(b.enrollmentDate!).unix() - moment(a.enrollmentDate!).unix()
                                                );
                                            })
                                            .map((cohort, i) => (
                                                <option
                                                    key={cohort.enrollmentRetentionId}
                                                    value={cohort.enrollmentRetentionId}
                                                >
                                                    {`${
                                                        cohort.satelliteId
                                                            ? `${
                                                                  programSatellites.find(
                                                                      (ps) => ps.satelliteId === cohort.satelliteId,
                                                                  )?.title || 'Satellite'
                                                              }: `
                                                            : programSatellites.length > 0
                                                            ? `Home Campus: `
                                                            : ``
                                                    }Enrollment Date - ${moment(cohort.enrollmentDate).format(
                                                        'MM/DD/YYYY',
                                                    )}, On-Time Graduation Date - ${moment(
                                                        cohort.onTimeGraduationDate,
                                                    ).format('MM/DD/YYYY')}, In-Progress or Stopped Out: ${
                                                        cohort.enrollmentRetentionReportInfoDto &&
                                                        cohort.enrollmentRetentionReportInfoDto[0]
                                                            ?.inProgressOrStoppedOut
                                                            ? cohort.enrollmentRetentionReportInfoDto[0]
                                                                  .inProgressOrStoppedOut
                                                            : 0
                                                    }`}
                                                </option>
                                            ))}
                                    </select>
                                </Col>
                            </Row>
                        )}

                        {selectedCohort && programSatellites && (
                            <Row className={'mt-3'}>
                                <Col>
                                    <Card>
                                        <CardHeader>
                                            {programSatellites.length > 0 && (
                                                <div>
                                                    <h5>
                                                        {programSatellites.find(
                                                            (p) => p.satelliteId === selectedCohort.satelliteId,
                                                        )?.title || 'Home Campus'}
                                                    </h5>
                                                </div>
                                            )}
                                            <div className={'d-flex justify-content-between'}>
                                                <div className={'d-flex flex-column'}>
                                                    <Label>{`Enrollment Date:`}</Label>
                                                    <span>{`${moment(selectedCohort.enrollmentDate!).format(
                                                        'MM/DD/YYYY',
                                                    )}`}</span>
                                                </div>
                                                <div className={'d-flex flex-column'}>
                                                    <Label>{`On-Time Graduation Date:`}</Label>
                                                    <span>{`${moment(selectedCohort.onTimeGraduationDate!).format(
                                                        'MM/DD/YYYY',
                                                    )}`}</span>
                                                </div>
                                                <div className={'d-flex flex-column'}>
                                                    <Label>{`Estimated Number of Applicants:`}</Label>
                                                    <span>{`${selectedCohort.estimatedNumberOfApplicants}`}</span>
                                                </div>
                                                <div className={'d-flex flex-column'}>
                                                    <Label>{`Maximum Number of Students:`}</Label>
                                                    <span>{`${selectedCohort.maximumNumberOfStudents}`}</span>
                                                </div>
                                                {props.canEdit && (
                                                    <div className={'d-flex flex-column'}>
                                                        <Button
                                                            type={'button'}
                                                            color={'info'}
                                                            onClick={() => toggleModal()}
                                                        >
                                                            {`Edit Cohort`}
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col xs={12} md={4}>
                                                    <h5>{`Enrollment Detail`}</h5>
                                                    <div className={'d-flex justify-content-between'}>
                                                        <Label>{`Number of Students Initially Enrolled:`}</Label>
                                                        <span>{`${selectedCohort.numberOfStudentsInitiallyEnrolled}`}</span>
                                                    </div>
                                                    <div className={'d-flex justify-content-between'}>
                                                        <Label>{`Number Added to Class:`}</Label>
                                                        <span>{`${
                                                            selectedCohort.enrollmentRetentionReportInfoDto &&
                                                            (selectedCohort.enrollmentRetentionReportInfoDto[0]
                                                                ?.numberAddedToClass ||
                                                                0)
                                                        }`}</span>
                                                    </div>
                                                </Col>
                                                {selectedCohort.enrollmentRetentionReportInfoDto && (
                                                    <Col xs={12} md={4}>
                                                        <h5>{`Attrition`}</h5>
                                                        <div className={'d-flex justify-content-between'}>
                                                            <Label>{`Non-Academic Reasons:`}</Label>
                                                            <span>{`${
                                                                selectedCohort.enrollmentRetentionReportInfoDto[0]
                                                                    ?.attritionDueToNonAcademicReasons || 0
                                                            }`}</span>
                                                        </div>
                                                        <div className={'d-flex justify-content-between'}>
                                                            <Label>{`Due to General Education Courses:`}</Label>
                                                            <span>{`${
                                                                selectedCohort.enrollmentRetentionReportInfoDto[0]
                                                                    ?.attritionDueToGeneralEducationCourses || 0
                                                            }`}</span>
                                                        </div>
                                                        <div className={'d-flex justify-content-between'}>
                                                            <Label>{`Due to Professional Courses:`}</Label>
                                                            <span>{`${
                                                                selectedCohort.enrollmentRetentionReportInfoDto[0]
                                                                    ?.attritionDueToProfessionalCourses || 0
                                                            }`}</span>
                                                        </div>
                                                    </Col>
                                                )}
                                                <Col xs={12} md={4}>
                                                    <h5>{`Graduates By Graduation Year`}</h5>

                                                    {selectedCohort.enrollmentRetentionDetailsDto &&
                                                        selectedCohort.enrollmentRetentionDetailsDto
                                                            .sort((a, b) => a.graduationYear - b.graduationYear)
                                                            .map((detail, i) => (
                                                                <div
                                                                    key={i}
                                                                    className={'d-flex justify-content-between'}
                                                                >
                                                                    <Label>{`${detail.graduationYear}:`}</Label>
                                                                    <span>{`${detail.graduated}`}</span>
                                                                </div>
                                                            ))}
                                                </Col>
                                            </Row>
                                            {selectedCohort.enrollmentRetentionReportInfoDto && (
                                                <Row>
                                                    <Col>
                                                        <h5>{`Cohort Totals`}</h5>
                                                        <div className={'d-flex justify-content-around'}>
                                                            <div
                                                                className={'d-flex flex-column justify-content-center'}
                                                            >
                                                                <h4 className={`text-center`}>
                                                                    {
                                                                        selectedCohort
                                                                            .enrollmentRetentionReportInfoDto[0]
                                                                            .totalStudentsInClass
                                                                    }
                                                                </h4>
                                                                <span>{`Total Students in Class`}</span>
                                                            </div>
                                                            <div
                                                                className={'d-flex flex-column justify-content-center'}
                                                            >
                                                                <h4 className={`text-center`}>
                                                                    {
                                                                        selectedCohort
                                                                            .enrollmentRetentionReportInfoDto[0]
                                                                            .inProgressOrStoppedOut
                                                                    }
                                                                </h4>
                                                                <span>{`In Progress or Stopped Out`}</span>
                                                            </div>
                                                            <div
                                                                className={'d-flex flex-column justify-content-center'}
                                                            >
                                                                <h4 className={`text-center`}>
                                                                    {
                                                                        selectedCohort
                                                                            .enrollmentRetentionReportInfoDto[0]
                                                                            .droppedOut
                                                                    }
                                                                </h4>
                                                                <span>{`Dropped Out`}</span>
                                                            </div>
                                                            <div
                                                                className={'d-flex flex-column justify-content-center'}
                                                            >
                                                                <h4 className={`text-center`}>
                                                                    {
                                                                        selectedCohort
                                                                            .enrollmentRetentionReportInfoDto[0]
                                                                            .totalGraduates
                                                                    }
                                                                </h4>
                                                                <span>{`Total Graduates`}</span>
                                                            </div>
                                                            {props.settings.reportingWindow.outcomeMeasure ===
                                                                'Attrition' && (
                                                                <div
                                                                    className={`d-flex flex-column justify-content-center ${getAttritionRetentionClass(
                                                                        attrition,
                                                                        retention,
                                                                    )}`}
                                                                >
                                                                    <h4
                                                                        className={`text-center ${getAttritionRetentionClass(
                                                                            attrition,
                                                                            retention,
                                                                        )}`}
                                                                    >{`${selectedCohort.enrollmentRetentionReportInfoDto[0].percentAttrition?.toFixed(
                                                                        2,
                                                                    )}%`}</h4>
                                                                    <span>{`Percent Attrition`}</span>
                                                                </div>
                                                            )}
                                                            {props.settings.reportingWindow.outcomeMeasure ===
                                                                'Retention' && (
                                                                <div
                                                                    className={`d-flex flex-column justify-content-center ${getAttritionRetentionClass(
                                                                        attrition,
                                                                        retention,
                                                                    )}`}
                                                                >
                                                                    <h4
                                                                        className={`text-center ${getAttritionRetentionClass(
                                                                            attrition,
                                                                            retention,
                                                                        )}`}
                                                                    >{`${selectedCohort.enrollmentRetentionReportInfoDto[0].percentRetention?.toFixed(
                                                                        2,
                                                                    )}%`}</h4>
                                                                    <span>{`Percent Retention`}</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        )}

                        <Card>
                            <CardBody>
                                <h5 className={'card-title'}>{`${reportStartYear} - ${
                                    reportStartYear - ((props.settings?.reportingWindow?.reportingWindow || 1) - 1)
                                } ${props.settings.reportingWindow.outcomeMeasure} Average By Graduation Year`}</h5>

                                <div className={'table-responsive'}>
                                    <table className={'table table-striped'}>
                                        <thead>
                                            <tr>
                                                <th style={{ width: `30%` }}>{`Graduation Year`}</th>
                                                {getEachReportYear().map((year) => (
                                                    <th
                                                        key={year}
                                                        style={{
                                                            width: `${
                                                                70 /
                                                                (props.settings.reportingWindow.reportingWindow || 1)
                                                            }%`,
                                                        }}
                                                    >
                                                        <span>{`${year}`}</span>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span>{`# Initially Enrolled`}</span>
                                                </td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        <span className={'d-block pl-2'}>{`${getInitiallyEnrolledByYear(
                                                            year,
                                                        )}`}</span>
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`# Added to Class`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        <span className={'d-block pl-2'}>{`${getAddedToClassByYear(
                                                            year,
                                                        )}`}</span>
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`Attrition Due to General Education Courses`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        <span className={'d-block pl-2'}>{`${getAttritionDueToGenEd(
                                                            year,
                                                        )}`}</span>
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`Attrition Due to Non-Academic Reasons`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        <span
                                                            className={'d-block pl-2'}
                                                        >{`${getAttritionDueToNonAcademic(year)}`}</span>
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`Attrition Due to Professional Courses`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        <span
                                                            className={'d-block pl-2'}
                                                        >{`${getAttritionDueToProfCourses(year)}`}</span>
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`# Dropped Out`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        <span className={'d-block pl-2'}>{`${getDroppedOut(
                                                            year,
                                                        )}`}</span>
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`# In-Progress or Stopped Out`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        <span className={'d-block pl-2'}>{`${getInProgressOrStoppedOut(
                                                            year,
                                                        )}`}</span>
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`# Graduated`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        <span
                                                            className={'d-block pl-2'}
                                                        >{`${getGraduatesByGraduationYear(year)}`}</span>
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`${props.settings.reportingWindow.outcomeMeasure} Percentage`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        <Label className={`d-block pl-2`}>
                                                            {`${getAttritionPercentageCalcByGraduationYear(
                                                                year,
                                                            ).toFixed(2)}%`}
                                                        </Label>
                                                    </td>
                                                ))}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className={'d-flex justify-content-around'}>
                                    <div className={'d-flex flex-column justify-content-center'}>
                                        <h4 className={`text-center`}>{totalStudents}</h4>
                                        <span>{`Total Students`}</span>
                                    </div>
                                    <div className={'d-flex flex-column justify-content-center'}>
                                        <h4 className={`text-center`}>{totalDroppedOut}</h4>
                                        <span>{`Dropped Out`}</span>
                                    </div>
                                    <div className={'d-flex flex-column justify-content-center'}>
                                        <h4 className={`text-center`}>{`${attritionRetentionOutcome?.threshold?.toFixed(
                                            2,
                                        )}%`}</h4>
                                        <span>{`Threshold`}</span>
                                    </div>
                                    {props.settings.reportingWindow.outcomeMeasure === 'Attrition' && (
                                        <>
                                            <div
                                                className={`d-flex flex-column justify-content-center ${getAttritionRetentionClass(
                                                    attrition,
                                                    retention,
                                                )}`}
                                            >
                                                <h4
                                                    className={`text-center ${getAttritionRetentionClass(
                                                        attrition,
                                                        retention,
                                                    )}`}
                                                >{`${attritionRetentionOutcome?.reportWindowAverage?.toFixed(2)}%`}</h4>
                                                <span>{`Percent Attrition`}</span>
                                            </div>
                                            {showThreeYearAverage() && (
                                                <div
                                                    className={`d-flex flex-column justify-content-center ${getThreeYearAttritionRetentionClass()}`}
                                                >
                                                    <h4
                                                        className={`text-center ${getThreeYearAttritionRetentionClass()}`}
                                                    >
                                                        {`${attritionRetentionOutcome?.threeYearAverage?.toFixed(2)}%`}
                                                    </h4>
                                                    <span>{`3-Year Average`}</span>
                                                </div>
                                            )}
                                        </>
                                    )}
                                    {props.settings.reportingWindow.outcomeMeasure === 'Retention' && (
                                        <>
                                            <div
                                                className={`d-flex flex-column justify-content-center ${getAttritionRetentionClass(
                                                    attrition,
                                                    retention,
                                                )}`}
                                            >
                                                <h4
                                                    className={`text-center ${getAttritionRetentionClass(
                                                        attrition,
                                                        retention,
                                                    )}`}
                                                >{`${attritionRetentionOutcome?.reportWindowAverage?.toFixed(2)}%`}</h4>
                                                <span>{`Percent Retention`}</span>
                                            </div>
                                            {showThreeYearAverage() && (
                                                <div
                                                    className={`d-flex flex-column justify-content-center ${getThreeYearAttritionRetentionClass()}`}
                                                >
                                                    <h4
                                                        className={`text-center ${getThreeYearAttritionRetentionClass()}`}
                                                    >
                                                        {`${attritionRetentionOutcome?.threeYearAverage?.toFixed(2)}%`}
                                                    </h4>
                                                    <span>{`3-Year Average`}</span>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                                {attritionRetentionOutcome && showAttritionRetentionPlan && (
                                    <div className={'d-flex justify-content-between mt-3'}>
                                        <div className={'d-flex flex-column'} style={{ flexBasis: 'calc(50% - 10px)' }}>
                                            <Label>Detailed Analysis</Label>
                                            <Input
                                                type={'textarea'}
                                                rows={10}
                                                onChange={(e) =>
                                                    setAttritionRetention({
                                                        ...attritionRetentionOutcome,
                                                        detailedAnalysis: e.target.value,
                                                    })
                                                }
                                                defaultValue={attritionRetentionOutcome.detailedAnalysis || ''}
                                            />
                                        </div>
                                        <div className={'d-flex flex-column'} style={{ flexBasis: 'calc(50% - 10px)' }}>
                                            <Label>Action Plan</Label>
                                            <Input
                                                type={'textarea'}
                                                rows={10}
                                                onChange={(e) =>
                                                    setAttritionRetention({
                                                        ...attritionRetentionOutcome,
                                                        actionPlan: e.target.value,
                                                    })
                                                }
                                                defaultValue={attritionRetentionOutcome.actionPlan || ''}
                                            />
                                        </div>
                                    </div>
                                )}
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <h5 className={'card-title'}>{`${reportStartYear} - ${
                                    reportStartYear - ((props.settings?.reportingWindow?.reportingWindow || 1) - 1)
                                } ${props.settings.reportingWindow.outcomeMeasure} Positive Placement Detail`}</h5>

                                <div className={'table-responsive'}>
                                    <table className={'table table-striped'}>
                                        <thead>
                                            <tr>
                                                <th style={{ width: `30%` }}>{`Graduation Year`}</th>
                                                {getEachReportYear().map((year) => (
                                                    <th
                                                        key={year}
                                                        style={{
                                                            width: `${
                                                                70 /
                                                                (props.settings.reportingWindow.reportingWindow || 1)
                                                            }%`,
                                                        }}
                                                    >
                                                        <span>{`${year}`}</span>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span>{`Total Graduates`}</span>
                                                </td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        <span className={'d-block pl-2'}>{`${getGraduatesByYear(
                                                            year,
                                                        )}`}</span>
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`# Employed`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        {/* {year === reportStartYear && (
                                                            <Input
                                                                type={'text'}
                                                                value={getPositivePlacementEmployedByGraduationYear(
                                                                    year,
                                                                )}
                                                                onChange={(e) => updateEmployed(year, +e.target.value)}
                                                            />
                                                        )}
                                                        {year !== reportStartYear && (
                                                            <span className={'d-block pl-2'}>
                                                                {getPositivePlacementEmployedByGraduationYear(year)}
                                                            </span>
                                                        )} */}
                                                        <Input
                                                            type={'text'}
                                                            value={getPositivePlacementEmployedByGraduationYear(year)}
                                                            onChange={(e) => updateEmployed(year, +e.target.value)}
                                                        />
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`# Continuing Education or Active Military, but NOT Employed`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        {/* {year === reportStartYear && (
                                                            <Input
                                                                type={'text'}
                                                                value={getPositivePlacementNotEmployedByGraduationYear(
                                                                    year,
                                                                )}
                                                                onChange={(e) =>
                                                                    updateNotEmployed(year, +e.target.value)
                                                                }
                                                            />
                                                        )}
                                                        {year !== reportStartYear && (
                                                            <span className={'d-block pl-2'}>
                                                                {getPositivePlacementNotEmployedByGraduationYear(year)}
                                                            </span>
                                                        )} */}
                                                        <Input
                                                            type={'text'}
                                                            value={getPositivePlacementNotEmployedByGraduationYear(
                                                                year,
                                                            )}
                                                            onChange={(e) => updateNotEmployed(year, +e.target.value)}
                                                        />
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`Positive Placement`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        <Label className={`d-block pl-2`}>
                                                            {calcPositivePlacementByYear(year)}
                                                        </Label>
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`Placement Percentage`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        <Label className={`d-block pl-2`}>
                                                            {`${calcPositivePlacementPercentageByYear(year)?.toFixed(
                                                                2,
                                                            )}%`}
                                                        </Label>
                                                    </td>
                                                ))}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className={'d-flex justify-content-around'}>
                                    <div className={'d-flex flex-column justify-content-center'}>
                                        <h4 className={`text-center`}>{totalGraduates}</h4>
                                        <span>{`Total Graduates`}</span>
                                    </div>
                                    <div className={'d-flex flex-column justify-content-center'}>
                                        <h4 className={`text-center`}>{totalPositivePlacement()}</h4>
                                        <span>{`Total Positive Placement`}</span>
                                    </div>
                                    <div className={'d-flex flex-column justify-content-center'}>
                                        <h4 className={`text-center`}>{`${positivePlacementOutcome?.threshold?.toFixed(
                                            2,
                                        )}%`}</h4>
                                        <span>{`Threshold`}</span>
                                    </div>
                                    <div
                                        className={`d-flex flex-column justify-content-center ${positivePlacementClass()}`}
                                    >
                                        <h4 className={`text-center ${positivePlacementClass()}`}>
                                            {`${positivePlacementOutcome?.reportWindowAverage?.toFixed(2)}%`}
                                        </h4>
                                        <span>{`Percent Positive Placement`}</span>
                                    </div>
                                    {showThreeYearAverage() && (
                                        <div
                                            className={`d-flex flex-column justify-content-center ${threeYearPositivePlacementClass()}`}
                                        >
                                            <h4 className={`text-center ${threeYearPositivePlacementClass()}`}>
                                                {`${positivePlacementOutcome?.threeYearAverage?.toFixed(2)}%`}
                                            </h4>
                                            <span>{`3-Year Average`}</span>
                                        </div>
                                    )}
                                </div>

                                {showPositivePlacementPlan && positivePlacementOutcome && (
                                    <div className={'d-flex justify-content-between mt-3'}>
                                        <div className={'d-flex flex-column'} style={{ flexBasis: 'calc(50% - 10px)' }}>
                                            <Label>Detailed Analysis</Label>
                                            <Input
                                                type={'textarea'}
                                                rows={10}
                                                onChange={(e) =>
                                                    setPositivePlacement({
                                                        ...positivePlacementOutcome,
                                                        detailedAnalysis: e.target.value,
                                                    })
                                                }
                                                value={positivePlacementOutcome.detailedAnalysis || ''}
                                            />
                                        </div>
                                        <div className={'d-flex flex-column'} style={{ flexBasis: 'calc(50% - 10px)' }}>
                                            <Label>Action Plan</Label>
                                            <Input
                                                type={'textarea'}
                                                rows={10}
                                                onChange={(e) =>
                                                    setPositivePlacement({
                                                        ...positivePlacementOutcome,
                                                        actionPlan: e.target.value,
                                                    })
                                                }
                                                value={positivePlacementOutcome.actionPlan || ''}
                                            />
                                        </div>
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    </CardBody>
                    {props.canEdit && (
                        <CardFooter className={'d-flex flex-column'}>
                            {hasAnyInProgressOrStoppedOutCohorts() && (
                                <Alert color={'warning'} className={'mb-3'}>
                                    <p>
                                        {`WARNING: There are cohorts within the reporting window that still have in-progress or stopped-out students. If this is expected, 
                                        please Save & Continue when ready. If this is unexpected, please resolve utilizing the Cohort Edit feature above before proceeding.`}
                                    </p>
                                </Alert>
                            )}
                            <div className={'d-flex justify-content-between'}>
                                <Button
                                    type={'button'}
                                    color={'warning'}
                                    onClick={() => props.onNavigate(AnnualReportNavigationDirection.Back)}
                                >
                                    <i className={'mdi mdi-arrow-left'} />
                                    <span className={'mr-1'}>{`Previous`}</span>
                                </Button>

                                <Button
                                    disabled={formSubmitting}
                                    type={'submit'}
                                    color={'primary'}
                                    onClick={() => saveOutcomeAndPositivePlacementDetail(false)}
                                >
                                    <i className={'mdi mdi-content-save'} />
                                    <span className={'ml-1'}>{`Save`}</span>
                                </Button>

                                <Button
                                    disabled={formSubmitting}
                                    type={'submit'}
                                    color={'info'}
                                    onClick={() => saveOutcomeAndPositivePlacementDetail(true)}
                                >
                                    <span className={'mr-1'}>{`Save & Continue`}</span>
                                    <i className={'mdi mdi-arrow-right'} />
                                </Button>
                            </div>
                        </CardFooter>
                    )}
                </Card>
            )}

            <Modal isOpen={showModal} toggle={toggleModal} centered={true} size={'lg'}>
                <ModalHeader>
                    {!selectedCohort && <>{`New Cohort`}</>}
                    {selectedCohort && selectedCohort.enrollmentRetentionId > 0 && <>{`Update Cohort`}</>}
                </ModalHeader>
                <ModalBody>
                    {programSatellites && (
                        <EnrollmentRetentionForm
                            enrollmentRetention={selectedCohort || initializeNewCohort()}
                            reportYear={props.annualReport.reportYear}
                            outcomeMeasure={props.settings.reportingWindow.outcomeMeasure || 'Attrition'}
                            onFormSaved={cohortFormSaved}
                            onFormCanceled={toggleModal}
                            satellites={programSatellites}
                            otherCohorts={
                                enrollmentRetention?.filter(
                                    (er) => er.enrollmentRetentionId !== selectedCohort?.enrollmentRetentionId,
                                ) || []
                            }
                        />
                    )}
                </ModalBody>
            </Modal>
        </>
    );
};

export default EnrollmentRetention;
