import { NoteDto } from '../common/Types';
import { APIUriAms, http } from './HttpHelper';

class NoteService {
    public getProgramNotesByProgramId = async (programId: number): Promise<NoteDto[]> => {
        const result = await http<NoteDto[]>(
            APIUriAms,
            `/note/by-program-id/${programId}`);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };
    public getProgramNotesByInstitutionId = async (institutionId: number): Promise<NoteDto[]> => {
        const result = await http<NoteDto[]>(
            APIUriAms,
            `/note/by-institution-id/${institutionId}`);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };
    public saveProgramNote = async (data: NoteDto[]) => {
        const result = await http<NoteDto[]>(
            APIUriAms,
            `/note`,
            undefined,
            'POST',
            data);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };
}


export const noteService = new NoteService();