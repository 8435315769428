import React from 'react';
import { Route as DOMRouter } from 'react-router-dom';

const CoaRoute = (route) => {
    return (
        <DOMRouter
            path={route.path}
            render={(props) => <route.component {...props} routes={route.routes} coa={route.coa} />}
        />
    );
};

export default CoaRoute;
