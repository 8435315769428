import {
    Badge,
    Button,
    Card,
    Col,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table,
} from 'reactstrap';
import { CoaDto, NewCoaDto, ProfessionDto } from '../../common/Types';
import { Input, PageTitle } from '../../components';
import { NavLink, Redirect } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { coaService } from '../../services/CoaAdministration';
import { professionService } from '../../services/ProfessionAdministration';
import { toast } from 'react-toastify';

const Coas = () => {
    const [coas, setCoas] = useState<CoaDto[]>();
    const [professions, setProfessions] = useState<ProfessionDto[]>();

    const [newCoa, setNewCoa] = useState<NewCoaDto>();
    const [showNewCoa, setShowNewCoa] = useState<boolean>(false);
    const toggleShowNewCoa = () => setShowNewCoa(!showNewCoa);

    const [navigateToId, setNavigateToId] = useState<number>();

    useEffect(() => {
        const loadCoas = () => {
            coaService
                .getAllCoas()
                .then((results) => {
                    setCoas([...results]);
                })
                .catch(() => {
                    toast.error('Error loading CoAs');
                });
        };

        const loadProfessions = () => {
            professionService
                .getProfessions()
                .then((results) => {
                    setProfessions([...results]);
                })
                .catch(() => {
                    toast.error('Error loading professions');
                });
        };

        loadCoas();
        loadProfessions();
    }, [setCoas]);

    const saveNewCoa = () => {
        if (newCoa) {
            const toastId = toast.info('Creating CoA...');

            coaService
                .saveCoas([{ ...newCoa }])
                .then((coas) => {
                    toast.update(toastId, {
                        render: 'CoA saved successfully',
                        type: 'success',
                    });

                    toggleShowNewCoa();
                    setNavigateToId(coas[0].id);
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error creating CoA',
                        type: 'error',
                    });
                });
        }
    };

    if (navigateToId) {
        return <Redirect to={`/system-admin/coa/${navigateToId}`} />;
    } else {
        return (
            <>
                <PageTitle className={'d-flex justify-content-between my-2'}>
                    <h4>{`Committees on Accreditation`}</h4>
                    <Button
                        type={'button'}
                        color={'primary'}
                        onClick={() => {
                            setNewCoa({ id: 0, coaTitle: '', abb: '', isActive: true });
                            toggleShowNewCoa();
                        }}
                    >
                        <i className={'mdi mdi-plus'} />
                        <span className={'ml-1'}>{`New CoA`}</span>
                    </Button>
                </PageTitle>
                <Row>
                    <Col>
                        <Card>
                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>{`Name`}</th>
                                        <th>{`Abbreviation`}</th>
                                        <th>{`Professions`}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {coas?.map((c) => (
                                        <tr key={c.id}>
                                            <td>
                                                {c.isActive === true && <Badge color={'success'}>{`active`}</Badge>}
                                                {c.isActive !== true && <Badge color={'danger'}>{`removed`}</Badge>}
                                            </td>
                                            <td>{c.coaTitle}</td>
                                            <td>{c.abb}</td>
                                            <td className={'d-flex flex-column'}>
                                                {professions
                                                    ?.filter((p) => p.coaId === c.id)
                                                    ?.map((p) => (
                                                        <NavLink
                                                            to={`/system-admin/profession/${p.professionId}`}
                                                            key={p.professionId}
                                                        >
                                                            {p.title}
                                                        </NavLink>
                                                    ))}
                                            </td>
                                            <td>
                                                <NavLink className={'btn btn-info'} to={`/system-admin/coa/${c.id}`}>
                                                    {`View`}
                                                </NavLink>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                </Row>
                <Modal isOpen={showNewCoa} toggle={toggleShowNewCoa}>
                    <ModalHeader>
                        <h4>{`New CoA`}</h4>
                    </ModalHeader>
                    {newCoa && (
                        <ModalBody>
                            <FormGroup>
                                <Label>{`Name`}</Label>
                                <Input
                                    type={'text'}
                                    value={newCoa.coaTitle}
                                    onChange={(e) => setNewCoa({ ...newCoa, coaTitle: e.target.value })}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label>{`Abbreviation`}</Label>
                                <Input
                                    type={'text'}
                                    value={newCoa.abb}
                                    onChange={(e) => setNewCoa({ ...newCoa, abb: e.target.value })}
                                />
                            </FormGroup>
                        </ModalBody>
                    )}
                    <ModalFooter>
                        <Button type={'button'} color={'primary'} onClick={() => saveNewCoa()}>
                            {`Create CoA`}
                        </Button>
                        <Button
                            type={'button'}
                            color={'secondary'}
                            className={'ml-2'}
                            onClick={() => toggleShowNewCoa()}
                        >
                            {`Cancel`}
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
};

export default Coas;
