import { Route as DOMRouter } from 'react-router-dom';
import React from 'react';

const AnnualReportRoute = (route) => {
    return (
        <DOMRouter
            path={route.path}
            render={(props) => (
                <route.component
                    {...props}
                    routes={route.routes}
                    program={route.program}
                    profession={route.profession}
                    settings={route.settings}
                    reportYear={route.reportYear}
                    onNavigate={route.onNavigate}
                    annualReport={route.annualReport}
                    onSavedSuccessfully={route.onSavedSuccessfully}
                    isReadyToSubmit={route.isReadyToSubmit}
                    remainingTabs={route.remainingTabs}
                    onGoToTab={route.onGoToTab}
                    canEdit={route.canEdit}
                    onSubmitAnnualReport={route.onSubmitAnnualReport}
                    hasTabAlreadyBeenSubmitted={route.hasTabAlreadyBeenSubmitted}
                />
            )}
        />
    );
};

export default AnnualReportRoute;
