import React from 'react';
import * as Sentry from '@sentry/browser';
import { v4 as uuidv4 } from 'uuid';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as copy from 'copy-to-clipboard';
import styled from 'styled-components';

const StyledIconButton = styled.button`
    border: none;
    background: none;
    color: black;
`;

interface ErrorState {
    error?: string;
    errorInfo?: string;
    errorId?: string;
}

class ErrorBoundary extends React.Component<{}, ErrorState> {
    constructor(props) {
        super(props);
        this.state = {};
    }

    copyToClipboard = (val: string) => {
        copy.default(val);
    };

    componentDidCatch(error, errorInfo) {
        const errorId = uuidv4();

        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo,
            errorId: errorId,
        });
        // This is where we would put a call to Sentry or ELK
        Sentry.configureScope((scope) => {
            scope.setTag('caahep.error_id', errorId);
        });
        Sentry.captureException(error);

        toast(
            () => (
                <>
                    <div className="mb-2">
                        Sorry, some thing went wrong. Please try again or contact support if the problem persists.
                    </div>
                    <div style={{ color: 'black' }}>
                        Code: {this.state.errorId}{' '}
                        <StyledIconButton
                            onClick={() => {
                                this.copyToClipboard(this.state.errorId ?? 'N/A');
                            }}
                        >
                            <i className={'remixicon-file-copy-line'}></i> Copy
                        </StyledIconButton>
                    </div>
                </>
            ),
            { type: 'error', position: 'top-center' },
        );
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <ToastContainer
                    {...{
                        autoClose: false,
                        draggable: false,
                        closeButton: false,
                        closeOnClick: false,
                        style: { width: 500 },
                    }}
                />
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

export default ErrorBoundary;
