import { IAuthState, IAuthAction, AuthActionType } from './Types';
import { authService } from '../../services/Auth';

const initialState: IAuthState = {
    authenticated: authService.validateInitialAuthentication(),
    authenticating: false,
    passwordResetting: false,
    passwordReset: false,
    user: authService.getProfile(),
};

export const authReducer = (state: IAuthState = initialState, action: IAuthAction<IAuthState>) => {
    switch (action.type) {
        case AuthActionType.Authenticating:
        case AuthActionType.Authenticated:
        case AuthActionType.AuthenticationError:
        case AuthActionType.LoggedOut:
        case AuthActionType.PasswordResetting:
        case AuthActionType.PasswordReset:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
