import {
    AffiliatePersonnel,
    ClinicalAffiliate,
    PersonnelPersonStatus,
    ProgramPersonnelDto,
} from '../../../common/Types';
import { AnnualReportNavigationDirection, AnnualReportProps } from '../AnnualReport';
import { Button, ClinicalAffiliateForm, Collapse } from '../../../components';
import {
    Card,
    CardBody,
    CardFooter,
    CardSubtitle,
    FormGroup,
    Input,
    Label,
    ListGroup,
    ListGroupItem,
    ListGroupItemHeading,
    ListGroupItemText,
    Modal,
    ModalBody,
    ModalHeader,
} from 'reactstrap';
import React, { useEffect, useState } from 'react';

import { SatelliteType } from '../../../common/Data';
import { personnelService } from '../../../services/Personnel';
import { professionService } from '../../../services/ProfessionAdministration';
import { programService } from '../../../services/ProgramService';
import { toast } from 'react-toastify';

const ClinicalAffilites = (props: AnnualReportProps) => {
    const [clinicalAffilites, setClinicalAffiliates] = useState<ClinicalAffiliate[]>([]);
    const [selectedAffiliate, setSelectedAffiliate] = useState<ClinicalAffiliate>();
    const [personnel, setPersonnel] = useState<ProgramPersonnelDto[]>();
    const [reload, setReload] = useState<boolean>(true);
    const [showModal, setModal] = useState(false);
    const [acknowledged, setAcknowledged] = useState(props.hasTabAlreadyBeenSubmitted);

    const toggleModal = () => setModal(!showModal);

    // Get satelillites
    useEffect(() => {
        const getClinicalAffiliates = (shouldReload: boolean) => {
            if (shouldReload) {
                programService
                    .getClinicalAffiliatesByProgramId(props.program.programId)
                    .then((results) => {
                        setClinicalAffiliates([
                            ...results.filter((ca) => ca.satalliteTypeId === SatelliteType['Clinical Affiliate']),
                        ]);
                        setReload(false);
                    })
                    .catch(() => {
                        toast.error('Unable to get clinical affiliates.');
                    });
            }
        };

        getClinicalAffiliates(reload);
    }, [props.program.programId, props.reportYear, setClinicalAffiliates, reload]);

    useEffect(() => {
        const getPersonnel = () => {
            if (
                props.program.programId &&
                props.program.professionId &&
                props.reportYear &&
                props.settings?.reportingWindow?.activeYear
            ) {
                professionService
                    .getPersonnelSettings(props.program.professionId)
                    .then((settings) => {
                        personnelService
                            .getArmsProgramPersonnelByProgramIdAndYear(
                                props.program.programId,
                                props.settings.reportingWindow.activeYear || +props.reportYear,
                                +props.reportYear,
                            )
                            .then((results) => {
                                if (results) {
                                    const applicable = results.filter(
                                        (p) =>
                                            (p.personnelStatus === PersonnelPersonStatus.modified ||
                                                p.personnelStatus === PersonnelPersonStatus.new ||
                                                p.personnelStatus === PersonnelPersonStatus.same) &&
                                            settings.filter(
                                                (s) =>
                                                    s.isAdvisoryCommittee === false &&
                                                    s.personnelTypeId === p.personnelTypeId,
                                            ).length > 0 &&
                                            p.personnelTypeId > 3,
                                    );

                                    setPersonnel(applicable);
                                }
                            })
                            .catch(() => {
                                toast.error('Error loading personnel');
                            });
                    })
                    .catch(() => {
                        toast.error('Error loading personnel');
                    });
            }
        };

        getPersonnel();
    }, [props.program.programId, props.program.professionId, props.reportYear, props.settings, setPersonnel]);

    const handleSave = (isModal = false) => {
        setReload(true);
        setSelectedAffiliate(undefined);

        if (isModal) {
            toggleModal();
        }
    };

    const acknowledgementRequired = (): boolean => {
        return (clinicalAffilites?.length || 0) > 0 && !acknowledged;
    };

    const showAcknowledgement = (): boolean => {
        return (clinicalAffilites?.length || 0) > 0;
    };

    const getPersonnelDetail = (ap: AffiliatePersonnel) => {
        if (personnel) {
            const person = personnel.find((p) => p.programPersonnelId === ap.programPersonnelId);

            if (person) {
                return (
                    <span key={person.programPersonnelId}>{`${person.people?.title ? `${person.people.title} - ` : ``}${
                        person.people?.firstName
                    } ${person.people?.lastName}${
                        person.people?.credentials ? `, ${person.people.credentials}` : ``
                    }`}</span>
                );
            }
        }

        return <></>;
    };

    return (
        <Card>
            <CardBody>
                <h3 className="card-title">Clinical Affiliates</h3>
                <CardSubtitle className="mb-3">
                    {`Add or update clinical affiliates. Once you have everything set, click the
                    'Continue' button at the bottom of the list.`}
                </CardSubtitle>
                {props.canEdit && (
                    <Button color="primary" onClick={() => toggleModal()}>
                        <i className={'mdi mdi-plus'} />
                        <span className={'ml-1'}>Add New Clinical Affiliate</span>
                    </Button>
                )}
                <ListGroup className={'mt-3'}>
                    {clinicalAffilites &&
                        personnel &&
                        clinicalAffilites.map((affiliate) => (
                            <ListGroupItem className="justify-content-between" key={affiliate.satelliteId}>
                                <ListGroupItemHeading className={'d-flex justify-content-between'}>
                                    <Label>{affiliate.title}</Label>
                                    {affiliate.satelliteId !== selectedAffiliate?.satelliteId && (
                                        <Button color="info" onClick={() => setSelectedAffiliate(affiliate)}>
                                            <i className={'remixicon-pencil-line'}></i>
                                        </Button>
                                    )}
                                </ListGroupItemHeading>
                                <ListGroupItemText className={'d-flex flex-column'}>
                                    <>
                                        <span>{`${affiliate.city}, ${affiliate.state} - ${affiliate.country}`}</span>
                                        {affiliate?.personnel
                                            ?.filter((p) => +p.reportYear === +props.reportYear)
                                            ?.map((p) => getPersonnelDetail(p))}
                                    </>
                                </ListGroupItemText>
                                <Collapse isOpen={affiliate.satelliteId === selectedAffiliate?.satelliteId}>
                                    {affiliate.satelliteId === selectedAffiliate?.satelliteId && (
                                        <ClinicalAffiliateForm
                                            programId={props.program.programId}
                                            onSave={() => handleSave(false)}
                                            onCancel={() => setSelectedAffiliate(undefined)}
                                            affiliate={{
                                                ...affiliate,
                                                personnel: [
                                                    ...(affiliate.personnel?.filter(
                                                        (a) =>
                                                            +a.reportYear === +props.reportYear &&
                                                            personnel.find(
                                                                (p) => p.programPersonnelId === a.programPersonnelId,
                                                            ),
                                                    ) || []),
                                                ],
                                            }}
                                            canEdit={props.canEdit}
                                            personnel={personnel}
                                            reportYear={+props.reportYear}
                                        />
                                    )}
                                </Collapse>
                            </ListGroupItem>
                        ))}
                </ListGroup>
                {showAcknowledgement() && (
                    <Card className={'mt-3'}>
                        <CardBody>
                            <h5 className={'card-title'}>{`Acknowledgment`}</h5>
                            <FormGroup>
                                <div className={'custom-control custom-checkbox'}>
                                    <Input
                                        type="checkbox"
                                        checked={acknowledged}
                                        id={'acknowledgment'}
                                        onChange={() => setAcknowledged(!acknowledged)}
                                        className={'custom-control-input'}
                                    />
                                    <Label
                                        for={`acknowledgment`}
                                        className={'custom-control-label'}
                                    >{`All clinical affiliates and associated instructors listed above are current to the best of my knowledge.`}</Label>
                                </div>
                            </FormGroup>
                        </CardBody>
                    </Card>
                )}
            </CardBody>
            {props.canEdit && (
                <CardFooter className={'d-flex justify-content-between'}>
                    <Button
                        type={'button'}
                        className={'btn btn-warning'}
                        onClick={() => props.onNavigate(AnnualReportNavigationDirection.Back)}
                    >
                        <i className={'mdi mdi-arrow-left'} />
                        <span className={'mr-1'}>{`Previous`}</span>
                    </Button>

                    {!acknowledgementRequired() && (
                        <Button
                            style={{ zIndex: 1 }}
                            className={'btn btn-info'}
                            disabled={acknowledgementRequired()}
                            onClick={() => props.onSavedSuccessfully(AnnualReportNavigationDirection.Forward)}
                        >
                            <span className={'mr-1'}>{`Continue`}</span>
                            <i className={'mdi mdi-arrow-right'} />
                        </Button>
                    )}
                    {acknowledgementRequired() && (
                        <Label className={'text-danger'}>
                            {`Please check the acknowledgement checkbox above to continue.`}
                        </Label>
                    )}
                </CardFooter>
            )}

            {personnel && (
                <Modal isOpen={showModal} toggle={toggleModal} size={'lg'}>
                    <ModalHeader toggle={toggleModal}>Add Clinical Affiliate</ModalHeader>
                    <ModalBody>
                        <ClinicalAffiliateForm
                            programId={props.program.programId}
                            onSave={() => handleSave(true)}
                            onCancel={() => toggleModal()}
                            canEdit={props.canEdit}
                            personnel={personnel}
                            reportYear={+props.reportYear}
                        />
                    </ModalBody>
                </Modal>
            )}
        </Card>
    );
};

export default ClinicalAffilites;
