import React from 'react';
import { PageTitle } from '../../components';
import CashReceiptsReport from './CashReceiptsReport';
import UnconfirmedPayments from './UnconfirmedPayments';

const Payments = () => {
    return (
        <>
            <PageTitle title="Payments" />

            <CashReceiptsReport />

            <UnconfirmedPayments />
        </>
    );
};

export default Payments;
