import React from 'react';
import { Input } from './';
import { ReportingYears } from '../common/Data';

const YearDropdown = (props) => {
    const currentYear = new Date().getFullYear();
    return (
        <Input
            type="select"
            name="reportYear"
            style={{ width: 100 }}
            onChange={(event) => props.setYear(event.target.value)}
            disabled={props.disabled === undefined ? false : props.disabled}
            defaultValue={currentYear}
        >
            {ReportingYears.map((year, i) => (
                <option key={i} value={year}>
                    {year}
                </option>
            ))}
        </Input>
    );
};

export default YearDropdown;
