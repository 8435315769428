import * as yup from 'yup';

import { Card, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Countries, States } from '../common/Data';
import { PersonDto, PersonnelPersonStatus, PersonnelSettings, ProgramPersonnelDto } from '../common/Types';

import Button from './Button';
import React from 'react';
import { personnelService } from '../services/Personnel';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

type Props = {
    person: ProgramPersonnelDto;
    onCancel: () => void;
    onSave: () => void;
};

const PersonnelForm = (props: Props) => {
    const { person } = props;

    const personnelSchema = yup.object<ProgramPersonnelDto>({
        programPersonnelId: yup.number().required().default(person.programPersonnelId),
        programId: yup.number().required().default(person.programId),
        personnelTypeId: yup.number().required().default(person.personnelTypeId),
        peopleId: yup.number().required().default(person.peopleId),
        visible: yup.boolean().required().default(person.visible),
        satelliteId: yup.number().notRequired().nullable().default(person.satelliteId),
        people: yup
            .object<PersonDto>({
                peopleId: yup.number().required().default(0),
                firstName: yup.string().notRequired(),
                lastName: yup.string().notRequired(),
                address: yup.string().notRequired(),
                address2: yup.string().notRequired(),
                address3: yup.string().notRequired(),
                address4: yup.string().notRequired(),
                city: yup.string().notRequired(),
                state: yup.string().notRequired(),
                zip: yup.string().notRequired(),
                country: yup.string().notRequired(),
                phone: yup.string().notRequired(),
                extension: yup.string().notRequired(),
                fax: yup.string().notRequired(),
                title: yup.string().notRequired(),
                credentials: yup.string().notRequired(),
                salutations: yup.string().notRequired(),
                email: yup.string().notRequired(),
                createdBy: yup.string().notRequired(),
                dateCreated: yup.string().notRequired(),
                lastEdited: yup.string().notRequired(),
                editedBy: yup.string().notRequired(),
                active: yup.boolean().required().default(true),
            })
            .required(),
        reportYear: yup.number().required().default(person.reportYear),
        personnelStatus: yup
            .number()
            .required('Please indicate the current status of this person')
            .typeError('Please indicate the current status of this person')
            .notOneOf([PersonnelPersonStatus.unknown], 'Please indicate the current status of this person.')
            .default(person.personnelStatus),
        personnelType: yup.object<PersonnelSettings>().notRequired(),
    });

    const {
        handleSubmit,
        register,
        errors,
        formState: { isSubmitting },
    } = useForm({
        validationSchema: personnelSchema,
        defaultValues: person,
    });

    const onSubmit = (values: ProgramPersonnelDto): Promise<void> => {
        return new Promise((res) => {
            const toastId = toast.info('Saving person...');

            personnelService
                .saveProgramPersonnel(values.programId, values.reportYear, [values])
                .then((result) => {
                    toast.update(toastId, {
                        render: 'Person saved successfully',
                        type: 'success',
                    });

                    props.onSave();
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error saving person',
                        type: 'error',
                    });
                })
                .finally(() => {
                    res();
                });
            res();
        });
    };

    console.log(errors);

    return (
        <Card className={'m-0'}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                    {person && person.people && (
                        <Row>
                            <Col md={7}>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup className={'row'}>
                                            <Label className={'col-form-label col-12'}>Job Title</Label>
                                            <Col xs={12}>
                                                <Input
                                                    name={'people.title'}
                                                    type="text"
                                                    bsSize={'sm'}
                                                    defaultValue={person.people.title}
                                                    innerRef={register()}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6} xl={3}>
                                        <FormGroup className={'row'}>
                                            <Label className={'col-form-label col-12'}>Salutations</Label>
                                            <Col xs={12}>
                                                <Input
                                                    name={'people.salutations'}
                                                    type="text"
                                                    bsSize={'sm'}
                                                    defaultValue={person.people.salutations}
                                                    innerRef={register()}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6} xl={3}>
                                        <FormGroup className={'row'}>
                                            <Label className={'col-form-label col-12'}>First Name</Label>
                                            <Col xs={12}>
                                                <Input
                                                    name={'people.firstName'}
                                                    type="text"
                                                    bsSize={'sm'}
                                                    defaultValue={person.people.firstName}
                                                    innerRef={register()}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6} xl={3}>
                                        <FormGroup className={'row'}>
                                            <Label className={'col-form-label col-12'}>Last Name</Label>
                                            <Col xs={12}>
                                                <Input
                                                    name={'people.lastName'}
                                                    type="text"
                                                    bsSize={'sm'}
                                                    defaultValue={person.people.lastName}
                                                    innerRef={register()}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6} xl={3}>
                                        <FormGroup className={'row'}>
                                            <Label className={'col-form-label col-12'}>Credentials</Label>
                                            <Col xs={12}>
                                                <Input
                                                    name={'people.credentials'}
                                                    type="text"
                                                    bsSize={'sm'}
                                                    defaultValue={person.people.credentials}
                                                    innerRef={register()}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup className={'row'}>
                                            <Label className={'col-form-label col-12'}>Email</Label>
                                            <Col xs={12}>
                                                <Input
                                                    name={'people.email'}
                                                    type="email"
                                                    bsSize={'sm'}
                                                    defaultValue={person.people.email}
                                                    innerRef={register()}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6} xl={4}>
                                        <FormGroup className={'row'}>
                                            <Label className={'col-form-label col-12'}>Phone</Label>
                                            <Col xs={12}>
                                                <Input
                                                    name={'people.phone'}
                                                    type="text"
                                                    bsSize={'sm'}
                                                    defaultValue={person.people.phone}
                                                    innerRef={register()}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6} xl={2}>
                                        <FormGroup className={'row'}>
                                            <Label className={'col-form-label col-12'}>Ext</Label>
                                            <Col xs={12}>
                                                <Input
                                                    name={'people.extension'}
                                                    type="text"
                                                    bsSize={'sm'}
                                                    defaultValue={person.people.extension}
                                                    innerRef={register()}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup className={'row'}>
                                            <Label className={'col-form-label col-12'}>Fax</Label>
                                            <Col xs={12}>
                                                <Input
                                                    name={'people.fax'}
                                                    type="text"
                                                    bsSize={'sm'}
                                                    defaultValue={person.people.fax}
                                                    innerRef={register()}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={5}>
                                <Row>
                                    <Col xs={12}>
                                        <FormGroup className={'row'}>
                                            <Label className={'col-form-label col-12'}>Address</Label>
                                            <Col xs={12}>
                                                <Input
                                                    name={'people.address'}
                                                    type="text"
                                                    defaultValue={person.people.address}
                                                    bsSize={'sm'}
                                                    innerRef={register()}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12}>
                                        <FormGroup className={'row'}>
                                            <Label className={'col-form-label col-12'}>Address 2</Label>
                                            <Col xs={12}>
                                                <Input
                                                    name={'people.address2'}
                                                    type="text"
                                                    bsSize={'sm'}
                                                    defaultValue={person.people.address2}
                                                    innerRef={register()}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4}>
                                        <FormGroup className={'row'}>
                                            <Label className={'col-form-label col-12'}>City</Label>
                                            <Col xs={12}>
                                                <Input
                                                    name={'people.city'}
                                                    type="text"
                                                    bsSize={'sm'}
                                                    defaultValue={person.people.city}
                                                    innerRef={register()}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4}>
                                        <FormGroup className={'row'}>
                                            <Label title={'State / Province'} className={'col-form-label col-12'}>
                                                St. / Pro...
                                            </Label>
                                            <Col xs={12}>
                                                <Input
                                                    type={'select'}
                                                    name={'people.state'}
                                                    bsSize={'sm'}
                                                    defaultValue={person.people.state}
                                                    innerRef={register()}
                                                >
                                                    <option value={undefined}>{``}</option>
                                                    {States &&
                                                        States.map((s) => (
                                                            <option key={s.abbreviation} value={s.abbreviation}>
                                                                {s.abbreviation}
                                                            </option>
                                                        ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4}>
                                        <FormGroup className={'row'}>
                                            <Label title={'Zip / Postal Code'} className={'col-form-label col-12'}>
                                                Zip / Post...
                                            </Label>
                                            <Col xs={12}>
                                                <Input
                                                    name={'people.zip'}
                                                    type="text"
                                                    bsSize={'sm'}
                                                    defaultValue={person.people.zip}
                                                    innerRef={register()}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12}>
                                        <FormGroup className={'row'}>
                                            <Label className={'col-form-label col-12'}>Country</Label>
                                            <Col xs={12}>
                                                <Input
                                                    type={'select'}
                                                    name={'people.country'}
                                                    bsSize={'sm'}
                                                    defaultValue={person.people.country}
                                                    innerRef={register()}
                                                >
                                                    <option value={undefined}>{``}</option>
                                                    {Countries &&
                                                        Countries.map((c) => (
                                                            <option key={c} value={c}>
                                                                {c}
                                                            </option>
                                                        ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )}
                </CardBody>
                <CardFooter>
                    <Button disabled={isSubmitting} type={'submit'} color={'primary'}>
                        {`Save`}
                    </Button>
                    <Button
                        type={'button'}
                        color={'secondary'}
                        className={'ml-2'}
                        onClick={() => (props.onCancel ? props.onCancel() : null)}
                    >
                        {`Cancel`}
                    </Button>
                </CardFooter>
            </Form>
        </Card>
    );
};

export default PersonnelForm;
