import { Card, CardBody, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { InstitutionPersonRole } from '../../common/Data';
import React, { useEffect, useState } from 'react';

import { Button } from '../../components';
import MultiSelect from 'react-multi-select-component';
import { ProgramStatus, States } from '../../common/Data';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { ProfessionDto } from '../../common/Types';
import { professionService } from '../../services/ProfessionAdministration';
import { reportService } from '../../services/ReportService';
import moment from 'moment';

const StyledMultiSelect = styled(MultiSelect)`
    width: 300px;

    & .dropdown-container {
        width: 100%;
        max-width: 100%;
    }
    & .dropdown-content {
        z-index: 3;
    }
    & .select-item {
        display: block;
    }
`;
const InstitutionContactReport = () => {
    const [professionId, setProfessionId] = useState<number>();
    const [selectedRole, setSelectedRole] = useState<[]>();
    const [selectedStatus, setSelectedStatus] = useState<[]>();
    const [effectiveDate, setEffectiveDate] = useState<Date>();
    const [selectedStates, setSelectedStates] = useState<[]>();
    const [professions, setProfessions] = useState<ProfessionDto[]>();

    useEffect(() => {
        const loadProfessions = () => {
            professionService
                .getProfessions()
                .then((results) => {
                    setProfessions([
                        ...results
                            .filter((p) => p.professionId == 32 || !p.isDeleted)
                            .sort((a, b) => a.title.localeCompare(b.title)),
                    ]);
                })
                .catch(() => {
                    toast.error('Error loading professions');
                });
        };
        loadProfessions();
    }, [setProfessions]);

    const { register } = useForm();

    const exportReport = () => {
        const toastId = toast.info('Generating report, this may take a minute...', {
            autoClose: false,
        });
        reportService
            .exportInstitutionContactReport(
                professionId,
                selectedRole?.map((s) => s['value']),
                selectedStatus?.map((s) => s['value']),
                selectedStates?.map((s) => s['value']),
                effectiveDate ? moment(effectiveDate).format() : effectiveDate,
            )
            .then((report) => {
                if (report) {
                    const url = window.URL.createObjectURL(report);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `InstitutionContactReport.xlsx`;
                    a.click();
                    toast.update(toastId, {
                        render: 'Report generated successfully.',
                        type: 'success',
                        autoClose: 2500,
                    });
                }
            })
            .catch(() => {
                toast.error(
                    `Unable to generate report',
                    )}`,
                );
            });
    };

    return (
        <>
            <Card>
                <CardBody>
                    <Row>
                        <Col>
                            <div className="mb-3">
                                <h3>Institution Contact Report</h3>
                            </div>
                            <Form inline>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                    <Label for="status" className="mr-sm-2">
                                        Role
                                    </Label>
                                    <StyledMultiSelect
                                        options={Object.entries(InstitutionPersonRole)
                                            .filter((p) => !isNaN(p[0] as any))
                                            .map((p) => {
                                                return {
                                                    label: p[1],
                                                    value: p[0],
                                                };
                                            })}
                                        disableSearch={true}
                                        hasSelectAll={false}
                                        value={selectedRole}
                                        onChange={setSelectedRole}
                                    />
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                    <Label for="professionId" className="mr-sm-2">
                                        Profession
                                    </Label>
                                    <Input
                                        type="select"
                                        name={'professionId'}
                                        innerRef={register()}
                                        style={{ width: '200px' }}
                                        onChange={(e) =>
                                            setProfessionId(
                                                professions?.find((p) => p.professionId === +e.target.value)
                                                    ?.professionId,
                                            )
                                        }
                                    >
                                        <option value={''}>Select...</option>
                                        {professions?.map((p) => (
                                            <option key={p.professionId} value={p.professionId}>
                                                {p.title}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                    <Label for="status" className="mr-sm-2">
                                        Status
                                    </Label>
                                    <StyledMultiSelect
                                        options={Object.entries(ProgramStatus)
                                            .filter((p) => !isNaN(p[0] as any))
                                            .map((p) => {
                                                return {
                                                    label: p[1],
                                                    value: p[0],
                                                };
                                            })}
                                        disableSearch={true}
                                        hasSelectAll={false}
                                        value={selectedStatus}
                                        onChange={setSelectedStatus}
                                    />
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                    <Label for="effectiveDate" className="mr-sm-2">
                                        Effective Date (Exact):
                                    </Label>
                                    <Input
                                        type="date"
                                        innerRef={register()}
                                        name="effectiveDate"
                                        onChange={(e) => setEffectiveDate(new Date(e.target.value))}
                                    />
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                    <Label for="state" className="mr-sm-2">
                                        State
                                    </Label>
                                    <StyledMultiSelect
                                        options={States?.map((s) => {
                                            return {
                                                label: s.name,
                                                value: s.abbreviation,
                                            };
                                        })}
                                        disableSearch={true}
                                        hasSelectAll={false}
                                        value={selectedStates}
                                        onChange={setSelectedStates}
                                    />
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col style={{ display: 'flex' }}>
                            <div className="mr-2">
                                <Button color="info" onClick={() => exportReport()}>
                                    Export Report
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    );
};

export default InstitutionContactReport;
