import { Card, CardBody, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import { Button } from '../../../components';
import { ProfessionAddOnTrack } from '../../../common/Types';
import Skeleton from 'react-loading-skeleton';
import { professionService } from '../../../services/ProfessionAdministration';
import { toast } from 'react-toastify';

interface AddOnTracksCardProps {
    professionId: number;
}

const AddOnTracksCard = (props: AddOnTracksCardProps) => {
    const { professionId } = props;

    const [addOnTracks, setAddOnTracks] = useState<ProfessionAddOnTrack[]>();
    const [reloadTracks, setReloadTracks] = useState<boolean>(true);

    const [addOnTrackEdit, setAddOnTrackEdit] = useState<ProfessionAddOnTrack>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const toggleModal = () => setShowModal(!showModal);

    useEffect(() => {
        const getAddOnTracks = () => {
            if (professionId && reloadTracks) {
                setReloadTracks(false);
                professionService
                    .getAddOnTracksByProfessionId(professionId)
                    .then((result) => {
                        setAddOnTracks([...result]);
                    })
                    .catch(() => {
                        toast.error('Error loading add-on tracks');
                    });
            }
        };

        getAddOnTracks();
    }, [professionId, setAddOnTracks, reloadTracks]);

    const startNewAddOnTrack = () => {
        setAddOnTrackEdit({
            addOnTrackId: 0,
            professionId: professionId,
            title: '',
        });
        toggleModal();
    };

    const startEditAddOnTrack = (addOnTrack: ProfessionAddOnTrack) => {
        setAddOnTrackEdit({
            ...addOnTrack,
        });
        toggleModal();
    };

    const saveAddOnTrack = () => {
        if (addOnTrackEdit && addOnTrackEdit.title) {
            const toastId = toast.info('Saving add-on track...');

            professionService
                .saveAddOnTrack(addOnTrackEdit)
                .then(() => {
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Add-on track saved successfully',
                    });

                    setReloadTracks(true);
                    toggleModal();
                })
                .catch(() => {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Error saving add-on track',
                    });
                });
        }

        if (addOnTrackEdit && !addOnTrackEdit.title) {
            toast.error('Add-On Track Title is required');
        }
    };

    return (
        <>
            <Card>
                <CardBody className={'d-flex flex-column'}>
                    <h4 className={'card-title d-flex align-items-center justify-content-between'}>
                        <span>{`Add-On Tracks`}</span>
                        <div>
                            <Button
                                type={'button'}
                                color={'primary'}
                                className={'btn-icon'}
                                onClick={() => startNewAddOnTrack()}
                            >
                                <i className={`mdi mdi-plus`} />
                            </Button>
                        </div>
                    </h4>
                    {!addOnTracks && <Skeleton count={5} />}
                    {addOnTracks && addOnTracks.length <= 0 && <Label>{`No Add-On Tracks`}</Label>}
                    {addOnTracks &&
                        addOnTracks.length > 0 &&
                        addOnTracks.map((t) => (
                            <div
                                key={t.addOnTrackId}
                                className={'d-flex justify-content-between align-items-center mb-2'}
                            >
                                <span>{t.title}</span>
                                <div>
                                    <Button
                                        type={'button'}
                                        color={'info'}
                                        className={'btn-icon'}
                                        onClick={() => startEditAddOnTrack(t)}
                                    >
                                        <i className={'mdi mdi-pencil'} />
                                    </Button>
                                </div>
                            </div>
                        ))}
                </CardBody>
            </Card>

            {addOnTrackEdit && (
                <Modal isOpen={showModal} toggle={toggleModal} centered={true}>
                    <ModalHeader>
                        {addOnTrackEdit.addOnTrackId > 0 ? `Edit Add-On Track` : `New Add-On Track`}
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label>{`Add-On Track Title`}</Label>
                            <Input
                                type={'text'}
                                value={addOnTrackEdit.title}
                                onChange={(e) => setAddOnTrackEdit({ ...addOnTrackEdit, title: e.target.value })}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color={'primary'}
                            className={'mr-2'}
                            type={'button'}
                            onClick={() => saveAddOnTrack()}
                        >{`Submit`}</Button>
                        <Button color={'link'} onClick={() => toggleModal()} type={'button'}>{`Cancel`}</Button>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
};

export default AddOnTracksCard;
