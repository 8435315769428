import * as yup from 'yup';

import { Button, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { GeneralSettings, Survey } from '../common/Types';

import { Input } from '.';
import React from 'react';
import { programService } from '../services/ProgramService';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

interface SurveyWorksheetFormProps {
    worksheet: Survey;
    settings: GeneralSettings;
    onWorksheetUpdated: (updated: Survey) => void;
    onCancel: () => void;
}

const SurveyWorksheetForm = (props: SurveyWorksheetFormProps) => {
    const { worksheet, settings, onWorksheetUpdated, onCancel } = props;

    const worksheetSchema: yup.ObjectSchema<Survey> = yup
        .object<Survey>({
            surveyWorksheetId: yup.number().required().default(worksheet.surveyWorksheetId),
            surveyTypeId: yup.number().notRequired().nullable().default(worksheet.surveyTypeId),
            programId: yup.number().required().default(worksheet.programId),
            graduatingYear: yup.number().required().default(worksheet.graduatingYear),
            graduateSurveysSent: yup
                .number()
                .required('This is required')
                .min(0, 'This is required')
                .max(yup.ref('totalGraduates'), 'Must be equal to or less than total graduates')
                .typeError('This is required')
                .default(worksheet.graduateSurveysSent),
            employerSurveysSent: yup
                .number()
                .required('This is required')
                .min(0, 'This is required')
                .max(yup.ref('numberOfGradsEmployed'), 'Must be equal to or less than total graduates employed')
                .typeError('This is required')
                .default(worksheet.employerSurveysSent),
            graduateSurveysReturned: yup
                .number()
                .required('This is required')
                .min(0, 'This is required')
                .max(yup.ref('graduateSurveysSent'), 'Must be less than graduate surveys sent')
                .typeError('This is required')
                .default(worksheet.graduateSurveysReturned),
            employerSurveysReturned: yup
                .number()
                .required('This is required')
                .min(0, 'This is required')
                .max(yup.ref('employerSurveysSent'), 'Must be less than employer surveys sent')
                .typeError('This is required')
                .default(worksheet.employerSurveysReturned),
            numberOfGradsEmployed: yup.number().required().default(worksheet.numberOfGradsEmployed),
            totalGraduates: yup.number().required().default(worksheet.totalGraduates),
            graduateSurveysSentRate: yup.number().notRequired().nullable().default(worksheet.graduateSurveysSentRate),
            graduateSurveysReturnRate: yup
                .number()
                .notRequired()
                .nullable()
                .default(worksheet.graduateSurveysReturnRate),
            employerSurveysSentRate: yup.number().notRequired().nullable().default(worksheet.employerSurveysSent),
            employerSurveysReturnRate: yup
                .number()
                .notRequired()
                .nullable()
                .default(worksheet.employerSurveysReturnRate),
        })
        .defined();

    const {
        handleSubmit,
        register,
        reset,
        watch,
        errors,
        formState: { isSubmitting },
    } = useForm({
        validationSchema: worksheetSchema,
        defaultValues: { ...worksheet },
    });

    const onSubmit = (formData: Survey): Promise<void> => {
        return new Promise((res) => {
            const toastId = toast.info('Saving worksheet data...');

            programService
                .saveSurveyWorksheet(formData)
                .then((response) => {
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Worksheet sent/returned data saved.',
                    });

                    reset(response);
                    onWorksheetUpdated(response);
                })
                .catch(() => {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Failed to save worksheet sent/returned data.',
                    });
                })
                .finally(() => {
                    res();
                });
        });
    };

    const gradSurveysSent = watch('graduateSurveysSent', 0);
    const gradSurveysReturned = watch('graduateSurveysReturned', 0);

    const gradSentRate =
        (worksheet?.totalGraduates || 0) > 0 ? (+(gradSurveysSent || 0) / (worksheet?.totalGraduates || 0)) * 100 : 0;
    const gradReturnRate =
        (worksheet?.totalGraduates || 0) > 0
            ? (+(gradSurveysReturned || 0) / (worksheet?.totalGraduates || 0)) * 100
            : 0;

    const employerSurveysSent = watch('employerSurveysSent', 0);
    const employerSurveysReturned = watch('employerSurveysReturned', 0);

    const empSentRate =
        (worksheet?.numberOfGradsEmployed || 0) > 0
            ? (+(employerSurveysSent || 0) / (worksheet?.numberOfGradsEmployed || 0)) * 100
            : 0;
    const empReturnRate =
        (worksheet?.numberOfGradsEmployed || 0) > 0
            ? (+(employerSurveysReturned || 0) / (worksheet?.numberOfGradsEmployed || 0)) * 100
            : 0;

    const getGradSurveyClass = (sentRate: number, returnRate: number, threshold: number): string => {
        if (settings.reportingWindow.graduateSurveyMeasureType === 'Sent') {
            return sentRate >= threshold ? 'text-success' : 'text-danger';
        } else {
            return returnRate >= threshold ? 'text-success' : 'text-danger';
        }
    };

    const getEmployerSurveyClass = (sentRate: number, returnRate: number, threshold: number): string => {
        if (settings.reportingWindow.employeeSurveyMeasureType === 'Sent') {
            return sentRate >= threshold ? 'text-success' : 'text-danger';
        } else {
            return returnRate >= threshold ? 'text-success' : 'text-danger';
        }
    };

    console.log(errors);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <h4>{`${worksheet?.graduatingYear} Graduates Surveys`}</h4>
            <div className={'d-flex justify-content-between'}>
                <FormGroup style={{ flexBasis: 'calc(25% - 5px)' }}>
                    <Label># Graduates</Label>
                    <h5>{worksheet?.totalGraduates}</h5>
                </FormGroup>
                <FormGroup style={{ flexBasis: 'calc(25% - 5px)' }}>
                    <Label>Sent</Label>
                    <Input
                        type="number"
                        name={`graduateSurveysSent`}
                        defaultValue={worksheet?.graduateSurveysSent}
                        style={{ width: '100px' }}
                        innerRef={register()}
                    />
                    {errors?.graduateSurveysSent?.message && (
                        <Label className={'text-danger'}>{errors.graduateSurveysSent.message}</Label>
                    )}
                </FormGroup>
                <FormGroup style={{ flexBasis: 'calc(25% - 5px)' }}>
                    <Label>Returned</Label>
                    <Input
                        type="number"
                        name={`graduateSurveysReturned`}
                        defaultValue={worksheet?.graduateSurveysReturned}
                        style={{ width: '100px' }}
                        innerRef={register()}
                    />
                    {errors?.graduateSurveysReturned?.message && (
                        <Label className={'text-danger'}>{errors.graduateSurveysReturned.message}</Label>
                    )}
                </FormGroup>
                {settings.reportingWindow.graduateSurveyMeasureType === 'Sent' && (
                    <FormGroup
                        style={{ flexBasis: 'calc(25% - 5px)' }}
                        className={`${getGradSurveyClass(
                            gradSentRate,
                            gradReturnRate,
                            settings.reportingWindow.graduateSurveysThreshold,
                        )}`}
                    >
                        <Label>Sent Rate</Label>
                        <h3
                            className={`${getGradSurveyClass(
                                gradSentRate,
                                gradReturnRate,
                                settings.reportingWindow.graduateSurveysThreshold,
                            )}`}
                        >{`${(gradSentRate || worksheet?.graduateSurveysSentRate || 0).toFixed(2)}%`}</h3>
                    </FormGroup>
                )}
                <FormGroup style={{ flexBasis: 'calc(25% - 5px)' }}>
                    <Label>{`${settings.reportingWindow.graduateSurveyMeasureType} Threshold`}</Label>
                    <h3>{`${settings.reportingWindow.graduateSurveysThreshold}%`}</h3>
                </FormGroup>
                {settings.reportingWindow.graduateSurveyMeasureType === 'Returned' && (
                    <FormGroup
                        style={{ flexBasis: 'calc(25% - 5px)' }}
                        className={`${getGradSurveyClass(
                            gradSentRate,
                            gradReturnRate,
                            settings.reportingWindow.graduateSurveysThreshold,
                        )}`}
                    >
                        <Label>Return Rate</Label>
                        <h3
                            className={`${getGradSurveyClass(
                                gradSentRate,
                                gradReturnRate,
                                settings.reportingWindow.graduateSurveysThreshold,
                            )}`}
                        >{`${(gradReturnRate || worksheet?.graduateSurveysReturnRate || 0).toFixed(2)}%`}</h3>
                    </FormGroup>
                )}
            </div>
            <h4>Employer Surveys</h4>
            <div className={'d-flex justify-content-between'}>
                <FormGroup style={{ flexBasis: 'calc(25% - 5px)' }}>
                    <Label># Employed</Label>
                    <h5>{worksheet?.numberOfGradsEmployed}</h5>
                </FormGroup>
                <FormGroup style={{ flexBasis: 'calc(25% - 5px)' }}>
                    <Label>Sent</Label>
                    <Input
                        type="number"
                        name={`employerSurveysSent`}
                        defaultValue={worksheet?.employerSurveysSent}
                        style={{ width: '100px' }}
                        innerRef={register()}
                    />
                    {errors?.employerSurveysSent?.message && (
                        <Label className={'text-danger'}>{errors.employerSurveysSent.message}</Label>
                    )}
                </FormGroup>
                <FormGroup style={{ flexBasis: 'calc(25% - 5px)' }}>
                    <Label>Returned</Label>
                    <Input
                        type="number"
                        name={`employerSurveysReturned`}
                        defaultValue={worksheet?.employerSurveysReturned}
                        style={{ width: '100px' }}
                        innerRef={register()}
                    />
                    {errors?.employerSurveysReturned?.message && (
                        <Label className={'text-danger'}>{errors.employerSurveysReturned.message}</Label>
                    )}
                </FormGroup>
                {settings.reportingWindow.employeeSurveyMeasureType === 'Sent' && (
                    <FormGroup
                        style={{ flexBasis: 'calc(25% - 5px)' }}
                        className={`${getEmployerSurveyClass(
                            empSentRate,
                            empReturnRate,
                            settings.reportingWindow.employerSurveysThreshold,
                        )}`}
                    >
                        <Label>Sent Rate</Label>
                        <h3
                            className={`${getEmployerSurveyClass(
                                empSentRate,
                                empReturnRate,
                                settings.reportingWindow.employerSurveysThreshold,
                            )}`}
                        >{`${(empSentRate || worksheet?.employerSurveysSentRate || 0).toFixed(2)}%`}</h3>
                    </FormGroup>
                )}
                <FormGroup style={{ flexBasis: 'calc(25% - 5px)' }}>
                    <Label>{`${settings.reportingWindow.employeeSurveyMeasureType} Threshold`}</Label>
                    <h3>{`${settings.reportingWindow.employerSurveysThreshold}%`}</h3>
                </FormGroup>
                {settings.reportingWindow.employeeSurveyMeasureType === 'Returned' && (
                    <FormGroup
                        style={{ flexBasis: 'calc(25% - 5px)' }}
                        className={`${getEmployerSurveyClass(
                            empSentRate,
                            empReturnRate,
                            settings.reportingWindow.employerSurveysThreshold,
                        )}`}
                    >
                        <Label>Return Rate</Label>
                        <h3
                            className={`${getEmployerSurveyClass(
                                empSentRate,
                                empReturnRate,
                                settings.reportingWindow.employerSurveysThreshold,
                            )}`}
                        >{`${(empReturnRate || worksheet?.employerSurveysReturnRate || 0).toFixed(2)}%`}</h3>
                    </FormGroup>
                )}
            </div>
            <Row>
                <Col>
                    <Button disabled={isSubmitting} type="submit" color={'primary'}>
                        Save
                    </Button>
                    <Button
                        type={'button'}
                        color={'secondary'}
                        className={'ml-2'}
                        onClick={onCancel}
                    >{`Cancel`}</Button>
                </Col>
            </Row>
        </Form>
    );
};

export default SurveyWorksheetForm;
