import React from 'react';
import { useForm } from 'react-hook-form';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import * as yup from 'yup';
import { ApplyAdministrativeFeesDto } from '../../../types/ApplyAdministrativeFeesDto';
import { generateYears } from '../../../common/Utils';
import { Button } from '../../../components';
import { invoiceService } from '../../../services/InvoiceService';
import { toast } from 'react-toastify';

interface AdministrativeFeesFormProps {
    onSave: () => void;
    onCancel: () => void;
}

const AdministrativeFeesForm = (props: AdministrativeFeesFormProps) => {
    const schema: yup.ObjectSchema<ApplyAdministrativeFeesDto> = yup
        .object({
            fiscalYear: yup.string().required(),
            lateFee: yup.number().required(),
        })
        .defined();

    const { handleSubmit, register } = useForm({
        validationSchema: schema,
        defaultValues: {
            fiscalYear: new Date().getFullYear(),
            lateFee: 200,
        },
    });

    const onSubmit = (formData) => {
        const toastId = toast.info('Applying administrative fees...');

        invoiceService
            .applyAdministrativeFees(formData)
            .then(() => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Fees applied successfuly',
                });
                props.onSave();
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Error applying fees',
                });
            });
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
                <Label for="fiscalYear">Fiscal Year</Label>
                <Input type="select" innerRef={register()} name="fiscalYear">
                    <option value={''}>Select...</option>
                    {generateYears().map((ct) => (
                        <option key={ct} value={ct}>
                            {ct}
                        </option>
                    ))}
                </Input>
            </FormGroup>
            <FormGroup>
                <Label for="lateFee">Amount</Label>
                <Input name="lateFee" innerRef={register()} />
            </FormGroup>
            <div>
                <Button type="button" color={'link'} onClick={props.onCancel}>
                    Cancel
                </Button>
                <Button type="submit">Save</Button>
            </div>
        </Form>
    );
};

export default AdministrativeFeesForm;
