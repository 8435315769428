import { Alert, Card, CardBody, Col, Form, FormGroup, Row } from 'reactstrap';
import { NavLink, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button';
import FormError from '../../components/FormError';
import Input from '../../components/Input';
import Logo from '../../assets/images/logo-blue.png';
import React from 'react';
import { requestPasswordReset } from '../../store/auth/Actions';
import { useForm } from 'react-hook-form';

const PasswordResetRequest = () => {
    const auth = useSelector((state) => state.auth);
    const { register, handleSubmit, errors } = useForm();
    const dispatch = useDispatch();

    const onSubmit = (formData) => {
        dispatch(requestPasswordReset(formData));
    };

    return (
        <>
            {auth.authenticated && <Redirect to="/profile" />}
            <Row className="justify-content-center">
                <Col md="8" lg="6" xl="5">
                    <Card>
                        <CardBody>
                            <div className="text-center w-75 m-auto">
                                <NavLink to="/">
                                    <span>
                                        <img src={Logo} alt="logo" height="150" />
                                    </span>
                                </NavLink>
                                <p className="text-muted mb-4 mt-3">
                                    Enter your email to receive an email with a link to reset your password.
                                </p>
                            </div>

                            {auth.passwordReset && (
                                <Alert color={'success'}>
                                    An email has been sent with instructions to complete your password reset. Please
                                    click the button in the email to complete the password reset process.
                                </Alert>
                            )}

                            {!auth.passwordReset && auth.passwordResetError && (
                                <Alert color={'danger'}>{auth.passwordResetError}</Alert>
                            )}

                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup className="mb-3">
                                    <label htmlFor="username">Email address</label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        id="username"
                                        name="username"
                                        innerRef={register({ required: true })}
                                        placeholder="Enter your email / username"
                                    />
                                    <FormError
                                        name="username"
                                        message="Please enter an email / username"
                                        errors={errors}
                                    />
                                </FormGroup>
                                <FormGroup className="mb-0 text-center">
                                    <Button className="btn-block" type="submit">
                                        Send Password Reset Email
                                    </Button>
                                </FormGroup>
                            </Form>
                        </CardBody>
                    </Card>

                    <Row className="mt-3">
                        <Col className="text-center">
                            <p>
                                <NavLink to="/login" className="ml-1">
                                    Back to Login
                                </NavLink>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default PasswordResetRequest;
