import { APIUriAms, http, httpFile } from "./HttpHelper";
import { AmsDocument, DocumentUpload } from "../common/Types";

class DocumentService {
    public saveAndUploadRelatedDocument = async (upload: DocumentUpload): Promise<AmsDocument> => {
        const formData = new FormData();
        formData.append('dto', JSON.stringify(upload.dto));
        if (upload.fileUpload) {
            formData.append('fileUpload', upload.fileUpload);
        }

        const result = await httpFile<any>(APIUriAms, '/document', formData, undefined, 'PUT');
        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveAndUploadProgramAwardLetter = async (upload: DocumentUpload): Promise<AmsDocument> => {
        const formData = new FormData();
        formData.append('dto', JSON.stringify(upload.dto));
        if (upload.fileUpload) {
            formData.append('fileUpload', upload.fileUpload);
        }

        const result = await httpFile<any>(APIUriAms, '/document/awardletter', formData, undefined, 'PUT');
        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public deleteDocument = async (document: AmsDocument): Promise<AmsDocument> => {
        const result = await http<any>(
            APIUriAms,
            '/document',
            undefined,
            'DELETE',
            document);
        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };
};

export const documentService = new DocumentService();