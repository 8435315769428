import React, { useState, useEffect } from 'react';
import { GeneralSettings, AnnualReport, AnnualReportStatus } from '../../../common/Types';
import { Card, CardBody, CardFooter } from 'reactstrap';
import { professionService } from '../../../services/ProfessionAdministration';
import { toast } from 'react-toastify';
import { programService } from '../../../services/ProgramService';
import { NavLink } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { authService, ArmsPermissionClaimType } from '../../../services/Auth';

const AnnualReports = (props) => {
    const [settings, setSettings] = useState<GeneralSettings>();
    const [annualReports, setAnnualReports] = useState<AnnualReport[]>();
    const [annualReportRequired] = useState<boolean>(props.annualReportRequired);

    useEffect(() => {
        professionService
            .getGeneralSettings(props.program.professionId)
            .then((response) => {
                setSettings(response);
            })
            .catch(() => {
                toast.error('Error loading annual report settings.');
            });
    }, [setSettings, props]);

    useEffect(() => {
        programService
            .getAnnualReports(props.program.programId)
            .then((response) => {
                setAnnualReports(response);
            })
            .catch(() => {
                toast.error('Error loading annual report settings.');
            });
    }, [setAnnualReports, props]);

    const getStatus = (): AnnualReportStatus | null => {
        if (annualReports && settings) {
            if (annualReportRequired) {
                const activeYearReport =
                    annualReports &&
                    annualReports.filter((ar) => ar.reportYear === settings?.reportingWindow.activeYear)[0];

                return activeYearReport?.reportStatus || AnnualReportStatus.NotStarted;
            }

            return AnnualReportStatus.NotRequired;
        }

        return null;
    };

    const getStatusColor = (): string => {
        switch (getStatus()) {
            case AnnualReportStatus.NotStarted:
                return 'danger';
            case AnnualReportStatus.InProgress:
                return 'warning';
            case AnnualReportStatus.SentBack:
                return 'secondary';
            case AnnualReportStatus.Submitted:
                return 'info';
            case AnnualReportStatus.Completed:
                return 'success';
            case AnnualReportStatus.NotRequired:
                return 'dark';
            default:
                return 'white';
        }
    };

    const canEditAnnualReport = () => {
        return (
            authService.hasPermission(ArmsPermissionClaimType, 'admin') ||
            authService.hasPermission(ArmsPermissionClaimType, 'mutateannualreport')
        );
    };

    return (
        <>
            <Card className={`bg-${getStatusColor()} flex-grow-1`}>
                {annualReports && settings && (
                    <CardBody>
                        <h4 className={'card-title text-white'}>Annual Report</h4>
                        <div className={'d-flex justify-content-between'}>
                            <div className={'d-flex flex-column align-items-center p-1'}>
                                <span className={'text-white'} style={{ fontSize: '32px', fontWeight: 'bold' }}>
                                    {settings?.reportingWindow.activeYear}
                                </span>
                                <span className={'text-white'}>Active Year</span>
                            </div>
                            <div className={'d-flex flex-column align-items-center p-1'}>
                                <span className={'text-white'} style={{ fontSize: '32px', fontWeight: 'bold' }}>
                                    {getStatus()}
                                </span>
                                <span className={'text-white'}>Current Status</span>
                            </div>
                        </div>
                    </CardBody>
                )}
                {(!annualReports || !settings) && (
                    <CardBody>
                        <Skeleton count={10} />
                    </CardBody>
                )}
                {annualReportRequired && annualReports && settings && canEditAnnualReport() && (
                    <CardFooter className={`bg-${getStatusColor()} border-0 p-0`}>
                        <NavLink
                            className={`btn btn-${getStatusColor()} d-block p-2`}
                            to={`/annual-report/${props.program.professionId}/${settings?.reportingWindow.activeYear}/program/${props.program.programId}/getting-started`}
                        >
                            <i className={'mdi mdi-lead-pencil'} />
                            <span className={'ml-1'}>Complete Annual Report</span>
                        </NavLink>
                    </CardFooter>
                )}
            </Card>
        </>
    );
};

export default AnnualReports;
