import { Button, Input, PageTitle } from '../../components';
import {
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    Col,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import React, { useEffect, useState } from 'react';

import { InstitutionalAccreditor } from '../../common/Types';
import Skeleton from 'react-loading-skeleton';
import { systemAdministration } from '../../services/SystemAdministration';
import { toast } from 'react-toastify';

const InstitutionalAccreditors = () => {
    const [accreditors, setAccreditors] = useState<InstitutionalAccreditor[]>();
    const [reload, setReload] = useState<boolean>(true);

    const [addEdit, setAddEdit] = useState<InstitutionalAccreditor>();
    const [showAddEdit, setShowAddEdit] = useState<boolean>(false);
    const [showRemove, setShowRemove] = useState<boolean>(false);
    const toggleShowAddEdit = () => setShowAddEdit(!showAddEdit);
    const toggleShowRemove = () => setShowRemove(!showRemove);

    useEffect(() => {
        const loadAccreditors = () => {
            if (reload) {
                setReload(false);
                systemAdministration
                    .getInstitutionalAccreditors()
                    .then((results) => {
                        setAccreditors([...results.sort((a, b) => a.title.localeCompare(b.title))]);
                    })
                    .catch(() => {
                        toast.error('Error loading institutional accreditors');
                    });
            }
        };

        loadAccreditors();
    }, [setAccreditors, reload]);

    const onSubmit = () => {
        if (addEdit && addEdit.title) {
            const toastId = toast.info('Saving institutional accreditor...');

            systemAdministration
                .saveInstitutionalAccreditor(addEdit)
                .then((result) => {
                    toast.update(toastId, {
                        render: 'Institutional accreditor saved successfully',
                        type: 'success',
                    });

                    setReload(true);
                    toggleShowAddEdit();
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error saving institutional accreditor',
                        type: 'error',
                    });
                });
        }
    };

    const onRemove = () => {
        if (addEdit) {
            const toastId = toast.info('Removing institutional accreditor...');

            systemAdministration
                .saveInstitutionalAccreditor({
                    ...addEdit,
                    isActive: false,
                })
                .then((result) => {
                    toast.update(toastId, {
                        render: 'Institutional accreditor removed successfully',
                        type: 'success',
                    });

                    setReload(true);
                    toggleShowRemove();
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error removing institutional accreditor',
                        type: 'error',
                    });
                });
        }
    };

    const onReinstate = (accreditor: InstitutionalAccreditor) => {
        if (accreditor) {
            const toastId = toast.info('Reinstating institutional accreditor...');

            systemAdministration
                .saveInstitutionalAccreditor({
                    ...accreditor,
                    isActive: true,
                })
                .then((result) => {
                    toast.update(toastId, {
                        render: 'Institutional accreditor reinstated successfully',
                        type: 'success',
                    });

                    setReload(true);
                    toggleShowRemove();
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error reinstating institutional accreditor',
                        type: 'error',
                    });
                });
        }
    };

    const accreditorCard = (accreditor: InstitutionalAccreditor) => {
        return (
            <Col key={accreditor.institutionalAccreditorId} className={'mr-3'} style={{ flexBasis: '33%' }}>
                <Card>
                    <CardBody className={'text-center'}>
                        <Label>{accreditor.title}</Label>
                    </CardBody>
                    <CardFooter className={'p-0'}>
                        <ButtonGroup className={'d-flex justify-content-between'}>
                            {accreditor.isActive === true && (
                                <>
                                    <Button
                                        type={'button'}
                                        onClick={() => {
                                            setAddEdit({ ...accreditor });
                                            toggleShowAddEdit();
                                        }}
                                    >
                                        <i className={'mdi mdi-pencil'} />
                                        <span className={'ml-1'}>{`Edit`}</span>
                                    </Button>
                                    <Button
                                        type={'button'}
                                        onClick={() => {
                                            setAddEdit({ ...accreditor });
                                            toggleShowRemove();
                                        }}
                                    >
                                        <i className={'mdi mdi-delete'} />
                                        <span className={'ml-1'}>{`Remove`}</span>
                                    </Button>
                                </>
                            )}
                            {accreditor.isActive === false && (
                                <Button
                                    type={'button'}
                                    onClick={() => {
                                        onReinstate(accreditor);
                                    }}
                                >
                                    <i className={'mdi mdi-delete-restore'} />
                                    <span className={'ml-1'}>{`Reinstate`}</span>
                                </Button>
                            )}
                        </ButtonGroup>
                    </CardFooter>
                </Card>
            </Col>
        );
    };

    return (
        <>
            <PageTitle>
                <div className={'d-flex justify-content-between py-3'}>
                    <h4>{`Institutional Accreditors`}</h4>
                    <Button
                        type={'button'}
                        color={'primary'}
                        onClick={() => {
                            setAddEdit({ institutionalAccreditorId: 0, isActive: true, title: '' });
                            toggleShowAddEdit();
                        }}
                    >
                        <i className={'mdi mdi-plus'} />
                        <span className={'ml-2'}>{`New Institutional Accreditor`}</span>
                    </Button>
                </div>
            </PageTitle>
            {!accreditors && <Skeleton count={10} />}
            {accreditors && (
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <Row className={'d-flex flex-wrap'}>
                                    {accreditors.filter((a) => a.isActive === true).map((a) => accreditorCard(a))}
                                    {!accreditors.find((a) => a.isActive === true) && (
                                        <Col>
                                            <Label>{`No active institutional accreditors.`}</Label>
                                        </Col>
                                    )}
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}
            {accreditors?.find((a) => a.isActive === false) && (
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <h4 className={'card-title'}>{`Removed Institutional Accreditors`}</h4>
                                <Row className={'d-flex flex-wrap'}>
                                    {accreditors &&
                                        accreditors.filter((a) => a.isActive === false).map((a) => accreditorCard(a))}
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}
            {addEdit && (
                <Modal isOpen={showAddEdit} toggle={toggleShowAddEdit}>
                    <ModalHeader>
                        {`${addEdit.institutionalAccreditorId === 0 ? 'New' : 'Edit'} Institutional Accreditor`}
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label>{`Institutional Accreditor Name`}</Label>
                            <Input
                                type={'text'}
                                value={addEdit.title}
                                onChange={(e) => setAddEdit({ ...addEdit, title: e.target.value })}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            type={'button'}
                            color={'secondary'}
                            className={'mr-2'}
                            onClick={() => toggleShowAddEdit()}
                        >
                            {`Cancel`}
                        </Button>
                        <Button type={'button'} color={'primary'} onClick={() => onSubmit()}>
                            <>
                                {addEdit.institutionalAccreditorId === 0 && <span>{`Create`}</span>}
                                {addEdit.institutionalAccreditorId !== 0 && <span>{`Save`}</span>}
                            </>
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
            {addEdit && (
                <Modal isOpen={showRemove} toggle={toggleShowRemove}>
                    <ModalHeader>{`Remove Institutional Accreditor`}</ModalHeader>
                    <ModalBody>
                        <p>{`In order to continue with the removal of ${addEdit.title}, please click Confirm below.`}</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            type={'button'}
                            color={'secondary'}
                            className={'mr-2'}
                            onClick={() => toggleShowRemove()}
                        >
                            {`Cancel`}
                        </Button>
                        <Button type={'button'} color={'primary'} onClick={() => onRemove()}>
                            {`Confirm`}
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
};

export default InstitutionalAccreditors;
