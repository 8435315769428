import * as yup from 'yup';

import { AnnualReportNavigationDirection, AnnualReportProps } from '../AnnualReport';
import { Button, Input } from '../../../components';
import { Card, CardBody, CardFooter, Col, Form, Label, Row } from 'reactstrap';
import { EnrollmentRetention, EnrollmentRetentionDetail, ProgramDemographics } from '../../../common/Types';
import React, { useEffect, useState } from 'react';

import { programService } from '../../../services/ProgramService';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

const Demographics = (props: AnnualReportProps) => {
    const [demographics, setDemographics] = useState<ProgramDemographics[]>();
    const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
    const [enrollmentRetention, setEnrollmentRetention] = useState<EnrollmentRetention[]>();
    const [submitDirection, setSubmitDirection] = useState<AnnualReportNavigationDirection>(
        AnnualReportNavigationDirection.Forward,
    );

    const defaultYear = props.settings.reportingWindow.yearAvarageStart || +props.reportYear - 1;

    const demographicSchema: yup.ObjectSchema<ProgramDemographics> = yup
        .object({
            demographicsId: yup.number().required().default(0),
            programId: yup.number().required().default(props.program.programId),
            graduatingYear: yup.number().required().default(defaultYear),
            gradMale: yup.number().required('This field is required').min(0).typeError('This field is required'),
            gradFemale: yup.number().required('This field is required').min(0).typeError('This field is required'),
            gradGenderNoResponse: yup
                .number()
                .required('This field is required')
                .min(0)
                .typeError('This field is required'),
            gradGenderNotListed: yup
                .number()
                .required('This field is required')
                .min(0)
                .typeError('This field is required'),
            gradLessThan25: yup.number().required('This field is required').min(0).typeError('This field is required'),
            grad25To35: yup.number().required('This field is required').min(0).typeError('This field is required'),
            grad36to45: yup.number().required('This field is required').min(0).typeError('This field is required'),
            grad45OrMore: yup.number().required('This field is required').min(0).typeError('This field is required'),
            gradUnknown: yup.number().required('This field is required').min(0).typeError('This field is required'),
            gradAfricanAmerican: yup
                .number()
                .required('This field is required')
                .min(0)
                .typeError('This field is required'),
            gradAmericanIndian: yup
                .number()
                .required('This field is required')
                .min(0)
                .typeError('This field is required'),
            gradAsian: yup.number().required('This field is required').min(0).typeError('This field is required'),
            gradCaucasian: yup.number().required('This field is required').min(0).typeError('This field is required'),
            gradHispanic: yup.number().required('This field is required').min(0).typeError('This field is required'),
            gradOther: yup.number().required('This field is required').min(0).typeError('This field is required'),
            createdBy: yup.string().notRequired(),
            dateCreated: yup.date().required(),
            lastEdited: yup.date().required(),
            editedBy: yup.string().notRequired(),
            totalGrads: yup.number().notRequired(),
            genderValidate: yup
                .number()
                .required()
                .min(yup.ref('totalGrads'), 'Graduates by gender should equal the total graduates')
                .max(yup.ref('totalGrads'), 'Graduates by gender should equal the total graduates')
                .when(
                    ['gradMale', 'gradFemale', 'gradGenderNoResponse', 'gradGenderNotListed'],
                    (gradMale, gradFemale, gradGenderNoResponse, gradGenderNotListed, schema) => {
                        const total =
                            (+gradMale || 0) +
                            (+gradFemale || 0) +
                            (+gradGenderNoResponse || 0) +
                            (+gradGenderNotListed || 0);
                        return schema.default(total);
                    },
                ),
            ageValidate: yup
                .number()
                .required()
                .min(yup.ref('totalGrads'), 'Graduates by age should equal the total graduates')
                .max(yup.ref('totalGrads'), 'Graduates by age should equal the total graduates')
                .when(
                    ['gradLessThan25', 'grad25To35', 'grad36to45', 'grad45OrMore', 'gradUnknown'],
                    (gradLessThan25, grad25To35, grad36to45, grad45OrMore, gradUnknown, schema) => {
                        const total =
                            (+gradLessThan25 || 0) +
                            (+grad25To35 || 0) +
                            (+grad36to45 || 0) +
                            (+grad45OrMore || 0) +
                            (+gradUnknown || 0);
                        return schema.default(total);
                    },
                ),
            ethnicityValidate: yup
                .number()
                .required()
                .min(yup.ref('totalGrads'), 'Graduates by ethnicity should equal the total graduates')
                .max(yup.ref('totalGrads'), 'Graduates by ethnicity should equal the total graduates')
                .when(
                    [
                        'gradAfricanAmerican',
                        'gradAmericanIndian',
                        'gradAsian',
                        'gradCaucasian',
                        'gradHispanic',
                        'gradOther',
                    ],
                    (
                        gradAfricanAmerican,
                        gradAmericanIndian,
                        gradAsian,
                        gradCaucasian,
                        gradHispanic,
                        gradOther,
                        schema,
                    ) => {
                        const total =
                            (+gradAfricanAmerican || 0) +
                            (+gradAmericanIndian || 0) +
                            (+gradAsian || 0) +
                            (+gradCaucasian || 0) +
                            (+gradHispanic || 0) +
                            (+gradOther || 0);
                        return schema.default(total);
                    },
                ),
        })
        .defined();

    const { handleSubmit, register, getValues, errors } = useForm({
        validationSchema: demographicSchema,
    });

    useEffect(() => {
        const getDemographics = () => {
            programService
                .getProgramDemographics(
                    props.program.programId,
                    props.reportYear ?? new Date().getFullYear(),
                    props.settings.reportingWindow.reportingWindow || 1,
                )
                .then((response) => {
                    setDemographics(response);
                })
                .catch(() => {
                    toast.error('Unable to get demographics.');
                });
        };

        const getEnrollmentRetention = () => {
            if (props.program.programId && props.reportYear && props.settings.reportingWindow) {
                programService
                    .getEnrollmentRetentionDataByProgramIdReportYearReportWindow(
                        props.program.programId,
                        +props.reportYear,
                        props.settings.reportingWindow.reportingWindow || 0,
                        props.settings?.reportingWindow?.yearAvarageStart || +props.reportYear - 1,
                    )
                    .then((result) => {
                        setEnrollmentRetention(result);
                    })
                    .catch(() => {
                        toast.error('Error loading graduate detail.');
                    });
            }
        };

        getDemographics();
        getEnrollmentRetention();
    }, [
        setEnrollmentRetention,
        setDemographics,
        props.program.programId,
        props.reportYear,
        props.settings,
        props.settings.reportingWindow,
    ]);

    const onSubmit = (formData) => {
        setFormSubmitting(true);
        const toastId = toast.info('Saving demographics...');

        programService
            .saveProgramDemographics(props.program.programId, formData)
            .then(() => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Demographics Saved.',
                });

                if (submitDirection === AnnualReportNavigationDirection.Forward) {
                    props.onSavedSuccessfully(AnnualReportNavigationDirection.Forward);
                }
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Failed to save demographics.',
                });
            })
            .finally(() => {
                setFormSubmitting(false);
            });
    };

    const submitWithoutValidation = () => {
        setFormSubmitting(true);
        const toastId = toast.info('Saving demographics...');

        programService
            .saveProgramDemographics(props.program.programId, getValues({ nest: true }) as ProgramDemographics)
            .then((result) => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Demographics Saved.',
                });

                if (demographics && demographics.length > 0) {
                    const demo = demographics.find((d) => d.graduatingYear === result.graduatingYear);

                    if (demo) {
                        const index = demographics.indexOf(demo, 0);
                        const newList = [...demographics];
                        newList.splice(index, 1, result);
                        setDemographics(newList);
                    } else {
                        setDemographics([...demographics, result]);
                    }
                } else {
                    setDemographics([result]);
                }
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Failed to save demographics.',
                });
            })
            .finally(() => {
                setFormSubmitting(false);
            });
    };

    const getEachReportYear = (): number[] => {
        const years = [] as number[];

        for (
            let i = props.settings.reportingWindow.yearAvarageStart || +props.reportYear - 1;
            i >=
            (props.settings.reportingWindow.yearAvarageStart || +props.reportYear) -
                (props.settings.reportingWindow.reportingWindow || 1);
            i--
        ) {
            years.push(i);
        }

        return years;
    };

    const getGraduatesByYear = (graduationYear: number): number => {
        if (enrollmentRetention) {
            const cohorts = enrollmentRetention.map((c) => {
                return c.enrollmentRetentionDetailsDto || [];
            });
            const cohortGrads = ([] as EnrollmentRetentionDetail[]).concat
                .apply([], cohorts)
                .filter((c) => c.graduationYear === graduationYear);

            let graduates = 0;

            cohortGrads.forEach((c) => {
                graduates += c.graduated || 0;
            });

            return graduates;
        }

        return 0;
    };

    const getDemographicsByGraduationYear = (year: number): ProgramDemographics | undefined => {
        if (demographics) {
            return demographics.find((e) => e.graduatingYear === year);
        }

        return undefined;
    };

    return (
        <Card>
            {enrollmentRetention && demographics && (
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <CardBody>
                        <h3 className="card-title">Demographics</h3>
                        <Row>
                            <Col>
                                <div className={'table-responsive'}>
                                    <table className={'table table-striped'}>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '25%' }}>{`Graduation Year`}</th>
                                                {getEachReportYear().map((year) => (
                                                    <th
                                                        style={{
                                                            width: `${
                                                                75 /
                                                                (props.settings.reportingWindow.reportingWindow || 1)
                                                            }%`,
                                                        }}
                                                        key={year}
                                                    >
                                                        {year}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{`Graduates`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        <span>{getGraduatesByYear(year)}</span>
                                                        {year === props.settings.reportingWindow.yearAvarageStart && (
                                                            <input
                                                                type={'hidden'}
                                                                ref={register()}
                                                                name={'totalGrads'}
                                                                value={getGraduatesByYear(year)}
                                                            />
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td
                                                    colSpan={(props.settings.reportingWindow.reportingWindow || 1) + 2}
                                                ></td>
                                            </tr>
                                            <tr>
                                                <td colSpan={(props.settings.reportingWindow.reportingWindow || 1) + 2}>
                                                    <Label>{`Gender`}</Label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{`Male`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        {year === props.settings.reportingWindow.yearAvarageStart && (
                                                            <>
                                                                <Input
                                                                    type="number"
                                                                    name="gradMale"
                                                                    innerRef={register()}
                                                                    defaultValue={
                                                                        getDemographicsByGraduationYear(year)
                                                                            ?.gradMale || 0
                                                                    }
                                                                />
                                                                {errors?.gradMale?.message && (
                                                                    <div>
                                                                        <Label className={'text-danger'}>
                                                                            {errors.gradMale.message}
                                                                        </Label>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                        {year !== props.settings.reportingWindow.yearAvarageStart && (
                                                            <span>
                                                                {getDemographicsByGraduationYear(year)?.gradMale}
                                                            </span>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`Female`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        {year === props.settings.reportingWindow.yearAvarageStart && (
                                                            <>
                                                                <Input
                                                                    type="number"
                                                                    name="gradFemale"
                                                                    innerRef={register()}
                                                                    defaultValue={
                                                                        getDemographicsByGraduationYear(year)
                                                                            ?.gradFemale || 0
                                                                    }
                                                                />
                                                                {errors?.gradFemale?.message && (
                                                                    <div>
                                                                        <Label className={'text-danger'}>
                                                                            {errors.gradFemale.message}
                                                                        </Label>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                        {year !== props.settings.reportingWindow.yearAvarageStart && (
                                                            <span>
                                                                {getDemographicsByGraduationYear(year)?.gradFemale}
                                                            </span>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`Prefer Not to Respond`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        {year === props.settings.reportingWindow.yearAvarageStart && (
                                                            <>
                                                                <Input
                                                                    type="number"
                                                                    name="gradGenderNoResponse"
                                                                    innerRef={register()}
                                                                    defaultValue={
                                                                        getDemographicsByGraduationYear(year)
                                                                            ?.gradGenderNoResponse || 0
                                                                    }
                                                                />
                                                                {errors?.gradGenderNoResponse?.message && (
                                                                    <div>
                                                                        <Label className={'text-danger'}>
                                                                            {errors.gradGenderNoResponse.message}
                                                                        </Label>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                        {year !== props.settings.reportingWindow.yearAvarageStart && (
                                                            <span>
                                                                {
                                                                    getDemographicsByGraduationYear(year)
                                                                        ?.gradGenderNoResponse
                                                                }
                                                            </span>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`Gender Not Listed`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        {year === props.settings.reportingWindow.yearAvarageStart && (
                                                            <>
                                                                <Input
                                                                    type="number"
                                                                    name="gradGenderNotListed"
                                                                    innerRef={register()}
                                                                    defaultValue={
                                                                        getDemographicsByGraduationYear(year)
                                                                            ?.gradGenderNotListed || 0
                                                                    }
                                                                />
                                                                {errors?.gradGenderNotListed?.message && (
                                                                    <div>
                                                                        <Label className={'text-danger'}>
                                                                            {errors.gradGenderNotListed.message}
                                                                        </Label>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                        {year !== props.settings.reportingWindow.yearAvarageStart && (
                                                            <span>
                                                                {
                                                                    getDemographicsByGraduationYear(year)
                                                                        ?.gradGenderNotListed
                                                                }
                                                            </span>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td colSpan={(props.settings.reportingWindow.reportingWindow || 1) + 2}>
                                                    {errors?.genderValidate?.message && (
                                                        <div>
                                                            <Label className={'text-danger'}>
                                                                {errors.genderValidate.message}
                                                            </Label>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={(props.settings.reportingWindow.reportingWindow || 1) + 2}>
                                                    <Label>{`Age`}</Label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{`Age less than 25 years`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        {year === props.settings.reportingWindow.yearAvarageStart && (
                                                            <>
                                                                <Input
                                                                    type="number"
                                                                    name="gradLessThan25"
                                                                    innerRef={register()}
                                                                    defaultValue={
                                                                        getDemographicsByGraduationYear(year)
                                                                            ?.gradLessThan25 || 0
                                                                    }
                                                                />
                                                                {errors?.gradLessThan25?.message && (
                                                                    <div>
                                                                        <Label className={'text-danger'}>
                                                                            {errors.gradLessThan25.message}
                                                                        </Label>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                        {year !== props.settings.reportingWindow.yearAvarageStart && (
                                                            <span>
                                                                {getDemographicsByGraduationYear(year)?.gradLessThan25}
                                                            </span>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`25 to 35 years`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        {year === props.settings.reportingWindow.yearAvarageStart && (
                                                            <>
                                                                <Input
                                                                    type="number"
                                                                    name="grad25To35"
                                                                    innerRef={register()}
                                                                    defaultValue={
                                                                        getDemographicsByGraduationYear(year)
                                                                            ?.grad25To35 || 0
                                                                    }
                                                                />
                                                                {errors?.grad25To35?.message && (
                                                                    <div>
                                                                        <Label className={'text-danger'}>
                                                                            {errors.grad25To35.message}
                                                                        </Label>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                        {year !== props.settings.reportingWindow.yearAvarageStart && (
                                                            <span>
                                                                {getDemographicsByGraduationYear(year)?.grad25To35}
                                                            </span>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`36 to 45 years`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        {year === props.settings.reportingWindow.yearAvarageStart && (
                                                            <>
                                                                <Input
                                                                    type="number"
                                                                    name="grad36to45"
                                                                    innerRef={register()}
                                                                    defaultValue={
                                                                        getDemographicsByGraduationYear(year)
                                                                            ?.grad36to45 || 0
                                                                    }
                                                                />
                                                                {errors?.grad36to45?.message && (
                                                                    <div>
                                                                        <Label className={'text-danger'}>
                                                                            {errors.grad36to45.message}
                                                                        </Label>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                        {year !== props.settings.reportingWindow.yearAvarageStart && (
                                                            <span>
                                                                {getDemographicsByGraduationYear(year)?.grad36to45}
                                                            </span>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`46 or more years`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        {year === props.settings.reportingWindow.yearAvarageStart && (
                                                            <>
                                                                <Input
                                                                    type="number"
                                                                    name="grad45OrMore"
                                                                    innerRef={register()}
                                                                    defaultValue={
                                                                        getDemographicsByGraduationYear(year)
                                                                            ?.grad45OrMore || 0
                                                                    }
                                                                />
                                                                {errors?.grad45OrMore?.message && (
                                                                    <div>
                                                                        <Label className={'text-danger'}>
                                                                            {errors.grad45OrMore.message}
                                                                        </Label>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                        {year !== props.settings.reportingWindow.yearAvarageStart && (
                                                            <span>
                                                                {getDemographicsByGraduationYear(year)?.grad45OrMore}
                                                            </span>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`Unknown`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        {year === props.settings.reportingWindow.yearAvarageStart && (
                                                            <>
                                                                <Input
                                                                    type="number"
                                                                    name="gradUnknown"
                                                                    innerRef={register()}
                                                                    defaultValue={
                                                                        getDemographicsByGraduationYear(year)
                                                                            ?.gradUnknown || 0
                                                                    }
                                                                />
                                                                {errors?.gradUnknown?.message && (
                                                                    <div>
                                                                        <Label className={'text-danger'}>
                                                                            {errors.gradUnknown.message}
                                                                        </Label>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                        {year !== props.settings.reportingWindow.yearAvarageStart && (
                                                            <span>
                                                                {getDemographicsByGraduationYear(year)?.gradUnknown}
                                                            </span>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td colSpan={(props.settings.reportingWindow.reportingWindow || 1) + 2}>
                                                    {errors?.ageValidate?.message && (
                                                        <div>
                                                            <Label className={'text-danger'}>
                                                                {errors.ageValidate.message}
                                                            </Label>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={(props.settings.reportingWindow.reportingWindow || 1) + 2}>
                                                    <Label>{`Ethnicity`}</Label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{`African American`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        {year === props.settings.reportingWindow.yearAvarageStart && (
                                                            <>
                                                                <Input
                                                                    type="number"
                                                                    name="gradAfricanAmerican"
                                                                    innerRef={register()}
                                                                    defaultValue={
                                                                        getDemographicsByGraduationYear(year)
                                                                            ?.gradAfricanAmerican || 0
                                                                    }
                                                                />
                                                                {errors?.gradAfricanAmerican?.message && (
                                                                    <div>
                                                                        <Label className={'text-danger'}>
                                                                            {errors.gradAfricanAmerican.message}
                                                                        </Label>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                        {year !== props.settings.reportingWindow.yearAvarageStart && (
                                                            <span>
                                                                {
                                                                    getDemographicsByGraduationYear(year)
                                                                        ?.gradAfricanAmerican
                                                                }
                                                            </span>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`American Indian`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        {year === props.settings.reportingWindow.yearAvarageStart && (
                                                            <>
                                                                <Input
                                                                    type="number"
                                                                    name="gradAmericanIndian"
                                                                    innerRef={register()}
                                                                    defaultValue={
                                                                        getDemographicsByGraduationYear(year)
                                                                            ?.gradAmericanIndian || 0
                                                                    }
                                                                />
                                                                {errors?.gradAmericanIndian?.message && (
                                                                    <div>
                                                                        <Label className={'text-danger'}>
                                                                            {errors.gradAmericanIndian.message}
                                                                        </Label>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                        {year !== props.settings.reportingWindow.yearAvarageStart && (
                                                            <span>
                                                                {
                                                                    getDemographicsByGraduationYear(year)
                                                                        ?.gradAmericanIndian
                                                                }
                                                            </span>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`Asian`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        {year === props.settings.reportingWindow.yearAvarageStart && (
                                                            <>
                                                                <Input
                                                                    type="number"
                                                                    name="gradAsian"
                                                                    innerRef={register()}
                                                                    defaultValue={
                                                                        getDemographicsByGraduationYear(year)
                                                                            ?.gradAsian || 0
                                                                    }
                                                                />
                                                                {errors?.gradAsian?.message && (
                                                                    <div>
                                                                        <Label className={'text-danger'}>
                                                                            {errors.gradAsian.message}
                                                                        </Label>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                        {year !== props.settings.reportingWindow.yearAvarageStart && (
                                                            <span>
                                                                {getDemographicsByGraduationYear(year)?.gradAsian}
                                                            </span>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`Caucasian`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        {year === props.settings.reportingWindow.yearAvarageStart && (
                                                            <>
                                                                <Input
                                                                    type="number"
                                                                    name="gradCaucasian"
                                                                    innerRef={register()}
                                                                    defaultValue={
                                                                        getDemographicsByGraduationYear(year)
                                                                            ?.gradCaucasian || 0
                                                                    }
                                                                />
                                                                {errors?.gradCaucasian?.message && (
                                                                    <div>
                                                                        <Label className={'text-danger'}>
                                                                            {errors.gradCaucasian.message}
                                                                        </Label>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                        {year !== props.settings.reportingWindow.yearAvarageStart && (
                                                            <span>
                                                                {getDemographicsByGraduationYear(year)?.gradCaucasian}
                                                            </span>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`Hispanic`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        {year === props.settings.reportingWindow.yearAvarageStart && (
                                                            <>
                                                                <Input
                                                                    type="number"
                                                                    name="gradHispanic"
                                                                    innerRef={register()}
                                                                    defaultValue={
                                                                        getDemographicsByGraduationYear(year)
                                                                            ?.gradHispanic || 0
                                                                    }
                                                                />
                                                                {errors?.gradHispanic?.message && (
                                                                    <div>
                                                                        <Label className={'text-danger'}>
                                                                            {errors.gradHispanic.message}
                                                                        </Label>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                        {year !== props.settings.reportingWindow.yearAvarageStart && (
                                                            <span>
                                                                {getDemographicsByGraduationYear(year)?.gradHispanic}
                                                            </span>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`Other`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year}>
                                                        {year === props.settings.reportingWindow.yearAvarageStart && (
                                                            <>
                                                                <Input
                                                                    type="number"
                                                                    name="gradOther"
                                                                    innerRef={register()}
                                                                    defaultValue={
                                                                        getDemographicsByGraduationYear(year)
                                                                            ?.gradOther || 0
                                                                    }
                                                                />
                                                                {errors?.gradOther?.message && (
                                                                    <div>
                                                                        <Label className={'text-danger'}>
                                                                            {errors.gradOther.message}
                                                                        </Label>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                        {year !== props.settings.reportingWindow.yearAvarageStart && (
                                                            <span>
                                                                {getDemographicsByGraduationYear(year)?.gradOther}
                                                            </span>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td colSpan={(props.settings.reportingWindow.reportingWindow || 1) + 1}>
                                                    {errors?.ethnicityValidate?.message && (
                                                        <div>
                                                            <Label className={'text-danger'}>
                                                                {errors.ethnicityValidate.message}
                                                            </Label>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                    <input
                        type="hidden"
                        name="demographicsId"
                        defaultValue={
                            getDemographicsByGraduationYear(
                                props.settings.reportingWindow.yearAvarageStart || +props.reportYear - 1,
                            )?.demographicsId ?? 0
                        }
                        ref={register()}
                    />
                    <input
                        type="hidden"
                        name="programId"
                        defaultValue={
                            getDemographicsByGraduationYear(
                                props.settings.reportingWindow.yearAvarageStart || +props.reportYear - 1,
                            )?.programId ?? props.program.programId
                        }
                        ref={register()}
                    />
                    <input
                        type="hidden"
                        name="graduatingYear"
                        defaultValue={
                            getDemographicsByGraduationYear(
                                props.settings.reportingWindow.yearAvarageStart || +props.reportYear - 1,
                            )?.graduatingYear ?? defaultYear
                        }
                        ref={register()}
                    />
                    <input
                        type="hidden"
                        name="createdBy"
                        defaultValue={
                            getDemographicsByGraduationYear(
                                props.settings.reportingWindow.yearAvarageStart || +props.reportYear - 1,
                            )?.createdBy ?? undefined
                        }
                        ref={register()}
                    />
                    <input
                        type="hidden"
                        name="dateCreated"
                        defaultValue={
                            getDemographicsByGraduationYear(
                                props.settings.reportingWindow.yearAvarageStart || +props.reportYear - 1,
                            )?.dateCreated?.toString() ?? new Date().toDateString()
                        }
                        ref={register()}
                    />
                    <input
                        type="hidden"
                        name="editedBy"
                        defaultValue={
                            getDemographicsByGraduationYear(
                                props.settings.reportingWindow.yearAvarageStart || +props.reportYear - 1,
                            )?.editedBy ?? undefined
                        }
                        ref={register()}
                    />
                    <input
                        type="hidden"
                        name="lastEdited"
                        defaultValue={
                            getDemographicsByGraduationYear(
                                props.settings.reportingWindow.yearAvarageStart || +props.reportYear - 1,
                            )?.lastEdited?.toString() ?? new Date().toDateString()
                        }
                        ref={register()}
                    />
                    {props.canEdit && (
                        <CardFooter className={'d-flex justify-content-between'}>
                            <Button
                                type={'button'}
                                color={'warning'}
                                onClick={() => props.onNavigate(AnnualReportNavigationDirection.Back)}
                            >
                                <i className={'mdi mdi-arrow-left'} />
                                <span className={'mr-1'}>{`Previous`}</span>
                            </Button>

                            <Button
                                disabled={formSubmitting}
                                type={'button'}
                                color={'primary'}
                                onClick={() => submitWithoutValidation()}
                            >
                                <i className={'mdi mdi-content-save'} />
                                <span className={'ml-1'}>{`Save`}</span>
                            </Button>

                            <Button
                                disabled={formSubmitting}
                                type={'submit'}
                                color={'info'}
                                onClick={() => setSubmitDirection(AnnualReportNavigationDirection.Forward)}
                            >
                                <span className={'mr-1'}>{`Save & Continue`}</span>
                                <i className={'mdi mdi-arrow-right'} />
                            </Button>
                        </CardFooter>
                    )}
                </Form>
            )}
        </Card>
    );
};

export default Demographics;
