import {
    AnnualReport as AnnualReportDto,
    AnnualReportStatus,
    GeneralSettings,
    Institution,
    ProfessionDto,
    Program,
} from '../../common/Types';
import { ArmsPermissionClaimType, authService } from '../../services/Auth';
import { Button, PageTitle } from '../../components';
import { Card, CardBody, CardHeader, Col, Nav, NavItem, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { Redirect, Switch, useParams } from 'react-router-dom';

import AnnualReportRoute from '../../router/AnnualReportRoute';
import { RouteItem } from '../../router/Routes';
import Skeleton from 'react-loading-skeleton';
import { annualReportService } from '../../services/AnnualReport';
import { institutionService } from '../../services/InstitutionService';
import { professionService } from '../../services/ProfessionAdministration';
import { programService } from '../../services/ProgramService';
import styled from 'styled-components';
import { toast } from 'react-toastify';

const StyledNavLink = styled.a`
    padding: 8px 0px;
`;

interface AnnualReportRoute extends RouteItem {
    priority: number;
}

export enum AnnualReportNavigationDirection {
    Back = -1,
    Stay = 0,
    Forward = 1,
}

export interface AnnualReportProps {
    program: Program;
    profession: ProfessionDto;
    settings: GeneralSettings;
    reportYear: number;
    annualReport: AnnualReportDto;
    isReadyToSubmit: boolean;
    remainingTabs: AnnualReportRoute[] | null;
    canEdit: boolean;
    hasTabAlreadyBeenSubmitted: boolean;
    onNavigate: (direction: AnnualReportNavigationDirection) => void;
    onGoToTab: (i: number) => void;
    onSavedSuccessfully: (direction: AnnualReportNavigationDirection) => void;
    onSubmitAnnualReport: () => void;
}

const AnnualReport = ({ routes }: { routes: RouteItem[] }) => {
    const [mappedRoutes, setMappedRoutes] = useState<AnnualReportRoute[]>();
    const { professionid, reportyear, programid } = useParams();
    const [profession, setProfession] = useState<ProfessionDto>();
    const [program, setProgram] = useState<Program>();
    const [settings, setSettings] = useState<GeneralSettings>();
    const [institution, setInstitution] = useState<Institution>();
    const [annualReport, setAnnualReport] = useState<AnnualReportDto>();
    const [currentTabIndex, setCurrentTabIndex] = useState<number>(-1);
    const [sentBackTabs, setSentBackTabs] = useState<number[]>([]);

    useEffect(() => {
        if (profession && program && !mappedRoutes) {
            professionService
                .getProgramTabSettings(profession.professionId)
                .then((response) => {
                    const visibleTabs = response.filter((t) => t.visible);
                    const normalizedRoutes = [
                        ...routes
                            .filter(
                                (r) =>
                                    visibleTabs.filter((t) => t.title === r.name).length > 0 ||
                                    r.name === 'Getting Started' ||
                                    r.name === 'Finish & Submit',
                            )
                            .map((route) => {
                                return {
                                    ...route,
                                    priority: visibleTabs.find((t) => t.title === route.name)?.priority,
                                    path: route.path
                                        .replace(':reportyear', reportyear.toString())
                                        .replace(':professionid', profession.professionId.toString())
                                        .replace(':programid', program.programId.toString()),
                                } as AnnualReportRoute;
                            })
                            .sort((a, b) => a.priority - b.priority),
                    ];

                    if (currentTabIndex < 0 && normalizedRoutes) {
                        const foundCurrentIndex = normalizedRoutes.findIndex(
                            (nr) => nr.path === window.location.pathname,
                        );

                        if (foundCurrentIndex >= 0) {
                            setCurrentTabIndex(foundCurrentIndex);
                        }
                    }

                    setMappedRoutes(normalizedRoutes);
                })
                .catch((error) => {
                    toast.error('Error loading report tabs');
                });
        }
    }, [profession, setMappedRoutes, reportyear, program, currentTabIndex, mappedRoutes, routes]);

    useEffect(() => {
        professionService
            .getGeneralSettings(professionid)
            .then((response) => {
                setSettings(response);
            })
            .catch(() => {
                toast.error('Error loading annual report settings.');
            });
    }, [setSettings, professionid]);

    useEffect(() => {
        professionService
            .getProfesssion(professionid)
            .then((response) => {
                setProfession(response);
            })
            .catch(() => {
                toast.error('Error loading profession.');
            });
    }, [setProfession, professionid]);

    useEffect(() => {
        programService
            .getProgram(programid)
            .then((response) => {
                setProgram(response);
            })
            .catch(() => {
                toast.error('Error loading profession.');
            });
    }, [setProfession, programid]);

    useEffect(() => {
        if (program) {
            institutionService
                .getInstitution(program.institutionId)
                .then((response) => {
                    setInstitution(response);
                })
                .catch(() => {
                    toast.error('Error loading institution.');
                });
        }
    }, [setInstitution, program]);

    useEffect(() => {
        programService
            .getAnnualReportByProfessionProgramAndYear(programid, professionid, reportyear)
            .then((result) => {
                setAnnualReport(result);
            })
            .catch(() => {
                toast.error('Error loading annual report');
            });
    }, [setAnnualReport, programid, professionid, reportyear]);

    const isTabCompleted = (tabIndex: number): boolean => {
        if (annualReport) {
            return annualReport.completedTabIds.indexOf(tabIndex) >= 0;
        }

        return false;
    };

    const handleNavigate = (direction: AnnualReportNavigationDirection) => {
        setCurrentTabIndex(currentTabIndex + direction);
    };

    const canNavigate = (): boolean => {
        return (
            (annualReport &&
                annualReport.reportStatus !== AnnualReportStatus.NotRequired &&
                annualReport.reportStatus !== AnnualReportStatus.NotStarted) ||
            false
        );
    };

    const handleNavigateToIndex = (newIndex: number) => {
        if (canNavigate()) {
            setCurrentTabIndex(newIndex);
        }
    };

    const handleTabSuccessfullySaved = (direction: AnnualReportNavigationDirection) => {
        if (annualReport) {
            const tabIndex = annualReport.completedTabIds.findIndex((t) => t === currentTabIndex);

            if (tabIndex < 0) {
                const updatedTabs = [...annualReport.completedTabIds, currentTabIndex];
                const updatedAnnualReport = {
                    ...annualReport,
                    completedTabIds: updatedTabs,
                    reportStatus: AnnualReportStatus.NotStarted
                        ? AnnualReportStatus.InProgress
                        : annualReport.reportStatus,
                    coaTitle: profession?.coaTitle ? profession?.coaTitle : '',
                    programTitle: program?.title ? program?.title : '',
                    programConcentration: program?.concentration?.title ? program?.concentration?.title : '',
                    institutionTitle: institution?.title ? institution?.title : '',
                    professionTitle: profession?.title ? profession?.title : '',
                    institutionType: institution?.institutionType,
                    programConcentrationId: program?.concentrationId || undefined,
                };
                setAnnualReport(updatedAnnualReport);

                programService.updateAnnualReport(updatedAnnualReport);
            }

            handleNavigate(direction);
        }
    };

    const isReadyToSubmit = (): boolean => {
        return (
            (annualReport &&
                (annualReport.reportStatus === AnnualReportStatus.InProgress ||
                    annualReport.reportStatus === AnnualReportStatus.SentBack ||
                    annualReport.reportStatus === AnnualReportStatus.NotStarted)) ||
            false
        );
    };

    const remainingTabs = (): AnnualReportRoute[] | null => {
        if (annualReport && mappedRoutes) {
            const mapped = mappedRoutes.reduce(
                (accumulator: AnnualReportRoute[], currentValue: AnnualReportRoute, i: number) =>
                    accumulator.concat(
                        annualReport.completedTabIds.indexOf(i) < 0
                            ? {
                                  ...currentValue,
                                  priority: i,
                              }
                            : {
                                  ...currentValue,
                                  priority: -10,
                              },
                    ),
                [],
            );

            return mapped.filter((r) => r.priority !== -10);
        }

        return null;
    };

    const canEditReport = (): boolean => {
        return (
            (annualReport &&
                annualReport.reportStatus !== AnnualReportStatus.NotRequired &&
                annualReport.reportStatus !== AnnualReportStatus.Completed &&
                annualReport.reportStatus !== AnnualReportStatus.Submitted) ||
            false
        );
    };

    const showReviewerControls = (): boolean => {
        return (
            (authService.hasPermission(ArmsPermissionClaimType, 'admin') &&
                annualReport &&
                annualReport.reportStatus === AnnualReportStatus.Submitted) ||
            false
        );
    };

    const removeIncompleteTab = (): void => {
        const currentList = [...sentBackTabs];
        const tabIndex = currentList.indexOf(currentTabIndex);
        currentList.splice(tabIndex, 1);
        setSentBackTabs([...currentList]);
    };

    const handleSubmitAnnualReport = (): void => {
        if (annualReport) {
            const toastId = toast.info('Submitting Annual Report, this may take a minute...', {
                autoClose: false,
            });

            const updatedAnnualReport = {
                ...annualReport,
                completedTabIds: [...annualReport.completedTabIds, (mappedRoutes?.length || 1) - 1],
                reportStatus: AnnualReportStatus.Submitted,
                coaTitle: profession?.coaTitle ? profession?.coaTitle : '',
                programTitle: program?.title ? program?.title : '',
                programConcentration: program?.concentration?.title ? program?.concentration?.title : '',
                institutionTitle: institution?.title ? institution?.title : '',
                professionTitle: profession?.title ? profession?.title : '',
                institutionType: institution?.institutionType,
                programConcentrationId: program?.concentrationId || undefined,
            };

            programService
                .updateAnnualReport(updatedAnnualReport)
                .then((result) => {
                    setAnnualReport(updatedAnnualReport);
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Annual Report submitted successfully',
                        autoClose: 2000,
                    });
                    handleNavigateToIndex(0);
                })
                .catch(() => {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Error submitting Annual Report',
                        autoClose: 5000,
                    });
                });
        }
    };

    const sendBackAnnualReport = (): void => {
        if (annualReport) {
            const newCompletedTabIds = [...annualReport.completedTabIds];

            sentBackTabs.forEach((t) => {
                const tabIndex = newCompletedTabIds.indexOf(t);
                newCompletedTabIds.splice(tabIndex, 1);
            });

            const updatedAnnualReport = {
                ...annualReport,
                completedTabIds: newCompletedTabIds,
                reportStatus: AnnualReportStatus.SentBack,
                coaTitle: profession?.coaTitle ? profession?.coaTitle : '',
                programTitle: program?.title ? program?.title : '',
                programConcentration: program?.concentration?.title ? program?.concentration?.title : '',
                institutionTitle: institution?.title ? institution?.title : '',
                professionTitle: profession?.title ? profession?.title : '',
                institutionType: institution?.institutionType,
                programConcentrationId: program?.concentrationId || undefined,
            };

            const toastId = toast.info('Sending Back Annual Report...');

            programService
                .updateAnnualReport(updatedAnnualReport)
                .then(() => {
                    setAnnualReport(updatedAnnualReport);
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Annual Report Sent Back',
                    });
                    handleNavigateToIndex(0);
                    setSentBackTabs([]);
                })
                .catch(() => {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Error sending back Annual Report',
                    });
                });
        }
    };

    const completeAnnualReport = (): void => {
        if (annualReport) {
            const updatedAnnualReport = {
                ...annualReport,
                reportStatus: AnnualReportStatus.Completed,
                coaTitle: profession?.coaTitle ? profession?.coaTitle : '',
                programTitle: program?.title ? program?.title : '',
                programConcentration: program?.concentration?.title ? program?.concentration?.title : '',
                institutionTitle: institution?.title ? institution?.title : '',
                professionTitle: profession?.title ? profession?.title : '',
                institutionType: institution?.institutionType,
                programConcentrationId: program?.concentrationId || undefined,
            };

            const toastId = toast.info('Completing Annual Report...');

            programService
                .updateAnnualReport(updatedAnnualReport)
                .then(() => {
                    setAnnualReport(updatedAnnualReport);
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Annual Report Complete',
                    });
                    handleNavigateToIndex(0);
                })
                .catch(() => {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Error completing Annual Report',
                    });
                });
        }
    };

    const hasTabBeenSubmitted = (): boolean => {
        if (annualReport?.completedTabIds) {
            return annualReport.completedTabIds.find((ct) => ct === currentTabIndex) !== undefined;
        }

        return false;
    };

    const getAnnualReportPdf = () => {
        const toastId = toast.info('Generating PDF, this may take a minute...', {
            autoClose: false,
        });
        if (annualReport) {
            programService
                .updateAnnualReport({
                    ...annualReport,
                    coaTitle: profession?.coaTitle,
                    institutionTitle: institution?.title,
                    programConcentration: program?.concentration?.title,
                    professionTitle: profession?.title,
                    programTitle: program?.title,
                    institutionType: institution?.institutionType,
                    programConcentrationId: program?.concentrationId || undefined,
                })
                .then(() => {
                    annualReportService
                        .getPdf(annualReport.professionId, annualReport.programId, annualReport.reportYear)
                        .then((blob) => {
                            if (blob) {
                                const url = window.URL.createObjectURL(blob);
                                const a = document.createElement('a');
                                a.href = url;
                                a.download = `Annual Report - ${annualReport.reportYear} - ${annualReport.institutionTitle} - ${program?.title}.pdf`;
                                a.click();
                                toast.update(toastId, {
                                    type: 'success',
                                    render: 'PDF generation complete',
                                    autoClose: 2000,
                                });
                            }
                        })
                        .catch(() => {
                            toast.update(toastId, {
                                type: 'error',
                                render: 'There was an error downloading the PDF',
                                autoClose: 5000,
                            });
                        });
                })
                .catch(() => {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'There was an error downloading the PDF',
                        autoClose: 5000,
                    });
                });
        }
    };

    if (mappedRoutes && currentTabIndex >= 0 && mappedRoutes[currentTabIndex]) {
        if (window.location.pathname !== mappedRoutes[currentTabIndex].path) {
            return <Redirect to={mappedRoutes[currentTabIndex].path} />;
        }
    }

    const showPreview = (): boolean => {
        return (
            (annualReport &&
                annualReport.reportStatus !== AnnualReportStatus.NotStarted &&
                annualReport.reportStatus !== AnnualReportStatus.NotRequired) ||
            false
        );
    };

    return (
        <>
            {profession && <PageTitle title={`Annual Report | ${reportyear} | ${profession.title}`} />}
            {!profession && <Skeleton count={1} />}

            <Row>
                <Col>
                    <Card className={'bg-dark text-white'}>
                        <CardBody className={'d-flex justify-content-between align-items-center'}>
                            {program && institution && (
                                <>
                                    <div>
                                        <h4 className={'card-title text-white mb-0'}>{`${program.title}`}</h4>
                                        <h5
                                            className={'card-sub-title text-muted mb-0 text-white'}
                                        >{`${institution.title}`}</h5>
                                    </div>
                                    {showPreview() && (
                                        <Button type={'button'} color={'info'} onClick={() => getAnnualReportPdf()}>
                                            <i className={'mdi mdi-file-pdf'} />
                                            <span className={`ml-1`}>{`Preview PDF`}</span>
                                        </Button>
                                    )}
                                </>
                            )}
                            {(!program || !institution) && <Skeleton count={2} />}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <Card>
                        <CardBody>
                            {(!mappedRoutes || !annualReport) && <Skeleton count={12} />}
                            {mappedRoutes && annualReport && (
                                <Nav className={'nav-tabs nav-bordered-vertical'} vertical>
                                    {mappedRoutes.map((route, i) => {
                                        return (
                                            <NavItem
                                                style={{ cursor: canNavigate() ? 'pointer' : '' }}
                                                key={i}
                                                onClick={() => handleNavigateToIndex(i)}
                                                className={window.location.pathname === route.path ? 'active' : ''}
                                            >
                                                <StyledNavLink>{route.name}</StyledNavLink>
                                                {isTabCompleted(i) && (
                                                    <i
                                                        style={{ fontWeight: 'bold' }}
                                                        className={'mdi mdi-check text-success'}
                                                    />
                                                )}
                                                {sentBackTabs.find((t) => t === i) && (
                                                    <i
                                                        style={{ fontWeight: 'bold' }}
                                                        className={'mdi mdi-window-close text-danger'}
                                                    />
                                                )}
                                            </NavItem>
                                        );
                                    })}
                                </Nav>
                            )}
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    {showReviewerControls() && (
                        <Card>
                            <CardHeader
                                className={`d-flex ${
                                    currentTabIndex === 0 && 'flex-row-reverse'
                                } justify-content-between`}
                            >
                                {currentTabIndex > 0 && (
                                    <Button
                                        type={'button'}
                                        color={'warning'}
                                        onClick={() =>
                                            currentTabIndex > 0 && handleNavigate(AnnualReportNavigationDirection.Back)
                                        }
                                    >
                                        <i className={'mdi mdi-arrow-left'} />
                                        <span className={'mr-1'}>{`Previous Tab`}</span>
                                    </Button>
                                )}

                                {currentTabIndex !== 0 &&
                                    currentTabIndex !== (mappedRoutes?.length || 0) - 1 &&
                                    !sentBackTabs.find((t) => t === currentTabIndex) && (
                                        <Button
                                            type={'button'}
                                            color={'secondary'}
                                            onClick={() => setSentBackTabs([...sentBackTabs, currentTabIndex])}
                                        >
                                            <span className={'mr-1'}>{`Mark Tab Incomplete`}</span>
                                        </Button>
                                    )}

                                {currentTabIndex !== 0 &&
                                    currentTabIndex !== (mappedRoutes?.length || 0) - 1 &&
                                    sentBackTabs.find((t) => t === currentTabIndex) && (
                                        <Button type={'button'} color={'danger'} onClick={() => removeIncompleteTab()}>
                                            <span className={'mr-1'}>{`Undo Tab Marked Incomplete`}</span>
                                        </Button>
                                    )}

                                {currentTabIndex === (mappedRoutes?.length || 0) - 1 && sentBackTabs.length > 0 && (
                                    <Button type={'button'} color={'danger'} onClick={() => sendBackAnnualReport()}>
                                        <span className={'mr-1'}>{`Send Back Annual Report`}</span>
                                    </Button>
                                )}

                                {currentTabIndex === (mappedRoutes?.length || 0) - 1 && sentBackTabs.length === 0 && (
                                    <Button type={'button'} color={'success'} onClick={() => completeAnnualReport()}>
                                        <span className={'mr-1'}>{`Mark Annual Report Complete`}</span>
                                    </Button>
                                )}

                                {currentTabIndex < (mappedRoutes?.length || 0) - 1 && (
                                    <Button
                                        type={'button'}
                                        color={'primary'}
                                        onClick={() =>
                                            currentTabIndex < (mappedRoutes?.length || 0) &&
                                            handleNavigate(AnnualReportNavigationDirection.Forward)
                                        }
                                    >
                                        <span className={'mr-1'}>{`Next Tab`}</span>
                                        <i className={'mdi mdi-arrow-right'} />
                                    </Button>
                                )}
                            </CardHeader>
                        </Card>
                    )}
                    <Switch>
                        {program &&
                            profession &&
                            settings &&
                            annualReport &&
                            routes.map((route, i) => (
                                <AnnualReportRoute
                                    key={i}
                                    {...route}
                                    reportYear={reportyear}
                                    program={program}
                                    profession={profession}
                                    settings={settings}
                                    onNavigate={handleNavigate}
                                    annualReport={annualReport}
                                    onSavedSuccessfully={handleTabSuccessfullySaved}
                                    isReadyToSubmit={isReadyToSubmit()}
                                    remainingTabs={remainingTabs()}
                                    onGoToTab={handleNavigateToIndex}
                                    canEdit={canEditReport()}
                                    onSubmitAnnualReport={handleSubmitAnnualReport}
                                    hasTabAlreadyBeenSubmitted={hasTabBeenSubmitted()}
                                />
                            ))}
                    </Switch>
                </Col>
            </Row>
        </>
    );
};

export default AnnualReport;
