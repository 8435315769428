import * as yup from 'yup';

import { Button, Input } from '../../../components';
import { Card, CardBody, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { CoaDto, ProfessionDetail, ProfessionLengthOfInitialDto } from '../../../common/Types';
import React, { useEffect, useState } from 'react';

import Skeleton from 'react-loading-skeleton';
import { coaService } from '../../../services/CoaAdministration';
import { professionService } from '../../../services/ProfessionAdministration';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

interface ProfessionDetailProps {
    detail: ProfessionDetail;
    onUpdate: () => void;
}

const ProfessionDetailCard = (props: ProfessionDetailProps) => {
    const { onUpdate } = props;
    const {
        professionId,
        title,
        isDeleted,
        armsUser,
        coaId,
        lengthOfInitial,
        showDegreeOnCertificate,
        isOneDegreePerProgram,
    } = props.detail;
    const [coas, setCoas] = useState<CoaDto[]>();
    const [editing, setEditing] = useState<boolean>(false);
    const [selectedCoa, setSelectedCoa] = useState<CoaDto>();

    const toggleEditing = () => setEditing(!editing);

    const professionSchema: yup.ObjectSchema<ProfessionDetail> = yup
        .object<ProfessionDetail>({
            professionId: yup.number().required().default(professionId),
            title: yup.string().max(1000).required().default(title),
            isDeleted: yup.boolean().required().default(isDeleted),
            armsUser: yup.boolean().required().default(armsUser),
            showDegreeOnCertificate: yup.boolean().required().default(showDegreeOnCertificate),
            coaId: yup.number().notRequired().nullable().typeError('Please select a CoA').default(coaId),
            isOneDegreePerProgram: yup.boolean().required().default(isOneDegreePerProgram),
            lengthOfInitial: yup
                .object<ProfessionLengthOfInitialDto>({
                    lengthId: yup
                        .number()
                        .required()
                        .default(lengthOfInitial?.lengthId || 0),
                    professionId: yup.number().required().default(professionId),
                    initialLength: yup
                        .number()
                        .required()
                        .min(1, 'Must be greater than 0')
                        .typeError('Must be greater than 0')
                        .default(lengthOfInitial?.initialLength),
                })
                .default(lengthOfInitial),
        })
        .defined();

    const { register, handleSubmit, reset, errors } = useForm({
        validationSchema: professionSchema,
        defaultValues: { ...props.detail } as ProfessionDetail,
    });

    useEffect(() => {
        const loadCoas = () => {
            if (!coas) {
                coaService
                    .getCoas()
                    .then((results) => {
                        setCoas([...results.filter((c) => c.isActive === true)]);
                        if (coaId && coaId > 0) {
                            setSelectedCoa(results.find((r) => r.id === coaId && r.isActive === true));
                        }
                    })
                    .catch(() => {
                        toast.error('Error loading CoA list');
                    });
            }
        };

        loadCoas();
    }, [setSelectedCoa]);

    const onSubmit = (values: ProfessionDetail) => {
        const toastId = toast.info('Saving profession detail...');

        professionService
            .saveProfessionDetail(values)
            .then((result) => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Profession detail saved successfully',
                });
                if (coas) {
                    setSelectedCoa(coas.find((r) => r.id === result.coaId && r.isActive === true));
                }
                reset(result);
                setEditing(false);
                if (onUpdate) {
                    onUpdate();
                }
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'There was an error saving profession detail',
                });
            });
    };

    console.log(errors);

    return (
        <Card>
            <CardBody>
                {coas && editing && (
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <FormGroup>
                            <Label>{`Title`}</Label>
                            <Input type={'text'} innerRef={register()} name={'title'} />
                        </FormGroup>
                        <FormGroup>
                            <Label>{`CoA`}</Label>
                            <Input type={'select'} innerRef={register()} name={'coaId'}>
                                <option value={``}>{`Select a CoA`}</option>
                                {coas.map((coa, i) => (
                                    <option key={i} value={coa.id}>
                                        {coa.coaTitle}
                                    </option>
                                ))}
                            </Input>
                            {errors?.coaId?.message && (
                                <div>
                                    <span className={'text-danger'}>{errors.coaId.message}</span>
                                </div>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label>{`Length of Initial`}</Label>
                            <Input type={'number'} innerRef={register()} name={'lengthOfInitial.initialLength'} />
                            {errors?.lengthOfInitial && errors.lengthOfInitial['initialLength']?.message && (
                                <div>
                                    <span className={'text-danger'}>
                                        {errors.lengthOfInitial['initialLength'].message}
                                    </span>
                                </div>
                            )}
                        </FormGroup>
                        <div className="custom-control custom-switch">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="armsUser"
                                name="armsUser"
                                ref={register()}
                                defaultChecked={armsUser === true}
                            />
                            <Label className="custom-control-label" for="armsUser">
                                {`Is Using ARMS?`}
                            </Label>
                        </div>
                        <div className="custom-control custom-switch mt-3">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="showDegreeOnCertificate"
                                name="showDegreeOnCertificate"
                                ref={register()}
                                defaultChecked={showDegreeOnCertificate === true}
                            />
                            <Label className="custom-control-label" for="showDegreeOnCertificate">
                                {`Show Degree on Certificate?`}
                            </Label>
                        </div>
                        <div className="custom-control custom-switch mt-3">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="isOneDegreePerProgram"
                                name="isOneDegreePerProgram"
                                ref={register()}
                                defaultChecked={isOneDegreePerProgram === true}
                            />
                            <Label className="custom-control-label" for="isOneDegreePerProgram">
                                {`One Degree Per Program?`}
                            </Label>
                        </div>
                        <Row className={`mt-3`}>
                            <Button type={`submit`} color={'primary'} className={'mr-2'}>{`Save`}</Button>
                            <Button
                                type={`button`}
                                color={'secondary'}
                                outline
                                onClick={() => toggleEditing()}
                            >{`Cancel`}</Button>
                        </Row>
                    </Form>
                )}
                {coas && !editing && (
                    <>
                        <Row>
                            <Col xs={12} className={`d-flex flex-column`}>
                                <Label>{`Title`}</Label>
                                <span>{title}</span>
                            </Col>
                            <Col xs={12} className={`d-flex flex-column mt-2`}>
                                <Label>{`CoA`}</Label>
                                {selectedCoa && <span>{selectedCoa.coaTitle}</span>}
                                {!selectedCoa && <span>{`Not Selected`}</span>}
                            </Col>
                            {selectedCoa && (
                                <Col xs={12} className={`d-flex flex-column mt-2`}>
                                    <Label>{`Chair`}</Label>
                                    {selectedCoa && <span>{selectedCoa.chairFullName}</span>}
                                    {!selectedCoa && <span>{`Not Selected`}</span>}
                                </Col>
                            )}
                            <Col xs={12} className={`d-flex flex-column mt-2`}>
                                <Label>{`Length of Initial`}</Label>
                                {lengthOfInitial && <span>{lengthOfInitial.initialLength}</span>}
                                {!lengthOfInitial && <span>{`Not Set`}</span>}
                            </Col>
                            <Col xs={12} className={`d-flex flex-column mt-2`}>
                                <Label>{`Is Using ARMS?`}</Label>
                                <span>{armsUser === true ? 'Yes' : 'No'}</span>
                            </Col>
                            <Col xs={12} className={`d-flex flex-column mt-2`}>
                                <Label>{`Show Degree on Certificate?`}</Label>
                                <span>{showDegreeOnCertificate === true ? 'Yes' : 'No'}</span>
                            </Col>
                            <Col xs={12} className={`d-flex flex-column mt-2`}>
                                <Label>{`One Degree Per Program?`}</Label>
                                <span>{isOneDegreePerProgram === true ? 'Yes' : 'No'}</span>
                            </Col>
                        </Row>
                        <Row className={`mt-3`}>
                            <Col>
                                <Button
                                    type={`button`}
                                    color={`primary`}
                                    onClick={() => toggleEditing()}
                                >{`Edit`}</Button>
                            </Col>
                        </Row>
                    </>
                )}
                {!coas && <Skeleton count={10} />}
            </CardBody>
        </Card>
    );
};

export default ProfessionDetailCard;
