import {
    Card,
    CardBody,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
} from 'reactstrap';
import { InvoicePaymentDetailDto, PagingDto } from '../../types';
import { PaymentType, PaymentTypes } from '../../common/Data';
import React, { useEffect, useState } from 'react';

import { Button } from '../../components';
import { PaymentSearchDto } from '../../types/PaymentSearchDto';
import { invoiceService } from '../../services/InvoiceService';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

const CashReceiptsReport = () => {
    const [receipts, setReceipts] = useState<InvoicePaymentDetailDto[]>();
    const [paging, setPaging] = useState<PagingDto>({
        pageNumber: 1,
        maxPageSize: null,
        pageSize: 10,
    });
    const [search, setSearch] = useState<PaymentSearchDto>({
        paymentTypeId: null,
        startDate: null,
        endDate: null,
        localTime: null,
        fiscalYear: null,
        isConfirmed: null,
        paging: paging,
    });

    useEffect(() => {
        const getData = () => {
            invoiceService
                .getInvoicePayments({
                    ...search,
                    paging: paging,
                })
                .then((response) => setReceipts(response))
                .catch(() => toast.error('Failed to get receipts'));
        };

        getData();
    }, [setReceipts, paging, search]);

    const { register, handleSubmit } = useForm();

    const onSubmit = (formData) => {
        setSearch({
            ...search,
            paymentTypeId: formData.paymentTypeId,
            startDate: formData.startDate,
            endDate: formData.endDate,
            fiscalYear: formData.fiscalYear,
            isConfirmed: formData.isConfirmed === '' ? null : Boolean(Number(formData.isConfirmed)),
        });
    };

    const exportReport = () => {
        const toastId = toast.info('Generating cash receipts report, this may take a minute...', {
            autoClose: false,
        });

        invoiceService
            .getCashReportExport({
                ...search,
            })
            .then((response) => {
                const downloadLink = window.document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(
                    new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
                );
                downloadLink.download = 'Cash Receipts Report.xlsx';
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                toast.update(toastId, {
                    render: 'Cash receipts report generated successfully.',
                    type: 'success',
                    autoClose: 2500,
                });
            })
            .catch(() => toast.error('Failed to export report'));
    };

    return (
        <>
            <Card>
                <CardBody>
                    <Row>
                        <Col>
                            <div className="mb-3">
                                <h3>Cash Receipts Report</h3>
                            </div>
                            <Form onSubmit={handleSubmit(onSubmit)} inline>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="paymentTypeId" className="mr-sm-2">
                                        Payment Type
                                    </Label>
                                    <Input
                                        type="select"
                                        innerRef={register()}
                                        name="paymentTypeId"
                                        style={{ width: '180px' }}
                                    >
                                        <option value={''}>Select...</option>
                                        {PaymentTypes?.map((pType) => (
                                            <option key={pType} value={PaymentType[pType]}>
                                                {pType}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="startDate" className="mr-sm-2">
                                        Dates between
                                    </Label>
                                    <Input
                                        type="date"
                                        innerRef={register()}
                                        name="startDate"
                                        style={{ width: '150px' }}
                                    />
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="endDate" className="mr-sm-2">
                                        and
                                    </Label>
                                    <Input
                                        type="date"
                                        innerRef={register()}
                                        name="endDate"
                                        style={{ width: '150px' }}
                                    />
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="endDate" className="mr-sm-2">
                                        Invoice Year
                                    </Label>
                                    <Input
                                        type="text"
                                        innerRef={register()}
                                        name="fiscalYear"
                                        placeholder="Invoice Year"
                                        style={{ width: '150px' }}
                                    />
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="isConfirmed" className="mr-sm-2">
                                        Confirmed
                                    </Label>
                                    <Input
                                        type="select"
                                        innerRef={register()}
                                        name="isConfirmed"
                                        style={{ width: '150px' }}
                                    >
                                        <option value={''}>Select...</option>
                                        <option value={1}>Confirmed</option>
                                        <option value={0}>Unconfirmed</option>
                                    </Input>
                                </FormGroup>
                                <div>
                                    <Button type="submit">Search</Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col style={{ display: 'flex' }}>
                            <div className="mr-2">
                                <Button color="info" onClick={() => exportReport()}>
                                    Export Report
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <div className="mt-3">
                        <h4>Payment Results</h4>
                    </div>
                </CardBody>
                <Table>
                    <thead>
                        <tr>
                            <th>Institution</th>
                            <th>Transaction/Reference #</th>
                            <th>Payment Date</th>
                            <th>Type</th>
                            <th>Amount</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {receipts ? (
                            receipts
                                .filter((x) => x.isDeleted === false)
                                .map((receipt, i) => (
                                    <tr key={receipt.id ?? i}>
                                        <td>{receipt.invoice.institutionTitle}</td>
                                        <td>
                                            {receipt.paymentType === PaymentType['Credit Card']
                                                ? receipt.transactionId
                                                : receipt.referenceNumber}
                                        </td>
                                        <td>{receipt.paymentDate && new Date(receipt.paymentDate).toDateString()}</td>
                                        <td>{PaymentType[receipt.paymentType]}</td>
                                        <td>${receipt.paymentAmount}</td>
                                        <td>{receipt.isConfirmed ? '' : 'Unconfirmed'}</td>
                                    </tr>
                                ))
                        ) : (
                            <tr>
                                <td colSpan={7}>No Results</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                <CardBody>
                    <Pagination size="sm" aria-label="Invoice Navigation">
                        <PaginationItem disabled={paging.pageNumber === 1}>
                            <PaginationLink
                                previous
                                href=""
                                onClick={() => setPaging({ ...paging, pageNumber: paging.pageNumber - 1 })}
                            />
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationLink
                                next
                                href=""
                                onClick={() => setPaging({ ...paging, pageNumber: paging.pageNumber + 1 })}
                            />
                        </PaginationItem>
                    </Pagination>
                </CardBody>
            </Card>
        </>
    );
};

export default CashReceiptsReport;
