import Logo from '../assets/images/logo-w-words.png';
import { NavLink } from 'react-router-dom';
import ProfileDropDown from './ProfileDropDown';
import React from 'react';
import { useSelector } from 'react-redux';

type Props = {
    menuToggle: Function;
};

/**
 * App Header
 * @param {Prop} props
 */
const Header = (props: Props) => {
    const user = useSelector((state) => state.auth.user);

    return (
        <div
            className="navbar-custom"
            style={{
                backgroundColor: process.env.REACT_APP_ENVIRONMENT != 'Production' ? '#ff4d42' : '$caahep-primary-blue',
            }}
        >
            <ul className="list-unstyled topnav-menu topnav-menu-left m-0 menu-icon-button d-lg-none">
                <li>
                    <button
                        type="button"
                        className="button-menu-mobile waves-effect waves-light"
                        onClick={() => props.menuToggle()}
                    >
                        <i className="fe-menu"></i>
                    </button>
                </li>
            </ul>
            <div className="logo-box">
                <NavLink to="/" className="logo text-center">
                    <span>
                        <img src={Logo} alt="logo" width="95%" />
                    </span>
                </NavLink>
            </div>
            <ul className="list-unstyled topnav-menu float-right mb-0">
                <li className="dropdown">
                    <ProfileDropDown username={user.name} />
                </li>
            </ul>
        </div>
    );
};

export default Header;
