export const getNullableNumberFromString = (numberAsString: string | number | undefined | null) => {
    return (!numberAsString && numberAsString !== 0) ? null : +numberAsString;
};

export const getNullableStringFromString = (stringAsString: string) => {
    return !stringAsString ? null : stringAsString;
};

export const getNullableDateFromString = (stringAsString: string) => {
    return !stringAsString ? null : new Date(stringAsString);
};

export const getNullableBooleanFromString = (stringAsString: string | boolean | undefined | null) => {
    return (stringAsString === undefined || stringAsString === null || stringAsString === '') ? null : stringAsString.toString() === 'true';
}

export const formatPhoneNumber = (phoneNumberString:string | undefined | null) => {
    if (!phoneNumberString) return null;
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
}

export const generateYears = () => {
    const currentYear = new Date().getFullYear();
    const years:number[] = [];

    for(let i = currentYear + 3; i >= 2016; i-- ) {
        years.push(i);
    }

    return years;
}