import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, FormGroup, Label, Row, Table } from 'reactstrap';
import { ProgramStatus } from '../../common/Data';
import { ProfessionDto, ProfessionStandardsDesignationDto, StandardReport } from '../../common/Types';
import { Button, Input, PageTitle } from '../../components';
import { professionService } from '../../services/ProfessionAdministration';
import { recommendationService } from '../../services/RecommendationService';
import { reportService } from '../../services/ReportService';

const StandardsReport = () => {
    const [professions, setProfessions] = useState<ProfessionDto[]>();
    const [years, setYears] = useState<number[]>();

    const [profession, setProfession] = useState<ProfessionDto>();
    const [year, setYear] = useState<number>();
    const [report, setReport] = useState<StandardReport>();

    useEffect(() => {
        professionService
            .getProfessions()
            .then((results) => {
                setProfessions(
                    results
                        .filter((p) => p.isDeleted === null || p.isDeleted === false)
                        .sort((a, b) => a.title.localeCompare(b.title)),
                );
            })
            .catch(() => {
                toast.error('Error loading professions');
            });
    }, [setProfessions]);

    useEffect(() => {
        setYears(undefined);
        if (profession) {
            recommendationService
                .meetingYearsByProfessionId(profession.professionId)
                .then((results) => {
                    setYears(results.sort((a, b) => b - a));
                })
                .catch(() => {
                    toast.error('Error loading meeting years');
                });
        }
    }, [profession, setYears]);

    const generateReport = async () => {
        if (profession && year) {
            const toastId = toast.info('Generate Standards Report...');

            recommendationService
                .standardsReportByProfessionIdYear(profession.professionId, year)
                .then((result) => {
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Standards Report Generated',
                    });

                    setReport(result);
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error generating report',
                        type: 'error',
                    });
                });
        }
    };

    const exportReport = () => {
        if (report) {
            const toastId = toast.info('Generating Excel download, this may take a minute...');

            reportService
                .exportRecommendationStandardsReport(report)
                .then((blob) => {
                    if (blob) {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = `RecommendationsStandardsReport.xlsx`;
                        a.click();

                        toast.update(toastId, {
                            render: 'Excel export successfully generated',
                            type: 'success',
                        });
                    }
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error exporting report',
                        type: 'error',
                    });
                });
        }
    };

    return (
        <>
            <PageTitle>
                <div className={'d-flex justify-content-between py-3'}>
                    <h4>{`Standards Report`}</h4>
                    <div>
                        <NavLink className={'btn btn-info'} to={`/recommendations/list`}>
                            <span>{`Back to List`}</span>
                        </NavLink>
                    </div>
                </div>
            </PageTitle>

            <Row>
                <Col>
                    <Card>
                        <CardBody className={'d-flex'}>
                            <FormGroup className={'mr-3'}>
                                <Label>{`Profession`}</Label>
                                {professions && (
                                    <Input
                                        type={'select'}
                                        onChange={(e) =>
                                            setProfession(professions.find((p) => p.professionId === +e.target.value))
                                        }
                                        value={profession?.professionId || -1}
                                    >
                                        <option value={-1}>{`Select`}</option>
                                        {professions.map((p) => (
                                            <option key={p.professionId} value={p.professionId}>
                                                {p.title}
                                            </option>
                                        ))}
                                    </Input>
                                )}
                            </FormGroup>
                            <FormGroup className={'mr-3'}>
                                <Label>{`Year`}</Label>
                                {years && years.length > 0 && (
                                    <Input
                                        type={'select'}
                                        onChange={(e) => setYear(years.find((y) => y === +e.target.value))}
                                        value={year || -1}
                                    >
                                        <option value={-1}>{`Select`}</option>
                                        {years.map((y) => (
                                            <option key={y} value={y}>
                                                {y}
                                            </option>
                                        ))}
                                    </Input>
                                )}
                                {years && years.length === 0 && (
                                    <div>
                                        <span>{`No years available`}</span>
                                    </div>
                                )}
                                {!years && (
                                    <div>
                                        <span>{`Please select a profession to load meeting date years.`}</span>
                                    </div>
                                )}
                            </FormGroup>
                            <FormGroup className={'d-flex flex-column-reverse'}>
                                <Button
                                    color={'primary'}
                                    disabled={!profession || !year}
                                    onClick={() => generateReport()}
                                >
                                    {`Generate Report`}
                                </Button>
                            </FormGroup>
                            <FormGroup className={'d-flex flex-column-reverse'}>
                                <Button
                                    color={'info'}
                                    disabled={!report}
                                    className={'ml-2'}
                                    onClick={() => exportReport()}
                                >
                                    {`Export to Excel`}
                                </Button>
                            </FormGroup>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {report && (
                <Row>
                    <Col>
                        <Card>
                            <Table responsive>
                                <tbody>
                                    <tr>
                                        <td>{`Total Actions`}</td>
                                        <td>{report.totalActions}</td>
                                        <td colSpan={3}></td>
                                    </tr>
                                    <tr>
                                        <td>{`Total Actions With Citations`}</td>
                                        <td>{report.totalActionsWithCitations}</td>
                                        <td colSpan={3}></td>
                                    </tr>
                                    {report.statusCounts
                                        .sort((a, b) => a.statusId - b.statusId)
                                        .map((sc) => (
                                            <tr key={sc.statusId}>
                                                <td></td>
                                                <td>{`${ProgramStatus[sc.statusId]} (Total)`}</td>
                                                <td>{sc.totalActions}</td>
                                                <td>{`${ProgramStatus[sc.statusId]} (With Citations)`}</td>
                                                <td>{sc.totalActionsWithCitations}</td>
                                            </tr>
                                        ))}
                                    <tr>
                                        <td colSpan={5}>{`Average # of Citations per Program`}</td>
                                    </tr>
                                    {report.programCitationAverageByStatus
                                        .sort((a, b) => a.statusId - b.statusId)
                                        .map((sc) => (
                                            <tr key={sc.statusId}>
                                                <td></td>
                                                <td>{`${ProgramStatus[sc.statusId]}`}</td>
                                                <td>{sc.citationsAverage}</td>
                                                <td colSpan={2}></td>
                                            </tr>
                                        ))}
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                </Row>
            )}

            {report && (
                <Row>
                    <Col>
                        <Card>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>{`STANDARD`}</th>
                                        <th></th>
                                        <th className={'text-center'}>{`INITIAL`}</th>
                                        <th className={'text-center'}>{`CONTINUING`}</th>
                                        <th className={'text-center'}>{`PROBATION`}</th>
                                        <th className={'text-center'}>{`WITHDRAW`}</th>
                                        <th className={'text-center'}>{`WITHOLD`}</th>
                                        <th className={'text-center'}>{`TOTAL`}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {report.detailRows &&
                                        report.detailRows.map((r) => (
                                            <tr key={`${r.designationNumber}:${r.designationText}`}>
                                                <td>{r.designationNumber}</td>
                                                <td>{r.designationText}</td>
                                                <td className={'text-center'}>
                                                    {r.initialCount === null ? '' : r.initialCount}
                                                </td>
                                                <td className={'text-center'}>
                                                    {r.continuingCount === null ? '' : r.continuingCount}
                                                </td>
                                                <td className={'text-center'}>
                                                    {r.probationCount === null ? '' : r.probationCount}
                                                </td>
                                                <td className={'text-center'}>
                                                    {r.withdrawCount === null ? '' : r.withdrawCount}
                                                </td>
                                                <td className={'text-center'}>
                                                    {r.witholdCount === null ? '' : r.witholdCount}
                                                </td>
                                                <td className={'text-center'}>
                                                    {r.totalCount === null ? '' : r.totalCount}
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default StandardsReport;
