import React from 'react';
import styled from 'styled-components';

const StyledIconButton = styled.button`
    border: none;
    background: none;
`;

const IconButton = (props) => {
    return (
        <StyledIconButton type="button" {...props.buttonProps}>
            <i className={`${props.iconClass ? props.iconClass : 'remixicon-add-line'} mr-1`}></i>
        </StyledIconButton>
    );
};

export default IconButton;
