import React, { useState, useEffect } from 'react';
import { InvoicingActivityDto } from '../../../types';
import { Card, CardBody, CardTitle } from 'reactstrap';
import moment from 'moment';

interface InvoiveTimelineProps {
    activity: InvoicingActivityDto[];
}

const InvoiceTimeline = (props: InvoiveTimelineProps) => {
    const [activity, setActivity] = useState<InvoicingActivityDto[]>([]);

    // Sort
    useEffect(() => {
        setActivity(props.activity.sort((a, b) => moment(a.timeStamp).diff(moment(b.timeStamp))).reverse());
    }, [setActivity, props.activity]);

    return (
        <Card>
            <CardBody>
                <CardTitle>
                    <h3>Invoice Timeline</h3>
                </CardTitle>
                <div className="timeline">
                    {activity.map((timelineEvent, i) => (
                        <article className={`timeline-item ${i % 2 === 0 ? 'timeline-item-left' : ''}`} key={i}>
                            <div className="timeline-desk">
                                <div className="timeline-box">
                                    <span className={i % 2 === 0 ? 'arrow-alt' : 'arrow'}></span>
                                    <span className="timeline-icon">
                                        <i className="mdi mdi-adjust"></i>
                                    </span>
                                    <h4 className="mt-0 font-16">
                                        ${moment.utc(timelineEvent.timeStamp).local().format('MM/DD/YYYY hh:mm A')}
                                    </h4>
                                    <p className="text-muted">
                                        <small>{timelineEvent.userEmail}</small>
                                    </p>
                                    <p className="mb-0">
                                        {timelineEvent.activityType.invoicingActivityType
                                            .replace(/([A-Z])/g, ' $1')
                                            .replace(/^./, function (str) {
                                                return str.toUpperCase();
                                            })}
                                    </p>
                                </div>
                            </div>
                        </article>
                    ))}
                </div>
                {activity.length === 0 && <div>No Activity Recorded</div>}
            </CardBody>
        </Card>
    );
};

export default InvoiceTimeline;
