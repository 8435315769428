import React from 'react';
import { Route as DOMRouter, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AuthenticatedContainer from '../containers/AuthenticatedContainer';

const PrivateRoute = (route) => {
    const auth = useSelector((state) => state.auth);
    return (
        <DOMRouter
            path={route.path}
            render={(props) =>
                auth.authenticated ? (
                    <AuthenticatedContainer>
                        <route.component {...props} routes={route.routes} />
                    </AuthenticatedContainer>
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
};

export default PrivateRoute;
