import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import { CoaDto, UserManagement } from '../../common/Types';
import { ErrorMessage, useForm } from 'react-hook-form';
import React, { ChangeEvent, useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import { authService } from '../../services/Auth';
import { coaService } from '../../services/CoaAdministration';
import { isArray } from 'util';
import { securityService } from '../../services/SecurityService';
import { toast } from 'react-toastify';

interface ModalDetail {
    header: string;
    confirmationButtonText: string;
    description: string;
    userId: string;
    action: (userId: string) => void;
}

const Users = () => {
    const [users, setUsers] = useState<UserManagement[]>([]);
    const [coas, setCoas] = useState<CoaDto[]>([]);
    const [reloading, reload] = useState(true);
    const [showRemoved, setShowRemoved] = useState(false);
    const [showModal, setModal] = useState(false);
    const [showFormModal, setFormModal] = useState(false);
    const [addEditUser, setAddEditUser] = useState<UserManagement>({
        email: '',
        isRemoved: false,
        name: '',
        roles: [],
        userId: '',
        firstName: '',
        lastName: '',
        coaIds: [],
    });
    const { handleSubmit, register, reset, errors, watch, getValues } = useForm();

    const watchRole = watch('roles');

    useEffect(() => {
        if (reloading) {
            reload(false);
            securityService
                .getCaahepAndCoaUsers()
                .then((response) => {
                    setUsers(response || []);
                })
                .catch(() => {
                    toast.error('There was an issue loading users.');
                });
        }
    }, [setUsers, reloading]);

    useEffect(() => {
        coaService
            .getCoas()
            .then((response) => {
                setCoas(response.filter((c) => c.isActive === true) || []);
            })
            .catch(() => {
                toast.error('There was an issue loading CoAs.');
            });
    }, [setCoas]);

    const showUser = (user): boolean => {
        return !user.isRemoved || (user.isRemoved && showRemoved);
    };

    const toggleModal = () => setModal(!showModal);
    const toggleFormModal = () => setFormModal(!showFormModal);

    const removeUser = (userId: string): void => {
        const toastId = toast.info('Removing user...');

        securityService
            .removeUser(userId)
            .then(() => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'User removed!',
                });
                reload(true);
                setModal(false);
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'There was an issue removing the user.',
                });
            });
    };

    const reinstateUser = (userId: string): void => {
        const toastId = toast.info('Reinstating user...');

        securityService
            .reinstateUser(userId)
            .then(() => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'User reinstated!',
                });
                reload(true);
                setModal(false);
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'There was an issue reinstating the user.',
                });
            });
    };

    const initiatePasswordReset = (userId: string): void => {
        const toastId = toast.info('Sending password reset instructions...');

        securityService
            .initiatePasswordReset(userId)
            .then(() => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Password reset instructions sent!',
                });
                reload(true);
                setModal(false);
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'There was an issue sending password reset instructions.',
                });
            });
    };

    const [modalDetail, setModalDetail] = useState<ModalDetail>({
        action: removeUser,
        confirmationButtonText: 'Remove User',
        description: 'Test',
        header: 'Confirm User Removal',
        userId: '',
    });

    const triggerModalConfirmation = (newModalDetail: ModalDetail): void => {
        setModalDetail(newModalDetail);
        toggleModal();
    };

    const startUserManage = (user: UserManagement): void => {
        setAddEditUser(user);
        reset(user);
        toggleFormModal();
    };

    const isSelf = (user: UserManagement): boolean => {
        return authService.getProfile()!.sub === user.userId;
    };

    const saveUser = (user: any): void => {
        const toastId = toast.info('Saving...');

        securityService
            .createOrUpdateUser(new UserManagement(user))
            .then(() => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Save successful!',
                });
                reload(true);
                setFormModal(false);
            })
            .catch((error) => {
                toast.update(toastId, {
                    type: 'error',
                    render: error.message || 'There was an issue saving the user information.',
                });
            });
    };

    const hasRoleSelected = (role: string): boolean => {
        const user = getValues({ nest: true });

        if (user && user.roles && !isArray(user.roles)) {
            user.roles = user.roles.split(',');
        }

        return user && user.roles && user.roles.filter((r) => r === role).length > 0;
    };

    const changeRoleSelection = (e: ChangeEvent<HTMLInputElement>, role: string): void => {
        const user = getValues({ nest: true });

        if (user && user.roles && !isArray(user.roles)) {
            user.roles = user.roles.split(',');
        }

        if (e.target.checked) {
            if (!user.roles || (user.roles && user.roles.filter((r) => r === role).length === 0)) {
                user.roles = [...user.roles, role];
            }
        }

        if (!e.target.checked && user.roles) {
            const index = user.roles.indexOf(role);

            if (index >= 0) {
                user.roles.splice(index, 1);
            }
        }

        if (user.roles && user.roles.filter((r) => r === 'CoA Administrator').length === 0) {
            user.coaIds = [];
        }

        reset(user);
    };

    const hasCoaSelected = (id: number): boolean => {
        const user = getValues({ nest: true });

        if (user && user.coaIds && !isArray(user.coaIds)) {
            user.coaIds = user.coaIds.split(',').map((c) => {
                return +c;
            });
        }

        return (user && user.coaIds && user.coaIds.filter((c) => c === id).length > 0) || false;
    };

    const changeCoaSelection = (e: ChangeEvent<HTMLInputElement>, id: number): void => {
        const user = getValues({ nest: true });

        if (user && user.coaIds && !isArray(user.coaIds)) {
            user.coaIds = user.coaIds.split(',').map((c) => {
                return +c;
            });
        }

        if (e.target.checked) {
            if (!user.coaIds || (user.coaIds && user.coaIds.filter((c) => c === id).length === 0)) {
                user.coaIds = [...user.coaIds, id];
            }
        }

        if (!e.target.checked && user.coaIds) {
            const index = user.coaIds.indexOf(id);

            if (index >= 0) {
                user.coaIds.splice(index, 1);
            }
        }
        console.log('resetting');
        reset(user);
    };

    return (
        <>
            <Row>
                <Col>
                    <PageTitle title="Users" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className={'card-box'}>
                        <div className={'responsive-table-plugin'}>
                            <div className={'table-wrapper'}>
                                <div className={'btn-toolbar clearfix'}>
                                    <div className={'btn-group'}>
                                        <Button
                                            color="info"
                                            type="button"
                                            className="add"
                                            onClick={() =>
                                                startUserManage({
                                                    email: '',
                                                    isRemoved: false,
                                                    name: '',
                                                    roles: [],
                                                    userId: '',
                                                    firstName: '',
                                                    lastName: '',
                                                    coaIds: [],
                                                })
                                            }
                                        >
                                            <i className={'mdi mdi-account-plus'} />
                                            <span className={'ml-1'}>Add New User</span>
                                        </Button>
                                    </div>
                                    <div className={'btn-group pull-right'}>
                                        <button
                                            type="button"
                                            className={`btn btn-default ${showRemoved && 'btn-primary'}`}
                                            onClick={() => setShowRemoved(!showRemoved)}
                                        >
                                            Show Removed Users
                                        </button>
                                    </div>
                                </div>
                                <div className={'table-responsive mt-2'}>
                                    <table className={'table table-hover'}>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Role</th>
                                                <th>CoA Association</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users &&
                                                users.map(
                                                    (user) =>
                                                        showUser(user) && (
                                                            <tr
                                                                key={user.userId}
                                                                className={`${user.isRemoved && 'table-danger'}`}
                                                            >
                                                                <td>
                                                                    {isSelf(user) && (
                                                                        <span
                                                                            className={`mr-2 mb-2 badge badge-primary`}
                                                                        >
                                                                            You
                                                                        </span>
                                                                    )}
                                                                    {user.name}
                                                                </td>
                                                                <td className="user-email">{user.email}</td>
                                                                <td>
                                                                    {user.roles.map((role) => (
                                                                        <div key={role}>
                                                                            <span>{role}</span>
                                                                        </div>
                                                                    ))}
                                                                </td>
                                                                <td>
                                                                    {user.coaIds.map((coaId) => (
                                                                        <div key={coaId}>
                                                                            <span>
                                                                                {coas.filter((c) => c.id === coaId)
                                                                                    .length > 0 && (
                                                                                    <span>
                                                                                        {
                                                                                            coas.filter(
                                                                                                (c) => c.id === coaId,
                                                                                            )[0].coaTitle
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                    ))}
                                                                </td>
                                                                <td className={'text-right'}>
                                                                    {!isSelf(user) && (
                                                                        <div className={'btn btn-group'}>
                                                                            <button
                                                                                type="button"
                                                                                className={'btn btn-sm btn-default'}
                                                                                onClick={() => startUserManage(user)}
                                                                            >
                                                                                <i className={'mdi mdi-account-edit'} />
                                                                                <span className={'ml-1'}>Edit</span>
                                                                            </button>
                                                                            {!user.isRemoved && (
                                                                                <button
                                                                                    type="button"
                                                                                    className={'btn btn-sm btn-default'}
                                                                                    onClick={() =>
                                                                                        triggerModalConfirmation({
                                                                                            action: initiatePasswordReset,
                                                                                            confirmationButtonText:
                                                                                                'Send Password Reset Instruction',
                                                                                            description: `Please click the button below to send password reset instructions to  
                                                                                          ${user.name} (${user.email}).`,
                                                                                            header: 'Password Reset',
                                                                                            userId: user.userId,
                                                                                        })
                                                                                    }
                                                                                >
                                                                                    <i
                                                                                        className={
                                                                                            'mdi mdi-account-key'
                                                                                        }
                                                                                    />
                                                                                    <span className={'ml-1'}>
                                                                                        Reset Password
                                                                                    </span>
                                                                                </button>
                                                                            )}
                                                                            {user.isRemoved && (
                                                                                <button
                                                                                    type="button"
                                                                                    className={'btn btn-sm btn-default'}
                                                                                    onClick={() =>
                                                                                        triggerModalConfirmation({
                                                                                            action: reinstateUser,
                                                                                            confirmationButtonText:
                                                                                                'Confirm Reinstatement',
                                                                                            description: `Please click the button below to confirm reinstatement of the user 
                                                                                          ${user.name} (${user.email}). Once completed, the user will be 
                                                                                          allows to log into the system.`,
                                                                                            header: 'Reinstate User',
                                                                                            userId: user.userId,
                                                                                        })
                                                                                    }
                                                                                >
                                                                                    <i
                                                                                        className={
                                                                                            'mdi mdi-account-remove'
                                                                                        }
                                                                                    />
                                                                                    <span className={'ml-1'}>
                                                                                        Reinstate
                                                                                    </span>
                                                                                </button>
                                                                            )}
                                                                            {!user.isRemoved && (
                                                                                <button
                                                                                    type="button"
                                                                                    className={'btn btn-sm btn-danger'}
                                                                                    onClick={() =>
                                                                                        triggerModalConfirmation({
                                                                                            action: removeUser,
                                                                                            confirmationButtonText:
                                                                                                'Confirm Removal',
                                                                                            description: `Please click the button below to confirm removal of the user 
                                                                            ${user.name} (${user.email}). Once completed, the user will no longer be permitted
                                                                            to log into the system.`,
                                                                                            header: 'Remove User',
                                                                                            userId: user.userId,
                                                                                        })
                                                                                    }
                                                                                >
                                                                                    <i
                                                                                        className={
                                                                                            'mdi mdi-account-plus'
                                                                                        }
                                                                                    />
                                                                                    <span className={'ml-1'}>
                                                                                        Remove
                                                                                    </span>
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                    {isSelf(user) && (
                                                                        <div className={'btn btn-group'}>
                                                                            <NavLink
                                                                                className={'btn btn-default'}
                                                                                to={`/profile`}
                                                                            >
                                                                                <i
                                                                                    className={
                                                                                        'mdi mdi-account-card-details'
                                                                                    }
                                                                                />
                                                                                <span className={'ml-1'}>
                                                                                    Manage My Account
                                                                                </span>
                                                                            </NavLink>
                                                                        </div>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ),
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Modal isOpen={showModal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>{modalDetail.header}</ModalHeader>
                <ModalBody>{modalDetail.description}</ModalBody>
                <ModalFooter>
                    <Button type="button" color={'primary'} onClick={() => modalDetail.action(modalDetail.userId)}>
                        {modalDetail.confirmationButtonText}
                    </Button>
                    <Button type="button" color={'link'} onClick={() => toggleFormModal()}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={showFormModal} toggle={toggleFormModal} size={'lg'}>
                <Form onSubmit={handleSubmit((values) => saveUser(values as UserManagement))}>
                    <ModalHeader toggle={toggleFormModal}>{`${
                        addEditUser.userId === '' ? 'Add New' : 'Edit'
                    } User`}</ModalHeader>
                    <ModalBody>
                        <input type="hidden" name="userId" defaultValue={addEditUser.userId} ref={register({})} />
                        <FormGroup className={'row'}>
                            <Label className={'col-form-label col-md-3'}>First Name</Label>
                            <Col md={9}>
                                <Input
                                    name={'firstName'}
                                    type="text"
                                    defaultValue={addEditUser.firstName}
                                    innerRef={register({ required: 'First Name is required.' })}
                                />
                                <ErrorMessage errors={errors} name="firstName">
                                    {({ message }) => <div className={'invalid-feedback'}>{message}</div>}
                                </ErrorMessage>
                            </Col>
                        </FormGroup>
                        <FormGroup className={'row'}>
                            <Label className={'col-form-label col-md-3'}>Last Name</Label>
                            <Col md={9}>
                                <Input
                                    name={'lastName'}
                                    type="text"
                                    defaultValue={addEditUser.lastName}
                                    innerRef={register({ required: 'Last Name is required.' })}
                                />
                                <ErrorMessage errors={errors} name="lastName">
                                    {({ message }) => <div className={'invalid-feedback'}>{message}</div>}
                                </ErrorMessage>
                            </Col>
                        </FormGroup>
                        <FormGroup className={'row'}>
                            <Label className={'col-form-label col-md-3'}>Email</Label>
                            <Col md={9}>
                                <Input
                                    name={'email'}
                                    type="text"
                                    defaultValue={addEditUser.email}
                                    innerRef={register({
                                        required: 'Email is required.',
                                        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    })}
                                />
                                <ErrorMessage errors={errors} name="email">
                                    {({ message }) => <div className={'invalid-feedback'}>{message}</div>}
                                </ErrorMessage>
                            </Col>
                        </FormGroup>
                        <FormGroup className={'row'}>
                            <Label className={'col-form-label col-md-3'}>Role</Label>
                            <Col md={9}>
                                <input
                                    type="hidden"
                                    name="roles"
                                    defaultValue={addEditUser.roles}
                                    ref={register({
                                        required: 'A role is required.',
                                    })}
                                />
                                <div className={'custom-control custom-checkbox mb-2'}>
                                    <input
                                        type="checkbox"
                                        id="admin"
                                        className={'custom-control-input'}
                                        value={'Administrator'}
                                        checked={hasRoleSelected('Administrator')}
                                        onChange={(e) => changeRoleSelection(e, 'Administrator')}
                                    />
                                    <Label className={'custom-control-label'} for="admin">
                                        Administrator
                                    </Label>
                                </div>
                                <div className={'custom-control custom-checkbox mb-2'}>
                                    <input
                                        type="checkbox"
                                        id="caahep"
                                        className={'custom-control-input'}
                                        value={'CAAHEP General User'}
                                        checked={hasRoleSelected('CAAHEP General User')}
                                        onChange={(e) => changeRoleSelection(e, 'CAAHEP General User')}
                                    />
                                    <Label className={'custom-control-label'} for="caahep">
                                        CAAHEP General User
                                    </Label>
                                </div>
                                <div className={'custom-control custom-checkbox mb-2'}>
                                    <input
                                        type="checkbox"
                                        id="coa"
                                        className={'custom-control-input'}
                                        value={'CoA Administrator'}
                                        checked={hasRoleSelected('CoA Administrator')}
                                        onChange={(e) => changeRoleSelection(e, 'CoA Administrator')}
                                    />
                                    <Label className={'custom-control-label'} for="coa">
                                        CoA Administrator
                                    </Label>
                                </div>
                            </Col>
                        </FormGroup>
                        {watchRole && watchRole.toString().indexOf('CoA Administrator') >= 0 && (
                            <FormGroup className={'row'}>
                                <Label className={'col-form-label col-md-3'}>CoA(s)</Label>
                                <Col md={9}>
                                    <input
                                        type="hidden"
                                        name="coaIds"
                                        defaultValue={addEditUser.coaIds.toString()}
                                        ref={register({
                                            required: 'A CoA selection is required.',
                                        })}
                                    />
                                    {coas.map(
                                        (coa, i) =>
                                            coa.coaTitle && (
                                                <div key={i} className={'custom-control custom-checkbox mb-2'}>
                                                    <input
                                                        type="checkbox"
                                                        id={`coa${i}`}
                                                        className={'custom-control-input'}
                                                        value={coa.id}
                                                        checked={hasCoaSelected(coa.id)}
                                                        onChange={(e) => changeCoaSelection(e, coa.id)}
                                                    />
                                                    <Label className={'custom-control-label'} for={`coa${i}`}>
                                                        {coa.coaTitle}
                                                    </Label>
                                                    <div className={'d-flex flex-column'}>
                                                        {coa.professions &&
                                                            coa.professions.map((prof) => (
                                                                <div
                                                                    className={'pt-2 pl-2 d-flex flex-column'}
                                                                    key={prof.professionId}
                                                                >
                                                                    <span>{`Profession: ${prof.title}`}</span>
                                                                    {prof.concentrations &&
                                                                        prof.concentrations.length > 0 && (
                                                                            <div
                                                                                className={
                                                                                    'pt-2 pl-2 d-flex flex-column'
                                                                                }
                                                                            >
                                                                                <span>{`Concentrations`}</span>
                                                                                {prof.concentrations.map((c) => (
                                                                                    <span
                                                                                        key={c.concentrationId}
                                                                                        className={'pt-2 pl-2'}
                                                                                    >
                                                                                        {c.title}
                                                                                    </span>
                                                                                ))}
                                                                            </div>
                                                                        )}
                                                                    {prof.addOnTracks && prof.addOnTracks.length > 0 && (
                                                                        <div className={'pt-2 pl-2 d-flex flex-column'}>
                                                                            <span>{`Add-On Tracks`}</span>
                                                                            {prof.addOnTracks.map((a) => (
                                                                                <span
                                                                                    key={a.addOnTrackId}
                                                                                    className={'pt-2 pl-2'}
                                                                                >
                                                                                    {a.title}
                                                                                </span>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>
                                            ),
                                    )}
                                    <ErrorMessage errors={errors} name="coaId">
                                        {({ message }) => <div className={'invalid-feedback'}>{message}</div>}
                                    </ErrorMessage>
                                </Col>
                            </FormGroup>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" color={'primary'}>
                            Save Changes
                        </Button>
                        <Button type="button" color={'link'} onClick={() => toggleFormModal()}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};

export default Users;
