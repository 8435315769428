import { Button, IconButton } from '../../components';
import { Card, CardBody, CardTitle, Col, Input, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import React, { useEffect, useState } from 'react';

import { PersonnelSettings } from '../../common/Types';
import Skeleton from 'react-loading-skeleton';
import { professionService } from '../../services/ProfessionAdministration';
import styled from 'styled-components';
import { toast } from 'react-toastify';

const StyledDraggable = styled.div`
    color: white;
    @media (min-width: 576px) {
        min-width: 400px;
    }

    label {
        margin-bottom: 0px;

        &:first-of-type {
            padding-right: 8px;
        }
    }
`;

const AdvisoryCommitteePage = (props) => {
    const [originalPersonnel, setOriginalPersonnel] = useState<PersonnelSettings[]>();
    const [personnel, setPersonnel] = useState<PersonnelSettings[]>();
    const [newTypeText, setNewTypeText] = useState<string>();
    const [editing, setEditing] = useState(false);

    const toggleEdit = () => setEditing(!editing);

    const handleSubmit = () => {
        const toastId = toast.info('Saving settings...');

        professionService
            .saveAdvisoryCommitteeSettings(props.coa.professionId, personnel)
            .then((response) => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Settings Saved.',
                });
                setPersonnel([...response]);
                setOriginalPersonnel([...response]);
                toggleEdit();
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Failed to save settings.',
                });
            });
    };

    useEffect(() => {
        const getPersonnel = () => {
            professionService
                .getAdvisoryCommitteeSettings(props.coa.professionId)
                .then((response) => {
                    setPersonnel([...response]);
                    setOriginalPersonnel([...response]);
                })
                .catch(() => {
                    toast.error('Unable to get settings.');
                });
        };

        getPersonnel();
    }, [setPersonnel, props.coa]);

    const grid = 8;
    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: 'none',
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,
        color: 'white',
        // change background color if dragging
        background: isDragging ? 'gray' : '',
        // styles we need to apply on draggables
        ...draggableStyle,
    });

    const getListStyle = (isDraggingOver) => ({
        background: isDraggingOver ? 'gray' : 'white',
        padding: `0 ${grid} 0 ${grid}`,
    });

    const reorder = (list, startIndex, endIndex): PersonnelSettings[] => {
        const result = [...list];
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result.map((set, i) => ({ ...set, priority: i + 1 })) as PersonnelSettings[];
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const orderedSettings = reorder(personnel, result.source.index, result.destination.index);
        setPersonnel([...orderedSettings]);
    };

    const toggleVisibility = (item, index) => {
        const newArray = [...(personnel || [])];
        newArray.splice(index, 1, { ...item, isActive: !item.isActive });
        setPersonnel(newArray.sort((a, b) => a.priority - b.priority));
    };

    const addNewType = () => {
        if (newTypeText) {
            setPersonnel([
                ...(personnel || []),
                {
                    editable: true,
                    id: 0,
                    isActive: true,
                    isAdvisoryCommittee: true,
                    isCustom: true,
                    lockChangesToData: false,
                    personnelTypeId: 0,
                    priority: (personnel?.length || 0) + 1,
                    professionId: props.coa.professionId,
                    selected: false,
                    title: newTypeText,
                },
            ]);
            setNewTypeText(undefined);
        }
    };

    const cancelEdit = () => {
        setPersonnel([...(originalPersonnel || [])]);
        toggleEdit();
    };

    return (
        <>
            <Col>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h3>Advisory Committee</h3>
                        </CardTitle>
                        {editing && (
                            <>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={getListStyle(snapshot.isDraggingOver)}
                                            >
                                                {personnel?.map((item, index) => (
                                                    <Draggable
                                                        key={item.priority}
                                                        draggableId={item.priority.toString()}
                                                        index={index}
                                                        isDragDisabled={!item.editable}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <StyledDraggable
                                                                className={
                                                                    !item.isActive
                                                                        ? 'bg-warning'
                                                                        : item.personnelTypeId > 0
                                                                        ? 'bg-info'
                                                                        : 'bg-success'
                                                                }
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style,
                                                                )}
                                                            >
                                                                <Label>{item.priority}</Label>
                                                                <Label>{item.title}</Label>
                                                                {item.editable ? (
                                                                    <span style={{ float: 'right' }}>
                                                                        {item.isActive ? (
                                                                            <IconButton
                                                                                iconClass="remixicon-delete-bin-line text-white"
                                                                                buttonProps={{
                                                                                    onClick: () =>
                                                                                        toggleVisibility(item, index),
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <IconButton
                                                                                iconClass="remixicon-eye-line text-white"
                                                                                buttonProps={{
                                                                                    onClick: () =>
                                                                                        toggleVisibility(item, index),
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </span>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </StyledDraggable>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                <Row className="mt-3">
                                    <Col md={6}>
                                        <div>
                                            <InputGroup>
                                                <Input
                                                    type={'text'}
                                                    value={newTypeText}
                                                    onChange={(e) => setNewTypeText(e.target.value)}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <Button type={'button'} color={'info'} onClick={() => addNewType()}>
                                                        Add Member Type
                                                    </Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <div>
                                            <Button className={'mr-2'} type="button" onClick={() => handleSubmit()}>
                                                Save
                                            </Button>
                                            <Button outline onClick={() => cancelEdit()}>
                                                Cancel
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        )}
                        {!editing && (
                            <>
                                {personnel && (
                                    <>
                                        <Row>
                                            <Col sm={6} md={5}>
                                                <>
                                                    <Label>Communities of Interest:</Label>
                                                    {personnel.map(
                                                        (person, i) =>
                                                            person.isActive && (
                                                                <Row key={i}>
                                                                    <Col className="personnel-title">
                                                                        {person.title}
                                                                    </Col>
                                                                </Row>
                                                            ),
                                                    )}
                                                </>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col>
                                                <Button onClick={() => toggleEdit()}>Edit</Button>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                                {!personnel && (
                                    <Row>
                                        <Col sm={6} md={5}>
                                            <Skeleton count={10} />
                                        </Col>
                                        <Col sm={6} md={5}>
                                            <Skeleton count={5} />
                                        </Col>
                                    </Row>
                                )}
                            </>
                        )}
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default AdvisoryCommitteePage;
