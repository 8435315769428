import React from 'react';
import * as yup from 'yup';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useFieldArray, useForm } from 'react-hook-form';
import { Form, FormGroup, Label, Input, Table, InputGroup, InputGroupText } from 'reactstrap';
import { InvoicingDto } from '../../../types';
import { Button } from '../../../components';
import { invoiceService } from '../../../services/InvoiceService';
import { generateYears } from '../../../common/Utils';

interface InvoiceFormProps {
    invoice: InvoicingDto;
    onSave: () => void;
    onCancel: () => void;
}

const InvoiceForm = (props: InvoiceFormProps) => {
    const schema: yup.ObjectSchema<InvoicingDto> = yup
        .object({
            id: yup.number().required().default(props.invoice.id),
            institutionId: yup.number().required().default(props.invoice.institutionId),
            institutionTitle: yup.string().required().default(props.invoice.institutionTitle),
            status: yup.number().required().default(props.invoice.status),
            fiscalYear: yup.string().notRequired().default(props.invoice.fiscalYear),
            description: yup.string().notRequired().default(props.invoice.description),
            amount: yup.number().notRequired().default(props.invoice.amount),
            tiers: yup
                .array()
                .of(
                    yup
                        .object({
                            numProfessions: yup.number().required().min(1),
                            perProfessionFee: yup.number().required().min(0),
                        })
                        .defined(),
                )
                .required(),
            invoiceDate: yup.string().required().default(moment(props.invoice.invoiceDate).format('YYYY-MM-DD')),
            automaticallySendOn: yup.string().nullable().notRequired().default(props.invoice.automaticallySendOn),
            sendAutomatically: yup.boolean().notRequired().default(props.invoice.sendAutomatically),
            createDate: yup.string().notRequired().default(props.invoice.createDate),
            lastUpdatedDate: yup.string().nullable().notRequired().default(props.invoice.lastUpdatedDate),
            rowVersion: yup.string().notRequired().default(props.invoice.rowVersion),
            invoiceNumber: yup.string().notRequired().default(props.invoice.invoiceNumber),
            lateFee: yup
                .number()
                .min(0)
                .notRequired()
                .default(props.invoice.lateFee || 0),
            convenienceFee: yup
                .number()
                .min(0)
                .notRequired()
                .default(props.invoice.convenienceFee || 0),
            dueAmount: yup.number().min(0).notRequired().default(props.invoice.dueAmount),
            shortUrl: yup.string().nullable().notRequired().default(props.invoice.shortUrl),
            programIds: yup.string().nullable().notRequired().default(props.invoice.programIds),
            programTitles: yup.string().nullable().notRequired().default(props.invoice.programTitles),
            professionList: yup
                .string()
                .nullable()
                .notRequired()
                .default(JSON.stringify(props.invoice.professionListDeserialized)),
            isDeleted: yup.boolean().required().default(props.invoice.isDeleted),
            invoiceType: yup.number().required().default(props.invoice.invoiceType),
            stripeInvoiceId: yup.string().notRequired().default(props.invoice.stripeInvoiceId),
            recipientId: yup.number().nullable().notRequired().default(props.invoice.recipientId),
            stripeInvoiceUrl: yup.string().nullable().notRequired().default(props.invoice.stripeInvoiceUrl),
            pdfUrl: yup.string().nullable().notRequired().default(props.invoice.pdfUrl),
            dueDate: yup.string().nullable().notRequired().default(props.invoice.dueDate),
        })
        .defined();

    const { handleSubmit, register, errors, control, watch, reset } = useForm({
        validationSchema: schema,
        defaultValues: {
            ...props.invoice,
            description: props.invoice.id === 0 ? 'CAAHEP Accredited Program Fee' : props.invoice.description,
            invoiceDate: moment(props.invoice.invoiceDate).format('YYYY-MM-DD'),
            lateFee: props.invoice.lateFee || 0,
            tiers: [
                {
                    numProfessions: 1,
                    perProfessionFee: 600,
                },
                {
                    numProfessions: 2,
                    perProfessionFee: 500,
                },
                {
                    numProfessions: 3,
                    perProfessionFee: 350,
                },
                {
                    numProfessions: 4,
                    perProfessionFee: 250,
                },
                {
                    numProfessions: 5,
                    perProfessionFee: 200,
                },
            ],
        },
    });
    const { fields, append } = useFieldArray({
        name: 'tiers',
        control,
    });
    const formValues = watch({ nest: true });

    const onSubmit = (values: InvoicingDto) => {
        const toastId = toast.info('Saving Invoice...');

        invoiceService
            .saveInvoice(values.id, values)
            .then(() => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Invoice saved successfuly',
                });

                if (values.sendAutomatically) {
                    const emailToastId = toast.info('Emailing Invoice...');
                    invoiceService
                        .emailInvoices([values.id])
                        .then(() => {
                            toast.update(emailToastId, {
                                type: 'success',
                                render: 'Invoice emailed successfuly',
                            });
                        })
                        .catch(() =>
                            toast.update(emailToastId, {
                                type: 'error',
                                render: 'Error emailing invoice',
                            }),
                        );
                }

                props.onSave();
            })
            .catch((e) => {
                if (e.error) {
                    toast.update(toastId, {
                        type: 'error',
                        render: e.error,
                    });
                } else {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Error saving Invoice',
                    });
                }
            });
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            {console.log(errors)}
            <FormGroup>
                <Label for="invoiceDate">Invoice Date</Label>
                <Input name="invoiceDate" innerRef={register()} type="date" />
            </FormGroup>
            <FormGroup>
                <Label for="description">Description</Label>
                <Input name="description" innerRef={register()} />
            </FormGroup>
            {!props.invoice.fiscalYear && (
                <FormGroup>
                    <Label for="fiscalYear">Fiscal Year</Label>
                    <Input type="select" innerRef={register()} name="fiscalYear">
                        <option value={''}>Select...</option>
                        {generateYears().map((ct) => (
                            <option key={ct} value={ct}>
                                {ct}
                            </option>
                        ))}
                    </Input>
                </FormGroup>
            )}
            {(!props.invoice.amount || props.invoice.status !== 1) && (
                <FormGroup>
                    <Label for="amount">Accredited Program Fee Structure</Label>
                    <div>
                        <Table>
                            <thead>
                                <tr>
                                    <th>{`# Programs at Institution`}</th>
                                    <th>{`Per Program Fee`}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {fields.map((f, i) => (
                                    <tr key={f.id}>
                                        <td>{f.numProfessions}</td>
                                        <td>
                                            <InputGroup>
                                                <InputGroupText>$</InputGroupText>
                                                <Input name={`tiers.${i}.perProfessionFee`} innerRef={register()} />
                                                <Input
                                                    name={`tiers.${i}.numProfessions`}
                                                    type={`hidden`}
                                                    innerRef={register()}
                                                />
                                            </InputGroup>
                                        </td>
                                        <td>
                                            <Button
                                                color={`danger`}
                                                type={'button'}
                                                onClick={() => {
                                                    const oldTiers = [...formValues.tiers];
                                                    oldTiers.splice(i, 1);
                                                    reset({
                                                        ...formValues,
                                                        tiers: oldTiers.map((t, i) => {
                                                            return {
                                                                numProfessions: i + 1,
                                                                perProfessionFee: t.perProfessionFee,
                                                            };
                                                        }),
                                                    });
                                                }}
                                            >
                                                <i className={`mdi mdi-close`} />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan={3}>
                                        <Button
                                            type={'button'}
                                            color={`primary`}
                                            onClick={() =>
                                                append({
                                                    numProfessions: fields.length + 1,
                                                    perProfessionFee: 0,
                                                })
                                            }
                                        >
                                            <i className={`mdi mdi-plus`} />
                                            <span className={`ml-2`}>{`Add a Fee Tier`}</span>
                                        </Button>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </FormGroup>
            )}
            <FormGroup>
                <Label for="lateFee">Administrative Fee</Label>
                <Input name="lateFee" innerRef={register()} />
            </FormGroup>

            <FormGroup>
                <Label check>
                    Send Now?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Input name="sendAutomatically" type="checkbox" innerRef={register()} />
                </Label>
            </FormGroup>

            <div>
                <Button type="button" color={'link'} onClick={props.onCancel}>
                    Cancel
                </Button>
                <Button type="submit">Save</Button>
            </div>
        </Form>
    );
};

export default InvoiceForm;
