import {
    AmsPermissionClaimType,
    ArmsPermissionClaimType,
    IdentityPermissionClaimType,
    authService,
} from '../services/Auth';
import { Link, NavLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

type Categories = {
    coa: boolean;
    coas: boolean;
    systemAdmin: boolean;
    security: boolean;
    institution: boolean;
    annualReport: boolean;
    recommendations: boolean;
    invoices: boolean;
    reports: boolean;
    hub: boolean;
};

/**
 * Main navigation left nav
 */
const LeftNav = () => {
    const [categories, setCategories] = useState<Categories>({
        coa: false,
        coas: false,
        systemAdmin: false,
        security: false,
        institution: false,
        annualReport: false,
        recommendations: false,
        invoices: false,
        reports: false,
        hub: false,
    });

    useEffect(() => {
        setCategories({
            coa: window.location.pathname.indexOf('admin/profession') > -1,
            coas: window.location.pathname.indexOf('admin/professions') > -1,
            systemAdmin: window.location.pathname.indexOf('system-admin') > -1,
            security: window.location.pathname.indexOf('security') > -1,
            institution: window.location.pathname.indexOf('institution') > -1,
            annualReport: window.location.pathname.indexOf('annual-report') > -1,
            recommendations: window.location.pathname.indexOf('recommendation') > -1,
            invoices:
                window.location.pathname.indexOf('invoice') > -1 || window.location.pathname.indexOf('billing') > -1,
            reports: window.location.pathname.indexOf('reports') > -1,
            hub: window.location.pathname.indexOf('hub') > -1,
        });
    }, [setCategories]);

    return (
        <div className="left-side-menu">
            <Scrollbars style={{ height: '100%' }} autoHide autoHideTimeout={1000} autoHideDuration={250}>
                <div className="slimscroll-menu">
                    <div id="sidebar-menu">
                        <ul className="metismenu" id="side-menu">
                            <li className="menu-title">Navigation</li>
                            {!authService.hasPermission(ArmsPermissionClaimType, 'admin') &&
                                authService.hasPermission(ArmsPermissionClaimType, 'viewannualreport') && (
                                    <li className={categories.institution ? 'mm-active' : ''}>
                                        <Link
                                            to="/annual-report/dashboard"
                                            className={categories.annualReport ? 'active' : ''}
                                        >
                                            <i className="mdi mdi-school" />
                                            <span>Annual Report</span>
                                        </Link>
                                    </li>
                                )}
                            {authService.hasPermission(AmsPermissionClaimType, 'recommendation-create') && (
                                <li className={categories.institution ? 'mm-active' : ''}>
                                    <Link to="/institutions" className={categories.institution ? 'active' : ''}>
                                        <i className="mdi mdi-school" />
                                        <span>Institutions</span>
                                    </Link>
                                </li>
                            )}
                            {authService.hasPermission(ArmsPermissionClaimType, 'admin') && (
                                <li className={categories.coa || categories.annualReport ? 'mm-active' : ''}>
                                    <Link
                                        to="/admin/professions"
                                        className={categories.coas ? 'active' : ''}
                                        aria-expanded="false"
                                    >
                                        <i className="mdi mdi-table-settings" />
                                        <span>Annual Reports</span>
                                    </Link>
                                    <ul className="nav-second-level nav" aria-expanded="false">
                                        <li>
                                            <Link to="/annual-report/overview">Overview</Link>
                                        </li>
                                        <li>
                                            <Link to="/admin/professions">ARMS Configuration</Link>
                                        </li>
                                    </ul>
                                </li>
                            )}
                            {authService.hasPermission(AmsPermissionClaimType, 'recommendation-create') && (
                                <li className={categories.recommendations ? 'mm-active' : ''}>
                                    <Link
                                        to="/recommendations/list"
                                        className={categories.recommendations ? 'active' : ''}
                                        aria-expanded="false"
                                    >
                                        <i className="mdi mdi-certificate" />
                                        <span>{`Recommendations`}</span>
                                    </Link>
                                </li>
                            )}
                            {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') && (
                                <li className={categories.invoices ? 'mm-active' : ''}>
                                    <NavLink
                                        to={`/invoices`}
                                        className={categories.invoices ? 'active' : ''}
                                        aria-expanded={'false'}
                                    >
                                        <i className="mdi mdi-credit-card" />
                                        <span>{`Invoices`}</span>
                                    </NavLink>
                                </li>
                            )}
                            {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') && (
                                <li className={categories.reports ? 'mm-active' : ''}>
                                    <NavLink
                                        to={`/reports/general-information`}
                                        className={categories.reports ? 'active' : ''}
                                        aria-expanded={'false'}
                                    >
                                        <i className="mdi mdi-file-document-edit" />
                                        <span>{`Reports`}</span>
                                    </NavLink>
                                    <ul className="nav-second-level nav" aria-expanded="false">
                                        <li>
                                            <Link to="/reports/accredited-programs-report">Accredited Programs</Link>
                                        </li>
                                        <li>
                                            <Link to="/reports/active-program-count">Active Program Report</Link>
                                        </li>
                                        <li>
                                            <Link to="/reports/annual-report-totals-report">Annual Report Totals</Link>
                                        </li>
                                        <li>
                                            <Link to="/reports/degrees-by-auspice">Degrees by Auspices</Link>
                                        </li>
                                        <li>
                                            <Link to="/reports/institution-contact">Institution Contacts</Link>
                                        </li>
                                        <li>
                                            <Link to="/reports/profession-search-count">Profession Search Count</Link>
                                        </li>
                                        <li>
                                            <Link to="/reports/profit-vs-public">Profit Vs Public Report</Link>
                                        </li>
                                        <li>
                                            <Link to="/reports/inactive-status">Status - Inactive</Link>
                                        </li>
                                        <li>
                                            <Link to="/reports/probation-status">Status - Probation</Link>
                                        </li>
                                        <li>
                                            <Link to="/reports/withdraw-status">Status - Voluntary Withdraw</Link>
                                        </li>
                                    </ul>
                                </li>
                            )}
                            {authService.hasPermission(AmsPermissionClaimType, 'hubviewdocuments') &&
                                !authService.hasPermission(AmsPermissionClaimType, 'hubmanageusers') && (
                                    <li className={categories.hub ? 'mm-active' : ''}>
                                        <NavLink
                                            to={`/hub/folders/main`}
                                            className={categories.hub ? 'active' : ''}
                                            aria-expanded={'false'}
                                        >
                                            <i className="mdi mdi-folder-network" />
                                            <span>{`Hub`}</span>
                                        </NavLink>
                                    </li>
                                )}
                            {authService.hasPermission(AmsPermissionClaimType, 'hubviewdocuments') &&
                                authService.hasPermission(AmsPermissionClaimType, 'hubmanageusers') && (
                                    <li className={categories.hub ? 'mm-active' : ''}>
                                        <Link
                                            to="/hub/folders/main"
                                            className={categories.hub ? 'active' : ''}
                                            aria-expanded="false"
                                        >
                                            <i className="mdi mdi-folder-network"></i>
                                            <span>Hub</span>
                                        </Link>
                                        <ul className="nav-second-level nav" aria-expanded="false">
                                            <li>
                                                <Link to="/hub/users">Hub Users</Link>
                                            </li>
                                        </ul>
                                    </li>
                                )}
                            {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') && (
                                <li className={categories.systemAdmin ? 'mm-active' : ''}>
                                    <Link
                                        to="/system-admin/institution-types"
                                        className={categories.systemAdmin ? 'active' : ''}
                                        aria-expanded="false"
                                    >
                                        <i className="mdi mdi-settings"></i>
                                        <span>System Administration</span>
                                    </Link>
                                    <ul className="nav-second-level nav" aria-expanded="false">
                                        <li>
                                            <Link to="/system-admin/institution-types">Institution Types</Link>
                                        </li>
                                        <li>
                                            <Link to="/system-admin/auspice">Auspice</Link>
                                        </li>
                                        <li>
                                            <Link to="/system-admin/meeting-dates">Meeting Dates</Link>
                                        </li>
                                        <li>
                                            <Link to="/system-admin/professions">Professions</Link>
                                        </li>
                                        <li>
                                            <Link to="/system-admin/coas">CoAs</Link>
                                        </li>
                                        <li>
                                            <Link to="/system-admin/institutional-accreditors">
                                                Institutional Accreditors
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/system-admin/letter-templates">Letter Templates</Link>
                                        </li>
                                    </ul>
                                </li>
                            )}
                            {(authService.hasPermission(IdentityPermissionClaimType, 'manageusers') ||
                                authService.hasPermission(IdentityPermissionClaimType, 'managepermissions')) && (
                                <li className={categories.security ? 'mm-active' : ''}>
                                    <Link
                                        to="/security/users"
                                        className={categories.security ? 'active' : ''}
                                        aria-expanded="false"
                                    >
                                        <i className="mdi mdi-shield-lock"></i>
                                        <span>Security</span>
                                    </Link>
                                    <ul className="nav-second-level nav" aria-expanded="false">
                                        {authService.hasPermission(IdentityPermissionClaimType, 'manageusers') && (
                                            <li>
                                                <Link to="/security/users">Users</Link>
                                            </li>
                                        )}
                                        {authService.hasPermission(
                                            IdentityPermissionClaimType,
                                            'managepermissions',
                                        ) && (
                                            <li>
                                                <Link to="/security/roles-permissions">Roles &amp; Permissions</Link>
                                            </li>
                                        )}
                                    </ul>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </Scrollbars>
        </div>
    );
};

export default LeftNav;
