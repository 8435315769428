import * as moment from 'moment';

import {
    Alert,
    Badge,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import {
    AmsDocument,
    AwardCertificateSend,
    AwardLetterSend,
    CertificateSend,
    DocumentActivity,
    DocumentUpload,
    InstitutionPeopleDto,
    ProgramAccreditationAwardLetterLog,
    ProgramCertificate,
    RelatedDocumentDto,
} from '../../../common/Types';
import { DocumentActivityType, InstitutionPersonRole, ProgramStatus } from '../../../common/Data';
import React, { useEffect, useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';

import { APIUriAms } from '../../../services/HttpHelper';
import { Button } from '../../../components';
import Dropzone from 'react-dropzone';
import Skeleton from 'react-loading-skeleton';
import { AmsPermissionClaimType, authService } from '../../../services/Auth';
import { documentService } from '../../../services/DocumentService';
import { personnelService } from '../../../services/Personnel';
import { programService } from '../../../services/ProgramService';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

const DropZoneContainer = styled.section`
    border: dashed 5px #cccccc;
    > div {
        height: 80px;
    }
`;

interface ProgramAwardLetterProps {
    institutionId: number;
    programId: number;
    programTitle: string;
}

const ProgramAwardLetter = (props: ProgramAwardLetterProps) => {
    const { institutionId, programId, programTitle } = props;

    const [logs, setLogs] = useState<ProgramAccreditationAwardLetterLog[]>();
    const [reloadLogs, setReloadLogs] = useState<boolean>(true);

    const [currentStatus, setCurrentStatus] = useState<string>();

    const [awardLetter, setAwardLetter] = useState<RelatedDocumentDto | null>();
    const [reloadAwardLetter, setReloadAwardLetter] = useState<boolean>(true);
    const [publicAwardLetter, setPublicAwardLetter] = useState<RelatedDocumentDto | null>();
    const [reloadPublicAwardLetter, setReloadPublicAwardLetter] = useState<boolean>(true);
    const [awardLetterEdit, setAwardLetterEdit] = useState<DocumentUpload>();
    const [showAwardUpload, setShowAwardUpload] = useState<boolean>(false);
    const toggleShowAwardModal = () => setShowAwardUpload(!showAwardUpload);

    const [awardLetterMessage, setAwardLetterMessage] = useState<AwardLetterSend>();
    const [showAwardSend, setShowAwardSend] = useState<boolean>(false);
    const toggleShowAwardSendModal = () => setShowAwardSend(!showAwardSend);

    const [certificate, setCertificate] = useState<ProgramCertificate>();

    const [certificateMessage, setCertificateMessage] = useState<CertificateSend>();
    const [showCertificateSend, setShowCertificateSend] = useState<boolean>(false);
    const toggleShowCertificateSendModal = () => setShowCertificateSend(!showCertificateSend);

    const [showFullAwardTracking, setShowFullAwardTracking] = useState<boolean>(false);
    const toggleAwardTracking = () => setShowFullAwardTracking(!showFullAwardTracking);

    const [personnel, setPersonnel] = useState<InstitutionPeopleDto[]>();
    const [newAdditionalRecipient, setNewAdditionalRecipient] = useState<string>();

    const loggedInUser = authService.getProfile();

    const defaultBody = `Effective January 1, 2012, the Commission on Accreditation of Allied Health Education Programs (CAAHEP) is electronically distributing accreditation letters to the President/CEO, Dean/Administrator, and Program Director.
                
Attached is a letter for the ${programTitle} program${currentStatus ? `, regarding ${currentStatus} status` : ''}.
                
If you have any questions or have difficulty with the attachment, please contact ${loggedInUser?.name} at ${
        loggedInUser?.email
    }.
                
Thank you!`;

    useEffect(() => {
        const getStatusHistory = () => {
            if (programId) {
                programService
                    .getProgramStatusHistoryByProgramId(programId)
                    .then((result) => {
                        const activeStatuses = result
                            .filter(
                                (r) =>
                                    r.active === true &&
                                    r.effectiveDate &&
                                    moment.utc(r.effectiveDate).unix() <= moment.utc().unix(),
                            )
                            .sort((a, b) => moment.utc(b.effectiveDate).unix() - moment.utc(a.effectiveDate).unix());

                        if (activeStatuses.length > 0) {
                            setCurrentStatus(ProgramStatus[activeStatuses[0].statusId]);
                        }
                    })
                    .catch(() => {
                        toast.error('Error getting current program status');
                    });
            }
        };

        getStatusHistory();
    }, [setCurrentStatus, programId]);

    useEffect(() => {
        const checkForCertificate = () => {
            if (programId) {
                programService
                    .checkForCertificate(programId)
                    .then((result) => {
                        setCertificate(
                            result || {
                                fileName: '',
                            },
                        );
                    })
                    .catch(() => {
                        toast.error('Error checking for program certificate');
                    });
            }
        };

        checkForCertificate();
    }, [setCertificate, programId]);

    useEffect(() => {
        const loadLogs = () => {
            if (programId && reloadLogs) {
                setReloadLogs(false);

                programService
                    .getAccreditationAwardLetterLogs(programId)
                    .then((results) => {
                        setLogs(results);
                    })
                    .catch(() => {
                        toast.error('Error loading history');
                    });
            }
        };

        loadLogs();
    }, [setLogs, programId, reloadLogs]);

    useEffect(() => {
        const loadAwardLetter = () => {
            if (programId && reloadAwardLetter) {
                setReloadAwardLetter(false);

                programService
                    .getAwardLetter(programId, false)
                    .then((results) => {
                        setAwardLetter(results);
                    })
                    .catch(() => {
                        toast.error('Error loading award letter');
                    });
            }
        };

        loadAwardLetter();
    }, [setAwardLetter, programId, reloadAwardLetter]);

    useEffect(() => {
        const loadAwardLetter = () => {
            if (programId && reloadPublicAwardLetter) {
                setReloadPublicAwardLetter(false);

                programService
                    .getAwardLetter(programId, true)
                    .then((results) => {
                        setPublicAwardLetter(results);
                    })
                    .catch(() => {
                        toast.error('Error loading public award letter');
                    });
            }
        };

        loadAwardLetter();
    }, [setPublicAwardLetter, programId, reloadPublicAwardLetter]);

    useEffect(() => {
        const loadPersonnel = () => {
            if (institutionId && programId) {
                personnelService
                    .getAmsPersonnelByInsitutionId(institutionId)
                    .then((results) => {
                        setPersonnel([
                            ...results.filter(
                                (p) =>
                                    (!p.programId || p.programId === programId) &&
                                    p.active === true &&
                                    p.people?.active === true &&
                                    (p.roleId === InstitutionPersonRole.CEO ||
                                        p.roleId === InstitutionPersonRole.Dean ||
                                        p.roleId === InstitutionPersonRole['Program Director']),
                            ),
                        ]);
                    })
                    .catch(() => {
                        toast.error('Error loading personnel');
                    });
            }
        };

        loadPersonnel();
    }, [setPersonnel, institutionId, programId]);

    const letterUpdateDate = (letter: RelatedDocumentDto | null): string | undefined => {
        if (letter) {
            const dateToUse = letter.lastEdited || letter.dateCreated;

            if (dateToUse) {
                return moment.utc(dateToUse).local().format('MM/DD/YYYY hh:mm A');
            }
        }

        return undefined;
    };

    const startAwardLetterUpdate = (isPublic: boolean, existingLetter: RelatedDocumentDto | null | undefined) => {
        if (existingLetter) {
            setAwardLetterEdit({
                dto: {
                    ...existingLetter,
                    documentActivity: [],
                },
                fileUpload: null,
            });
        } else {
            setAwardLetterEdit({
                dto: {
                    active: true,
                    documentId: 0,
                    institutionId: institutionId,
                    programId: programId,
                    isDeleted: false,
                    isAwardLetter: true,
                    title: '',
                    fileDownloadName: '',
                    fileUrl: '',
                    isDistributed: false,
                    documentActivity: [],
                    isPublic,
                },
                fileUpload: null,
            });
        }

        toggleShowAwardModal();
    };

    const addStagedAwardLetter = (dropData) => {
        if (awardLetterEdit && dropData && dropData[0]) {
            dropData.fileId = uuidv4();

            setAwardLetterEdit({
                fileUpload: dropData[0],
                dto: {
                    ...awardLetterEdit.dto,
                    documentId: 0,
                    isDistributed: false,
                    active: true,
                    fileUrl: dropData[0].path as string,
                    documentActivity: [],
                } as AmsDocument,
            });
        }
    };

    const saveAwardLetter = () => {
        if (awardLetterEdit) {
            const toastId = toast.info('Saving & uploading award letter...');

            documentService
                .saveAndUploadProgramAwardLetter(awardLetterEdit)
                .then((result) => {
                    toast.update(toastId, {
                        render: 'Award Letter uploaded and saved successfully',
                        type: 'success',
                    });

                    setReloadAwardLetter(true);
                    setReloadPublicAwardLetter(true);
                    setReloadLogs(true);
                    toggleShowAwardModal();
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error uploading and saving award letter',
                        type: 'error',
                    });
                });
        }
    };

    const startAwardLetterSend = () => {
        if (awardLetter && awardLetter.documentId) {
            setAwardLetterMessage({
                awardLetterId: awardLetter.documentId,
                additionalRecipients: [],
                message: defaultBody,
                recipients: [],
                subject: '',
                programId: programId,
            });
            toggleShowAwardSendModal();
        } else {
            toast.warning('An award letter must be uploaded before it can be sent.');
        }
    };

    const startCertificateSend = () => {
        if (certificate && certificate.fileName !== '') {
            setCertificateMessage({
                fileName: certificate.fileName,
                additionalRecipients: [],
                message: defaultBody,
                recipients: [],
                subject: '',
                programId: programId,
            });
            toggleShowCertificateSendModal();
        } else {
            toast.warning('An award letter must be uploaded before it can be sent.');
        }
    };

    const addRecipient = (message: AwardCertificateSend, recipient: string) => {
        if (recipient && message) {
            if (!message.additionalRecipients.find((r) => r === recipient)) {
                message.additionalRecipients.push(recipient);
                setNewAdditionalRecipient('');
            }
        }
    };

    const removeRecipient = (message: AwardCertificateSend, setter: any, recipient: string) => {
        if (recipient && message) {
            const index = message.additionalRecipients.indexOf(recipient, 0);
            if (index >= 0) {
                const newRecipients = [...message.additionalRecipients];
                newRecipients.splice(index, 1);

                setter({
                    ...message,
                    additionalRecipients: [...newRecipients],
                });
            }
        }
    };

    const getPersonByRoleId = (roleId: number): InstitutionPeopleDto | null => {
        if (personnel) {
            const person = personnel.find(
                (p) => p.roleId === roleId && (p.programId === programId || p.roleId === InstitutionPersonRole.CEO),
            );

            if (person?.people) {
                return person;
            }
        }

        return null;
    };

    const getPersonTextByRoleId = (roleId: number): string | null => {
        const person = getPersonByRoleId(roleId)?.people;

        if (person) {
            return `${person.firstName} ${person.lastName} (${person.email || 'Email Not Provided'})`;
        }

        return null;
    };

    const isPersonChecked = (message: AwardCertificateSend, roleId: number): boolean => {
        const person = getPersonByRoleId(roleId)?.people;

        if (person?.email && message?.recipients) {
            return message.recipients.find((r) => r === person.email) !== undefined;
        }

        return false;
    };

    const toggleSelectPerson = (message: AwardCertificateSend, setter: any, roleId: number) => {
        const person = getPersonByRoleId(roleId)?.people;

        if (person?.email && message?.recipients) {
            const index = message.recipients.indexOf(person.email);

            if (index < 0) {
                const newRecipients = [...message.recipients, person.email];
                setter({
                    ...message,
                    recipients: [...newRecipients],
                });
            } else {
                const newRecipients = [...message.recipients];
                newRecipients.splice(index, 1);
                setter({
                    ...message,
                    recipients: [...newRecipients],
                });
            }
        }
    };

    const sendAwardLetter = () => {
        if (awardLetterMessage) {
            const toastId = toast.info('Sending award letter...');

            programService
                .sendAwardLetterCertificate(awardLetterMessage)
                .then((result) => {
                    toast.update(toastId, {
                        render: 'Award letter sent',
                        type: 'success',
                    });
                    setReloadLogs(true);
                    setReloadAwardLetter(true);
                    toggleShowAwardSendModal();
                    setAwardLetter({
                        ...(awardLetter as RelatedDocumentDto),
                        isDistributed: true,
                    });
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error sending award letter',
                        type: 'error',
                    });
                });
        }
    };

    const sendCertificate = () => {
        if (certificateMessage) {
            const toastId = toast.info('Sending certificate...');

            programService
                .sendAwardLetterCertificate(certificateMessage)
                .then((result) => {
                    toast.update(toastId, {
                        render: 'Certificate sent',
                        type: 'success',
                    });
                    setReloadLogs(true);
                    toggleShowCertificateSendModal();
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error sending certificate',
                        type: 'error',
                    });
                });
        }
    };

    const generateCertificate = () => {
        const toastId = toast.info('Generating certificate...', {
            autoClose: false,
        });

        if (programId) {
            programService
                .generateCertificate(programId)
                .then((result) => {
                    toast.update(toastId, {
                        render: 'Certificate generated',
                        type: 'success',
                        autoClose: 5000,
                    });
                    setCertificate(result);
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error generating certificate',
                        type: 'error',
                        autoClose: 5000,
                    });
                });
        }
    };

    const awardLetterFirstSent: DocumentActivity | undefined = awardLetter?.documentActivity
        ?.filter((da) => da.activityTypeId === DocumentActivityType.Sent && da.timeStamp)
        ?.sort((a, b) => moment.utc(a.timeStamp).unix() - moment.utc(b.timeStamp).unix())
        ?.find((da) => da.id > 0);

    const awardLetterFirstReceived: DocumentActivity | undefined = awardLetter?.documentActivity
        ?.filter((da) => da.activityTypeId === DocumentActivityType['Email Received'] && da.timeStamp)
        ?.sort((a, b) => moment.utc(a.timeStamp).unix() - moment.utc(b.timeStamp).unix())
        ?.find((da) => da.id > 0);

    const awardLetterFirstOpened: DocumentActivity | undefined = awardLetter?.documentActivity
        ?.filter((da) => da.activityTypeId === DocumentActivityType['Email Opened'] && da.timeStamp)
        ?.sort((a, b) => moment.utc(a.timeStamp).unix() - moment.utc(b.timeStamp).unix())
        ?.find((da) => da.id > 0);

    const awardLetterLastUndelieverable: DocumentActivity | undefined = awardLetter?.documentActivity
        ?.filter(
            (da) =>
                (da.activityTypeId === DocumentActivityType['Email Blocked'] ||
                    da.activityTypeId === DocumentActivityType['Email Bounced'] ||
                    da.activityTypeId === DocumentActivityType['Email Dropped']) &&
                da.timeStamp,
        )
        ?.sort((a, b) => moment.utc(b.timeStamp).unix() - moment.utc(a.timeStamp).unix())
        ?.find((da) => da.id > 0);

    const getTimelineIconClass = (activity: DocumentActivity) => {
        switch (activity.activityTypeId || -1) {
            case DocumentActivityType['Email Blocked']:
            case DocumentActivityType['Email Bounced']:
            case DocumentActivityType['Email Dropped']:
                return 'var(--danger)';
            case DocumentActivityType['Email Opened']:
            case DocumentActivityType['Email Received']:
            case DocumentActivityType['Link Clicked']:
            case DocumentActivityType.Previewed:
            case DocumentActivityType.Sent:
                return 'var(--success)';
            default:
                return 'var(--success)';
        }
    };

    const getTimelineIcon = (activity: DocumentActivity) => {
        switch (activity.activityTypeId || -1) {
            case DocumentActivityType['Email Blocked']:
            case DocumentActivityType['Email Bounced']:
            case DocumentActivityType['Email Dropped']:
                return <i className={'mdi mdi-email-alert'} />;
            case DocumentActivityType['Email Opened']:
                return <i className={'mdi mdi-email-open'} />;
            case DocumentActivityType['Email Received']:
                return <i className={'mdi mdi-email-check'} />;
            case DocumentActivityType['Link Clicked']:
            case DocumentActivityType.Previewed:
            case DocumentActivityType.Sent:
                return <i className={'mdi mdi-email'} />;
            default:
                return <i className={'mdi mdi-email'} />;
        }
    };

    return (
        <>
            <Card className={'flex-grow-1 d-flex'}>
                <CardBody>
                    <h4 className={'card-title'}>{`Certificate / Award Letter`}</h4>

                    <Row>
                        <Col xs={12} md={5}>
                            {certificate && (
                                <Card>
                                    <CardBody>
                                        <h5 className={'card-title'}>{`Certificate`}</h5>
                                        <div className={'d-flex flex-column'}>
                                            {certificate.fileName !== '' && <Label>{certificate.fileName}</Label>}
                                            {certificate.fileName === '' && (
                                                <Label>{'Certificate not yet generated'}</Label>
                                            )}
                                            {certificate.dateCreated && (
                                                <span
                                                    className={'text-muted font-italic'}
                                                >{`Last Generated: ${moment
                                                    .utc(certificate.dateCreated)
                                                    .local()
                                                    .format('MM/DD/YYYY hh:mm A')}`}</span>
                                            )}
                                        </div>
                                    </CardBody>
                                    <CardFooter className={'p-0 m-0 d-flex justify-content-stretch bg-white'}>
                                        <ButtonGroup className={'flex-grow-1'}>
                                            {certificate.fileName !== '' && (
                                                <a
                                                    className={'btn btn-icon btn-white'}
                                                    target={'_blank'}
                                                    rel="noopener noreferrer"
                                                    href={`${APIUriAms}/program/${programId}/certificate/${certificate.fileName}`}
                                                >
                                                    <i className={`mdi mdi-download`} />
                                                    <span className={'ml-1'}>{'Download'}</span>
                                                </a>
                                            )}
                                            {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') && (
                                                <Button
                                                    type={'button'}
                                                    onClick={() => generateCertificate()}
                                                    color={'white'}
                                                >
                                                    <i className={'mdi mdi-autorenew'} />
                                                    <span className={'ml-1'}>{`${
                                                        certificate.fileName !== '' ? 'Re-generate' : 'Generate'
                                                    }`}</span>
                                                </Button>
                                            )}
                                            {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') &&
                                                certificate.fileName !== '' && (
                                                    <Button
                                                        type={'button'}
                                                        onClick={() => startCertificateSend()}
                                                        color={'white'}
                                                    >
                                                        <i className={'mdi mdi-send'} />
                                                        <span className={'ml-1'}>{'Send'}</span>
                                                    </Button>
                                                )}
                                        </ButtonGroup>
                                    </CardFooter>
                                </Card>
                            )}
                            {!certificate && <Skeleton count={5} />}
                            <Card>
                                <CardBody>
                                    <h5 className={'card-title'}>{`Award Letter`}</h5>
                                    {awardLetter && (
                                        <>
                                            <div className={'d-flex flex-column'}>
                                                {awardLetter.title && <Label>{awardLetter.title}</Label>}
                                                {awardLetter.fileDownloadName &&
                                                    awardLetter.fileDownloadName !== awardLetter.title && (
                                                        <Label>{`(${awardLetter.fileDownloadName})`}</Label>
                                                    )}
                                                {letterUpdateDate(awardLetter) && (
                                                    <span
                                                        className={'text-muted font-italic'}
                                                    >{`Last Updated: ${letterUpdateDate(awardLetter)}`}</span>
                                                )}
                                                {awardLetter.isDistributed === false && (
                                                    <Alert color={'warning'} className={'mt-3 text-center'}>
                                                        {`This award letter has not been distributed`}
                                                    </Alert>
                                                )}
                                                <div className={'mt-3'}>
                                                    {awardLetterFirstSent && (
                                                        <Badge color={'success'} className={'p-1 mr-2'}>{`sent`}</Badge>
                                                    )}
                                                    {awardLetterLastUndelieverable &&
                                                        !awardLetterFirstOpened &&
                                                        !awardLetterFirstReceived && (
                                                            <Badge
                                                                color={'danger'}
                                                                className={'p-1 mr-2'}
                                                            >{`undeliverable`}</Badge>
                                                        )}
                                                    {awardLetterFirstReceived && (
                                                        <Badge
                                                            color={'success'}
                                                            className={'p-1 mr-2'}
                                                        >{`received`}</Badge>
                                                    )}
                                                    {awardLetterFirstOpened && (
                                                        <Badge
                                                            color={'success'}
                                                            className={'p-1 mr-2'}
                                                        >{`opened`}</Badge>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {awardLetter === null && <Label>{`No award letter uploaded`}</Label>}
                                    {awardLetter === undefined && <Skeleton count={2} />}
                                </CardBody>
                                <CardFooter className={'p-0 m-0 d-flex justify-content-stretch bg-white'}>
                                    <ButtonGroup className={'flex-grow-1'}>
                                        {awardLetter && (
                                            <a
                                                className={'btn btn-icon btn-white'}
                                                target={'_blank'}
                                                rel="noopener noreferrer"
                                                href={`${APIUriAms}/document/download/${awardLetter.fileUrl}`}
                                            >
                                                <i className={`mdi mdi-download`} />
                                                <span className={'ml-1'}>{'Download'}</span>
                                            </a>
                                        )}
                                        {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') && (
                                            <Button
                                                type={'button'}
                                                onClick={() => startAwardLetterUpdate(false, awardLetter)}
                                                color={'white'}
                                            >
                                                <i className={'mdi mdi-upload'} />
                                                <span className={'ml-1'}>{`${
                                                    awardLetter == null ? 'Upload' : 'Replace'
                                                }`}</span>
                                            </Button>
                                        )}
                                        {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') &&
                                            awardLetter && (
                                                <Button
                                                    type={'button'}
                                                    onClick={() => startAwardLetterSend()}
                                                    color={'white'}
                                                >
                                                    <i className={'mdi mdi-send'} />
                                                    <span className={'ml-1'}>{'Send'}</span>
                                                </Button>
                                            )}
                                        {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') &&
                                            (awardLetter?.documentActivity?.length || 0) > 0 && (
                                                <Button
                                                    type={'button'}
                                                    onClick={() => toggleAwardTracking()}
                                                    color={'white'}
                                                >
                                                    <i className={'mdi mdi-history'} />
                                                    <span className={'ml-1'}>{'Tracking History'}</span>
                                                </Button>
                                            )}
                                    </ButtonGroup>
                                </CardFooter>
                            </Card>
                            <Card>
                                <CardBody>
                                    <h5 className={'card-title'}>{`Public Award Letter`}</h5>
                                    {publicAwardLetter && (
                                        <>
                                            <div className={'d-flex flex-column'}>
                                                {publicAwardLetter.title && <Label>{publicAwardLetter.title}</Label>}
                                                {publicAwardLetter.fileDownloadName &&
                                                    publicAwardLetter.fileDownloadName !== publicAwardLetter.title && (
                                                        <Label>{`(${publicAwardLetter.fileDownloadName})`}</Label>
                                                    )}
                                                {letterUpdateDate(publicAwardLetter) && (
                                                    <span
                                                        className={'text-muted font-italic'}
                                                    >{`Last Updated: ${letterUpdateDate(publicAwardLetter)}`}</span>
                                                )}
                                            </div>
                                        </>
                                    )}
                                    {publicAwardLetter === null && <Label>{`No public award letter uploaded`}</Label>}
                                    {publicAwardLetter === undefined && <Skeleton count={2} />}
                                </CardBody>
                                <CardFooter className={'p-0 m-0 d-flex justify-content-stretch bg-white'}>
                                    <ButtonGroup className={'flex-grow-1'}>
                                        {publicAwardLetter && (
                                            <a
                                                className={'btn btn-icon btn-white'}
                                                target={'_blank'}
                                                rel="noopener noreferrer"
                                                href={`${APIUriAms}/document/download/${publicAwardLetter.fileUrl}`}
                                            >
                                                <i className={`mdi mdi-download`} />
                                                <span className={'ml-1'}>{'Download'}</span>
                                            </a>
                                        )}
                                        {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') && (
                                            <Button
                                                type={'button'}
                                                onClick={() => startAwardLetterUpdate(true, publicAwardLetter)}
                                                color={'white'}
                                            >
                                                <i className={'mdi mdi-upload'} />
                                                <span className={'ml-1'}>{`${
                                                    publicAwardLetter == null ? 'Upload' : 'Replace'
                                                }`}</span>
                                            </Button>
                                        )}
                                    </ButtonGroup>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col xs={12} md={7}>
                            <Card>
                                <CardBody>
                                    <h5 className={'card-title'}>{`History`}</h5>

                                    {logs && (
                                        <div className={'table-responsive'} style={{ maxHeight: '532px' }}>
                                            <table className={'table table-striped'}>
                                                <thead>
                                                    <tr>
                                                        <th>Description</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {logs.length === 0 && (
                                                        <tr>
                                                            <td colSpan={2}>{'No history to display'}</td>
                                                        </tr>
                                                    )}
                                                    {logs.length > 0 &&
                                                        logs.map((l) => (
                                                            <tr key={l.logID}>
                                                                <td>{l.description}</td>
                                                                <td>
                                                                    {moment
                                                                        .utc(l.dateCreated)
                                                                        .local()
                                                                        .format('MM/DD/YYYY hh:mm A')}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                    {!logs && <Skeleton count={10} />}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            {awardLetterEdit && awardLetterEdit.dto && (
                <Modal isOpen={showAwardUpload} toggle={toggleShowAwardModal} className={`modal-lg`} centered={true}>
                    <ModalHeader>{`${awardLetterEdit.dto.documentId === 0 ? 'New' : 'Replace'} ${
                        awardLetterEdit.dto.isPublic === true ? 'Public' : ''
                    } Award Letter`}</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label>{`Award Letter`}</Label>
                            <Input
                                type={'text'}
                                value={awardLetterEdit.dto?.title || ''}
                                onChange={(e) =>
                                    setAwardLetterEdit({
                                        ...awardLetterEdit,
                                        dto: { ...awardLetterEdit.dto, title: e.target.value } as AmsDocument,
                                    })
                                }
                            />
                        </FormGroup>
                        <FormGroup className={'d-flex flex-column'}>
                            <Label>{`File Upload`}</Label>
                            {awardLetterEdit?.dto?.fileDownloadName && (
                                <span className={'mb-2'}>{awardLetterEdit.dto.fileDownloadName}</span>
                            )}
                            <Dropzone onDrop={(acceptedFiles) => addStagedAwardLetter(acceptedFiles)} multiple={false}>
                                {({ getRootProps, getInputProps }) => (
                                    <DropZoneContainer>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <p style={{ textAlign: 'center', lineHeight: '80px' }}>
                                                {`Drag & drop a file here, or click to select a file`}
                                            </p>
                                        </div>
                                    </DropZoneContainer>
                                )}
                            </Dropzone>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color={'primary'}
                            className={'mr-2'}
                            type={'button'}
                            onClick={() => saveAwardLetter()}
                        >{`Upload & Save`}</Button>
                        <Button
                            color={'link'}
                            onClick={() => toggleShowAwardModal()}
                            type={'button'}
                        >{`Cancel`}</Button>
                    </ModalFooter>
                </Modal>
            )}

            {awardLetter && awardLetterMessage && (
                <Modal isOpen={showAwardSend} toggle={toggleShowAwardSendModal} className={`modal-lg`} centered={true}>
                    <ModalHeader>{`Send Award Letter`}</ModalHeader>
                    <ModalBody>
                        <FormGroup className={'d-flex flex-column'}>
                            <Label>{`Award Letter`}</Label>
                            <span>{`${awardLetter.title} (${awardLetter.fileDownloadName})`}</span>
                        </FormGroup>
                        <FormGroup className={'d-flex flex-column'}>
                            <Label>{`Recipients`}</Label>
                            <div className={'d-flex flex-column'}>
                                <div className={'custom-control custom-checkbox d-flex align-items-center my-1'}>
                                    <input
                                        type="checkbox"
                                        id={'ceo'}
                                        className={'custom-control-input'}
                                        onChange={() =>
                                            toggleSelectPerson(
                                                awardLetterMessage,
                                                setAwardLetterMessage,
                                                InstitutionPersonRole.CEO,
                                            )
                                        }
                                        checked={isPersonChecked(awardLetterMessage, InstitutionPersonRole.CEO)}
                                        disabled={!getPersonByRoleId(InstitutionPersonRole.CEO)?.people?.email}
                                    />
                                    <Label for={'ceo'} className={'custom-control-label'}>
                                        CEO{' '}
                                        <span className={'font-weight-normal'}>{`${
                                            getPersonTextByRoleId(InstitutionPersonRole.CEO) || ''
                                        }`}</span>
                                    </Label>
                                </div>
                                <div className={'custom-control custom-checkbox d-flex align-items-center my-1'}>
                                    <input
                                        type="checkbox"
                                        id={'dean'}
                                        className={'custom-control-input'}
                                        onChange={() =>
                                            toggleSelectPerson(
                                                awardLetterMessage,
                                                setAwardLetterMessage,
                                                InstitutionPersonRole.Dean,
                                            )
                                        }
                                        checked={isPersonChecked(awardLetterMessage, InstitutionPersonRole.Dean)}
                                        disabled={!getPersonByRoleId(InstitutionPersonRole.Dean)?.people?.email}
                                    />
                                    <Label for={'dean'} className={'custom-control-label'}>
                                        Dean{' '}
                                        <span className={'font-weight-normal'}>{`${
                                            getPersonTextByRoleId(InstitutionPersonRole.Dean) || ''
                                        }`}</span>
                                    </Label>
                                </div>
                                <div className={'custom-control custom-checkbox d-flex align-items-center my-1'}>
                                    <input
                                        type="checkbox"
                                        id={'pd'}
                                        className={'custom-control-input'}
                                        onChange={() =>
                                            toggleSelectPerson(
                                                awardLetterMessage,
                                                setAwardLetterMessage,
                                                InstitutionPersonRole['Program Director'],
                                            )
                                        }
                                        checked={isPersonChecked(
                                            awardLetterMessage,
                                            InstitutionPersonRole['Program Director'],
                                        )}
                                        disabled={
                                            !getPersonByRoleId(InstitutionPersonRole['Program Director'])?.people?.email
                                        }
                                    />
                                    <Label for={'pd'} className={'custom-control-label'}>
                                        PD{' '}
                                        <span className={'font-weight-normal'}>{`${getPersonTextByRoleId(
                                            InstitutionPersonRole['Program Director'],
                                        )}`}</span>
                                    </Label>
                                </div>
                            </div>
                        </FormGroup>
                        <FormGroup className={'d-flex flex-column'}>
                            <Label>{`Additional Recipients`}</Label>
                            {awardLetterMessage.additionalRecipients.map((r) => (
                                <div key={r} className={'d-flex align-items-center my-1 ml-2'}>
                                    <span key={r} className={'mr-1'}>
                                        {r}
                                    </span>
                                    <Button
                                        type={'button'}
                                        color={'white'}
                                        className={'btn-icon btn-sm'}
                                        onClick={() => removeRecipient(awardLetterMessage, setAwardLetterMessage, r)}
                                    >
                                        <i className={'mdi mdi-delete'} />
                                    </Button>
                                </div>
                            ))}
                            <InputGroup className={'mt-2'}>
                                <Input
                                    type={'email'}
                                    value={newAdditionalRecipient}
                                    onChange={(e) => setNewAdditionalRecipient(e.target.value)}
                                />
                                <InputGroupAddon addonType={'append'}>
                                    <Button
                                        color={'info'}
                                        onClick={() => addRecipient(awardLetterMessage, newAdditionalRecipient || '')}
                                    >
                                        {`Add Recipient`}
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className={'d-flex flex-column'}>
                            <Label>{`Subject`}</Label>
                            <Input
                                type={'text'}
                                value={awardLetterMessage.subject}
                                onChange={(e) =>
                                    setAwardLetterMessage({ ...awardLetterMessage, subject: e.target.value })
                                }
                            />
                        </FormGroup>
                        <FormGroup className={'d-flex flex-column'}>
                            <Label>{`Message`}</Label>
                            <Input
                                type={'textarea'}
                                rows={10}
                                value={awardLetterMessage.message}
                                onChange={(e) =>
                                    setAwardLetterMessage({ ...awardLetterMessage, message: e.target.value })
                                }
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color={'primary'}
                            className={'mr-2'}
                            type={'button'}
                            onClick={() => sendAwardLetter()}
                            disabled={
                                awardLetterMessage.recipients.length === 0 &&
                                awardLetterMessage.additionalRecipients.length === 0
                            }
                        >{`Send Award Letter`}</Button>
                        <Button
                            color={'link'}
                            onClick={() => toggleShowAwardSendModal()}
                            type={'button'}
                        >{`Cancel`}</Button>
                    </ModalFooter>
                </Modal>
            )}

            {certificate && certificate.fileName !== '' && certificateMessage && (
                <Modal
                    isOpen={showCertificateSend}
                    toggle={toggleShowCertificateSendModal}
                    className={`modal-lg`}
                    centered={true}
                >
                    <ModalHeader>{`Send Certificate`}</ModalHeader>
                    <ModalBody>
                        <FormGroup className={'d-flex flex-column'}>
                            <Label>{`Certificate`}</Label>
                            <span>{certificate.fileName}</span>
                        </FormGroup>
                        <FormGroup className={'d-flex flex-column'}>
                            <Label>{`Recipients`}</Label>
                            <div className={'d-flex flex-column'}>
                                <div className={'custom-control custom-checkbox d-flex align-items-center my-1'}>
                                    <input
                                        type="checkbox"
                                        id={'ceo'}
                                        className={'custom-control-input'}
                                        onChange={() =>
                                            toggleSelectPerson(
                                                certificateMessage,
                                                setCertificateMessage,
                                                InstitutionPersonRole.CEO,
                                            )
                                        }
                                        checked={isPersonChecked(certificateMessage, InstitutionPersonRole.CEO)}
                                        disabled={!getPersonByRoleId(InstitutionPersonRole.CEO)?.people?.email}
                                    />
                                    <Label for={'ceo'} className={'custom-control-label'}>
                                        CEO{' '}
                                        <span className={'font-weight-normal'}>{`${
                                            getPersonTextByRoleId(InstitutionPersonRole.CEO) || ''
                                        }`}</span>
                                    </Label>
                                </div>
                                <div className={'custom-control custom-checkbox d-flex align-items-center my-1'}>
                                    <input
                                        type="checkbox"
                                        id={'dean'}
                                        className={'custom-control-input'}
                                        onChange={() =>
                                            toggleSelectPerson(
                                                certificateMessage,
                                                setCertificateMessage,
                                                InstitutionPersonRole.Dean,
                                            )
                                        }
                                        checked={isPersonChecked(certificateMessage, InstitutionPersonRole.Dean)}
                                        disabled={!getPersonByRoleId(InstitutionPersonRole.Dean)?.people?.email}
                                    />
                                    <Label for={'dean'} className={'custom-control-label'}>
                                        Dean{' '}
                                        <span className={'font-weight-normal'}>{`${
                                            getPersonTextByRoleId(InstitutionPersonRole.Dean) || ''
                                        }`}</span>
                                    </Label>
                                </div>
                                <div className={'custom-control custom-checkbox d-flex align-items-center my-1'}>
                                    <input
                                        type="checkbox"
                                        id={'pd'}
                                        className={'custom-control-input'}
                                        onChange={() =>
                                            toggleSelectPerson(
                                                certificateMessage,
                                                setCertificateMessage,
                                                InstitutionPersonRole['Program Director'],
                                            )
                                        }
                                        checked={isPersonChecked(
                                            certificateMessage,
                                            InstitutionPersonRole['Program Director'],
                                        )}
                                        disabled={
                                            !getPersonByRoleId(InstitutionPersonRole['Program Director'])?.people?.email
                                        }
                                    />
                                    <Label for={'pd'} className={'custom-control-label'}>
                                        PD{' '}
                                        <span className={'font-weight-normal'}>{`${getPersonTextByRoleId(
                                            InstitutionPersonRole['Program Director'],
                                        )}`}</span>
                                    </Label>
                                </div>
                            </div>
                        </FormGroup>
                        <FormGroup className={'d-flex flex-column'}>
                            <Label>{`Additional Recipients`}</Label>
                            {certificateMessage.additionalRecipients.map((r) => (
                                <div key={r} className={'d-flex align-items-center my-1 ml-2'}>
                                    <span key={r} className={'mr-1'}>
                                        {r}
                                    </span>
                                    <Button
                                        type={'button'}
                                        color={'white'}
                                        className={'btn-icon btn-sm'}
                                        onClick={() => removeRecipient(certificateMessage, setCertificateMessage, r)}
                                    >
                                        <i className={'mdi mdi-delete'} />
                                    </Button>
                                </div>
                            ))}
                            <InputGroup className={'mt-2'}>
                                <Input
                                    type={'email'}
                                    value={newAdditionalRecipient}
                                    onChange={(e) => setNewAdditionalRecipient(e.target.value)}
                                />
                                <InputGroupAddon addonType={'append'}>
                                    <Button
                                        color={'info'}
                                        onClick={() => addRecipient(certificateMessage, newAdditionalRecipient || '')}
                                    >
                                        {`Add Recipient`}
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className={'d-flex flex-column'}>
                            <Label>{`Subject`}</Label>
                            <Input
                                type={'text'}
                                value={certificateMessage.subject}
                                onChange={(e) =>
                                    setCertificateMessage({ ...certificateMessage, subject: e.target.value })
                                }
                            />
                        </FormGroup>
                        <FormGroup className={'d-flex flex-column'}>
                            <Label>{`Message`}</Label>
                            <Input
                                type={'textarea'}
                                rows={10}
                                value={certificateMessage.message}
                                onChange={(e) =>
                                    setCertificateMessage({ ...certificateMessage, message: e.target.value })
                                }
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color={'primary'}
                            className={'mr-2'}
                            type={'button'}
                            onClick={() => sendCertificate()}
                            disabled={
                                certificateMessage.recipients.length === 0 &&
                                certificateMessage.additionalRecipients.length === 0
                            }
                        >{`Send Award Letter`}</Button>
                        <Button
                            color={'link'}
                            onClick={() => toggleShowCertificateSendModal()}
                            type={'button'}
                        >{`Cancel`}</Button>
                    </ModalFooter>
                </Modal>
            )}

            {(awardLetter?.documentActivity?.length || 0 > 0) && (
                <Modal isOpen={showFullAwardTracking} toggle={toggleAwardTracking} className={`modal-lg`}>
                    <Card className={'mb-0'}>
                        <CardBody>
                            <h4>{`Award Tracking`}</h4>
                            <VerticalTimeline className={'timeline-vertical-line-color'} animate={true}>
                                {awardLetter?.documentActivity
                                    ?.filter((a) => a.timeStamp && a.activityTypeId)
                                    ?.map((a) => (
                                        <VerticalTimelineElement
                                            key={a.id}
                                            contentStyle={{ background: 'var(--light)' }}
                                            contentArrowStyle={{ borderRight: '7px solid var(--primary)' }}
                                            date={``}
                                            iconStyle={{ background: `${getTimelineIconClass(a)}`, color: '#fff' }}
                                            icon={getTimelineIcon(a)}
                                        >
                                            <div className={'d-flex flex-column'}>
                                                <h4 className={'vertical-timeline-element-title'}>{`${
                                                    DocumentActivityType[a.activityTypeId || -1]
                                                }`}</h4>
                                                <h5 className={'vertical-timeline-element-subtitle'}>{`${moment
                                                    .utc(a.timeStamp)
                                                    .local()
                                                    .format('MM/DD/YYYY h:mm A')}`}</h5>
                                                <h5 className={'vertical-timeline-element-subtitle'}>{`${
                                                    a.userEmail || ''
                                                }`}</h5>
                                            </div>
                                        </VerticalTimelineElement>
                                    ))}
                            </VerticalTimeline>
                        </CardBody>
                        <CardFooter>
                            <Button
                                color={'link'}
                                onClick={() => toggleAwardTracking()}
                                type={'button'}
                            >{`Close`}</Button>
                        </CardFooter>
                    </Card>
                </Modal>
            )}
        </>
    );
};

export default ProgramAwardLetter;
