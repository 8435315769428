import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Folder } from '../common/Types';
import { hubService } from '../services/HubService';
import HubNavItem from './HubNavItem';

interface HubNavigationProps {
    structureLastChanged: Date;
}

const NavTree = styled.ul`
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    min-width: 200px;

    & li {
        list-style: none;
    }
`;

const HubNavigation = (props: HubNavigationProps) => {
    const { structureLastChanged } = props;
    const [folders, setFolders] = useState<Folder[]>();

    useEffect(() => {
        hubService.getAllFolders().then((allFolders) => {
            setFolders(allFolders);
        });
    }, [structureLastChanged, setFolders]);

    const mainFolder = folders?.find((f) => !!!f.parentFolderId);

    return (
        <NavTree>
            <li>
                <label
                    style={{
                        fontWeight: 700,
                        margin: '20px 0px',
                        fontSize: '16px',
                    }}
                >{`Hub`}</label>
            </li>
            <li>{!!folders && !!mainFolder && <HubNavItem folder={mainFolder} allFolders={folders} />}</li>
        </NavTree>
    );
};

export default HubNavigation;
