import { AmsPermissionClaimType, authService } from '../../../services/Auth';
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import { Program, ProgramStatusHistory } from '../../../common/Types';
import { ProgramStatus, ProgramStatusActiveOnly } from '../../../common/Data';
import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { programService } from '../../../services/ProgramService';
import { toast } from 'react-toastify';

interface StatusHistoryProps {
    onStatusChanged: () => void;
    program: Program;
}

const StatusHistory = (props: StatusHistoryProps) => {
    const { onStatusChanged, program } = props;
    const [history, setHistory] = useState<ProgramStatusHistory[]>();
    const [reload, setReload] = useState<boolean>(true);
    const [currentStatus, setCurrentStatus] = useState<ProgramStatusHistory>();
    const [showModal, setModal] = useState<boolean>(false);
    const [showModalNew, setModalNew] = useState<boolean>(false);
    const [newStatus, setNewStatus] = useState<ProgramStatusHistory>();

    const toggleModal = () => setModal(!showModal);
    const toggleModalNew = () => setModalNew(!showModalNew);

    const [edit, setEdit] = useState<ProgramStatusHistory>();
    const [remove, setRemove] = useState<ProgramStatusHistory>();
    const [showEdit, setShowEdit] = useState<boolean>(false);
    const [showRemove, setShowRemove] = useState<boolean>(false);
    const toggleEdit = () => setShowEdit(!showEdit);
    const toggleRemove = () => setShowRemove(!showRemove);

    useEffect(() => {
        if (reload) {
            programService
                .getProgramStatusHistoryByProgramId(program.programId)
                .then((response) => {
                    setHistory(response);
                })
                .catch(() => {
                    toast.error('Error loading status history.');
                });
        }
    }, [setHistory, setCurrentStatus, props, reload]);

    const getTimeSafe = (date?: Date | null): number => {
        return date != null ? moment(date).unix() : 0;
    };

    useEffect(() => {
        const findCurrentStatus = (): ProgramStatusHistory | undefined => {
            return history
                ?.sort((a, b) => getTimeSafe(b.effectiveDate) - getTimeSafe(a.effectiveDate))
                .find((h) => h.effectiveDate && new Date(h.effectiveDate) <= new Date());
        };

        setCurrentStatus(findCurrentStatus());
    }, [history, setCurrentStatus]);

    const getStatusTextFromId = (statusId: number): any => {
        return Object.keys(ProgramStatus).filter((x) => ProgramStatus[x] === statusId);
    };

    const resetNewStatusAndShow = () => {
        setNewStatus({
            programId: program.programId,
            historyId: 0,
            effectiveDate: new Date(moment.utc().format('YYYY-MM-DD')),
            statusId: 0,
            lastClassToBeAccredited: undefined,
            active: true,
        });
        toggleModalNew();
    };

    const saveNewStatus = () => {
        if (newStatus) {
            const toastId = toast.info('Updating program status...');

            programService
                .createProgramStatusHistory(newStatus)
                .then(() => {
                    toast.update(toastId, {
                        render: 'Program status updated successfully',
                        type: 'success',
                    });
                    setReload(true);
                    toggleModalNew();
                    onStatusChanged();
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error updating program status',
                        type: 'error',
                    });
                });
        }
    };

    const updateStatus = () => {
        if (edit) {
            const toastId = toast.info('Updating program status record...');

            programService
                .updateProgramStatusHistory(edit)
                .then(() => {
                    toast.update(toastId, {
                        render: 'Program status record updated successfully',
                        type: 'success',
                    });
                    setReload(reload);
                    toggleEdit();
                    onStatusChanged();
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error updating program status',
                        type: 'error',
                    });
                });
        }
    };

    const removeStatus = () => {
        if (remove) {
            const toastId = toast.info('Removing program status record...');

            programService
                .updateProgramStatusHistory({
                    ...remove,
                    active: false,
                })
                .then(() => {
                    toast.update(toastId, {
                        render: 'Program status record removed successfully',
                        type: 'success',
                    });
                    setReload(reload);
                    toggleRemove();
                    onStatusChanged();
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error removikng program status',
                        type: 'error',
                    });
                });
        }
    };

    return (
        <>
            <Card className={'bg-secondary flex-grow-1'}>
                <CardBody>
                    <h4 className={'card-title text-white'}>Current Program Status</h4>
                    <div className={'d-flex flex-row justify-content-around text-white'}>
                        {currentStatus && (
                            <div className={'d-flex flex-column text-center m-1'}>
                                <h4>{getStatusTextFromId(currentStatus.statusId)}</h4>
                                {currentStatus.effectiveDate && (
                                    <>
                                        <span style={{ fontStyle: 'italic' }}>as of:</span>
                                        <span style={{ fontStyle: 'italic' }}>
                                            {moment(currentStatus.effectiveDate).format('MM/DD/YYYY')}
                                        </span>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </CardBody>
                <CardFooter className={`bg-secondary border-0 p-0 d-flex`}>
                    {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') && (
                        <Button
                            type={'button'}
                            className={`btn btn-secondary p-2 flex-grow-1`}
                            onClick={() => resetNewStatusAndShow()}
                        >
                            <i className={'mdi mdi-pencil'} />
                            <span className={'ml-1'}>Add Status</span>
                        </Button>
                    )}
                    <Button
                        type={'button'}
                        className={'btn btn-secondary p-2 flex-grow-1'}
                        onClick={() => toggleModal()}
                    >
                        <i className={'mdi mdi-history'} />
                        <span className={'ml-1'}>View Full Status History</span>
                    </Button>
                </CardFooter>
            </Card>

            <Modal isOpen={showModal} toggle={toggleModal} centered>
                <ModalHeader>Program Status History</ModalHeader>
                <ModalBody className={'p-0'}>
                    <div className={'table-responsive'}>
                        <table className={'table table-striped'}>
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Effective Date</th>
                                    <th>Last Class to be Accredited</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {history &&
                                    history.map((status) => (
                                        <tr key={status.historyId}>
                                            <td>{getStatusTextFromId(status.statusId)}</td>
                                            <td>
                                                {status.effectiveDate &&
                                                    moment.utc(status.effectiveDate).format('MM/DD/YYYY')}
                                            </td>
                                            <td>
                                                {status.lastClassToBeAccredited &&
                                                    moment.utc(status.lastClassToBeAccredited).format('MM/DD/YYYY')}
                                            </td>
                                            <td>
                                                {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') && (
                                                    <ButtonGroup>
                                                        <Button
                                                            type={'button'}
                                                            color={`warning`}
                                                            onClick={() => {
                                                                setEdit({ ...status });
                                                                toggleEdit();
                                                            }}
                                                        >
                                                            <i className={`mdi mdi-pencil`} />
                                                        </Button>
                                                        <Button
                                                            type={'button'}
                                                            color={`danger`}
                                                            onClick={() => {
                                                                setRemove({ ...status });
                                                                toggleRemove();
                                                            }}
                                                        >
                                                            <i className={`mdi mdi-delete`} />
                                                        </Button>
                                                    </ButtonGroup>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
            </Modal>

            {edit && (
                <Modal isOpen={showEdit} toggle={toggleEdit}>
                    <ModalHeader>Update Status Record</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label>{`Status`}</Label>
                            <Input
                                type={`select`}
                                value={edit.statusId}
                                onChange={(e) =>
                                    setEdit({
                                        ...edit,
                                        statusId: +e.target.value,
                                    })
                                }
                            >
                                <option value={0}>{` Select a Status `}</option>
                                {Object.keys(ProgramStatusActiveOnly)
                                    .filter((ps) => !isNaN(+ps))
                                    .map((ps) => (
                                        <option key={+ps} value={+ps}>
                                            {ProgramStatusActiveOnly[ps]}
                                        </option>
                                    ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>{`Effective Date`}</Label>
                            <Input
                                type={'date'}
                                value={moment.utc(edit.effectiveDate).format('YYYY-MM-DD')}
                                onChange={(e) =>
                                    setEdit({
                                        ...edit,
                                        effectiveDate: new Date(e.target.value),
                                    })
                                }
                            />
                        </FormGroup>
                        {edit.statusId === 9 && (
                            <FormGroup>
                                <Label>{`Last Class Enrolled Under Current Accreditation`}</Label>
                                <Input
                                    type={'date'}
                                    value={
                                        edit.lastClassToBeAccredited
                                            ? moment.utc(edit.lastClassToBeAccredited).format('YYYY-MM-DD')
                                            : undefined
                                    }
                                    onChange={(e) =>
                                        setEdit({
                                            ...edit,
                                            lastClassToBeAccredited: new Date(e.target.value),
                                        })
                                    }
                                />
                            </FormGroup>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color={'primary'}
                            className={'mr-2'}
                            onClick={() => updateStatus()}
                            type={'button'}
                        >{`Save`}</Button>
                        <Button color={'link'} onClick={() => toggleEdit()} type={'button'}>{`Cancel`}</Button>
                    </ModalFooter>
                </Modal>
            )}

            {remove && (
                <Modal isOpen={showRemove} toggle={toggleRemove}>
                    <ModalHeader>Remove Status Record</ModalHeader>
                    <ModalBody>
                        <p>
                            {`To complete the removal of the ${ProgramStatus[remove.statusId]}, 
                        effective ${moment.utc(remove.effectiveDate).format('MM/DD/YYYY')}, 
                        please click the confirm button below.`}
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color={'primary'}
                            className={'mr-2'}
                            onClick={() => removeStatus()}
                            type={'button'}
                        >{`Confirm`}</Button>
                        <Button color={'link'} onClick={() => toggleRemove()} type={'button'}>{`Cancel`}</Button>
                    </ModalFooter>
                </Modal>
            )}

            <Modal isOpen={showModalNew} toggle={toggleModalNew}>
                <ModalHeader>Set Program Status</ModalHeader>
                {newStatus && (
                    <ModalBody>
                        <FormGroup>
                            <Label>{`Status`}</Label>
                            <Input
                                type={`select`}
                                value={newStatus.statusId}
                                onChange={(e) =>
                                    setNewStatus({
                                        ...newStatus,
                                        statusId: +e.target.value,
                                    })
                                }
                            >
                                <option value={0}>{` Select a Status `}</option>
                                {Object.keys(ProgramStatusActiveOnly)
                                    .filter((ps) => !isNaN(+ps))
                                    .map((ps) => (
                                        <option key={+ps} value={+ps}>
                                            {ProgramStatusActiveOnly[ps]}
                                        </option>
                                    ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>{`Effective Date`}</Label>
                            <Input
                                type={'date'}
                                value={moment.utc(newStatus.effectiveDate).format('YYYY-MM-DD')}
                                onChange={(e) =>
                                    setNewStatus({
                                        ...newStatus,
                                        effectiveDate: new Date(e.target.value),
                                    })
                                }
                            />
                        </FormGroup>
                        {newStatus.statusId === 9 && (
                            <FormGroup>
                                <Label>{`Last Class Enrolled Under Current Accreditation`}</Label>
                                <Input
                                    type={'date'}
                                    value={
                                        newStatus.lastClassToBeAccredited
                                            ? moment.utc(newStatus.lastClassToBeAccredited).format('YYYY-MM-DD')
                                            : undefined
                                    }
                                    onChange={(e) =>
                                        setNewStatus({
                                            ...newStatus,
                                            lastClassToBeAccredited: new Date(e.target.value),
                                        })
                                    }
                                />
                            </FormGroup>
                        )}
                    </ModalBody>
                )}
                <ModalFooter>
                    <Button
                        color={'primary'}
                        className={'mr-2'}
                        onClick={() => saveNewStatus()}
                        type={'button'}
                    >{`Save`}</Button>
                    <Button color={'link'} onClick={() => toggleModalNew()} type={'button'}>{`Cancel`}</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default StatusHistory;
