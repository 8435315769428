import React, { useState } from 'react';
import HubNavigation from '../../components/HubNavigation';
import HubExplorer from '../../components/HubExplorer';

const Hub = () => {
    const [folderStructureLastUpdated, setFolderStructureLastUpdated] = useState<Date>(new Date());

    const handleFolderStructureChanged = () => {
        setFolderStructureLastUpdated(new Date());
    };

    return (
        <div
            style={{
                minHeight: 'calc(100vh - 70px)',
                display: 'flex',
            }}
        >
            <HubNavigation structureLastChanged={folderStructureLastUpdated} />
            <HubExplorer onStructureChanged={handleFolderStructureChanged} />
        </div>
    );
};

export default Hub;
