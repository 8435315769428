import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { HubDocument } from '../common/Types';
import { hubService } from '../services/HubService';

const HubPublicDownload = () => {
    const { documentSlug } = useParams<{ documentSlug: string }>();
    const [hubDocument, setHubDocument] = useState<HubDocument>();
    const [error, setError] = useState<string>();

    useEffect(() => {
        if (!!documentSlug) {
            hubService
                .hubDocumentBySlugPublic(documentSlug)
                .then((document) => {
                    if (!!!document) {
                        setError('Requested file is no longer available');
                    } else if (document.isDeleted) {
                        setError('Requested file is no longer available');
                    } else {
                        setHubDocument(document);
                    }
                })
                .catch((err) => {
                    if (err.message === 'No Content') {
                        setError('Requested file is no longer available');
                    } else {
                        setError(err.message);
                    }
                });
        }
    }, [documentSlug, setHubDocument, setError]);

    useEffect(() => {
        if (!!hubDocument) {
            hubService
                .downloadHubDocument(hubDocument.hubDocumentId)
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = hubDocument.name;
                    a.click();
                })
                .catch((err) => {
                    setError(err.message);
                });
        }
    }, [hubDocument, setError]);

    return (
        <div
            style={{
                height: '60vh',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {!!error && (
                <h4 className={`text-danger`}>
                    <i className={`mdi mdi-close-octagon mr-2`} /> <span>{error}</span>
                </h4>
            )}
            {!!!error && !!hubDocument && (
                <h4>
                    <i className={`mdi mdi-download mr-2`} /> <span>{`File downloading now`}</span>
                </h4>
            )}
        </div>
    );
};

export default HubPublicDownload;
