import * as yup from 'yup';

import { Card, CardBody, CardFooter, Col, Form, FormGroup, Input, Label } from 'reactstrap';
import { Concentration, ProfessionDto, Program, ProgramAddress, ProgramDto } from '../common/Types';
import React, { useEffect, useState } from 'react';

import Button from './Button';
import { Redirect } from 'react-router-dom';
import { professionService } from '../services/ProfessionAdministration';
import { programService } from '../services/ProgramService';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { States } from '../common/Data';

interface NewProgramProps {
    onSaved: (newProgram: ProgramDto) => void;
    onCanceled: () => void;
    institutionId: number;
}

const NewProgram = (props: NewProgramProps) => {
    const { onSaved, onCanceled, institutionId } = props;

    const [navigateToId, setNavigateToId] = useState<number>();
    const [programAddress, setProgramAddress] = useState<ProgramAddress>();
    const [professions, setProfessions] = useState<ProfessionDto[]>();
    const [concentrations, setConcentrations] = useState<Concentration[]>();
    const [filteredConcentrations, setFilteredConcentrations] = useState<Concentration[]>();

    const programAddressSchema: yup.ObjectSchema<ProgramAddress> = yup
        .object({
            id: yup.number().required().default(0),
            city: yup.string().required(),
            state: yup.string().required(),
            address: yup.string().required(),
            address2: yup.string().notRequired().nullable().default(null),
            address3: yup.string().notRequired().nullable().default(null),
            address4: yup.string().notRequired().nullable().default(null),
            lastEdited: yup.date().notRequired().nullable().default(null),
            dateCreated: yup.date().notRequired().nullable().default(null),
            isDeleted: yup.boolean().required().default(false),
            editedByString: yup.string().notRequired().nullable().default(null),
            createdByString: yup.string().notRequired().nullable().default(null),
        })
        .defined();

    const programSchema: yup.ObjectSchema<ProgramDto> = yup
        .object({
            programId: yup.number().required().default(0),
            professionId: yup.number().required('A profession is required').min(1, 'A profession is required'),
            concentrationId: yup.number().notRequired().nullable().default(null),
            institutionId: yup.number().required().default(institutionId),
            title: yup.string().required(),
            programName: yup.string().notRequired().nullable().default(null),
            degreeDiploma: yup.boolean().required().default(false),
            degreeCertificate: yup.boolean().required().default(false),
            degreeAssociate: yup.boolean().required().default(false),
            degreeBaccalaureate: yup.boolean().required().default(false),
            degreeMasters: yup.boolean().required().default(false),
            dateFirstAccredited: yup
                .date()
                .notRequired()
                .nullable()
                .default(null)
                .transform((cv, ov) => {
                    return ov === '' ? undefined : cv;
                }),
            distance: yup.boolean().required().default(false),
            militaryPersonnelOnly: yup.boolean().required().default(false),
            website: yup.string().notRequired().nullable().default(null),
            visible: yup.boolean().required().default(true),
            createdBy: yup.string().notRequired().nullable().default(null),
            dateCreated: yup.date().notRequired().nullable().default(null),
            lastEdited: yup.date().notRequired().nullable().default(null),
            editedBy: yup.string().notRequired().nullable().default(null),
            active: yup.boolean().required().default(true),
            credentilingCode: yup.string().notRequired().nullable().default(null),
            onProbation: yup.boolean().required().default(false),
            probationReason: yup.string().notRequired().nullable().default(null),
            probationStartDate: yup.date().notRequired().nullable().default(null),
            statusId: yup.number().notRequired().nullable().default(null),
            programAddressId: yup.number().required().default(0),
            programAddress: programAddressSchema,
        })
        .defined();

    const { handleSubmit, register, errors, watch } = useForm({
        defaultValues: {} as ProgramDto,
        validationSchema: programSchema,
    });

    console.log('Errors:', errors);

    const watchProgram = watch({ nest: true });

    useEffect(() => {
        const loadProfessions = () => {
            professionService
                .getProfessions()
                .then((results) => {
                    setProfessions([
                        ...results.filter((p) => p.isDeleted === false).sort((a, b) => a.title.localeCompare(b.title)),
                    ]);
                })
                .catch(() => {
                    toast.error('Error loading professions');
                });
        };

        const loadConcentrations = () => {
            professionService
                .getProfessionConcentrations([])
                .then((results) => {
                    setConcentrations([...results]);
                })
                .catch(() => {
                    toast.error('Error loading concentrations');
                });
        };

        loadProfessions();
        loadConcentrations();
    }, [setProfessions]);

    const filterConcentrations = (professionId: number) => {
        setFilteredConcentrations([...(concentrations?.filter((c) => c.professionId === professionId) || [])]);
    };

    const submitForm = (values: ProgramDto) => {
        const toastId = toast.info('Creating program...');

        programService
            .saveProgram(new Program(values))
            .then((result) => {
                toast.update(toastId, {
                    render: 'Program created successfully',
                    type: 'success',
                });

                setNavigateToId(result.programId);
            })
            .catch(() => {
                toast.update(toastId, {
                    render: 'Error creating program',
                    type: 'error',
                });
            });
    };

    const selectedProfession = professions?.find((p) => p.professionId === +(watchProgram.professionId || -1));
    const selectedConcentration = concentrations?.find(
        (c) => c.concentrationId === +(watchProgram.concentrationId || -1),
    );
    const programTitle = `${selectedProfession?.title || ''}${
        selectedConcentration ? ` - ${selectedConcentration.title}` : ''
    }`;

    if (navigateToId && navigateToId > 0) {
        return <Redirect to={`/institution/${institutionId}/program/${navigateToId}`} />;
    } else {
        return (
            <>
                <Form onSubmit={handleSubmit(submitForm)}>
                    <Card className={'mb-0'}>
                        <CardBody>
                            <h4>{`New Program`}</h4>

                            <FormGroup>
                                <Label>{`Profession`}</Label>
                                <Input
                                    type={'select'}
                                    name={'professionId'}
                                    innerRef={register()}
                                    onChange={(e) => filterConcentrations(+e.target.value)}
                                >
                                    <option value={-1}>{`Select a Profession`}</option>
                                    {professions?.map((p) => (
                                        <option key={p.professionId} value={p.professionId}>
                                            {p.title}
                                        </option>
                                    ))}
                                </Input>
                                {errors?.professionId?.message && (
                                    <span className={'text-danger'}>{errors.professionId.message}</span>
                                )}
                            </FormGroup>

                            {(filteredConcentrations?.length || 0) > 0 && (
                                <FormGroup>
                                    <Label>{`Concentration`}</Label>
                                    <Input type={'select'} name={'concentrationId'} innerRef={register()}>
                                        <option value={-1}>{`Select a Concentration`}</option>
                                        {filteredConcentrations?.map((c) => (
                                            <option key={c.concentrationId} value={c.concentrationId}>
                                                {c.title}
                                            </option>
                                        ))}
                                    </Input>
                                    {errors?.concentrationId?.message && (
                                        <span className={'text-danger'}>{errors.concentrationId.message}</span>
                                    )}
                                </FormGroup>
                            )}

                            <FormGroup>
                                <Label>{`Title`}</Label>
                                <div>
                                    <span>{programTitle || 'Please select a profession'}</span>
                                    <input type={'hidden'} name={'title'} ref={register()} value={programTitle} />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label>{`Program Name`}</Label>
                                <Input type={'text'} name={'programName'} innerRef={register()} />
                                {errors?.programName?.message && (
                                    <span className={'text-danger'}>{errors.programName.message}</span>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label>{`Address`}</Label>
                                <div>
                                    <Input
                                        type="text"
                                        name="programAddress.address"
                                        id="programAddress.address"
                                        placeholder="Address"
                                        innerRef={register()}
                                        defaultValue={programAddress?.address ?? undefined}
                                    />
                                </div>
                                <div className="mt-1">
                                    <Input
                                        type="text"
                                        name="programAddress.address2"
                                        id="programAddress.address2"
                                        placeholder="Address 2"
                                        innerRef={register()}
                                        defaultValue={programAddress?.address2 ?? undefined}
                                    />
                                </div>
                                <div className="mt-1">
                                    <Input
                                        type="text"
                                        name="programAddress.address3"
                                        id="programAddress.address3"
                                        placeholder="Address 3"
                                        innerRef={register()}
                                        defaultValue={programAddress?.address3 ?? undefined}
                                    />
                                </div>
                                <div className="mt-1">
                                    <Input
                                        type="text"
                                        name="programAddress.address4"
                                        id="programAddress.address4"
                                        placeholder="Address 4"
                                        innerRef={register()}
                                        defaultValue={programAddress?.address4 ?? undefined}
                                    />
                                </div>
                                <div className="mt-1">
                                    <div className="d-inline-block w-50">
                                        <Input
                                            type="text"
                                            name="programAddress.city"
                                            id="programAddress.city"
                                            placeholder="City"
                                            innerRef={register()}
                                            defaultValue={programAddress?.city ?? undefined}
                                        />
                                    </div>
                                    <div className="d-inline-block w-25 p-1">
                                        <Input
                                            type={'select'}
                                            name="programAddress.state"
                                            id="programAddress.state"
                                            placeholder="State"
                                            defaultValue={programAddress?.state || undefined}
                                            innerRef={register()}
                                        >
                                            <option value={undefined}>{``}</option>
                                            {States &&
                                                States.map((s) => (
                                                    <option key={s.abbreviation} value={s.abbreviation}>
                                                        {s.abbreviation}
                                                    </option>
                                                ))}
                                        </Input>
                                    </div>
                                    <div className="d-inline-block w-25">
                                        <Input
                                            type="text"
                                            name="programAddress.zip"
                                            id="programAddress.zip"
                                            placeholder="Zip"
                                            innerRef={register()}
                                            defaultValue={programAddress?.zip ?? undefined}
                                        />
                                    </div>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label>{`Degree(s)`}</Label>
                                <div className={'d-flex justify-content-between'}>
                                    <div className={'ml-1 custom-control custom-checkbox'}>
                                        <Input
                                            type="checkbox"
                                            id={`degreeDiploma`}
                                            name={`degreeDiploma`}
                                            innerRef={register()}
                                            className={'custom-control-input'}
                                        />
                                        <Label for={`degreeDiploma`} className={'custom-control-label'}>
                                            Diploma
                                        </Label>
                                    </div>
                                    <div className={'ml-1 custom-control custom-checkbox'}>
                                        <Input
                                            type="checkbox"
                                            id={`degreeCertificate`}
                                            name={`degreeCertificate`}
                                            innerRef={register()}
                                            className={'custom-control-input'}
                                        />
                                        <Label for={`degreeCertificate`} className={'custom-control-label'}>
                                            Certificate
                                        </Label>
                                    </div>
                                    <div className={'ml-1 custom-control custom-checkbox'}>
                                        <Input
                                            type="checkbox"
                                            id={`degreeAssociate`}
                                            name={`degreeAssociate`}
                                            innerRef={register()}
                                            className={'custom-control-input'}
                                        />
                                        <Label for={`degreeAssociate`} className={'custom-control-label'}>
                                            Associate
                                        </Label>
                                    </div>
                                    <div className={'ml-1 custom-control custom-checkbox'}>
                                        <Input
                                            type="checkbox"
                                            id={`degreeBaccalaureate`}
                                            name={`degreeBaccalaureate`}
                                            innerRef={register()}
                                            className={'custom-control-input'}
                                        />
                                        <Label for={`degreeBaccalaureate`} className={'custom-control-label'}>
                                            Baccalaureate
                                        </Label>
                                    </div>
                                    <div className={'ml-1 custom-control custom-checkbox'}>
                                        <Input
                                            type="checkbox"
                                            id={`degreeMasters`}
                                            name={`degreeMasters`}
                                            innerRef={register()}
                                            className={'custom-control-input'}
                                        />
                                        <Label for={`degreeMasters`} className={'custom-control-label'}>
                                            Masters
                                        </Label>
                                    </div>
                                </div>
                            </FormGroup>

                            <FormGroup>
                                <Label>{`Outcomes Link`}</Label>
                                <Input type={'text'} name={'website'} innerRef={register()} />
                                {errors?.website?.message && (
                                    <span className={'text-danger'}>{errors.website.message}</span>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label>Date First Accredited</Label>
                                <Input name={'dateFirstAccredited'} type={'date'} innerRef={register()} />
                                {errors?.dateFirstAccredited?.message && (
                                    <span className={'text-danger'}>{errors.dateFirstAccredited.message}</span>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label>Credentialing Code</Label>
                                <Input name={'credentialingCode'} type={'text'} innerRef={register()} />
                                {errors?.credentilingCode?.message && (
                                    <span className={'text-danger'}>{errors.credentilingCode.message}</span>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <div className={'custom-control custom-checkbox'}>
                                    <Input
                                        type="checkbox"
                                        id={`distance`}
                                        name={`distance`}
                                        innerRef={register()}
                                        className={'custom-control-input'}
                                    />
                                    <Label for={`distance`} className={'custom-control-label'}>
                                        {`Distance?`}
                                    </Label>
                                </div>
                            </FormGroup>

                            <FormGroup>
                                <div className={'custom-control custom-checkbox'}>
                                    <Input
                                        type="checkbox"
                                        id={`militaryPersonnelOnly`}
                                        name={`militaryPersonnelOnly`}
                                        innerRef={register()}
                                        className={'custom-control-input'}
                                    />
                                    <Label for={`militaryPersonnelOnly`} className={'custom-control-label'}>
                                        {`Military Only?`}
                                    </Label>
                                </div>
                            </FormGroup>

                            <FormGroup>
                                <div className={'custom-control custom-checkbox'}>
                                    <Input
                                        type="checkbox"
                                        id={`visible`}
                                        name={`visible`}
                                        innerRef={register()}
                                        defaultChecked={true}
                                        className={'custom-control-input'}
                                    />
                                    <Label for={`visible`} className={'custom-control-label'}>
                                        {`Visible?`}
                                    </Label>
                                </div>
                            </FormGroup>
                        </CardBody>
                        <CardFooter>
                            <Button color={'primary'} type={'submit'}>
                                {`Create Program`}
                            </Button>
                            <Button color={'secondary'} type={'button'} className={'ml-2'} onClick={() => onCanceled()}>
                                {`Cancel`}
                            </Button>
                        </CardFooter>
                    </Card>
                </Form>
            </>
        );
    }
};

export default NewProgram;
