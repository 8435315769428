import { createStore, combineReducers, applyMiddleware } from 'redux';
import { authReducer } from './auth/Reducers';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

const rootReducer = combineReducers({
    auth: authReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore() {
    const middlewares = [thunkMiddleware];
    const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));
    return store;
}
