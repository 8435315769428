import React, { useState, useEffect } from 'react';
import { PageTitle } from '../../components';
import { Card, CardBody, Col, Row, Badge } from 'reactstrap';
import { InvoiceStatus } from '../../common/Data';
import { invoiceService } from '../../services/InvoiceService';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { InvoicingDetailDto } from '../../types';

const PublicInvoice = () => {
    const [invoice, setInvoice] = useState<InvoicingDetailDto>();
    const { invoiceId } = useParams<{ invoiceId: string }>();

    useEffect(() => {
        const getData = () => {
            invoiceService
                .getPublicInvoice(+invoiceId)
                .then((response) => setInvoice(response))
                .catch(() => toast.error('Failed to get professions.'));
        };

        getData();
    }, [setInvoice, invoiceId]);

    return (
        <>
            <PageTitle title={`Invoice`} />

            <Row>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <div
                                style={{
                                    display: 'flex',
                                    borderBottom: '1px solid',
                                    paddingBottom: '16px',
                                    marginBottom: '6px',
                                }}
                            >
                                <div style={{ flex: 1 }}>
                                    <h2>{invoice?.institutionTitle}</h2>
                                </div>
                                <div className="text-right">
                                    <h2>
                                        <Badge color={invoice?.status === 1 ? 'success' : 'warning'}>
                                            {InvoiceStatus[invoice?.status ?? 3]}
                                        </Badge>
                                    </h2>
                                </div>
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    borderBottom: '1px solid',
                                    paddingBottom: '6px',
                                    marginBottom: '6px',
                                }}
                            >
                                <div style={{ flex: 1 }}>
                                    <h3>Due Date:</h3>
                                </div>
                                <div className="text-right">
                                    <h3>{new Date(invoice?.invoiceDate || new Date()).toDateString()}</h3>
                                </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }}>
                                    <h2>Total Due:</h2>
                                </div>
                                <div className="text-right">
                                    <h2>${invoice?.dueAmount}</h2>
                                </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }}>
                                    <h4>Initial Amount:</h4>
                                </div>
                                <div className="text-right">
                                    <h4>${invoice?.amount}</h4>
                                </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }}>
                                    <h4>Administrative Fee:</h4>
                                </div>
                                <div className="text-right">
                                    <h4>${invoice?.lateFee ?? 0}</h4>
                                </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }}>
                                    <h5>Billing Contact:</h5>
                                </div>
                                <div className="text-right">
                                    <h5>{invoice?.recipient?.email}</h5>
                                </div>
                            </div>

                            <div>
                                <div>
                                    <h5>Payment URL</h5>
                                </div>
                                {invoice?.stripeInvoiceUrl ? (
                                    <div>
                                        <a target="_blank" rel="noopener noreferrer" href={invoice?.stripeInvoiceUrl}>
                                            {invoice?.stripeInvoiceUrl}
                                        </a>
                                    </div>
                                ) : (
                                    <div>Not Available</div>
                                )}
                            </div>

                            <div>
                                <div>
                                    <h5>Description:</h5>
                                </div>
                                <div>{invoice?.description}</div>
                            </div>

                            <div style={{ paddingTop: '24px', display: 'flex' }}>
                                <div style={{ flex: 1 }}></div>
                                {invoice?.pdfUrl && (
                                    <div>
                                        <a href={invoice?.pdfUrl} target="_blank" rel="noopener noreferrer">
                                            Download
                                        </a>
                                    </div>
                                )}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default PublicInvoice;
