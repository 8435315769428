import { Button, IconButton } from '../../components';
import { Card, CardBody, CardSubtitle, CardTitle, Col, Label, Row } from 'reactstrap';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import React, { useEffect, useState } from 'react';

import { IProgramTabSettings } from '../../common/Types';
import Skeleton from 'react-loading-skeleton';
import { professionService } from '../../services/ProfessionAdministration';
import styled from 'styled-components';
import { toast } from 'react-toastify';

const StyledDraggable = styled.div`
    color: white;
    @media (min-width: 576px) {
        min-width: 400px;
    }

    label {
        margin-bottom: 0px;

        &:first-of-type {
            padding-right: 8px;
        }
    }
`;

const StyledSetting = styled(Row)`
    .col {
        > div {
            @media (min-width: 576px) {
                min-width: 300px;
            }

            div {
                display: inline-block;

                &:first-of-type {
                    width: 24px;
                }
            }
        }
    }
`;

const ProgramTabSettings = (props) => {
    const [originalSettings, setOriginalSettings] = useState<IProgramTabSettings[]>();
    const [settings, setSettings] = useState<IProgramTabSettings[]>();
    const [editing, setEditing] = useState(false);

    const onSubmit = () => {
        const toastId = toast.info('Saving settings...');
        professionService
            .saveProgramTabSettings(props.coa.professionId, settings ?? [])
            .then((response) => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Settings Saved.',
                });
                setEditing(false);
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Failed to save settings.',
                });
            });
    };

    useEffect(() => {
        const getTabCustomizations = () => {
            professionService
                .getProgramTabSettings(props.coa.professionId)
                .then((settings) => {
                    if (settings) {
                        setOriginalSettings(settings);
                        setSettings(settings);
                    } else {
                        setSettings([]);
                    }
                })
                .catch((error) => {
                    toast.error('Unable to get settings.');
                });
        };

        getTabCustomizations();
    }, [setSettings, props.coa]);

    const grid = 8;
    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: 'none',
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,
        color: 'white',
        // change background color if dragging
        background: isDragging ? 'gray' : '',
        // styles we need to apply on draggables
        ...draggableStyle,
    });

    const getListStyle = (isDraggingOver) => ({
        background: isDraggingOver ? 'gray' : 'white',
        padding: `0 ${grid} 0 ${grid}`,
    });

    const reorder = (list, startIndex, endIndex): IProgramTabSettings[] => {
        const result = [...list];
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result.map((set, i) => ({ ...set, priority: i + 1 })) as IProgramTabSettings[];
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const orderedSettings = reorder(settings, result.source.index, result.destination.index);
        setSettings(orderedSettings);
    };

    const cancelChanges = () => {
        setSettings(originalSettings);
        setEditing(!editing);
    };

    const toggleVisibility = (annualReportTabId) => {
        setSettings(
            settings?.map((set) => {
                if (set.annualReportTabId === annualReportTabId) {
                    set.visible = !set.visible;
                }
                return set;
            }),
        );
    };

    return (
        <>
            <Col sm={6} md={5}>
                <Card style={{ display: 'inline-block' }}>
                    <CardBody>
                        <CardTitle>
                            <h3>Program Tab Settings</h3>
                            {editing && (
                                <CardSubtitle>
                                    {`Drag and drop to reorder the list. Click the icon that looks like an eye to change 
                                    the visibility of the item to the COA. If the icon doesn't appear, the visibility 
                                    cannot be changed.`}
                                </CardSubtitle>
                            )}
                        </CardTitle>
                        {editing && (
                            <>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={getListStyle(snapshot.isDraggingOver)}
                                            >
                                                {settings?.map((item, index) => (
                                                    <Draggable
                                                        key={item.priority}
                                                        draggableId={item.priority.toString()}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <StyledDraggable
                                                                className={item.visible ? 'bg-info' : 'bg-secondary'}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style,
                                                                )}
                                                            >
                                                                <Label>{index + 1}</Label>
                                                                <Label>{item.title}</Label>
                                                                {item.editable ? (
                                                                    <span style={{ float: 'right' }}>
                                                                        {item.visible ? (
                                                                            <IconButton
                                                                                iconClass="remixicon-eye-line text-white"
                                                                                buttonProps={{
                                                                                    onClick: () =>
                                                                                        toggleVisibility(
                                                                                            item.annualReportTabId,
                                                                                        ),
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <IconButton
                                                                                iconClass="remixicon-eye-off-line text-white"
                                                                                buttonProps={{
                                                                                    onClick: () =>
                                                                                        toggleVisibility(
                                                                                            item.annualReportTabId,
                                                                                        ),
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </span>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </StyledDraggable>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                <Row className="mt-3">
                                    <Col>
                                        <div>
                                            <Button type="button" onClick={() => onSubmit()}>
                                                Save
                                            </Button>
                                            &nbsp;
                                            <Button outline onClick={() => cancelChanges()}>
                                                Cancel
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        )}
                        {!editing && settings && (
                            <>
                                {settings.map((setting, i) => {
                                    return (
                                        <div key={i}>
                                            <div>
                                                <span className={`mr-2`}>{i + 1}</span>
                                                {!setting.visible && (
                                                    <span>
                                                        <s>{setting.title}</s>
                                                    </span>
                                                )}
                                                {setting.visible && <span>{setting.title}</span>}
                                            </div>
                                        </div>
                                    );
                                })}
                                <Row className="mt-3">
                                    <Col>
                                        <Button onClick={() => setEditing(!editing)}>Edit</Button>
                                    </Col>
                                </Row>
                            </>
                        )}
                        {!editing && !settings && <Skeleton count={15} />}
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default ProgramTabSettings;
