import { APIUriAms, http, httpFile } from './HttpHelper';
import { InstitutionalAccreditor, LetterTemplateDto, MeetingDateDto, ProfessionDto } from '../common/Types';

class SystemAdministration {
    public getProfesssion = async () => {
        const result = await http<ProfessionDto>(APIUriAms, '/profession', undefined, undefined, null);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getProfessions = async () => {
        const result = await http<ProfessionDto[]>(APIUriAms, `/profession`, undefined, undefined, null);
        if (result.parsedBody) {
            return result.parsedBody.sort((a, b) => a.title.localeCompare(b.title));
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveProfessions = async (professions: ProfessionDto[]) => {
        const result = await http<ProfessionDto[]>(APIUriAms, `/profession`, undefined, 'POST', professions);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getMeetingDates = async () => {
        const result = await http<MeetingDateDto[]>(APIUriAms, `/meeting`, undefined, undefined, null);
        if (result.parsedBody) {
            return result.parsedBody.map((x) => {
                return { ...x, meetingDate: new Date(x.meetingDate ?? new Date()) } as MeetingDateDto;
            });
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveMeetingDates = async (meetings: MeetingDateDto[]) => {
        const result = await http<MeetingDateDto[]>(APIUriAms, `/meeting`, undefined, 'POST', meetings);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getInstitutionalAccreditors = async (): Promise<InstitutionalAccreditor[]> => {
        const result = await http<InstitutionalAccreditor[]>(APIUriAms, '/system/institutional-accreditors');

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveInstitutionalAccreditor = async (accreditor: InstitutionalAccreditor) => {
        const result = await http<InstitutionalAccreditor>(
            APIUriAms,
            `/system/institutional-accreditor`,
            undefined,
            'PUT',
            accreditor,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getLetterTemplates = async (): Promise<LetterTemplateDto[]> => {
        const result = await http<LetterTemplateDto[]>(APIUriAms, '/system/letter-templates');

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public uploadLetterTemplate = async (upload: LetterTemplateDto): Promise<LetterTemplateDto> => {
        const { newFileName, fileUpload, ...dto } = upload;

        const formData = new FormData();
        formData.append('dto', JSON.stringify(dto));
        if (fileUpload) {
            formData.append('fileUpload', fileUpload);
        }
        if (newFileName) {
            formData.append('newFileName', newFileName);
        }

        const result = await httpFile<any>(APIUriAms, '/system/letter-template', formData, undefined, 'PUT');

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public removeLetterTemplate = async (toRemove: LetterTemplateDto): Promise<LetterTemplateDto> => {
        const result = await http<LetterTemplateDto>(
            APIUriAms,
            `/system/letter-template/${toRemove.letterTemplateId}`,
            undefined,
            'DELETE',
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };
}

export const systemAdministration = new SystemAdministration();
