import { ButtonGroup, Card, CardBody, CardFooter, FormGroup, Label, Modal, ModalHeader, Table } from 'reactstrap';
import React, { useEffect, useRef, useState } from 'react';

import Button from './Button';
import { InstitutionNameHistory as HistoryDto } from '../common/Types';
import Input from './Input';
import Skeleton from 'react-loading-skeleton';
import { institutionService } from '../services/InstitutionService';
import moment from 'moment';
import { toast } from 'react-toastify';
import { AmsPermissionClaimType, authService } from '../services/Auth';

interface NameHistoryProps {
    institutionId: number;
}

interface NameHistoryState {
    history?: HistoryDto[];
    editHistory?: HistoryDto;
    showEditForm: boolean;
    showRemoveForm: boolean;
}

const InstitutionNameHistory = (props: NameHistoryProps) => {
    const { institutionId } = props;

    const [state, setState] = useState<NameHistoryState>({ showEditForm: false, showRemoveForm: false });
    const [reload, setReload] = useState<boolean>(true);

    const { history, editHistory, showEditForm, showRemoveForm } = state;

    const toggleShowEditForm = () => setState({ ...state, showEditForm: !showEditForm });
    const toggleShowRemoveForm = () => setState({ ...state, showRemoveForm: !showRemoveForm });

    useEffect(() => {
        if (reload) {
            setReload(false);

            institutionService
                .getNameHistory(institutionId)
                .then((history) => {
                    setState({
                        ...state,
                        history: history
                            .filter((h) => h.isActive === true)
                            .sort(
                                (a, b) =>
                                    moment(b.effectiveDate || b.lastEdited).unix() -
                                    moment(a.effectiveDate || a.lastEdited).unix(),
                            ),
                    });
                })
                .catch(() => {
                    toast.error('Error loading name history');
                });
        }
    }, [reload]);

    const startNew = () => {
        setState({
            ...state,
            editHistory: {
                isActive: true,
                title: '',
                institutionId: institutionId,
                institutionNameHistoryId: 0,
            },
            showEditForm: true,
        });
    };

    const startEdit = (edit: HistoryDto) => {
        setState({
            ...state,
            editHistory: {
                ...edit,
            },
            showEditForm: true,
        });
    };

    const startRemove = (remove: HistoryDto) => {
        setState({
            ...state,
            editHistory: {
                ...remove,
                isActive: false,
            },
            showRemoveForm: true,
        });
    };

    const save = () => {
        if (editHistory) {
            const toastId = toast.info('Saving name history record...');

            institutionService
                .saveNameHistory(editHistory)
                .then(() => {
                    toast.update(toastId, {
                        render: 'Name history record saved',
                        type: 'success',
                    });

                    setState({
                        ...state,
                        showEditForm: false,
                        showRemoveForm: false,
                    });
                    setReload(true);
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error saving name history record',
                        type: 'error',
                    });
                });
        }
    };

    return (
        <>
            <Card>
                <CardBody className={'pb-0'}>
                    <h4 className={`card-title d-flex justify-content-between align-items-center`}>
                        <span>{`Name History`}</span>
                        {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') && (
                            <Button type={'button'} color={'info'} onClick={() => startNew()}>
                                <i className={`mdi mdi-plus`} />
                                <span className={`ml-1`}>{`Add Name`}</span>
                            </Button>
                        )}
                    </h4>
                </CardBody>
                {!history && <Skeleton count={5} />}
                {history && (
                    <Table responsive hover className={'mb-0'}>
                        <thead>
                            <tr>
                                <th>{`Effective Date`}</th>
                                <th>{`Name`}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {history.length > 0 &&
                                history.map((h) => (
                                    <tr key={h.institutionNameHistoryId}>
                                        <td>
                                            {h.effectiveDate ? moment.utc(h.effectiveDate).format('MM/DD/YYYY') : ''}
                                        </td>
                                        <td>{h.title}</td>
                                        <td className={'text-right'}>
                                            {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') && (
                                                <ButtonGroup>
                                                    <Button
                                                        type={'button'}
                                                        className={'btn-sm'}
                                                        color={'info'}
                                                        onClick={() => startEdit(h)}
                                                    >
                                                        <i className={'mdi mdi-pencil'} />
                                                    </Button>
                                                    <Button
                                                        type={'button'}
                                                        className={'btn-sm'}
                                                        color={'danger'}
                                                        onClick={() => startRemove(h)}
                                                    >
                                                        <i className={'mdi mdi-delete'} />
                                                    </Button>
                                                </ButtonGroup>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            {history.length === 0 && (
                                <tr>
                                    <td colSpan={3}>{`No name history records`}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                )}
            </Card>
            {editHistory && (
                <Modal isOpen={showEditForm} toggle={toggleShowEditForm} centered>
                    <Card className={'mb-0'}>
                        <CardBody>
                            <h4 className={'card-title'}>{`${
                                editHistory.institutionNameHistoryId > 0 ? `Edit` : `Add`
                            } Name History`}</h4>
                            <FormGroup>
                                <Label>{`Name`}</Label>
                                <Input
                                    type={'text'}
                                    value={editHistory.title}
                                    onChange={(e) =>
                                        setState({ ...state, editHistory: { ...editHistory, title: e.target.value } })
                                    }
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>{`Effective Date`}</Label>
                                <Input
                                    type={'date'}
                                    value={
                                        editHistory.effectiveDate
                                            ? moment(editHistory.effectiveDate).format('YYYY-MM-DD')
                                            : ''
                                    }
                                    onChange={(e) =>
                                        setState({
                                            ...state,
                                            editHistory: {
                                                ...editHistory,
                                                effectiveDate: e.target.value
                                                    ? moment(e.target.value).toDate()
                                                    : undefined,
                                            },
                                        })
                                    }
                                />
                            </FormGroup>
                        </CardBody>
                        <CardFooter className={'text-right'}>
                            <Button type={'button'} color={'secondary'} onClick={() => toggleShowEditForm()}>
                                {`Cancel`}
                            </Button>
                            <Button type={'button'} color={'primary'} className={'ml-2'} onClick={() => save()}>
                                {`Submit`}
                            </Button>
                        </CardFooter>
                    </Card>
                </Modal>
            )}
            {editHistory && (
                <Modal isOpen={showRemoveForm} toggle={toggleShowRemoveForm} centered>
                    <Card className={'mb-0'}>
                        <CardBody>
                            <h4 className={'card-title'}>{`Confirm Name History Delete`}</h4>
                            <p>{`Please click Confirm below to proceed with the name history record deletion.`}</p>
                        </CardBody>
                        <CardFooter className={'text-right'}>
                            <Button type={'button'} color={'secondary'} onClick={() => toggleShowRemoveForm()}>
                                {`Cancel`}
                            </Button>
                            <Button type={'button'} color={'primary'} className={'ml-2'} onClick={() => save()}>
                                {`Submit`}
                            </Button>
                        </CardFooter>
                    </Card>
                </Modal>
            )}
        </>
    );
};

export default InstitutionNameHistory;
