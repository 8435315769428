import React from 'react';
import { NavItem, Nav as StrapNav } from 'reactstrap';
import { RouteItem } from '../router/Routes';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const StyledNavLink = styled(NavLink)`
    padding: 8px 0px;
`;

type Props = {
    routes: NavRouteItem[];
    vertical?: boolean;
    className?: string;
};

interface NavRouteItem extends RouteItem {
    name?: string;
    activeMatch?: string;
}

const Nav = (props: Props) => {
    const matchesActive = (routePath: string, activeMatch?: string) => {
        if (activeMatch) {
            return window.location.pathname.indexOf(activeMatch) >= 0;
        }

        return window.location.pathname === routePath;
    };

    const mappedRoutes = props.routes.map((route) => {
        return { ...route, active: matchesActive(route.path, route.activeMatch) };
    });

    return (
        <StrapNav className={props.className} vertical={props.vertical || false}>
            {mappedRoutes.map((route, i) => {
                return (
                    <NavItem key={i} className={route.active ? 'active' : ''}>
                        <StyledNavLink to={route.path} active={route.active ? '' : undefined}>
                            {route.name}
                        </StyledNavLink>
                    </NavItem>
                );
            })}
        </StrapNav>
    );
};

export default Nav;
