import { APIUriAms, ApiUriArms, http } from './HttpHelper';
import {
    BaseGeneralSetting,
    Concentration,
    GeneralSettings,
    IDocumentCategory,
    IProgramTabSettings,
    OutcomeTypeCustomization,
    OutcomesType,
    PersonnelSettings,
    ProfessionAddOnTrack,
    ProfessionDetail,
    ProfessionDetailsDto,
    ProfessionDto,
    ProfessionStandardsDesignationDto,
    ProfessionStandardsDesignationSubStandardDto,
    ProfessionStandardsDesignationSubStandardRationaleDto,
    ProfessionStandardsDto,
    TabSetting,
} from '../common/Types';
class ProfessionAdministration {
    public getProfesssion = async (id) => {
        const result = await http<ProfessionDto>(APIUriAms, `/profession/${id}`, undefined, undefined, null);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getProfessions = async () => {
        const result = await http<ProfessionDto[]>(APIUriAms, `/profession`, undefined, undefined, null);
        if (result.parsedBody) {
            return result.parsedBody.sort((a, b) => a.title.localeCompare(b.title));
        } else {
            throw new Error(result.statusText);
        }
    };

    public getProfessionDetails = async (professionId: number) => {
        const result = await http<ProfessionDetailsDto>(
            APIUriAms,
            `/profession/detail/${professionId}`,
            undefined,
            undefined,
            null,
        );
        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveProfessions = async (professions: ProfessionDto[]) => {
        const result = await http<ProfessionDto[]>(APIUriAms, `/profession`, undefined, 'POST', professions);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public createArmsProfession = async (profession: ProfessionDto) => {
        const result = await http<ProfessionDto>(
            ApiUriArms,
            `/Administration/profession`,
            undefined,
            'POST',
            profession,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveProfessionDetail = async (detail: ProfessionDetail) => {
        const result = await http<ProfessionDetail>(APIUriAms, `/profession/detail`, undefined, 'PATCH', detail);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getProfessionStandards = async (professionId: number): Promise<ProfessionStandardsDto[]> => {
        const result = await http<ProfessionStandardsDto[]>(
            APIUriAms,
            `/profession/${professionId}/standards`,
            undefined,
            undefined,
            null,
        );
        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getProfessionStandardDesignations = async (
        professionId: number,
    ): Promise<ProfessionStandardsDesignationDto[]> => {
        const result = await http<ProfessionStandardsDesignationDto[]>(
            APIUriAms,
            `/profession/${professionId}/standard-designations`,
            undefined,
            undefined,
            null,
        );
        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveStandardVersions = async (standards: ProfessionStandardsDto[]) => {
        const result = await http<ProfessionStandardsDto[]>(
            APIUriAms,
            `/profession/standards`,
            undefined,
            'POST',
            standards,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveStandardDesignations = async (standards: ProfessionStandardsDesignationDto[]) => {
        const result = await http<ProfessionStandardsDesignationDto[]>(
            APIUriAms,
            `/profession/standard-designation`,
            undefined,
            'POST',
            standards,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveSubStandards = async (standards: ProfessionStandardsDesignationSubStandardDto[]) => {
        const result = await http<ProfessionStandardsDesignationSubStandardDto[]>(
            APIUriAms,
            `/profession/standard-designation-substandards`,
            undefined,
            'POST',
            standards,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveRationale = async (rationale: ProfessionStandardsDesignationSubStandardRationaleDto[]) => {
        const result = await http<ProfessionStandardsDesignationSubStandardRationaleDto[]>(
            APIUriAms,
            `/profession/standard-designation-substandards-rationale`,
            undefined,
            'POST',
            rationale,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getGeneralSettings = async (professionId): Promise<GeneralSettings> => {
        const result = await http<any>(
            ApiUriArms,
            `/administration/general-settings/${professionId}`,
            undefined,
            undefined,
            null,
        );

        if (result.parsedBody) {
            const transformed = {
                reportingWindow: new BaseGeneralSetting(professionId, result.parsedBody.reportingWindow),
                outcomeTypesCustomizations: result.parsedBody.outcomeTypesCustomizations,
                originalReportingWindow: new BaseGeneralSetting(professionId, result.parsedBody.reportingWindow),
            };

            return transformed;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getGeneralSettingsByProfessionIds = async (professionIds: number[]): Promise<GeneralSettings[]> => {
        const result = await http<any>(
            ApiUriArms,
            `/administration/general-settings/professionIds?${professionIds
                .map((professionId) => {
                    return `professionId=${professionId}`;
                })
                .join('&')}`,
        );

        if (result.parsedBody) {
            return result.parsedBody.map((setting) => {
                return {
                    reportingWindow: new BaseGeneralSetting(
                        setting.reportingWindow.professionId,
                        setting.reportingWindow,
                    ),
                    outcomeTypesCustomizations: setting.outcomeTypesCustomizations,
                    originalReportingWindow: new BaseGeneralSetting(
                        setting.reportingWindow.professionId,
                        setting.reportingWindow,
                    ),
                } as GeneralSettings;
            });
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveGeneralSettings = async (professionId, settings): Promise<GeneralSettings> => {
        const result = await http<any>(
            ApiUriArms,
            `/administration/general-settings/${professionId}`,
            undefined,
            'POST',
            settings,
        );

        if (result.parsedBody) {
            return {
                reportingWindow: result.parsedBody.reportingWindow,
                outcomeTypesCustomizations: result.parsedBody.outcomeTypesCustomizations,
                originalReportingWindow: result.parsedBody.reportingWindow,
            };
        } else {
            throw new Error(result.statusText);
        }
    };

    public getPersonnelSettings = async (professionId): Promise<PersonnelSettings[]> => {
        const result = await http<PersonnelSettings[]>(
            ApiUriArms,
            `/administration/personnel/${professionId}`,
            undefined,
            undefined,
            null,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public savePersonnelSettings = async (professionId, settings) => {
        const result = await http<PersonnelSettings[]>(
            ApiUriArms,
            `/administration/personnel/${professionId}`,
            undefined,
            'POST',
            settings,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getAdvisoryCommitteeSettings = async (professionId): Promise<PersonnelSettings[]> => {
        const result = await http<PersonnelSettings[]>(
            ApiUriArms,
            `/administration/advisory/${professionId}`,
            undefined,
            undefined,
            null,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveAdvisoryCommitteeSettings = async (professionId, settings) => {
        const result = await http<PersonnelSettings[]>(
            ApiUriArms,
            `/administration/advisory/${professionId}`,
            undefined,
            'POST',
            settings,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getProgramTabSettings = async (professionId): Promise<IProgramTabSettings[]> => {
        const result = await http<IProgramTabSettings[]>(
            ApiUriArms,
            `/administration/program-tab/${professionId}`,
            undefined,
            undefined,
            null,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveProgramTabSettings = async (
        professionId: number,
        settings: IProgramTabSettings[],
    ): Promise<IProgramTabSettings[]> => {
        const result = await http<IProgramTabSettings[]>(
            ApiUriArms,
            `/administration/program-tab/${professionId}`,
            undefined,
            'POST',
            settings,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getProgramInformation = async (professionId) => {
        const result = await http<TabSetting[]>(
            ApiUriArms,
            `/administration/program-information/${professionId}`,
            undefined,
            undefined,
            null,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveProgramInformation = async (professionId, expenditures) => {
        const result = await http<TabSetting[]>(
            ApiUriArms,
            `/administration/program-information/${professionId}`,
            undefined,
            'POST',
            expenditures,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getResources = async (professionId) => {
        const result = await http<TabSetting[]>(
            ApiUriArms,
            `/administration/resources/${professionId}`,
            undefined,
            undefined,
            null,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveResources = async (professionId, resouces) => {
        const result = await http<TabSetting[]>(
            ApiUriArms,
            `/administration/resources/${professionId}`,
            undefined,
            'POST',
            resouces,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getDocumentCategories = async (professionId): Promise<IDocumentCategory[]> => {
        const result = await http<IDocumentCategory[]>(
            ApiUriArms,
            `/document/categories/${professionId}/true`,
            undefined,
            undefined,
            null,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveDocumentCategories = async (professionId, settings) => {
        const result = await http<IDocumentCategory[]>(
            ApiUriArms,
            `/document/categories/${professionId}`,
            undefined,
            'POST',
            settings,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getExams = async (professionId): Promise<OutcomesType[]> => {
        const result = await http<OutcomesType[]>(
            ApiUriArms,
            `/outcomes/types/by-profession-id/${professionId}`,
            undefined,
            undefined,
            null,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveExams = async (settings) => {
        const result = await http<OutcomesType[]>(ApiUriArms, `/outcomes/types`, undefined, 'POST', settings);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getMeasures = async (professionId) => {
        const result = await http<TabSetting[]>(
            ApiUriArms,
            `/administration/summative-measures/${professionId}`,
            undefined,
            undefined,
            null,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveMeasures = async (professionId, measures) => {
        const result = await http<TabSetting[]>(
            ApiUriArms,
            `/administration/summative-measures/${professionId}`,
            undefined,
            'POST',
            measures,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getProfessionConcentrations = async (professionIds: number[]): Promise<Concentration[]> => {
        const result = await http<Concentration[]>(
            APIUriAms,
            `/profession/concentrations?${professionIds
                .map((professionId) => {
                    return `professionIds=${professionId}`;
                })
                .join('&')}`,
            undefined,
            undefined,
            null,
        );

        if (result.parsedBody) {
            return result.parsedBody.sort((a, b) => a.title.localeCompare(b.title));
        } else {
            throw new Error(result.statusText);
        }
    };

    public getProfessionOutcomeTypesByConcentration = async (
        professionId: number,
        concentrationId: number | null,
    ): Promise<OutcomeTypeCustomization[]> => {
        const result = await http<OutcomeTypeCustomization[]>(
            ApiUriArms,
            `/outcomes/types/by-profession-and-concentration/${professionId}/${concentrationId ? concentrationId : ''}`,
            undefined,
            undefined,
            null,
        );

        if (result.parsedBody) {
            return result.parsedBody.sort((a, b) => a.title?.localeCompare(b.title));
        } else {
            throw new Error(result.statusText);
        }
    };

    public getAddOnTracksByProfessionId = async (professionId: number): Promise<ProfessionAddOnTrack[]> => {
        const result = await http<ProfessionAddOnTrack[]>(APIUriAms, `/profession/${professionId}/addontracks`);

        if (result.parsedBody) {
            return result.parsedBody.sort((a, b) => a.title?.localeCompare(b.title));
        } else {
            throw new Error(result.statusText);
        }
    };

    public getAllProfessionAddOnTracks = async (): Promise<ProfessionAddOnTrack[]> => {
        const result = await http<ProfessionAddOnTrack[]>(APIUriAms, `/profession/addontracks`);

        if (result.parsedBody) {
            return result.parsedBody.sort((a, b) => a.title?.localeCompare(b.title));
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveAddOnTrack = async (addOnTrack: ProfessionAddOnTrack): Promise<ProfessionAddOnTrack> => {
        const result = await http<ProfessionAddOnTrack>(
            APIUriAms,
            `/profession/addontrack`,
            undefined,
            'PUT',
            addOnTrack,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveConcentration = async (concentration: Concentration): Promise<Concentration> => {
        const result = await http<Concentration>(
            APIUriAms,
            `/profession/concentration`,
            undefined,
            'PUT',
            concentration,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };
}

export const professionService = new ProfessionAdministration();
