import React, { useState, useEffect } from 'react';
import { ProfessionStandardsDto } from '../../../common/Types';
import { Card, CardBody, Table, Form, FormGroup } from 'reactstrap';
import { Button, Input } from '../../../components';
import { useForm, useFieldArray } from 'react-hook-form';
import * as yup from 'yup';
import { professionService } from '../../../services/ProfessionAdministration';
import { toast } from 'react-toastify';

type Props = {
    professionStandards: ProfessionStandardsDto[] | undefined;
    professionId: number;
};

interface Versions {
    versions: ProfessionStandardsDto[];
}

const StandardVersionsCard = (props: Props) => {
    const [versions, setVersions] = useState<ProfessionStandardsDto[]>();
    const [editing, setEditing] = useState(false);
    const [deletedVersions, setDeletedVersions] = useState<ProfessionStandardsDto[]>([]);

    // Individual ProfessionStandardsDto validation schema
    const versionSchema: yup.ObjectSchema<ProfessionStandardsDto> = yup
        .object({
            professionStandardId: yup.number().required().default(0),
            professionId: yup.number().required(),
            standardYear: yup.number().required(),
            isDeleted: yup.boolean().required().default(false),
            dateCreated: yup.string(),
            lastEdited: yup.string(),
            createdByString: yup.string(),
            editedByString: yup.string(),
            createdBy: yup.string(),
        })
        .defined();

    // ProfessionStandardsDto validation schema as a list
    const listSchema = yup.object().shape({
        versions: yup.array().of(versionSchema),
    });

    const { control, handleSubmit, register, reset } = useForm({
        validationSchema: listSchema,
        defaultValues: { versions: [] } as Versions,
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'versions',
    });

    useEffect(() => {
        if (props.professionStandards) {
            setVersions(props.professionStandards);
            append(props.professionStandards);
        }
    }, [props.professionStandards, setVersions, append]);

    const onSubmit = (formData: Versions) => {
        const toastId = toast.info('Saving Standard Versions...');

        // Add back deleted standard versions
        const saveObj = [...formData.versions, ...deletedVersions];

        professionService
            .saveStandardVersions(saveObj)
            .then((response) => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Standard Versions saved successfuly',
                });
                reset({ versions: response });
                setVersions(response);
                setEditing(false);
            })
            .catch(() =>
                toast.update(toastId, {
                    type: 'error',
                    render: 'Error saving Standard Versions',
                }),
            );
    };

    const deleteVersion = (standard, i) => {
        remove(i);
        // Keep track of deleted versions so we don't have to manage the UI
        standard.isDeleted = true;
        setDeletedVersions([...deletedVersions, standard]);
    };

    return (
        <Card>
            <CardBody>
                <div style={{ display: 'flex' }} className="mb-2">
                    <div style={{ flex: 1 }}>
                        <h5>Standard Versions</h5>
                    </div>
                    {editing && (
                        <div>
                            <Button type={'button'} color={'info'} onClick={() => append({})}>
                                <i className="remixicon-add-line" />
                            </Button>
                        </div>
                    )}
                </div>
                {editing ? (
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        {fields.map((version, i) => {
                            return (
                                <div style={{ display: 'flex' }} key={i}>
                                    <FormGroup key={i} style={{ flex: 1, paddingRight: '8px' }}>
                                        <Input
                                            name={`versions[${i}].standardYear`}
                                            innerRef={register()}
                                            defaultValue={version.standardYear}
                                        />
                                        <input
                                            type="hidden"
                                            name={`versions[${i}].professionStandardId`}
                                            defaultValue={version.professionStandardId ?? 0}
                                            ref={register()}
                                        />
                                        <input
                                            type="hidden"
                                            name={`versions[${i}].professionId`}
                                            defaultValue={props.professionId}
                                            ref={register()}
                                        />
                                        <input
                                            type="hidden"
                                            name={`versions[${i}].createdBy`}
                                            defaultValue={version.createdBy}
                                            ref={register()}
                                        />
                                        <input
                                            type="hidden"
                                            name={`versions[${i}].dateCreated`}
                                            defaultValue={version.dateCreated}
                                            ref={register()}
                                        />
                                        <input
                                            type="hidden"
                                            name={`versions[${i}].lastEdited`}
                                            defaultValue={version.lasteEdited}
                                            ref={register()}
                                        />
                                        <input
                                            type="hidden"
                                            name={`versions[${i}].isDeleted`}
                                            defaultValue={version.isDeleted ?? false}
                                            ref={register()}
                                        />
                                        <input
                                            type="hidden"
                                            name={`versions[${i}].createdByString`}
                                            defaultValue={version.createdByString}
                                            ref={register()}
                                        />
                                        <input
                                            type="hidden"
                                            name={`versions[${i}].editedByString`}
                                            defaultValue={version.editedByString}
                                            ref={register()}
                                        />
                                    </FormGroup>
                                    <div>
                                        <Button
                                            type={'button'}
                                            color={'danger'}
                                            onClick={() => deleteVersion(version, i)}
                                        >
                                            <i className={'remixicon-delete-bin-line'}></i>
                                        </Button>
                                    </div>
                                </div>
                            );
                        })}
                        <Button color="primary" type="submit">
                            Save
                        </Button>
                        &nbsp;
                        <Button outline onClick={() => setEditing(false)}>
                            Cancel
                        </Button>
                    </Form>
                ) : (
                    <>
                        <Table>
                            <tbody>
                                {versions?.map((standard) => {
                                    return (
                                        <tr key={standard.professionStandardId}>
                                            <td>{standard.standardYear}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                        <Button color="primary" onClick={() => setEditing(true)}>
                            Edit Versions
                        </Button>
                    </>
                )}
            </CardBody>
        </Card>
    );
};

export default StandardVersionsCard;
