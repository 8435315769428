import { APIUriAms, ApiUriArms, http } from './HttpHelper';
import {
    InstitutionPeopleDto,
    ProgramPersonnelDto
} from '../common/Types';

class PersonnelService {
    public getAmsPersonnelByInsitutionId = async (institutionId: number): Promise<InstitutionPeopleDto[]> => {
        const result = await http<InstitutionPeopleDto[]>(APIUriAms, `/institution/people/${institutionId}`, undefined, undefined, null);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getArmsProgramPersonnelByProgramIdAndYear = async (programId: number, year: number, reportYear: number): Promise<ProgramPersonnelDto[]> => {
        const result = await http<ProgramPersonnelDto[]>(ApiUriArms, `/program/personnel/${programId}/${year}/${reportYear}`, undefined, undefined, null);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveProgramPersonnel = async (programId: number, year: number, personnel: ProgramPersonnelDto[]): Promise<ProgramPersonnelDto[]> => {
        const result = await http<ProgramPersonnelDto[]>(
            ApiUriArms,
            `/program/personnel/${programId}/${year}`,
            undefined,
            'PUT',
            personnel
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    }
}

export const personnelService = new PersonnelService();