import {
    ButtonDropdown,
    Card,
    CardBody,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    ModalHeader,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
} from 'reactstrap';
import { InvoicePaymentDetailDto, PagingDto, PaymentSearchDto } from '../../types';
import React, { useEffect, useState } from 'react';

import { Button } from '../../components';
import { PaymentType } from '../../common/Data';
import { invoiceService } from '../../services/InvoiceService';
import { toast } from 'react-toastify';

const UnconfirmedPayments = () => {
    const [unconfirmedPayments, setUnconfirmedPayments] = useState<InvoicePaymentDetailDto[]>();
    const [dropdownOpenId, setDropdownOpenId] = useState<number>();
    const [reload, setReload] = useState<boolean>(true);
    const [confirmingPayment, setConfirmingPayment] = useState(false);
    const [paymentToConfirm, setPaymentToConfirm] = useState<number>();
    const [paging, setPaging] = useState<PagingDto>({
        pageNumber: 1,
        maxPageSize: null,
        pageSize: 10,
    });
    const [search] = useState<PaymentSearchDto>({
        paymentTypeId: null,
        startDate: null,
        endDate: null,
        localTime: null,
        fiscalYear: null,
        isConfirmed: false,
        paging: paging,
    });

    const toggleButtonDropdown = (id: number) => {
        if (dropdownOpenId) setDropdownOpenId(undefined);
        else setDropdownOpenId(id);
    };

    useEffect(() => {
        const getData = () => {
            if (reload) {
                setReload(false);
                invoiceService
                    .getInvoicePayments({
                        ...search,
                        paging: paging,
                    })
                    .then((response) => setUnconfirmedPayments(response))
                    .catch(() => toast.error('Failed to get receipts'));
            }
        };
        getData();
    }, [setUnconfirmedPayments, paging, search, reload]);

    const confirmPayment = (paymentId: number) => {
        const toastId = toast.info('Confirming payment...');

        invoiceService
            .confirmPayments([paymentId])
            .then(() => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Payment confirmed successfuly',
                });
                setConfirmingPayment(false);
                setPaymentToConfirm(undefined);
                setReload(true);
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Error confirmed payment',
                });
            });
    };

    const deletePayment = (payment: InvoicePaymentDetailDto) => {
        const toastId = toast.info('Deleting payment...');

        payment.isDeleted = true;

        invoiceService
            .saveInvoicePayment(payment)
            .then(() => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Payment deleted successfuly',
                });
                setReload(true);
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Error deleting payment',
                });
            });
    };

    return (
        <>
            <Card>
                <CardBody>
                    <div>
                        <h3>Unconfirmed Payments</h3>
                    </div>
                </CardBody>
                <Table>
                    <thead>
                        <tr>
                            <th>Insitution</th>
                            <th>Payment Date</th>
                            <th>Type</th>
                            <th>Amount</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {unconfirmedPayments ? (
                            unconfirmedPayments
                                .filter((x) => x.isDeleted === false)
                                .map((payment, i) => (
                                    <tr key={payment.id ?? i}>
                                        <td>{payment.invoice.institutionTitle}</td>
                                        <td>{payment.paymentDate && new Date(payment.paymentDate).toDateString()}</td>
                                        <td>{PaymentType[payment.paymentType]}</td>
                                        <td>${payment.paymentAmount}</td>
                                        <td>
                                            <ButtonDropdown
                                                className="text-left"
                                                isOpen={payment.id === dropdownOpenId}
                                                toggle={() => payment.id && toggleButtonDropdown(payment.id)}
                                                direction="left"
                                            >
                                                <DropdownToggle caret color="info">
                                                    <i className={'remixicon-more-2-fill'}></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem
                                                        onClick={() => {
                                                            if (payment.id) {
                                                                setPaymentToConfirm(payment.id);
                                                                setConfirmingPayment(true);
                                                            }
                                                        }}
                                                    >
                                                        Confirm
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => deletePayment(payment)}>
                                                        Delete
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </ButtonDropdown>
                                        </td>
                                    </tr>
                                ))
                        ) : (
                            <tr>
                                <td colSpan={7}>No Results</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                <CardBody>
                    <Pagination size="sm" aria-label="Unconfirmed Payments Navigation">
                        <PaginationItem disabled={paging.pageNumber === 1}>
                            <PaginationLink
                                previous
                                href=""
                                onClick={() => setPaging({ ...paging, pageNumber: paging.pageNumber - 1 })}
                            />
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationLink
                                next
                                href=""
                                onClick={() => setPaging({ ...paging, pageNumber: paging.pageNumber + 1 })}
                            />
                        </PaginationItem>
                    </Pagination>
                </CardBody>
            </Card>

            {paymentToConfirm && (
                <Modal isOpen={confirmingPayment} toggle={() => setConfirmingPayment(!confirmingPayment)} size="lg">
                    <ModalHeader toggle={() => setConfirmingPayment(!confirmingPayment)}>Confirm Payment</ModalHeader>
                    <ModalBody>
                        <p>
                            Are you sure you want to confirm this payment? You will not be able to delete it once it is
                            confirmed.
                        </p>
                        <div>
                            <Button
                                type="button"
                                color={'link'}
                                onClick={() => setConfirmingPayment(!confirmingPayment)}
                            >
                                No
                            </Button>
                            <Button type="button" color="primary" onClick={() => confirmPayment(paymentToConfirm)}>
                                Yes
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
            )}
        </>
    );
};

export default UnconfirmedPayments;
