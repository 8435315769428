import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Badge, Card, CardBody, Col, Row, Table } from 'reactstrap';
import { InvoicingDetailDto } from '../../types';
import { invoiceService } from '../../services/InvoiceService';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './payment-form';
import { PaymentIntent } from '../../types/InvoicingDto';
import { debounce } from 'lodash';

const currencyFormatter = new Intl.NumberFormat(`en-US`, {
    style: `currency`,
    currency: `USD`,
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || ``);

const InvoicePayment = () => {
    const { invoiceNumber } = useParams<{ invoiceNumber: string }>();
    const [invoice, setInvoice] = useState<InvoicingDetailDto>();
    const [selectedProfessions, setSelectedProfessions] = useState<number[]>([]);
    const [payLateFee, setPayLateFee] = useState<boolean>(false);
    const [paymentIntent, setPaymentIntent] = useState<PaymentIntent>();
    const [paymentAmount, setPaymentAmount] = useState<number>();
    const [email, setEmail] = useState<string>();

    const amountPaid = (inv: InvoicingDetailDto): number => {
        let total = 0;

        if (!!inv.invoicePayments) {
            inv.invoicePayments
                .filter((ip) => !ip.isDeleted)
                .forEach((ip) => {
                    total += +ip.paymentAmount;
                });
        }

        return total;
    };

    const isLateFeeDue = (inv: InvoicingDetailDto): boolean => {
        let profAmts = 0;
        inv.professionListDeserialized?.forEach((pl) => {
            profAmts += pl.amountPaid || 0;
        });
        return (inv.lateFee || 0) > 0 && amountPaid(inv) <= profAmts;
    };

    useEffect(() => {
        if (!!invoiceNumber) {
            invoiceService
                .getPublicInvoiceByNumber(invoiceNumber)
                .then((response) => {
                    const unpaidProfessions =
                        response.professionListDeserialized?.filter(
                            (pl) => (response?.amount || 0) > (pl.amountPaid || 0),
                        ) || [];
                    setInvoice(response);
                    setSelectedProfessions(unpaidProfessions.map((pl) => pl.id));
                    setPayLateFee(isLateFeeDue(response));
                    setPaymentAmount(response.dueAmount);
                    setEmail(response.recipient.email);
                })
                .catch((err) => {
                    console.log(err);
                    toast.error(`There was a problem loading the requested invoice.`);
                });
        }
    }, [invoiceNumber, setInvoice]);

    useEffect(() => {
        if ((paymentAmount || 0) > 0 && invoice && email) {
            let amountToPay = paymentAmount || 0;

            if (amountToPay > (invoice?.dueAmount || 0)) {
                amountToPay = invoice?.dueAmount || 0;
            }

            if (!paymentIntent) {
                invoiceService
                    .createPaymentIntent(
                        (amountToPay || 0) * 1.029 || 0,
                        email,
                        `Invoice Number: ${invoice.invoiceNumber}`,
                    )
                    .then((response) => {
                        setPaymentIntent(response);
                    })
                    .catch((err) => {
                        console.log(err);
                        toast.error(`There was an issue setting up the payment form.`);
                    });
            } else {
                invoiceService
                    .updatePaymentIntent(
                        paymentIntent.id,
                        (amountToPay || 0) * 1.029 || 0,
                        email,
                        `Invoice Number: ${invoice.invoiceNumber}`,
                    )
                    .then((response) => {
                        setPaymentIntent(response);
                    })
                    .catch((err) => {
                        console.log(err);
                        toast.error(`There was an issue setting up the payment form.`);
                    });
            }
        }
    }, [paymentAmount, setPaymentIntent, email]);

    useEffect(() => {
        if (!!invoice?.amount) {
            let total = selectedProfessions.length * invoice.amount;
            if (payLateFee && (invoice.lateFee || 0) > 0) {
                total += invoice.lateFee || 0;
            }

            setPaymentAmount(total);
        }
    }, [selectedProfessions, payLateFee, invoice?.amount, invoice?.lateFee, setPaymentAmount]);

    const followingYear = (): number | undefined => {
        return !!invoice?.fiscalYear ? +invoice.fiscalYear + 1 : undefined;
    };

    const fiscalYearString = (): string => {
        return !!invoice?.fiscalYear ? `${invoice.fiscalYear} - ${followingYear()}` : ``;
    };

    const formatMoneyOrZero = (amount: number | undefined | null): string => {
        return currencyFormatter.format(amount || 0);
    };

    const amountPaidString = (): string => {
        if (!!invoice) {
            return formatMoneyOrZero(amountPaid(invoice));
        }

        return formatMoneyOrZero(0);
    };

    const hasLateFeeBeenPaid = (): boolean => {
        const total = invoice ? amountPaid(invoice) : 0;
        let profAmts = 0;
        invoice?.professionListDeserialized?.forEach((pl) => {
            profAmts += pl.amountPaid || 0;
        });

        return total > profAmts;
    };

    const stripeOptions = {
        clientSecret: paymentIntent?.clientSecret,
    };

    const getSerializedProfessionIds = () => {
        const profAmounts =
            invoice?.professionListDeserialized
                ?.filter((pl) => selectedProfessions?.includes(pl.id))
                .map((pl) => {
                    return {
                        id: pl.id,
                        amountPaid:
                            pl?.amountPaid == 0 ? invoice?.amount || 0 : (invoice?.amount || 0) - (pl?.amountPaid || 0),
                    };
                }) || [];
        return JSON.stringify(profAmounts);
    };

    const isProgramPaid = (pl): boolean => {
        if (pl.isPaid === true) {
            return true;
        }

        if ((pl.amountPaid || 0) >= (invoice?.amount || 0)) {
            return true;
        }

        return false;
    };

    return (
        <>
            {!!invoice && (
                <>
                    <Row className="justify-content-center">
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={9} className={`mb-3`}>
                                            <img
                                                style={{ width: `100%`, maxWidth: `400px` }}
                                                src="https://uploads-ssl.webflow.com/5f466098a462432df91fef63/5fa3462933aa311a701e3196_CAAHEP_Full_LBlue.gif"
                                            />
                                        </Col>
                                        <Col md={3} className={`d-flex flex-column mb-3`}>
                                            <div className={`d-flex justify-content-between`}>
                                                <span className={`font-weight-bold`}>Invoice:</span>
                                                <span>{invoice.invoiceNumber}</span>
                                            </div>
                                            <div className={`d-flex justify-content-between`}>
                                                <span className={`font-weight-bold`}>Date:</span>
                                                <span>{moment(invoice.invoiceDate).format(`MM/DD/yyyy`)}</span>
                                            </div>
                                            <div className={`d-flex justify-content-between`}>
                                                <span className={`font-weight-bold`}>Term:</span>
                                                <span>Net 30</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className={`mb-3`}>
                                        {!!invoice.recipient && (
                                            <Col className={`d-flex flex-column`}>
                                                <label>
                                                    <span className={`font-weight-bold`}>To:</span>
                                                </label>
                                                <span>{`${invoice.recipient.firstName} ${invoice.recipient.lastName}`}</span>
                                                <span>{invoice.recipient.title || ``}</span>
                                                <span>{invoice.institutionTitle}</span>
                                                <span>{invoice.recipient.address || ``}</span>
                                                <span>{invoice.recipient.address2 || ``}</span>
                                                <span>{invoice.recipient.address3 || ``}</span>
                                                <span>{invoice.recipient.address4 || ``}</span>
                                                <span>{`${invoice.recipient.city || ``}, ${
                                                    invoice.recipient.state || ``
                                                } ${invoice.recipient.zip || ``}`}</span>
                                            </Col>
                                        )}
                                        <Col className={`d-flex flex-column`}>
                                            <label>
                                                <span className={`font-weight-bold`}>For:</span>
                                            </label>
                                            <span>CAAHEP Accredited Program Fee</span>
                                            {!!invoice.fiscalYear && <span>{fiscalYearString()}</span>}
                                            {!!invoice.fiscalYear && (
                                                <span>{`[July 1, ${
                                                    invoice.fiscalYear
                                                } - June 30, ${followingYear()}]`}</span>
                                            )}
                                        </Col>
                                    </Row>
                                    <Table className={`mb-3`}>
                                        <thead>
                                            <tr>
                                                <th>Description</th>
                                                <th>Fee Per Accredited Program</th>
                                                <th>Check the Programs Included in Payment</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan={3}>
                                                    {`CAAHEP ${fiscalYearString()} Accredited Program Fee`}
                                                </td>
                                            </tr>
                                            {!!invoice.professionListDeserialized &&
                                                invoice.professionListDeserialized.map((pl) => (
                                                    <tr key={pl.id}>
                                                        <td className={`pl-5`}>
                                                            <span>{pl.title}</span>
                                                            {!!pl.concentrationsOrAddOnTracks && (
                                                                <div className={`mt-1`}>
                                                                    {pl.concentrationsOrAddOnTracks.map((c, i) => (
                                                                        <div
                                                                            key={i}
                                                                            className={`pl-5`}
                                                                        >{` - ${c}`}</div>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td>{formatMoneyOrZero(invoice?.amount)}</td>
                                                        <td>
                                                            {isProgramPaid(pl) === true && (
                                                                <Badge color={'primary'}>Paid</Badge>
                                                            )}
                                                            {!!!isProgramPaid(pl) && (
                                                                <input
                                                                    type={`checkbox`}
                                                                    checked={
                                                                        !!selectedProfessions.find((id) => id === pl.id)
                                                                    }
                                                                    onChange={(e) => {
                                                                        const others = selectedProfessions.filter(
                                                                            (id) => id !== pl.id,
                                                                        );

                                                                        if (e.target.checked) {
                                                                            setSelectedProfessions([...others, pl.id]);
                                                                        } else {
                                                                            setSelectedProfessions([...others]);
                                                                        }
                                                                    }}
                                                                />
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            <tr>
                                                <td>Administrative Fee</td>
                                                <td>{formatMoneyOrZero(invoice.lateFee)}</td>
                                                <td>
                                                    {isLateFeeDue(invoice) && (
                                                        <input
                                                            type={'checkbox'}
                                                            checked={payLateFee}
                                                            onChange={(e) => setPayLateFee(e.target.checked)}
                                                        />
                                                    )}
                                                    {hasLateFeeBeenPaid() && <Badge color={'primary'}>Paid</Badge>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Amount Paid</td>
                                                <td>{amountPaidString()}</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td className={`font-weight-bold`}>
                                                    Total Fee for all CAAHEP Accredited Programs
                                                </td>
                                                <td>{formatMoneyOrZero(invoice.dueAmount)}</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    {/* <Row>
                                <Col className={`d-flex flex-column`}>
                                    <label><span className={`font-weight-bold`}>Please remit payment to:</span></label>
                                    <label></label>
                                    <label></label>
                                    <label></label>
                                </Col>
                                <Col className={`d-flex flex-column`}>
                                    <label className={`mb-3`}>{`Payments are due `}<span className={"font-weight-bold"}>August 1, @Model.FiscalYear</span></label>
                                    <label className={`mb-3`}>{`
                                        If you have any questions concerning this invoice, contact CAAHEP at
                                        (727) 210-2350 ext.102, or email receivables@CAAHEP.org.`}</label>
                                </Col>
                            </Row> */}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {!!paymentIntent?.clientSecret && !!invoice && (
                        <Row className="justify-content-center">
                            <Col>
                                <Card>
                                    <CardBody>
                                        <Elements stripe={stripePromise} options={stripeOptions}>
                                            <PaymentForm
                                                paymentIntentId={paymentIntent.id}
                                                invoiceId={invoice.id}
                                                amount={(paymentAmount || 0) * 1.029}
                                                professionIds={getSerializedProfessionIds()}
                                                recipientEmail={invoice.recipient.email}
                                                onEmailChange={debounce((newEmail) => setEmail(newEmail), 3000)}
                                            />
                                        </Elements>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </>
    );
};

export default InvoicePayment;
