import * as yup from 'yup';

import { Card, CardBody, CardFooter, Form, FormGroup, Input, Label } from 'reactstrap';
import { CoaDto, ProfessionDto } from '../common/Types';
import React, { useEffect, useState } from 'react';

import { Button } from '.';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { coaService } from '../services/CoaAdministration';
import { professionService } from '../services/ProfessionAdministration';

interface NewProfessionFormProps {
    onCancel: () => void;
    onSave: (newProfessionId: number) => void;
}

const NewProfessionForm = (props: NewProfessionFormProps) => {
    const { onCancel, onSave } = props;

    const [coas, setCoas] = useState<CoaDto[]>();

    const newProfessionSchema: yup.ObjectSchema<ProfessionDto> = yup
        .object<ProfessionDto>({
            professionId: yup.number().required().default(0),
            title: yup.string().required(`Profession Name is required`),
            isDeleted: yup.boolean().required().default(false),
            webSiteSearchCount: yup.number().required().default(0),
            armsUser: yup.boolean().required().default(true),
            showDegreeOnCertificate: yup.boolean().required().default(false),
            coaId: yup.number().required().min(1, `Please select a CoA`),
            coaTitle: yup.string().required().default(''),
            isOneDegreePerProgram: yup.boolean().required().default(false),
        })
        .defined();

    const { handleSubmit, errors, watch, register, reset } = useForm({
        validationSchema: newProfessionSchema,
        defaultValues: {} as ProfessionDto,
    });

    useEffect(() => {
        if (!coas) {
            coaService
                .getAllCoas()
                .then((results) => {
                    setCoas(
                        results.filter((r) => r.isActive === true).sort((a, b) => (a.abb || '').localeCompare(b.abb)),
                    );
                })
                .catch(() => {
                    toast.error('Error loading CoA list');
                });
        }
    }, [setCoas]);

    const onSubmit = (values: ProfessionDto) => {
        const toastId = toast.info('Creating profession...');

        professionService
            .saveProfessions([values])
            .then((results) => {
                professionService
                    .createArmsProfession({
                        ...results[0],
                    })
                    .then((result) => {
                        toast.update(toastId, {
                            render: 'Profession created successfully',
                            type: 'success',
                        });

                        onSave(results[0].professionId);
                    })
                    .catch(() => {
                        toast.update(toastId, {
                            render: 'Error creating profession',
                            type: 'error',
                        });
                    });
            })
            .catch(() => {
                toast.update(toastId, {
                    render: 'Error creating profession',
                    type: 'error',
                });
            });
    };

    console.log(errors);

    return (
        <>
            {coas && (
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Card className={'mb-0'}>
                        <CardBody>
                            <h4 className={'card-title'}>{`New Profession`}</h4>

                            <FormGroup>
                                <Label>{`Profession Name`}</Label>

                                <Input type={'text'} name={'title'} innerRef={register()} />
                                {errors?.title?.message && (
                                    <span className={'text-danger mt-2'}>{errors.title.message}</span>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label>{`CoA`}</Label>

                                <Input type={'select'} name={'coaId'} innerRef={register()}>
                                    <option value={-1}>{`Select a CoA`}</option>
                                    {coas.map((c) => (
                                        <option key={c.id} value={c.id}>
                                            {c.abb}
                                        </option>
                                    ))}
                                </Input>
                                <Input type={'hidden'} defaultValue={`N/A`} innerRef={register()} name={`coaTitle`} />
                                {errors?.coaId?.message && (
                                    <span className={'text-danger mt-2'}>{errors.coaId.message}</span>
                                )}
                            </FormGroup>
                        </CardBody>
                        <CardFooter>
                            <Button color={'primary'} type={'submit'}>
                                <span>{`Save`}</span>
                            </Button>
                            <Button color={'secondary'} className={'ml-2'} onClick={onCancel}>
                                <span>{`Cancel`}</span>
                            </Button>
                        </CardFooter>
                    </Card>
                </Form>
            )}
        </>
    );
};

export default NewProfessionForm;
