import * as yup from 'yup';

import { AffiliatePersonnel, ClinicalAffiliate, ProgramPersonnelDto } from '../common/Types';
import { Button, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap';
import { Countries, SatelliteType, SatelliteTypes, States } from '../common/Data';
import { useFieldArray, useForm } from 'react-hook-form';

import React from 'react';
import { programService } from '../services/ProgramService';
import { toast } from 'react-toastify';

interface ClinicalAffiliateFormProps {
    onSave: () => void;
    onCancel: () => void;
    programId: number;
    affiliate?: ClinicalAffiliate;
    canEdit: boolean;
    personnel: ProgramPersonnelDto[];
    reportYear: number;
}

const ClinicalAffiliateForm = (props: ClinicalAffiliateFormProps) => {
    const clinicalAffiliateSchema: yup.ObjectSchema<ClinicalAffiliate> = yup
        .object({
            satelliteId: yup
                .number()
                .required()
                .default(props.affiliate?.satelliteId || 0),
            programId: yup.number().required().default(props.programId),
            title: yup.string().notRequired(),
            city: yup.string().notRequired(),
            state: yup.string().notRequired(),
            country: yup.string().notRequired(),
            satalliteTypeId: yup.number().required().default(SatelliteType['Clinical Affiliate']),
            address: yup.string().notRequired(),
            address2: yup.string().notRequired(),
            postalCode: yup.string().notRequired(),
            dateCreated: yup
                .date()
                .required()
                .default(new Date(props.affiliate?.dateCreated || new Date())),
            isActive: yup
                .boolean()
                .required()
                .default(props.affiliate?.isActive || true),
            personnel: yup
                .array()
                .of(
                    yup
                        .object<AffiliatePersonnel>({
                            satelliteId: yup
                                .number()
                                .required()
                                .default(props.affiliate?.satelliteId || 0),
                            programPersonnelId: yup.number().required(),
                            reportYear: yup
                                .number()
                                .required()
                                .default(+props.reportYear),
                        })
                        .defined(),
                )
                .notRequired(),
        })
        .defined();

    const {
        handleSubmit,
        register,
        control,
        watch,
        errors,
        setValue,
        formState: { isSubmitting },
    } = useForm({
        validationSchema: clinicalAffiliateSchema,
        defaultValues:
            props.affiliate ||
            ({
                isActive: true,
                satelliteId: 0,
                programId: props.programId,
                satalliteTypeId: SatelliteTypes['Clinical Affiliate'],
                personnel: [],
                country: 'United States of America',
            } as ClinicalAffiliate),
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'personnel',
    });

    const onSubmit = async (values: ClinicalAffiliate): Promise<void> => {
        return new Promise((res) => {
            const toastId = toast.info('Saving Clinical Affiliate...');

            programService
                .saveClinicalAffiliate(values, props.reportYear)
                .then((result) => {
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Clinical Affiliate saved successfuly',
                    });
                    props.onSave();
                })
                .catch(() => {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Error saving Clinical Affiliate',
                    });
                })
                .finally(() => {
                    res();
                });
        });
    };

    const formatPersonText = (personnel: ProgramPersonnelDto): string => {
        return `${personnel.personnelType ? `${personnel.personnelType.title}: ` : ``}
        ${personnel.people?.firstName} ${personnel.people?.lastName}
        ${personnel.people?.city ? `, ${personnel.people.city}` : ``}
        ${personnel.people?.state ? `, ${personnel.people.state}` : ``}`;
    };

    const watchIsActive = JSON.parse(watch('isActive').toString());

    console.log(errors);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <input type={'hidden'} name={'isActive'} ref={register()} />
            {!props.affiliate && (
                <Row>
                    <Col>
                        <h5 className={'card-title'}>Clinical Affiliate Detail</h5>
                    </Col>
                </Row>
            )}
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Name</Label>
                        <Input name="title" innerRef={register()} bsSize={'sm'} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>Address</Label>
                                <Input name="address" innerRef={register()} bsSize={'sm'} />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>Address 2</Label>
                                <Input name="address2" innerRef={register()} bsSize={'sm'} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} md={6}>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label>City</Label>
                                <Input name="city" innerRef={register()} bsSize={'sm'} />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label>State</Label>
                                <Input type={'select'} name="state" innerRef={register()} bsSize={'sm'}>
                                    <option value={undefined}>{``}</option>
                                    {States &&
                                        States.map((s) => (
                                            <option key={s.abbreviation} value={s.abbreviation}>
                                                {s.abbreviation}
                                            </option>
                                        ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label>Zip / Postal Code</Label>
                                <Input name="postalCode" innerRef={register()} bsSize={'sm'} />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label>Country</Label>
                                <Input type={'select'} name="country" innerRef={register()} bsSize={'sm'}>
                                    <option value={undefined}>{``}</option>
                                    {Countries &&
                                        Countries.map((c) => (
                                            <option key={c} value={c}>
                                                {c}
                                            </option>
                                        ))}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Row className={'mb-2'}>
                        <Col>
                            <h5 className={'card-title'}>Clinical Affiliate Personnel</h5>
                            <h6
                                className={'card-subtitle text-muted'}
                            >{`Please select personnel to associate to this Clinical Affiliate`}</h6>
                        </Col>
                    </Row>
                    {fields.map((ap, i) => (
                        <FormGroup key={ap.id}>
                            <input
                                type={'hidden'}
                                ref={register()}
                                name={`personnel[${i}].satelliteId`}
                                defaultValue={ap.satelliteId}
                            />
                            <input
                                type={'hidden'}
                                ref={register()}
                                name={`personnel[${i}].reportYear`}
                                defaultValue={ap.reportYear}
                            />
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <Button type={'button'} color={'danger'} onClick={() => remove(i)}>
                                        <i className={'mdi mdi-close'} />
                                    </Button>
                                </InputGroupAddon>
                                <Input
                                    type={'select'}
                                    name={`personnel[${i}].programPersonnelId`}
                                    innerRef={register()}
                                >
                                    {props.personnel &&
                                        props.personnel.map((person) => (
                                            <option key={person.programPersonnelId} value={person.programPersonnelId}>
                                                {formatPersonText(person)}
                                            </option>
                                        ))}
                                </Input>
                            </InputGroup>
                        </FormGroup>
                    ))}

                    {props.personnel && props.personnel.length > 0 && (
                        <Button
                            type={'button'}
                            color={'info'}
                            onClick={() =>
                                append({
                                    satelliteId: props.affiliate?.satelliteId || 0,
                                    reportYear: +props.reportYear,
                                })
                            }
                        >
                            <i className={'mdi mdi-plus'} />
                            <span className={'ml-1'}>Add Person</span>
                        </Button>
                    )}
                </Col>
            </Row>

            {props.canEdit && (
                <Row className={'mt-2'}>
                    <Col xs={12} className={'d-flex flex-row-reverse'}>
                        <Button type="button" color={'link'} onClick={props.onCancel}>
                            Cancel
                        </Button>
                        <Button disabled={isSubmitting} type="submit" color={'primary'}>
                            Save
                        </Button>
                        {props.affiliate && (
                            <Button
                                type="button"
                                color={watchIsActive ? 'danger' : 'secondary'}
                                className={'mr-5'}
                                onClick={() => setValue('isActive', false)}
                            >
                                <i className={'mdi mdi-delete'} />
                                {watchIsActive && <span className={'ml-1'}>Remove Affiliate</span>}
                                {!watchIsActive && <span className={'ml-1'}>Click Save to Confirm</span>}
                            </Button>
                        )}
                    </Col>
                </Row>
            )}
        </Form>
    );
};

export default ClinicalAffiliateForm;
