import React, { useState, useEffect } from 'react';
import { InvoicePaymentsDto, InvoicingDetailDto, InvoicingDto } from '../../../types';
import {
    Card,
    CardBody,
    CardTitle,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import moment from 'moment';
import { InvoiceStatus, PaymentType } from '../../../common/Data';
import InvoicePaymentForm from './InvoicePaymentForm';
import { Button } from '../../../components';
import { invoiceService } from '../../../services/InvoiceService';
import { toast } from 'react-toastify';

interface InvoicePaymentsProps {
    payments: InvoicePaymentsDto[];
    amountPaid: number;
    invoice: InvoicingDetailDto | undefined;
    onSave: () => void;
}

const InvoicePayments = (props: InvoicePaymentsProps) => {
    const [payments, setPayments] = useState<InvoicePaymentsDto[]>([]);
    const [addingPayment, setAddingPayment] = useState(false);
    const [isRefund, setIsRefund] = useState(false);
    const [dropdownOpenId, setDropdownOpenId] = useState<number>();
    const [confirmingPayment, setConfirmingPayment] = useState(false);
    const [paymentToConfirm, setPaymentToConfirm] = useState<number>();

    // Sort
    useEffect(() => {
        setPayments(props.payments.sort((a, b) => moment(a.createDate).diff(moment(b.createDate))));
    }, [setPayments, props.payments]);

    const paymentsUpdated = () => {
        setAddingPayment(false);
        props.onSave();
    };

    const toggleButtonDropdown = (id: number) => {
        if (dropdownOpenId) setDropdownOpenId(undefined);
        else setDropdownOpenId(id);
    };

    const confirmPayment = (paymentId: number) => {
        const toastId = toast.info('Confirming payment...');

        invoiceService
            .confirmPayments([paymentId])
            .then(() => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Payment confirmed  successfuly',
                });
                setConfirmingPayment(false);
                setPaymentToConfirm(undefined);
                props.onSave();
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Error confirmed payment',
                });
            });
    };

    const deletePayment = (payment: InvoicePaymentsDto) => {
        const toastId = toast.info('Deleting payment...');

        payment.isDeleted = true;

        invoiceService
            .saveInvoicePayment(payment)
            .then(() => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Payment deleted successfuly',
                });
                props.onSave();
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Error deleting payment',
                });
            });
    };

    return (
        <>
            <Card>
                <CardBody>
                    <CardTitle>
                        <h3>Payments</h3>
                    </CardTitle>
                    <Table striped responsive>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Paid By</th>
                                <th>Email</th>
                                <th>Type</th>
                                <th>Amount</th>
                                <th>Transaction #</th>
                                <th>Reference #</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {payments
                                .filter((x) => x.isDeleted === false)
                                .map((payment, i) => (
                                    <tr key={i}>
                                        <td>{moment(payment.paymentDate).format('YYYY-MM-DD')}</td>
                                        <td>{`${payment.paidBy || ``}`}</td>
                                        <td>{payment.email}</td>
                                        <td>{PaymentType[payment.paymentType]}</td>
                                        <td>${payment.paymentAmount?.toFixed(2)}</td>
                                        <td>{payment.transactionId}</td>
                                        <td>{payment.referenceNumber}</td>
                                        {payment.paymentType !== 3 && payment.isConfirmed === false ? (
                                            <td>
                                                <ButtonDropdown
                                                    className="text-left"
                                                    isOpen={payment.id === dropdownOpenId}
                                                    toggle={() => payment.id && toggleButtonDropdown(payment.id)}
                                                    direction="left"
                                                >
                                                    <DropdownToggle caret color="info">
                                                        <i className={'remixicon-more-2-fill'}></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem
                                                            onClick={() => {
                                                                if (payment.id) {
                                                                    setPaymentToConfirm(payment.id);
                                                                    setConfirmingPayment(true);
                                                                }
                                                            }}
                                                        >
                                                            Confirm
                                                        </DropdownItem>
                                                        <DropdownItem onClick={() => deletePayment(payment)}>
                                                            Delete
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </ButtonDropdown>
                                            </td>
                                        ) : (
                                            <td></td>
                                        )}
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                    {!props.invoice?.isDeleted && (
                        <div style={{ paddingTop: '24px', display: 'flex' }}>
                            {(props.invoice?.dueAmount || 0) > 0 && (
                                <div className="mr-sm-2">
                                    <Button
                                        onClick={() => {
                                            setAddingPayment(true);
                                            setIsRefund(false);
                                        }}
                                    >
                                        Add Payment
                                    </Button>
                                </div>
                            )}
                            {props.invoice?.status != InvoiceStatus.Unpaid && (
                                <div className="mr-sm-2">
                                    <Button
                                        color="danger"
                                        onClick={() => {
                                            setAddingPayment(true);
                                            setIsRefund(true);
                                        }}
                                    >
                                        Add Refund
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                </CardBody>
            </Card>

            {props.invoice && (
                <Modal isOpen={addingPayment} toggle={() => setAddingPayment(!addingPayment)} size="lg">
                    <ModalHeader toggle={() => setAddingPayment(!addingPayment)}>
                        {isRefund ? 'Apply Refund' : 'Add Payment'}
                    </ModalHeader>
                    <ModalBody>
                        <InvoicePaymentForm
                            invoice={props.invoice}
                            onSave={() => paymentsUpdated()}
                            onCancel={() => setAddingPayment(false)}
                            isRefund={isRefund}
                        />
                    </ModalBody>
                </Modal>
            )}

            {paymentToConfirm && (
                <Modal isOpen={confirmingPayment} toggle={() => setConfirmingPayment(!confirmingPayment)} size="lg">
                    <ModalHeader toggle={() => setConfirmingPayment(!confirmingPayment)}>Confirm Payment</ModalHeader>
                    <ModalBody>
                        <p>
                            Are you sure you want to confirm this payment? You will not be able to delete it once it is
                            confirmed.
                        </p>
                        <div>
                            <Button
                                type="button"
                                color={'link'}
                                onClick={() => setConfirmingPayment(!confirmingPayment)}
                            >
                                No
                            </Button>
                            <Button type="button" color="primary" onClick={() => confirmPayment(paymentToConfirm)}>
                                Yes
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
            )}
        </>
    );
};

export default InvoicePayments;
