import { AmsPermissionClaimType, authService } from '../../services/Auth';
import {
    Badge,
    Card,
    CardBody,
    Col,
    Form,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
} from 'reactstrap';
import { Button, Input } from '../../components';
import { Controller, useForm } from 'react-hook-form';
import { InvoiceSearchDto, InvoicingDto, PagingDto } from '../../types';
import { InvoiceStatus, InvoiceType, InvoiceTypes } from '../../common/Data';
import { Link, Redirect } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Select, { createFilter } from 'react-select';

import AdministrativeFeesForm from './invoice/AdministrativeFeesForm';
import { Institution } from '../../common/Types';
import InvoiceForm from './invoice/InvoiceForm';
import PageTitle from '../../components/PageTitle';
import { generateYears } from '../../common/Utils';
import { institutionService } from '../../services/InstitutionService';
import { invoiceService } from '../../services/InvoiceService';
import qs from 'qs';
import { toast } from 'react-toastify';

const Invoices = (props) => {
    const [addingCustomInvoice, setAddingCustomInvoice] = useState(false);
    const [applyingLateFees, setApplyingLateFees] = useState(false);
    const [customInvoice, setCustomInvoice] = useState<InvoicingDto>();
    const [viewingInvoices, setViewingInvoices] = useState<InvoicingDto[]>([]);
    const [bouncedInvoices, setBouncedInvoices] = useState<InvoicingDto[]>([]);
    const [institutions, setInstitutions] = useState<Institution[]>([]);
    const [total, setTotal] = useState(0);
    const [reloadBounces, setReloadBounces] = useState<boolean>(true);
    const [paging, setPaging] = useState<PagingDto>({
        pageNumber: 1,
        maxPageSize: null,
        pageSize: 10,
    });

    const [search, setSearch] = useState<InvoiceSearchDto>({
        institutionId: null,
        invoiceNumber: null,
        invoiceType: null,
        paymentType: null,
        year: null,
        status: null,
        ...qs.parse(props.location.search.replace('?', '')),
        paging: paging,
    });

    const { register, handleSubmit, reset, control } = useForm();
    const { register: registerIdSearch, handleSubmit: handleIdSubmit, reset: resetId } = useForm();

    useEffect(() => {
        const getData = () => {
            const urlSearch = qs.parse(props.location.search.replace('?', ''));
            invoiceService
                .getInvoices({
                    ...search,
                    ...urlSearch,
                    paging: paging,
                })
                .then((response) => {
                    setViewingInvoices(response.invoices);
                    setTotal(response.total);
                })
                .catch(() => toast.error('Failed to get invoices'));
        };

        getData();
    }, [setViewingInvoices, paging, search, props.location]);

    useEffect(() => {
        institutionService
            .getInstitutions()
            .then((response) => {
                setInstitutions(response);
                const setInstitution = response.find((x) => x.institutionId.toString() === `${search.institutionId}`);
                if (setInstitution) {
                    reset({ institution: setInstitution });
                }
            })
            .catch(() => toast.error('Failed to get insutitutions'));
    }, [setInstitutions, reset, search.institutionId]);

    // useEffect(() => {
    //     const getData = () => {
    //         if (reloadBounces) {
    //             setReloadBounces(false);
    //             invoiceService
    //                 .getBouncedInvoices()
    //                 .then((response) => setBouncedInvoices(response))
    //                 .catch(() => toast.error('Failed to get bounced invoices'));
    //         }
    //     };
    //     getData();
    // }, [setBouncedInvoices, reloadBounces]);

    const onSubmit = (formData) => {
        const newSearch: InvoiceSearchDto = {
            ...search,
            invoiceNumber: null,
            status: formData.status,
            institutionId: formData.institution ? formData.institution.institutionId : null,
            year: formData.year,
            invoiceType: formData.invoiceType,
        };

        resetId();

        props.history.push(`/invoices?${qs.stringify(newSearch)}`);

        setSearch(newSearch);
    };

    const onIdSubmit = (formData) => {
        const newSearch: InvoiceSearchDto = {
            ...search,
            invoiceNumber: formData.invoiceNumber,
            status: null,
            institutionId: null,
            year: null,
            invoiceType: null,
        };

        reset();

        props.history.push(`/invoices?${qs.stringify(newSearch)}`);

        setSearch(newSearch);
    };

    const clearSearchForm = () => {
        setSearch({
            institutionId: null,
            invoiceType: null,
            invoiceNumber: null,
            paymentType: null,
            year: null,
            status: null,
            paging: paging,
        });
        reset({
            institution: 0,
            status: null,
            year: null,
            invoiceType: null,
        });
        resetId({
            invoiceNumber: null,
        });
    };

    const addInvoice = () => {
        setAddingCustomInvoice(true);
    };

    const dismiss = (invoiceId) => {
        const toastId = toast.info('Dismissing invoice email activity...');

        invoiceService
            .dismissBounces(invoiceId)
            .then(() => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Invoice email activity dismissed',
                });
                setReloadBounces(true);
            })
            .catch(() => {
                toast.error(`Unable to dismiss email activity`);
            });
    };

    const customInvoiceInstitutionChange = (institutionId) => {
        setCustomInvoice({
            id: 0,
            institutionId: +institutionId,
            institutionTitle: institutions.find((x) => x.institutionId === +institutionId)?.title ?? '',
            status: 3,
            invoiceDate: new Date(),
            invoiceNumber: '',
            programIds: '',
            programTitles: '',
            isDeleted: false,
            invoiceType: 2,
        } as InvoicingDto);
    };

    return (
        <>
            {!authService.hasPermission(AmsPermissionClaimType, 'systemadmin') && <Redirect to="/profile" />}

            <PageTitle title="Invoices" />

            <Card>
                <CardBody>
                    <Row>
                        <Col style={{ display: 'flex' }}>
                            <div className="mr-2">
                                <Button color="info" onClick={() => addInvoice()}>
                                    Add Custom Invoice
                                </Button>
                            </div>
                            <div className="mr-2">
                                <Button color="info" onClick={() => setApplyingLateFees(true)}>
                                    Apply Administrative Fees
                                </Button>
                            </div>
                            <div className="mr-2">
                                <Button
                                    color="link"
                                    onClick={() => props.history.push(`/billing/generate-annual-invoices`)}
                                >
                                    Generate Annual Invoices
                                </Button>
                            </div>
                            <div className="mr-2">
                                <Button color="link" onClick={() => props.history.push(`/billing/payments`)}>
                                    Payment Management
                                </Button>
                            </div>
                            <div className="mr-2">
                                <Button color="link" onClick={() => props.history.push(`/billing/unpaid`)}>
                                    Unpaid Invoices
                                </Button>
                            </div>
                            <div>
                                <Button color="link" onClick={() => props.history.push(`/billing/send-invoice-emails`)}>
                                    Send Invoice Emails
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Card>
                <CardBody>
                    <Row className="mb-3">
                        <Col>
                            <Form onSubmit={handleSubmit(onSubmit)} inline>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0" style={{ width: '350px' }}>
                                    <Controller
                                        as={Select}
                                        className="w-100"
                                        placeholder="Select Institution"
                                        options={institutions}
                                        getOptionLabel={(option) => option.title}
                                        getOptionValue={(option) => option.institutionId}
                                        control={control}
                                        name="institution"
                                        isClearable={true}
                                        filterOption={createFilter({ ignoreAccents: false })}
                                    />
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="status" className="mr-sm-2">
                                        Status
                                    </Label>
                                    <Input
                                        name="status"
                                        innerRef={register()}
                                        defaultValue={search.status}
                                        type="select"
                                    >
                                        <option value="">Select Status</option>
                                        <option value={1}>Paid</option>
                                        <option value={2}>Partially Paid</option>
                                        <option value={3}>Unpaid</option>
                                    </Input>
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="year" className="mr-sm-2">
                                        Year
                                    </Label>
                                    <Input name="year" innerRef={register()} defaultValue={search.year} type="select">
                                        <option value="">Select Year</option>
                                        {generateYears().map((ct) => (
                                            <option key={ct} value={ct}>
                                                {ct}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="invoiceType" className="mr-sm-2">
                                        Invoice Type
                                    </Label>
                                    <Input
                                        name="invoiceType"
                                        innerRef={register()}
                                        defaultValue={search.invoiceType}
                                        type="select"
                                    >
                                        <option value="">Select Type</option>
                                        {InvoiceTypes.map((ct) => (
                                            <option key={ct} value={InvoiceType[ct]}>
                                                {ct}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <div className="mr-sm-2">
                                    <Button type="submit">Search</Button>
                                </div>
                                <div className="mr-sm-2">
                                    <Button color="link" onClick={() => clearSearchForm()}>
                                        Clear
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <Label>OR</Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={handleIdSubmit(onIdSubmit)} inline>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="invoiceNumber" className="mr-sm-2">
                                        Invoice Number
                                    </Label>
                                    <Input
                                        type="text"
                                        innerRef={registerIdSearch()}
                                        name="invoiceNumber"
                                        style={{ width: '250px' }}
                                        placeholder="Invoice"
                                        defaultValue={search.invoiceNumber}
                                    />
                                </FormGroup>
                                <div className=" mr-sm-2">
                                    <Button type="submit">Search</Button>
                                </div>
                                <div className=" mr-sm-2">
                                    <Button color="link" onClick={() => clearSearchForm()}>
                                        Clear
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </CardBody>

                <CardBody>
                    <h3>Invoice Results</h3>
                </CardBody>
                <Table>
                    <thead>
                        <tr>
                            <td>Invoice Number</td>
                            <td>Institution</td>
                            <td>Invoice Date</td>
                            <td>Status</td>
                            <td>Fee per Program</td>
                            <td>Administrative Fee</td>
                            <td>Total Fee</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {viewingInvoices.map((vInvoice) => (
                            <tr key={vInvoice.id}>
                                <td>{vInvoice.invoiceNumber}</td>
                                <td>
                                    <a href={`/institution/${vInvoice.institutionId}`}>{vInvoice.institutionTitle}</a>
                                </td>
                                <td>{new Date(vInvoice.invoiceDate || new Date()).toDateString()}</td>
                                <td>
                                    <Badge color={vInvoice.status === 1 ? 'success' : 'warning'}>
                                        {InvoiceStatus[vInvoice.status]}
                                    </Badge>
                                </td>
                                <td>${vInvoice.amount}</td>
                                <td>${vInvoice.lateFee ?? 0}</td>
                                <td>${vInvoice.dueAmount}</td>
                                <td>
                                    <Link to={`/system-admin/invoice/${vInvoice.id}`}>View</Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <CardBody>
                    <h4>Total: {total}</h4>
                    <Pagination size="sm" aria-label="Invoice Navigation">
                        <PaginationItem disabled={paging.pageNumber === 1}>
                            <PaginationLink
                                previous
                                href=""
                                onClick={() => setPaging({ ...paging, pageNumber: paging.pageNumber - 1 })}
                            />
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationLink
                                next
                                href=""
                                onClick={() => setPaging({ ...paging, pageNumber: paging.pageNumber + 1 })}
                            />
                        </PaginationItem>
                    </Pagination>
                </CardBody>
            </Card>

            {/*<Card>*/}
            {/*    <CardBody>*/}
            {/*        <h3>Invoices with bounced emails</h3>*/}
            {/*    </CardBody>*/}
            {/*    <Table>*/}
            {/*        <thead>*/}
            {/*            <tr>*/}
            {/*                <td>Invoice Number</td>*/}
            {/*                <td>Institution</td>*/}
            {/*                <td>Invoice Date</td>*/}
            {/*                <td>Status</td>*/}
            {/*                <td>Amount</td>*/}
            {/*                <td>Administrative Fee</td>*/}
            {/*                <td>Due Amount</td>*/}
            {/*                <td></td>*/}
            {/*                <td></td>*/}
            {/*            </tr>*/}
            {/*        </thead>*/}
            {/*        <tbody>*/}
            {/*            {bouncedInvoices.map((vInvoice) => (*/}
            {/*                <tr key={vInvoice.id}>*/}
            {/*                    <td>{vInvoice.invoiceNumber}</td>*/}
            {/*                    <td>{vInvoice.institutionTitle}</td>*/}
            {/*                    <td>{new Date(vInvoice.invoiceDate || new Date()).toDateString()}</td>*/}
            {/*                    <td>*/}
            {/*                        <Badge color={vInvoice.status === 1 ? 'success' : 'warning'}>*/}
            {/*                            {InvoiceStatus[vInvoice.status]}*/}
            {/*                        </Badge>*/}
            {/*                    </td>*/}
            {/*                    <td>${vInvoice.amount}</td>*/}
            {/*                    <td>${vInvoice.lateFee ?? 0}</td>*/}
            {/*                    <td>${vInvoice.dueAmount}</td>*/}
            {/*                    <td>*/}
            {/*                        <Link*/}
            {/*                            style={{ display: 'inline-block' }}*/}
            {/*                            className={'p-1'}*/}
            {/*                            to={`/system-admin/invoice/${vInvoice.id}`}*/}
            {/*                        >*/}
            {/*                            View*/}
            {/*                        </Link>*/}
            {/*                    </td>*/}
            {/*                    <td>*/}
            {/*                        <Button className={'p-1'} color="link" onClick={() => dismiss(vInvoice.id)}>*/}
            {/*                            Dismiss*/}
            {/*                        </Button>*/}
            {/*                    </td>*/}
            {/*                </tr>*/}
            {/*            ))}*/}
            {/*            {bouncedInvoices.length === 0 && (*/}
            {/*                <tr>*/}
            {/*                    <td colSpan={8}>No bounced emails</td>*/}
            {/*                </tr>*/}
            {/*            )}*/}
            {/*        </tbody>*/}
            {/*    </Table>*/}
            {/*</Card>*/}

            <Modal isOpen={addingCustomInvoice} toggle={() => setAddingCustomInvoice(!addingCustomInvoice)} size="lg">
                <ModalHeader toggle={() => setAddingCustomInvoice(!addingCustomInvoice)}>
                    Add Custom Invoice
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>Select Institution</Label>
                        <Select
                            className="w-100"
                            placeholder="Select Institution"
                            options={institutions}
                            getOptionLabel={(option) => option.title}
                            getOptionValue={(option) => option.institutionId}
                            control={control}
                            name="institutionId"
                            isClearable={true}
                            filterOption={createFilter({ ignoreAccents: false })}
                            onChange={(e) => customInvoiceInstitutionChange(e.institutionId)}
                        />
                    </FormGroup>

                    {customInvoice && (
                        <InvoiceForm
                            invoice={customInvoice}
                            onSave={() => {
                                setSearch(search);
                                setCustomInvoice(undefined);
                                setAddingCustomInvoice(false);
                            }}
                            onCancel={() => setAddingCustomInvoice(false)}
                        />
                    )}
                </ModalBody>
            </Modal>

            <Modal isOpen={applyingLateFees} toggle={() => setApplyingLateFees(!applyingLateFees)} size="lg">
                <ModalHeader toggle={() => setApplyingLateFees(!applyingLateFees)}>
                    Apply Administrative Fees
                </ModalHeader>
                <ModalBody>
                    <AdministrativeFeesForm
                        onSave={() => {
                            setSearch(search);
                            setApplyingLateFees(false);
                        }}
                        onCancel={() => setApplyingLateFees(false)}
                    />
                </ModalBody>
            </Modal>
        </>
    );
};

export default Invoices;
