import * as yup from 'yup';

import { AnnualReportNavigationDirection, AnnualReportProps } from '../AnnualReport';
import { Button, CardFormGroup, Input } from '../../../components';
import { Card, CardBody, CardFooter, CardSubtitle, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { ProgramResource, ProgramResourceDetail } from '../../../common/Types';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import Skeleton from 'react-loading-skeleton';
import { programService } from '../../../services/ProgramService';
import { toast } from 'react-toastify';

const Resources = (props: AnnualReportProps) => {
    const [resources, setResources] = useState<ProgramResourceDetail>();
    const [reload, setReload] = useState<boolean>(true);
    const [formSubmitting, setFormSubmitting] = useState<boolean>(false);

    const programResourceDetailSchema: yup.ObjectSchema<ProgramResourceDetail> = yup
        .object({
            programResourceId: yup
                .number()
                .required()
                .default(resources?.programResourceId || 0),
            programId: yup.number().required().default(props.program.programId),
            activeYear: yup
                .number()
                .required()
                .default(+props.reportYear),
            anyNo: yup.boolean(),
            descriptionAnalysis: yup.string().when('anyNo', {
                is: true,
                then: yup.string().notRequired(),
                otherwise: yup.string().notRequired(),
            }),
            actionPlan: yup.string().when('anyNo', {
                is: true,
                then: yup.string().notRequired(),
                otherwise: yup.string().notRequired(),
            }),
            createdBy: yup.string().notRequired(),
            dateCreated: yup.date().notRequired().nullable().default(new Date()),
            lastEdited: yup.date().notRequired().nullable().default(new Date()),
            editedBy: yup.string().notRequired(),
            programResourceDetailDto: yup
                .array()
                .of(
                    yup
                        .object<ProgramResource>({
                            id: yup.number().required().default(0),
                            programResourceId: yup.number().required().default(0),
                            title: yup.string().required(),
                            requireDetail: yup.boolean().required(),
                            hasChanged: yup
                                .boolean()
                                .when('requireDetail', {
                                    is: true,
                                    then: yup
                                        .boolean()
                                        .typeError('Please select Yes or No.')
                                        .required(`Please select whether or not the resource has changed.`),
                                    otherwise: yup.boolean().notRequired().default(undefined),
                                })
                                .default(undefined),
                            status: yup
                                .boolean()
                                .typeError('Please select Yes or No.')
                                .required('Please select Yes or No.')
                                .defined(),
                            reason: yup.string().when('hasChanged', {
                                is: true,
                                then: yup.string().required('Please indicate what has changed.'),
                                otherwise: yup
                                    .string()
                                    .notRequired()
                                    .transform((val, org) => {
                                        return undefined;
                                    }),
                            }),
                        })
                        .defined(),
                )
                .min(1),
        })
        .defined();

    const [submitDirection, setSubmitDirection] = useState<AnnualReportNavigationDirection>(
        AnnualReportNavigationDirection.Forward,
    );
    const { control, handleSubmit, register, watch, reset, setValue, errors, getValues } = useForm({
        validationSchema: programResourceDetailSchema,
        defaultValues: {} as ProgramResourceDetail,
    });
    const { fields } = useFieldArray({
        control,
        name: 'programResourceDetailDto',
    });

    const answers = watch('programResourceDetailDto') as ProgramResource[];
    const anyNo = answers && answers.filter((a) => a.status?.toString() === 'false').length > 0;

    setValue('anyNo', anyNo);

    const onSubmit = (formData: ProgramResourceDetail) => {
        setFormSubmitting(true);
        const toastId = toast.info('Saving resources...');

        programService
            .saveProgramResources(props.program.programId, formData)
            .then(() => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Resources Saved.',
                });

                if (submitDirection === AnnualReportNavigationDirection.Forward) {
                    props.onSavedSuccessfully(AnnualReportNavigationDirection.Forward);
                }
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Failed to save resources.',
                });
            })
            .finally(() => {
                setFormSubmitting(false);
            });
    };

    const submitWithoutValidation = () => {
        setFormSubmitting(true);
        const formValues = getValues({ nest: true });

        const values = {
            ...programResourceDetailSchema.default(),
            programResourceDetailDto: formValues.programResourceDetailDto,
            actionPlan: formValues.actionPlan,
            descriptionAnalysis: formValues.descriptionAnalysis,
        } as ProgramResourceDetail;

        const toastId = toast.info('Saving resources...');

        programService
            .saveProgramResources(props.program.programId, values)
            .then((results) => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Resources Saved.',
                });

                setReload(true);
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Failed to save resources.',
                });
            })
            .finally(() => {
                setFormSubmitting(false);
            });
    };

    useEffect(() => {
        const getResources = () => {
            if (reload) {
                setReload(false);
                programService
                    .getProgramResources(
                        props.program.programId,
                        props.profession.professionId,
                        props.reportYear ?? new Date().getFullYear(),
                    )
                    .then((response) => {
                        setResources(response);
                        reset(response);
                    })
                    .catch(() => {
                        toast.error('Unable to get settings.');
                    });
            }
        };

        getResources();
    }, [props.program.programId, props.reportYear, setResources, props.profession.professionId, reset, reload]);

    const showReason = (answers, resource): boolean => {
        if (!answers && resource.reason) {
            return true;
        }

        if (answers && !answers.find((a) => a.title === resource.title) && resource.reason) {
            return true;
        }

        if (
            answers &&
            answers.find(
                (a) => a.title === resource.title && (a.hasChanged?.toString() === 'true' || a.hasChanged === true),
            ) !== undefined
        ) {
            return true;
        }

        if (
            answers &&
            answers.find(
                (a) => a.title === resource.title && (a.hasChanged?.toString() === 'true' || a.hasChanged === true),
            ) === undefined &&
            resource.reason
        ) {
            return true;
        }

        return false;
    };

    return (
        <>
            <Col>
                <Card>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <input ref={register()} name={'anyNo'} type={'hidden'} />
                        {(resources?.programResourceId || 0) > 0 && (
                            <>
                                <input ref={register()} name={'programResourceId'} type={'hidden'} />
                                <input ref={register()} name={'createdBy'} type={'hidden'} />
                                <input ref={register()} name={'dateCreated'} type={'hidden'} />
                                <input ref={register()} name={'lastEdited'} type={'hidden'} />
                                <input ref={register()} name={'editedBy'} type={'hidden'} />
                            </>
                        )}
                        <CardBody>
                            <h3 className="card-title">Resources</h3>
                            <CardSubtitle className="mb-3">
                                {`Are the following resources sufficient to ensure the achievement of a program's
                                goals and outcomes?`}
                            </CardSubtitle>
                            {resources ? (
                                <>
                                    <FormGroup>
                                        {fields.map((resource, i) => (
                                            <CardFormGroup key={i}>
                                                <input
                                                    type="hidden"
                                                    name={`programResourceDetailDto[${i}].id`}
                                                    defaultValue={resource.id}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`programResourceDetailDto[${i}].programResourceId`}
                                                    defaultValue={resource.programResourceId}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`programResourceDetailDto[${i}].title`}
                                                    defaultValue={resource.title}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`programResourceDetailDto[${i}].requireDetail`}
                                                    defaultValue={resource.requireDetail}
                                                    ref={register()}
                                                />
                                                <Label
                                                    className="standard-label"
                                                    for={`programResourceDetailDto[${i}].status`}
                                                >
                                                    {resource.title}
                                                </Label>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className="radio-group">
                                                            <input
                                                                type={'hidden'}
                                                                name={`programResourceDetailDto[${i}].status`}
                                                                ref={register()}
                                                                defaultValue={answers[i]?.status?.toString()}
                                                            />
                                                            <FormGroup>
                                                                <Input
                                                                    type="radio"
                                                                    id={`programResourceDetailDto[${i}].statusYes`}
                                                                    value={true}
                                                                    onChange={(e) =>
                                                                        setValue(
                                                                            `programResourceDetailDto[${i}].status`,
                                                                            e.target.value,
                                                                        )
                                                                    }
                                                                    checked={answers[i]?.status?.toString() === 'true'}
                                                                />
                                                                <Label for={`programResourceDetailDto[${i}].statusYes`}>
                                                                    Yes
                                                                </Label>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Input
                                                                    type="radio"
                                                                    id={`programResourceDetailDto[${i}].statusNo`}
                                                                    value={false}
                                                                    onChange={(e) =>
                                                                        setValue(
                                                                            `programResourceDetailDto[${i}].status`,
                                                                            e.target.value,
                                                                        )
                                                                    }
                                                                    checked={answers[i]?.status?.toString() === 'false'}
                                                                />
                                                                <Label for={`programResourceDetailDto[${i}].statusNo`}>
                                                                    No
                                                                </Label>
                                                            </FormGroup>
                                                        </div>
                                                        {errors.programResourceDetailDto &&
                                                            errors.programResourceDetailDto[i] && (
                                                                <Label className={'text-danger'}>
                                                                    {
                                                                        errors.programResourceDetailDto[i]?.status
                                                                            ?.message
                                                                    }
                                                                </Label>
                                                            )}
                                                    </Col>
                                                </Row>
                                                {resource.requireDetail?.toString() === 'true' && (
                                                    <>
                                                        <Label
                                                            className="standard-label"
                                                            for={`programResourceDetailDto[${i}].hasChanged`}
                                                        >
                                                            {`Have these resources changed over the past year?`}
                                                        </Label>
                                                        <Row>
                                                            <Col md={6}>
                                                                <input
                                                                    type={'hidden'}
                                                                    name={`programResourceDetailDto[${i}].hasChanged`}
                                                                    ref={register()}
                                                                    defaultValue={answers[i]?.hasChanged?.toString()}
                                                                />
                                                                <div className="radio-group">
                                                                    <FormGroup>
                                                                        <Input
                                                                            type="radio"
                                                                            id={`programResourceDetailDto[${i}].hasChangedYes`}
                                                                            value={true}
                                                                            onChange={(e) =>
                                                                                setValue(
                                                                                    `programResourceDetailDto[${i}].hasChanged`,
                                                                                    e.target.value,
                                                                                )
                                                                            }
                                                                            checked={
                                                                                answers[i]?.hasChanged?.toString() ===
                                                                                'true'
                                                                            }
                                                                        />
                                                                        <Label
                                                                            for={`programResourceDetailDto[${i}].hasChangedYes`}
                                                                        >
                                                                            Yes
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Input
                                                                            type="radio"
                                                                            id={`programResourceDetailDto[${i}].hasChangedNo`}
                                                                            value={false}
                                                                            onChange={(e) =>
                                                                                setValue(
                                                                                    `programResourceDetailDto[${i}].hasChanged`,
                                                                                    e.target.value,
                                                                                )
                                                                            }
                                                                            checked={
                                                                                answers[i]?.hasChanged?.toString() ===
                                                                                'false'
                                                                            }
                                                                        />
                                                                        <Label
                                                                            for={`programResourceDetailDto[${i}].hasChangedNo`}
                                                                        >
                                                                            No
                                                                        </Label>
                                                                    </FormGroup>
                                                                </div>
                                                                {errors.programResourceDetailDto &&
                                                                    errors.programResourceDetailDto[i] && (
                                                                        <Label className={'text-danger'}>
                                                                            {
                                                                                errors.programResourceDetailDto[i]
                                                                                    ?.hasChanged?.message
                                                                            }
                                                                        </Label>
                                                                    )}
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )}
                                                {showReason(answers, resource) && (
                                                    <FormGroup>
                                                        <Label for={`programResourceDetailDto[${i}].reason`}>
                                                            {`Describe changes to this resource`}
                                                        </Label>
                                                        <Input
                                                            type="textarea"
                                                            name={`programResourceDetailDto[${i}].reason`}
                                                            innerRef={register()}
                                                            defaultValue={resource.reason}
                                                        />
                                                        {errors.programResourceDetailDto &&
                                                            errors.programResourceDetailDto[i] && (
                                                                <Label className={'text-danger'}>
                                                                    {
                                                                        errors.programResourceDetailDto[i]?.reason
                                                                            ?.message
                                                                    }
                                                                </Label>
                                                            )}
                                                    </FormGroup>
                                                )}
                                            </CardFormGroup>
                                        ))}
                                    </FormGroup>
                                    {anyNo === true && (
                                        <FormGroup>
                                            <Row>
                                                <Col className={'d-flex flex-column'}>
                                                    <Label>{`Description/Analysis of each insufficient resource`}</Label>
                                                    <Input
                                                        type="textarea"
                                                        name={`descriptionAnalysis`}
                                                        innerRef={register()}
                                                        rows={10}
                                                    />
                                                    {errors && errors.descriptionAnalysis && (
                                                        <Label className={'text-danger'}>
                                                            {errors.descriptionAnalysis?.message}
                                                        </Label>
                                                    )}
                                                </Col>
                                                <Col className={'d-flex flex-column'}>
                                                    <Label>{`Action Plan for each insufficient resource`}</Label>
                                                    <Input
                                                        type="textarea"
                                                        name={`actionPlan`}
                                                        innerRef={register()}
                                                        rows={10}
                                                    />
                                                    {errors && errors.descriptionAnalysis && (
                                                        <Label className={'text-danger'}>
                                                            {errors.actionPlan?.message}
                                                        </Label>
                                                    )}
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    )}
                                </>
                            ) : (
                                <>
                                    <Card>
                                        <CardBody>
                                            <Skeleton count={2} />
                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <Skeleton count={2} />
                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <Skeleton count={2} />
                                        </CardBody>
                                    </Card>
                                </>
                            )}
                        </CardBody>
                        {props.canEdit && (
                            <CardFooter className={'d-flex justify-content-between'}>
                                <Button
                                    type={'button'}
                                    color={'warning'}
                                    onClick={() => props.onNavigate(AnnualReportNavigationDirection.Back)}
                                >
                                    <i className={'mdi mdi-arrow-left'} />
                                    <span className={'mr-1'}>{`Previous`}</span>
                                </Button>

                                <Button
                                    disabled={formSubmitting}
                                    type={'button'}
                                    color={'primary'}
                                    onClick={() => submitWithoutValidation()}
                                >
                                    <i className={'mdi mdi-content-save'} />
                                    <span className={'ml-1'}>{`Save`}</span>
                                </Button>

                                <Button
                                    disabled={formSubmitting}
                                    type={'submit'}
                                    color={'info'}
                                    onClick={() => setSubmitDirection(AnnualReportNavigationDirection.Forward)}
                                >
                                    <span className={'mr-1'}>{`Save & Continue`}</span>
                                    <i className={'mdi mdi-arrow-right'} />
                                </Button>
                            </CardFooter>
                        )}
                    </Form>
                </Card>
            </Col>
        </>
    );
};

export default Resources;
