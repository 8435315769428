import { AmsPermissionClaimType, authService } from '../../services/Auth';
import {
    Badge,
    ButtonGroup,
    Card,
    CardBody,
    Col,
    Form,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    Row,
    Table,
} from 'reactstrap';
import { Button, Input, PageTitle } from '../../components';
import {
    AccreditationActionsIndexReport,
    GenerateRecommendationListItemCertificate,
    MeetingDateDto,
    ProfessionDto,
    RecommendationListItem,
    RecommendationReportProfessions,
} from '../../common/Types';
import React, { useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';
import NewRecommendationForm from '../../components/NewRecommendationForm';
import { ProgramStatus } from '../../common/Data';
import moment from 'moment';
import { recommendationService } from '../../services/RecommendationService';
import { systemAdministration } from '../../services/SystemAdministration';
import { toast } from 'react-toastify';

enum RecommendationStatus {
    All = 0,
    Unmerged = 1,
    Merged = 2,
}

const RecommendationsList = () => {
    const [meetingDates, setMeetingDates] = useState<MeetingDateDto[]>();
    const [selectedMeetingDate, setSelectedMeetingDate] = useState<MeetingDateDto>();

    const [professions, setProfessions] = useState<ProfessionDto[]>();
    const [selectedProfession, setSelectedProfession] = useState<ProfessionDto>();

    const [statusSearch, setStatusSearch] = useState<RecommendationStatus>(RecommendationStatus.All);

    const [recommendations, setRecommendations] = useState<RecommendationListItem[]>();
    const [certificateEligible, setCertificateEligible] = useState<GenerateRecommendationListItemCertificate[]>();

    const [showNewRecommendationModal, setShowNewRecommendationModal] = useState<boolean>(false);
    const toggleNewRecommendationModal = () => setShowNewRecommendationModal(!showNewRecommendationModal);

    const [showGenerateCertificates, setShowGenerateCertificates] = useState<boolean>(false);
    const toggleGenerateCertificates = () => setShowGenerateCertificates(!showGenerateCertificates);

    const [showAccreditationActionIndex, setShowAccreditationActionIndex] = useState<boolean>(false);
    const toggleAccreditationActionIndex = () => setShowAccreditationActionIndex(!showAccreditationActionIndex);

    const [accreditationActionsIndexReport, setAccreditationActionsIndexReport] = useState<
        AccreditationActionsIndexReport
    >();
    const [selectedReportType, setSelectedReportType] = useState<string>();

    const isRecommendationCertificateEligible = (r: RecommendationListItem): boolean => {
        return (
            (r.statusId || 0) > 0 &&
            r.statusId !== ProgramStatus.Probation &&
            r.effectiveDate !== undefined &&
            r.effectiveDate !== null
        );
    };

    useEffect(() => {
        const loadMeetingDates = () => {
            systemAdministration
                .getMeetingDates()
                .then((dates: MeetingDateDto[]) => {
                    setMeetingDates(
                        dates.sort((a, b) => moment.utc(b.meetingDate).unix() - moment.utc(a.meetingDate).unix()),
                    );

                    const today = new Date();
                    today.setHours(0, 0, 0, 0);

                    const datesTodayOrAfter = dates
                        .filter((md) => md.meetingDate >= today)
                        .sort((a, b) => moment.utc(a.meetingDate).unix() - moment.utc(b.meetingDate).unix());

                    if (datesTodayOrAfter?.length || 0 > 0) {
                        setSelectedMeetingDate(datesTodayOrAfter[0]);
                    } else if ((dates?.length || 0) > 0) {
                        setSelectedMeetingDate(dates[0]);
                    }
                })
                .catch(() => {
                    toast.error('Error loading meeting dates');
                });
        };

        const loadProfessions = () => {
            systemAdministration
                .getProfessions()
                .then((profs: ProfessionDto[]) => {
                    setProfessions(
                        profs.filter((p) => p.isDeleted === undefined || p.isDeleted === null || p.isDeleted === false),
                    );
                })
                .catch(() => {
                    toast.error('Error loading professions');
                });
        };

        loadMeetingDates();
        loadProfessions();
    }, [setMeetingDates, setProfessions]);

    useEffect(() => {
        const searchRecommendations = () => {
            if (selectedMeetingDate) {
                recommendationService
                    .search(
                        selectedMeetingDate.meetingDateId,
                        selectedProfession?.professionId,
                        statusSearch === RecommendationStatus.All
                            ? undefined
                            : statusSearch === RecommendationStatus.Unmerged
                            ? true
                            : false,
                    )
                    .then((results) => {
                        setRecommendations(results);
                        setCertificateEligible(
                            results.map((r) => {
                                return {
                                    ...r,
                                    isSelected: isRecommendationCertificateEligible(r),
                                };
                            }),
                        );
                    })
                    .catch(() => {
                        toast.error('Error searching recommendations');
                    });
            }
        };

        searchRecommendations();
    }, [selectedMeetingDate, selectedProfession, statusSearch]);

    const generateCertificates = () => {
        const certIds =
            certificateEligible
                ?.filter((r) => r.isSelected)
                ?.map((r) => {
                    return r.recommendationId;
                }) || [];

        if (certIds.length > 0) {
            const toastId = toast.info('Generating certificates, this may take a minute...', {
                autoClose: false,
            });

            recommendationService
                .generateCertificates(certIds)
                .then((zip) => {
                    if (zip) {
                        const url = window.URL.createObjectURL(zip);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = `RecommendationCertificates.zip`;
                        a.click();

                        toast.update(toastId, {
                            render: 'Certificate generated successfully.',
                            type: 'success',
                            autoClose: 2500,
                        });

                        toggleGenerateCertificates();
                    }
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error generating certificates',
                        type: 'error',
                        autoClose: 2500,
                    });
                });
        } else {
            toast.warn('No recommendations selected to generate.');
        }
    };
    const handleGenerateReport = () => {
        if (selectedReportType === 'accreditation-action-index') {
            if (selectedMeetingDate) {
                recommendationService
                    .generateAccreditationActionIndexReport(
                        selectedMeetingDate.meetingDateId,
                        selectedProfession?.professionId,
                        statusSearch === RecommendationStatus.All
                            ? undefined
                            : statusSearch === RecommendationStatus.Unmerged
                            ? true
                            : false,
                    )
                    .then((results) => {
                        setAccreditationActionsIndexReport(results);
                        toggleAccreditationActionIndex();
                    })
                    .catch(() => {
                        toast.error('Error retrieving accreditation action index');
                    });
            }
        } else {
            recommendationService
                .downloadReport(
                    selectedReportType,
                    selectedMeetingDate?.meetingDateId,
                    selectedProfession?.professionId,
                    statusSearch === RecommendationStatus.All
                        ? undefined
                        : statusSearch === RecommendationStatus.Unmerged
                        ? true
                        : false,
                )
                .then((report) => {
                    if (report) {
                        const url = window.URL.createObjectURL(report);
                        const a = document.createElement('a');
                        a.href = url;
                        if (selectedReportType?.includes(`contacts`)) {
                            a.download = `${moment(selectedMeetingDate?.meetingDate).format(
                                'MM/DD/YYYY',
                            )}-${selectedReportType}-report.xlsx`;
                        } else {
                            a.download = `${moment(selectedMeetingDate?.meetingDate).format(
                                'MM/DD/YYYY',
                            )}-${selectedReportType}-report.docx`;
                        }

                        a.click();
                    }
                })
                .catch(() => {
                    toast.error(
                        `Unable to generate ${selectedReportType}-report for ${moment(
                            selectedMeetingDate?.meetingDate,
                        ).format('MM/DD/YYYY')}`,
                    );
                });
        }
    };

    const getModifiedBy = (modifiedByString: string): string | undefined => {
        const modifiedInfo = JSON.parse(modifiedByString);

        return modifiedInfo?.name?.trim();
    };

    const citationLetter = (rec: RecommendationListItem) => {
        const toastId = toast.info('Generating award letter, this may take a minute...', {
            autoClose: false,
        });

        recommendationService
            .generateCitationLetter(rec.recommendationId)
            .then((letter) => {
                if (letter) {
                    const url = window.URL.createObjectURL(letter);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `${rec.recommendationId}_${rec.institutionTitle}_${rec.programTitle}_awardletter.docx`;
                    a.click();

                    toast.update(toastId, {
                        render: 'Award Letter generated successfully.',
                        type: 'success',
                        autoClose: 2500,
                    });
                }
            })
            .catch(() => {
                toast.update(toastId, {
                    render: 'Error generating citation letter',
                    type: 'error',
                    autoClose: 2500,
                });
            });
    };
    const generateMeetingSummaryReport = () => {
        if (selectedMeetingDate) {
            recommendationService
                .generateMeetingSummeryReport(selectedMeetingDate.meetingDateId)
                .then((report) => {
                    if (report) {
                        const url = window.URL.createObjectURL(report);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = `${moment(selectedMeetingDate.meetingDate).format(
                            'MM/DD/YYYY',
                        )}-CoAMeetingSummaryReport.docx`;
                        a.click();
                    }
                })
                .catch(() => {
                    toast.error(
                        `Unable to generate meeting summary report for ${moment(selectedMeetingDate.meetingDate).format(
                            'MM/DD/YYYY',
                        )}`,
                    );
                });
        }
    };
    const exportAccreditationActionIndexReport = (excelDoc: boolean) => {
        if (selectedMeetingDate) {
            recommendationService
                .exportAccreditationActionIndexReport(
                    selectedMeetingDate?.meetingDateId,
                    selectedProfession?.professionId,
                    statusSearch === RecommendationStatus.All
                        ? undefined
                        : statusSearch === RecommendationStatus.Unmerged
                        ? true
                        : false,
                    excelDoc,
                )
                .then((report) => {
                    if (report) {
                        const url = window.URL.createObjectURL(report);
                        const a = document.createElement('a');
                        a.href = url;
                        if (excelDoc) {
                            a.download = `${moment(selectedMeetingDate.meetingDate).format(
                                'MM/DD/YYYY',
                            )}-AccreditationActionIndexReport.xlsx`;
                        } else {
                            a.download = `${moment(selectedMeetingDate.meetingDate).format(
                                'MM/DD/YYYY',
                            )}-AccreditationActionIndexReport.docx`;
                        }

                        a.click();
                    }
                })
                .catch(() => {
                    toast.error(
                        `Unable to generate meeting summary report for ${moment(selectedMeetingDate.meetingDate).format(
                            'MM/DD/YYYY',
                        )}`,
                    );
                });
        }
    };

    const canMerge = authService.hasPermission(AmsPermissionClaimType, 'recommendation');

    return (
        <>
            <PageTitle>
                <div className={'d-flex justify-content-between py-3'}>
                    <h4>{`Recommendations`}</h4>
                    <div>
                        <NavLink className={'btn btn-info mr-3'} to={`standards-report`}>
                            <span>{`Standards Report`}</span>
                        </NavLink>

                        <Button type={'button'} color={'primary'} onClick={() => toggleNewRecommendationModal()}>
                            <i className={'mdi mdi-plus'} />
                            <span className={'ml-2'}>{`New Recommendation`}</span>
                        </Button>
                    </div>
                </div>
            </PageTitle>

            <Row>
                <Col>
                    <Card>
                        <CardBody className={'d-flex justify-content-between flex-wrap'}>
                            <FormGroup style={{ flexBasis: `calc(20% - 10px)`, minWidth: '200px' }}>
                                <Label>{`Meeting Date`}</Label>
                                <Input
                                    type={'select'}
                                    value={selectedMeetingDate?.meetingDateId || ''}
                                    onChange={(e) =>
                                        setSelectedMeetingDate(
                                            meetingDates?.find((md) => md.meetingDateId === +e.target.value),
                                        )
                                    }
                                >
                                    <option value={''}>{`Select a Meeting Date`}</option>
                                    {meetingDates &&
                                        meetingDates.map((md) => (
                                            <option key={md.meetingDateId} value={md.meetingDateId}>
                                                {moment(md.meetingDate).format('MM/DD/YYYY')}
                                            </option>
                                        ))}
                                </Input>
                            </FormGroup>
                            <FormGroup style={{ flexBasis: `calc(30% - 10px)`, minWidth: '200px' }}>
                                <Label>{`Profession`}</Label>
                                <Input
                                    type={'select'}
                                    onChange={(e) =>
                                        setSelectedProfession(
                                            professions?.find((p) => p.professionId === +e.target.value),
                                        )
                                    }
                                >
                                    <option value={''}>{`Select a Profession`}</option>
                                    {professions &&
                                        professions.map((p) => (
                                            <option key={p.professionId} value={p.professionId}>
                                                {p.title}
                                            </option>
                                        ))}
                                </Input>
                            </FormGroup>
                            <FormGroup style={{ flexBasis: `calc(50% - 10px)`, minWidth: '300px' }}>
                                <Label>{`Status`}</Label>

                                <div className={'d-flex justify-content-between pt-1'}>
                                    <div className={'custom-control custom-radio'}>
                                        <input
                                            type={'radio'}
                                            id={`radioAll`}
                                            className={'custom-control-input'}
                                            checked={statusSearch === RecommendationStatus.All}
                                            onClick={(e) => setStatusSearch(RecommendationStatus.All)}
                                            value={RecommendationStatus.All}
                                        />
                                        <Label
                                            className={'custom-control-label'}
                                            for={`radioAll`}
                                        >{`All Statuses`}</Label>
                                    </div>
                                    <div className={'custom-control custom-radio'}>
                                        <input
                                            type={'radio'}
                                            id={`radioUnmerged`}
                                            className={'custom-control-input'}
                                            checked={statusSearch === RecommendationStatus.Unmerged}
                                            onClick={(e) => setStatusSearch(RecommendationStatus.Unmerged)}
                                            value={RecommendationStatus.Unmerged}
                                        />
                                        <Label
                                            className={'custom-control-label'}
                                            for={`radioUnmerged`}
                                        >{`New (Unmerged)`}</Label>
                                    </div>
                                    <div className={'custom-control custom-radio'}>
                                        <input
                                            type={'radio'}
                                            id={`radioMerged`}
                                            className={'custom-control-input'}
                                            checked={statusSearch === RecommendationStatus.Merged}
                                            onClick={(e) => setStatusSearch(RecommendationStatus.Merged)}
                                            value={RecommendationStatus.Merged}
                                        />
                                        <Label
                                            className={'custom-control-label'}
                                            for={`radioMerged`}
                                        >{`Accepted (Merged)`}</Label>
                                    </div>
                                </div>
                            </FormGroup>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {canMerge && (
                <Row>
                    <Col>
                        <Card>
                            <CardBody className={'d-flex flex-wrap justify-content-start'}>
                                <FormGroup style={{ flexBasis: `calc(20% - 10px)`, minWidth: '200px' }}>
                                    <Button
                                        type={'button'}
                                        color={'primary'}
                                        onClick={() => toggleGenerateCertificates()}
                                    >
                                        <i className={'mdi mdi-certificate'} />
                                        <span className={'ml-1'}>{`Generate Certificates`}</span>
                                    </Button>
                                </FormGroup>
                                <FormGroup style={{ flexBasis: `calc(30% - 10px)`, minWidth: '200px' }}>
                                    <Button
                                        type={'button'}
                                        color={'primary'}
                                        onClick={() => generateMeetingSummaryReport()}
                                    >
                                        <i className={'mdi mdi-file-document-edit'} />
                                        <span className={'ml-1'}>{`Generate Meeting Summary Report`}</span>
                                    </Button>
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}
            {canMerge && (
                <Row>
                    <Col>
                        <Card>
                            <CardBody className={'d-flex justify-content-between flex-wrap'}>
                                <Form style={{ flexBasis: 'calc(60% - 10px)', minWidth: '100px' }}>
                                    <FormGroup>
                                        <Label>{`Recommendation Reports`}</Label>
                                        <InputGroup>
                                            <Input
                                                type={'select'}
                                                value={selectedReportType}
                                                onChange={(e) => setSelectedReportType(e.target.value)}
                                            >
                                                <option value="">Select...</option>
                                                {/*<option value={'labels'}>Labels</option>*/}
                                                <option value={'accreditation-action-index'}>
                                                    Accreditation Actions Index
                                                </option>
                                                <option value={'consent-board'}>CAAHEP Consent Agenda - Board</option>
                                                <option value={'consent-staff'}>CAAHEP Consent Agenda - Staff</option>
                                                <option value={'action-board'}>CAAHEP Action Agenda - Board</option>
                                                <option value={'action-staff'}>CAAHEP Action Agenda - Staff</option>
                                                <option value={'all-contacts'}>All Institutions Contacts</option>
                                                <option value={'contacts'}>Institutions Contacts</option>
                                                <option value={'transfer-sponsor'}>Transfer of sponsorships</option>
                                            </Input>
                                            <InputGroupAddon addonType={'append'}>
                                                <Button
                                                    color="primary"
                                                    type="button"
                                                    onClick={() => handleGenerateReport()}
                                                >
                                                    {`Generate Report`}
                                                </Button>
                                                <Button color="white" className={'ml-2 btn-link'} type="submit">
                                                    {}
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}

            <Row>
                <Col>
                    <Card>
                        <CardBody className={`p-0 table-responsive`}>
                            <table className={`table table-striped p-0 m-0`}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>{`Institution`}</th>
                                        <th>{`Program`}</th>
                                        <th>{`City`}</th>
                                        <th>{`Meeting Date`}</th>
                                        <th>{`Citations`}</th>
                                        <th>{`Date Submitted`}</th>
                                        <th>{`Last Modified`}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(recommendations?.length || 0) === 0 && (
                                        <tr>
                                            <td colSpan={9}>{`No recommendations for the selected filters.`}</td>
                                        </tr>
                                    )}
                                    {(recommendations?.length || 0) > 0 &&
                                        recommendations?.map((r) => (
                                            <tr key={r.recommendationId}>
                                                <td className={'d-flex flex-column'}>
                                                    {r.statusId && (
                                                        <Badge color={'primary'} className={'mb-1'}>
                                                            {ProgramStatus[r.statusId]}
                                                        </Badge>
                                                    )}
                                                    {r.isMerged === true && <Badge color={'success'}>{`merged`}</Badge>}
                                                    {r.isMerged === false && <Badge color={'warning'}>{`new`}</Badge>}
                                                </td>
                                                <td>
                                                    <span className={'mr-1'}>{r.institutionTitle}</span>
                                                    {r.isNewInstitution && (
                                                        <Badge color={'primary'}>{`New Institution`}</Badge>
                                                    )}
                                                </td>
                                                <td>
                                                    <div className={'d-flex flex-column'}>
                                                        <div>
                                                            <span className={'mr-1'}>{r.programTitle}</span>
                                                            {r.isNewProgram && (
                                                                <Badge
                                                                    className={'mr-1'}
                                                                    color={'primary'}
                                                                >{`New Program`}</Badge>
                                                            )}
                                                            {r.isTransferSponsorship && (
                                                                <Badge
                                                                    color={'primary'}
                                                                >{`Transfer Sponsorship`}</Badge>
                                                            )}
                                                        </div>
                                                        {r.addOnTrackName && (
                                                            <div>
                                                                <span className={'mr-1'}>{r.addOnTrackName}</span>
                                                                <Badge color={'primary'}>{`Add-On Track`}</Badge>
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                                <td>{r.institutionCity}</td>
                                                <td>{moment(r.meetingDate).format('MM/DD/YYYY')}</td>
                                                <td>{r.citationCount}</td>
                                                <td>{moment.utc(r.createdOn).local().format('MM/DD/YYYY')}</td>
                                                <td>
                                                    {r.modifiedOn && (
                                                        <div className={'d-flex flex-column'}>
                                                            <span>
                                                                {moment.utc(r.modifiedOn).local().format('MM/DD/YYYY')}
                                                            </span>
                                                            {r.modifiedByString &&
                                                                getModifiedBy(r.modifiedByString) && (
                                                                    <span className={'font-italic text-muted'}>
                                                                        {`By: ${getModifiedBy(r.modifiedByString)}`}
                                                                    </span>
                                                                )}
                                                        </div>
                                                    )}
                                                </td>
                                                <td>
                                                    <ButtonGroup>
                                                        <NavLink
                                                            to={`/recommendation/${r.recommendationId}`}
                                                            className={`btn btn-info`}
                                                        >
                                                            {r.isMerged === true && (
                                                                <>
                                                                    <i className={'mdi mdi-magnify'} />
                                                                </>
                                                            )}
                                                            {r.isMerged === false && (
                                                                <>
                                                                    <i className={'mdi mdi-pencil'} />
                                                                </>
                                                            )}
                                                        </NavLink>
                                                        {canMerge && (
                                                            <Button
                                                                type={'button'}
                                                                color={'warning'}
                                                                disabled={r.awardLetterTemplate === undefined}
                                                                onClick={() => citationLetter(r)}
                                                                title={`Generate Awards Letter - ${
                                                                    r.awardLetterTemplate || 'No Template Found'
                                                                }`}
                                                            >
                                                                <i className={`mdi mdi-certificate`} />
                                                            </Button>
                                                        )}
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Modal isOpen={showNewRecommendationModal} toggle={toggleNewRecommendationModal} size={'lg'}>
                {selectedMeetingDate && (
                    <NewRecommendationForm
                        onCancel={toggleNewRecommendationModal}
                        meetingDateId={selectedMeetingDate.meetingDateId}
                    />
                )}
            </Modal>

            {certificateEligible && (
                <Modal isOpen={showGenerateCertificates} toggle={toggleGenerateCertificates} centered size={'lg'}>
                    <ModalBody>
                        <h4 className={'card-title'}>{`Generate Certificates`}</h4>
                        {certificateEligible.length === 0 && (
                            <p>
                                {`Based on the selected recommendation search criteria, there are no recommendations eligible for a certificate to be generated.`}
                            </p>
                        )}
                    </ModalBody>
                    {certificateEligible.length > 0 && (
                        <div className={'table-responsive'}>
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>{`Select`}</th>
                                        <th>{`Institution`}</th>
                                        <th>{`Program`}</th>
                                        <th>{`Recommended Status`}</th>
                                        <th>{`Effective Date`}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {certificateEligible.map((c, i) => (
                                        <tr key={c.recommendationId}>
                                            <td className={'text-center'}>
                                                <div className={'custom-control custom-checkbox'}>
                                                    <Input
                                                        type={'checkbox'}
                                                        id={`genSel[${i}]`}
                                                        className={'custom-control-input'}
                                                        checked={c.isSelected}
                                                        disabled={!isRecommendationCertificateEligible(c)}
                                                        onChange={(e) => {
                                                            const newEligibile = [...certificateEligible];
                                                            newEligibile.splice(i, 1, {
                                                                ...c,
                                                                isSelected: e.target.checked,
                                                            });
                                                            setCertificateEligible([...newEligibile]);
                                                        }}
                                                    />
                                                    <Label
                                                        for={`genSel[${i}]`}
                                                        className={'custom-control-label'}
                                                    ></Label>
                                                </div>
                                            </td>
                                            <td>{c.institutionTitle}</td>
                                            <td>{c.programTitle}</td>
                                            <td>{c.statusId && ProgramStatus[c.statusId]}</td>
                                            <td>
                                                {c.effectiveDate
                                                    ? moment(c.effectiveDate).local().format('MM/DD/YYYY')
                                                    : ''}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    )}
                    <ModalFooter>
                        <Button type={'button'} color={'secondary'} onClick={() => toggleGenerateCertificates()}>
                            {`Cancel`}
                        </Button>
                        <Button
                            type={'button'}
                            color={'primary'}
                            className={'ml-2'}
                            disabled={!certificateEligible.find((c) => c.isSelected === true)}
                            onClick={() => generateCertificates()}
                        >
                            {'Generate'}
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
            <Modal isOpen={showAccreditationActionIndex} toggle={toggleAccreditationActionIndex} centered size={'lg'}>
                <ModalBody>
                    <h4 className={'card-title'}>{`Accreditation Actions Index - Meeting Date: ${moment(
                        selectedMeetingDate?.meetingDate,
                    )
                        .local()
                        .format('MM/DD/YYYY')}`}</h4>
                    {(accreditationActionsIndexReport?.recommendedProfessionsGroup?.length || 0) === 0 && (
                        <p>{`Based on the selected criteria, there are no accreditation actions`}</p>
                    )}
                </ModalBody>
                {(accreditationActionsIndexReport?.recommendedProfessionsGroup?.length || 0) > 0 && (
                    <div className={`m-4`}>
                        <div style={{ margin: '1em' }}>
                            <label className={`mr-1`}>Export: </label>
                            <Button
                                onClick={() => exportAccreditationActionIndexReport(false)}
                                className={`btn btn-info mr-1`}
                            >
                                <>
                                    <i className={'mdi mdi-file-word'} />
                                </>
                            </Button>
                            <Button
                                onClick={() => exportAccreditationActionIndexReport(true)}
                                className={`btn btn-info mr-1`}
                            >
                                <>
                                    <i className={'mdi mdi-file-excel'} />
                                </>
                            </Button>
                        </div>
                        {accreditationActionsIndexReport?.recommendedProfessionsGroup?.map((p, i) => (
                            <div key={`pt-${p.professionId}`} className={`p-3`}>
                                <h5>
                                    {p.professionTitle}({p.professionCount})
                                </h5>
                                {p.recommendedProgramsStatusGroup?.map((ps, i) => (
                                    <div key={`pt-${ps.statusId}`} className={`p-3`}>
                                        <h5 className={`ml-5`}>
                                            {ps.statusTitle}({ps.statusCount})
                                        </h5>
                                        {ps.recommendedPrograms?.map((rp, i) => (
                                            <div key={`pt-${rp.recommendationId}`} className={`p-3`}>
                                                <p className={`ml-6`}>
                                                    <span className={`font-weight-bold`}>{rp.institutionTitle}</span> -
                                                    {rp.programTitle}, {rp.institutionCity}, {rp.institutionState} (
                                                    {rp.degrees})
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        ))}
                        <div>
                            <h5 className={`m-4`}>
                                This report contains {accreditationActionsIndexReport?.totalCount} record(s)
                            </h5>
                        </div>
                    </div>
                )}
                <ModalFooter></ModalFooter>
            </Modal>
        </>
    );
};

export default RecommendationsList;
