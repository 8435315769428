import { AnnualReportNavigationDirection, AnnualReportProps } from '../AnnualReport';
import { Button, Input } from '../../../components';
import { Card, CardBody, CardFooter, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import { ProgramDistanceEducation } from '../../../common/Types';
import { programService } from '../../../services/ProgramService';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

const DistanceEducation = (props: AnnualReportProps) => {
    const { handleSubmit, register, errors, watch, setValue, getValues, reset } = useForm();
    const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
    const [distanceEd, setDistanceEd] = useState<ProgramDistanceEducation>();
    const [submitDirection, setSubmitDirection] = useState<AnnualReportNavigationDirection>(
        AnnualReportNavigationDirection.Forward,
    );

    const onSubmit = (formData) => {
        setFormSubmitting(true);
        const toastId = toast.info('Saving Distance Education Detail...');

        programService
            .saveProgramDistanceEducation(props.program.programId, new ProgramDistanceEducation(formData))
            .then(() => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Details Saved.',
                });

                if (submitDirection === AnnualReportNavigationDirection.Forward) {
                    props.onSavedSuccessfully(AnnualReportNavigationDirection.Forward);
                }
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Failed to save Details.',
                });
            })
            .finally(() => {
                setFormSubmitting(false);
            });
    };

    const submitWithoutValidation = () => {
        setFormSubmitting(true);
        const toastId = toast.info('Saving Distance Education Detail...');

        programService
            .saveProgramDistanceEducation(
                props.program.programId,
                new ProgramDistanceEducation(getValues({ nest: true })),
            )
            .then((result) => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Details Saved.',
                });

                reset(result);
                setDistanceEd(result);
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Failed to save Details.',
                });
            })
            .finally(() => {
                setFormSubmitting(false);
            });
    };

    useEffect(() => {
        const getResources = () => {
            if (props.program.programId && +props.reportYear) {
                programService
                    .getProgramDistanceEducation(props.program.programId, props.reportYear ?? new Date().getFullYear())
                    .then((response) => {
                        setDistanceEd(response);
                        reset(response);

                        if (props.hasTabAlreadyBeenSubmitted && !response?.distanceId) {
                            setValue('distanceLearning', false);
                        }
                    })
                    .catch(() => {
                        toast.error('Unable to get settings.');
                    });
            }
        };

        getResources();
    }, [props.program.programId, props.reportYear, setDistanceEd, props.hasTabAlreadyBeenSubmitted, setValue, reset]);

    const watchDistance: boolean = watch('distanceLearning');

    return (
        <Card>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <input
                    name={`distanceId`}
                    type={'hidden'}
                    defaultValue={distanceEd?.distanceId || 0}
                    ref={register()}
                />
                <CardBody>
                    <h3 className="card-title">Distance Education</h3>
                    <FormGroup>
                        <div>
                            <Label>Is any portion of the program offered through distance learning?</Label>
                        </div>
                        <div className={'d-flex'}>
                            <input
                                type="hidden"
                                name={'distanceLearning'}
                                ref={register({ required: 'Please select Yes or No' })}
                            />
                            <div className={'custom-control custom-radio'}>
                                <Input
                                    type="radio"
                                    id={'distanceLearningYes'}
                                    value={true}
                                    onChange={(e) => setValue('distanceLearning', e.target.value)}
                                    checked={watchDistance?.toString() === 'true'}
                                    className={'custom-control-input'}
                                />
                                <Label for={'distanceLearningYes'} className={'custom-control-label'}>{`Yes`}</Label>
                            </div>
                            <div className={'custom-control custom-radio ml-3'}>
                                <Input
                                    type="radio"
                                    id={'distanceLearningNo'}
                                    value={false}
                                    onChange={(e) => setValue('distanceLearning', e.target.value)}
                                    checked={watchDistance?.toString() === 'false'}
                                    className={'custom-control-input'}
                                />
                                <Label for={'distanceLearningNo'} className={'custom-control-label'}>{`No`}</Label>
                            </div>
                        </div>
                        <div>
                            {errors?.distanceLearning?.message && (
                                <Label className={'text-danger'}>{errors.distanceLearning.message}</Label>
                            )}
                        </div>
                    </FormGroup>
                    {watchDistance?.toString() === 'true' && (
                        <>
                            <FormGroup>
                                <Label for="percentageOfTheProgram">
                                    Percentage of the program delivered by distance
                                </Label>
                                <Input
                                    type="number"
                                    name="percentageOfTheProgram"
                                    innerRef={register()}
                                    defaultValue={distanceEd?.percentageOfTheProgram ?? undefined}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>
                                    List of courses that are totally web based (ie no face-to-face instruction)
                                </Label>
                                <Input
                                    type="textarea"
                                    name="webBasedPrograms"
                                    innerRef={register()}
                                    defaultValue={distanceEd?.webBasedPrograms ?? undefined}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>
                                    List of courses that are interactive video conferencing to remote locations
                                </Label>
                                <Input
                                    type="textarea"
                                    name="remoteLocations"
                                    innerRef={register()}
                                    defaultValue={distanceEd?.remoteLocations ?? undefined}
                                />
                            </FormGroup>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Description of Distance Mode</Label>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                name="distanceMode1"
                                                innerRef={register()}
                                                defaultValue={distanceEd?.distanceMode1 ?? undefined}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                name="distanceMode2"
                                                innerRef={register()}
                                                defaultValue={distanceEd?.distanceMode2 ?? undefined}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                name="distanceMode3"
                                                innerRef={register()}
                                                defaultValue={distanceEd?.distanceMode3 ?? undefined}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                name="distanceMode4"
                                                innerRef={register()}
                                                defaultValue={distanceEd?.distanceMode4 ?? undefined}
                                            />
                                        </FormGroup>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>List of courses delivered in that mode</Label>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                name="listOfCourses1"
                                                innerRef={register()}
                                                defaultValue={distanceEd?.listOfCourses1 ?? undefined}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                name="listOfCourses2"
                                                innerRef={register()}
                                                defaultValue={distanceEd?.listOfCourses2 ?? undefined}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                name="listOfCourses3"
                                                innerRef={register()}
                                                defaultValue={distanceEd?.listOfCourses3 ?? undefined}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                name="listOfCourses4"
                                                innerRef={register()}
                                                defaultValue={distanceEd?.listOfCourses4 ?? undefined}
                                            />
                                        </FormGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <input
                                type="hidden"
                                name={`distanceId`}
                                defaultValue={distanceEd?.distanceId ?? 0}
                                ref={register()}
                            />
                            <input
                                type="hidden"
                                name={`programId`}
                                defaultValue={distanceEd?.programId ?? props.program.programId}
                                ref={register()}
                            />
                            <input
                                type="hidden"
                                name={`activeYear`}
                                defaultValue={distanceEd?.activeYear ?? +props.reportYear}
                                ref={register()}
                            />
                            <input
                                type="hidden"
                                name={`createdBy`}
                                defaultValue={distanceEd?.createdBy ?? undefined}
                                ref={register()}
                            />
                            <input
                                type="hidden"
                                name={`dateCreated`}
                                defaultValue={distanceEd?.dateCreated?.toString() ?? undefined}
                                ref={register()}
                            />
                            <input
                                type="hidden"
                                name={`lastEdited`}
                                defaultValue={distanceEd?.lastEdited?.toString() ?? undefined}
                                ref={register()}
                            />
                            <input
                                type="hidden"
                                name={`editedBy`}
                                defaultValue={distanceEd?.editedBy ?? undefined}
                                ref={register()}
                            />
                        </>
                    )}
                </CardBody>
                {props.canEdit && (
                    <CardFooter className={'d-flex justify-content-between'}>
                        <Button
                            type={'button'}
                            color={'warning'}
                            onClick={() => props.onNavigate(AnnualReportNavigationDirection.Back)}
                        >
                            <i className={'mdi mdi-arrow-left'} />
                            <span className={'mr-1'}>{`Previous`}</span>
                        </Button>

                        <Button
                            disabled={formSubmitting}
                            type={'button'}
                            color={'primary'}
                            onClick={() => submitWithoutValidation()}
                        >
                            <i className={'mdi mdi-content-save'} />
                            <span className={'ml-1'}>{`Save`}</span>
                        </Button>

                        <Button
                            disabled={formSubmitting}
                            type={'submit'}
                            color={'info'}
                            onClick={() => setSubmitDirection(AnnualReportNavigationDirection.Forward)}
                        >
                            <span className={'mr-1'}>{`Save & Continue`}</span>
                            <i className={'mdi mdi-arrow-right'} />
                        </Button>
                    </CardFooter>
                )}
            </Form>
        </Card>
    );
};

export default DistanceEducation;
