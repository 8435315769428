import {
    AnnualReport,
    AnnualReportStatus,
    Concentration,
    GeneralSettings,
    Institution,
    Program,
    ProgramWithStatus,
} from '../../common/Types';
import { Button, PageTitle } from '../../components';
import { ButtonGroup, Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { institutionService } from '../../services/InstitutionService';
import { professionService } from '../../services/ProfessionAdministration';
import { programService } from '../../services/ProgramService';
import { toast } from 'react-toastify';
import { annualReportService } from '../../services/AnnualReport';

interface ProgramAnnualReport {
    programId: number;
    year: number;
    annualReportStatus: AnnualReportStatus;
}

const ProgramDirectorDashboard = () => {
    const [programs, setPrograms] = useState<Program[]>();
    const [institutions, setInstitutions] = useState<Institution[]>();
    const [programsNeedingReport, setProgramsNeedingReport] = useState<ProgramWithStatus[]>();
    const [concentrations, setConcentrations] = useState<Concentration[]>();
    const [annualReports, setAnnualReports] = useState<AnnualReport[]>();
    const [settings, setSettings] = useState<GeneralSettings[]>();
    const [programAnnualReports, setProgramAnnualReports] = useState<ProgramAnnualReport[]>([]);

    useEffect(() => {
        programService
            .getAllPrograms()
            .then((results) => {
                setPrograms(results);
            })
            .catch(() => {
                toast.error('Error loading programs');
            });

        institutionService
            .getInstitutions()
            .then((results) => {
                setInstitutions(results);
            })
            .catch(() => {
                toast.error('Error loading institutions');
            });

        programService
            .getProgramsNeedingAnnualReport()
            .then((results) => {
                setProgramsNeedingReport(results);
            })
            .catch(() => {
                toast.error('Error loading program statuses');
            });

        programService
            .getAllAnnualReports()
            .then((results) => {
                setAnnualReports(results);
            })
            .catch(() => {
                toast.error('Error loading program statuses');
            });

        professionService
            .getProfessionConcentrations([])
            .then((response) => {
                setConcentrations(response);
            })
            .catch(() => {
                toast.error('Failed to load concentrations.');
            });
    }, [setPrograms, setInstitutions, setProgramsNeedingReport, setAnnualReports]);

    useEffect(() => {
        if (programs && programs.length > 0) {
            const professionIds = programs.map((p) => p.professionId);

            professionService
                .getGeneralSettingsByProfessionIds(professionIds)
                .then((results) => {
                    setSettings(results);
                })
                .catch(() => {
                    toast.error('Error loading profession settings');
                });
        }
    }, [setSettings, programs]);

    useEffect(() => {
        if (programs && programsNeedingReport && annualReports && settings) {
            const programAnnualReports = programsNeedingReport.map((pnr) => {
                const program = programs.find((p) => p.programId === pnr.programId);
                const currentSettings = settings.find((s) => s.reportingWindow.professionId === program?.professionId);
                const existingAr = annualReports.find(
                    (ar) =>
                        ar.programId === pnr.programId && ar.reportYear === currentSettings?.reportingWindow.activeYear,
                );
                return {
                    year: currentSettings?.reportingWindow.activeYear,
                    programId: pnr.programId,
                    annualReportStatus: existingAr ? existingAr.reportStatus : 'Not Started',
                } as ProgramAnnualReport;
            });

            setProgramAnnualReports(programAnnualReports);
        }
    }, [programs, programsNeedingReport, annualReports, settings, setProgramAnnualReports]);

    const getStatus = (programId: number): AnnualReportStatus => {
        if (programAnnualReports) {
            const par = programAnnualReports.find((p) => p.programId === programId);
            if (par) {
                return par.annualReportStatus;
            }
        }

        return AnnualReportStatus.NotRequired;
    };

    const getAnnualReportColor = (programId: number): string => {
        switch (getStatus(programId)) {
            case AnnualReportStatus.NotStarted:
                return 'danger';
            case AnnualReportStatus.InProgress:
                return 'warning';
            case AnnualReportStatus.SentBack:
                return 'secondary';
            case AnnualReportStatus.Submitted:
                return 'info';
            case AnnualReportStatus.Completed:
                return 'success';
            default:
                return 'dark';
        }
    };

    const getActiveYear = (professionId: number): string => {
        if (settings) {
            const setting = settings.find((s) => s.reportingWindow.professionId === professionId);

            if (setting && setting.reportingWindow && setting.reportingWindow.activeYear) {
                return setting.reportingWindow.activeYear.toString();
            }
        }

        return 'Not Set';
    };

    const getInstitution = (institutionId: number): Institution | undefined => {
        return institutions?.find((i) => i.institutionId === institutionId);
    };

    const getPastAnnualReports = (program: Program): AnnualReport[] => {
        if (annualReports && annualReports.length > 0) {
            const activeYear = getActiveYear(program.professionId);

            if (activeYear) {
                return annualReports
                    .filter((ar) => ar.programId === program.programId && ar.reportYear !== +activeYear)
                    .sort((a, b) => b.reportYear - a.reportYear);
            }
        }

        return [];
    };

    const getPdf = (program: Program, reportYear: number) => {
        const institution = getInstitution(program.institutionId);

        if (!!institution) {
            const toastId = toast.info('Generating PDF, this may take a minute...', {
                autoClose: false,
            });

            annualReportService
                .getPdf(program.professionId, program.programId, reportYear)
                .then((blob) => {
                    if (blob) {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = `Annual Report - ${reportYear} - ${institution.title} - ${program.title}.pdf`;
                        a.click();
                        toast.update(toastId, {
                            type: 'success',
                            render: 'PDF generation complete',
                            autoClose: 2000,
                        });
                    }
                })
                .catch(() => {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'There was an error downloading the PDF',
                        autoClose: 5000,
                    });
                });
        }
    };

    return (
        <>
            <PageTitle title={'My Programs'} />

            {(!programs || !institutions) && (
                <Row className={'mt-3'}>
                    <Col xs={12} md={6}>
                        <Skeleton count={1} />
                        <Skeleton count={1} />
                        <Row className={'mt-3'}>
                            <Col>
                                <Card className={'bg-light'}>
                                    <CardBody>
                                        <Skeleton count={5} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}

            {programs && programs.length > 0 && institutions && institutions.length > 0 && (
                <Row>
                    {programs.map((program) => (
                        <Col key={program.programId} xs={12} md={6} xl={4}>
                            <h4 className={'card-title'}>{program.title}</h4>
                            <h5 className={'card-subtitle text-muted'}>
                                {institutions.find((i) => i.institutionId === program.institutionId)?.title}
                            </h5>

                            {settings && programAnnualReports && (
                                <>
                                    <Row className={'mt-3'}>
                                        <Col xs={12}>
                                            <Card className={`bg-${getAnnualReportColor(program.programId)}`}>
                                                <CardBody>
                                                    <h4 className={'card-title text-white'}>Annual Report</h4>
                                                    <div className={'d-flex flex-wrap justify-content-around'}>
                                                        <div className={'d-flex flex-column align-items-center'}>
                                                            <h2 className={'text-white'}>
                                                                {getActiveYear(program.professionId)}
                                                            </h2>
                                                            <span className={'text-white'}>Active Year</span>
                                                        </div>
                                                        <div className={'d-flex flex-column align-items-center'}>
                                                            <h2 className={'text-white text-center'}>
                                                                {getStatus(program.programId)}
                                                            </h2>
                                                            <span className={'text-white'}>Current Status</span>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                                <CardFooter className={'p-0 border-0'}>
                                                    <ButtonGroup className={'d-flex flex-wrap'}>
                                                        {getStatus(program.programId) !== 'Not Required' && (
                                                            <NavLink
                                                                className={`btn btn-${getAnnualReportColor(
                                                                    program.programId,
                                                                )}`}
                                                                to={`/annual-report/${
                                                                    program.professionId
                                                                }/${getActiveYear(program.professionId)}/program/${
                                                                    program.programId
                                                                }/getting-started`}
                                                            >
                                                                <i className={'mdi mdi-lead-pencil'} />
                                                                <span className={'ml-1'}>Complete Annual Report</span>
                                                            </NavLink>
                                                        )}
                                                    </ButtonGroup>
                                                </CardFooter>
                                            </Card>
                                        </Col>
                                    </Row>
                                    {getPastAnnualReports(program).length > 0 && (
                                        <Row>
                                            {getPastAnnualReports(program).map((ar, i) => (
                                                <Col xs={12} md={6} key={i}>
                                                    <Card className={'bg-white'}>
                                                        <CardBody>
                                                            <h4
                                                                className={'card-title'}
                                                            >{`${ar.reportYear} Report`}</h4>
                                                            <div className={'d-flex justify-content-around'}>
                                                                <div
                                                                    className={'d-flex flex-column align-items-center'}
                                                                >
                                                                    <h4>{ar.reportStatus}</h4>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                        <CardFooter className={'p-0 border-0 bg-white'}>
                                                            <ButtonGroup className={'d-flex'}>
                                                                <Button
                                                                    type={'button'}
                                                                    color={'white'}
                                                                    className={'btn-sm'}
                                                                    onClick={() => getPdf(program, ar.reportYear)}
                                                                >
                                                                    <i className={'mdi mdi-file-download'} />
                                                                    <span className={'ml-1'}>Download PDF</span>
                                                                </Button>
                                                            </ButtonGroup>
                                                        </CardFooter>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    )}
                                </>
                            )}
                        </Col>
                    ))}
                </Row>
            )}
        </>
    );
};

export default ProgramDirectorDashboard;
