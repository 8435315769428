import { Button, FormError, Input, PageTitle } from '../../components';
import { ButtonGroup, Card, CardBody, Col, Form, FormGroup, InputGroup, InputGroupAddon, Modal, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import { Link, Redirect } from 'react-router-dom';
import { ProfessionDto } from '../../common/Types';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { systemAdministration } from '../../services/SystemAdministration';
import { toast } from 'react-toastify';
import NewProfessionForm from '../../components/NewProfessionForm';

const InactiveDiv = styled.div`
    div {
        display: inline-block;
    }

    > div {
        padding-left: 16px;
    }

    div:last-of-type {
        float: right;
    }
`;

const Professions = () => {
    // List of active professions
    const [activeProfessions, setActiveProfessions] = useState<ProfessionDto[]>();
    // Seperate list to track inactive professions
    const [inactiveProfessions, setInactiveProfessions] = useState<ProfessionDto[]>([]);
    // Handles how many professions to drop into each column
    const [profPerCol, setProfPerCol] = useState(0);
    // Search form
    const { register: registerSearch, handleSubmit: handleSearchSubmit } = useForm();
    // Profession edit form
    const {
        register: registerProfessionEdit,
        handleSubmit: handleProfessionSubmit,
        errors: professionErrors,
        control,
        getValues,
        reset,
        setValue,
    } = useForm();
    const { fields, append } = useFieldArray({
        control,
        name: 'professions',
    });
    // Tracking original list to reset ui after filtering
    const [originalProfessions, setOriginalProfessions] = useState<ProfessionDto[]>();
    const [editing, setEditing] = useState(false);

    const [showNewProfession, setShowNewProfession] = useState<boolean>(false);
    const toggleShowNewProfession = () => setShowNewProfession(!showNewProfession);
    const [createdProfessionId, setCreatedProfessionId] = useState<number>();

    const toggleEdit = () => {
        setEditing(!editing);
    };

    useEffect(() => {
        const getProfessions = () => {
            systemAdministration
                .getProfessions()
                .then((response) => {
                    setOriginalProfessions(response);
                    setActiveProfessions(response.filter((x) => !x.isDeleted));
                    setInactiveProfessions(response.filter((x) => x.isDeleted));
                    setProfPerCol(Math.round(response.length / 4));
                    append(response.filter((x) => !x.isDeleted));
                })
                .catch(() => {
                    toast.error('Failed to get Profession list.');
                });
        };

        getProfessions();
    }, [setActiveProfessions, setInactiveProfessions, setOriginalProfessions, setProfPerCol, append]);

    const onSearchSubmit = (search) => {
        if (search.text !== '') {
            setActiveProfessions(
                activeProfessions?.filter(
                    (x) => x.title.toLocaleLowerCase().indexOf(search.text.toLocaleLowerCase()) > -1,
                ),
            );
            setInactiveProfessions(
                inactiveProfessions?.filter(
                    (x) => x.title.toLocaleLowerCase().indexOf(search.text.toLocaleLowerCase()) > -1,
                ),
            );
        } else {
            setActiveProfessions(originalProfessions?.filter((x) => !x.isDeleted));
            setInactiveProfessions(originalProfessions?.filter((x) => x.isDeleted) ?? []);
        }
    };

    const onProfessionSubmit = (formData) => {
        const toastId = toast.info('Saving settings...');

        systemAdministration
            .saveProfessions(formData.professions.map((person) => new ProfessionDto(person)))
            .then((response) => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Settings Saved.',
                });
                setOriginalProfessions(response);
                setActiveProfessions(response.filter((x) => !x.isDeleted));
                setInactiveProfessions(response.filter((x) => x.isDeleted));
                setProfPerCol(Math.round(response.length / 4));
                append(response.filter((x) => !x.isDeleted));
                toggleEdit();
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Failed to save settings.',
                });
            });
    };

    // Sets profession to active or inactive
    const toggleProfession = (i) => {
        const formValues = getValues({ nest: true });
        setValue(`professions[${i}]`, { isDeleted: !JSON.parse(formValues.professions[i].isDeleted) });
        reset(getValues({ nest: true }));
    };

    const ProfFormGroup = (props) => {
        return (
            <FormGroup>
                <input
                    type="hidden"
                    name={`professions[${props.index}].professionId`}
                    defaultValue={props.profession.professionId}
                    ref={registerProfessionEdit()}
                />
                <input
                    type="hidden"
                    name={`professions[${props.index}].webSiteSearchCount`}
                    defaultValue={props.profession.webSiteSearchCount}
                    ref={registerProfessionEdit()}
                />
                <input
                    type="hidden"
                    name={`professions[${props.index}].isDeleted`}
                    defaultValue={props.profession.isDeleted}
                    ref={registerProfessionEdit()}
                />
                <input
                    type="hidden"
                    name={`professions[${props.index}].armsUser`}
                    defaultValue={props.profession.armsUser}
                    ref={registerProfessionEdit()}
                />
                <input
                    type="hidden"
                    name={`professions[${props.index}].coaId`}
                    defaultValue={props.profession.coaId}
                    ref={registerProfessionEdit()}
                />
                {!JSON.parse(props.profession.isDeleted) ? (
                    <>
                        <InputGroup>
                            <Input
                                name={`professions[${props.index}].title`}
                                defaultValue={props.profession.title}
                                innerRef={registerProfessionEdit({ required: true })}
                                readOnly={JSON.parse(props.profession.isDeleted ?? false)}
                            />
                            <InputGroupAddon addonType="append">
                                <Button color={'info'} onClick={() => toggleProfession(props.index)}>
                                    <i className={'remixicon-eye-line'} />
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                        <FormError
                            name={`professions[${props.index}].title`}
                            message="A name is required."
                            errors={professionErrors}
                        />
                    </>
                ) : (
                    <>
                        <InactiveDiv>
                            <div style={{ textDecoration: 'line-through', lineHeight: '38px' }}>
                                {props.profession.title}
                            </div>
                            <ButtonGroup>
                                <Button color={'secondary'} onClick={() => toggleProfession(props.index)}>
                                    <i className={'remixicon-eye-off-line'} />
                                </Button>
                            </ButtonGroup>
                        </InactiveDiv>
                        <input
                            type="hidden"
                            name={`professions[${props.index}].title`}
                            defaultValue={props.profession.title}
                            ref={registerProfessionEdit()}
                        />
                    </>
                )}
            </FormGroup>
        );
    };

    return (
        <>
            {createdProfessionId && <Redirect to={`/system-admin/profession/${createdProfessionId}`} />}
            {!createdProfessionId && (
                <>
                    <PageTitle title="Admin | Profession" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col xs={9}>
                                            <Form onSubmit={handleSearchSubmit(onSearchSubmit)} className={'w-100'}>
                                                <FormGroup row>
                                                    <Col xs={12} sm={9} md={4} lg={3}>
                                                        <Input
                                                            placeholder="Profession Name"
                                                            name="text"
                                                            type="search"
                                                            innerRef={registerSearch()}
                                                        />
                                                    </Col>
                                                    <Col xs={12} className={'d-block d-sm-none mb-2'}></Col>
                                                    <Col>
                                                        <Button type="submit">Search</Button>
                                                    </Col>
                                                </FormGroup>
                                            </Form>
                                        </Col>
                                        <Col xs={3} className={'text-right'}>
                                            <Button color={'primary'} onClick={toggleShowNewProfession}>
                                                <i className={'mdi mdi-plus'} />
                                                <span className={'ml-1'}>{`New Profession`}</span>
                                            </Button>
                                        </Col>
                                    </Row>
                                    {editing ? (
                                        <Form onSubmit={handleProfessionSubmit(onProfessionSubmit)}>
                                            <Row>
                                                <Col lg={3} md={6}>
                                                    {fields
                                                        .map((profession, i) => (
                                                            <ProfFormGroup key={i} profession={profession} index={i} />
                                                        ))
                                                        .slice(0, profPerCol)}
                                                </Col>
                                                <Col lg={3} md={6}>
                                                    {fields
                                                        ?.map((profession, i) => (
                                                            <ProfFormGroup key={i} profession={profession} index={i} />
                                                        ))
                                                        .slice(profPerCol, profPerCol * 2)}
                                                </Col>
                                                <Col lg={3} md={6}>
                                                    {fields
                                                        ?.map((profession, i) => (
                                                            <ProfFormGroup key={i} profession={profession} index={i} />
                                                        ))
                                                        .slice(profPerCol * 2, profPerCol * 3)}
                                                </Col>
                                                <Col lg={3} md={6}>
                                                    {fields
                                                        ?.map((profession, i) => (
                                                            <ProfFormGroup key={i} profession={profession} index={i} />
                                                        ))
                                                        .slice(profPerCol * 3)}
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col>
                                                    <Button type="submit">Save</Button>&nbsp;
                                                    <Button outline onClick={() => toggleEdit()}>
                                                        Cancel
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    ) : (
                                        <>
                                            {activeProfessions ? (
                                                <>
                                                    <Row>
                                                        <Col lg={3} md={6}>
                                                            {activeProfessions
                                                                .slice(0, profPerCol)
                                                                .map((profession, i) => (
                                                                    <div key={i}>
                                                                        <p>
                                                                            <Link
                                                                                to={`/system-admin/profession/${profession.professionId}`}
                                                                            >
                                                                                {profession.title}
                                                                            </Link>
                                                                        </p>
                                                                    </div>
                                                                ))}
                                                        </Col>
                                                        <Col lg={3} md={6}>
                                                            {activeProfessions
                                                                .slice(profPerCol, profPerCol * 2)
                                                                .map((profession, i) => (
                                                                    <div key={i}>
                                                                        <p>
                                                                            <Link
                                                                                to={`/system-admin/profession/${profession.professionId}`}
                                                                            >
                                                                                {profession.title}
                                                                            </Link>
                                                                        </p>
                                                                    </div>
                                                                ))}
                                                        </Col>
                                                        <Col lg={3} md={6}>
                                                            {activeProfessions
                                                                .slice(profPerCol * 2, profPerCol * 3)
                                                                .map((profession, i) => (
                                                                    <div key={i}>
                                                                        <p>
                                                                            <Link
                                                                                to={`/system-admin/profession/${profession.professionId}`}
                                                                            >
                                                                                {profession.title}
                                                                            </Link>
                                                                        </p>
                                                                    </div>
                                                                ))}
                                                        </Col>
                                                        <Col lg={3} md={6}>
                                                            {activeProfessions
                                                                .slice(profPerCol * 3)
                                                                .map((profession, i) => (
                                                                    <div key={i}>
                                                                        <p>
                                                                            <Link
                                                                                to={`/system-admin/profession/${profession.professionId}`}
                                                                            >
                                                                                {profession.title}
                                                                            </Link>
                                                                        </p>
                                                                    </div>
                                                                ))}
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-3">
                                                        <Col>
                                                            <Button onClick={() => toggleEdit()}>Edit</Button>
                                                        </Col>
                                                    </Row>
                                                </>
                                            ) : (
                                                <Row>
                                                    <Col lg={3} md={6}>
                                                        <Skeleton count={5} />
                                                    </Col>
                                                    <Col lg={3} md={6}>
                                                        <Skeleton count={5} />
                                                    </Col>
                                                    <Col lg={3} md={6}>
                                                        <Skeleton count={5} />
                                                    </Col>
                                                    <Col lg={3} md={6}>
                                                        <Skeleton count={5} />
                                                    </Col>
                                                </Row>
                                            )}
                                        </>
                                    )}
                                </CardBody>
                            </Card>
                            {inactiveProfessions.length > 0 && (
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col xs={12}>
                                                <h5>Deleted Professions</h5>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={3} md={6}>
                                                {inactiveProfessions
                                                    ?.filter((x) => x.isDeleted)
                                                    .map((profession, i) => (
                                                        <div key={i}>
                                                            <p>
                                                                <Link
                                                                    to={`/system-admin/profession/${profession.professionId}`}
                                                                >
                                                                    {profession.title}
                                                                </Link>
                                                            </p>
                                                        </div>
                                                    ))
                                                    .slice(0, profPerCol)}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            )}
                        </Col>
                    </Row>
                    <Modal isOpen={showNewProfession} toggle={toggleShowNewProfession}>
                        <NewProfessionForm
                            onCancel={toggleShowNewProfession}
                            onSave={(newProfessionId) => setCreatedProfessionId(newProfessionId)}
                        />
                    </Modal>
                </>
            )}
        </>
    );
};

export default Professions;
