import { AnnualReportNavigationDirection, AnnualReportProps } from '../AnnualReport';
import { Button, PersonnelForm, Ribbon } from '../../../components';
import { Card, CardBody, Col, Modal, Row } from 'reactstrap';
import { PersonnelPersonStatus, PersonnelSettings, ProgramPersonnelDto } from '../../../common/Types';
import React, { useEffect, useState } from 'react';

import { personnelService } from '../../../services/Personnel';
import { professionService } from '../../../services/ProfessionAdministration';
import { toast } from 'react-toastify';

const AdvisoryCommittee = (props: AnnualReportProps) => {
    const [advisoryCommitteeTypes, setAdvisoryCommitteeTypes] = useState<PersonnelSettings[]>([]);
    const [personnelList, setPersonnelList] = useState<ProgramPersonnelDto[]>();
    const [reloadPersonnel, setReloadPersonnel] = useState<boolean>(true);

    const [showModal, setShowModal] = useState<boolean>(false);
    const toggleModal = () => setShowModal(!showModal);
    const [personEdit, setPersonEdit] = useState<ProgramPersonnelDto>();

    useEffect(() => {
        professionService
            .getAdvisoryCommitteeSettings(props.profession.professionId)
            .then((response) => {
                setAdvisoryCommitteeTypes(response);
            })
            .catch(() => {
                toast.error('Error loading advisory committee types.');
            });
    }, [setAdvisoryCommitteeTypes, props]);

    useEffect(() => {
        if (
            props.settings.reportingWindow.activeYear &&
            advisoryCommitteeTypes &&
            advisoryCommitteeTypes.length > 0 &&
            props.reportYear &&
            reloadPersonnel
        ) {
            setReloadPersonnel(false);
            personnelService
                .getArmsProgramPersonnelByProgramIdAndYear(
                    props.program.programId,
                    props.settings.reportingWindow.activeYear || new Date().getFullYear(),
                    +props.reportYear,
                )
                .then((response) => {
                    let personnel = [
                        ...response.filter(
                            (i) =>
                                (!i.programId || (i.programId && i.programId === props.program.programId)) &&
                                advisoryCommitteeTypes.filter((at) => at.personnelTypeId === i.personnelTypeId).length >
                                    0,
                        ),
                    ];

                    if ((response?.length || 0) === 0) {
                        personnelService
                            .getArmsProgramPersonnelByProgramIdAndYear(
                                props.program.programId,
                                (props.settings.reportingWindow.activeYear || new Date().getFullYear()) - 1,
                                +props.reportYear,
                            )
                            .then((response) => {
                                personnel = [
                                    ...response
                                        .filter(
                                            (i) =>
                                                (!i.programId ||
                                                    (i.programId && i.programId === props.program.programId)) &&
                                                advisoryCommitteeTypes.filter(
                                                    (at) => at.personnelTypeId === i.personnelTypeId,
                                                ).length > 0,
                                        )
                                        .map((i) => {
                                            return {
                                                programPersonnelId: 0,
                                                programId: props.program.programId,
                                                personnelTypeId: i.personnelTypeId,
                                                peopleId: 0,
                                                visible: true,
                                                people: { ...i.people, peopleId: 0 },
                                                reportYear: +props.reportYear,
                                                personnelStatus: PersonnelPersonStatus.unknown,
                                            } as ProgramPersonnelDto;
                                        }),
                                ];

                                setPersonnelList([...personnel]);
                            })
                            .catch(() => {
                                toast.error(`Error loading last year's program personnel.`);
                            });
                    } else {
                        setPersonnelList([...personnel]);
                    }
                })
                .catch(() => {
                    toast.error(`Error loading this year's program personnel.`);
                });
        }
    }, [
        advisoryCommitteeTypes,
        props.settings.reportingWindow.activeYear,
        props.program.programId,
        props.reportYear,
        reloadPersonnel,
        setPersonnelList,
    ]);

    const completeForm = () => {
        if (advisoryCommitteeTypes && personnelList) {
            const missingTypes = advisoryCommitteeTypes.filter(
                (pt) =>
                    pt.editable && personnelList.filter((p) => p.personnelTypeId === pt.personnelTypeId).length === 0,
            );

            if (missingTypes && missingTypes.length > 0) {
                toast.error(
                    'Please enter at least one person for each personnel type. If a member type does not apply, please add a person and enter Not Applicable in the name fields.',
                );
                return;
            }

            const unknownTypes = personnelList.filter(
                (p) =>
                    !p.personnelStatus ||
                    // @ts-ignore
                    (p.personnelStatus && p.personnelStatus === PersonnelPersonStatus.unknown),
            );

            if (unknownTypes && unknownTypes.length > 0) {
                toast.error(
                    'Please use the three buttons in the top right corner to indicate the updated status of each non-new person.',
                );
                return;
            }
        }

        props.onSavedSuccessfully(AnnualReportNavigationDirection.Forward);
    };

    const updateStatus = (person: ProgramPersonnelDto, newStatus: PersonnelPersonStatus) => {
        const toastId = toast.info('Updating person status...');

        personnelService
            .saveProgramPersonnel(props.program.programId, props.settings.reportingWindow.activeYear || 0, [
                { ...person, personnelStatus: newStatus },
            ])
            .then((results) => {
                toast.update(toastId, {
                    render: 'Person status updated successfully',
                    type: 'success',
                });
                setReloadPersonnel(true);
            })
            .catch(() => {
                toast.update(toastId, {
                    render: 'Error updating person status',
                    type: 'error',
                });
            });
    };

    const startEdit = (person: ProgramPersonnelDto) => {
        setPersonEdit({
            ...person,
            personnelStatus:
                person.personnelStatus === PersonnelPersonStatus.unknown
                    ? PersonnelPersonStatus.modified
                    : person.personnelStatus,
        });
        toggleModal();
    };

    const removePerson = (person: ProgramPersonnelDto) => {
        const toastId = toast.info('Removing person...');

        personnelService
            .saveProgramPersonnel(props.program.programId, props.settings.reportingWindow.activeYear || 0, [
                { ...person, visible: false },
            ])
            .then((results) => {
                toast.update(toastId, {
                    render: 'Person removed successfully',
                    type: 'success',
                });
                setReloadPersonnel(true);
            })
            .catch(() => {
                toast.update(toastId, {
                    render: 'Error removing person',
                    type: 'error',
                });
            });
    };

    const startNew = (type: PersonnelSettings) => {
        setPersonEdit({
            peopleId: 0,
            personnelTypeId: type.personnelTypeId,
            programId: props.program.programId,
            reportYear: props.settings.reportingWindow.activeYear || new Date().getFullYear(),
            programPersonnelId: 0,
            visible: true,
            people: {
                active: true,
                peopleId: 0,
            },
            personnelStatus: PersonnelPersonStatus.new,
        });
        toggleModal();
    };

    const saved = () => {
        setReloadPersonnel(true);
        toggleModal();
    };

    return (
        <>
            <Row>
                <Col>
                    <h4 className={'card-title'}>Advisory Committee</h4>
                    <h5 className={'card-subtitle text-muted mb-3'}>
                        {`Please add the appropriate members based on the communities of interest below. For Members from the 
                        prior year, please indicate if the Member's information is still current, has changed, or if they 
                        person is no longer at the program.`}
                    </h5>
                </Col>
            </Row>

            {advisoryCommitteeTypes &&
                advisoryCommitteeTypes.length > 0 &&
                advisoryCommitteeTypes.map((type) => (
                    <div key={`pt-${type.personnelTypeId}`} className={`mb-4`}>
                        <h5 className={'card-title'}>{type.title}</h5>

                        {personnelList &&
                            personnelList
                                .filter((p) => p.personnelTypeId === type.personnelTypeId)
                                .sort((a, b) => (a.people?.lastName || '').localeCompare(b.people?.lastName || ''))
                                .map((p) => (
                                    <Card key={p.programPersonnelId}>
                                        <CardBody className={'ribbon-box'}>
                                            <div className={'d-flex justify-content-between'}>
                                                {type.editable &&
                                                    (p.personnelStatus === PersonnelPersonStatus.unknown ||
                                                        !p.personnelStatus) && (
                                                        <Ribbon color={'secondary'}>
                                                            <span>
                                                                Please indicate this person&apos;s current status with
                                                                the buttons to the right
                                                            </span>
                                                        </Ribbon>
                                                    )}
                                                {type.editable && p.personnelStatus === PersonnelPersonStatus.new && (
                                                    <Ribbon color={'info'}>
                                                        <span>New {type.title}</span>
                                                    </Ribbon>
                                                )}
                                                {type.editable && p.personnelStatus === PersonnelPersonStatus.removed && (
                                                    <Ribbon color={'danger'}>
                                                        <span>No Longer in Position</span>
                                                    </Ribbon>
                                                )}
                                                {type.editable && p.personnelStatus === PersonnelPersonStatus.modified && (
                                                    <Ribbon color={'warning'}>
                                                        <span>Updated</span>
                                                    </Ribbon>
                                                )}
                                                {type.editable && p.personnelStatus === PersonnelPersonStatus.same && (
                                                    <Ribbon color={'success'}>
                                                        <span>No Changes Needed</span>
                                                    </Ribbon>
                                                )}
                                                {!type.editable && (
                                                    <Ribbon>
                                                        <span>{`Information incorrect or has changed? Please follow the instructions provided by your
                                                        Committee on Accreditation`}</span>
                                                    </Ribbon>
                                                )}

                                                {type.editable && props.canEdit && (
                                                    <div className={'float-right'}>
                                                        {p.personnelStatus !== PersonnelPersonStatus.modified &&
                                                            p.personnelStatus !== PersonnelPersonStatus.new && (
                                                                <Button
                                                                    type={'button'}
                                                                    color={'success'}
                                                                    className={'btn-icon'}
                                                                    title={'No Changes Needed'}
                                                                    onClick={() =>
                                                                        updateStatus(p, PersonnelPersonStatus.same)
                                                                    }
                                                                >
                                                                    <i className={'mdi mdi-check-outline'} />
                                                                </Button>
                                                            )}
                                                        <Button
                                                            type={'button'}
                                                            color={'warning'}
                                                            className={'btn-icon ml-2'}
                                                            title={'Update Contact Information'}
                                                            onClick={() => startEdit(p)}
                                                        >
                                                            <i className={'mdi mdi-pencil-outline'} />
                                                        </Button>
                                                        {p.personnelStatus !== PersonnelPersonStatus.new && (
                                                            <Button
                                                                type={'button'}
                                                                color={'danger'}
                                                                className={'btn-icon ml-2'}
                                                                title={'Remove Person'}
                                                                onClick={() =>
                                                                    updateStatus(p, PersonnelPersonStatus.removed)
                                                                }
                                                            >
                                                                <i className={'mdi mdi-close-outline'} />
                                                            </Button>
                                                        )}
                                                        {p.personnelStatus === PersonnelPersonStatus.new && (
                                                            <Button
                                                                type={'button'}
                                                                color={'danger'}
                                                                className={'btn-icon ml-2'}
                                                                title={'Remove Person'}
                                                                onClick={() => removePerson(p)}
                                                            >
                                                                <i className={'mdi mdi-close-outline'} />
                                                            </Button>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            {p && p.people && (
                                                <Row>
                                                    <Col md={6}>
                                                        <Row>
                                                            <Col lg={4}>
                                                                <span>Job Title</span>
                                                            </Col>
                                                            <Col>
                                                                <span>{p.people.title}</span>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={4}>
                                                                <span>Name</span>
                                                            </Col>
                                                            <Col>
                                                                {p.people.salutations && (
                                                                    <span className={'mr-1'}>
                                                                        {p.people.salutations}
                                                                    </span>
                                                                )}
                                                                <span>{p.people.firstName}</span>
                                                                <span className={'ml-1'}>{p.people.lastName}</span>
                                                                <span className={'ml-1'}>{p.people.credentials}</span>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={4}>
                                                                <span>Contact Info</span>
                                                            </Col>
                                                            <Col>
                                                                <span>E:</span>
                                                                <span className={'ml-1'}>{p.people.email}</span>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={4}></Col>
                                                            <Col>
                                                                <span>P:</span>
                                                                <span className={'ml-1'}>{p.people.phone}</span>
                                                                {p.people.extension && (
                                                                    <span className={'ml-1'}>
                                                                        x {p.people.extension}
                                                                    </span>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                        {p.people.fax && (
                                                            <Row>
                                                                <Col lg={4}></Col>
                                                                <Col>
                                                                    <span>F:</span>
                                                                    <span className={'ml-1'}>{p.people.fax}</span>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </Col>
                                                    <Col md={6}>
                                                        <Row>
                                                            <Col lg={4}>
                                                                <span>Address</span>
                                                            </Col>
                                                            <Col>
                                                                <span>{p.people.address}</span>
                                                            </Col>
                                                        </Row>
                                                        {p.people.address2 && (
                                                            <Row>
                                                                <Col lg={4}></Col>
                                                                <Col>
                                                                    <span>{p.people.address2}</span>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                        <Row>
                                                            <Col lg={4}></Col>
                                                            <Col>
                                                                <span>{p.people.city}</span>
                                                                <span className={'ml-1'}>{p.people.state},</span>
                                                                <span className={'ml-1'}>{p.people.zip}</span>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={4}></Col>
                                                            <Col>
                                                                <span>{p.people.country}</span>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            )}
                                        </CardBody>
                                    </Card>
                                ))}

                        {type.editable && props.canEdit && (
                            <Row>
                                <Col>
                                    <Button type={'button'} color={'primary'} onClick={() => startNew(type)}>
                                        <i className={'mdi mdi-account-plus'} />
                                        <span className={'ml-2'}>Add {type.title}</span>
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </div>
                ))}

            {props.canEdit && (
                <Card>
                    <CardBody className={'d-flex justify-content-between'}>
                        <Button
                            type={'button'}
                            color={'warning'}
                            onClick={() => props.onNavigate(AnnualReportNavigationDirection.Back)}
                        >
                            <i className={'mdi mdi-arrow-left'} />
                            <span className={'mr-1'}>{`Previous`}</span>
                        </Button>

                        <Button type={'button'} color={'info'} onClick={() => completeForm()}>
                            <span className={'mr-1'}>{`Save & Continue`}</span>
                            <i className={'mdi mdi-arrow-right'} />
                        </Button>
                    </CardBody>
                </Card>
            )}

            {personEdit && (
                <Modal isOpen={showModal} toggle={toggleModal} size={'lg'}>
                    <PersonnelForm person={personEdit} onCancel={toggleModal} onSave={saved} />
                </Modal>
            )}
        </>
    );
};

export default AdvisoryCommittee;
