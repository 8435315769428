import React, { useState } from 'react';
import { HubDocument } from '../common/Types';
import styled from 'styled-components';
import Button from '../components/Button';
import { hubService } from '../services/HubService';
import { BlobServiceClient, BlockBlobClient, ContainerClient } from '@azure/storage-blob';
import { FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { AmsPermissionClaimType, authService } from '../services/Auth';

interface HubDocumentItemProps {
    hubDocument: HubDocument;
    folderSlug: string;
    onDocumentRemoved: (hubDocumentId: number) => void;
}

const DocumentItem = styled.div`
    border: 1px solid #dddddd;
    border-radius: 8px;
    margin: 10px 20px 10px 0px;
    flex-basis: 300px;
    height: 150px;
    display: flex;
    flex-direction: column;

    & div.name {
        flex: 1;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & div.actions {
        display: flex;
        height: 50px;

        & button {
            flex: 1;
            border-radius: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        & button:first-child {
            border-radius: 0px 0px 0px 8px;
        }

        & button:last-child {
            border-radius: 0px 0px 8px 0px;
        }
    }
`;

const HubDocumentItem = (props: HubDocumentItemProps) => {
    const canManageStructure = authService.hasPermission(AmsPermissionClaimType, 'hubmanagestructure');

    const {
        hubDocument: { name, hubDocumentId, documentSlug },
        folderSlug,
        onDocumentRemoved,
    } = props;

    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const toggleShowRemoveModal = () => setShowRemoveModal(!showRemoveModal);

    const [showShareModel, setShowShareModel] = useState<boolean>(false);
    const toggleShowShareModal = () => setShowShareModel(!showShareModel);

    const downloadFile = async () => {
        const uri = await hubService.getSasUri();
        const blobService = new BlobServiceClient(uri);
        const containerClient: ContainerClient = blobService.getContainerClient('hub');
        const blobClient: BlockBlobClient = containerClient.getBlockBlobClient(`${folderSlug}/${name}`);

        if (await blobClient.exists()) {
            const fileBlob = await blobClient.download();
            const blob = await fileBlob.blobBody;

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = name;
            a.click();
        }
    };

    const removeFile = async () => {
        const uri = await hubService.getSasUri();
        const blobService = new BlobServiceClient(uri);
        const containerClient: ContainerClient = blobService.getContainerClient('hub');
        const blobClient: BlockBlobClient = containerClient.getBlockBlobClient(`${folderSlug}/${name}`);

        await blobClient.deleteIfExists();
        await hubService.removeHubDocument({
            hubDocumentId,
        });
        if (onDocumentRemoved) {
            onDocumentRemoved(hubDocumentId);
        }
    };

    return (
        <>
            <DocumentItem>
                <div className={'name'}>
                    <span>{name}</span>
                </div>
                <div className={'actions'}>
                    <Button color={'primary'} onClick={() => downloadFile()}>
                        <i className={`mdi mdi-download mr-2`} />
                        <span>{`Download`}</span>
                    </Button>
                    <Button color={'info'} onClick={() => toggleShowShareModal()}>
                        <i className={`mdi mdi-share mr-2`} />
                        <span>{`Share`}</span>
                    </Button>
                    {canManageStructure && (
                        <Button color={'danger'} onClick={() => toggleShowRemoveModal()}>
                            <i className={`mdi mdi-delete mr-2`} />
                            <span>{`Delete`}</span>
                        </Button>
                    )}
                </div>
            </DocumentItem>

            <Modal isOpen={showRemoveModal} toggle={toggleShowRemoveModal}>
                <ModalHeader>Are you sure?</ModalHeader>
                <ModalBody>
                    <p>{`If you would like to continue, pleae click 'Confirm' below.`}</p>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color={'primary'}
                        className={'mr-2'}
                        type={'button'}
                        onClick={() => removeFile()}
                    >{`Confirm Remove`}</Button>
                    <Button color={'link'} onClick={() => toggleShowRemoveModal()} type={'button'}>{`Cancel`}</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={showShareModel} toggle={toggleShowShareModal} centered size={'lg'}>
                <ModalBody>
                    <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                        <Label>{`Copy the link below in order to share this file publicly`}</Label>
                        <code
                            className={'mt-3'}
                            style={{ fontWeight: 700 }}
                        >{`${window.location.protocol}//${window.location.host}/hub/public/${documentSlug}`}</code>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color={'primary'} onClick={() => toggleShowShareModal()} type={'button'}>{`Done`}</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default HubDocumentItem;
