/* eslint-disable */
import React, { forwardRef, useEffect, useMemo } from 'react';
import { useTable, useRowSelect } from 'react-table';
import { toast } from 'react-toastify';
import { Button, CardBody, Table } from 'reactstrap';
import { invoiceService } from '../../services/InvoiceService';
import { InvoicingDetailDto, InvoicingDto } from '../../types';

interface UnpaidInvoicesTableProps {
    invoices: InvoicingDetailDto[];
    onSave: () => void;
}

interface Props {
    indeterminate?: boolean;
    name: string;
}

const useCombinedRefs = (...refs): React.MutableRefObject<any> => {
    const targetRef = React.useRef();

    React.useEffect(() => {
        refs.forEach((ref) => {
            if (!ref) return;

            if (typeof ref === 'function') {
                ref(targetRef.current);
            } else {
                ref.current = targetRef.current;
            }
        });
    }, [refs]);

    return targetRef;
};

const IndeterminateCheckbox = forwardRef<HTMLInputElement, Props>(
    ({ indeterminate, ...rest }, ref: React.Ref<HTMLInputElement>) => {
        const defaultRef = React.useRef(null);
        const combinedRef = useCombinedRefs(ref, defaultRef);

        useEffect(() => {
            if (combinedRef?.current) {
                combinedRef.current.indeterminate = indeterminate ?? false;
            }
        }, [combinedRef, indeterminate]);

        return (
            <React.Fragment>
                <input type="checkbox" ref={combinedRef} {...rest} />
            </React.Fragment>
        );
    },
);

const UnpaidInvoicesTable = (props: UnpaidInvoicesTableProps) => {
    const columns = useMemo(
        () => [
            { Header: '', accessor: 'id' },
            { Header: 'Invoice Number', accessor: 'invoiceNumber' },
            { Header: 'Institution', accessor: 'institutionTitle' },
            { Header: 'Recipient Name', accessor: (row) => `${row.recipient?.firstName} ${row.recipient?.lastName}` },
            { Header: 'Recipient Email', accessor: 'recipient?.email' },
            { Header: 'Amount Due', accessor: 'dueAmount' },
        ],
        [],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        state: { selectedRowIds },
    } = useTable(
        {
            columns,
            data: useMemo(() => [...props.invoices], []),
        },
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        </div>
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ]);
        },
    );

    const sendEmails = () => {
        const toastId = toast.info('Sending invoices...', { autoClose: false });

        invoiceService
            .emailInvoices(selectedFlatRows.map((x) => x.values.id))
            .then(() => {
                if (toast.isActive(toastId)) {
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Invoices sent',
                    });
                } else {
                    toast.success('Invoices sent');
                }
                props.onSave();
            })
            .catch(() => {
                if (toast.isActive(toastId)) {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Error sending invoices',
                    });
                } else {
                    toast.error('Error sending invoices');
                }
            });
    };
    const setProbation = () => {
        const toastId = toast.info('Starting probation...', { autoClose: false });

        invoiceService
            .setProbation(selectedFlatRows.map((x) => x.values.id))
            .then(() => {
                if (toast.isActive(toastId)) {
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Probation set',
                    });
                } else {
                    toast.success('Probation set');
                }
                props.onSave();
            })
            .catch(() => {
                if (toast.isActive(toastId)) {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Error setting probation',
                    });
                } else {
                    toast.error('Error setting probation');
                }
            });
    };

    return (
        <>
            <CardBody className="mb-3">
                <Button color="primary" onClick={() => sendEmails()}>
                    Send Selected Invoices
                </Button>
                <Button style={{ marginLeft: '8px' }} color="danger" onClick={() => setProbation()}>
                    Start Probation for Selected
                </Button>
            </CardBody>
            <Table {...getTableProps()}>
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </Table>
        </>
    );
};

export default UnpaidInvoicesTable;