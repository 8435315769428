import * as yup from 'yup';

import { AnnualReportNavigationDirection, AnnualReportProps } from '../AnnualReport';
import { Card, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { ProgramSummativeMeasure, ProgramSummativeMeasureDetail } from '../../../common/Types';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import { Button } from '../../../components';
import Skeleton from 'react-loading-skeleton';
import { programService } from '../../../services/ProgramService';
import { toast } from 'react-toastify';

const SummativeMeasures = (props: AnnualReportProps) => {
    const [summativeMeasure, setSummativeMeasure] = useState<ProgramSummativeMeasure>();
    const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
    const [submitDirection, setSubmitDirection] = useState<AnnualReportNavigationDirection>(
        AnnualReportNavigationDirection.Forward,
    );

    const programSummativeMeasureSchema: yup.ObjectSchema<ProgramSummativeMeasure> = yup
        .object({
            summativeMeasuresId: yup.number().required().default(0),
            anyNo: yup.boolean(),
            programId: yup.number().required().default(props.program.programId),
            activeYear: yup
                .number()
                .required()
                .default(+props.reportYear),
            descriptionAnalysis: yup.string().when('anyNo', {
                is: true,
                then: yup.string().notRequired(),
                otherwise: yup.string().notRequired(),
            }),
            actionPlan: yup.string().when('anyNo', {
                is: true,
                then: yup.string().notRequired(),
                otherwise: yup.string().notRequired(),
            }),
            createdBy: yup.string().notRequired(),
            dateCreated: yup.date().notRequired().default(new Date()),
            lastEdited: yup.date().notRequired().default(new Date()),
            editedBy: yup.string().notRequired(),
            createdByString: yup.string().notRequired(),
            editedByString: yup.string().notRequired(),
            programSummativeMeasureDetailDto: yup
                .array()
                .of(
                    yup
                        .object<ProgramSummativeMeasureDetail>({
                            id: yup.number().required().default(0),
                            summativeMeasuresId: yup.number().required().default(0),
                            title: yup.string().required(),
                            status: yup
                                .boolean()
                                .typeError('Please select Yes or No.')
                                .required('Please select Yes or No.'),
                        })
                        .defined(),
                )
                .default([]),
        })
        .defined();

    const {
        register,
        reset,
        control,
        handleSubmit,
        errors,
        watch,
        setValue,
        getValues,
        formState: { isSubmitting },
    } = useForm({
        validationSchema: programSummativeMeasureSchema,
    });
    //setFormSubmitting(isSubmitting);
    const { fields } = useFieldArray({
        control: control,
        name: 'programSummativeMeasureDetailDto',
    });

    useEffect(() => {
        const loadSummativeMeasure = () => {
            programService
                .getSummativeMeasure(props.program.programId, props.profession.professionId, props.reportYear)
                .then((result) => {
                    setSummativeMeasure(result);
                    reset(result);
                })
                .catch(() => {
                    toast.error('Error loading summative measures');
                });
        };

        loadSummativeMeasure();
    }, [props, reset, setSummativeMeasure]);

    const submitForm = async (values) => {
        setFormSubmitting(true);
        const toastId = toast.info('Saving summative measures...');

        programService
            .saveSummativeMeasure(values)
            .then((result) => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Summative Measure saved successfully',
                });

                if (submitDirection === AnnualReportNavigationDirection.Forward) {
                    props.onSavedSuccessfully(AnnualReportNavigationDirection.Forward);
                }
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Error saving Summative Measures.',
                });
            })
            .finally(() => {
                setFormSubmitting(false);
            });
    };

    const submitWithoutValidation = () => {
        setFormSubmitting(true);
        const toastId = toast.info('Saving summative measures...');

        let values = getValues({ nest: true }) as ProgramSummativeMeasure;

        if (summativeMeasure?.summativeMeasuresId === 0) {
            values = {
                ...programSummativeMeasureSchema.default(),
                programSummativeMeasureDetailDto: values.programSummativeMeasureDetailDto,
                actionPlan: values.actionPlan,
                descriptionAnalysis: values.descriptionAnalysis,
            } as ProgramSummativeMeasure;
        }

        programService
            .saveSummativeMeasure(values)
            .then((result) => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Summative Measure saved successfully',
                });

                setSummativeMeasure(result);
                reset(result);
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Error saving Summative Measures.',
                });
            })
            .finally(() => {
                setFormSubmitting(false);
            });
    };
    const answers = watch('programSummativeMeasureDetailDto') as ProgramSummativeMeasureDetail[];
    const anyNo = answers && answers.filter((a) => a.status?.toString() === 'false').length > 0;
    setValue('anyNo', anyNo);

    console.log(errors);

    return (
        <>
            <Row>
                <Col>
                    <h4 className={'card-title'}>Summative Measures</h4>
                    <h5 className={'card-subtitle text-muted mb-3'}>
                        Please enter yes or no for each option below. If you answer No for any item, please provide the
                        necessary analysis and action plan
                    </h5>
                </Col>
            </Row>

            <Form onSubmit={handleSubmit(submitForm)}>
                <input ref={register()} name={'anyNo'} type={'hidden'} />
                {(summativeMeasure?.summativeMeasuresId || 0) > 0 && (
                    <>
                        <input type={'hidden'} name={`summativeMeasuresId`} ref={register()} />
                        <input type={'hidden'} name={`programId`} ref={register()} />
                        <input type={'hidden'} name={`activeYear`} ref={register()} />
                        <input type={'hidden'} name={`createdBy`} ref={register()} />
                        <input type={'hidden'} name={`dateCreated`} ref={register()} />
                        <input type={'hidden'} name={`lastEdited`} ref={register()} />
                        <input type={'hidden'} name={`editedBy`} ref={register()} />
                        <input type={'hidden'} name={`createdByString`} ref={register()} />
                        <input type={'hidden'} name={`editedByString`} ref={register()} />
                    </>
                )}
                <Card>
                    <CardBody>
                        {!summativeMeasure && <Skeleton count={20} />}
                        {summativeMeasure && (
                            <>
                                <Row className={'mb-3'}>
                                    <Col>
                                        <span>Does the program document student competency in the following?</span>
                                    </Col>
                                </Row>
                                <div className={'d-flex flex-column'}>
                                    {fields &&
                                        fields.map((measure, i) => (
                                            <div className={'d-flex justify-content-between mb-2'} key={i}>
                                                <input
                                                    type={'hidden'}
                                                    name={`programSummativeMeasureDetailDto[${i}].title`}
                                                    defaultValue={measure.title}
                                                    ref={register()}
                                                />
                                                <input
                                                    type={'hidden'}
                                                    name={`programSummativeMeasureDetailDto[${i}].id`}
                                                    defaultValue={measure.id}
                                                    ref={register()}
                                                />
                                                <input
                                                    type={'hidden'}
                                                    name={`programSummativeMeasureDetailDto[${i}].summativeMeasuresId`}
                                                    defaultValue={measure.summativeMeasuresId}
                                                    ref={register()}
                                                />
                                                <Label style={{ flexBasis: 'calc(50% - 10px)' }}>{measure.title}</Label>
                                                <div
                                                    style={{ flexBasis: 'calc(50% - 10px)' }}
                                                    className={'d-flex flex-column'}
                                                >
                                                    <div className={'d-flex'}>
                                                        <div className={'custom-control custom-radio mx-3'}>
                                                            <input
                                                                type={'radio'}
                                                                id={`radioYes${i}`}
                                                                name={`programSummativeMeasureDetailDto[${i}].status`}
                                                                className={'custom-control-input'}
                                                                ref={register()}
                                                                defaultChecked={
                                                                    measure.status !== undefined &&
                                                                    measure.status === true
                                                                }
                                                                value={'true'}
                                                            />
                                                            <Label
                                                                className={'custom-control-label'}
                                                                for={`radioYes${i}`}
                                                            >
                                                                Yes
                                                            </Label>
                                                        </div>
                                                        <div className={'custom-control custom-radio mx-3'}>
                                                            <input
                                                                type={'radio'}
                                                                id={`radioNo${i}`}
                                                                name={`programSummativeMeasureDetailDto[${i}].status`}
                                                                className={'custom-control-input'}
                                                                ref={register()}
                                                                defaultChecked={
                                                                    measure.status !== undefined &&
                                                                    measure.status === false
                                                                }
                                                                value={'false'}
                                                            />
                                                            <Label
                                                                className={'custom-control-label'}
                                                                for={`radioNo${i}`}
                                                            >
                                                                No
                                                            </Label>
                                                        </div>
                                                    </div>
                                                    {errors.programSummativeMeasureDetailDto &&
                                                        errors.programSummativeMeasureDetailDto[i] && (
                                                            <Label className={'text-danger'}>
                                                                {
                                                                    errors.programSummativeMeasureDetailDto[i]?.status
                                                                        ?.message
                                                                }
                                                            </Label>
                                                        )}
                                                </div>
                                            </div>
                                        ))}
                                </div>
                                {anyNo && (
                                    <Row>
                                        <Col className={'d-flex justify-content-between'}>
                                            <FormGroup style={{ flexBasis: 'calc(50% - 10px)' }}>
                                                <Label>{`Detailed Analysis of Summative Measure(s)`}</Label>
                                                <Input
                                                    type={'textarea'}
                                                    name={'descriptionAnalysis'}
                                                    innerRef={register()}
                                                    rows={10}
                                                />
                                                {errors.descriptionAnalysis && (
                                                    <Label className={'text-danger'}>
                                                        {errors.descriptionAnalysis.message}
                                                    </Label>
                                                )}
                                            </FormGroup>
                                            <FormGroup style={{ flexBasis: 'calc(50% - 10px)' }}>
                                                <Label>{`Action Plan for Summative Measure(s)`}</Label>
                                                <Input
                                                    type={'textarea'}
                                                    name={'actionPlan'}
                                                    innerRef={register()}
                                                    rows={10}
                                                />
                                                {errors.actionPlan && (
                                                    <Label className={'text-danger'}>{errors.actionPlan.message}</Label>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}
                            </>
                        )}
                    </CardBody>
                    {props.canEdit && (
                        <CardFooter className={'d-flex justify-content-between'}>
                            <Button
                                type={'button'}
                                color={'warning'}
                                onClick={() => props.onNavigate(AnnualReportNavigationDirection.Back)}
                            >
                                <i className={'mdi mdi-arrow-left'} />
                                <span className={'mr-1'}>{`Previous`}</span>
                            </Button>

                            <Button
                                disabled={formSubmitting}
                                type={'button'}
                                color={'primary'}
                                onClick={() => submitWithoutValidation()}
                            >
                                <i className={'mdi mdi-content-save'} />
                                <span className={'ml-1'}>{`Save`}</span>
                            </Button>

                            <Button
                                disabled={formSubmitting}
                                type={'submit'}
                                color={'info'}
                                onClick={() => setSubmitDirection(AnnualReportNavigationDirection.Forward)}
                            >
                                <span className={'mr-1'}>{`Save & Continue`}</span>
                                <i className={'mdi mdi-arrow-right'} />
                            </Button>
                        </CardFooter>
                    )}
                </Card>
            </Form>
        </>
    );
};

export default SummativeMeasures;
