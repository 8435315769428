import { Card, CardBody, Col, Row } from 'reactstrap';
import { InstitutionDetail, InstitutionPeopleDto } from '../../common/Types';
import { PageTitle, RelatedDocumentsList } from '../../components';
import React, { useEffect, useState } from 'react';

import Institution from './Institution';
import InstitutionBillingContact from './institution-tabs/InstitutionBillingContact';
import InstitutionInvoices from './InstitutionInvoices';
import { InstitutionPersonRole } from '../../common/Data';
import InstitutionPersonnel from './institution-tabs/InstitutionPersonnel';
import Notes from '../../components/NotesList';
import Programs from './Programs';
import { institutionService } from '../../services/InstitutionService';
import { personnelService } from '../../services/Personnel';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const InstitutionWidget = styled.div`
    display: flex;
    flex-basis: calc(33% - 10px);

    &.half-widget {
        flex-basis: calc(50% - 10px);
    }

    &.full-widget {
        flex-basis: calc(100% - 10px);
    }
`;

interface InstitutionParams {
    id?: string;
}

const InstitutionOverview = () => {
    const { id } = useParams<InstitutionParams>();
    const [institution, setInstitution] = useState<InstitutionDetail>();
    const [institutionPersonnel, setInstitutionPersonnel] = useState<InstitutionPeopleDto[]>();

    const institutionId = +(id || 0);

    useEffect(() => {
        const getInstitution = () => {
            institutionService
                .getInstitutionDetails(id)
                .then((response) => {
                    setInstitution(response);
                })
                .catch(() => {
                    toast.error('Failed to get institution detail.');
                });
        };

        getInstitution();
    }, [setInstitution, institutionId, id]);

    useEffect(() => {
        if (institution) {
            personnelService
                .getAmsPersonnelByInsitutionId(institution.institutionId)
                .then((results) => {
                    const instPeople = results.filter((r) => r.active === true);
                    const billingContact = instPeople.find(
                        (p) => p.roleId === InstitutionPersonRole['Billing Contact'],
                    );

                    if (billingContact) {
                        setInstitutionPersonnel([...instPeople]);
                    } else {
                        institutionService
                            .getInstitutionBillingContact(institution.institutionId)
                            .then((bc) => {
                                if (bc) {
                                    setInstitutionPersonnel([
                                        ...instPeople,
                                        {
                                            ...bc,
                                            roleId: InstitutionPersonRole['Billing Contact'],
                                        },
                                    ]);
                                } else {
                                    setInstitutionPersonnel([]);
                                }
                            })
                            .catch(() => {
                                toast.error('Error loading billing contact');
                            });
                    }
                })
                .catch(() => {
                    toast.error('Error loading personnel');
                });
        }
    }, [setInstitutionPersonnel, institution]);

    return (
        <>
            <PageTitle title={`Institution | ${institution ? institution.title : ''}`} />
            {institution && (
                <Row>
                    <Col xs={12} md={4}>
                        <Card>
                            <CardBody>
                                <Institution institution={institution} />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={12} md={8}>
                        <Programs
                            programs={institution?.programDto}
                            people={institutionPersonnel}
                            institutionId={+institutionId}
                        />
                    </Col>
                    <Col xs={12} md={6} className={'d-flex flex-wrap justify-content-between'}>
                        <InstitutionWidget className={'full-widget'}>
                            {institutionPersonnel && (
                                <InstitutionPersonnel
                                    institution={institution}
                                    type={InstitutionPersonRole.CEO}
                                    personnel={institutionPersonnel}
                                />
                            )}
                        </InstitutionWidget>
                    </Col>
                    <Col xs={12} md={6} className={'d-flex flex-wrap justify-content-between pr-0'}>
                        <InstitutionWidget className={'full-widget'}>
                            {institutionPersonnel && (
                                <InstitutionBillingContact
                                    institution={institution}
                                    type={InstitutionPersonRole['Billing Contact']}
                                    personnel={institutionPersonnel}
                                />
                            )}
                        </InstitutionWidget>
                    </Col>
                    <Col xs={12} lg={6}>
                        <InstitutionWidget className={'full-widget'}>
                            <RelatedDocumentsList institutionId={institutionId} />
                        </InstitutionWidget>
                    </Col>
                    <Col xs={12} lg={6}>
                        <InstitutionWidget className={'full-widget'}>
                            <Notes institutionId={institutionId} />
                        </InstitutionWidget>
                    </Col>
                    <Col xs={12}>{id && <InstitutionInvoices institutionId={+id} />}</Col>
                </Row>
            )}
        </>
    );
};

export default InstitutionOverview;
