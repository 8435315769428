import React, { useState } from 'react';
import {
    Form,
    FormGroup,
    Label,
    Input,
    Badge,
    Table,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Alert,
} from 'reactstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { InvoicePaymentsDto, InvoicingDetailDto, InvoicingDto } from '../../../types';
import { toast } from 'react-toastify';
import { invoiceService } from '../../../services/InvoiceService';
import * as yup from 'yup';
import moment from 'moment';
import { Button } from '../../../components';
import { PaymentTypes, PaymentType } from '../../../common/Data';

const currencyFormatter = new Intl.NumberFormat(`en-US`, {
    style: `currency`,
    currency: `USD`,
});

interface InvoicePaymentFormProps {
    invoice: InvoicingDetailDto;
    onSave: (payment: InvoicePaymentsDto) => void;
    onCancel: () => void;
    isRefund: boolean;
}

const InvoicePaymentForm = (props: InvoicePaymentFormProps) => {
    const [amountError, setAmountError] = useState<string>(``);

    const formatMoneyOrZero = (amount: number | undefined | null): string => {
        return currencyFormatter.format(amount || 0);
    };

    const amountPaid = (inv: InvoicingDetailDto): number => {
        let total = 0;

        if (!!inv.invoicePayments) {
            inv.invoicePayments
                .filter((ip) => !ip.isDeleted)
                .forEach((ip) => {
                    total += +ip.paymentAmount;
                });
        }

        return total;
    };

    const calculateLateFeePaid = () => {
        let profAmts = 0;

        props.invoice.professionListDeserialized?.forEach((pl) => {
            profAmts += pl.amountPaid || 0;
        });
        return !!profAmts ? amountPaid(props.invoice) - profAmts : props.invoice.lateFee || 0;
    };

    const [lateFeePaid, setLateFeePaid] = useState<number>(calculateLateFeePaid());

    const schema: yup.ObjectSchema<InvoicePaymentsDto> = yup
        .object({
            id: yup.number().nullable(),
            invoiceId: yup.number().required(),
            transactionId: yup.string().nullable(),
            paymentType: yup.number().required(),
            paymentAmount: yup.number().required(),
            convenienceFee: yup.number().notRequired(),
            paidBy: yup.string().nullable().notRequired(),
            referenceNumber: yup.string(),
            checkInformation: yup.string().nullable(),
            rowVersion: yup.string().nullable(),
            firstName: yup.string().nullable(),
            lastName: yup.string().nullable(),
            address: yup.string().nullable(),
            city: yup.string().nullable(),
            state: yup.string().nullable(),
            zipCode: yup.string().nullable(),
            email: yup.string().nullable(),
            createDate: yup.string().nullable(),
            paymentDate: yup.string().required(),
            country: yup.string().nullable(),
            isConfirmed: yup.boolean().nullable(),
            isDeleted: yup.boolean().nullable(),
            isRefund: yup.boolean().nullable(),
            professionIds: yup.array().of(yup.number().required()).notRequired().nullable(),
            receiptUrl: yup.string().nullable(),
            professionAmounts: yup
                .array()
                .of(
                    yup
                        .object<{ id: number; amountPaid?: number }>({
                            id: yup.number().required(),
                            amountPaid: yup.number().notRequired(),
                        })
                        .defined(),
                )
                .notRequired(),
        })
        .defined();

    const {
        handleSubmit,
        register,
        formState: { isSubmitting },
        control,
        errors,
    } = useForm({
        validationSchema: schema,
        defaultValues: {
            paymentDate: moment().format('YYYY-MM-DD'),
            paymentAmount: props.isRefund ? amountPaid(props.invoice) : props.invoice.dueAmount || 0,
            invoiceId: props.invoice.id,
            isRefund: props.isRefund,
            professionAmounts:
                props.invoice?.professionListDeserialized?.map((pl) => {
                    return {
                        id: pl.id,
                        amountPaid:
                            pl?.amountPaid == 0
                                ? props.invoice?.amount || 0
                                : (props.invoice?.amount || 0) - (pl?.amountPaid || 0),
                    };
                }) || [],
        } as InvoicePaymentsDto,
    });

    const { fields } = useFieldArray({
        control,
        name: 'professionAmounts',
    });

    const onSubmit = (values: InvoicePaymentsDto) => {
        setAmountError(``);
        let total = amountPaid(props.invoice);
        if (props.isRefund) {
            total -= +values.paymentAmount;
        } else {
            total += +values.paymentAmount;
        }
        let totalPaid = lateFeePaid;

        if (lateFeePaid > (props.invoice.lateFee || 0) && !props.isRefund) {
            setAmountError(`A late fee has been applied when there is no late fee due.`);
            return;
        }

        values.professionAmounts?.forEach((pl) => {
            totalPaid += +(pl.amountPaid || 0);
        });

        if (totalPaid > values.paymentAmount) {
            setAmountError(
                `More has been allocated (${formatMoneyOrZero(
                    totalPaid,
                )}) than the amount paid towards the invoice (${formatMoneyOrZero(values.paymentAmount)})`,
            );
            return;
        }

        if (totalPaid < values.paymentAmount) {
            setAmountError(
                `Less has been allocated (${formatMoneyOrZero(
                    totalPaid,
                )}) than the amount paid towards the invoice (${formatMoneyOrZero(values.paymentAmount)})`,
            );
            return;
        }
        const payments = values.professionAmounts;
        if (payments) {
            payments.forEach((val, i) => {
                const pl = props.invoice.professionListDeserialized?.find((pl) => pl.id === val.id);
                if (Number(props.invoice.amount) - Number(val.amountPaid) + Number(pl?.amountPaid) < 0) {
                    setAmountError(
                        `More has been allocated (${formatMoneyOrZero(
                            val.amountPaid,
                        )}) than the amount due for the program (${pl?.title})`,
                    );
                    return;
                }
            });
        }

        const toastId = toast.info('Saving Payment...');

        invoiceService
            .saveInvoicePayment({
                ...values,
                professionAmounts: [...(values.professionAmounts || [])],
            })
            .then((response) => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Payment saved successfuly',
                });
                props.onSave(response);
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Error saving Payment',
                });
            });
    };

    const getProgramTitleDetail = (plId: number) => {
        const pl = props.invoice.professionListDeserialized?.find((pl) => pl.id === plId);

        if (!!pl) {
            return (
                <td>
                    <span>{pl.title}</span>
                    {!!pl.concentrationsOrAddOnTracks && (
                        <div className={`mt-1`}>
                            {pl.concentrationsOrAddOnTracks.map((c, i) => (
                                <div key={i} className={`pl-`}>{` - ${c}`}</div>
                            ))}
                        </div>
                    )}
                </td>
            );
        }
    };
    const getProfessionAmountPaid = (plId: number) => {
        const pl = props.invoice.professionListDeserialized?.find((pl) => pl.id === plId);

        if (!!pl) {
            return pl.amountPaid;
        }

        return null;
    };

    const getProfessionAmountDue = (plId: number) => {
        const pl = props.invoice.professionListDeserialized?.find((pl) => pl.id === plId);

        if (!!pl) {
            return pl?.amountPaid == 0
                ? props.invoice?.amount || 0
                : (props.invoice?.amount || 0) - (pl?.amountPaid || 0);
        }

        return null;
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
                <Label for="paymentDate">{props.isRefund ? 'Refund' : 'Payment'} Date</Label>
                <Input name="paymentDate" innerRef={register()} type="date" />
            </FormGroup>
            <FormGroup>
                <Label for="paymentType">{props.isRefund ? 'Refund' : 'Payment'} Type</Label>
                <Input type="select" innerRef={register()} name="paymentType">
                    <option value={''}>Select...</option>
                    {PaymentTypes.filter(props.isRefund ? (x) => x : (x) => x !== 'Credit Card').map((ct) => (
                        <option key={ct} value={PaymentType[ct]}>
                            {ct}
                        </option>
                    ))}
                </Input>
            </FormGroup>
            <FormGroup>
                <Label for="referenceNumber">Reference #</Label>
                <Input name="referenceNumber" innerRef={register()} />
            </FormGroup>
            <FormGroup>
                <Label for="paymentAmount">Payment Amount</Label>
                <Input name="paymentAmount" innerRef={register()} type={`number`} />
            </FormGroup>
            <FormGroup>
                <Label for="alreadyPaid">Amount Already Paid</Label>
                <span className={`ml-3`}>{formatMoneyOrZero(amountPaid(props.invoice))}</span>
            </FormGroup>
            <FormGroup>
                <Label for="paymentAmount">{props.isRefund ? 'Refund' : 'Payment'} Amount</Label>
                <Table className={`mb-3`}>
                    <thead>
                        <tr>
                            <th>Program</th>
                            <th>Amount Due</th>
                            <th>Amount Paid</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((f, i) => (
                            <tr key={f.id}>
                                {getProgramTitleDetail(+(f.id || 0))}
                                <td
                                    style={
                                        Number(getProfessionAmountPaid(+(f.id || 0))) != 0
                                            ? { fontWeight: 'bold' }
                                            : { fontWeight: 'normal' }
                                    }
                                >
                                    {formatMoneyOrZero(getProfessionAmountDue(+(f.id || 0)))}
                                </td>
                                <td>
                                    <InputGroup>
                                        <InputGroupAddon addonType={'prepend'}>
                                            <InputGroupText>$</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={'text'}
                                            name={`professionAmounts.${i}.amountPaid`}
                                            readOnly={getProfessionAmountDue(+(f.id || 0)) == 0 && !props.isRefund}
                                            innerRef={register()}
                                        />
                                        <input type={`hidden`} name={`professionAmounts.${i}.id`} ref={register()} />
                                    </InputGroup>
                                </td>
                            </tr>
                        ))}
                        {Number(props.invoice.lateFee) > 0 || lateFeePaid != 0 || props.isRefund ? (
                            <tr>
                                <td>Administrative Fee</td>
                                <td>{formatMoneyOrZero((props?.invoice?.lateFee || 0) - lateFeePaid)}</td>
                                <td>
                                    <InputGroup>
                                        <InputGroupAddon addonType={'prepend'}>
                                            <InputGroupText>$</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={'text'}
                                            value={lateFeePaid}
                                            //readOnly={((props?.invoice?.lateFee || 0) - lateFeePaid) == 0}
                                            onChange={(e) => setLateFeePaid(+e.target.value)}
                                        />
                                    </InputGroup>
                                </td>
                            </tr>
                        ) : (
                            <tr></tr>
                        )}
                    </tbody>
                </Table>
                {amountError && <Alert className={`alert-danger`}>{amountError}</Alert>}
            </FormGroup>
            <input type="hidden" name="invoiceId" ref={register()} />
            <input type="hidden" name="isRefund" ref={register()} />
            <div>
                <Button type="button" color={'link'} onClick={props.onCancel}>
                    Cancel
                </Button>
                <Button disabled={isSubmitting} type={'submit'} color={'primary'}>{`Save`}</Button>
            </div>
        </Form>
    );
};

export default InvoicePaymentForm;
