import PageTitle from '../../components/PageTitle';
import { Card, CardBody, Col, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GeneralInformationDto } from '../../common/Types';
import { toast } from 'react-toastify';
import { reportService } from '../../services/ReportService';

const StyledSettingListItem = styled.div`
    div {
        display: inline-block;
    }

    div:first-of-type {
    }
`;

const GeneralInformation = (props) => {
    const [generalInfo, setGeneralInfo] = useState<GeneralInformationDto>();

    useEffect(() => {
        const toastId = toast.info('Searching...', {
            autoClose: false,
        });
        const getGeneralInfo = async () => {
            reportService
                .getGeneralInfo()
                .then((response) => {
                    setGeneralInfo(response);
                    toast.update(toastId, {
                        type: 'success',
                        autoClose: 500,
                    });
                })
                .catch(() => {
                    toast.error('Unable to get general info.');
                });
        };

        getGeneralInfo();
    }, [setGeneralInfo]);

    return (
        <>
            <PageTitle title="General Information" />
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <StyledSettingListItem>
                                <div className="font-weight-bold">
                                    Number of Institutions with current accredited programs:
                                </div>
                                <div className="setting-val">{generalInfo?.institutionCount}</div>
                            </StyledSettingListItem>
                            <StyledSettingListItem>
                                <div className="font-weight-bold">Number of accredited programs: </div>
                                <div className="setting-val">{generalInfo?.accreditedProgramCount}</div>
                            </StyledSettingListItem>
                            <StyledSettingListItem>
                                <div className="font-weight-bold">Number of programs that are degree granting: </div>
                                <div className="setting-val">
                                    Associate({generalInfo?.associateCount}) - Baccalaureate(
                                    {generalInfo?.baccalaureateCount}) - Masters({generalInfo?.mastersCount}) - Diploma(
                                    {generalInfo?.diplomaCount}) - Certificate({generalInfo?.certificateCount})
                                </div>
                            </StyledSettingListItem>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default GeneralInformation;
