import * as yup from 'yup';

import { Alert, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { ArmsPermissionClaimType, authService } from '../services/Auth';
import {
    EnrollmentRetention,
    EnrollmentRetentionDetail,
    EnrollmentRetentionReportInfo,
    Satellite,
} from '../common/Types';
import React, { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import Button from './Button';
import moment from 'moment';
import { programService } from '../services/ProgramService';
import styled from 'styled-components';
import { toast } from 'react-toastify';

const FlexFormGroup = styled(FormGroup)`
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > label {
        flex-basis: calc(33% - 10px);
        margin: 0px;
        padding: 0px;
    }
    & > div {
        flex-basis: calc(67% - 10px);
    }
`;

interface EnrollmentRetentionFormProps {
    enrollmentRetention: EnrollmentRetention;
    reportYear: number;
    outcomeMeasure: string;
    satellites: Satellite[];
    otherCohorts: EnrollmentRetention[];
    onFormSaved: (savedRecord?: EnrollmentRetention) => void;
    onFormCanceled: () => void;
}

const EnrollmentRetentionForm = (props: EnrollmentRetentionFormProps) => {
    const today = new Date(moment().format('MM/DD/YYYY'));

    const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
    const toggleShowConfirmDelete = () => setShowConfirmDelete(!showConfirmDelete);

    const enrollmentRetentionSchema: yup.ObjectSchema<EnrollmentRetention> = yup
        .object({
            enrollmentRetentionId: yup.number().required().default(0),
            programId: yup.number().required().default(0),
            enrollmentYear: yup.number().required(),
            satellite: yup.number().nullable(true).notRequired().default(undefined),
            enrollmentDate: yup
                .date()
                .required()
                .max(
                    props.enrollmentRetention.enrollmentRetentionId > 0 ? yup.ref('enrollmentDate') : today,
                    `Enrollment Date (${yup.ref('enrollmentDate')}) must be on or before ${moment(today).format(
                        'MM/DD/YYYY',
                    )}`,
                ),
            onTimeGraduationDate: yup
                .date()
                .required()
                .min(yup.ref('enrollmentDate'), `On Time Graduation Date must be on or after the Enrollment Date`),
            estimatedNumberOfApplicants: yup.number().notRequired().min(0, `Must be greater than or equal to 0`),
            maximumNumberOfStudents: yup.number().notRequired().min(0, `Must be greater than or equal to 0`),
            numberOfStudentsInitiallyEnrolled: yup.number().notRequired().min(0, `Must be greater than or equal to 0`),
            dateCreated: yup.date().notRequired().default(props.enrollmentRetention.dateCreated),
            enrollmentRetentionDetailsDto: yup.array().of(
                yup
                    .object<EnrollmentRetentionDetail>({
                        detailId: yup.number().required().default(0),
                        enrollmentRetentionId: yup.number().required().default(yup.ref('enrollmentRetentionId')),
                        graduationYear: yup.number().required(),
                        graduated: yup.number().notRequired().min(0, `Must be greater than or equal to 0`).default(0),
                        reportYear: yup
                            .number()
                            .required()
                            .default(+props.reportYear),
                    })
                    .defined(),
            ),
            enrollmentRetentionReportInfoDto: yup.array().of(
                yup
                    .object<EnrollmentRetentionReportInfo>({
                        id: yup.number().required().default(0),
                        enrollmentRetentionId: yup.number().required().default(0),
                        reportYear: yup.number().required().default(0),
                        numberAddedToClass: yup.number().notRequired().min(0, `Must be greater than or equal to 0`),
                        attritionDueToNonAcademicReasons: yup
                            .number()
                            .notRequired()
                            .min(0, `Must be greater than or equal to 0`)
                            .max(
                                yup.ref('totalStudentsInClass'),
                                `Must be less than or equal to the total number of students`,
                            ),
                        attritionDueToGeneralEducationCourses: yup
                            .number()
                            .notRequired()
                            .min(0, `Must be greater than or equal to 0`)
                            .max(
                                yup.ref('totalStudentsInClass'),
                                `Must be less than or equal to the total number of students`,
                            ),
                        attritionDueToProfessionalCourses: yup
                            .number()
                            .notRequired()
                            .min(0, `Must be greater than or equal to 0`)
                            .max(
                                yup.ref('totalStudentsInClass'),
                                `Must be less than or equal to the total number of students`,
                            ),
                        totalStudentsInClass: yup
                            .number()
                            .notRequired()
                            .min(0, `Total Students must be greater than or equal to 0`),
                        inProgressOrStoppedOut: yup
                            .number()
                            .notRequired()
                            .min(0, `In Progress or Stopped Out must be greater than or equal to 0`)
                            .max(
                                yup.ref('totalStudentsInClass'),
                                `Total Graduates + In Progress or Stopped Out must be less than or equal to Total Initially Enrolled + Students Added to Class`,
                            ),
                        totalGraduates: yup
                            .number()
                            .notRequired()
                            .min(0, `Graduates must be greater than or equal to 0`)
                            .max(
                                yup.ref('totalStudentsInClass'),
                                `Graduates must be less than or equal to the total number of students`,
                            ),
                        percentAttrition: yup
                            .number()
                            .notRequired()
                            .min(0, `Percent Attrition must be greater than or equal to 0`)
                            .max(100, `Percent Attrition must be less than or equal to 100`),
                        percentRetention: yup
                            .number()
                            .notRequired()
                            .min(0, `Percent Retention must be greater than or equal to 0`)
                            .max(100, `Percent Retention must be less than or equal to 100`),
                        droppedOut: yup.number().notRequired().min(0, `Dropped Out must be greater than or equal to 0`),
                    })
                    .defined(),
            ),
        })
        .defined();

    const {
        handleSubmit,
        register,
        control,
        watch,
        setValue,
        errors,
        formState: { isSubmitting },
    } = useForm({
        defaultValues: { ...props.enrollmentRetention },
        validationSchema: enrollmentRetentionSchema,
    });
    const infoArray = useFieldArray({
        control: control,
        name: 'enrollmentRetentionReportInfoDto',
    });
    const detailArray = useFieldArray({
        control: control,
        name: 'enrollmentRetentionDetailsDto',
    });

    const enrollmentDate = watch('enrollmentDate');
    const onTimeGraduationDate = watch('onTimeGraduationDate');
    const initiallyEnrolled = +watch('numberOfStudentsInitiallyEnrolled', 0);
    const addedToClass = +watch(`enrollmentRetentionReportInfoDto[0].numberAddedToClass`, 0);
    const attritionNonAcademic = +watch(`enrollmentRetentionReportInfoDto[0].attritionDueToNonAcademicReasons`, 0);
    const attritionGenEd = +watch(`enrollmentRetentionReportInfoDto[0].attritionDueToGeneralEducationCourses`, 0);
    const attritionProfessional = +watch(`enrollmentRetentionReportInfoDto[0].attritionDueToProfessionalCourses`, 0);
    const details = watch('enrollmentRetentionDetailsDto') as EnrollmentRetentionDetail[];

    const droppedOut = attritionNonAcademic + attritionGenEd + attritionProfessional;
    const totalInClass = initiallyEnrolled + addedToClass;

    let graduated = 0;

    if (details) {
        details.forEach((val, i) => {
            if (val.graduated) {
                graduated += +val.graduated;
            }
        });
    }

    const inProgress = totalInClass - droppedOut - graduated;

    const attrition = totalInClass > 0 ? (droppedOut / totalInClass) * 100 : 0;
    const retention = totalInClass > 0 ? ((totalInClass - droppedOut) / totalInClass) * 100 : 0;

    setValue(`enrollmentRetentionReportInfoDto[0].totalStudentsInClass`, totalInClass);
    setValue(`enrollmentRetentionReportInfoDto[0].inProgressOrStoppedOut`, inProgress);
    setValue(`enrollmentRetentionReportInfoDto[0].totalGraduates`, graduated);
    setValue(`enrollmentRetentionReportInfoDto[0].percentAttrition`, attrition);
    setValue(`enrollmentRetentionReportInfoDto[0].percentRetention`, retention);

    const isValidEnrollmentDate = (enrollmentDate): boolean => {
        if (!enrollmentDate) {
            return false;
        }

        try {
            if (isNaN(moment(enrollmentDate).unix())) {
                return false;
            }
        } catch (e) {
            return false;
        }

        const year = moment(enrollmentDate).year();
        const currentYear = moment().year();

        return year >= currentYear - 20 && year <= currentYear + 20;
    };

    if (isValidEnrollmentDate(enrollmentDate)) {
        setValue('enrollmentYear', moment(enrollmentDate).year());

        if (detailArray?.fields) {
            detailArray.fields.forEach((f, i) => {
                if (f.graduationYear < moment(enrollmentDate).year() || f.graduationYear > today.getFullYear()) {
                    detailArray.remove();
                }
            });
        }

        for (let i = moment(enrollmentDate).year(); i <= today.getFullYear(); i++) {
            const existingDetail = detailArray.fields.find((f) => f.graduationYear === i);
            const existingRecord = props.enrollmentRetention.enrollmentRetentionDetailsDto?.find(
                (er) => er.graduationYear === i,
            );

            if (!existingDetail) {
                if (existingRecord) {
                    detailArray.append({
                        ...existingRecord,
                    });
                } else {
                    detailArray.append({
                        detailId: 0,
                        enrollmentRetentionId: props.enrollmentRetention.enrollmentRetentionId,
                        graduationYear: i,
                        reportYear: +props.reportYear,
                        graduated: 0,
                    } as EnrollmentRetentionDetail);
                }
            }
        }
    } else {
        setValue('enrollmentYear', -1);
    }

    const saveForm = (values: EnrollmentRetention): Promise<void> => {
        return new Promise((res) => {
            const toastId = toast.info('Saving cohort...');

            programService
                .saveEnrollmentRetentionByEnrollmentRetentionId(values)
                .then((result) => {
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Cohort saved successfully',
                    });

                    props.onFormSaved(result);
                })
                .catch(() => {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Error saving cohort.',
                    });
                })
                .finally(() => {
                    res();
                });
        });
    };

    const disableDates = (): boolean => {
        if (props.enrollmentRetention.enrollmentRetentionId <= 0) {
            return false;
        }

        if (
            props.enrollmentRetention.enrollmentRetentionId > 0 &&
            authService.hasPermission(ArmsPermissionClaimType, 'admin')
        ) {
            return false;
        }

        return true;
    };

    const deleteCohort = () => {
        const toastId = toast.info('Deleting Cohort...');

        programService
            .cohortDeleteByReportYear(props.enrollmentRetention.enrollmentRetentionId, props.reportYear)
            .then((er) => {
                toast.update(toastId, {
                    render: 'Cohort successfully removed',
                    type: 'success',
                });
                props.onFormSaved();
            })
            .catch(() => {
                toast.update(toastId, {
                    render: 'Error deleting cohort',
                    type: 'error',
                });
            });
    };

    console.log(errors);

    const showEnrollmentDateWarning = (): boolean => {
        return (
            props.otherCohorts.filter((c) => {
                return (
                    moment.utc(c.enrollmentDate).format('MM/DD/YYYY') ===
                    moment.utc(enrollmentDate).format('MM/DD/YYYY')
                );
            }).length > 0
        );
    };

    const showGraduationDateWarning = (): boolean => {
        return (
            props.otherCohorts.filter((c) => {
                return (
                    moment.utc(c.onTimeGraduationDate).format('MM/DD/YYYY') ===
                    moment.utc(onTimeGraduationDate).format('MM/DD/YYYY')
                );
            }).length > 0
        );
    };

    return (
        <Form onSubmit={handleSubmit(saveForm)}>
            <input type={'hidden'} ref={register()} name={'enrollmentRetentionId'} />
            <input type={'hidden'} ref={register()} name={'programId'} />
            <input type={'hidden'} ref={register()} name={'dateCreated'} />
            <input type={'hidden'} ref={register()} name={'enrollmentYear'} />
            <input type={'hidden'} ref={register()} name={'enrollmentDate'} />
            <input type={'hidden'} ref={register()} name={'onTimeGraduationDate'} />

            {props.satellites.length > 0 && (
                <FlexFormGroup>
                    <Label>Satellite:</Label>
                    <div className={'d-flex flex-column'}>
                        <Input type={'select'} name={'satelliteId'} innerRef={register()}>
                            <option value={''}>{`Home Campus`}</option>
                            {props.satellites.map((s) => (
                                <option key={s.satelliteId} value={s.satelliteId}>
                                    {`${s.title} (${s.city}, ${s.state})`}
                                </option>
                            ))}
                        </Input>
                        {errors && errors.satelliteId && (
                            <Row>
                                <Col>
                                    <span className={'text-danger'}>{errors.satelliteId.message}</span>
                                </Col>
                            </Row>
                        )}
                    </div>
                </FlexFormGroup>
            )}

            <FlexFormGroup>
                <Label>Enrollment Date:</Label>
                <div className={'d-flex flex-column'}>
                    <Input
                        defaultValue={
                            props.enrollmentRetention.enrollmentDate
                                ? moment(props.enrollmentRetention.enrollmentDate).format('YYYY-MM-DD')
                                : ''
                        }
                        disabled={disableDates()}
                        onChange={(e) =>
                            setValue('enrollmentDate', new Date(moment(e.target.value).format('MM/DD/YYYY')))
                        }
                        type={'date'}
                    />
                    {errors && errors.enrollmentDate && (
                        <Row>
                            <Col>
                                <span className={'text-danger'}>{errors.enrollmentDate.message}</span>
                            </Col>
                        </Row>
                    )}
                </div>
            </FlexFormGroup>

            <FlexFormGroup>
                <Label>On-Time Graduation Date:</Label>
                <div className={'d-flex flex-column'}>
                    <Input
                        defaultValue={
                            props.enrollmentRetention.onTimeGraduationDate
                                ? moment(props.enrollmentRetention.onTimeGraduationDate).format('YYYY-MM-DD')
                                : ''
                        }
                        disabled={disableDates()}
                        onChange={(e) =>
                            setValue('onTimeGraduationDate', new Date(moment(e.target.value).format('MM/DD/YYYY')))
                        }
                        type={'date'}
                    />
                    {errors && errors.onTimeGraduationDate && (
                        <Row>
                            <Col>
                                <span className={'text-danger'}>{errors.onTimeGraduationDate.message}</span>
                            </Col>
                        </Row>
                    )}
                </div>
            </FlexFormGroup>

            <FlexFormGroup>
                <Label>Estimated Number of Applicants:</Label>
                <div className={'d-flex flex-column'}>
                    <Input name={'estimatedNumberOfApplicants'} innerRef={register()} type={'number'} />
                    {errors && errors.estimatedNumberOfApplicants && (
                        <Row>
                            <Col>
                                <span className={'text-danger'}>{errors.estimatedNumberOfApplicants.message}</span>
                            </Col>
                        </Row>
                    )}
                </div>
            </FlexFormGroup>

            <FlexFormGroup>
                <Label>Maximum Number of Students (capacity) of the Class:</Label>
                <div className={'d-flex flex-column'}>
                    <Input name={'maximumNumberOfStudents'} innerRef={register()} type={'number'} />
                    {errors && errors.maximumNumberOfStudents && (
                        <Row>
                            <Col>
                                <span className={'text-danger'}>{errors.maximumNumberOfStudents.message}</span>
                            </Col>
                        </Row>
                    )}
                </div>
            </FlexFormGroup>

            <FlexFormGroup>
                <Label>Number of Students Initially Enrolled:</Label>
                <div className={'d-flex flex-column'}>
                    <Input name={'numberOfStudentsInitiallyEnrolled'} innerRef={register()} type={'number'} />
                    {errors && errors.numberOfStudentsInitiallyEnrolled && (
                        <Row>
                            <Col>
                                <span className={'text-danger'}>
                                    {errors.numberOfStudentsInitiallyEnrolled.message}
                                </span>
                            </Col>
                        </Row>
                    )}
                </div>
            </FlexFormGroup>

            {infoArray &&
                infoArray.fields &&
                infoArray.fields.map((info, i) => (
                    <div key={info.id}>
                        <input type={'hidden'} ref={register()} name={`enrollmentRetentionReportInfoDto[${i}].id`} />
                        <input
                            type={'hidden'}
                            ref={register()}
                            name={`enrollmentRetentionReportInfoDto[${i}].enrollmentRetentionId`}
                        />
                        <input
                            type={'hidden'}
                            ref={register()}
                            name={`enrollmentRetentionReportInfoDto[${i}].reportYear`}
                        />
                        <input
                            type={'hidden'}
                            ref={register()}
                            name={`enrollmentRetentionReportInfoDto[${i}].totalStudentsInClass`}
                        />
                        <input
                            type={'hidden'}
                            ref={register()}
                            name={`enrollmentRetentionReportInfoDto[${i}].totalGraduates`}
                        />
                        <input
                            type={'hidden'}
                            ref={register()}
                            name={`enrollmentRetentionReportInfoDto[${i}].inProgressOrStoppedOut`}
                        />
                        <input
                            type={'hidden'}
                            ref={register()}
                            name={`enrollmentRetentionReportInfoDto[${i}].percentAttrition`}
                        />
                        <input
                            type={'hidden'}
                            ref={register()}
                            name={`enrollmentRetentionReportInfoDto[${i}].percentRetention`}
                        />

                        <FlexFormGroup>
                            <Label>Number Transferring/Joining After Enrollment Date:</Label>
                            <div className={'d-flex flex-column'}>
                                <Input
                                    name={`enrollmentRetentionReportInfoDto[${i}].numberAddedToClass`}
                                    innerRef={register()}
                                    type={'number'}
                                />
                                {errors &&
                                    errors['enrollmentRetentionReportInfoDto'] &&
                                    errors['enrollmentRetentionReportInfoDto'][i] && (
                                        <span className={'text-danger'}>
                                            {errors['enrollmentRetentionReportInfoDto'][i].numberAddedToClass?.message}
                                        </span>
                                    )}
                            </div>
                        </FlexFormGroup>

                        <FlexFormGroup>
                            <Label>Attrition Due to Non-Academic Reasons</Label>
                            <div className={'d-flex flex-column'}>
                                <Input
                                    name={`enrollmentRetentionReportInfoDto[${i}].attritionDueToNonAcademicReasons`}
                                    innerRef={register()}
                                    type={'number'}
                                />
                                {errors &&
                                    errors['enrollmentRetentionReportInfoDto'] &&
                                    errors['enrollmentRetentionReportInfoDto'][i] && (
                                        <span className={'text-danger'}>
                                            {
                                                errors['enrollmentRetentionReportInfoDto'][i]
                                                    .attritionDueToNonAcademicReasons?.message
                                            }
                                        </span>
                                    )}
                            </div>
                        </FlexFormGroup>

                        <FlexFormGroup>
                            <Label>Attrition Due to Professional Courses</Label>
                            <div className={'d-flex flex-column'}>
                                <Input
                                    name={`enrollmentRetentionReportInfoDto[${i}].attritionDueToProfessionalCourses`}
                                    innerRef={register()}
                                    type={'number'}
                                />
                                {errors &&
                                    errors['enrollmentRetentionReportInfoDto'] &&
                                    errors['enrollmentRetentionReportInfoDto'][i] && (
                                        <span className={'text-danger'}>
                                            {
                                                errors['enrollmentRetentionReportInfoDto'][i]
                                                    .attritionDueToProfessionalCourses?.message
                                            }
                                        </span>
                                    )}
                            </div>
                        </FlexFormGroup>

                        <FlexFormGroup>
                            <Label>Attrition Due to General Education Courses</Label>
                            <div className={'d-flex flex-column'}>
                                <Input
                                    name={`enrollmentRetentionReportInfoDto[${i}].attritionDueToGeneralEducationCourses`}
                                    innerRef={register()}
                                    type={'number'}
                                />
                                {errors &&
                                    errors['enrollmentRetentionReportInfoDto'] &&
                                    errors['enrollmentRetentionReportInfoDto'][i] && (
                                        <span className={'text-danger'}>
                                            {
                                                errors['enrollmentRetentionReportInfoDto'][i]
                                                    .attritionDueToGeneralEducationCourses?.message
                                            }
                                        </span>
                                    )}
                            </div>
                        </FlexFormGroup>
                    </div>
                ))}

            {detailArray &&
                detailArray.fields &&
                detailArray.fields
                    .sort((a, b) => a.graduationYear - b.graduationYear)
                    .map((det, i) => (
                        <div key={det.id}>
                            <input
                                type={'hidden'}
                                ref={register()}
                                name={`enrollmentRetentionDetailsDto[${i}].detailId`}
                                defaultValue={det.detailId}
                            />
                            <input
                                type={'hidden'}
                                ref={register()}
                                name={`enrollmentRetentionDetailsDto[${i}].enrollmentRetentionId`}
                                defaultValue={det.enrollmentRetentionId}
                            />
                            <input
                                type={'hidden'}
                                ref={register()}
                                name={`enrollmentRetentionDetailsDto[${i}].reportYear`}
                                defaultValue={det.reportYear}
                            />
                            <input
                                type={'hidden'}
                                ref={register()}
                                name={`enrollmentRetentionDetailsDto[${i}].graduationYear`}
                                defaultValue={det.graduationYear}
                            />

                            <FlexFormGroup>
                                <Label>{`Graduated in ${det.graduationYear}`}</Label>
                                <div className={'d-flex flex-column'}>
                                    <Input
                                        name={`enrollmentRetentionDetailsDto[${i}].graduated`}
                                        innerRef={register()}
                                        type={'number'}
                                        defaultValue={det.graduated}
                                    />
                                </div>
                            </FlexFormGroup>
                        </div>
                    ))}

            <Row>
                <Col className={'d-flex justify-content-around'}>
                    <div className={'d-flex flex-column justify-content-center align-items-center'}>
                        <h5>{totalInClass}</h5>
                        <span>{`Students In Class`}</span>
                    </div>
                    <div className={'d-flex flex-column justify-content-center align-items-center'}>
                        <h5>{droppedOut}</h5>
                        <span>{`Dropped Out`}</span>
                    </div>
                    <div className={'d-flex flex-column justify-content-center align-items-center'}>
                        <h5>{graduated}</h5>
                        <span>{`Graduated`}</span>
                    </div>
                    <div className={'d-flex flex-column justify-content-center align-items-center'}>
                        <h5>{inProgress}</h5>
                        <span>{`In Progress or Stopped Out`}</span>
                    </div>
                    {props.outcomeMeasure === 'Attrition' && (
                        <div className={'d-flex flex-column justify-content-center align-items-center'}>
                            <h5>{`${attrition?.toFixed(2)}%`}</h5>
                            <span>{`Percent Attrition`}</span>
                        </div>
                    )}
                    {props.outcomeMeasure === 'Retention' && (
                        <div className={'d-flex flex-column justify-content-center align-items-center'}>
                            <h5>{`${retention?.toFixed(2)}%`}</h5>
                            <span>{`Percent Retention`}</span>
                        </div>
                    )}
                </Col>
            </Row>

            {errors && errors['enrollmentRetentionReportInfoDto'] && errors['enrollmentRetentionReportInfoDto'][0] && (
                <Row className={'mt-2'}>
                    <Col className={'d-flex flex-column'}>
                        {errors['enrollmentRetentionReportInfoDto'][0].inProgressOrStoppedOut?.message && (
                            <span className={'text-danger'}>
                                {errors['enrollmentRetentionReportInfoDto'][0].inProgressOrStoppedOut.message}
                            </span>
                        )}
                        {errors['enrollmentRetentionReportInfoDto'][0].percentAttrition?.message && (
                            <span className={'text-danger'}>
                                {errors['enrollmentRetentionReportInfoDto'][0].percentAttrition.message}
                            </span>
                        )}
                        {errors['enrollmentRetentionReportInfoDto'][0].percentRetention?.message && (
                            <span className={'text-danger'}>
                                {errors['enrollmentRetentionReportInfoDto'][0].percentRetention.message}
                            </span>
                        )}
                        {errors['enrollmentRetentionReportInfoDto'][0].totalGraduates?.message && (
                            <span className={'text-danger'}>
                                {errors['enrollmentRetentionReportInfoDto'][0].totalGraduates.message}
                            </span>
                        )}
                    </Col>
                </Row>
            )}

            {showEnrollmentDateWarning() && (
                <Row className={'m-1'}>
                    <Col>
                        <Alert color={'warning'}>
                            {`WARNING: Another cohort with the enrollment date of ${moment
                                .utc(enrollmentDate)
                                .format(
                                    'MM/DD/YYYY',
                                )} already exists. Please confirm the details of this cohort before saving. Undoing this action may require additional support.`}
                        </Alert>
                    </Col>
                </Row>
            )}

            {showGraduationDateWarning() && (
                <Row className={'m-1'}>
                    <Col>
                        <Alert color={'warning'}>
                            {`WARNING: Another cohort with the on-time graduation date of ${moment
                                .utc(onTimeGraduationDate)
                                .format(
                                    'MM/DD/YYYY',
                                )} already exists. Please confirm the details of this cohort before saving. Undoing this action may require additional support.`}
                        </Alert>
                    </Col>
                </Row>
            )}

            <Row className={'mt-2'}>
                <Col className={'d-flex justify-content-between'}>
                    <div>
                        <Button disabled={isSubmitting} type={'submit'} color={'primary'}>
                            {`Save`}
                        </Button>
                        <Button
                            type={'button'}
                            color={'link'}
                            className={'ml-3'}
                            onClick={() => props.onFormCanceled()}
                        >
                            {`Cancel`}
                        </Button>
                    </div>
                    {authService.hasPermission(ArmsPermissionClaimType, 'admin') &&
                        props.enrollmentRetention.enrollmentRetentionId > 0 && (
                            <Button
                                type={'button'}
                                color={'danger'}
                                className={'ml-3'}
                                onClick={() => toggleShowConfirmDelete()}
                            >
                                {`Delete`}
                            </Button>
                        )}
                </Col>
            </Row>

            <Modal isOpen={showConfirmDelete} toggle={toggleShowConfirmDelete} centered>
                <ModalHeader>
                    <h4>{`Confirm Cohort Delete`}</h4>
                </ModalHeader>
                <ModalBody>
                    <p>
                        {`To confirm the deletion of this cohort, please click Confirm below. This action cannot be reversed, even manually.`}
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button
                        type={'button'}
                        color={'secondary'}
                        className={'mr-3'}
                        onClick={() => toggleShowConfirmDelete()}
                    >
                        {`Cancel`}
                    </Button>
                    <Button type={'button'} color={'danger'} onClick={() => deleteCohort()}>
                        {`Confirm`}
                    </Button>
                </ModalFooter>
            </Modal>
        </Form>
    );
};

export default EnrollmentRetentionForm;
