import * as yup from 'yup';

import { AnnualReportNavigationDirection, AnnualReportProps } from '../AnnualReport';
import { Button, CardFormGroup } from '../../../components';
import { Card, CardBody, CardFooter, CardSubtitle, Form, FormGroup, Input, Label } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import { ProgramStandard } from '../../../common/Types';
import { programService } from '../../../services/ProgramService';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

const Standards = (props: AnnualReportProps) => {
    const [standard, setStandard] = useState<ProgramStandard>();
    const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
    const [anyNo, setAnyNo] = useState<boolean>(false);
    const [submitDirection, setSubmitDirection] = useState<AnnualReportNavigationDirection>(
        AnnualReportNavigationDirection.Forward,
    );

    const standardsSchema: yup.ObjectSchema<ProgramStandard> = yup
        .object({
            standardId: yup
                .number()
                .required()
                .default(standard?.standardId || 0),
            programId: yup.number().required().default(props.program.programId),
            anyNo: yup.boolean(),
            activeYear: yup
                .number()
                .required()
                .default(+props.reportYear),
            standardIsponsorship: yup
                .boolean()
                .typeError('Please select Yes or No')
                .required('Please select Yes or No'),
            standardIiprogramGoals: yup
                .boolean()
                .typeError('Please select Yes or No')
                .required('Please select Yes or No'),
            advisoryCommitteeRepresentation: yup
                .boolean()
                .typeError('Please select Yes or No')
                .required('Please select Yes or No'),
            advisoryCommitteeFulfilsRole: yup
                .boolean()
                .typeError('Please select Yes or No')
                .required('Please select Yes or No'),
            standardIiiresources: yup
                .boolean()
                .typeError('Please select Yes or No')
                .required('Please select Yes or No'),
            standardIvstudentAndGraduate: yup
                .boolean()
                .typeError('Please select Yes or No')
                .required('Please select Yes or No'),
            standardVfairPractices: yup
                .boolean()
                .typeError('Please select Yes or No')
                .required('Please select Yes or No'),
            standardIIAcHasMetAnnually: yup
                .boolean()
                .typeError('Please select Yes or No')
                .required('Please select Yes or No'),
            datesCommitteeMeetings: yup.string().notRequired(),
            descriptionAnalysis: yup.string().when('anyNo', {
                is: true,
                then: yup.string().notRequired(),
                otherwise: yup.string().notRequired(),
            }),
            actionPlan: yup.string().when('anyNo', {
                is: true,
                then: yup.string().notRequired(),
                otherwise: yup.string().notRequired(),
            }),
            createdBy: yup.string().notRequired().nullable().default(standard?.createdBy),
            dateCreated: yup.string().notRequired().default(standard?.dateCreated),
            lastEdited: yup.string().notRequired().default(standard?.lastEdited),
            editedBy: yup.string().notRequired().nullable().default(standard?.editedBy),
        })
        .defined();

    const { handleSubmit, register, errors, getValues, watch } = useForm({
        validationSchema: standardsSchema,
    });

    const hasAnyNo = () => {
        const values = getValues({ nest: true }) as ProgramStandard;

        if (values) {
            return (
                values.standardIsponsorship?.toString() === 'false' ||
                values.standardIiprogramGoals?.toString() === 'false' ||
                values.advisoryCommitteeRepresentation?.toString() === 'false' ||
                values.advisoryCommitteeFulfilsRole?.toString() === 'false' ||
                values.standardIiiresources?.toString() === 'false' ||
                values.standardIvstudentAndGraduate?.toString() === 'false' ||
                values.standardVfairPractices?.toString() === 'false' ||
                values.standardIIAcHasMetAnnually?.toString() === 'false'
            );
        }

        return false;
    };

    const onSubmit = (formData) => {
        setFormSubmitting(true);
        const toastId = toast.info('Saving standards...');

        programService
            .saveProgramStandards(props.program.programId, formData)
            .then(() => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Standards Saved.',
                });

                if (submitDirection === AnnualReportNavigationDirection.Forward) {
                    props.onSavedSuccessfully(AnnualReportNavigationDirection.Forward);
                }
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Failed to save standards.',
                });
            })
            .finally(() => {
                setFormSubmitting(false);
            });
    };

    const submitWithoutValidation = () => {
        setFormSubmitting(true);
        const toastId = toast.info('Saving standards...');

        const formValues = getValues({ nest: true });

        const values = {
            ...standardsSchema.default(),
            ...formValues,
        } as ProgramStandard;

        programService
            .saveProgramStandards(props.program.programId, values)
            .then((response) => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Standards Saved.',
                });

                setStandard(response);
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Failed to save standards.',
                });
            })
            .finally(() => {
                setFormSubmitting(false);
            });
    };

    useEffect(() => {
        const getStandards = () => {
            programService
                .getProgramStandads(props.program.programId, props.reportYear ?? new Date().getFullYear())
                .then((response) => {
                    setStandard(response);

                    setAnyNo(
                        response.standardIsponsorship?.toString() === 'false' ||
                            response.standardIiprogramGoals?.toString() === 'false' ||
                            response.advisoryCommitteeRepresentation?.toString() === 'false' ||
                            response.advisoryCommitteeFulfilsRole?.toString() === 'false' ||
                            response.standardIiiresources?.toString() === 'false' ||
                            response.standardIvstudentAndGraduate?.toString() === 'false' ||
                            response.standardVfairPractices?.toString() === 'false' ||
                            response.standardIIAcHasMetAnnually?.toString() === 'false',
                    );
                })
                .catch(() => {
                    toast.error('Unable to get settings.');
                });
        };

        getStandards();
    }, [props.program.programId, props.reportYear, setStandard, setAnyNo]);

    watch('standardIsponsorship');
    watch('standardIiprogramGoals');
    watch('advisoryCommitteeRepresentation');
    watch('advisoryCommitteeFulfilsRole');
    watch('standardIiiresources');
    watch('standardIvstudentAndGraduate');
    watch('standardVfairPractices');
    watch('standardIIAcHasMetAnnually');

    if (hasAnyNo() !== anyNo) {
        setAnyNo(hasAnyNo());
    }

    console.log(errors);

    return (
        <Card>
            {standard && (
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <input
                        type={'hidden'}
                        ref={register()}
                        defaultValue={standard?.standardId || 0}
                        name={'standardId'}
                    />
                    <input ref={register()} name={'anyNo'} type={'hidden'} value={hasAnyNo().toString()} />
                    <CardBody>
                        <h3 className="card-title">Standards</h3>
                        <CardSubtitle className="mb-3">
                            To the best of your knowledge, does your program meet each standard?
                        </CardSubtitle>

                        <CardFormGroup>
                            <h5>Standard I</h5>
                            <Label className="standard-label">Sponsorship</Label>
                            <div className="radio-group">
                                <FormGroup>
                                    <Input
                                        type="radio"
                                        id={'standardIsponsorshipYes'}
                                        name="standardIsponsorship"
                                        innerRef={register()}
                                        defaultChecked={standard.standardIsponsorship === true}
                                        value="true"
                                    />
                                    <Label for={'standardIsponsorshipYes'}>Yes</Label>
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        type="radio"
                                        id={'standardIsponsorshipNo'}
                                        name="standardIsponsorship"
                                        innerRef={register()}
                                        defaultChecked={standard.standardIsponsorship === false}
                                        value="false"
                                    />
                                    <Label for={'standardIsponsorshipNo'}>No</Label>
                                </FormGroup>
                            </div>
                            {errors.standardIsponsorship?.message && (
                                <Label className={'text-danger'}>{errors.standardIsponsorship?.message}</Label>
                            )}
                        </CardFormGroup>
                        <CardFormGroup>
                            <h5>Standard II</h5>
                            <div className="program-goals">
                                <Label>Program Goals</Label>
                                <div className="radio-group">
                                    <FormGroup>
                                        <Input
                                            type="radio"
                                            id={'standardIiprogramGoalsYes'}
                                            name="standardIiprogramGoals"
                                            innerRef={register()}
                                            defaultChecked={standard.standardIiprogramGoals === true}
                                            value="true"
                                        />
                                        <Label for={'standardIiprogramGoalsYes'}>Yes</Label>
                                    </FormGroup>
                                    <FormGroup>
                                        <Input
                                            type="radio"
                                            id={'standardIiprogramGoalsNo'}
                                            name="standardIiprogramGoals"
                                            innerRef={register()}
                                            defaultChecked={standard.standardIiprogramGoals === false}
                                            value="false"
                                        />
                                        <Label for={'standardIiprogramGoalsNo'}>No</Label>
                                    </FormGroup>
                                </div>
                                <div>
                                    {errors.standardIiprogramGoals?.message && (
                                        <Label className={'text-danger'}>
                                            {errors.standardIiprogramGoals?.message}
                                        </Label>
                                    )}
                                </div>
                                <Label>{`Does the Advisory Committee have required representation?`}</Label>
                                <div className="radio-group">
                                    <FormGroup>
                                        <Input
                                            type="radio"
                                            id={'advisoryCommitteeRepresentationYes'}
                                            name="advisoryCommitteeRepresentation"
                                            innerRef={register()}
                                            defaultChecked={standard.advisoryCommitteeRepresentation === true}
                                            value="true"
                                        />
                                        <Label for={'advisoryCommitteeRepresentationYes'}>Yes</Label>
                                    </FormGroup>
                                    <FormGroup>
                                        <Input
                                            type="radio"
                                            id={'advisoryCommitteeRepresentationNo'}
                                            name="advisoryCommitteeRepresentation"
                                            innerRef={register()}
                                            defaultChecked={standard.advisoryCommitteeRepresentation === false}
                                            value="false"
                                        />
                                        <Label for={'advisoryCommitteeRepresentationNo'}>No</Label>
                                    </FormGroup>
                                </div>
                                <div>
                                    {errors.advisoryCommitteeRepresentation?.message && (
                                        <Label className={'text-danger'}>
                                            {errors.advisoryCommitteeRepresentation?.message}
                                        </Label>
                                    )}
                                </div>
                                <Label>{`Has the Advisory Committee met at least annually?`}</Label>
                                <div className="radio-group">
                                    <FormGroup>
                                        <Input
                                            type="radio"
                                            id={'standardIIAcHasMetAnnuallyYes'}
                                            name="standardIIAcHasMetAnnually"
                                            innerRef={register()}
                                            defaultChecked={standard.standardIIAcHasMetAnnually === true}
                                            value="true"
                                        />
                                        <Label for={'standardIIAcHasMetAnnuallyYes'}>Yes</Label>
                                    </FormGroup>
                                    <FormGroup>
                                        <Input
                                            type="radio"
                                            id={'standardIIAcHasMetAnnuallyNo'}
                                            name="standardIIAcHasMetAnnually"
                                            innerRef={register()}
                                            defaultChecked={standard.standardIIAcHasMetAnnually === false}
                                            value="false"
                                        />
                                        <Label for={'standardIIAcHasMetAnnuallyNo'}>No</Label>
                                    </FormGroup>
                                </div>
                                <div>
                                    {errors.standardIIAcHasMetAnnually?.message && (
                                        <Label className={'text-danger'}>
                                            {errors.standardIIAcHasMetAnnually?.message}
                                        </Label>
                                    )}
                                </div>
                                <Label>{`Does the Advisory Committee fulfill its required role?`}</Label>
                                <div className="radio-group">
                                    <FormGroup>
                                        <Input
                                            type="radio"
                                            id={'advisoryCommitteeFulfilsRoleYes'}
                                            name="advisoryCommitteeFulfilsRole"
                                            innerRef={register()}
                                            defaultChecked={standard.advisoryCommitteeFulfilsRole === true}
                                            value="true"
                                        />
                                        <Label for={'advisoryCommitteeFulfilsRoleYes'}>Yes</Label>
                                    </FormGroup>
                                    <FormGroup>
                                        <Input
                                            type="radio"
                                            id={'advisoryCommitteeFulfilsRoleNo'}
                                            name="advisoryCommitteeFulfilsRole"
                                            innerRef={register()}
                                            defaultChecked={standard.advisoryCommitteeFulfilsRole === false}
                                            value="false"
                                        />
                                        <Label for={'advisoryCommitteeFulfilsRoleNo'}>No</Label>
                                    </FormGroup>
                                </div>
                            </div>
                            <div>
                                {errors.advisoryCommitteeFulfilsRole?.message && (
                                    <Label className={'text-danger'}>
                                        {errors.advisoryCommitteeFulfilsRole?.message}
                                    </Label>
                                )}
                            </div>
                        </CardFormGroup>
                        <CardFormGroup>
                            <h5>Standard III</h5>
                            <Label className="standard-label">Resources</Label>
                            <div className="radio-group">
                                <FormGroup>
                                    <Input
                                        type="radio"
                                        id={'standardIiiresourcesYes'}
                                        name="standardIiiresources"
                                        innerRef={register()}
                                        defaultChecked={standard.standardIiiresources === true}
                                        value="true"
                                    />
                                    <Label for={'standardIiiresourcesYes'}>Yes</Label>
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        type="radio"
                                        id={'standardIiiresourcesNo'}
                                        name="standardIiiresources"
                                        innerRef={register()}
                                        defaultChecked={standard.standardIiiresources === false}
                                        value="false"
                                    />
                                    <Label for={'standardIiiresourcesNo'}>No</Label>
                                </FormGroup>
                            </div>
                            <div>
                                {errors.standardIiiresources?.message && (
                                    <Label className={'text-danger'}>{errors.standardIiiresources?.message}</Label>
                                )}
                            </div>
                        </CardFormGroup>
                        <CardFormGroup>
                            <h5>Standard IV</h5>
                            <Label className="standard-label">Student and Graduate Evaluation/Assessment</Label>
                            <div className="radio-group">
                                <FormGroup>
                                    <Input
                                        type="radio"
                                        id={'standardIvstudentAndGraduateYes'}
                                        name="standardIvstudentAndGraduate"
                                        innerRef={register()}
                                        defaultChecked={standard.standardIvstudentAndGraduate === true}
                                        value="true"
                                    />
                                    <Label for={'standardIvstudentAndGraduateYes'}>Yes</Label>
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        type="radio"
                                        id={'standardIvstudentAndGraduateNo'}
                                        name="standardIvstudentAndGraduate"
                                        innerRef={register()}
                                        defaultChecked={standard.standardIvstudentAndGraduate === false}
                                        value="false"
                                    />
                                    <Label for={'standardIvstudentAndGraduateNo'}>No</Label>
                                </FormGroup>
                            </div>
                            <div>
                                {errors.standardIvstudentAndGraduate?.message && (
                                    <Label className={'text-danger'}>
                                        {errors.standardIvstudentAndGraduate?.message}
                                    </Label>
                                )}
                            </div>
                        </CardFormGroup>
                        <CardFormGroup>
                            <h5>Standard V</h5>
                            <Label className="standard-label">Fair Practice</Label>
                            <div className="radio-group">
                                <FormGroup>
                                    <Input
                                        type="radio"
                                        id={'standardVfairPracticesYes'}
                                        name="standardVfairPractices"
                                        innerRef={register()}
                                        defaultChecked={standard.standardVfairPractices === true}
                                        value="true"
                                    />
                                    <Label for={'standardVfairPracticesYes'}>Yes</Label>
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        type="radio"
                                        id={'standardVfairPracticesNo'}
                                        name="standardVfairPractices"
                                        innerRef={register()}
                                        defaultChecked={standard.standardVfairPractices === false}
                                        value="false"
                                    />
                                    <Label for={'standardVfairPracticesNo'}>No</Label>
                                </FormGroup>
                            </div>
                            <div>
                                {errors.standardVfairPractices?.message && (
                                    <Label className={'text-danger'}>{errors.standardVfairPractices?.message}</Label>
                                )}
                            </div>
                        </CardFormGroup>
                        {anyNo && (
                            <div className={'d-flex justify-content-between'}>
                                <FormGroup style={{ flexBasis: 'calc(50% - 10px)' }}>
                                    <Label>Detailed Analysis of Standard(s) Not Met</Label>
                                    <Input
                                        type="textarea"
                                        id="standardsNotMet"
                                        name="descriptionAnalysis"
                                        rows={10}
                                        innerRef={register()}
                                        defaultValue={standard?.descriptionAnalysis ?? undefined}
                                    />
                                    <div>
                                        {errors.descriptionAnalysis?.message && (
                                            <Label className={'text-danger'}>
                                                {errors.descriptionAnalysis?.message}
                                            </Label>
                                        )}
                                    </div>
                                </FormGroup>
                                <FormGroup style={{ flexBasis: 'calc(50% - 10px)' }}>
                                    <Label>Action Plan for Standard(s) Not Met</Label>
                                    <Input
                                        type="textarea"
                                        name="actionPlan"
                                        rows={10}
                                        innerRef={register()}
                                        defaultValue={standard?.actionPlan ?? undefined}
                                    />
                                    <div>
                                        {errors.actionPlan?.message && (
                                            <Label className={'text-danger'}>{errors.actionPlan?.message}</Label>
                                        )}
                                    </div>
                                </FormGroup>
                            </div>
                        )}
                    </CardBody>
                    {props.canEdit && (
                        <CardFooter className={'d-flex justify-content-between'}>
                            <Button
                                type={'button'}
                                color={'warning'}
                                onClick={() => props.onNavigate(AnnualReportNavigationDirection.Back)}
                            >
                                <i className={'mdi mdi-arrow-left'} />
                                <span className={'mr-1'}>{`Previous`}</span>
                            </Button>

                            <Button
                                disabled={formSubmitting}
                                type={'button'}
                                color={'primary'}
                                onClick={() => submitWithoutValidation()}
                            >
                                <i className={'mdi mdi-content-save'} />
                                <span className={'ml-1'}>{`Save`}</span>
                            </Button>

                            <Button
                                disabled={formSubmitting}
                                type={'submit'}
                                color={'info'}
                                onClick={() => setSubmitDirection(AnnualReportNavigationDirection.Forward)}
                            >
                                <span className={'mr-1'}>{`Save & Continue`}</span>
                                <i className={'mdi mdi-arrow-right'} />
                            </Button>
                        </CardFooter>
                    )}
                </Form>
            )}
        </Card>
    );
};

export default Standards;
