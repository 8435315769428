import { Button, Input, PageTitle } from '../../components';
import {
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    Col,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import React, { useEffect, useState } from 'react';

import { MeetingDateDto } from '../../common/Types';
import moment from 'moment';
import { systemAdministration } from '../../services/SystemAdministration';
import { toast } from 'react-toastify';

const MeetingDates = () => {
    const [meetingDates, setMeetingDates] = useState<MeetingDateDto[]>();
    const [reloadMeetings, setReloadMeetings] = useState<boolean>(true);
    const beginningOfDay = new Date();
    beginningOfDay.setHours(0, 0, 0, 0);
    const [today] = useState<Date>(beginningOfDay);

    const [edit, setEdit] = useState<MeetingDateDto>();
    const [editing, setEditing] = useState<boolean>(false);
    const toggleEditing = () => setEditing(!editing);

    const [removing, setRemoving] = useState<boolean>(false);
    const toggleRemoving = () => setRemoving(!removing);

    const onSubmit = () => {
        if (edit && edit.meetingDate) {
            const toastId = toast.info('Saving meeting date...');

            systemAdministration
                .saveMeetingDates([edit])
                .then(() => {
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Meeting date saved successfully',
                    });
                    setReloadMeetings(true);
                    toggleEditing();
                })
                .catch(() =>
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Error saving meeting date',
                    }),
                );
        }
    };

    const onRemove = () => {
        if (edit && edit.meetingDate) {
            const toastId = toast.info('Removing meeting date...');

            systemAdministration
                .saveMeetingDates([
                    {
                        ...edit,
                        active: false,
                    },
                ])
                .then(() => {
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Meeting date removed successfully',
                    });
                    setReloadMeetings(true);
                    toggleRemoving();
                })
                .catch(() =>
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Error removing meeting date',
                    }),
                );
        }
    };

    useEffect(() => {
        const getData = () => {
            if (reloadMeetings) {
                setReloadMeetings(false);
                systemAdministration
                    .getMeetingDates()
                    .then((response) => {
                        setMeetingDates(response);
                    })
                    .catch(() => {
                        toast.error('Failed to get Meeting Dates.');
                    });
            }
        };

        getData();
    }, [setMeetingDates, reloadMeetings]);

    return (
        <>
            <PageTitle>
                <div className={'d-flex justify-content-between py-3'}>
                    <h4>{`Meeting Dates`}</h4>
                    <Button
                        type={'button'}
                        color={'primary'}
                        onClick={() => {
                            setEdit({ meetingDateId: 0, meetingDate: new Date(), active: true });
                            toggleEditing();
                        }}
                    >
                        <i className={'mdi mdi-plus'} />
                        <span className={'ml-2'}>{`New Meeting Date`}</span>
                    </Button>
                </div>
            </PageTitle>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <h4 className={'card-title'}>{`Upcoming Meetings`}</h4>
                            <div className={'d-flex flex-wrap'}>
                                {meetingDates
                                    ?.filter((md) => md.meetingDate >= today)
                                    .map((md) => (
                                        <Card
                                            key={md.meetingDateId}
                                            className={'font-weight-bold mr-3'}
                                            style={{
                                                borderColor: 'var(--caahep-primary)',
                                                color: 'var(--caahep-primary',
                                            }}
                                        >
                                            <CardBody className={'d-flex flex-column align-items-center'}>
                                                <h4 className={'mb-1'}>{`${moment(md.meetingDate).format(
                                                    'MM',
                                                )}/${moment(md.meetingDate).format('DD')}`}</h4>
                                                <h4 className={'m-0'}>{moment(md.meetingDate).format('YYYY')}</h4>
                                            </CardBody>
                                            <CardFooter className={'p-0'}>
                                                <ButtonGroup>
                                                    <Button
                                                        type={'button'}
                                                        onClick={() => {
                                                            setEdit({ ...md });
                                                            toggleEditing();
                                                        }}
                                                    >
                                                        <i className={'mdi mdi-pencil'} />
                                                        <span className={`ml-1`}>{`Edit`}</span>
                                                    </Button>
                                                    <Button
                                                        type={'button'}
                                                        onClick={() => {
                                                            setEdit({ ...md });
                                                            toggleRemoving();
                                                        }}
                                                    >
                                                        <i className={'mdi mdi-delete'} />
                                                        <span className={`ml-1`}>{`Delete`}</span>
                                                    </Button>
                                                </ButtonGroup>
                                            </CardFooter>
                                        </Card>
                                    ))}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <h4 className={'card-title'}>{`Past Meetings`}</h4>
                            <div className={'d-flex flex-wrap'}>
                                {meetingDates
                                    ?.filter((md) => md.meetingDate < today)
                                    .map((md) => (
                                        <Card key={md.meetingDateId} className={'font-weight-bold mr-3'}>
                                            <CardBody className={'d-flex flex-column align-items-center'}>
                                                <h4 className={'mb-1'}>{`${moment(md.meetingDate).format(
                                                    'MM',
                                                )}/${moment(md.meetingDate).format('DD')}`}</h4>
                                                <h4 className={'m-0'}>{moment(md.meetingDate).format('YYYY')}</h4>
                                            </CardBody>
                                            <CardFooter className={'p-0'}>
                                                <ButtonGroup className={'d-flex justify-content-between'}>
                                                    <Button
                                                        type={'button'}
                                                        onClick={() => {
                                                            setEdit({ ...md });
                                                            toggleEditing();
                                                        }}
                                                    >
                                                        <i className={'mdi mdi-pencil'} />
                                                        <span className={`ml-1`}>{`Edit`}</span>
                                                    </Button>
                                                    <Button
                                                        type={'button'}
                                                        onClick={() => {
                                                            setEdit({ ...md });
                                                            toggleRemoving();
                                                        }}
                                                    >
                                                        <i className={'mdi mdi-delete'} />
                                                        <span className={`ml-1`}>{`Delete`}</span>
                                                    </Button>
                                                </ButtonGroup>
                                            </CardFooter>
                                        </Card>
                                    ))}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {edit && (
                <Modal isOpen={editing} toggle={toggleEditing}>
                    <ModalHeader>
                        <h4>{`${edit.meetingDateId === 0 ? 'New' : 'Edit'} Meeting Date`}</h4>
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label>{`Meeting Date`}</Label>
                            <Input
                                type={'date'}
                                value={moment(edit.meetingDate).format('YYYY-MM-DD')}
                                onChange={(e) => setEdit({ ...edit, meetingDate: moment(e.target.value).toDate() })}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button type={'button'} color={'secondary'} className={'mr-2'} onClick={() => toggleEditing()}>
                            {`Cancel`}
                        </Button>
                        <Button type={'button'} color={'primary'} className={'mr-2'} onClick={() => onSubmit()}>
                            <>
                                {edit.meetingDateId === 0 && <span>{`Create`}</span>}
                                {edit.meetingDateId !== 0 && <span>{`Submit`}</span>}
                            </>
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
            {edit && (
                <Modal isOpen={removing} toggle={toggleRemoving}>
                    <ModalHeader>
                        <h4>{`Remove Meeting Date`}</h4>
                    </ModalHeader>
                    <ModalBody>
                        <p>{`To confirm the removal of the meeting date, ${moment(edit.meetingDate).format(
                            'MM/DD/YYYY',
                        )}, please click Confirm below.`}</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button type={'button'} color={'secondary'} className={'mr-2'} onClick={() => toggleRemoving()}>
                            {`Cancel`}
                        </Button>
                        <Button type={'button'} color={'primary'} className={'mr-2'} onClick={() => onRemove()}>
                            {`Confirm`}
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
};

export default MeetingDates;
