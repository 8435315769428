import React, { ReactNode } from 'react';
import posed from 'react-pose';
import styled from 'styled-components';

const Collapsable = posed.div({
    visible: { opacity: 1, height: 'auto' },
    hidden: { opacity: 0, height: '1px', zIndex: -1 },
});

const StyledCollapse = styled(Collapsable)`
    > div {
        z-index: -1;
    }
`;

type Props = {
    isOpen: boolean;
    children: ReactNode;
};

const Collapse = (props: Props) => {
    return <StyledCollapse pose={props.isOpen ? 'visible' : 'hidden'}>{props.children}</StyledCollapse>;
};

export default Collapse;
