import React from 'react';
import { Route as DOMRouter } from 'react-router-dom';
import EmptyContainer from '../containers/NavLessContainer';

const PublicRoute = (route) => {
    return (
        <DOMRouter
            path={route.path}
            render={(props) => (
                <EmptyContainer>
                    <route.component {...props} routes={route.routes} />
                </EmptyContainer>
            )}
        />
    );
};

export default PublicRoute;
