import { Card, CardBody, Col, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import React, { useState } from 'react';

import { Button } from '../../components';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { NumberOfActiveProgramsReportDto } from '../../common/Types';
import { reportService } from '../../services/ReportService';
import moment from 'moment';

const NumberOfActiveProgramsReport = () => {
    const [cutOffDate, setCutOffDate] = useState<Date>();
    const [concentrations, setConcentrations] = useState<boolean>(false);
    const [actionTotals, setActionTotals] = useState<NumberOfActiveProgramsReportDto>();

    const { register, handleSubmit } = useForm();

    const onSubmit = () => {
        const toastId = toast.info('Searching...', {
            autoClose: false,
        });
        reportService
            .getNumberOfActiveProgramsReport(moment(cutOffDate).format(), concentrations)
            .then((response) => {
                setActionTotals(response);
                toast.update(toastId, {
                    type: 'success',
                    autoClose: 500,
                });
            })
            .catch(() => toast.error('Failed to generate report'));
    };

    const exportReport = () => {
        const toastId = toast.info('Generating report, this may take a minute...', {
            autoClose: false,
        });
        reportService
            .exportNumberOfActiveProgramsReport(moment(cutOffDate).format(), concentrations)
            .then((report) => {
                if (report) {
                    const url = window.URL.createObjectURL(report);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `ActiveProgramsReport.xlsx`;
                    a.click();
                    toast.update(toastId, {
                        render: 'Report generated successfully.',
                        type: 'success',
                        autoClose: 2500,
                    });
                }
            })
            .catch(() => {
                toast.error(`Unable to generate report`);
            });
    };

    return (
        <>
            <Card>
                <CardBody>
                    <Row>
                        <Col>
                            <div className="mb-3">
                                <h3>Active Program Report</h3>
                            </div>
                            <Form onSubmit={handleSubmit(onSubmit)} inline>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                    <div className={'d-flex justify-content-between p-2'}>
                                        <div className={'custom-control custom-radio pr-2'}>
                                            <input
                                                type={'radio'}
                                                id={`radioAll`}
                                                className={'custom-control-input'}
                                                checked={concentrations}
                                                onClick={() => setConcentrations(true)}
                                                value={1}
                                            />
                                            <Label
                                                className={'custom-control-label'}
                                                for={`radioAll`}
                                            >{`With Concentrations`}</Label>
                                        </div>
                                        <div className={'custom-control custom-radio pr-2'}>
                                            <input
                                                type={'radio'}
                                                id={`radioUnmerged`}
                                                className={'custom-control-input'}
                                                checked={!concentrations}
                                                onClick={() => setConcentrations(false)}
                                                value={0}
                                            />
                                            <Label
                                                className={'custom-control-label'}
                                                for={`radioUnmerged`}
                                            >{`Without Concentrations`}</Label>
                                        </div>
                                    </div>
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                    <Label for="startDate" className="mr-sm-2">
                                        Cut Off Date:
                                    </Label>
                                    <Input
                                        type="date"
                                        innerRef={register()}
                                        name="startDate"
                                        onChange={(e) => setCutOffDate(new Date(e.target.value))}
                                        required
                                    />
                                </FormGroup>
                                <div className="mb-2 mr-sm-2 mb-sm-2">
                                    <Button type="submit">Search</Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col style={{ display: 'flex' }}>
                            <div className="mr-2">
                                <Button color="info" onClick={() => exportReport()}>
                                    Export Report
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <div className="mt-3">
                        <h4>Number of Active Programs</h4>
                    </div>
                </CardBody>
                <Table>
                    {actionTotals?.numberOfActiveProgramsByProfessionDtos ? (
                        actionTotals?.numberOfActiveProgramsByProfessionDtos.map((profession) => (
                            <tbody key={profession.professionId}>
                                <tr>
                                    <td className={`font-weight-bold`}>
                                        {profession.professionTitle} - {profession.totalCount}
                                    </td>
                                </tr>
                                {profession.activeProgramsByStatusDtos ? (
                                    profession.activeProgramsByStatusDtos.map((status) => (
                                        <div key={status.statusId}>
                                            <tr>
                                                <td>
                                                    {status.statusName} - {status.programCount}
                                                </td>
                                            </tr>
                                        </div>
                                    ))
                                ) : (
                                    <tr>
                                        <td />
                                    </tr>
                                )}
                                {profession.activeConcentrationsDtos ? (
                                    profession.activeConcentrationsDtos.map((concentration) => (
                                        <div key={concentration.concentrationId}>
                                            <tr>
                                                <td>
                                                    {concentration.concentrationTitle} - {concentration.programCount}
                                                </td>
                                            </tr>
                                        </div>
                                    ))
                                ) : (
                                    <tr>
                                        <td />
                                    </tr>
                                )}
                            </tbody>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={7}>No Results</td>
                        </tr>
                    )}
                    <tr>
                        <td className={`font-weight-bold`}>Total Records: {actionTotals?.totalCount ?? 0}</td>
                    </tr>
                </Table>
            </Card>
        </>
    );
};

export default NumberOfActiveProgramsReport;
