import { Col, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import AddOnTracksCard from './AddOnTracksCard';
import ConcentrationsCard from './ConcentrationsCard';
import DesignationsCard from './DesignationsCard';
import { PageTitle } from '../../../components';
import ProfessionDetailCard from './ProfessionDetailCard';
import { ProfessionDetailsDto } from '../../../common/Types';
import StandardVersionsCard from './StandardVersionsCard';
import { professionService } from '../../../services/ProfessionAdministration';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

interface ProfessionParams {
    professionId?: string;
}

const Profession = () => {
    const { professionId } = useParams<ProfessionParams>();
    const [profession, setProfession] = useState<ProfessionDetailsDto>();
    const [reloadProfession, setReloadProfession] = useState<boolean>(true);

    useEffect(() => {
        const getProfession = () => {
            if (professionId && reloadProfession) {
                setReloadProfession(false);
                professionService
                    .getProfessionDetails(+professionId)
                    .then((response) => setProfession(response))
                    .catch(() => {
                        toast.error('Error loading profession details.');
                    });
            }
        };

        getProfession();
    }, [setProfession, professionId, reloadProfession]);

    return (
        <>
            <PageTitle title={`Profession | ${profession?.title ?? ''}`} />

            <Row>
                <Col xs={3}>
                    <Row>
                        <Col>
                            {professionId && profession && (
                                <ProfessionDetailCard
                                    detail={{
                                        ...profession,
                                        lengthOfInitial:
                                            profession.professionLengthOfInitial &&
                                            profession.professionLengthOfInitial.length > 0
                                                ? profession.professionLengthOfInitial[0]
                                                : undefined,
                                    }}
                                    onUpdate={() => setReloadProfession(true)}
                                />
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {professionId && (
                                <StandardVersionsCard
                                    professionStandards={profession?.professionStandards}
                                    professionId={+professionId}
                                />
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>{professionId && <AddOnTracksCard professionId={+professionId} />}</Col>
                    </Row>
                    <Row>
                        <Col>{professionId && <ConcentrationsCard professionId={+professionId} />}</Col>
                    </Row>
                </Col>
                <Col>
                    {professionId && (
                        <DesignationsCard
                            designations={profession?.professionStandardsDesignation}
                            professionId={+professionId}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default Profession;
