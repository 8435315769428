export interface IAuthState {
    authenticated: boolean;
    authenticating: boolean;
    authenticationError?: string;
    passwordReset: boolean;
    passwordResetting: boolean;
    passwordResetError?: string;
    user?: any;
}

export interface IAuthenticatedUser {
    name: string;
    email: string;
}

export enum AuthActionType {
    Authenticating = 'AUTHENTICATING',
    Authenticated = 'AUTHENTICATED',
    AuthenticationError = 'AUTHENTICATION_ERROR',
    LoggedOut = 'LOGGED_OUT',
    PasswordResetting = 'PASSWORD_RESETTING',
    PasswordReset = 'PASSWORD_RESET',
}

export interface IAuthAction<T> {
    type: AuthActionType;
    payload: T;
}
