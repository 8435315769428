import * as yup from 'yup';

import { Alert, Badge, Card, CardBody, CardFooter, Form, FormGroup, Input, Label } from 'reactstrap';
import {
    AuspiceDto,
    Concentration,
    Institution,
    InstitutionTypeDto,
    ProfessionAddOnTrack,
    ProfessionDto,
    Program,
    RecommendationCreate,
} from '../common/Types';
import { Countries, PaymentType, ProgramStatus, States } from '../common/Data';
import React, { useEffect, useRef, useState } from 'react';

import { Button } from '.';
import { Redirect } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import Spinner from './Spinner';
import { institutionService } from '../services/InstitutionService';
import { professionService } from '../services/ProfessionAdministration';
import { recommendationService } from '../services/RecommendationService';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { invoiceService } from '../services/InvoiceService';

interface NewRecommendationFormProps {
    onCancel: () => void;
    meetingDateId: number;
}

const awards: string[] = ['Associate', 'Certificate', 'Diploma', 'Baccalaureate', 'Masters'];

const NewRecommendationForm = (props: NewRecommendationFormProps) => {
    const { onCancel, meetingDateId } = props;

    const [isNewInstitution, setIsNewInstitution] = useState<boolean>(false);
    const [isTransfer, setIsTransfer] = useState<boolean>();

    const newRecommendationSchema: yup.ObjectSchema<RecommendationCreate> = yup
        .object<RecommendationCreate>({
            programProfessionId: yup.number().required().min(1, 'Please select a profession'),
            statusId: yup.number().required().min(0, 'Please select a recommendation status'),
            caahepprogramId: yup
                .number()
                .notRequired()
                .transform((cv, ov) => {
                    return ov === '' ? undefined : +cv;
                }),
            caahepinstitutionId: yup.number().when('statusId', {
                is: () => isNewInstitution === false,
                then: yup
                    .number()
                    .required(
                        'You must either confirm this is a new institution, or select from an existing institution',
                    )
                    .typeError(
                        'You must either confirm this is a new institution, or select from an existing institution',
                    ),
                otherwise: yup
                    .number()
                    .notRequired()
                    .transform((cv, ov) => {
                        return ov === '' ? undefined : +cv;
                    }),
            }),
            transferFromProgramId: yup.number().when('statusId', {
                is: () => isTransfer === true,
                then: yup
                    .number()
                    .required('When transferring a program, an existing program must be found')
                    .typeError('When transferring a program, an existing program must be found'),
                otherwise: yup
                    .number()
                    .notRequired()
                    .transform((cv, ov) => {
                        return ov === '' ? undefined : +cv;
                    }),
            }),
            transferFromInstitutionId: yup.number().when('statusId', {
                is: () => isTransfer === true,
                then: yup
                    .number()
                    .required('When transferring a program, you must select an existing institution')
                    .typeError('When transferring a program, you must select an existing institution'),
                otherwise: yup
                    .number()
                    .notRequired()
                    .transform((cv, ov) => {
                        return ov === '' ? undefined : +cv;
                    }),
            }),
            institutionTitle: yup.string().when('statusId', {
                is: () => isNewInstitution === true,
                then: yup.string().required('This is required for new institutions'),
                otherwise: yup.string().notRequired(),
            }),
            institutionCity: yup.string().when('statusId', {
                is: () => isNewInstitution === true,
                then: yup.string().required('This is required for new institutions'),
                otherwise: yup.string().notRequired(),
            }),
            institutionCountry: yup.string().when('statusId', {
                is: () => isNewInstitution === true,
                then: yup.string().required('This is required for new institutions'),
                otherwise: yup.string().notRequired(),
            }),
            institutionState: yup.string().when('statusId', {
                is: () => isNewInstitution === true,
                then: yup.string().required('This is required for new institutions'),
                otherwise: yup.string().notRequired(),
            }),
            transferToInstitutionId: yup
                .number()
                .notRequired()
                .transform((cv, ov) => {
                    return ov === '' ? undefined : +cv;
                }),
            programConcentrationId: yup
                .number()
                .notRequired()
                .min(0, 'Please select a concentration.')
                .default(undefined),
            programAddOnTrackId: yup.number().notRequired().default(undefined),
            programTitle: yup.string().required(),
            meetingDateId: yup.number().required().default(meetingDateId),
            isAwardRequired: yup.boolean().required().default(false),
            award: yup.string().when('isAwardRequired', {
                is: (isAwardRequired) => isAwardRequired === true,
                then: yup.string().required('Award / Degree is required for this profession'),
                otherwise: yup.string().notRequired(),
            }),
        })
        .defined();

    const { handleSubmit, errors, watch, register, reset, control, setValue } = useForm({
        validationSchema: newRecommendationSchema,
        defaultValues: {
            institutionCountry: 'United States of America',
        } as RecommendationCreate,
    });
    const recommendation = watch();

    const [existingProgram, setExistingProgram] = useState<Program>();
    const [existingAddOnTrack, setExistingAddOnTrack] = useState<boolean>(false);
    const [searchPrograms, setSearchPrograms] = useState<boolean>(false);
    const [concentrations, setConcentrations] = useState<Concentration[]>();
    const [addOnTracks, setAddOnTracks] = useState<ProfessionAddOnTrack[]>();
    const [fromProfessionId, setFromProfessionId] = useState<number>();
    const [fromInstitutions, setFromInstitutions] = useState<Institution[]>();
    const lookupEcho = useRef<number>(0);
    const searching = useRef<boolean>(false);
    const [navigateToId, setNavigateToId] = useState<number>(0);
    const loadProfessionOptions = (professionId: number) => {
        professionService
            .getProfessionConcentrations([professionId])
            .then((cons) => {
                setConcentrations(cons);
            })
            .catch(() => {
                toast.error('Error loading profession concentrations');
            });

        professionService
            .getAddOnTracksByProfessionId(professionId)
            .then((aots) => {
                setAddOnTracks(aots);
            })
            .catch(() => {
                toast.error('Error loading profession add-on tracks');
            });

        setFromProfessionId(professionId);
    };

    const [professions, setProfessions] = useState<ProfessionDto[]>([]);
    const [isAwardRequired, setIsAwardRequired] = useState<boolean>(true);
    useEffect(() => {
        if (professions?.length <= 0) {
            professionService
                .getProfessions()
                .then((profs) => {
                    setProfessions(profs.filter((p) => !p.isDeleted || false));
                    if (profs?.length === 1) {
                        loadProfessionOptions(profs[0].professionId);
                        setIsAwardRequired(profs[0].isOneDegreePerProgram);
                    }
                })
                .catch(() => {
                    toast.error('Error loading professions');
                });
        }
    }, [setProfessions, setIsAwardRequired]);

    const [institutionTypes, setInstitutionTypes] = useState<InstitutionTypeDto[]>();
    useEffect(() => {
        if (!institutionTypes) {
            institutionService
                .getTypes()
                .then((insTypes) => {
                    setInstitutionTypes(insTypes.filter((it) => it.isActive === true));
                })
                .catch(() => {
                    toast.error('Error loading institution types');
                });
        }
    }, [setInstitutionTypes]);

    const [auspices, setAuspices] = useState<AuspiceDto[]>();
    useEffect(() => {
        if (!auspices) {
            institutionService
                .getAuspice()
                .then((aus) => {
                    setAuspices(aus.filter((a) => a.isActive === true));
                })
                .catch(() => {
                    toast.error('Error loading auspices');
                });
        }
    }, [setAuspices]);

    const [existingInstitutions, setExistingInstitutions] = useState<Institution[]>();
    const searchInstitutions = () => {
        lookupEcho.current = lookupEcho.current + 1;
        searching.current = true;
        recommendationService
            .institutionSearch(
                lookupEcho.current,
                recommendation.institutionCity,
                recommendation.institutionState,
                recommendation.institutionTitle,
            )
            .then((ins) => {
                if (ins.echo === lookupEcho.current) {
                    searching.current = false;
                    setExistingInstitutions([...ins.institutions.sort((a, b) => a.title.localeCompare(b.title))]);
                }
            })
            .catch(() => {
                toast.error('Error searching existing institutions');
            });
    };

    const selectExistingInstitution = (institution: Institution, recommendation: RecommendationCreate) => {
        reset({
            ...recommendation,
            caahepinstitutionId: institution.institutionId,
            institutionState: institution.state || undefined,
            institutionTitle: institution.title || undefined,
            institutionCity: institution.city || undefined,
            institutionCountry: institution.country || undefined,
            transferToInstitutionId: isTransfer === true ? institution.institutionId : undefined,
        });
        setExistingInstitutions([{ ...institution }]);
        setIsNewInstitution(false);
        setSearchPrograms(true);
    };

    const selectFromInstitution = (institution: Institution, recommendation: RecommendationCreate) => {
        reset({
            ...recommendation,
            transferFromInstitutionId: institution.institutionId,
        });
        setFromInstitutions([{ ...institution }]);
        setSearchPrograms(true);
    };

    const deselectInstitution = (recommendation: RecommendationCreate) => {
        reset({
            ...recommendation,
            caahepinstitutionId: undefined,
            transferToInstitutionId: undefined,
            caahepprogramId: undefined,
        });
        setSearchPrograms(true);
    };

    useEffect(() => {
        const locateProgram = () => {
            if (
                searchPrograms &&
                +(recommendation.programProfessionId || -1) > 0 &&
                ((+(recommendation.transferFromInstitutionId || -1) > 0 && isTransfer === true) ||
                    (+(recommendation.caahepinstitutionId || -1) > 0 && isTransfer === false)) &&
                (!recommendation.isAwardRequired || (recommendation.isAwardRequired && !!recommendation.award))
            ) {
                setSearchPrograms(false);

                let institutionId = -1;

                if (isTransfer === true) {
                    institutionId = +recommendation.transferFromInstitutionId;
                } else {
                    institutionId = +recommendation.caahepinstitutionId;
                }

                recommendationService
                    .programSearch(
                        institutionId,
                        +recommendation.programProfessionId,
                        recommendation.programConcentrationId,
                        recommendation.programAddOnTrackId,
                        recommendation.award,
                    )
                    .then((result) => {
                        reset({
                            ...recommendation,
                            caahepprogramId: result.programId,
                            transferFromProgramId: isTransfer === true ? result.programId : undefined,
                        });
                        setExistingProgram(result.existingProgram);
                        setExistingAddOnTrack(result.addOnTrackExists);
                    })
                    .catch(() => {
                        toast.error('Error encountered searching programs');
                    });
            } else if (searchPrograms) {
                setSearchPrograms(false);
            }
        };

        locateProgram();
    }, [searchPrograms, isNewInstitution, isTransfer]);

    const submitForm = (values: RecommendationCreate) => {
        values.isAwardRequired = isAwardRequired;
        if (isTransfer === undefined) {
            return;
        }

        const toastId = toast.info('Creating recommendation...');
        recommendationService
            .createRecommendation(values)
            .then((newRec) => {
                toast.update(toastId, {
                    render: 'Recommendation created successfully',
                    type: 'success',
                });

                setNavigateToId(newRec.recommendationId);
            })
            .catch(() => {
                toast.update(toastId, {
                    render: 'Error creating recommendation',
                    type: 'error',
                });
            });
    };

    // console.log('Errors:', errors);

    const recommendationStatuses: ProgramStatus[] = [
        ProgramStatus.Initial,
        ProgramStatus.Continuing,
        ProgramStatus.Probation,
        ProgramStatus.Withdraw,
        ProgramStatus.Withhold,
    ];

    const [fromCity, setFromCity] = useState<string>();
    const [fromState, setFromState] = useState<string>();
    const [fromName, setFromName] = useState<string>();
    useEffect(() => {
        const searchFromInstitutions = () => {
            if (isTransfer === true) {
                lookupEcho.current = lookupEcho.current + 1;
                searching.current = true;
                recommendationService
                    .institutionSearch(
                        lookupEcho.current,
                        fromCity,
                        fromState,
                        fromName,
                        fromProfessionId,
                        recommendation.programConcentrationId,
                        recommendation.programAddOnTrackId,
                    )
                    .then((ins) => {
                        if (ins.echo === lookupEcho.current) {
                            searching.current = false;
                            setFromInstitutions([...ins.institutions.sort((a, b) => a.title.localeCompare(b.title))]);
                        }
                    })
                    .catch(() => {
                        toast.error('Error searching existing institutions');
                    });
            }
        };

        searchFromInstitutions();
    }, [fromCity, fromState, fromName, setFromInstitutions, fromProfessionId, isTransfer]);

    const buildProgramTitle = (recommendation: RecommendationCreate): string => {
        let title = '';

        if (+(recommendation.programProfessionId || -1) > 0) {
            title = professions?.find((p) => p.professionId === +recommendation.programProfessionId)?.title || '';
        }

        if (+(recommendation.programConcentrationId || -1) > 0) {
            title = `${title} - ${
                concentrations?.find((c) => c.concentrationId === +(recommendation.programConcentrationId || -1))
                    ?.title || ''
            }`;
        }

        return title;
    };

    const programTitle = existingProgram ? existingProgram.title : buildProgramTitle(recommendation);

    const isInitialStatusExistingProgram = (rec: RecommendationCreate) => {
        return +(rec.statusId || -1) === ProgramStatus.Initial && +(rec.caahepprogramId || -1) > 0;
    };

    const isNonInitialStatusNewProgram = (rec: RecommendationCreate) => {
        return (
            +(rec.statusId || -1) !== ProgramStatus.Initial &&
            +(rec.statusId || -1) > 0 &&
            +(rec.caahepprogramId || -1) < 0 &&
            (isNewInstitution === true ||
                (isNewInstitution === false && +(recommendation.caahepinstitutionId || -1) > 0))
        );
    };
    const isTransferToSameInsitution = (rec: RecommendationCreate) => {
        return (
            isTransfer &&
            +(rec.transferFromInstitutionId || -1) === +(rec.transferToInstitutionId || -1) &&
            +(rec.transferFromInstitutionId || -1) > 0
        );
    };

    if (navigateToId > 0) {
        return <Redirect to={`/recommendation/${navigateToId}/new`} />;
    }

    return (
        <>
            <Form onSubmit={handleSubmit(submitForm)}>
                <input
                    type={'hidden'}
                    name={'transferToInstitutionId'}
                    ref={register()}
                    defaultValue={recommendation.transferToInstitutionId}
                />
                <input
                    type={'hidden'}
                    name={'transferFromInstitutionId'}
                    ref={register()}
                    defaultValue={recommendation.transferFromInstitutionId}
                />
                <input
                    type={'hidden'}
                    name={'transferFromProgramId'}
                    ref={register()}
                    defaultValue={recommendation.transferFromProgramId}
                />
                <input
                    type={'hidden'}
                    name={'caahepinstitutionId'}
                    ref={register()}
                    defaultValue={recommendation.caahepinstitutionId}
                />
                <input
                    type={'hidden'}
                    name={'caahepprogramId'}
                    ref={register()}
                    defaultValue={recommendation.caahepprogramId}
                />
                <input type={'hidden'} name={'programTitle'} ref={register()} value={programTitle} />
                <Card className={'mb-0'}>
                    <CardBody>
                        <h4 className={'card-title'}>{`New Recommendation`}</h4>
                        <h5
                            className={'card-subtitle text-muted mb-3'}
                        >{`Please complete the sections below to start the new recommendation. The next page will contain the rest of the recommendation sections including citations, additional program details, additional recommendation details, and insitutiton / program personnel.`}</h5>
                        {professions.length <= 0 && <Skeleton count={5} />}
                        {professions.length > 0 && (
                            <>
                                <Card>
                                    <CardBody>
                                        <h5 className={'card-title'}>{`Program Detail`}</h5>

                                        {professions.length === 0 && (
                                            <p>
                                                {`You do not appear to be associated with any professions, please contact CAAHEP support.`}
                                            </p>
                                        )}
                                        {professions.length === 1 && (
                                            <>
                                                <input
                                                    type={'hidden'}
                                                    name={'programProfessionId'}
                                                    defaultValue={professions[0].professionId}
                                                    ref={register()}
                                                    onInput={() =>
                                                        setIsAwardRequired(professions[0].isOneDegreePerProgram)
                                                    }
                                                />
                                                <FormGroup>
                                                    <Label>{`Profession`}</Label>
                                                    <div>
                                                        <span>{professions[0].title}</span>
                                                    </div>
                                                </FormGroup>
                                            </>
                                        )}
                                        {professions.length > 1 && (
                                            <FormGroup>
                                                <Label>{`Please select a profession`}</Label>
                                                <Input
                                                    type={'select'}
                                                    innerRef={register()}
                                                    name={'programProfessionId'}
                                                    onChange={(e) => {
                                                        loadProfessionOptions(+e.target.value);
                                                        setIsAwardRequired(
                                                            professions.find(
                                                                (p) =>
                                                                    p.professionId ===
                                                                    +recommendation.programProfessionId,
                                                            )?.isOneDegreePerProgram || false,
                                                        );
                                                        return +e.target.value;
                                                    }}
                                                >
                                                    <option value={-1}>{`Select...`}</option>
                                                    {professions.map((p) => (
                                                        <option key={p.professionId} value={p.professionId}>
                                                            {p.title}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        )}

                                        {recommendation.programProfessionId > 0 && (
                                            <>
                                                <Controller
                                                    as={'input'}
                                                    type={'hidden'}
                                                    name={`isAwardRequired`}
                                                    control={control}
                                                    value={isAwardRequired.toString()}
                                                />
                                                {concentrations && concentrations.length > 0 && (
                                                    <FormGroup>
                                                        <Label>{`Concentration`}</Label>
                                                        <Input
                                                            type={'select'}
                                                            innerRef={register()}
                                                            name={'programConcentrationId'}
                                                            onChange={() => setSearchPrograms(true)}
                                                        >
                                                            <option value={-1}>{`Please select`}</option>
                                                            {concentrations.map((c) => (
                                                                <option
                                                                    key={c.concentrationId}
                                                                    value={c.concentrationId}
                                                                >
                                                                    {c.title}
                                                                </option>
                                                            ))}
                                                        </Input>
                                                        {errors?.programConcentrationId?.message && (
                                                            <div>
                                                                <span className={'text-danger'}>
                                                                    {errors.programConcentrationId.message}
                                                                </span>
                                                            </div>
                                                        )}
                                                    </FormGroup>
                                                )}

                                                {addOnTracks && addOnTracks.length > 0 && (
                                                    <FormGroup>
                                                        <Label>{`Add-On Tracks`}</Label>
                                                        <Input
                                                            type={'select'}
                                                            innerRef={register()}
                                                            name={'programAddOnTrackId'}
                                                            onChange={() => setSearchPrograms(true)}
                                                        >
                                                            <option value={-1}>{`Not applicable`}</option>
                                                            {addOnTracks.map((a) => (
                                                                <option key={a.addOnTrackId} value={a.addOnTrackId}>
                                                                    {a.title}
                                                                </option>
                                                            ))}
                                                        </Input>
                                                        {errors?.programAddOnTrackId?.message && (
                                                            <div>
                                                                <span className={'text-danger'}>
                                                                    {errors.programAddOnTrackId.message}
                                                                </span>
                                                            </div>
                                                        )}
                                                    </FormGroup>
                                                )}
                                                {isAwardRequired && (
                                                    <FormGroup>
                                                        <Label>{`Award / Degree`}</Label>
                                                        <Input
                                                            type={'select'}
                                                            innerRef={register()}
                                                            name={'award'}
                                                            onChange={() => setSearchPrograms(true)}
                                                        >
                                                            <option value={''}>{`Please Select`}</option>
                                                            {awards.map((a) => (
                                                                <option key={a} value={a}>
                                                                    {a}
                                                                </option>
                                                            ))}
                                                        </Input>
                                                        {errors?.award?.message && (
                                                            <div>
                                                                <span className={'text-danger'}>
                                                                    {errors.award.message}
                                                                </span>
                                                            </div>
                                                        )}
                                                    </FormGroup>
                                                )}
                                            </>
                                        )}
                                    </CardBody>
                                </Card>
                                {recommendation.programProfessionId > 0 && (
                                    <Card>
                                        <CardBody>
                                            <h5 className={'card-title'}>{`Recommendation`}</h5>

                                            <FormGroup>
                                                <Label>{`Please select a recommendation status`}</Label>
                                                <Input type={'select'} innerRef={register()} name={'statusId'}>
                                                    <option value={-1}>{`Select...`}</option>
                                                    {recommendationStatuses.map((rs) => (
                                                        <option key={rs} value={rs}>
                                                            {ProgramStatus[rs]}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label>{`Is this program's sponsorship tranferring to a different institution?`}</Label>
                                                <div className={'d-flex'}>
                                                    <div className={'custom-control custom-radio mr-3'}>
                                                        <Input
                                                            type={'radio'}
                                                            className={'custom-control-input'}
                                                            id={'isTransfer'}
                                                            checked={isTransfer === true}
                                                            onChange={() => setIsTransfer(true)}
                                                        />
                                                        <Label
                                                            className={'custom-control-label font-weight-normal'}
                                                            for="isTransfer"
                                                        >{`Yes`}</Label>
                                                    </div>
                                                    <div className={'custom-control custom-radio'}>
                                                        <Input
                                                            type={'radio'}
                                                            className={'custom-control-input'}
                                                            id={'isNotTransfer'}
                                                            checked={isTransfer === false}
                                                            onChange={() => setIsTransfer(false)}
                                                        />
                                                        <Label
                                                            className={'custom-control-label font-weight-normal'}
                                                            for="isNotTransfer"
                                                        >{`No`}</Label>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        </CardBody>
                                    </Card>
                                )}
                                {recommendation.statusId > 0 && isTransfer === true && (
                                    <Card>
                                        <CardBody>
                                            <h5 className={'card-title'}>
                                                <span>{`Transferring from Sponsoring Institution`}</span>
                                                {searching.current === true && <Spinner className={'ml-2'} />}
                                            </h5>
                                            <h6
                                                className={'card-subtitle text-muted mb-3'}
                                            >{`Please use the filters below to locate the institution from which sponsorship for this program is transferring. If you are unable to find the institution, this program may not already be part of the CAAHEP Accreditation Management System (AMS).`}</h6>

                                            {errors?.transferFromInstitutionId?.message && (
                                                <div className={'mb-2'}>
                                                    <span className={'text-danger'}>
                                                        {errors.transferFromInstitutionId.message}
                                                    </span>
                                                </div>
                                            )}
                                            <div
                                                className={
                                                    'd-flex justify-content-between align-items-center flex-wrap'
                                                }
                                            >
                                                <FormGroup style={{ flexBasis: 'calc(100%)' }}>
                                                    <Label>{`Insitutiton Name`}</Label>
                                                    <Input
                                                        type={'text'}
                                                        value={fromName}
                                                        onChange={(e) => {
                                                            setFromName(e.target.value);
                                                        }}
                                                    />
                                                </FormGroup>
                                                <FormGroup style={{ flexBasis: 'calc(50% - 10px)' }}>
                                                    <Label>{`City`}</Label>
                                                    <Input
                                                        type={'text'}
                                                        value={fromCity}
                                                        onChange={(e) => {
                                                            setFromCity(e.target.value);
                                                        }}
                                                    />
                                                </FormGroup>
                                                <FormGroup style={{ flexBasis: 'calc(50% - 10px)' }}>
                                                    <Label>{`State`}</Label>
                                                    {States && (
                                                        <Input
                                                            type={'select'}
                                                            value={fromState}
                                                            onChange={(e) => setFromState(e.target.value)}
                                                        >
                                                            <option value={''}>{`Select...`}</option>
                                                            {States.map((s) => (
                                                                <option key={s.abbreviation} value={s.abbreviation}>
                                                                    {s.abbreviation}
                                                                </option>
                                                            ))}
                                                        </Input>
                                                    )}
                                                </FormGroup>
                                            </div>
                                            <FormGroup>
                                                <Label>
                                                    <span>{`Institutions (click to select)`}</span>
                                                </Label>
                                                <div className={'d-flex flex-wrap'}>
                                                    {fromInstitutions &&
                                                        fromInstitutions.map((i) => (
                                                            <Button
                                                                key={i.institutionId}
                                                                type={'button'}
                                                                color={'secondary'}
                                                                className={'mr-2 mb-2'}
                                                                onClick={() => selectFromInstitution(i, recommendation)}
                                                            >
                                                                <div>
                                                                    {i.institutionId ===
                                                                        +(
                                                                            recommendation.transferFromInstitutionId ||
                                                                            -1
                                                                        ) && <i className={'mdi mdi-check mr-2'} />}
                                                                    <span>{`${i.title} (${i.city}, ${i.state})`}</span>
                                                                </div>
                                                            </Button>
                                                        ))}
                                                </div>
                                            </FormGroup>
                                        </CardBody>
                                    </Card>
                                )}
                                {recommendation.statusId > 0 && isTransfer !== undefined && (
                                    <Card>
                                        <CardBody>
                                            <h5 className={'card-title'}>
                                                <span>{`${
                                                    isTransfer === true ? 'Transferring to' : ''
                                                } Sponsoring Institution`}</span>
                                                {searching.current === true && (
                                                    <Spinner className={'ml-2'} size={'xs'} />
                                                )}
                                            </h5>
                                            <h6
                                                className={'card-subtitle mb-3'}
                                            >{`Search for an existing institution. Click the institution to select. If not found, click “This is a new Institution” to create a new institution record.`}</h6>

                                            <div
                                                className={
                                                    'd-flex justify-content-between align-items-center flex-wrap'
                                                }
                                            >
                                                <FormGroup style={{ flexBasis: 'calc(100%)' }}>
                                                    <Label>{`Institution Name`}</Label>
                                                    <Input
                                                        type={'text'}
                                                        name={'institutionTitle'}
                                                        innerRef={register()}
                                                        onChange={(e) => {
                                                            searchInstitutions();
                                                            return e.target.value;
                                                        }}
                                                    />
                                                    {errors?.institutionTitle?.message && (
                                                        <div>
                                                            <span className={'text-danger'}>
                                                                {errors.institutionTitle.message}
                                                            </span>
                                                        </div>
                                                    )}
                                                </FormGroup>
                                                <FormGroup style={{ flexBasis: 'calc(50% - 10px)' }}>
                                                    <Label>{`City`}</Label>
                                                    <Input
                                                        type={'text'}
                                                        name={'institutionCity'}
                                                        innerRef={register()}
                                                        onChange={(e) => {
                                                            searchInstitutions();
                                                            return e.target.value;
                                                        }}
                                                    />
                                                    {errors?.institutionCity?.message && (
                                                        <div>
                                                            <span className={'text-danger'}>
                                                                {errors.institutionCity.message}
                                                            </span>
                                                        </div>
                                                    )}
                                                </FormGroup>
                                                <FormGroup style={{ flexBasis: 'calc(50% - 10px)' }}>
                                                    <Label>{`Country`}</Label>
                                                    {Countries && (
                                                        <Input
                                                            type={'select'}
                                                            innerRef={register()}
                                                            name={'institutionCountry'}
                                                            onChange={(e) => {
                                                                return e.target.value;
                                                            }}
                                                        >
                                                            <option value={''}>{`Select...`}</option>
                                                            {Countries.map((c) => (
                                                                <option key={c} value={c}>
                                                                    {c}
                                                                </option>
                                                            ))}
                                                        </Input>
                                                    )}
                                                    {errors?.institutionCountry?.message && (
                                                        <div>
                                                            <span className={'text-danger'}>
                                                                {errors.institutionCountry.message}
                                                            </span>
                                                        </div>
                                                    )}
                                                </FormGroup>
                                                <FormGroup style={{ flexBasis: 'calc(50% - 10px)' }}>
                                                    <Label>{`State`}</Label>
                                                    {recommendation.institutionCountry === 'United States of America' &&
                                                        States && (
                                                            <Input
                                                                type={'select'}
                                                                innerRef={register()}
                                                                name={'institutionState'}
                                                                onChange={(e) => {
                                                                    searchInstitutions();
                                                                    return e.target.value;
                                                                }}
                                                            >
                                                                <option value={''}>{`Select...`}</option>
                                                                {States.map((s) => (
                                                                    <option key={s.abbreviation} value={s.abbreviation}>
                                                                        {s.abbreviation}
                                                                    </option>
                                                                ))}
                                                            </Input>
                                                        )}
                                                    {recommendation.institutionCountry !==
                                                        'United States of America' && (
                                                        <Input
                                                            type={'text'}
                                                            name={'institutionState'}
                                                            innerRef={register()}
                                                            onChange={(e) => {
                                                                searchInstitutions();
                                                                return e.target.value;
                                                            }}
                                                        />
                                                    )}
                                                    {errors?.institutionState?.message && (
                                                        <div>
                                                            <span className={'text-danger'}>
                                                                {errors.institutionState.message}
                                                            </span>
                                                        </div>
                                                    )}
                                                </FormGroup>
                                            </div>
                                            <FormGroup>
                                                <div className={'d-flex align-items-center'}>
                                                    <Label className={'mr-3'}>
                                                        <span>{`Institutions`}</span>
                                                    </Label>
                                                    {recommendation.caahepinstitutionId > 0 && (
                                                        <Button
                                                            className={'btn-sm'}
                                                            color={'warning'}
                                                            type={'button'}
                                                            onClick={() => deselectInstitution(recommendation)}
                                                        >{`Unselect Institution`}</Button>
                                                    )}
                                                </div>
                                                <div className={'d-flex flex-wrap mt-2'}>
                                                    {existingInstitutions &&
                                                        existingInstitutions.map((i) => (
                                                            <Button
                                                                type={'button'}
                                                                color={'secondary'}
                                                                className={'mb-2 mr-2'}
                                                                key={i.institutionId}
                                                                onClick={() => {
                                                                    selectExistingInstitution(i, recommendation);
                                                                }}
                                                            >
                                                                <div>
                                                                    {+(recommendation.caahepinstitutionId || -1) ===
                                                                        i.institutionId && (
                                                                        <i className={'mdi mdi-check mr-2'} />
                                                                    )}
                                                                    <span>{`${i.title} (${i.city}, ${i.state})`}</span>
                                                                </div>
                                                            </Button>
                                                        ))}
                                                </div>
                                            </FormGroup>
                                            {(recommendation.caahepinstitutionId || -1) < 0 && (
                                                <FormGroup>
                                                    <div className={'custom-control custom-checkbox'}>
                                                        <Input
                                                            type={'checkbox'}
                                                            className={'custom-control-input'}
                                                            id={'isNewInstitution'}
                                                            checked={isNewInstitution}
                                                            onChange={(e) => setIsNewInstitution(e.target.checked)}
                                                        />
                                                        <Label
                                                            className={'custom-control-label font-weight-normal'}
                                                            for="isNewInstitution"
                                                        >{`This is a new Institution and does not exist within the list of existing Institutions.`}</Label>
                                                    </div>
                                                    {errors?.caahepinstitutionId?.message && (
                                                        <div>
                                                            <span className={'text-danger'}>
                                                                {errors.caahepinstitutionId.message}
                                                            </span>
                                                        </div>
                                                    )}
                                                </FormGroup>
                                            )}
                                        </CardBody>
                                    </Card>
                                )}
                                {isInitialStatusExistingProgram(recommendation) && (
                                    <Alert color={'warning'}>
                                        <p>{`Warning: You have indicated that an existing program is being recommended for an Initial accreditation status.`}</p>
                                    </Alert>
                                )}
                                {isNonInitialStatusNewProgram(recommendation) && (
                                    <Alert color={'warning'}>
                                        <p>{`Warning: You have indicated that a new program is being recommended for a Non-Initial accreditation status.`}</p>
                                    </Alert>
                                )}
                                {isTransferToSameInsitution(recommendation) && (
                                    <Alert color={'danger'}>
                                        <p>{`Error: You have indicated a sponsorship transfer between the same Insitution.`}</p>
                                    </Alert>
                                )}
                            </>
                        )}
                        {isTransfer !== undefined && (
                            <Card>
                                <CardBody>
                                    <h4 className={'card-title'}>{`Confirmation`}</h4>
                                    <FormGroup>
                                        <Label>{`Based on the selections above, please confirm that this information is accurate before proceeding with the recommendations...`}</Label>
                                        <div>
                                            {isTransfer === false && (
                                                <div className={'d-flex flex-column'}>
                                                    <h4>
                                                        <span className={'mr-1'}>{programTitle}</span>
                                                        <Badge color={'primary'}>{`${
                                                            +(recommendation.caahepprogramId || -1) > 0
                                                                ? 'existing'
                                                                : 'new'
                                                        } program`}</Badge>
                                                    </h4>
                                                    {recommendation.programAddOnTrackId &&
                                                        addOnTracks &&
                                                        recommendation.programAddOnTrackId > 0 && (
                                                            <h4>
                                                                <span className={'mr-1'}>
                                                                    {
                                                                        addOnTracks.find(
                                                                            (t) =>
                                                                                t.addOnTrackId ===
                                                                                +recommendation.programAddOnTrackId,
                                                                        )?.title
                                                                    }
                                                                </span>
                                                                <Badge color={'primary'}>{`${
                                                                    existingAddOnTrack ? 'existing' : 'new'
                                                                } add-on track`}</Badge>
                                                            </h4>
                                                        )}
                                                    <h4>
                                                        <span className={'mr-1'}>
                                                            {recommendation.institutionTitle}
                                                        </span>
                                                        <Badge color={'primary'}>{`${
                                                            recommendation.caahepinstitutionId > 0 ? `existing` : `new`
                                                        } institution`}</Badge>
                                                    </h4>
                                                </div>
                                            )}
                                            {isTransfer === true && (
                                                <div className={'d-flex flex-column'}>
                                                    <h4>
                                                        <span className={'mr-1'}>{programTitle}</span>
                                                        <Badge color={'primary'}>{`${
                                                            +(recommendation.caahepprogramId || -1) > 0
                                                                ? 'existing'
                                                                : 'new'
                                                        } program`}</Badge>
                                                    </h4>
                                                    <h4>
                                                        <span className={'mr-1'}>
                                                            {recommendation.institutionTitle}
                                                        </span>
                                                        <Badge color={'primary'}>{`${
                                                            recommendation.caahepinstitutionId > 0 ? `existing` : `new`
                                                        } institution`}</Badge>
                                                    </h4>
                                                    <h4>
                                                        <span className={'mr-1'}>
                                                            {
                                                                fromInstitutions?.find(
                                                                    (i) =>
                                                                        i.institutionId ===
                                                                        +recommendation.transferFromInstitutionId,
                                                                )?.title
                                                            }
                                                        </span>
                                                        <Badge color={'primary'}>{`transferring sponsorship`}</Badge>
                                                    </h4>
                                                </div>
                                            )}
                                        </div>
                                    </FormGroup>
                                </CardBody>
                            </Card>
                        )}
                    </CardBody>
                    <CardFooter>
                        <Button
                            type={'submit'}
                            color={'primary'}
                            disabled={isTransferToSameInsitution(recommendation)}
                        >{`Create Recommendation`}</Button>
                        <Button type={'button'} color={'secondary'} className={'ml-3'} onClick={() => onCancel()}>
                            {'Cancel'}
                        </Button>
                    </CardFooter>
                </Card>
            </Form>
        </>
    );
};

export default NewRecommendationForm;
