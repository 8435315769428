export const ReportingYears = [
    '2006',
    '2007',
    '2008',
    '2009',
    '2010',
    '2011',
    '2012',
    '2013',
    '2014',
    '2015',
    '2016',
    '2017',
    '2018',
    '2019',
    '2020',
    '2021',
];

export enum InstitutionType {
    'Four-year College or University' = 1,
    'Academic Health Center / Medical School' = 2,
    'Junior or Community College' = 3,
    'Vocational or Technical School' = 4,
    'Hospital or Medical Center' = 5,
    'Department of Defense' = 7,
    'Non-hospital Health Facility, BB or Lab' = 8,
    'N/A' = 10,
    'Consortium' = 11,
    'State, County, or Municipal Fire/EMS Services' = 22,
}

export const InstitutionTypes = [
    'Four-year College or University',
    'Academic Health Center / Medical School',
    'Junior or Community College',
    'Vocational or Technical School',
    'Hospital or Medical Center',
    'Department of Defense',
    'Non-hospital Health Facility, BB or Lab',
    'N/A',
    'Consortium',
    'State, County, or Municipal Fire/EMS Services',
];

export enum InstitutionControl {
    'For Profit' = 1,
    'State, County, or Government' = 2,
    'Non-Profit (Private or Religious)' = 3,
    'Federal Government' = 4,
    'N/A' = 5,
}

export const InstitutionControls = [
    'For Profit',
    'State, County, or Government',
    'Non-Profit (Private or Religious)',
    'Federal Government',
    'N/A',
];

export enum ProgramStatus {
    'Initial' = 3,
    'Continuing' = 2,
    'Probation' = 4,
    'Withhold' = 13,
    'Withdraw' = 7,
    'Voluntary Withdraw' = 9,
    'Transfer of Sponsorship - Initial' = 15,
    'Transfer of Sponsorship - Continuing' = 16,
    'Transfer of Sponsorship - Probation' = 17,
    'Inactive' = 10,
    'Letter of Review' = 11,
    'Not Applicable' = 12,
    'Transfer' = 6,
    'Expire' = 8,
}

export enum ProgramStatusActiveOnly {
    'Initial' = 3,
    'Continuing' = 2,
    'Probation' = 4,
    'Withhold' = 13,
    'Withdraw' = 7,
    'Voluntary Withdraw' = 9,
    'Inactive' = 10,
    'Letter of Review' = 11,
    'Not Applicable' = 12,
}

export enum InstitutionPersonRole {
    CEO = 1,
    Dean = 2,
    'Program Director' = 3,
    'Billing Contact' = 4,
}

export enum DegreeTypes {
    'Associate' = 1,
    'Bachelors' = 2,
    'Certificate' = 3,
    'Diploma' = 4,
    'Masters' = 5,
}

export const AwardTypes = ['Diploma', 'Certificate', 'Associates', 'Baccalaureate', 'Masters'];

export const CreditTypes = ['Contact Hours', 'Semester', 'Trimester', 'Quarter', 'Other'];

export enum Months {
    January = 1,
    February = 2,
    March = 3,
    April = 4,
    May = 5,
    June = 6,
    July = 7,
    August = 8,
    September = 9,
    October = 10,
    November = 11,
    December = 12,
}

export const Days = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
];

export enum SatelliteType {
    'Satellite Campus' = 1,
    'Clinical Affiliate' = 2,
}

export const SatelliteTypes = ['Satellite Campus', 'Clinical Affiliate'];

export enum InvoiceStatus {
    'Paid' = 1,
    'Partially Paid' = 2,
    'Unpaid' = 3,
    'Draft' = 4,
}

export enum PaymentType {
    'ACH' = 1,
    'Check' = 2,
    'Credit Card' = 3,
}

export const PaymentTypes = ['ACH', 'Check', 'Credit Card'];

export enum InvoiceType {
    'Annual' = 1,
    'Custom' = 2,
}

export const InvoiceTypes = ['Annual', 'Custom'];

export type State = {
    name: string;
    abbreviation: string;
};

export const ProbationReasons = ['Unpaid Program Accreditation Fees', 'Other'];

export const States: State[] = [
    {
        name: 'Alabama',
        abbreviation: 'AL',
    },
    {
        name: 'Alaska',
        abbreviation: 'AK',
    },
    {
        name: 'American Samoa',
        abbreviation: 'AS',
    },
    {
        name: 'Arizona',
        abbreviation: 'AZ',
    },
    {
        name: 'Arkansas',
        abbreviation: 'AR',
    },
    {
        name: 'California',
        abbreviation: 'CA',
    },
    {
        name: 'Colorado',
        abbreviation: 'CO',
    },
    {
        name: 'Connecticut',
        abbreviation: 'CT',
    },
    {
        name: 'Delaware',
        abbreviation: 'DE',
    },
    {
        name: 'District Of Columbia',
        abbreviation: 'DC',
    },
    {
        name: 'Federated States Of Micronesia',
        abbreviation: 'FM',
    },
    {
        name: 'Florida',
        abbreviation: 'FL',
    },
    {
        name: 'Georgia',
        abbreviation: 'GA',
    },
    {
        name: 'Guam',
        abbreviation: 'GU',
    },
    {
        name: 'Hawaii',
        abbreviation: 'HI',
    },
    {
        name: 'Idaho',
        abbreviation: 'ID',
    },
    {
        name: 'Illinois',
        abbreviation: 'IL',
    },
    {
        name: 'Indiana',
        abbreviation: 'IN',
    },
    {
        name: 'Iowa',
        abbreviation: 'IA',
    },
    {
        name: 'Kansas',
        abbreviation: 'KS',
    },
    {
        name: 'Kentucky',
        abbreviation: 'KY',
    },
    {
        name: 'Louisiana',
        abbreviation: 'LA',
    },
    {
        name: 'Maine',
        abbreviation: 'ME',
    },
    {
        name: 'Marshall Islands',
        abbreviation: 'MH',
    },
    {
        name: 'Maryland',
        abbreviation: 'MD',
    },
    {
        name: 'Massachusetts',
        abbreviation: 'MA',
    },
    {
        name: 'Michigan',
        abbreviation: 'MI',
    },
    {
        name: 'Minnesota',
        abbreviation: 'MN',
    },
    {
        name: 'Mississippi',
        abbreviation: 'MS',
    },
    {
        name: 'Missouri',
        abbreviation: 'MO',
    },
    {
        name: 'Montana',
        abbreviation: 'MT',
    },
    {
        name: 'Nebraska',
        abbreviation: 'NE',
    },
    {
        name: 'Nevada',
        abbreviation: 'NV',
    },
    {
        name: 'New Hampshire',
        abbreviation: 'NH',
    },
    {
        name: 'New Jersey',
        abbreviation: 'NJ',
    },
    {
        name: 'New Mexico',
        abbreviation: 'NM',
    },
    {
        name: 'New York',
        abbreviation: 'NY',
    },
    {
        name: 'North Carolina',
        abbreviation: 'NC',
    },
    {
        name: 'North Dakota',
        abbreviation: 'ND',
    },
    {
        name: 'Northern Mariana Islands',
        abbreviation: 'MP',
    },
    {
        name: 'Ohio',
        abbreviation: 'OH',
    },
    {
        name: 'Oklahoma',
        abbreviation: 'OK',
    },
    {
        name: 'Oregon',
        abbreviation: 'OR',
    },
    {
        name: 'Palau',
        abbreviation: 'PW',
    },
    {
        name: 'Pennsylvania',
        abbreviation: 'PA',
    },
    {
        name: 'Puerto Rico',
        abbreviation: 'PR',
    },
    {
        name: 'Rhode Island',
        abbreviation: 'RI',
    },
    {
        name: 'South Carolina',
        abbreviation: 'SC',
    },
    {
        name: 'South Dakota',
        abbreviation: 'SD',
    },
    {
        name: 'Tennessee',
        abbreviation: 'TN',
    },
    {
        name: 'Texas',
        abbreviation: 'TX',
    },
    {
        name: 'Utah',
        abbreviation: 'UT',
    },
    {
        name: 'Vermont',
        abbreviation: 'VT',
    },
    {
        name: 'Virgin Islands',
        abbreviation: 'VI',
    },
    {
        name: 'Virginia',
        abbreviation: 'VA',
    },
    {
        name: 'Washington',
        abbreviation: 'WA',
    },
    {
        name: 'West Virginia',
        abbreviation: 'WV',
    },
    {
        name: 'Wisconsin',
        abbreviation: 'WI',
    },
    {
        name: 'Wyoming',
        abbreviation: 'WY',
    },
];

export const Countries: string[] = [
    'United States of America',
    'Canada',
    'United Kingdom',
    'Saudi Arabia',
    'Albania',
    'Algeria',
    'American Samoa',
    'Andorra',
    'Angola',
    'Anguilla',
    'Antigua',
    'Argentina',
    'Armenia',
    'Aruba',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Barbuda',
    'Belgium',
    'Belize',
    'Benin',
    'Bermuda',
    'Bhutan',
    'Bolivia',
    'Bonaire',
    'Botswana',
    'Brazil',
    'British Virgin Islands',
    'Brunei',
    'Bulgaria',
    'Burundi',
    'Cambodia',
    'Cameroon',
    'Cape Verde',
    'Cayman Islands',
    'Central African Rep.',
    'Chad',
    'Channel Islands',
    'Chile',
    'China',
    'Colombia',
    'Congo',
    'Cook Islands',
    'Costa Rica',
    'Croatia',
    'Curacao',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Ethiopia',
    'Faeroe Islands',
    'Fiji',
    'Finland',
    'France',
    'French Guiana',
    'French Polynesia',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Gibraltar',
    'Great Britain',
    'Greece',
    'Greenland',
    'Grenada',
    'Guadeloupe',
    'Guam',
    'Guatemala',
    'Guinea',
    'Guinea Bissau',
    'Guyana',
    'Haiti',
    'Honduras',
    'Hong Kong',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iraq',
    'Iran',
    'Ireland',
    'Ireland, Northern',
    'Israel',
    'Italy',
    'Ivory Coast',
    'Jamaica',
    'Japan',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kuwait',
    'Kyrgyzstan',
    'Latvia',
    'Lebanon',
    'Liberia',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macau',
    'Macedonia',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Martinique',
    'Mauritania',
    'Mauritius',
    'Mexico',
    'Micronesia',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montserrat',
    'Morocco',
    'Mozambique',
    'Myanmar/Burma',
    'Namibia',
    'Nepal',
    'Netherlands',
    'Netherlands Antilles',
    'New Caledonia',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Norway',
    'Oman',
    'Palau',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Poland',
    'Portugal',
    'Puerto Rico',
    'Qatar',
    'Reunion',
    'Rwanda',
    'Saba',
    'Saipan',
    'Scotland',
    'Senegal',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Slovak Republic',
    'Slovenia',
    'South Africa',
    'South Korea',
    'Spain',
    'Sri Lanka',
    'Sudan',
    'Suriname',
    'Swaziland',
    'Sweden',
    'Switzerland',
    'Syria',
    'Taiwan',
    'Tanzania',
    'Thailand',
    'Togo',
    'Trinidad-Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'United Arab Emirates',
    'U.S. Virgin Islands',
    'Uganda',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Vatican City',
    'Venezuela',
    'Vietnam',
    'Wales',
    'Yemen',
    'Zaire',
    'Zambia',
    'Zimbabwe',
];

export enum DocumentActivityType {
    'Email Received' = 1,
    'Email Opened' = 2,
    'Link Clicked' = 3,
    Previewed = 4,
    Sent = 5,
    'Email Bounced' = 6,
    'Email Dropped' = 7,
    'Email Blocked' = 8,
}
