import { Card, CardBody, Col, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import { Button } from '../../components';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { AccreditationActionTotalReportDto, WithdrawStatusReportDto } from '../../common/Types';
import { reportService } from '../../services/ReportService';
import moment from 'moment';

const WithdrawStatusReport = () => {
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [report, setReport] = useState<WithdrawStatusReportDto>();

    const { register, handleSubmit } = useForm();

    const onSubmit = () => {
        const toastId = toast.info('Processing, this may take a minute...', {
            autoClose: false,
        });
        reportService
            .getWithdrawStatusReport(moment(startDate).format(), moment(endDate).format())
            .then((response) => {
                setReport(response);
                toast.update(toastId, {
                    type: 'success',
                    autoClose: 2500,
                });
            })
            .catch(() => toast.error('Failed to generate report'));
    };

    const exportReport = () => {
        const toastId = toast.info('Generating report, this may take a minute...', {
            autoClose: false,
        });
        reportService
            .exportWithdrawStatusReport(moment(startDate).format(), moment(endDate).format())
            .then((report) => {
                if (report) {
                    const url = window.URL.createObjectURL(report);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `VoluntaryWithdrawReport.xlsx`;
                    a.click();
                    toast.update(toastId, {
                        render: 'Report generated successfully.',
                        type: 'success',
                        autoClose: 2500,
                    });
                }
            })
            .catch(() => {
                toast.error(`Unable to generate report`);
            });
    };

    return (
        <>
            <Card>
                <CardBody>
                    <Row>
                        <Col>
                            <div className="mb-3">
                                <h3>Voluntary Withdraw Report</h3>
                            </div>
                            <Form onSubmit={handleSubmit(onSubmit)} inline>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                    <Label for="startDate" className="mr-sm-2">
                                        Start Date:
                                    </Label>
                                    <Input
                                        type="date"
                                        innerRef={register()}
                                        name="startDate"
                                        onChange={(e) => setStartDate(new Date(e.target.value))}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                    <Label for="endDate" className="mr-sm-2">
                                        End Date:
                                    </Label>
                                    <Input
                                        type="date"
                                        innerRef={register()}
                                        name="endDate"
                                        onChange={(e) => setEndDate(new Date(e.target.value))}
                                        required
                                    />
                                </FormGroup>
                                <div className="mb-2 mr-sm-2 mb-sm-2">
                                    <Button type="submit">Search</Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col style={{ display: 'flex' }}>
                            <div className="mr-2">
                                <Button color="info" onClick={() => exportReport()}>
                                    Export Report
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <div className="mt-3">
                        <h4>Programs with Voluntary Withdraw Status</h4>
                    </div>
                </CardBody>
                <Table>
                    <thead>
                        <tr>
                            <th>Program</th>
                            <th>Institution Name</th>
                            <th>Institution City</th>
                            <th>Institution State</th>
                            <th>Effective Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {report?.withdrawStatusDtos ? (
                            report.withdrawStatusDtos.map((record) => (
                                <tr key={record.programId}>
                                    <td>{record.programTitle}</td>
                                    <td>{record.institutionTitle}</td>
                                    <td>{record.institutionCity}</td>
                                    <td>{record.institutionState}</td>
                                    <td>{moment(record.withdrawEffectiveDate).format('MM/DD/YYYY')}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={7}>No Results</td>
                            </tr>
                        )}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>Total Records</th>
                            <th>{report?.totalCount}</th>
                        </tr>
                    </tfoot>
                </Table>
            </Card>
        </>
    );
};

export default WithdrawStatusReport;
