import React from 'react';

interface SpinnerProps {
    color?: string;
    className?: string;
    size?: 'xs' | 'sm' | 'md' | 'lg';
}

const Spinner = (props: SpinnerProps) => {
    const { color, className, size } = props;

    return (
        <div
            className={`spinner-border text-${color || 'primary'} ${className || ''} ${
                size ? (size === 'xs' ? 'spinner-border-sm' : `avatar-${size}`) : ''
            }`}
        ></div>
    );
};

export default Spinner;
