import * as yup from 'yup';

import { Button, Input } from '.';
import { Col, Form, Label, Row, Table } from 'reactstrap';
import { GeneralSettings, SurveyType, SurveyWorksheetResult } from '../common/Types';
import { useFieldArray, useForm } from 'react-hook-form';

import React from 'react';
import { programService } from '../services/ProgramService';
import { toast } from 'react-toastify';

interface SurveyWorksheetResultsFormProps {
    settings: GeneralSettings;
    surveyType: SurveyType;
    surveyResults: SurveyWorksheetResult[];
    selectedYear: number;
    onResultsUpdated: () => void;
    onCancel: () => void;
}

const SurveyWorksheetResultsForm = (props: SurveyWorksheetResultsFormProps) => {
    const { settings, surveyType, surveyResults, onResultsUpdated, onCancel, selectedYear } = props;

    const surveyWorksheetResultSchema = yup.object({
        results: yup.array().of(
            yup.object<SurveyWorksheetResult>({
                resultId: yup.number().required(),
                programId: yup.number().required(),
                surveyTypeId: yup.number().required().default(surveyType.surveyTypeId),
                questionId: yup.number().required(),
                graduatingYear: yup.number().required().default(selectedYear),
                number5: yup.number().required().min(0, 'Must be greater than 0'),
                number4: yup.number().notRequired().nullable(),
                number3: yup.number().notRequired().nullable(),
                number2: yup.number().notRequired().nullable(),
                number1: yup.number().notRequired().nullable(),
                numberNa: yup.number().required().min(0, 'Must be greater than 0'),
                numberOmitted: yup.number().required().min(0, 'Must be greater than 0'),
                surveysReturned: yup.number().notRequired().nullable(),
                totalEntered: yup
                    .number()
                    .when(
                        ['number5', 'numberNa', 'numberOmitted', 'surveysReturned'],
                        (number5, numberNa, numberOmitted, surveysReturned, schema) => {
                            if (number5 + numberNa + numberOmitted > surveysReturned) {
                                return schema
                                    .default(number5 + numberNa + numberOmitted)
                                    .max(
                                        surveyResults,
                                        `Total responses entered cannot be more than ${surveysReturned}`,
                                    );
                            }
                        },
                    ),
            }),
        ),
    });

    const {
        handleSubmit,
        register,
        control,
        errors,
        watch,
        formState: { isSubmitting },
    } = useForm({
        validationSchema: surveyWorksheetResultSchema,
        defaultValues: { results: surveyResults },
    });
    const { fields } = useFieldArray({
        control,
        name: 'results',
    });

    // Generates name of specific exam based on questionLabeType on General Settings
    const surveyTitle = (survey, index: number) => {
        const letters = [
            'A',
            'B',
            'C',
            'D',
            'E',
            'F',
            'G',
            'H',
            'I',
            'J',
            'K',
            'L',
            'M',
            'N',
            'O',
            'P',
            'Q',
            'R',
            'S',
            'T',
            'U',
            'V',
            'W',
            'X',
            'Y',
            'Z',
        ];
        const prefix = survey.surveyType?.prefix;
        const isLetters: boolean = settings.reportingWindow.questionLabelType === 'Letters';

        return isLetters ? <span>{`${prefix}-${letters[index]}`}</span> : <span>{`${prefix}-${index + 1}`}</span>;
    };

    const getSurveyTypeCutScore = (surveyTypeId: number): number => {
        if (surveyTypeId >= 6 && surveyTypeId <= 8) {
            return +(settings?.reportingWindow?.employeeSurveyCutScore || 0);
        }

        return +(settings?.reportingWindow?.graduateSurveyCutScore || 0);
    };

    const getSurveyTypeSatisfactionThreshold = (surveyTypeId: number): number => {
        if (surveyTypeId >= 6 && surveyTypeId <= 8) {
            return settings.reportingWindow.employeeSurveySatisfactionThreshold || 0;
        }

        return settings.reportingWindow.graduateSurveySatisfactionThreshold || 0;
    };

    const getNumberAboveCutScore = (cutScore: number, surveyResult: SurveyWorksheetResult): number => {
        if (cutScore === 0) {
            return (
                (surveyResult.number1 || 0) +
                (surveyResult.number2 || 0) +
                (surveyResult.number3 || 0) +
                (surveyResult.number4 || 0) +
                (surveyResult.number5 || 0)
            );
        } else {
            return surveyResult.number5 || 0;
        }
    };

    const isPositive = (result: any): boolean => {
        const numberAboveCut = getNumberAboveCutScore(getSurveyTypeCutScore(result.surveyTypeId) || 0, result);
        const numberNaOrOmitted = (Number(result.numberNa) || 0) + (Number(result.numberOmitted) || 0);
        const calculationResponses = (Number(result.surveysReturned) || 0) - numberNaOrOmitted;

        if (calculationResponses < 0) {
            return false;
        }
        if (calculationResponses > 0) {
            if (
                (numberAboveCut / calculationResponses) * 100 >=
                getSurveyTypeSatisfactionThreshold(result.surveyTypeId)
            ) {
                return true;
            }
        } else {
            return true;
        }

        return false;
    };

    const onSubmit = (formData): Promise<void> => {
        return new Promise((res) => {
            const toastId = toast.info('Saving survey data...');

            programService
                .saveSurveyWorksheetResult(formData.results)
                .then((response) => {
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Survey result data saved.',
                    });

                    onResultsUpdated();
                })
                .catch(() => {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Failed to save survey result data.',
                    });
                })
                .finally(() => {
                    res();
                });
        });
    };

    const watchResults = watch('results');

    console.log(errors);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th></th>
                                <th>{`# Returned`}</th>
                                {getSurveyTypeCutScore(surveyType.surveyTypeId) === 0 && (
                                    <>
                                        <th>5s</th>
                                        <th>4s</th>
                                        <th>3s</th>
                                        <th>2s</th>
                                        <th>1s</th>
                                    </>
                                )}
                                {getSurveyTypeCutScore(surveyType.surveyTypeId) > 0 && (
                                    <th>{`>= ${getSurveyTypeCutScore(surveyType.surveyTypeId)}`}</th>
                                )}
                                <th>{`N/A`}</th>
                                <th>{`Omitted`}</th>
                                <th>{`Pos / Neg`}</th>
                            </tr>
                        </thead>
                        {fields &&
                            fields.map((survey, i) => (
                                <tbody key={i}>
                                    <tr>
                                        <td style={{ whiteSpace: 'nowrap' }}>{surveyTitle(survey, i)}</td>
                                        <td>{survey.surveysReturned}</td>
                                        {getSurveyTypeCutScore(surveyType.surveyTypeId) === 0 && (
                                            <>
                                                <td>
                                                    <Input
                                                        type={`number`}
                                                        name={`results[${i}].number5`}
                                                        defaultValue={survey.number5}
                                                        style={{ width: '100px' }}
                                                        innerRef={register()}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        type={`number`}
                                                        name={`results[${i}].number4`}
                                                        defaultValue={survey.number4}
                                                        style={{ width: '100px' }}
                                                        innerRef={register()}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        type={`number`}
                                                        name={`results[${i}].number3`}
                                                        defaultValue={survey.number3}
                                                        style={{ width: '100px' }}
                                                        innerRef={register()}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        type={`number`}
                                                        name={`results[${i}].number2`}
                                                        defaultValue={survey.number2}
                                                        style={{ width: '100px' }}
                                                        innerRef={register()}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        type={`number`}
                                                        name={`results[${i}].number1`}
                                                        defaultValue={survey.number1}
                                                        style={{ width: '100px' }}
                                                        innerRef={register()}
                                                    />
                                                </td>
                                            </>
                                        )}
                                        {getSurveyTypeCutScore(surveyType.surveyTypeId) > 0 && (
                                            <td>
                                                <Input
                                                    type={`number`}
                                                    name={`results[${i}].number5`}
                                                    defaultValue={survey.number5}
                                                    style={{ width: '100px' }}
                                                    innerRef={register()}
                                                />
                                            </td>
                                        )}
                                        <td>
                                            <Input
                                                type={`number`}
                                                name={`results[${i}].numberNa`}
                                                defaultValue={survey.numberNa}
                                                style={{ width: '100px' }}
                                                innerRef={register()}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                type={`number`}
                                                name={`results[${i}].numberOmitted`}
                                                defaultValue={survey.numberOmitted}
                                                style={{ width: '100px' }}
                                                innerRef={register()}
                                            />
                                        </td>
                                        <td>
                                            <div>
                                                {isPositive(watchResults[i]) && (
                                                    <i
                                                        style={{ fontSize: '22px' }}
                                                        className={'mdi mdi-checkbox-marked'}
                                                    ></i>
                                                )}
                                                {!isPositive(watchResults[i]) && (
                                                    <i
                                                        style={{ fontSize: '22px' }}
                                                        className={'mdi mdi-close-box text-danger'}
                                                    ></i>
                                                )}
                                            </div>
                                            <input
                                                type="hidden"
                                                name={`results[${i}].resultId`}
                                                defaultValue={survey.resultId}
                                                ref={register()}
                                            />
                                            <input
                                                type="hidden"
                                                name={`results[${i}].programId`}
                                                defaultValue={survey.programId}
                                                ref={register()}
                                            />
                                            <input
                                                type="hidden"
                                                name={`results[${i}].surveyTypeId`}
                                                defaultValue={survey.surveyTypeId}
                                                ref={register()}
                                            />
                                            <input
                                                type="hidden"
                                                name={`results[${i}].questionId`}
                                                defaultValue={survey.questionId}
                                                ref={register()}
                                            />
                                            <input
                                                type="hidden"
                                                name={`results[${i}].graduatingYear`}
                                                defaultValue={survey.graduatingYear}
                                                ref={register()}
                                            />
                                            <input
                                                type="hidden"
                                                name={`results[${i}].surveysReturned`}
                                                defaultValue={survey.surveysReturned}
                                                ref={register()}
                                            />
                                            <input
                                                type="hidden"
                                                name={`results[${i}].positive`}
                                                defaultValue={survey.positive}
                                                ref={register()}
                                            />
                                            <input
                                                type="hidden"
                                                name={`results[${i}].percentGreaterThanEqualToThree`}
                                                defaultValue={survey.percentGreaterThanEqualToThree}
                                                ref={register()}
                                            />
                                        </td>
                                    </tr>
                                    {errors?.results && errors.results[i]?.totalEntered?.message && (
                                        <tr>
                                            <td colSpan={getSurveyTypeCutScore(surveyType.surveyTypeId) === 0 ? 10 : 6}>
                                                <Label className={'text-danger'}>
                                                    {errors.results[i]?.totalEntered?.message}
                                                </Label>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            ))}
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button disabled={isSubmitting} type="submit" color={'primary'}>
                        Save
                    </Button>
                    <Button
                        type={'button'}
                        color={'secondary'}
                        className={'ml-2'}
                        onClick={onCancel}
                    >{`Cancel`}</Button>
                </Col>
            </Row>
        </Form>
    );
};

export default SurveyWorksheetResultsForm;
