import {
    getNullableBooleanFromString,
    getNullableDateFromString,
    getNullableNumberFromString,
    getNullableStringFromString,
} from './Utils';

import { isArray } from 'util';

export interface Coa {
    id: number;
    coaTitle: string;
    active: boolean;
}

export class Coa {
    id: number;
    coaTitle: string;
    active: boolean;
    professions?: ProfessionDto[];
    constructor(coa) {
        this.id = +coa.id;
        this.coaTitle = coa.coaTitle;
        this.active = coa.active.toString() === 'true';
        this.professions = coa.professions;
    }
}

export interface ProfessionDto {
    professionId: number;
    title: string;
    isDeleted: boolean;
    webSiteSearchCount: number;
    armsUser: boolean;
    showDegreeOnCertificate: boolean;
    coaId?: number | null;
    coaTitle: string;
    concentrations?: ProfessionsConcentrationDto[];
    addOnTracks?: ProfessionAddOnTrack[];
}

export class ProfessionDto {
    professionId: number;
    title: string;
    isDeleted: boolean;
    webSiteSearchCount: number;
    armsUser: boolean;
    coaId?: number | null;
    coaTitle: string;
    isOneDegreePerProgram: boolean;
    constructor(profession) {
        this.professionId = +profession.professionId;
        this.title = profession.title;
        this.isDeleted = profession.isDeleted.toString() === 'true';
        this.webSiteSearchCount = +profession.webSiteSearchCount;
        this.armsUser = profession.armsUser.toString() === 'true';
        this.coaId = getNullableNumberFromString(profession.coaId);
        this.coaTitle = profession.coaTitle;
        this.isOneDegreePerProgram = profession.isOneDegreePerProgram.toString() === 'true';
    }
}

export interface ProfessionDetailsDto extends ProfessionDto {
    coa: CoaDto;
    professionLengthOfInitial: ProfessionLengthOfInitialDto[];
    professionStandards: ProfessionStandardsDto[];
    professionStandardsDesignation: ProfessionStandardsDesignationDto[];
    professionsConcentrations: ProfessionsConcentrationDto[];
    //programs: ProgramDto[];
}

export interface ProfessionsConcentrationDto {
    concentrationId: number;
    professionId: number;
    title: string;
}

export interface ProfessionStandardsDesignationSubStandardRationaleDto {
    rationaleId: number;
    subStandardId: number;
    rationale: string;
    suggestedDocumentation: string;
}

export interface ProfessionStandardsDesignationSubStandardDto {
    subStandardId: number;
    standardsDesignationId: number;
    description: string;
    isDeleted: boolean;
    professionStandardsDesignationSubStandardRationale?: ProfessionStandardsDesignationSubStandardRationaleDto[];
}

export interface ProfessionStandardsDesignationDto {
    standardsDesignationId: number;
    professionId?: number;
    designationNumber: string;
    designationTitle: string;
    designationDescription: string;
    createdBy?: string;
    dateCreated?: string;
    lastEdited?: string;
    editedBy?: string;
    isDeleted?: boolean;
    createdByString?: string;
    editedByString?: string;
    professionStandardsDesignationSubStandard?: ProfessionStandardsDesignationSubStandardDto[];
}

export interface ProfessionStandardsDto {
    professionStandardId: number;
    professionId?: number;
    standardYear?: number;
    createdBy?: string;
    dateCreated?: string;
    lastEdited?: string;
    isDeleted?: boolean;
    createdByString?: string;
    editedByString?: string;
}

export interface ProfessionLengthOfInitialDto {
    lengthId: number;
    professionId?: number;
    initialLength?: number;
}

export interface ProfessionDetail {
    professionId: number;
    title: string;
    isDeleted: boolean;
    armsUser: boolean;
    showDegreeOnCertificate: boolean;
    coaId?: number | null;
    lengthOfInitial?: ProfessionLengthOfInitialDto | null;
    isOneDegreePerProgram: boolean;
}

export interface CoaDto {
    id: number;
    professions: ProfessionDto[];
    coaTitle: string;
    abb: string;
    fullName: string;
    title: string;
    address: string;
    address2: string;
    address3: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
    fax: string;
    email: string;
    sponsors: string;
    submitPrto: string;
    chairFullName: string;
    ChairSignature: string;
    isActive: boolean;
}

export interface NewCoaDto {
    id: number;
    coaTitle: string;
    abb: string;
    isActive: boolean;
}

export interface InstitutionSearchResult {
    echo: number;
    institutions: Institution[];
}

export class Institution {
    institutionId: number;
    institutionTypeId: number | null;
    auspiceId: number | null;
    title: string;
    city?: string | null;
    state?: string | null;
    address?: string | null;
    address2?: string | null;
    address3?: string | null;
    address4?: string | null;
    zip?: string | null;
    country?: string | null;
    phone?: string | null;
    extension?: string | null;
    fax?: string | null;
    webSite?: string | null;
    ceoId?: number | null;
    createdBy?: string | null;
    dateCreated?: Date | null;
    lastEdited?: Date | null;
    editedBy?: string | null;
    editedByString?: string | null;
    active: boolean;
    preserveBillingInformation: boolean | null;
    synchronizeCeoInstitution: boolean | null;
    institutionType?: string;
    institutionalAccreditorId?: number | null;
    nameHistory?: InstitutionNameHistory[];

    constructor(institution) {
        this.institutionId = +institution.institutionId;
        this.institutionTypeId = getNullableNumberFromString(institution.institutionTypeId);
        this.auspiceId = getNullableNumberFromString(institution.auspiceId);
        this.title = institution.title;
        this.city = getNullableStringFromString(institution.city);
        this.state = getNullableStringFromString(institution.state);
        this.address = getNullableStringFromString(institution.address);
        this.address2 = getNullableStringFromString(institution.address2);
        this.address3 = getNullableStringFromString(institution.address3);
        this.address4 = getNullableStringFromString(institution.address4);
        this.zip = getNullableStringFromString(institution.zip);
        this.country = getNullableStringFromString(institution.country);
        this.phone = getNullableStringFromString(institution.phone);
        this.extension = getNullableStringFromString(institution.extension);
        this.fax = getNullableStringFromString(institution.fax);
        this.webSite = getNullableStringFromString(institution.webSite);
        this.ceoId = getNullableNumberFromString(institution.ceoId);
        this.createdBy = getNullableStringFromString(institution.createdBy);
        this.dateCreated = getNullableDateFromString(institution.dateCreated);
        this.lastEdited = getNullableDateFromString(institution.lastEdited);
        this.editedBy = getNullableStringFromString(institution.editedBy);
        this.active = institution.active.toString() === 'true';
        this.preserveBillingInformation = institution.preserveBillingInformation.toString() === 'true';
        this.synchronizeCeoInstitution = institution.synchronizeCeoInstitution.toString() === 'true';
        this.institutionType = institution.institutionType;
        this.institutionalAccreditorId = getNullableNumberFromString(institution.institutionalAccreditorId);
        this.nameHistory = institution.nameHistory || [];
    }
}

export interface InstitutionDetail extends Institution {
    programDto: ProgramDto[];
}

export class InstitutionCoAMemberDto {
    institutionMemberId: number;
    institutionId: number | null;
    userId: number | null;
    createdBy: string | null;
    dateCreated: Date | null;

    constructor(member) {
        this.institutionMemberId = +member.institutionMemberId;
        this.institutionId = getNullableNumberFromString(member.institutionId);
        this.userId = getNullableNumberFromString(member.userId);
        this.createdBy = getNullableStringFromString(member.createdBy);
        this.dateCreated = getNullableDateFromString(member.dateCreated);
    }
}

export class InstitutionPeopleDto {
    institutionPeopleId: number;
    institutionId: number | null;
    programId: number | null;
    peopleId: number | null;
    roleId: number | null;
    dateCreated: Date | null;
    role: { roleId: number; title: string };
    people: PersonDto | null;
    active: boolean;

    constructor(people: any) {
        this.institutionPeopleId = +people.institutionPeopleId;
        this.institutionId = getNullableNumberFromString(people.institutionId);
        this.programId = getNullableNumberFromString(people.programId);
        this.peopleId = getNullableNumberFromString(people.peopleId);
        this.roleId = getNullableNumberFromString(people.roleId);
        this.dateCreated = getNullableDateFromString(people.dateCreated);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        this.people = people.people ? new PersonDto(people.people) : null;
        this.role = people.role;
        this.active = people.active === true ? people.active : people.active === false ? people.active : true;
    }
}

export enum PersonnelPersonStatus {
    unknown,
    removed,
    modified,
    same,
    new,
}

export class ProgramPersonnelDto {
    programPersonnelId: number;
    programId: number;
    personnelTypeId: number;
    peopleId: number;
    visible: boolean;
    people: PersonDto | null;
    reportYear: number;
    personnelStatus?: PersonnelPersonStatus | null;
    personnelType?: PersonnelSettings;
    satelliteId?: number | null;

    constructor(people: any) {
        this.programPersonnelId = +people.programPersonnelId;
        this.programId = +people.programId;
        this.peopleId = +people.peopleId;
        this.personnelTypeId = +people.personnelTypeId;
        this.visible = getNullableBooleanFromString(people.visible) || false;
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        this.people = people.people ? new PersonDto(people.people) : null;
        this.reportYear = +people.reportYear;
        this.personnelStatus = +people.personnelStatus;
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        this.personnelType = people.personnelType && new PersonnelSettings(people.personnelType);
        this.satelliteId = getNullableNumberFromString(people.satelliteId);
    }
}

export class PersonDto {
    peopleId: number;
    firstName?: string;
    lastName?: string;
    address?: string;
    address2?: string;
    address3?: string;
    address4?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
    phone?: string;
    extension?: string;
    fax?: string;
    title?: string;
    credentials?: string;
    salutations?: string;
    email?: string;
    createdBy?: string;
    dateCreated?: string;
    lastEdited?: string;
    editedBy?: string;
    active: boolean;
    editedByString?: string;

    constructor(per: any) {
        this.peopleId = +per.peopleId;
        this.firstName = per.firstName;
        this.lastName = per.lastName;
        this.address = per.address;
        this.address2 = per.address2;
        this.address3 = per.address3;
        this.address4 = per.address4;
        this.city = per.city;
        this.state = per.state;
        this.zip = per.zip;
        this.country = per.country;
        this.phone = per.phone;
        this.extension = per.extension;
        this.fax = per.fax;
        this.title = per.title;
        this.credentials = per.credentials;
        this.salutations = per.salutations;
        this.email = per.email;
        this.createdBy = per.createdBy;
        this.dateCreated = per.dateCreated;
        this.lastEdited = per.lastEdited;
        this.editedBy = per.editedBy;
        this.active = per.active.toString() === 'true';
        this.editedByString = per.editedByString;
    }
}

export class ProgramDto {
    programId: number;
    professionId: number | null;
    concentrationId?: number | null;
    institutionId: number | null;
    title: string;
    degreeDiploma: boolean | null;
    degreeCertificate: boolean | null;
    degreeAssociate: boolean | null;
    degreeBaccalaureate: boolean | null;
    degreeMasters: boolean | null;
    dateFirstAccredited?: Date | null;
    distance: boolean | null;
    militaryPersonnelOnly: boolean | null;
    website?: string | null;
    visible: boolean | null;
    createdBy?: string | null;
    dateCreated?: Date | null;
    lastEdited?: Date | null;
    editedBy?: string | null;
    active: boolean | null;
    credentilingCode?: string | null;
    onProbation: boolean | null;
    probationReason?: string | null;
    probationStartDate?: Date | null;
    statusId?: number | null;
    activeStatus?: number | null;
    programName?: string | null;
    programAddressId?: number | null;
    programAddress: ProgramAddress | null = null;

    constructor(program) {
        this.programId = +program.programId;
        this.professionId = getNullableNumberFromString(program.professionId);
        this.concentrationId = getNullableNumberFromString(program.concentrationId);
        this.institutionId = getNullableNumberFromString(program.institutionId);
        this.title = program.title;
        this.degreeDiploma = getNullableBooleanFromString(program.degreeDiploma);
        this.degreeCertificate = getNullableBooleanFromString(program.degreeCertificate);
        this.degreeAssociate = getNullableBooleanFromString(program.degreeAssociate);
        this.degreeBaccalaureate = getNullableBooleanFromString(program.degreeBaccalaureate);
        this.degreeMasters = getNullableBooleanFromString(program.degreeMasters);
        this.dateFirstAccredited = getNullableDateFromString(program.dateFirstAccredited);
        this.distance = getNullableBooleanFromString(program.distance);
        this.militaryPersonnelOnly = getNullableBooleanFromString(program.militaryPersonnelOnly);
        this.website = getNullableStringFromString(program.website);
        this.visible = getNullableBooleanFromString(program.visible);
        this.createdBy = getNullableStringFromString(program.createdBy);
        this.dateCreated = getNullableDateFromString(program.dateCreated);
        this.lastEdited = getNullableDateFromString(program.lastEdited);
        this.editedBy = getNullableStringFromString(program.editedBy);
        this.active = getNullableBooleanFromString(program.active);
        this.credentilingCode = getNullableStringFromString(program.credentilingCode);
        this.onProbation = getNullableBooleanFromString(program.onProbation);
        this.probationReason = getNullableStringFromString(program.probationReason);
        this.probationStartDate = getNullableDateFromString(program.probationStartDate);
        this.statusId = getNullableNumberFromString(program.statusId);
        this.activeStatus = getNullableNumberFromString(program.activeStatus);
        this.programName = getNullableStringFromString(program.programName);
        this.programAddressId = getNullableNumberFromString(program.programAddressId);
        this.programAddress = program.programAddress;
    }
}

export interface AmsDocument {
    documentId: number;
    institutionId: number;
    programId?: number;
    addOnId?: number;
    title: string;
    fileUrl: string;
    createdBy?: string;
    dateCreated?: Date;
    lastEdited?: Date;
    editedBy?: string;
    active: boolean;
    isAwardLetter?: boolean;
    createdByString?: string;
    editedByString?: string;
    fileDownloadName: string;
    isDistributed: boolean;
    documentActivity?: DocumentActivity[];
    isPublic: boolean;
}

export interface SignatureDocument {
    coaId: number;
    fileUrl: string;
    fileDownloadName: string;
    title: string;
    isPublic: boolean;
    documentId: number;
}

export class DocumentCategory {
    categoryId: number | null = null;
    isActive: boolean | null = null;
    isDeleted: boolean | null = null;
    professionId: number | null = null;
    title: string | null = null;
    constructor(init?: Partial<DocumentUpload>) {
        Object.assign(this, init);
    }
}

export class RelatedDocumentDto {
    documentId: number | null = null;
    categoryId: number | null = null;
    activeYear: number | null = null;
    programId: number | null = null;
    title: string | null = null;
    fileUrl: string | null = null;
    createdBy: string | null = null;
    dateCreated: Date | null = null;
    lastEdited: Date | null = null;
    editedBy: string | null = null;
    isDeleted: boolean | null = null;
    isAwardLetter: boolean | null = null;
    createdByString: string | null = null;
    editedByString: string | null = null;
    fileDownloadName: string | null = null;
    category: DocumentCategory | null = null;
    isDistributed = true;
    documentActivity?: DocumentActivity[] = [];
    isPublic = false;

    constructor(init?: Partial<RelatedDocumentDto>) {
        Object.assign(this, init);
        this.documentId = getNullableNumberFromString(init?.documentId);
        this.categoryId = getNullableNumberFromString(init?.categoryId);
        this.activeYear = getNullableNumberFromString(init?.activeYear);
        this.programId = getNullableNumberFromString(init?.programId);
        this.isDeleted = getNullableBooleanFromString(init?.isDeleted);
        this.isDistributed = init?.isDistributed === true;
        this.documentActivity = init?.documentActivity;
    }
}

export interface ProgramAmsSatellite {
    satelliteId: number;
    programId: number;
    title: string;
    active: boolean;
    createdBy?: string;
    dateCreated?: Date;
    lastEdited?: Date;
    editedBy?: string;
    createdByString?: string;
    editedByString?: string;
    city?: string;
    state?: string;
}

export interface ProgramProbation {
    probationId: number;
    programId: number;
    probationReason: string;
    probationStartDate: Date;
    probationEndDate?: Date;
    createdBy?: string;
    dateCreated?: Date;
    lastEdited?: Date;
    editedBy?: string;
    editedByString?: string;
    createdByString?: string;
    recipients: string[];
    additionalRecipients: string[];
    subject: string;
    message: string;
    probationActivity?: ProgramProbationActivity[];
}

export interface ProgramProbationActivity {
    id: number;
    programId: number;
    probationId: number;
    messageId?: string;
    activityTypeId?: number;
    userEmail?: string;
    timeStamp?: Date;
    message?: string;
    createdByString?: string;
    editedByString?: string;
    lastEdited?: Date | null;
}

export interface ProgramCertificate {
    fileName: string;
    dateCreated?: Date;
}

export class OutcomesType {
    customId: number | null;
    professionId: number;
    outcomeTypeId: number | null;
    title: string;
    threshold: number | null;
    isActive: boolean;
    addOnTrackId: number | null;
    concentrationIds: string | null;
    constructor(outcomes) {
        this.customId = getNullableNumberFromString(outcomes.customId);
        this.professionId = +outcomes.professionId;
        this.outcomeTypeId = getNullableNumberFromString(outcomes.outcomeTypeId);
        this.title = outcomes.title;
        this.threshold = getNullableNumberFromString(outcomes.threshold);
        this.isActive = outcomes.isActive.toString() === 'true';
        this.addOnTrackId = getNullableNumberFromString(outcomes.addOnTrackId);
        this.concentrationIds = outcomes.concentrationIds;
    }
}

export class OutcomeTypeCustomization {
    customId: number | null;
    professionId: number;
    outcomeTypeId: number | null;
    title = '';
    threshold: number | null;
    isActive: boolean;
    userType: string | null = null;
    visible: boolean | null = null;
    concentrationId: number | null = null;
    addOnTrackId: number | null = null;
    constructor(outcomes) {
        this.customId = getNullableNumberFromString(outcomes.customId);
        this.professionId = +outcomes.professionId;
        this.outcomeTypeId = getNullableNumberFromString(outcomes.outcomeTypeId);
        this.title = outcomes.title;
        this.threshold = getNullableNumberFromString(outcomes.threshold);
        this.isActive = outcomes.isActive.toString() === 'true';
        this.userType = getNullableStringFromString(outcomes.userType);
        this.visible = getNullableBooleanFromString(outcomes.visible);
        this.concentrationId = getNullableNumberFromString(outcomes.concentrationId);
        this.addOnTrackId = getNullableNumberFromString(outcomes.addOnTrackId);
    }
}

export interface CredentialingExamProgramOutcome {
    customId: number;
    professionId: number;
    outcomeTypeId: number;
    title: string;
    isActive: boolean;
    currentThreshold: number;
    concentrationId?: number;
    addOnTrackId?: number;
    concentrationIds?: string;
    detailId: number;
    programId: number;
    detailedAnalysis?: string;
    actionPlan?: string;
    reportYear: number;
    reportWindowStartYear: number;
    reportWindowEndYear: number;
    createdByString: string;
    editedByString: string;
    outcomeThreshold: number;
    oneYearAverage?: number;
    threeYearAverage?: number;
    reportWindowAverage?: number;
    results: CredentialingExamProgramResult[];
}

export interface CredentialingExamProgramResult {
    id: number;
    outcomeTypeId: number;
    programId: number;
    graduatingYear: number;
    numberOfGradsAttemptingExam?: number | null;
    passing1stAttempt?: number | null;
    passingSubsequentAttempts?: number | null;
    reportYear: number;
    total?: number;
}

export interface GeneralSettings {
    outcomeTypesCustomizations: OutcomesType[];
    reportingWindow: BaseGeneralSetting;
    originalReportingWindow?: BaseGeneralSetting;
}

export class PersonnelSettings {
    id: number;
    professionId: number;
    personnelTypeId: number;
    title: string;
    priority: number;
    editable: boolean;
    selected: boolean;
    isActive: boolean;
    isCustom: boolean;
    isAdvisoryCommittee: boolean;
    lockChangesToData: boolean;

    constructor(setting: any) {
        this.id = Number(setting.id);
        this.professionId = Number(setting.professionId);
        this.personnelTypeId = Number(setting.personnelTypeId);
        this.title = setting.title;
        this.priority = Number(setting.priority);
        this.editable = setting.editable.toString() === 'true';
        this.selected = setting.selected.toString() === 'true';
        this.isActive = setting.isActive.toString() === 'true';
        this.isAdvisoryCommittee = JSON.parse(setting.isAdvisoryCommittee);
        this.isCustom = JSON.parse(setting.isCustom);
        this.lockChangesToData = JSON.parse(setting.lockChangesToData);
    }
}

export interface FormGroupProps {
    label: string;
    name: string;
    value: any;
    type?: 'text' | 'number' | 'email' | 'select' | 'checkbox' | 'radio' | 'hidden';
    errorMessage?: string;
    valueOptions?: { key: string | number; value: string | number; label: string }[];
}

export class BaseGeneralSetting {
    id: number;
    activeYear: number | null;
    attritionRetentionThreshold = 0;
    professionId: number;
    cutScore: string | null;
    employeeSurveyCutScore: string | null;
    employeeSurveySatisfactionThreshold = 0;
    employeeSurveyMeasureType = 'Returned';
    employerSurveysThreshold = 0;
    esaffectiveQuestions = 0;
    escognitiveQuestions = 0;
    espsychomotorQuestions = 0;
    graduateSurveyCutScore: string | null;
    graduateSurveySatisfactionThreshold = 0;
    graduateSurveysThreshold = 0;
    graduateSurveyMeasureType = 'Returned';
    gsaffectiveQuestions = 0;
    gscognitiveQuestions = 0;
    gspsychomotorQuestions = 0;
    outcomeMeasure: string | null;
    positivePlacementThreshold = 0;
    questionLabelType: string | null;
    reportingWindow?: number | null;
    standardsTabMeetingCount = 0;
    title: string | null;
    yearAvarageStart: number | null;
    yearAvarage: number | null;
    annualReportMailbox: string | null;
    constructor(professionId, settings: any) {
        this.id = +settings.id;
        this.activeYear = getNullableNumberFromString(settings.activeYear);
        this.attritionRetentionThreshold =
            getNullableNumberFromString(settings.attritionRetentionThreshold) ?? this.attritionRetentionThreshold;
        this.yearAvarageStart = getNullableNumberFromString(settings.yearAvarageStart);
        this.yearAvarage = getNullableNumberFromString(settings.yearAvarage);
        this.cutScore = getNullableStringFromString(settings.cutScore);
        this.employeeSurveyCutScore = getNullableStringFromString(settings.employeeSurveyCutScore);
        this.employeeSurveySatisfactionThreshold =
            getNullableNumberFromString(settings.employeeSurveySatisfactionThreshold) ??
            this.employeeSurveySatisfactionThreshold;
        this.employeeSurveyMeasureType =
            getNullableStringFromString(settings.employeeSurveyMeasureType) ?? this.employeeSurveyMeasureType;
        this.employerSurveysThreshold =
            getNullableNumberFromString(settings.employerSurveysThreshold) ?? this.employerSurveysThreshold;
        this.esaffectiveQuestions =
            getNullableNumberFromString(settings.esaffectiveQuestions) ?? this.esaffectiveQuestions;
        this.escognitiveQuestions =
            getNullableNumberFromString(settings.escognitiveQuestions) ?? this.escognitiveQuestions;
        this.espsychomotorQuestions =
            getNullableNumberFromString(settings.espsychomotorQuestions) ?? this.espsychomotorQuestions;
        this.graduateSurveyCutScore = getNullableStringFromString(settings.graduateSurveyCutScore);
        this.graduateSurveySatisfactionThreshold =
            getNullableNumberFromString(settings.graduateSurveySatisfactionThreshold) ??
            this.graduateSurveySatisfactionThreshold;
        this.graduateSurveysThreshold =
            getNullableNumberFromString(settings.graduateSurveysThreshold) ?? this.graduateSurveysThreshold;
        this.graduateSurveyMeasureType =
            getNullableStringFromString(settings.graduateSurveyMeasureType) ?? this.graduateSurveyMeasureType;
        this.gsaffectiveQuestions =
            getNullableNumberFromString(settings.gsaffectiveQuestions) ?? this.gsaffectiveQuestions;
        this.gscognitiveQuestions =
            getNullableNumberFromString(settings.gscognitiveQuestions) ?? this.gscognitiveQuestions;
        this.gspsychomotorQuestions =
            getNullableNumberFromString(settings.gspsychomotorQuestions) ?? this.gspsychomotorQuestions;
        this.outcomeMeasure = getNullableStringFromString(settings.outcomeMeasure);
        this.positivePlacementThreshold =
            getNullableNumberFromString(settings.positivePlacementThreshold) ?? this.positivePlacementThreshold;
        this.questionLabelType = getNullableStringFromString(settings.questionLabelType);
        this.reportingWindow = getNullableNumberFromString(settings.reportingWindow);
        this.standardsTabMeetingCount =
            getNullableNumberFromString(settings.standardsTabMeetingCount) ?? this.standardsTabMeetingCount;
        this.title = getNullableStringFromString(settings.title);
        this.professionId = professionId;
        this.annualReportMailbox = getNullableStringFromString(settings.annualReportMailbox);
    }
}

export class TabSetting {
    professionId: number;
    customId: number | null;
    isActive: boolean;
    title: string;
    requireDetail: boolean | null;

    constructor(exp: any) {
        this.professionId = +exp.professionId;
        this.customId = getNullableNumberFromString(exp.customId);
        this.isActive = JSON.parse(exp.isActive);
        this.title = exp.title;
        this.requireDetail = getNullableBooleanFromString(exp.requireDetail);
    }
}

export interface ProgramResourceDetail {
    programResourceId: number;
    programId?: number;
    activeYear?: number;
    descriptionAnalysis?: string;
    actionPlan?: string;
    createdBy?: string;
    dateCreated?: Date | null;
    lastEdited?: Date | null;
    editedBy?: string;
    programResourceDetailDto?: ProgramResource[];
}

export interface ProgramResource {
    id: number;
    programResourceId: number;
    title: string;
    status?: boolean;
    reason?: string;
    hasChanged?: boolean;
    requireDetail: boolean;
}

export interface ProgramStandard {
    standardId: number;
    programId: number;
    activeYear: number;
    standardIsponsorship?: boolean;
    standardIiprogramGoals?: boolean;
    advisoryCommitteeRepresentation?: boolean;
    datesCommitteeMeetings?: string;
    advisoryCommitteeFulfilsRole?: boolean;
    standardIiiresources?: boolean;
    standardIvstudentAndGraduate?: boolean;
    standardVfairPractices?: boolean;
    descriptionAnalysis?: string;
    actionPlan?: string;
    createdBy?: string | null;
    dateCreated?: string;
    lastEdited?: string;
    editedBy?: string | null;
    standardIIAcHasMetAnnually?: boolean;
}

export class ProgramDistanceEducation {
    distanceId: number | null;
    programId: number | null;
    activeYear: number | null;
    createdBy: string | null;
    dateCreated: Date | null;
    lastEdited: Date | null;
    editedBy: string | null;
    distanceLearning: boolean | null;
    percentageOfTheProgram: number | null;
    webBasedPrograms: string | null;
    remoteLocations: string | null;
    distanceMode1: string | null;
    listOfCourses1: string | null;
    distanceMode2: string | null;
    listOfCourses2: string | null;
    distanceMode3: string | null;
    listOfCourses3: string | null;
    distanceMode4: string | null;
    listOfCourses4: string | null;

    constructor(distanceEd) {
        this.distanceId = getNullableNumberFromString(distanceEd.distanceId);
        this.programId = getNullableNumberFromString(distanceEd.programId);
        this.activeYear = getNullableNumberFromString(distanceEd.activeYear);
        this.createdBy = getNullableStringFromString(distanceEd.createdBy);
        this.dateCreated = getNullableDateFromString(distanceEd.dateCreated);
        this.lastEdited = getNullableDateFromString(distanceEd.lastEdited);
        this.editedBy = getNullableStringFromString(distanceEd.editedBy);
        this.distanceLearning = getNullableBooleanFromString(distanceEd.distanceLearning);
        this.percentageOfTheProgram = getNullableNumberFromString(distanceEd.percentageOfTheProgram);
        this.webBasedPrograms = getNullableStringFromString(distanceEd.webBasedPrograms);
        this.remoteLocations = getNullableStringFromString(distanceEd.remoteLocations);
        this.distanceMode1 = getNullableStringFromString(distanceEd.distanceMode1);
        this.listOfCourses1 = getNullableStringFromString(distanceEd.listOfCourses1);
        this.distanceMode2 = getNullableStringFromString(distanceEd.distanceMode2);
        this.listOfCourses2 = getNullableStringFromString(distanceEd.listOfCourses2);
        this.distanceMode3 = getNullableStringFromString(distanceEd.distanceMode3);
        this.listOfCourses3 = getNullableStringFromString(distanceEd.listOfCourses3);
        this.distanceMode4 = getNullableStringFromString(distanceEd.distanceMode4);
        this.listOfCourses4 = getNullableStringFromString(distanceEd.listOfCourses4);
    }
}

export class ProgramComment {
    commentId: number | null;
    programId: number | null;
    activeYear: number | null;
    createdBy: string | null;
    dateCreated: Date | null;
    lastEdited: Date | null;
    editedBy: string | null;
    comment: string | null;

    constructor(comment) {
        this.commentId = getNullableNumberFromString(comment.commentId);
        this.programId = getNullableNumberFromString(comment.programId);
        this.activeYear = getNullableNumberFromString(comment.activeYear);
        this.comment = getNullableStringFromString(comment.comment);
        this.createdBy = getNullableStringFromString(comment.createdBy);
        this.dateCreated = getNullableDateFromString(comment.dateCreated);
        this.lastEdited = getNullableDateFromString(comment.lastEdited);
        this.editedBy = getNullableStringFromString(comment.editedBy);
    }
}

export class ProgramSatellitesAffiliates {
    satelliteId = 0;
    programId: number | null = null;
    title: string | null = null;
    city: string | null = null;
    state: string | null = null;
    country: string | null = null;
    satelliteTypeId: number | null = null;
    dateCreated: Date | null = null;
    isActive: boolean | null = true;
    satalliteType: { satalliteTypeId: number; title: string } | null = null;
    constructor(init?: Partial<ProgramSatellitesAffiliates>) {
        Object.assign(this, init);
        this.satelliteId = getNullableNumberFromString(init?.satelliteId) ?? 0;
        this.programId = getNullableNumberFromString(init?.programId);
        this.satelliteTypeId = getNullableNumberFromString(init?.satelliteTypeId);
        this.isActive = getNullableBooleanFromString(init?.isActive);
    }
}

export interface Satellite {
    satelliteId: number;
    programId: number;
    title?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    satalliteTypeId?: number;
    dateCreated?: Date;
    isActive: boolean;
    address?: string;
}

export interface ClinicalAffiliate {
    satelliteId: number;
    programId: number;
    title?: string;
    city?: string;
    state?: string;
    country?: string;
    satalliteTypeId: number;
    dateCreated?: Date;
    isActive?: boolean;
    satalliteType?: { satalliteTypeId?: number; title?: string };
    address?: string;
    address2?: string;
    postalCode?: string;
    personnel?: AffiliatePersonnel[];
}

export interface AffiliatePersonnel {
    satelliteId: number;
    programPersonnelId: number;
    reportYear: number;
}

export interface ProgramDemographics {
    demographicsId: number;
    programId: number;
    graduatingYear: number;
    gradMale: number;
    gradFemale: number;
    gradGenderNoResponse: number;
    gradGenderNotListed: number;
    gradLessThan25: number;
    grad25To35: number;
    grad36to45: number;
    grad45OrMore: number;
    gradUnknown: number;
    gradAfricanAmerican: number;
    gradAmericanIndian: number;
    gradAsian: number;
    gradCaucasian: number;
    gradHispanic: number;
    gradOther: number;
    createdBy?: string;
    dateCreated: Date;
    lastEdited: Date;
    editedBy?: string;
    genderValidate?: number;
    ageValidate?: number;
    ethnicityValidate?: number;
    totalGrads?: number;
}

export class OutcomeType {
    outcomeTypeId: number | null = null;
    priority: number | null = null;
    title: string | null = null;
}

export class ProgramOutcomes {
    detailId: number | null = null;
    programId: number | null = null;
    outcomeTypeId: number | null = null;
    detailedAnalysis: string | null = null;
    actionPlan: string | null = null;
    createdBy: string | null = null;
    dateCreated: Date | null = null;
    lastEdited: Date | null = null;
    editedBy: string | null = null;
    outcomeType: OutcomeType | null = null;
    reportYear: number | null = null;
    threshold: number | null = null;
    reportWindowStartYear: number | null = null;
    reportWindowEndYear: number | null = null;
    oneYearAverage: number | null = null;
    threeYearAverage: number | null = null;
    reportWindowAverage: number | null = null;

    constructor(init?: Partial<ProgramOutcomes>) {
        Object.assign(this, init);
    }
}

export interface ProgramOutcomeExams {
    id: number;
    outcomeTypeId: number;
    programId: number;
    graduatingYear: number;
    numberOfGradsAttemptingExam: number | null;
    passing1stAttempt: number | null;
    passingSubsequentAttempts: number | null;
    outcomeType?: OutcomeType | null;
    detailedAnalysis?: string | null;
    actionPlan?: string | null;
    reportYear: number;
    total?: number;
    threshold?: number;
    reportWindowStartYear?: number;
    reportWindowEndYear?: number;
    oneYearAverage?: number;
    threeYearAverage?: number;
    reportWindowAverage?: number;
}

export class UserManagement {
    userId: string;
    name: string;
    email: string;
    roles: string[];
    isRemoved: boolean;
    firstName: string;
    lastName: string;
    coaIds: number[];

    constructor(user: any) {
        this.userId = user.userId;
        this.name = user.name;
        this.email = user.email;
        this.roles = user.roles && !isArray(user.roles) ? user.roles.split(',') : !user.roles ? [] : user.roles;
        this.isRemoved = user.isRemoved ? JSON.parse(user.isRemoved) : false;
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.coaIds =
            user.coaIds && !isArray(user.coaIds)
                ? user.coaIds.split(',').map((c) => {
                      return +c;
                  })
                : !user.coaIds
                ? []
                : user.coaIds;
    }
}

export class ProgramDirectorUser {
    firstName: string;
    lastName: string;
    email: string;
    programId: number;

    constructor(pd: any) {
        this.firstName = pd.firstName;
        this.lastName = pd.lastName;
        this.email = pd.email;
        this.programId = +pd.programId;
    }
}

export interface IdentityUser {
    email: string;
    id: string;
}

export class Permission {
    title: string;
    permissionClaimType: string;
    permissionValue: string;
    isSelected: boolean;

    constructor(permission: any) {
        this.title = permission.title;
        this.permissionClaimType = permission.permissionClaimType;
        this.permissionValue = permission.permissionValue;
        this.isSelected = JSON.parse(permission.isSelected);
    }
}

export class RolePermission {
    roleName: string;
    roleId: string;
    permissions: Permission[];

    constructor(rolePermission: any) {
        this.roleName = rolePermission.roleName;
        this.roleId = rolePermission.roleId;
        this.permissions =
            (rolePermission.permissions &&
                rolePermission.permissions.map((permission) => {
                    return new Permission(permission);
                })) ||
            [];
    }
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface IDocumentCategory {
    categoryId: number;
    professionId: number;
    title: string;
    isActive: boolean;
}

export const DocumentCategories = (category: IDocumentCategory): IDocumentCategory => {
    return {
        categoryId: Number(category.categoryId),
        professionId: Number(category.professionId),
        title: category.title,
        isActive: category.isActive.toString() === 'true',
    } as IDocumentCategory;
};

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface IProgramTabSettings {
    annualReportTabId: number;
    professionId: number;
    title: string;
    editable: boolean;
    priority: number;
    visible: boolean;
    isNew: boolean;
}

export class Concentration {
    concentrationId: number;
    professionId: number;
    title: string;

    constructor(con: any) {
        this.concentrationId = +con.concentrationId;
        this.professionId = +con.professionId;
        this.title = con.title;
    }
}

export interface RecommendationProgramSearchResult {
    programId?: number;
    existingProgram?: Program;
    includesAddOnTrack: boolean;
    addOnTrackExists: boolean;
}

export class Program {
    programId: number;
    professionId: number;
    concentrationId: number | null;
    institutionId: number;
    title: string;
    degreeDiploma: boolean | null;
    degreeCertificate: boolean | null;
    degreeAssociate: boolean | null;
    degreeBaccalaureate: boolean | null;
    degreeMasters: boolean | null;
    dateFirstAccredited: Date | string | null;
    nextFullEvaluation: Date | string | null;
    distance: boolean | null;
    militaryPersonnelOnly: boolean | null;
    website: string | null;
    outcomesUrl: string | null;
    visible: boolean | null;
    createdBy: string;
    dateCreated: string;
    lastEdited: string;
    editedBy: string;
    active: boolean | null;
    credentialingCode: string;
    onProbation: boolean | null;
    probationReason: string;
    probationStartDate: string;
    concentration: Concentration | null = null;
    editedByString: string | null;
    activeStatus?: number | null;
    prDue?: Date | string | null;
    programName?: string | null;
    programAddressId: number | null;
    programAddress: ProgramAddress | null = null;

    constructor(prog: any) {
        this.programId = +prog.programId;
        this.professionId = +prog.professionId;
        this.concentrationId = getNullableNumberFromString(prog.concentrationId);
        this.institutionId = +prog.institutionId;
        this.title = prog.title;
        this.degreeDiploma = getNullableBooleanFromString(prog.degreeDiploma);
        this.degreeCertificate = getNullableBooleanFromString(prog.degreeCertificate);
        this.degreeAssociate = getNullableBooleanFromString(prog.degreeAssociate);
        this.degreeBaccalaureate = getNullableBooleanFromString(prog.degreeBaccalaureate);
        this.degreeMasters = getNullableBooleanFromString(prog.degreeMasters);
        this.dateFirstAccredited = getNullableDateFromString(prog.dateFirstAccredited);
        this.nextFullEvaluation = getNullableDateFromString(prog.nextFullEvaluation);
        this.distance = getNullableBooleanFromString(prog.distance);
        this.militaryPersonnelOnly = getNullableBooleanFromString(prog.militaryPersonnelOnly);
        this.website = prog.website;
        this.outcomesUrl = prog.outcomesUrl;
        this.visible = getNullableBooleanFromString(prog.visible);
        this.createdBy = prog.createdBy;
        this.dateCreated = prog.dateCreated;
        this.lastEdited = prog.lastEdited;
        this.editedBy = prog.editedBy;
        this.active = getNullableBooleanFromString(prog.active);
        this.credentialingCode = prog.credentialingCode;
        this.onProbation = getNullableBooleanFromString(prog.onProbation);
        this.probationReason = prog.probationReason;
        this.probationStartDate = prog.probationStartDate;
        this.concentration = prog.concentration;
        this.editedByString = prog.editedByString;
        this.activeStatus = getNullableNumberFromString(prog.activeStatus);
        this.prDue = getNullableDateFromString(prog.prDue);
        this.programName = prog.programName;
        this.programAddressId = getNullableNumberFromString(prog.programAddressId);
        this.programAddress = prog.programAddress;
    }
}

export class ProgramAddress {
    id: number;
    city?: string | null;
    state?: string | null;
    address?: string | null;
    address2?: string | null;
    address3?: string | null;
    address4?: string | null;
    zip?: string | null;
    country?: string | null;
    phone?: string | null;
    extension?: string | null;
    fax?: string | null;
    lastEdited?: Date | null;
    dateCreated?: Date | null;
    isDeleted: boolean | null;
    editedByString?: string | null;
    createdByString?: string | null;

    constructor(pa: any) {
        this.id = +pa.id;
        this.city = pa.city;
        this.state = pa.state;
        this.address = pa.address;
        this.address2 = pa.address2;
        this.address3 = pa.address3;
        this.address4 = pa.address4;
        this.zip = pa.zip;
        this.country = pa.country;
        this.phone = pa.phone;
        this.extension = pa.extension;
        this.lastEdited = pa.lastEdited;
        this.dateCreated = pa.dateCreated;
        this.fax = pa.fax;
        this.isDeleted = pa.isDeleted;
        this.editedByString = pa.editedByString;
        this.createdByString = pa.createdByString;
    }
}

export class AnnualReport {
    professionId: number;
    programId: number;
    reportYear: number;
    reportStatus: AnnualReportStatus;
    completedTabIds: number[];
    fileUrl?: string;
    coaTitle?: string;
    programTitle?: string;
    programConcentration?: string;
    institutionTitle?: string;
    professionTitle?: string;
    institutionType?: string;
    programConcentrationId?: number;

    constructor(ar: any) {
        this.professionId = +ar.professionId;
        this.programId = +ar.programId;
        this.reportYear = +ar.reportYear;
        this.reportStatus = ar.reportStatus;
        this.completedTabIds =
            ar.completedTabIds && !Array.isArray(ar.completedTabIds) ? JSON.parse(ar.completedTabIds) : [];
        this.fileUrl = ar.fileUrl;
        this.coaTitle = ar.coaTitle;
        this.programTitle = ar.programTitle;
        this.programConcentration = ar.programConcentration;
        this.institutionTitle = ar.institutionTitle;
        this.professionTitle = ar.professionTitle;
        this.institutionType = ar.institutionType;
        this.programConcentrationId = ar.programConcentrationId;
    }
}

export class ProgramWithStatus {
    programId: number;
    institutionId: number;
    concentrationId: number | null;
    title: string;
    institution: string;
    institutionCity: string;
    institutionState: string;
    concentration: string;
    currentProgramStatusId: number;

    constructor(ps: any) {
        this.programId = +ps.programId;
        this.institutionId = +ps.institutionId;
        this.concentrationId = getNullableNumberFromString(ps.concentrationId);
        this.title = ps.title;
        this.institution = ps.institution;
        this.institutionCity = ps.institutionCity;
        this.institutionState = ps.institutionState;
        this.concentration = ps.concentration;
        this.currentProgramStatusId = +ps.currentProgramStatusId;
    }
}

export interface ProgramStatusHistory {
    historyId: number;
    programId: number;
    statusId: number;
    effectiveDate: Date;
    expirationDate?: Date;
    lastClassToBeAccredited?: Date;
    active: boolean;
}

export interface DocumentActivity {
    id: number;
    documentId: number;
    messageId?: string;
    activityTypeId?: number;
    userEmail?: string;
    timeStamp?: Date;
    message?: string;
}

export class DocumentUpload {
    fileUpload: File | null = null;
    dto: RelatedDocumentDto | SignatureDocument | AmsDocument | null = null;

    constructor(init?: Partial<DocumentUpload>) {
        Object.assign(this, init);
    }
}

export enum AnnualReportStatus {
    NotStarted = 'Not Started',
    InProgress = 'In Progress',
    SentBack = 'Sent Back',
    Submitted = 'Submitted',
    Completed = 'Completed',
    NotRequired = 'Not Required',
}

export interface ProgramInformation {
    programInfoId: number;
    programId: number;
    activeYear: number;
    certificateAwarded: string;
    typeOfAward: string;
    lengthOfProgram: number;
    lengthOfProgramAcademicSession: number;
    totalCreditHoursRequired: number;
    typeOfCredits: string;
    totalFeesResident: number;
    totalFeesNonResident: number;
    typeOfAward2?: string;
    lengthOfProgram2?: number;
    lengthOfProgramAcademicSession2?: number;
    totalCreditHoursRequired2?: number;
    typeOfCredits2?: string;
    totalFeesResident2?: number;
    totalFeesNonResident2?: number;
    programFiscalYearBeginsOnMonth: number;
    programFiscalYearBeginsOnDate: number;
    totalExpenditures?: number;
    isBudgetSufficient?: boolean;
    detailedAnalysisOfBudget?: string;
    actionPlanForBudget?: string;
    createdBy?: string;
    dateCreated?: Date;
    lastEdited?: Date;
    editedBy?: string;
    totalPreviousExpenditures?: number;
    createdByString?: string;
    editedByString?: string;
    programInformationDetail?: ProgramInformationDetail[];
    website: string;
}

export interface ProgramInformationDetail {
    id: number;
    programInfoId?: number;
    title: string;
    expenditure?: number;
    previousExpenditure?: number;
}

export interface Survey {
    surveyWorksheetId: number;
    surveyTypeId?: number | null;
    programId: number;
    graduatingYear: number;
    graduateSurveysSent: number;
    employerSurveysSent: number;
    graduateSurveysReturned: number;
    employerSurveysReturned: number;
    numberOfGradsEmployed: number;
    totalGraduates: number;
    graduateSurveysSentRate?: number | null;
    graduateSurveysReturnRate?: number | null;
    employerSurveysSentRate?: number | null;
    employerSurveysReturnRate?: number | null;
}

export interface SurveyType {
    surveyTypeId: number;
    title: string;
    prefix: string;
}

export interface SurveyWorksheetResult {
    resultId: number;
    programId: number;
    surveyTypeId: number;
    questionId: number;
    graduatingYear: number;
    number5?: number | null;
    number4?: number | null;
    number3?: number | null;
    number2?: number | null;
    number1?: number | null;
    numberNa?: number | null;
    numberOmitted?: number | null;
    surveysReturned?: number | null;
    totalEntered?: number | null;
}

export interface EnrollmentRetention {
    enrollmentRetentionId: number;
    programId: number;
    enrollmentYear: number;
    enrollmentDate?: Date;
    onTimeGraduationDate?: Date;
    estimatedNumberOfApplicants?: number;
    maximumNumberOfStudents?: number;
    numberOfStudentsInitiallyEnrolled?: number;
    satelliteId?: number;
    dateCreated?: Date;

    enrollmentRetentionDetailsDto?: EnrollmentRetentionDetail[];
    enrollmentRetentionReportInfoDto?: EnrollmentRetentionReportInfo[];
}

export interface EnrollmentRetentionDetail {
    detailId: number;
    enrollmentRetentionId: number;
    graduationYear: number;
    graduated?: number;
    reportYear: number;
}

export interface EnrollmentRetentionReportInfo {
    id: number;
    enrollmentRetentionId: number;
    reportYear: number;
    numberAddedToClass?: number;
    attritionDueToNonAcademicReasons?: number;
    attritionDueToGeneralEducationCourses?: number;
    attritionDueToProfessionalCourses?: number;
    totalStudentsInClass?: number;
    inProgressOrStoppedOut?: number;
    totalGraduates?: number;
    percentAttrition?: number;
    percentRetention?: number;
    droppedOut?: number;
}

export interface PositivePlacement {
    id: number;
    programId: number;
    graduatingYear: number;
    numberOfGradsEmployed?: number;
    numberOfGradsNotEmployed?: number;
}

export interface ProgramSummativeMeasure {
    summativeMeasuresId: number;
    programId: number;
    activeYear: number;
    descriptionAnalysis?: string;
    actionPlan?: string;
    createdBy?: string;
    dateCreated?: Date;
    lastEdited?: Date;
    editedBy?: string;
    createdByString?: string;
    editedByString?: string;
    programSummativeMeasureDetailDto?: ProgramSummativeMeasureDetail[];
}

export interface ProgramAccreditationAwardLetterLog {
    logID: number;
    programID: number;
    description: string;
    dateCreated: Date;
}

export interface AwardCertificateSend {
    recipients: string[];
    additionalRecipients: string[];
    subject: string;
    message: string;
    programId: number;
}

export interface AwardLetterSend extends AwardCertificateSend {
    awardLetterId: number;
}

export interface CertificateSend extends AwardCertificateSend {
    fileName: string;
}

export interface ProgramSummativeMeasureDetail {
    id: number;
    summativeMeasuresId: number;
    title: string;
    status?: boolean;
}

export interface InstitutionTypeDto {
    institutionTypeId: number;
    title: string;
    isActive: boolean;
}

export interface AuspiceDto {
    auspiceID: number;
    title: string;
    isActive: boolean;
}
export interface ProfessionAddOnTrack {
    addOnTrackId: number;
    professionId: number;
    title: string;
}

export interface ProgramAddOnTrack {
    addOnID: number;
    programId: number;
    addOnTrackId: number;
    dateFirstAccredited?: Date;
    statusId?: number;
    effectiveDate?: Date;
    dateWithdrawn?: Date;
    createdBy?: string;
    dateCreated?: Date;
    lastEdited?: Date;
    editedBy?: string;
    active: boolean;
    editedByString?: string;
    createdByString?: string;
    documents?: AmsDocument[];
}

export interface ProgramAddOnTrackCreate {
    addOnID: number;
    programId: number;
    addOnTrackId: number;
    dateFirstAccredited?: Date;
    statusId?: number;
    effectiveDate?: Date;
    active: boolean;
}

export interface ProgramAddOnTrackEdit {
    addOnId: number;
    dateFirstAccredited?: Date;
}

export interface ProgramAddOnTrackStatusCreate {
    addOnId: number;
    statusId?: number;
    effectiveDate?: Date;
}

export interface MeetingDateDto {
    meetingDateId: number;
    meetingDate: Date;
    createdBy?: string;
    dateCreated?: string;
    lastEdited?: string;
    editedBy?: string;
    active: boolean;
    editedByString?: string;
    createdByString?: string;
}

export interface NoteDto {
    noteId: number;
    programId?: number;
    institutionId?: number;
    notes: string;
    createdBy?: string;
    dateCreated?: Date;
    lastEdited?: Date;
    editedBy?: string;
    active: boolean;
    editedByString?: string;
    createdByString?: string;
}
export interface NoteCreateDto {
    noteId: number;
    programId?: number;
    institutionId?: number;
    notes: string;
    active: boolean;
}
export interface RecommendationListItem {
    recommendationId: number;
    meetingDateId: number;
    programProfessionId: number;
    institutionTitle: string;
    programTitle: string;
    institutionCity: string;
    meetingDate: Date;
    createdOn: Date;
    modifiedOn?: Date;
    modifiedByString: string;
    isTransferSponsorship: boolean;
    isNewInstitution: boolean;
    isNewProgram: boolean;
    citationCount: number;
    isMerged: boolean;
    statusId?: number;
    effectiveDate?: Date;
    awardLetterTemplate?: string;
    addOnTrackName?: string;
}
export interface AccreditationActionsIndexReport {
    meetingDateId: number;
    totalCount: number;
    recommendedProfessionsGroup?: RecommendationReportProfessions[];
}
export interface RecommendationReportProfessions {
    professionCount: number;
    professionId: number;
    professionTitle: string;
    recommendedProgramsStatusGroup?: RecommendedProgramsStatusGroup[];
}
export interface RecommendedProgramsStatusGroup {
    statusCount: number;
    statusId?: number;
    statusTitle: string;
    recommendedPrograms?: RecommendedPrograms[];
}
export interface RecommendedPrograms {
    recommendationId: number;
    meetingDateId?: number;
    programProfessionId?: number;
    institutionTitle: string;
    programTitle: string;
    institutionCity: string;
    institutionState: string;
    meetingDate: Date;
    citationCount: number;
    isMerged: boolean;
    currentStatusId?: number;
    recommendedStatusId?: number;
    standardVersion: string;
    progressReportDue?: Date;
    nextFullEvalDay?: number;
    nextFullEvalMonth?: number;
    nextFullEvalYear?: number;
    degrees?: string;
}
export interface GenerateRecommendationListItemCertificate extends RecommendationListItem {
    isSelected: boolean;
}

export interface CitationDto {
    citationId: number;
    letterId?: number;
    standardsDesignationId?: number | null;
    designationNumber?: string | null;
    designationTitle?: string | null;
    designationDescription?: string | null;
    programId?: number | null;
    recommendationId: number;
    rationale?: string | null;
    suggestedDocumentation?: string;
    createdBy?: string | null;
    dateCreated?: Date | null;
    lastEdited?: Date | null;
    editedBy?: string | null;
    isDeleted: boolean;
    editedByString?: string | null;
    createdByString?: string | null;
    detail?: CitationDetailDto[] | null;
}

export interface CitationDetailDto {
    citationDetailId: number;
    citationId: number;
    subStandardId?: number | null;
    rationaleId?: number | null;
    subStandardDescription?: string | null;
}

export interface RecommendationCreate {
    programProfessionId: number;
    statusId: number;
    caahepprogramId?: number;
    caahepinstitutionId?: number;
    transferFromProgramId?: number;
    transferFromInstitutionId?: number;
    institutionTitle?: string;
    institutionCity?: string;
    institutionState?: string;
    institutionCountry?: string;
    transferToInstitutionId?: number;
    programConcentrationId?: number;
    programAddOnTrackId?: number;
    programTitle: string;
    meetingDateId: number;
    isAwardRequired: boolean;
    award?: string;
}

export class RecommendationDto {
    constructor(init?: Partial<RecommendationDto>) {
        init && Object.assign(this, init);
    }

    recommendationId = 0;
    caahepinstitutionId?: number;
    caahepprogramId?: number;
    professionStandardId?: number;
    citations = 0;
    statusId?: number;
    effectiveDate?: Date | string;
    expirationDate?: Date;
    prdue?: Date | string;
    nextFullEval?: Date | string;
    meetingDateId = 0;
    meetingDate: Date = new Date();
    institutionTitle?: string;
    institutionTypeId?: number;
    institutionAuspiceId?: number;
    institutionAddress?: string;
    institutionAddress2?: string;
    institutionAddress3?: string;
    institutionAddress4?: string;
    institutionCity?: string;
    institutionState?: string;
    institutionZip?: string;
    institutionCountry?: string;
    institutionPhone?: string;
    institutionExtension?: string;
    institutionWebSite?: string;
    programProfessionId = 0;
    programConcentrationId?: number;
    programAddOnTrackId?: number;
    programTitle?: string;
    programName?: string;
    programAddress?: string;
    programAddress2?: string;
    programAddress3?: string;
    programAddress4?: string;
    programCity?: string;
    programState?: string;
    programZip?: string;
    programCountry?: string;
    programDegreeDiploma = false;
    programDegreeCertificate = false;
    programDegreeAssociate = false;
    programDegreeBaccalaureate = false;
    programDegreeMasters = false;
    programDateFirstAccredited?: Date | string;
    programDistance = false;
    programMilitaryPersonnelOnly = false;
    programWebSite?: string;
    programOutcomesUrl?: string;
    programDirectorFirstName?: string;
    programDirectorLastName?: string;
    programDirectorAddress?: string;
    programDirectorAddress2?: string;
    programDirectorAddress3?: string;
    programDirectorAddress4?: string;
    programDirectorCity?: string;
    programDirectorState?: string;
    programDirectorZip?: string;
    programDirectorCountry?: string;
    programDirectorPhone?: string;
    programDirectorExtension?: string;
    programDirectorTitle?: string;
    programDirectorCredentials?: string;
    programDirectorSalutations?: string;
    programDirectorEmail?: string;
    deanFirstName?: string;
    deanLastName?: string;
    deanAddress?: string;
    deanAddress2?: string;
    deanAddress3?: string;
    deanAddress4?: string;
    deanCity?: string;
    deanState?: string;
    deanZip?: string;
    deanCountry?: string;
    deanPhone?: string;
    deanExtension?: string;
    deanTitle?: string;
    deanCredentials?: string;
    deanSalutations?: string;
    deanEmail?: string;
    ceofirstName?: string;
    ceolastName?: string;
    ceoaddress?: string;
    ceoaddress2?: string;
    ceoaddress3?: string;
    ceoaddress4?: string;
    ceocity?: string;
    ceostate?: string;
    ceozip?: string;
    ceocountry?: string;
    ceophone?: string;
    ceoextension?: string;
    ceotitle?: string;
    ceocredentials?: string;
    ceosalutations?: string;
    ceoemail?: string;
    createdOn?: Date;
    createdBy?: string;
    modifiedOn?: Date;
    modifiedBy?: string;
    isMerged = false;
    mergedBy?: string;
    mergedByString?: string;
    mergedOn?: Date;
    active = false;
    programDirectorNotAvailable = false;
    deanNotAvailable = false;
    ceonotAvailable = false;
    coaletterCc1?: string;
    coaletterCc2?: string;
    coaletterCc3?: string;
    transferFromProgramId?: number;
    transferFromInstitutionId?: number;
    transferToInstitutionId?: number;
    createdByString?: string | null;
    modifiedByString?: string;
    professionTitle?: string;
    concentrationTitle?: string;
    addOnTrackTitle?: string;
    isCeoSectionComplete = false;
    isCitationSectionComplete = false;
    isDeanSectionComplete = false;
    isInstitutionSectionComplete = false;
    isProgramDirectorSectionComplete = false;
    isProgramSectionComplete = false;
    isRecommendationSectionComplete = false;
    institutionalAccreditorId?: number = undefined;
    satellites?: RecommendationSatellite[] = [];
}

export interface RecommendationSatellite {
    recommendationSatelliteId: number;
    recommendationId: number;
    satelliteId?: number;
    title?: string;
    city?: string;
    state?: string;
    isActive: boolean;
}

export interface UserInfo {
    userId: string;
    userName: string;
    name: string;
    email: string;
}

export interface InstitutionalAccreditor {
    institutionalAccreditorId: number;
    title: string;
    isActive: boolean;
}

export interface InstitutionNameHistory {
    institutionNameHistoryId: number;
    institutionId: number;
    title: string;
    effectiveDate?: Date;
    isActive: boolean;
    dateCreated?: Date;
    createdByString?: string;
    lastEdited?: Date;
    editedByString?: string;
}

export interface LetterTemplateDto {
    letterTemplateId: number;
    statusId: number;
    isWithCitations: boolean;
    originalFilename?: string;
    fileUrl?: string;
    dateCreated?: Date;
    createdByString?: string;
    lastEdited?: Date;
    editedByString?: string;
    fileUpload?: File;
    newFileName?: string;
    professionIds?: string;
    meetingDateId?: number;
}

export interface CitationCountByStatusStandardRationale {
    statusId: number;
    standardsDesignationId: number;
    subStandardId?: number;
    rationaleId?: number;
    count: number;
}

export interface AverageCitationsPerProgramByStatus {
    statusId: number;
    citationsAverage: number;
}

export interface StandardReportStatusCount {
    statusId: number;
    totalActions: number;
    totalActionsWithCitations: number;
}

export interface StandardReportDetailRow {
    designationNumber: string;
    designationText: string;
    initialCount?: number;
    continuingCount?: number;
    probationCount?: number;
    withdrawCount?: number;
    witholdCount?: number;
    totalCount?: number;
}

export interface StandardReport {
    professionId: number;
    year: number;
    totalActions: number;
    totalActionsWithCitations: number;
    statusCounts: StandardReportStatusCount[];
    programCitationAverageByStatus: AverageCitationsPerProgramByStatus[];
    // citationCount: CitationCountByStatusStandardRationale[];
    detailRows: StandardReportDetailRow[];
}

export interface GeneralInformationDto {
    institutionCount: number;
    accreditedProgramCount: number;
    associateCount: number;
    baccalaureateCount: number;
    mastersCount: number;
    diplomaCount: number;
    certificateCount: number;
}

export interface AccreditedProgramDto {
    programId: number;
    programTitle: string;
    degrees: string;
    institutionId: number;
    institutionName: string;
    institutionCity: string;
    institutionState: string;
    institutionType: string;
    auspice: string;
    accreditationStatusTitle: string;
    statusEffectiveDate?: Date;
    dateFirstAccredited?: Date;
    evaluationDate?: Date;
    outcomesUrl: string;
    prDue?: Date;
    distance?: boolean;
}

export interface AccreditationActionTotalDto {
    professionId: number;
    professionTitle: string;
    initialCount: number;
    continuingCount: number;
    probationCount: number;
    withdrawnCount: number;
    expireCount: number;
    totalCount: number;
}

export interface AccreditationActionTotalReportDto {
    startDate: Date;
    endDate: Date;
    withConcentrations: boolean;
    initialTotal: number;
    continuingTotal: number;
    probationTotal: number;
    withdrawnTotal: number;
    expireTotal: number;
    totalTotal: number;
    professionAccreditationActions: AccreditationActionTotalDto[];
}

export interface ProgramByAuspiceDto {
    auspiceId: number;
    auspiceTitle: string;
    auspiceCount: number;
}

export interface DegreeByAuspiceDto {
    degreeTypeId: number;
    degreeTypeTitle: string;
    totalCount: number;
    programByAuspiceDtos: ProgramByAuspiceDto[];
}

export interface DegreeByAuspiceReportDto {
    withConcentrations: boolean;
    recordCount: number;
    degreeByAuspiceDtos: DegreeByAuspiceDto[];
}

export interface InactiveStatusDto {
    programId: number;
    programTitle: string;
    institutionId: number;
    institutionTitle: string;
    institutionCity: string;
    institutionState: string;
    inactiveDate: Date;
}

export interface InactiveStatusReportDto {
    totalCount: number;
    inactiveStatusDtos: InactiveStatusDto[];
}

export interface ProbationStatusDto {
    programId: number;
    programTitle: string;
    institutionId: number;
    institutionTitle: string;
    institutionCity: string;
    institutionState: string;
    probationEffectiveDate: Date;
}

export interface ProbationStatusReportDto {
    totalCount: number;
    probationStatusDtos: ProbationStatusDto[];
}

export interface ProfessionSearchCountDto {
    professionId: number;
    professionTitle: string;
    searchCount: number;
}

export interface ProfessionSearchCountReportDto {
    recordCount: number;
    professionSearchCountDtos: ProfessionSearchCountDto[];
}

export interface ActiveConcentrationsDto {
    concentrationId: number;
    concentrationTitle: string;
    programCount: number;
}

export interface ActiveProgramsByStatusDto {
    statusId: number;
    statusName: string;
    programCount: number;
}

export interface NumberOfActiveProgramsByProfessionDto {
    professionId: number;
    professionTitle: string;
    totalCount: number;
    activeConcentrationsDtos: ActiveConcentrationsDto[];
    activeProgramsByStatusDtos: ActiveProgramsByStatusDto[];
}

export interface NumberOfActiveProgramsReportDto {
    cutOffDate: Date;
    withConcentrations: boolean;
    totalCount: number;
    numberOfActiveProgramsByProfessionDtos: NumberOfActiveProgramsByProfessionDto[];
}

export interface ProfitVsPublicDto {
    institutionId: number;
    institutionTitle: string;
    institutionType: string;
    auspiceTitle: string;
}

export interface WithdrawStatusDto {
    programId: number;
    programTitle: string;
    institutionId: number;
    institutionTitle: string;
    institutionCity: string;
    institutionState: string;
    withdrawEffectiveDate: Date;
}

export interface WithdrawStatusReportDto {
    totalCount: number;
    withdrawStatusDtos: WithdrawStatusDto[];
}

export interface Folder {
    folderId: number;
    folderSlug: string;
    parentFolderId?: number;
    name: string;
    dateCreated: Date;
    dateCreatedString: string;
    lastEdited: Date;
    lastEditedString: string;
    isDeleted: boolean;

    parentFolder?: Folder;
    childFolders: Folder[];
    hubDocuments: HubDocument[];
}

export interface CreateFolder {
    parentFolderId: number;
    name: string;
}

export interface CreateHubDocument {
    folderId: number;
    name: string;
}

export interface UpdateFolder {
    folderId: number;
    name: string;
}

export interface RemoveFolder {
    folderId: number;
}

export interface RemoveHubDocument {
    hubDocumentId: number;
}

export interface HubDocument {
    hubDocumentId: number;
    documentSlug: string;
    folderId: number;
    name: string;
    isDeleted: boolean;

    folder: Folder;
}
