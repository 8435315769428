import { Button, Card, CardBody, CardHeader, Table } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import GenerateAnnualInvoicesForm from './GenerateAnnualInvoicesForm';
import { InvoicingDetailDto } from '../../types';
import { NavLink } from 'react-router-dom';
import { PageTitle } from '../../components';
import { invoiceService } from '../../services/InvoiceService';
import { toast } from 'react-toastify';

const GenerateAnnualInvoices = () => {
    const [failedList, setFailedList] = useState<InvoicingDetailDto[]>();
    const [reload, setReload] = useState(false);

    useEffect(() => {
        const getData = () => {
            invoiceService
                .getFailedInvoices()
                .then((response) => {
                    setFailedList(response);
                    setReload(false);
                })
                .catch(() => toast.error('Failed to get invoices'));
        };

        getData();
    }, [setFailedList, reload, setReload]);

    const dismissActivity = (invoice) => {
        const toastId = toast.info('Dismissing Activity...');

        invoiceService
            .saveInvoice(invoice.id)
            .then(() => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Activity dismissed',
                });
                setReload(true);
            })
            .catch((e) => {
                if (e.error) {
                    toast.update(toastId, {
                        type: 'error',
                        render: e.error,
                    });
                } else {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Error dismissing activity',
                    });
                }
            });
    };

    return (
        <>
            <PageTitle title="Generate Annual Invoices" />
            <Card>
                <CardBody>
                    <GenerateAnnualInvoicesForm />
                </CardBody>
            </Card>

            <Card>
                <CardBody>
                    <h3>Failure Messages</h3>
                </CardBody>
                <Table>
                    <thead>
                        <tr>
                            <th>Invoice Number</th>
                            <th>Institution</th>
                            <th>Errors</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {failedList &&
                            failedList.map((invoice) => (
                                <tr key={invoice.id}>
                                    <td>{invoice.invoiceNumber}</td>
                                    <td>
                                        <NavLink to={`/institution/${invoice.institutionId}`}>
                                            {invoice.institutionTitle}
                                        </NavLink>
                                    </td>
                                    <td>
                                        <ul>
                                            {invoice.invoiceActivities.map((act) => (
                                                <li key={act.id}>{act.message}</li>
                                            ))}
                                        </ul>
                                    </td>
                                    <td>
                                        <Button color="link" onClick={() => dismissActivity(invoice)}>
                                            Dismiss &amp; Regenerate
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        {!failedList && (
                            <tr>
                                <td colSpan={2}>No Failed Invoices</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card>
        </>
    );
};

export default GenerateAnnualInvoices;
