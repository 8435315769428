import React, { useEffect, useState } from 'react';
import { Folder, HubDocument } from '../common/Types';
import moment from 'moment';
import { BlobServiceClient, BlockBlobClient, ContainerClient } from '@azure/storage-blob';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Alert } from 'reactstrap';
import { hubService } from '../services/HubService';

interface HubDocumentUploadProps {
    file: File;
    folder: Folder;
    sasToken: string;
    onDocumentCreated: (document: HubDocument) => void;
}

const HubDocumentUpload = (props: HubDocumentUploadProps) => {
    const { file, folder, sasToken, onDocumentCreated } = props;
    const [progress, setProgress] = useState<number>(0);
    const [error, setError] = useState<string>();

    const convertSize = (): string => {
        if (file.size < 1048576) {
            return `${(file.size / 1024).toFixed(2)} kb`;
        } else if (file.size > 1024 * 1024 && file.size < 1024 * 1024 * 1024) {
            return `${(file.size / (1024 * 1024)).toFixed(2)} mb`;
        } else {
            return `${(file.size / (1024 * 1024 * 1024)).toFixed(2)} gb`;
        }
    };

    const getLastModified = (): string => {
        const lastModified = moment(file.lastModified);
        return lastModified.format('YYYY-MM-DD HH:mm:SS');
    };

    useEffect(() => {
        const blobService = new BlobServiceClient(sasToken);
        const containerClient: ContainerClient = blobService.getContainerClient('hub');
        containerClient
            .createIfNotExists({
                access: 'container',
            })
            .then(() => {
                const blobClient: BlockBlobClient = containerClient.getBlockBlobClient(
                    `${folder.folderSlug}/${file.name}`,
                );
                blobClient.exists().then((exists) => {
                    if (exists) {
                        setError(`Another file with this name already exists in this folder`);
                    } else {
                        blobClient
                            .uploadData(file, {
                                blobHTTPHeaders: {
                                    blobContentType: file.type,
                                },
                                blockSize: 4 * 1024 * 1024,
                                concurrency: 20,
                                onProgress: (ev) => {
                                    setProgress((ev.loadedBytes / file.size) * 100);
                                },
                            })
                            .then((result) => {
                                if (!!result.errorCode) {
                                    setError(result.errorCode);
                                } else {
                                    hubService
                                        .createDocument({
                                            folderId: folder.folderId,
                                            name: file.name,
                                        })
                                        .then((newDocument) => {
                                            if (onDocumentCreated) {
                                                onDocumentCreated(newDocument);
                                            }
                                        })
                                        .catch((err) => {
                                            setError(err.message);
                                            blobClient.deleteIfExists();
                                        });
                                }
                            });
                    }
                });
            });
    }, [file]);

    return (
        <>
            <div
                className={`mb-1 p-2`}
                style={{
                    border: 'solid 1px #DDDDDD',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <span style={{ fontWeight: 500, fontSize: '14px' }}>{file.name}</span>
                <div
                    style={{
                        display: 'flex',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                        }}
                    >
                        <span
                            className={'mr-3'}
                            style={{ fontSize: '12px', fontStyle: 'italic', opacity: 0.8 }}
                        >{`File Size: ${convertSize()}`}</span>
                        <span
                            style={{ fontSize: '12px', fontStyle: 'italic', opacity: 0.8 }}
                        >{`Last Modified: ${getLastModified()}`}</span>
                    </div>
                    <div
                        style={{
                            flexBasis: '40px',
                            alignItems: 'center',
                        }}
                    >
                        {progress < 100 && !!!error && (
                            <CircularProgressbar value={progress} text={`${progress.toFixed(0)}%`} strokeWidth={15} />
                        )}
                        {progress === 100 && !!!error && (
                            <CircularProgressbar
                                value={progress}
                                text={`${progress.toFixed(0)}%`}
                                strokeWidth={15}
                                styles={buildStyles({
                                    pathColor: '#1abc9c',
                                })}
                            />
                        )}
                        {!!error && (
                            <CircularProgressbar
                                value={progress}
                                text={`${progress.toFixed(0)}%`}
                                strokeWidth={15}
                                styles={buildStyles({
                                    trailColor: '#f1556c',
                                })}
                            />
                        )}
                    </div>
                </div>
                {!!error && (
                    <Alert className={`p-1 mt-1 mb-0`} style={{ fontSize: '12px' }} color={'danger'}>
                        {error}
                    </Alert>
                )}
            </div>
        </>
    );
};

export default HubDocumentUpload;
