import { AnnualReport, GeneralSettings, ProfessionDto, ProgramWithStatus } from '../../common/Types';
import {
    Button,
    ButtonDropdown,
    Card,
    CardBody,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Label,
    Row,
} from 'reactstrap';
import React, { useEffect, useState } from 'react';

import Chart from 'react-apexcharts';
import { NavLink } from 'react-router-dom';
import { ProgramStatus } from '../../common/Data';
import { professionService } from '../../services/ProfessionAdministration';
import { programService } from '../../services/ProgramService';
import { toast } from 'react-toastify';

interface ProgramAnnualReport {
    programTitle: string;
    programId: number;
    activeYear: number;
    institution: string;
    institutionCity: string;
    institutionState: string;
    concentration: string;
    annualReportStatus: string;
    currentProgramStatusId: number;
}

const Overview = () => {
    const [professions, setProfessions] = useState<ProfessionDto[]>([]);
    const [selectedProfession, setSelectedProfession] = useState<ProfessionDto>();
    const [programs, setPrograms] = useState<ProgramWithStatus[]>();
    const [settings, setSettings] = useState<GeneralSettings>();
    const [annualReports, setAnnualReports] = useState<AnnualReport[]>();
    const [programAnnualReports, setProgramAnnualReports] = useState<ProgramAnnualReport[]>([]);
    const [notStartedReports, setNotStartedReports] = useState<ProgramAnnualReport[]>([]);
    const [inProgressReports, setInProgressReports] = useState<ProgramAnnualReport[]>([]);
    const [submittedReports, setSubmittedReports] = useState<ProgramAnnualReport[]>([]);
    const [sentBackReports, setSentBackReports] = useState<ProgramAnnualReport[]>([]);
    const [completedReports, setCompletedReports] = useState<ProgramAnnualReport[]>([]);
    const [selectedReports, setSelectedReports] = useState<ProgramAnnualReport[]>();

    const [dropdownOpen, toggleDropDown] = useState<boolean>(false);
    const toggle = () => toggleDropDown(!dropdownOpen);

    useEffect(() => {
        professionService
            .getProfessions()
            .then((results) => {
                const armsProfessions = [
                    ...results.filter(
                        (p) =>
                            p.armsUser === true &&
                            (p.isDeleted === undefined || p.isDeleted === null || p.isDeleted === false),
                    ),
                ];
                setProfessions(armsProfessions);
                setSelectedProfession(armsProfessions[0]);
            })
            .catch((error) => {
                toast.error('Error loading profession detail.');
            });
    }, [setProfessions]);

    useEffect(() => {
        if (selectedProfession) {
            programService
                .getProgramsNeedingAnnualReportByProfessionId(selectedProfession.professionId)
                .then((results) => {
                    setPrograms(results);
                })
                .catch((error) => {
                    toast.error('Error loading programs requiring an annual report.');
                });
        }
    }, [selectedProfession, setPrograms]);

    useEffect(() => {
        if (selectedProfession) {
            professionService
                .getGeneralSettings(selectedProfession.professionId)
                .then((response) => {
                    setSettings(response);
                })
                .catch(() => {
                    toast.error('Error loading annual report settings.');
                });
        }
    }, [setSettings, selectedProfession]);

    useEffect(() => {
        if (selectedProfession && settings && settings.reportingWindow && settings.reportingWindow.activeYear) {
            programService
                .getAnnualReportsByProfessionIdAndYear(
                    selectedProfession.professionId,
                    settings.reportingWindow.activeYear,
                )
                .then((results) => {
                    setAnnualReports(results);
                })
                .catch((error) => {
                    toast.error('Error loading annual reports.');
                });
        }
    }, [settings, selectedProfession, setAnnualReports]);

    useEffect(() => {
        if (programs && annualReports && settings && settings.reportingWindow && settings.reportingWindow.activeYear) {
            const normalizedList = programs.map((program) => {
                const annualReport = annualReports.filter((ar) => ar.programId === program.programId)[0];

                return {
                    ...program,
                    programTitle: program.title,
                    activeYear: settings.reportingWindow.activeYear,
                    annualReportStatus: annualReport?.reportStatus || 'Not Started',
                } as ProgramAnnualReport;
            });

            setProgramAnnualReports(normalizedList);
            setNotStartedReports([...normalizedList.filter((par) => par.annualReportStatus === 'Not Started')]);
            setInProgressReports([...normalizedList.filter((par) => par.annualReportStatus === 'In Progress')]);
            setSubmittedReports([...normalizedList.filter((par) => par.annualReportStatus === 'Submitted')]);
            setSentBackReports([...normalizedList.filter((par) => par.annualReportStatus === 'Sent Back')]);
            setCompletedReports([...normalizedList.filter((par) => par.annualReportStatus === 'Completed')]);
            setSelectedReports(normalizedList);
        }
    }, [
        annualReports,
        programs,
        settings,
        setNotStartedReports,
        setInProgressReports,
        setSubmittedReports,
        setSentBackReports,
        setCompletedReports,
    ]);

    return (
        <>
            <Row>
                <Col>
                    <div className="page-title-box">
                        <h4 className="page-title">
                            <span>Annual Report Dashboard</span>
                            {selectedProfession && professions && (
                                <>
                                    <span> - </span>
                                    {professions.length === 1 && <span>{selectedProfession.title}</span>}
                                    {professions.length > 1 && (
                                        <>
                                            <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                                                <DropdownToggle color={'primary'}>
                                                    {selectedProfession.title}
                                                    <i className={'mdi mdi-chevron-down ml-2'} />
                                                </DropdownToggle>
                                                <DropdownMenu style={{ maxHeight: '600px', overflowY: 'scroll' }}>
                                                    {professions.map((prof) => (
                                                        <DropdownItem
                                                            key={prof.professionId}
                                                            onClick={() => setSelectedProfession(prof)}
                                                        >
                                                            {prof.title}
                                                        </DropdownItem>
                                                    ))}
                                                </DropdownMenu>
                                            </ButtonDropdown>
                                        </>
                                    )}
                                </>
                            )}
                        </h4>
                    </div>
                </Col>
            </Row>
            <Row className={'mt-3'}>
                <Col xs={12} md={5}>
                    <Row>
                        <Col xs={12} md={6}>
                            <Card className={'bg-dark'}>
                                <CardBody className={'d-flex flex-row justify-content-around'}>
                                    <div className={'text-center'}>
                                        <h1 style={{ fontSize: '48px' }} className={'card-title text-white'}>
                                            {programAnnualReports.length}
                                        </h1>
                                        <h4 className={'card-title text-white'}>Total Programs</h4>
                                    </div>
                                    <Button
                                        type={'button'}
                                        className={'btn btn-icon btn-dark btn-lg'}
                                        onClick={() => setSelectedReports(programAnnualReports)}
                                    >
                                        <i className={'mdi mdi-binoculars'} style={{ fontSize: '48px' }} />
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12} md={6}>
                            <Card className={'bg-danger'}>
                                <CardBody className={'d-flex flex-row justify-content-around'}>
                                    <div className={'text-center'}>
                                        <h1 style={{ fontSize: '48px' }} className={'card-title text-white'}>
                                            {notStartedReports.length}
                                        </h1>
                                        <h4 className={'card-title text-white'}>Not Started</h4>
                                    </div>
                                    <Button
                                        type={'button'}
                                        className={'btn btn-icon btn-danger btn-lg'}
                                        onClick={() => setSelectedReports(notStartedReports)}
                                    >
                                        <i className={'mdi mdi-binoculars'} style={{ fontSize: '48px' }} />
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12} md={6}>
                            <Card className={'bg-warning'}>
                                <CardBody className={'d-flex flex-row justify-content-around'}>
                                    <div className={'text-center'}>
                                        <h1 style={{ fontSize: '48px' }} className={'card-title text-white'}>
                                            {inProgressReports.length}
                                        </h1>
                                        <h4 className={'card-title text-white'}>In Progress</h4>
                                    </div>
                                    <Button
                                        type={'button'}
                                        className={'btn btn-icon btn-warning btn-lg'}
                                        onClick={() => setSelectedReports(inProgressReports)}
                                    >
                                        <i className={'mdi mdi-binoculars'} style={{ fontSize: '48px' }} />
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12} md={6}>
                            <Card className={'bg-secondary'}>
                                <CardBody className={'d-flex flex-row justify-content-around'}>
                                    <div className={'text-center'}>
                                        <h1 style={{ fontSize: '48px' }} className={'card-title text-white'}>
                                            {sentBackReports.length}
                                        </h1>
                                        <h4 className={'card-title text-white'}>Sent Back</h4>
                                    </div>
                                    <Button
                                        type={'button'}
                                        className={'btn btn-icon btn-secondary btn-lg'}
                                        onClick={() => setSelectedReports(sentBackReports)}
                                    >
                                        <i className={'mdi mdi-binoculars'} style={{ fontSize: '48px' }} />
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12} md={6}>
                            <Card className={'bg-info'}>
                                <CardBody className={'d-flex flex-row justify-content-around'}>
                                    <div className={'text-center'}>
                                        <h1 style={{ fontSize: '48px' }} className={'card-title text-white'}>
                                            {submittedReports.length}
                                        </h1>
                                        <h4 className={'card-title text-white'}>Submitted</h4>
                                    </div>
                                    <Button
                                        type={'button'}
                                        className={'btn btn-icon btn-info btn-lg'}
                                        onClick={() => setSelectedReports(submittedReports)}
                                    >
                                        <i className={'mdi mdi-binoculars'} style={{ fontSize: '48px' }} />
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12} md={6}>
                            <Card className={'bg-success'}>
                                <CardBody className={'d-flex flex-row justify-content-around'}>
                                    <div className={'text-center'}>
                                        <h1 style={{ fontSize: '48px' }} className={'card-title text-white'}>
                                            {completedReports.length}
                                        </h1>
                                        <h4 className={'card-title text-white'}>Completed</h4>
                                    </div>
                                    <Button
                                        type={'button'}
                                        className={'btn btn-icon btn-success btn-lg'}
                                        onClick={() => setSelectedReports(completedReports)}
                                    >
                                        <i className={'mdi mdi-binoculars'} style={{ fontSize: '48px' }} />
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <h4 className={'card-title'}>Total Report Progress</h4>
                                    <Chart
                                        options={{
                                            labels: [
                                                'Not Started',
                                                'In Progress',
                                                'Reopened',
                                                'Submitted',
                                                'Completed',
                                            ],
                                            colors: ['#f1556c', '#f7b84b', '#6c757d', '#0ed4cd', '#1abc9c'],
                                        }}
                                        series={[
                                            notStartedReports.length,
                                            inProgressReports.length,
                                            sentBackReports.length,
                                            submittedReports.length,
                                            completedReports.length,
                                        ]}
                                        type={'pie'}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} md={7}>
                    {selectedReports && (
                        <Row>
                            <Col>
                                <Card style={{ maxHeight: '700px', overflowY: 'scroll' }}>
                                    <CardBody>
                                        <div className={'table-responsive'}>
                                            <table className={'table table-striped'}>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={4}>
                                                            <h3>Active Year: {settings?.reportingWindow.activeYear}</h3>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>Program</th>
                                                        <th>Program Status</th>
                                                        <th>Report Status</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {selectedReports.length > 0 &&
                                                        selectedReports.map((report) => (
                                                            <tr key={report.programId}>
                                                                <td className={'d-flex flex-column'}>
                                                                    <Label>{report.institution}</Label>
                                                                    <Label>
                                                                        {report.institutionCity},{' '}
                                                                        {report.institutionState}
                                                                    </Label>
                                                                    <Label
                                                                        className={'text-muted'}
                                                                    >{`${report.programTitle}`}</Label>
                                                                </td>
                                                                <td>
                                                                    <Label>{`${Object.keys(ProgramStatus).filter(
                                                                        (x) =>
                                                                            ProgramStatus[x] ===
                                                                            report.currentProgramStatusId,
                                                                    )}`}</Label>
                                                                </td>
                                                                <td>{report.annualReportStatus}</td>
                                                                <td>
                                                                    {selectedProfession && settings && (
                                                                        <NavLink
                                                                            to={`/annual-report/${selectedProfession.professionId}/${settings.reportingWindow.activeYear}/program/${report.programId}/getting-started`}
                                                                            className={'btn btn-info'}
                                                                        >
                                                                            View Annual Report
                                                                        </NavLink>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    {selectedReports.length === 0 && (
                                                        <tr>
                                                            <td colSpan={4}>No programs to display</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        </>
    );
};

export default Overview;
