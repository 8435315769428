import styled from 'styled-components';
import { Card } from 'reactstrap';

const CardFormGroup = styled(Card)`
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 16px;
    padding-bottom: 16px;

    .radio-group {
        margin-left: 1.6rem;
        padding-left: 16px;
        display: flex;

        .form-group {
            margin-right: 1.25rem;
            flex: 1;
        }
    }

    .date-group {
        display: flex;
        margin-left: 1.25rem;

        .form-group {
            margin-right: 1.25rem;
            flex: 1;
        }
    }

    .program-goals {
        margin-left: 1.25rem;
    }
`;

export default CardFormGroup;
