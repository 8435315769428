import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import styled from 'styled-components';

const StyledSettingListItem = styled.div`
    div {
        display: inline-block;
        margin: 10px;
        width: 150px;
    }
`;
const InvoiceAchInfo = () => {
    return (
        <>
            <Row className="justify-content-center">
                <Col>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col>
                                    <img
                                        style={{ width: `100%`, maxWidth: `400px` }}
                                        src="https://uploads-ssl.webflow.com/5f466098a462432df91fef63/5fa3462933aa311a701e3196_CAAHEP_Full_LBlue.gif"
                                    />
                                </Col>
                            </Row>
                            <Row className={`mt-3 mb-3`}>
                                <Col>
                                    <h1>ACH Payments</h1>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>{`
                                            To initiate an ACH payment, you can use your bank's website or 
                                            your accounting system using the following information:`}</p>
                                    <StyledSettingListItem>
                                        <div className="font-weight-bold">Company Name: </div>
                                        <div>CAAHEP</div>
                                    </StyledSettingListItem>
                                    <StyledSettingListItem>
                                        <div className="font-weight-bold">Bank Name: </div>
                                        <div>Regions Bank</div>
                                    </StyledSettingListItem>
                                    <StyledSettingListItem>
                                        <div className="font-weight-bold">Routing Number: </div>
                                        <div>063104668</div>
                                    </StyledSettingListItem>
                                    <StyledSettingListItem>
                                        <div className="font-weight-bold">Account Number: </div>
                                        <div>0062220101</div>
                                    </StyledSettingListItem>
                                    <p>{`Please note that the invoice number must be present in the ACH details.`}</p>
                                    <p>{`If you have any questions concerning this invoice, contact CAAHEP at 727-210-2350, or email cynthia@caahep.org.`}</p>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default InvoiceAchInfo;
