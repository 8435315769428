import { APIUriAms, ApiUriArms, http, httpFile } from './HttpHelper';
import {
    AmsDocument,
    AnnualReport,
    AnnualReportStatus,
    AwardLetterSend,
    CertificateSend,
    ClinicalAffiliate,
    CredentialingExamProgramOutcome,
    CredentialingExamProgramResult,
    DocumentUpload,
    EnrollmentRetention,
    NoteDto,
    PositivePlacement,
    Program,
    ProgramAccreditationAwardLetterLog,
    ProgramAddOnTrack,
    ProgramAddOnTrackCreate,
    ProgramAddOnTrackEdit,
    ProgramAddOnTrackStatusCreate,
    ProgramAmsSatellite,
    ProgramCertificate,
    ProgramComment,
    ProgramDemographics,
    ProgramDistanceEducation,
    ProgramDto,
    ProgramInformation,
    ProgramOutcomes,
    ProgramProbation,
    ProgramProbationActivity,
    ProgramResourceDetail,
    ProgramStandard,
    ProgramStatusHistory,
    ProgramSummativeMeasure,
    ProgramWithStatus,
    RelatedDocumentDto,
    Satellite,
    Survey,
    SurveyType,
    SurveyWorksheetResult,
} from '../common/Types';

class ProgramService {
    public getProgramsByInstitutionId = async (institutionId: number) => {
        const result = await http<Program[]>(
            APIUriAms,
            `/programs/byinstitution/${institutionId}`,
            undefined,
            undefined,
            null,
        );

        if (result.parsedBody) {
            return result.parsedBody.sort((a, b) => a.title.localeCompare(b.title));
        } else {
            throw new Error(result.statusText);
        }
    };

    public getProgram = async (id: number) => {
        const result = await http<Program>(APIUriAms, `/program/${id}`, undefined, undefined, null);
        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveProgram = async (program: Program) => {
        const result = await http<Program>(APIUriAms, `/program/${program.programId}`, undefined, 'PUT', program);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public transferProgram = async (programId: number, fromInstitutionId: number, toInstitutionId: number) => {
        const result = await http<Program>(
            APIUriAms,
            `/program/transfer/${programId}/${fromInstitutionId}/${toInstitutionId}`,
            undefined,
            'POST',
            null,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getAnnualReports = async (id: number) => {
        const result = await http<AnnualReport[]>(
            ApiUriArms,
            `/annualreport/byprogramid/${id}`,
            undefined,
            undefined,
            null,
        );
        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getProgramResources = async (id: number, professionId: number, year: number) => {
        const result = await http<ProgramResourceDetail>(
            ApiUriArms,
            `/program/resource/${id}/${professionId}/${year}`,
            undefined,
            undefined,
            null,
        );
        if (result.parsedBody) {
            return result.parsedBody;
        } else if (result.ok) {
            return {} as ProgramResourceDetail;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveProgramResources = async (id: number, data: ProgramResourceDetail) => {
        const result = await http<ProgramResourceDetail>(
            ApiUriArms,
            `/program/resource/${id}`,
            undefined,
            'POST',
            data,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getProgramStandads = async (id: number, year: number) => {
        const result = await http<ProgramStandard>(
            ApiUriArms,
            `/program/standard/${id}/${year}`,
            undefined,
            undefined,
            null,
        );
        if (result.parsedBody) {
            return result.parsedBody;
        } else if (result.ok) {
            return {} as ProgramStandard;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveProgramStandards = async (id: number, data: ProgramStandard) => {
        const result = await http<ProgramStandard>(ApiUriArms, `/program/standard/${id}`, undefined, 'POST', data);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getProgramDistanceEducation = async (id: number, year: number) => {
        const result = await http<ProgramDistanceEducation>(
            ApiUriArms,
            `/program/distance-ed/${id}/${year}`,
            undefined,
            undefined,
            null,
        );
        if (result.parsedBody) {
            return result.parsedBody;
        } else if (result.ok) {
            return {} as ProgramDistanceEducation;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveProgramDistanceEducation = async (id: number, data: ProgramDistanceEducation) => {
        const result = await http<ProgramDistanceEducation>(
            ApiUriArms,
            `/program/distance-ed/${id}`,
            undefined,
            'POST',
            data,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getProgramComment = async (id: number, year: number) => {
        const result = await http<ProgramComment>(
            ApiUriArms,
            `/program/comment/${id}/${year}`,
            undefined,
            undefined,
            null,
        );
        if (result.parsedBody) {
            return result.parsedBody;
        } else if (result.ok) {
            return {} as ProgramComment;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveProgramComment = async (id: number, resource: ProgramComment) => {
        const result = await http<ProgramComment>(ApiUriArms, `/program/comment/${id}`, undefined, 'POST', resource);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getClinicalAffiliatesByProgramId = async (programId: number): Promise<ClinicalAffiliate[]> => {
        const result = await http<ClinicalAffiliate[]>(
            ApiUriArms,
            `/program/satellite-affiliate/${programId}`,
            undefined,
            undefined,
            null,
        );
        if (result.parsedBody) {
            return result.parsedBody;
        } else if (result.ok) {
            return [] as ClinicalAffiliate[];
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveClinicalAffiliate = async (
        affiliate: ClinicalAffiliate,
        reportYear: number,
    ): Promise<ClinicalAffiliate> => {
        if (!affiliate.personnel) {
            affiliate = {
                ...affiliate,
                personnel: [],
            };
        }

        const result = await http<ClinicalAffiliate>(
            ApiUriArms,
            `/program/satellite-affiliate/${affiliate.satelliteId}/${reportYear}`,
            undefined,
            'POST',
            affiliate,
        );
        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getSatelliteCampusesByProgramId = async (programId: number): Promise<Satellite[]> => {
        const result = await http<Satellite[]>(ApiUriArms, `/program/satellite-affiliate/${programId}`);

        if (result.parsedBody) {
            return result.parsedBody;
        } else if (result.ok) {
            return [] as Satellite[];
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveSatelliteCampuses = async (data: Satellite[]) => {
        const result = await http<Satellite[]>(ApiUriArms, `/program/satellite-affiliate`, undefined, 'POST', data);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getProgramsNeedingAnnualReportByProfessionId = async (
        professionId: number,
    ): Promise<ProgramWithStatus[]> => {
        const result = await http<ProgramWithStatus[]>(
            APIUriAms,
            `/program/needannualreportbyprofessionid/${professionId}`,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getProgramsNeedingAnnualReport = async (): Promise<ProgramWithStatus[]> => {
        const result = await http<ProgramWithStatus[]>(APIUriAms, `/program/needannualreport`);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getProgramDemographics = async (
        id: number,
        year: number,
        reportingWindow: number,
    ): Promise<ProgramDemographics[]> => {
        const result = await http<ProgramDemographics[]>(
            ApiUriArms,
            `/program/demographic/${id}/${year}/${reportingWindow}`,
            undefined,
            undefined,
            null,
        );
        if (result.parsedBody) {
            return result.parsedBody;
        } else if (result.ok) {
            return [] as ProgramDemographics[];
        } else {
            throw new Error(result.statusText);
        }
    };

    public getAnnualReportsByProfessionIdAndYear = async (
        professionId: number,
        year: number,
    ): Promise<AnnualReport[]> => {
        const result = await http<AnnualReport[]>(
            ApiUriArms,
            `/annualreport/byprofessionidandyear/${professionId}/${year}`,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveProgramDemographics = async (id: number, data: ProgramDemographics) => {
        const result = await http<ProgramDemographics>(
            ApiUriArms,
            `/program/demographic/${id}`,
            undefined,
            'POST',
            data,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getProgramOutcomeDetail = async (programId: number, year: number): Promise<ProgramOutcomes[]> => {
        const result = await http<ProgramOutcomes[]>(ApiUriArms, `/outcomes/detail/byyear/${programId}/${year}`);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveProgramOutcomeDetail = async (data: ProgramOutcomes[]) => {
        const result = await http<ProgramOutcomes[]>(ApiUriArms, `/outcomes/detail`, undefined, 'POST', data);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getProgramCredentialingExams = async (
        programId: number,
        professionId: number,
        reportYear: number,
    ): Promise<CredentialingExamProgramOutcome[]> => {
        const result = await http<CredentialingExamProgramOutcome[]>(
            ApiUriArms,
            `/outcomes/exam-and-details/${programId}/${professionId}/${reportYear}`,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else if (result.ok) {
            return [] as CredentialingExamProgramOutcome[];
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveProgramCredentialingExams = async (data: CredentialingExamProgramResult) => {
        const result = await http<CredentialingExamProgramResult>(
            ApiUriArms,
            `/outcomes/exam-and-details`,
            undefined,
            'POST',
            data,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getProgramStatusHistoryByProgramId = async (programId: number): Promise<ProgramStatusHistory[]> => {
        const result = await http<ProgramStatusHistory[]>(APIUriAms, `/program/${programId}/statushistory`);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public createProgramStatusHistory = async (newStatus: ProgramStatusHistory): Promise<ProgramStatusHistory> => {
        const result = await http<ProgramStatusHistory>(
            APIUriAms,
            `/program/${newStatus.programId}/statushistory`,
            undefined,
            'POST',
            newStatus,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public updateProgramStatusHistory = async (status: ProgramStatusHistory): Promise<ProgramStatusHistory> => {
        const result = await http<ProgramStatusHistory>(
            APIUriAms,
            `/program/${status.programId}/statushistory`,
            undefined,
            'PATCH',
            status,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getAllPrograms = async (): Promise<Program[]> => {
        const result = await http<Program[]>(APIUriAms, `/program`);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getAllAnnualReports = async (): Promise<AnnualReport[]> => {
        const result = await http<AnnualReport[]>(ApiUriArms, `/annualreport`);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getAmsProgramDocuments = async (programId: number): Promise<AmsDocument[]> => {
        const result = await http<AmsDocument[]>(APIUriAms, `/program/${programId}/documents`);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getProgramDocuments = async (programId: number, year: number): Promise<RelatedDocumentDto[]> => {
        const result = await http<RelatedDocumentDto[]>(ApiUriArms, `/document/${programId}/${year}`);

        if (result.parsedBody) {
            return result.parsedBody.filter((x) => !x.isDeleted);
        } else {
            throw new Error(result.statusText);
        }
    };

    public uploadProgramDocument = async (upload: DocumentUpload): Promise<any> => {
        const formData = new FormData();
        formData.append('dto', JSON.stringify(upload.dto));
        if (upload.fileUpload) formData.append('fileUpload', upload.fileUpload);
        console.log(formData);
        const result = await httpFile<any>(ApiUriArms, '/document', formData);
        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getAnnualReportByProfessionProgramAndYear = async (
        programId: number,
        professionId: number,
        year: number,
    ): Promise<AnnualReport> => {
        const result = await http<AnnualReport>(
            ApiUriArms,
            `/annualreport/byprofessionidprogramidandyear/${professionId}/${programId}/${year}`,
        );

        if (result.parsedBody) {
            return new AnnualReport(result.parsedBody);
        } else {
            return new AnnualReport({
                professionId: professionId,
                programId: programId,
                reportYear: year,
                reportStatus: AnnualReportStatus.NotStarted,
            });
        }
    };

    public updateAnnualReport = async (annualReport: AnnualReport): Promise<AnnualReport> => {
        const result = await http<AnnualReport>(ApiUriArms, '/annualreport', undefined, 'PUT', {
            ...annualReport,
            completedTabIds: JSON.stringify(annualReport.completedTabIds),
        });

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getProgramInformationByProgramIdAndYear = async (
        programId: number,
        year: number,
    ): Promise<ProgramInformation> => {
        const result = await http<ProgramInformation>(ApiUriArms, `/program/information/${programId}/${year}`);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            return {
                activeYear: year,
                programId: programId,
                programInfoId: 0,
            } as ProgramInformation;
        }
    };

    public saveProgramInformation = async (programInfo: ProgramInformation): Promise<ProgramInformation> => {
        const result = await http<ProgramInformation>(
            ApiUriArms,
            `/program/information/${programInfo.programInfoId}`,
            undefined,
            'PUT',
            programInfo,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getEnrollmentRetentionDataByProgramIdReportYearReportWindow = async (
        programId: number,
        reportYear: number,
        reportWindow: number,
        startYear: number,
    ): Promise<EnrollmentRetention[]> => {
        const result = await http<EnrollmentRetention[]>(
            ApiUriArms,
            `/enrollmentretention/byprogramidreportwindowreportyear/${programId}/${reportYear}/${reportWindow}/${startYear}`,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public initializeEnrollmentRetentionDataByProgramIdReportYearReportWindow = async (
        programId: number,
        reportYear: number,
        reportWindow: number,
        averageStartYear,
    ): Promise<EnrollmentRetention[]> => {
        const result = await http<EnrollmentRetention[]>(
            ApiUriArms,
            `/enrollmentretention/byprogramidreportwindowreportyear/${programId}/${reportYear}/${reportWindow}/${averageStartYear}`,
            undefined,
            'POST',
            undefined,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveEnrollmentRetentionByEnrollmentRetentionId = async (
        enrollmentRetention: EnrollmentRetention,
    ): Promise<EnrollmentRetention> => {
        const result = await http<EnrollmentRetention>(
            ApiUriArms,
            `/enrollmentretention/${enrollmentRetention.enrollmentRetentionId}`,
            undefined,
            'POST',
            enrollmentRetention,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getPassingAverageForOutcome = async (programId: number, outcomeTypeId: number, years: number) => {
        const result = await http<any>(ApiUriArms, `/outcomes/passing-percent/${programId}/${outcomeTypeId}/${years}`);

        if (result.parsedBody) {
            return result.parsedBody;
        } else if (result.ok) {
            return undefined;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getPositivePlacementDetailByProgramId = async (programId: number): Promise<PositivePlacement[]> => {
        const result = await http<PositivePlacement[]>(ApiUriArms, `/program/positive-placement/${programId}`);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public savePositivePlacementDetailByProgramId = async (
        programId: number,
        detail: PositivePlacement,
    ): Promise<PositivePlacement> => {
        const result = await http<PositivePlacement>(
            ApiUriArms,
            `/program/positive-placement/${programId}`,
            undefined,
            'POST',
            detail,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getSurveyWorksheetResult = async (programId: number, year: number, reportWindow: number) => {
        const result = await http<any>(ApiUriArms, `/survey/worksheet-result/${programId}/${year}/${reportWindow}`);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getSurveyWorksheet = async (programId: number, year: number, reportYear: number, reportingWindow = 1) => {
        const result = await http<any>(
            ApiUriArms,
            `/survey/worksheet/${programId}/${year}/${reportYear}/${reportingWindow}`,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else if (result.ok) {
            return undefined;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getSurveyTypes = async () => {
        const result = await http<SurveyType[]>(ApiUriArms, `/survey/types`);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveSurveyWorksheetResult = async (
        surveyResults: SurveyWorksheetResult[],
    ): Promise<SurveyWorksheetResult[]> => {
        const result = await http<SurveyWorksheetResult[]>(
            ApiUriArms,
            `/survey/worksheet-result`,
            undefined,
            'POST',
            surveyResults,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveSurveyWorksheet = async (worksheet: Survey): Promise<Survey> => {
        const result = await http<Survey>(ApiUriArms, `/survey/worksheet`, undefined, 'POST', worksheet);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getSummativeMeasure = async (
        programId: number,
        professionId: number,
        year: number,
    ): Promise<ProgramSummativeMeasure> => {
        const result = await http<ProgramSummativeMeasure>(
            ApiUriArms,
            `/program/summative-measure/${programId}/${professionId}/${year}`,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveSummativeMeasure = async (data: ProgramSummativeMeasure): Promise<ProgramSummativeMeasure> => {
        const result = await http<ProgramSummativeMeasure>(
            ApiUriArms,
            `/program/summative-measure/${data.summativeMeasuresId}`,
            undefined,
            'POST',
            data,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getAddOnTracksByProgramId = async (programId: number): Promise<ProgramAddOnTrack[]> => {
        const result = await http<ProgramAddOnTrack[]>(APIUriAms, `/program/${programId}/addontracks`);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public createAddOnTrack = async (newAddOnTrack: ProgramAddOnTrackCreate): Promise<ProgramAddOnTrack> => {
        const result = await http<ProgramAddOnTrack>(
            APIUriAms,
            `/program/addontrack`,
            undefined,
            'POST',
            newAddOnTrack,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public createAddOnTrackStatus = async (
        newStatus: ProgramAddOnTrackStatusCreate,
    ): Promise<ProgramAddOnTrackStatusCreate> => {
        const result = await http<ProgramAddOnTrackStatusCreate>(
            APIUriAms,
            `/program/addontrackstatus`,
            undefined,
            'POST',
            newStatus,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public updateAddOnTrack = async (addOnTrack: ProgramAddOnTrackEdit): Promise<ProgramAddOnTrack> => {
        const result = await http<ProgramAddOnTrack>(APIUriAms, `/program/addontrack`, undefined, 'PATCH', addOnTrack);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getAccreditationAwardLetterLogs = async (
        programId: number,
    ): Promise<ProgramAccreditationAwardLetterLog[]> => {
        const result = await http<ProgramAccreditationAwardLetterLog[]>(
            APIUriAms,
            `/program/${programId}/awardletterlogs`,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getAwardLetter = async (programId: number, isPublic: boolean): Promise<RelatedDocumentDto | null> => {
        const result = await http<RelatedDocumentDto | null>(
            APIUriAms,
            `/program/${programId}/awardletter?isPublic=${isPublic}`,
        );

        if (!result.parsedBody) {
            return null;
        } else {
            return result.parsedBody;
        }
    };

    public checkForCertificate = async (programId: number): Promise<ProgramCertificate | null> => {
        const result = await http<ProgramCertificate | null>(APIUriAms, `/program/${programId}/certificate/exists`);

        if (!result.parsedBody) {
            return null;
        } else {
            return result.parsedBody;
        }
    };

    public sendAwardLetterCertificate = async (
        awardLetterCertificate: AwardLetterSend | CertificateSend,
    ): Promise<void> => {
        const result = await http<string>(
            APIUriAms,
            `/program/awardlettercertificate/send`,
            undefined,
            'POST',
            awardLetterCertificate,
        );
    };

    public generateCertificate = async (programId: number): Promise<ProgramCertificate> => {
        const result = await http<ProgramCertificate>(
            APIUriAms,
            `/program/${programId}/certificate/generate`,
            undefined,
            'POST',
            undefined,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getSatellites = async (programId: number): Promise<ProgramAmsSatellite[]> => {
        const result = await http<ProgramAmsSatellite[]>(APIUriAms, `/program/${programId}/satellites`);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveAmsSatellite = async (satellite: ProgramAmsSatellite): Promise<ProgramAmsSatellite> => {
        const result = await http<ProgramAmsSatellite>(APIUriAms, `/program/satellite`, undefined, 'PUT', satellite);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getProbationHistory = async (programId: number): Promise<ProgramProbation[]> => {
        const result = await http<ProgramProbation[]>(APIUriAms, `/program/${programId}/probation`);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getProbationActivity = async (programId: number): Promise<ProgramProbationActivity[]> => {
        const result = await http<ProgramProbationActivity[]>(APIUriAms, `/program/${programId}/probationActivity`);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public startProgramProbation = async (probation: ProgramProbation): Promise<ProgramProbation> => {
        const result = await http<ProgramProbation>(APIUriAms, `/program/probation`, undefined, 'POST', probation);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public endProgramProbation = async (probation: ProgramProbation): Promise<ProgramProbation> => {
        const result = await http<ProgramProbation>(APIUriAms, `/program/probation`, undefined, 'DELETE', probation);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public updateProgramProbation = async (probation: ProgramProbation): Promise<ProgramProbation> => {
        const result = await http<ProgramProbation>(APIUriAms, `/program/probation`, undefined, 'PATCH', probation);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public cohortDeleteByReportYear = async (
        enrollmentRetentionId: number,
        reportYear: number,
    ): Promise<EnrollmentRetention> => {
        const result = await http<EnrollmentRetention>(
            ApiUriArms,
            `/enrollmentretention/${enrollmentRetentionId}/${reportYear}`,
            undefined,
            'DELETE',
            undefined,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };
    public getProgramNames = async (professionId?: number) => {
        const result = await http<string[]>(
            APIUriAms,
            `/program/names?professionId=${professionId || ''}`,
            undefined,
            undefined,
            null,
        );
        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };
}

export const programService = new ProgramService();
