import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

type Props = {
    username: string;
};

/**
 * Username dropdown with links to account pages
 * @param {Props} props
 */
const ProfileDropDown = (props: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
            <DropdownToggle
                data-toggle="dropdown"
                tag="button"
                className="btn btn-link nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light"
                onClick={() => setIsOpen(!isOpen)}
                aria-expanded={isOpen}
            >
                <span>
                    <span className="ml-1">
                        {props.username} <i className="mdi mdi-chevron-down"></i>{' '}
                    </span>
                </span>
            </DropdownToggle>
            <DropdownMenu right className="dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
                <div onClick={() => setIsOpen(!isOpen)}>
                    <div className="dropdown-header noti-title">
                        <h6 className="text-overflow m-0">Welcome!</h6>
                    </div>
                    <Link to="/profile" className="dropdown-item notify-item">
                        <i className={`remixicon-account-circle-line mr-1`}></i>
                        <span>Profile</span>
                    </Link>
                    <Link to="/logout" className="dropdown-item notify-item">
                        <i className={`remixicon-logout-box-line mr-1`}></i>
                        <span>Logout</span>
                    </Link>
                </div>
            </DropdownMenu>
        </Dropdown>
    );
};

export default ProfileDropDown;
