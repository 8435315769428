import * as yup from 'yup';

import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { SatelliteType, States } from '../common/Data';

import React from 'react';
import { Satellite } from '../common/Types';
import { programService } from '../services/ProgramService';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

interface SatelliteFormProps {
    onSave: () => void;
    onCancel: () => void;
    programId: number;
    satellite?: Satellite;
}

const SatelliteForm = (props: SatelliteFormProps) => {
    const satelliteSchema: yup.ObjectSchema<Satellite> = yup
        .object({
            satelliteId: yup
                .number()
                .required()
                .default(props.satellite?.satelliteId || 0),
            programId: yup.number().required().default(props.programId),
            title: yup.string().notRequired(),
            city: yup.string().notRequired(),
            state: yup.string().notRequired(),
            postalCode: yup.string().notRequired(),
            satalliteTypeId: yup.number().required().default(SatelliteType['Satellite Campus']),
            address: yup.string().notRequired(),
            dateCreated: yup
                .date()
                .required()
                .default(new Date(props.satellite?.dateCreated || new Date())),
            isActive: yup
                .boolean()
                .required()
                .default(props.satellite?.isActive || true),
        })
        .defined();

    const {
        handleSubmit,
        register,
        watch,
        setValue,
        formState: { isSubmitting },
    } = useForm({
        validationSchema: satelliteSchema,
        defaultValues:
            props.satellite ||
            ({
                isActive: true,
            } as Satellite),
    });

    const onSubmit = (values: Satellite): Promise<void> => {
        return new Promise((res) => {
            const toastId = toast.info('Saving Clinical Affiliate...');

            programService
                .saveSatelliteCampuses([values])
                .then((result) => {
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Satellite Campus saved successfuly',
                    });
                    props.onSave();
                })
                .catch(() => {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Error saving Satellite Campus',
                    });
                })
                .finally(() => {
                    res();
                });
        });
    };

    const watchIsActive = JSON.parse(watch('isActive').toString());

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <input type={'hidden'} name={'isActive'} ref={register()} />
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Name</Label>
                        <Input name="title" innerRef={register()} bsSize={'sm'} />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={3}>
                    <FormGroup>
                        <Label>Address</Label>
                        <Input name="address" innerRef={register()} bsSize={'sm'} />
                    </FormGroup>
                </Col>
                <Col xs={12} md={3}>
                    <FormGroup>
                        <Label>City</Label>
                        <Input name="city" innerRef={register()} bsSize={'sm'} />
                    </FormGroup>
                </Col>
                <Col xs={12} md={3}>
                    <FormGroup>
                        <Label>State</Label>
                        <Input type={'select'} name={'state'} bsSize={'sm'} innerRef={register()}>
                            <option value={undefined}>{``}</option>
                            {States &&
                                States.map((s) => (
                                    <option key={s.abbreviation} value={s.abbreviation}>
                                        {s.abbreviation}
                                    </option>
                                ))}
                        </Input>
                    </FormGroup>
                </Col>
                <Col xs={12} md={3}>
                    <FormGroup>
                        <Label>Zip / Postal Code</Label>
                        <Input name="postalCode" innerRef={register()} bsSize={'sm'} />
                    </FormGroup>
                </Col>
            </Row>

            <Row className={'mt-2'}>
                <Col xs={12} className={'d-flex flex-row-reverse'}>
                    <Button type="button" color={'link'} onClick={props.onCancel}>
                        Cancel
                    </Button>
                    <Button disabled={isSubmitting} type="submit" color={'primary'}>
                        Save
                    </Button>
                    {props.satellite && (
                        <Button
                            disabled={isSubmitting}
                            type="button"
                            color={watchIsActive ? 'danger' : 'secondary'}
                            className={'mr-5'}
                            onClick={() => setValue('isActive', false)}
                        >
                            <i className={'mdi mdi-delete'} />
                            {watchIsActive && <span className={'ml-1'}>Remove Satellite Campus</span>}
                            {!watchIsActive && <span className={'ml-1'}>Click Save to Confirm</span>}
                        </Button>
                    )}
                </Col>
            </Row>
        </Form>
    );
};

export default SatelliteForm;
