import { Card, CardBody, Col, Form, FormGroup, Label, Row, Table } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import { Button } from '../../components';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { DegreeByAuspiceReportDto } from '../../common/Types';
import { reportService } from '../../services/ReportService';
import MultiSelect from 'react-multi-select-component';
import styled from 'styled-components';
import { institutionService } from '../../services/InstitutionService';
import { DegreeTypes } from '../../common/Data';

interface AuspiceOption {
    label: string;
    value: number;
}

const StyledMultiSelect = styled(MultiSelect)`
    width: 300px;

    & .dropdown-container {
        width: 100%;
        max-width: 100%;
    }
    & .dropdown-content {
        z-index: 3;
    }
    & .select-item {
        display: block;
    }
`;
const degreeOptions = Object.entries(DegreeTypes)
    .filter((d) => !isNaN(d[0] as any))
    .map((d) => {
        return {
            label: d[1],
            value: d[0],
        };
    });
const DegreesByAuspiceReport = () => {
    const [selectedDegreeType, setSelectedDegreeType] = useState(degreeOptions);
    const [degreeByAuspiceReport, setDegreeByAuspiceReport] = useState<DegreeByAuspiceReportDto>();
    const [auspiceOptions, setAuspiceOptions] = useState<AuspiceOption[]>();
    const [selectedAuspices, setSelectedAuspices] = useState<AuspiceOption[]>();

    useEffect(() => {
        const loadAuspices = () => {
            institutionService
                .getAuspice()
                .then((results) => {
                    const mappedAuspices = (
                        results.filter((r) => r.isActive).sort((a, b) => a.title.localeCompare(b.title)) || []
                    ).map((a) => {
                        return {
                            label: a.title,
                            value: a.auspiceID,
                        };
                    });
                    setAuspiceOptions(mappedAuspices);
                    setSelectedAuspices(mappedAuspices);
                })
                .catch(() => {
                    toast.error('Error loading auspices');
                });
        };
        loadAuspices();
    }, [setAuspiceOptions]);

    const { handleSubmit } = useForm();

    const onSubmit = () => {
        const toastId = toast.info('Searching...', {
            autoClose: false,
        });
        reportService
            .getDegreesByAuspiceReport(
                selectedDegreeType?.map((d) => +d['value']),
                selectedAuspices?.map((a) => a['value']),
            )
            .then((response) => {
                setDegreeByAuspiceReport(response);
                toast.update(toastId, {
                    type: 'success',
                    autoClose: 500,
                });
            })
            .catch(() => toast.error('Failed to generate report'));
    };

    const exportReport = () => {
        const toastId = toast.info('Generating report, this may take a minute...', {
            autoClose: false,
        });
        reportService
            .exportDegreesByAuspiceReport(
                selectedDegreeType?.map((d) => +d['value']),
                selectedAuspices?.map((a) => a['value']),
            )
            .then((report) => {
                if (report) {
                    const url = window.URL.createObjectURL(report);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `DegreesByAuspiceReport.xlsx`;
                    a.click();
                    toast.update(toastId, {
                        render: 'Report generated successfully.',
                        type: 'success',
                        autoClose: 2500,
                    });
                }
            })
            .catch(() => {
                toast.error(`Unable to generate report`);
            });
    };

    return (
        <>
            <Card>
                <CardBody>
                    <Row>
                        <Col>
                            <div className="mb-3">
                                <h3>Degrees By Auspice Report</h3>
                            </div>
                            <Form onSubmit={handleSubmit(onSubmit)} inline>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                    <Label for="degreeTypes" className="mr-sm-2">
                                        Degree Types
                                    </Label>
                                    <StyledMultiSelect
                                        options={degreeOptions}
                                        disableSearch={true}
                                        hasSelectAll={true}
                                        value={selectedDegreeType}
                                        onChange={setSelectedDegreeType}
                                        required
                                    />
                                </FormGroup>
                                {auspiceOptions && auspiceOptions?.length > 0 && (
                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                        <Label for="auspice" className="mr-sm-2">
                                            Auspice
                                        </Label>
                                        <StyledMultiSelect
                                            options={auspiceOptions}
                                            disableSearch={true}
                                            hasSelectAll={true}
                                            value={selectedAuspices}
                                            onChange={setSelectedAuspices}
                                            required
                                        />
                                    </FormGroup>
                                )}
                                <div className="mb-2 mr-sm-2 mb-sm-2">
                                    <Button type="submit">Search</Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col style={{ display: 'flex' }}>
                            <div className="mr-2">
                                <Button color="info" onClick={() => exportReport()}>
                                    Export Report
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <div className="mt-3">
                        <h4>Degrees By Auspices</h4>
                    </div>
                </CardBody>
                <Table>
                    {degreeByAuspiceReport?.degreeByAuspiceDtos ? (
                        degreeByAuspiceReport.degreeByAuspiceDtos.map((record) => (
                            <tbody key={record.degreeTypeId}>
                                <tr>
                                    <td className={`font-weight-bold`}>
                                        {record.degreeTypeTitle} - {record.totalCount} programs of which
                                    </td>
                                </tr>
                                {record.programByAuspiceDtos ? (
                                    record.programByAuspiceDtos.map((program) => (
                                        <div key={program.auspiceId}>
                                            <tr>
                                                <td>
                                                    {program.auspiceCount} are {program.auspiceTitle}
                                                </td>
                                            </tr>
                                        </div>
                                    ))
                                ) : (
                                    <tr>
                                        <td />
                                    </tr>
                                )}
                            </tbody>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={7}>No Results</td>
                        </tr>
                    )}
                    <tr>
                        <td className={`font-weight-bold`}>
                            Total Records: {degreeByAuspiceReport?.recordCount ? degreeByAuspiceReport?.recordCount : 0}
                        </td>
                    </tr>
                </Table>
            </Card>
        </>
    );
};

export default DegreesByAuspiceReport;
