import { AmsPermissionClaimType, ArmsPermissionClaimType, IdentityPermissionClaimType } from '../../services/Auth';
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import { Permission, RolePermission } from '../../common/Types';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import PageTitle from '../../components/PageTitle';
import { securityService } from '../../services/SecurityService';
import styled from 'styled-components';
import { toast } from 'react-toastify';

const CenteredTable = styled.table`
    & tr > td:not(:nth-child(2)) {
        text-align: center;
    }
    & tr > th {
        font-weight: bold;

        :not(:nth-child(2)) {
            text-align: center;
        }
    }
    & tr:first-child > th {
        text-align: center;
    }
`;

const RolesPermissions = () => {
    const [rolePermissions, setRolePermissions] = useState<RolePermission[]>([]);
    const [reloading, reload] = useState(true);
    const [showModal, setModal] = useState(false);
    const [editingRole, setEditingRole] = useState<RolePermission>({
        permissions: [],
        roleId: '',
        roleName: '',
    });
    const { handleSubmit, register, control, reset } = useForm();
    const { fields } = useFieldArray({
        control,
        name: 'permissions',
    });

    useEffect(() => {
        if (reloading) {
            reload(false);
            securityService
                .getRolesAndPermissions()
                .then((response) => {
                    setRolePermissions(response || []);
                })
                .catch(() => {
                    toast.error('There was an issue loading roles & permissions');
                });
        }
    }, [setRolePermissions, reloading]);

    const hasPermission = (claimType: string, claimValue: string, permissions: Permission[]): boolean => {
        return (
            permissions &&
            (permissions.filter((p) => p.permissionClaimType === claimType && p.permissionValue === claimValue)[0]
                ?.isSelected ||
                false)
        );
    };

    const toggleModal = () => setModal(!showModal);

    const editRolePermission = (rolePermission: RolePermission): void => {
        setEditingRole(rolePermission);
        reset(rolePermission);
        toggleModal();
    };

    const savePermissions = (rolePermission: any): void => {
        const toastId = toast.info('Saving...');

        securityService
            .savePermissionsForRole(new RolePermission(rolePermission))
            .then(() => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Save successful!',
                });
                reload(true);
                setModal(false);
            })
            .catch((error) => {
                toast.update(toastId, {
                    type: 'error',
                    render: error.message || 'There was an issue saving the permissions.',
                });
            });
    };

    return (
        <>
            <Row>
                <Col>
                    <PageTitle title="Roles &amp; Permissions" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className={'card-box'}>
                        <div className={'responsive-table-plugin'}>
                            <div className={'table-wrapper'}>
                                <div className={'table-responsive'}>
                                    <CenteredTable className={'table table-hover table-bordered'}>
                                        <thead>
                                            <tr>
                                                <th colSpan={2}></th>
                                                <th colSpan={3}>Overall System</th>
                                                <th colSpan={2}>AMS Functions</th>
                                                <th colSpan={5}>ARMS Functions</th>
                                                <th colSpan={3}>Hub Functions</th>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                <th>Role</th>
                                                <th>Manage Users</th>
                                                <th>Manage Permissions</th>
                                                <th>System Administration</th>
                                                <th>Recomm. - Create</th>
                                                <th>Recomm. - Complete</th>
                                                <th>Configuration</th>
                                                <th>Manage All Professions</th>
                                                <th>Manage Exams List</th>
                                                <th>View Annual Reports</th>
                                                <th>Edit Annual Reports</th>
                                                <th>Manage Users</th>
                                                <th>Manage Folders &amp; Documents</th>
                                                <th>View Documents</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rolePermissions &&
                                                rolePermissions.map((rolePermission) => (
                                                    <tr key={rolePermission.roleId}>
                                                        <td>
                                                            <Button
                                                                type="button"
                                                                color="info"
                                                                className="edit"
                                                                onClick={() => editRolePermission(rolePermission)}
                                                            >
                                                                <i className={'mdi mdi-account-edit'} />
                                                                <span className={'ml-1'}>Edit</span>
                                                            </Button>
                                                        </td>
                                                        <td>{rolePermission.roleName}</td>
                                                        <td>
                                                            {hasPermission(
                                                                IdentityPermissionClaimType,
                                                                'manageusers',
                                                                rolePermission.permissions,
                                                            ) && (
                                                                <span className={'badge badge-success'}>
                                                                    <i className={'mdi mdi-check-bold'} />
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {hasPermission(
                                                                IdentityPermissionClaimType,
                                                                'managepermissions',
                                                                rolePermission.permissions,
                                                            ) && (
                                                                <span className={'badge badge-success'}>
                                                                    <i className={'mdi mdi-check-bold'} />
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {hasPermission(
                                                                AmsPermissionClaimType,
                                                                'systemadmin',
                                                                rolePermission.permissions,
                                                            ) && (
                                                                <span className={'badge badge-success'}>
                                                                    <i className={'mdi mdi-check-bold'} />
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {hasPermission(
                                                                AmsPermissionClaimType,
                                                                'recommendation-create',
                                                                rolePermission.permissions,
                                                            ) && (
                                                                <span className={'badge badge-success'}>
                                                                    <i className={'mdi mdi-check-bold'} />
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {hasPermission(
                                                                AmsPermissionClaimType,
                                                                'recommendation',
                                                                rolePermission.permissions,
                                                            ) && (
                                                                <span className={'badge badge-success'}>
                                                                    <i className={'mdi mdi-check-bold'} />
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {hasPermission(
                                                                ArmsPermissionClaimType,
                                                                'admin',
                                                                rolePermission.permissions,
                                                            ) && (
                                                                <span className={'badge badge-success'}>
                                                                    <i className={'mdi mdi-check-bold'} />
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {hasPermission(
                                                                ArmsPermissionClaimType,
                                                                'adminallprofessions',
                                                                rolePermission.permissions,
                                                            ) && (
                                                                <span className={'badge badge-success'}>
                                                                    <i className={'mdi mdi-check-bold'} />
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {hasPermission(
                                                                ArmsPermissionClaimType,
                                                                'adminexams',
                                                                rolePermission.permissions,
                                                            ) && (
                                                                <span className={'badge badge-success'}>
                                                                    <i className={'mdi mdi-check-bold'} />
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {hasPermission(
                                                                ArmsPermissionClaimType,
                                                                'viewannualreport',
                                                                rolePermission.permissions,
                                                            ) && (
                                                                <span className={'badge badge-success'}>
                                                                    <i className={'mdi mdi-check-bold'} />
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {hasPermission(
                                                                ArmsPermissionClaimType,
                                                                'mutateannualreport',
                                                                rolePermission.permissions,
                                                            ) && (
                                                                <span className={'badge badge-success'}>
                                                                    <i className={'mdi mdi-check-bold'} />
                                                                </span>
                                                            )}
                                                        </td>

                                                        <td>
                                                            {hasPermission(
                                                                AmsPermissionClaimType,
                                                                'hubmanageusers',
                                                                rolePermission.permissions,
                                                            ) && (
                                                                <span className={'badge badge-success'}>
                                                                    <i className={'mdi mdi-check-bold'} />
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {hasPermission(
                                                                AmsPermissionClaimType,
                                                                'hubmanagestructure',
                                                                rolePermission.permissions,
                                                            ) && (
                                                                <span className={'badge badge-success'}>
                                                                    <i className={'mdi mdi-check-bold'} />
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {hasPermission(
                                                                AmsPermissionClaimType,
                                                                'hubviewdocuments',
                                                                rolePermission.permissions,
                                                            ) && (
                                                                <span className={'badge badge-success'}>
                                                                    <i className={'mdi mdi-check-bold'} />
                                                                </span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </CenteredTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Modal isOpen={showModal} toggle={toggleModal}>
                <Form onSubmit={handleSubmit((values) => savePermissions(values as RolePermission))}>
                    <ModalHeader
                        toggle={toggleModal}
                    >{`Manage Role Permissions - ${editingRole.roleName}`}</ModalHeader>
                    <ModalBody>
                        <input type="hidden" name="roleId" ref={register({})} />
                        <input type="hidden" name="roleName" ref={register({})} />
                        {fields &&
                            fields.map((permission, i) => (
                                <FormGroup key={i} check className={'custom-control custom-checkbox row mt-3'}>
                                    <Col className={'pl-5'}>
                                        <input
                                            type="hidden"
                                            name={`permissions[${i}].title`}
                                            defaultValue={permission.title}
                                            ref={register({})}
                                        />
                                        <input
                                            type="hidden"
                                            name={`permissions[${i}].permissionClaimType`}
                                            defaultValue={permission.permissionClaimType}
                                            ref={register({})}
                                        />
                                        <input
                                            type="hidden"
                                            name={`permissions[${i}].permissionValue`}
                                            defaultValue={permission.permissionValue}
                                            ref={register({})}
                                        />
                                        <Input
                                            type="checkbox"
                                            id={`permissions[${i}].isSelected`}
                                            name={`permissions[${i}].isSelected`}
                                            innerRef={register()}
                                            defaultChecked={permission.isSelected}
                                            className={'custom-control-input'}
                                        />
                                        <Label for={`permissions[${i}].isSelected`} className={'custom-control-label'}>
                                            {permission.title}
                                        </Label>
                                    </Col>
                                </FormGroup>
                            ))}
                    </ModalBody>
                    <ModalFooter className={'mt-3'}>
                        <Button type="submit" color={'primary'}>
                            Save Changes
                        </Button>
                        <Button type="button" color={'link'} onClick={() => toggleModal()}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};

export default RolesPermissions;
