import React, { useEffect, useState } from 'react';
import { PageTitle, Button } from '../../components';
import { Card, CardBody, Form, Label, FormGroup, Input, Table, Row, Col } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { InvoicingDto } from '../../types';
import { PaymentTypes, PaymentType } from '../../common/Data';
import { invoiceService } from '../../services/InvoiceService';
import { toast } from 'react-toastify';
import InvoiceEmailsTable from './InvoiceEmailsTable';
import { generateYears } from '../../common/Utils';

const SendInvoiceEmails = () => {
    const [invoices, setInvoices] = useState<InvoicingDto[]>();
    const [year, setyear] = useState(new Date().getFullYear());
    const [reload, setReload] = useState(false);

    useEffect(() => {
        const getData = () => {
            invoiceService
                .getUnsentInvoices(year)
                .then((response) => setInvoices(response))
                .catch(() => toast.error('Failed to get invoices'));
        };

        getData();
    }, [setInvoices, year, reload]);

    const { register, handleSubmit } = useForm();

    const onSubmit = (formData) => {
        setyear(formData.fiscalYear);
        setInvoices(undefined);
    };

    const reloadInvoices = () => {
        setInvoices(undefined);
        setReload(true);
    };

    return (
        <>
            <PageTitle title="Send Invoice Emails" />

            <Card>
                <CardBody>
                    <Row>
                        <Col>
                            <Form onSubmit={handleSubmit(onSubmit)} inline>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="fiscalYear" className="mr-sm-2">
                                        Fiscal Year
                                    </Label>
                                    <Input type="select" innerRef={register()} name="fiscalYear" defaultValue={year}>
                                        <option value={''}>Select...</option>
                                        {generateYears().map((ct) => (
                                            <option key={ct} value={ct}>
                                                {ct}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <div>
                                    <Button type="submit">Search</Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                    <div className="mt-3">
                        <h3>Unsent Emails</h3>
                    </div>
                </CardBody>
                {invoices && <InvoiceEmailsTable invoices={invoices} onSave={() => reloadInvoices()} />}
                {!invoices && (
                    <Table>
                        <tr>
                            <td>Loading Results</td>
                        </tr>
                    </Table>
                )}
            </Card>
        </>
    );
};

export default SendInvoiceEmails;
