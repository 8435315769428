import { AnnualReportNavigationDirection, AnnualReportProps } from '../AnnualReport';
import { Card, CardBody, CardFooter } from 'reactstrap';

import { AnnualReportStatus } from '../../../common/Types';
import { Button } from '../../../components';
import React from 'react';

const GettingStarted = (props: AnnualReportProps) => {
    return (
        <Card>
            {props.annualReport &&
                props.annualReport.reportStatus !== AnnualReportStatus.Submitted &&
                props.annualReport.reportStatus !== AnnualReportStatus.Completed && (
                    <CardBody>
                        <h3 className={'card-title'}>{`Welcome to the ${props.reportYear} Annual Report`}</h3>
                        <p>
                            {`Please use the buttons below or to the left to navigate through and to complete each section.
                        Upon completion of each section, please click the 'Save' or 'Save &
                        Continue' buttons to save any changes to that section.`}
                        </p>
                        <p>
                            {`Once all of the sections are complete, use the 'Submit' button to finalize the report.
                        Upon completion, you will have the option to download a PDF of the completed report for your own
                        records.`}
                        </p>
                    </CardBody>
                )}
            {props.annualReport && props.annualReport.reportStatus === AnnualReportStatus.Submitted && (
                <CardBody>
                    <h3 className={'card-title'}>{`Thank you for submitting the ${props.reportYear} Annual Report`}</h3>
                    <p>
                        {`Thank you for submitting your Annual Report. Your report is currently being reviewed by the CoA team 
                          and you will be notified if either the report needs additional information or is accepted.`}
                    </p>
                    <p>
                        {`While your report is being reviewed, you may download a copy of the submitted report by clicking the Preview PDF button.`}
                    </p>
                </CardBody>
            )}
            {props.annualReport && props.annualReport.reportStatus === AnnualReportStatus.Completed && (
                <CardBody>
                    <h3 className={'card-title'}>{`Thank you for submitting the ${props.reportYear} Annual Report`}</h3>
                    <p>
                        {`Your Annual Report has been reviewed and accepted. If you would like to download a copy of your Annual Report, 
                        please click the 'Download Report' button below.`}
                    </p>
                </CardBody>
            )}
            <CardFooter className={'d-flex justify-content-between flex-row-reverse'}>
                {props.annualReport.reportStatus === AnnualReportStatus.NotStarted && (
                    <Button
                        type={'button'}
                        className={'btn btn-info'}
                        onClick={() => props.onSavedSuccessfully(AnnualReportNavigationDirection.Forward)}
                    >
                        <span className={'mr-1'}>Get Started</span>
                        <i className={'mdi mdi-arrow-right'} />
                    </Button>
                )}
                {props.annualReport.reportStatus === AnnualReportStatus.NotRequired && (
                    <Button disabled type={'button'} className={'btn btn-info'}>
                        <span className={'mr-1'}>No Report Required</span>
                    </Button>
                )}
                {(props.annualReport.reportStatus === AnnualReportStatus.InProgress ||
                    props.annualReport.reportStatus === AnnualReportStatus.SentBack) && (
                    <Button
                        type={'button'}
                        className={'btn btn-info'}
                        onClick={() => props.onNavigate(AnnualReportNavigationDirection.Forward)}
                    >
                        <span className={'mr-1'}>Continue Annual Report</span>
                        <i className={'mdi mdi-arrow-right'} />
                    </Button>
                )}
            </CardFooter>
        </Card>
    );
};

export default GettingStarted;
