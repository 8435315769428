import { AnnualReportNavigationDirection, AnnualReportProps } from '../AnnualReport';
import {
    Badge,
    Card,
    CardBody,
    CardFooter,
    CardSubtitle,
    Col,
    FormGroup,
    Label,
    ListGroup,
    ListGroupItem,
    ListGroupItemHeading,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Table,
} from 'reactstrap';
import { Button, Collapse, ExamForm, Input } from '../../../components';
import {
    CredentialingExamProgramOutcome,
    CredentialingExamProgramResult,
    EnrollmentRetention,
    EnrollmentRetentionDetail,
    ProgramAddOnTrack,
    ProgramOutcomes,
} from '../../../common/Types';
import React, { useEffect, useState } from 'react';

import { programService } from '../../../services/ProgramService';
import { toast } from 'react-toastify';

const CredentialingExams = (props: AnnualReportProps) => {
    const { settings, reportYear } = props;

    const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
    const [surveyYear, setSurveyYear] = useState<number>(
        settings?.reportingWindow?.yearAvarageStart || +reportYear - 1,
    );
    const [allExams, setAllExams] = useState<CredentialingExamProgramOutcome[]>();
    const [reloadExams, setReloadExams] = useState<boolean>(true);
    const [filteredExams, setFilteredExams] = useState<CredentialingExamProgramOutcome[]>();
    const [addOnTracks, setAddOnTracks] = useState<ProgramAddOnTrack[]>();
    const [enrollmentRetention, setEnrollmentRetention] = useState<EnrollmentRetention[]>();

    const [selectedExam, setSelectedExam] = useState<CredentialingExamProgramOutcome>();
    const [selectedResult, setSelectedResult] = useState<CredentialingExamProgramResult>();

    const [showModal, setShowModal] = useState<boolean>(false);
    const toggleModal = () => setShowModal(!showModal);

    useEffect(() => {
        const determineSurveyYear = () => {
            if (settings?.reportingWindow?.yearAvarageStart) {
                setSurveyYear(settings.reportingWindow.yearAvarageStart);
            } else {
                setSurveyYear(reportYear - 1);
            }
        };

        determineSurveyYear();
    }, [reportYear, settings, setSurveyYear]);

    useEffect(() => {
        const getAddOnTracks = () => {
            if (props.program?.programId) {
                programService
                    .getAddOnTracksByProgramId(props.program.programId)
                    .then((results) => {
                        setAddOnTracks(results.filter((a) => a.active));
                    })
                    .catch(() => {
                        toast.error('Error loading program add-on tracks');
                    });
            }
        };

        getAddOnTracks();
    }, [setAddOnTracks, props.program]);

    useEffect(() => {
        const getExams = () => {
            if (surveyYear && reloadExams && props.program && reportYear) {
                setReloadExams(false);
                programService
                    .getProgramCredentialingExams(props.program.programId, props.program.professionId, +reportYear)
                    .then((response) => {
                        setAllExams(response);
                    })
                    .catch(() => toast.error('Failed to load exams...'));
            }
        };
        getExams();
    }, [surveyYear, reloadExams, props, reportYear, setAllExams]);

    useEffect(() => {
        const getEnrollmentRetention = () => {
            if (props.program.programId && props.reportYear) {
                programService
                    .getEnrollmentRetentionDataByProgramIdReportYearReportWindow(
                        props.program.programId,
                        +props.reportYear,
                        props.settings.reportingWindow.reportingWindow || 0,
                        surveyYear,
                    )
                    .then((result) => {
                        setEnrollmentRetention(result);
                    })
                    .catch(() => {
                        toast.error('Error loading graduate detail.');
                    });
            }
        };

        getEnrollmentRetention();
    }, [setEnrollmentRetention, props.program.programId, props.reportYear, props.settings.reportingWindow, surveyYear]);

    useEffect(() => {
        const filterExams = () => {
            if (allExams && addOnTracks && props.program) {
                setFilteredExams([
                    ...allExams.filter(
                        (e) =>
                            e.isActive &&
                            (!e.addOnTrackId ||
                                (e.addOnTrackId && addOnTracks.find((a) => a.addOnTrackId === e.addOnTrackId))) &&
                            (!e.concentrationIds ||
                                (e.concentrationIds &&
                                    JSON.parse(`[${e.concentrationIds}]`).filter(
                                        (c) => c === props.program.concentrationId,
                                    ).length > 0)),
                    ),
                ]);

                if (selectedExam) {
                    const newSelectedExam = allExams.find((e) => e.outcomeTypeId === selectedExam.outcomeTypeId);
                    if (newSelectedExam) {
                        setSelectedExam({
                            ...newSelectedExam,
                        });
                    } else {
                        setSelectedExam(undefined);
                    }
                }
            }
        };

        filterExams();
    }, [allExams, addOnTracks, props.program]);

    const getEachReportYear = (): number[] => {
        const years = [] as number[];

        for (let i = surveyYear; i > surveyYear - (props.settings.reportingWindow.reportingWindow || 1); i--) {
            years.push(i);
        }

        return years;
    };

    const getGraduatesByYear = (graduationYear: number): number => {
        if (enrollmentRetention) {
            const cohorts = enrollmentRetention.map((c) => {
                return c.enrollmentRetentionDetailsDto || [];
            });
            const cohortGrads = ([] as EnrollmentRetentionDetail[]).concat
                .apply([], cohorts)
                .filter((c) => c.graduationYear === graduationYear);

            let graduates = 0;

            cohortGrads.forEach((c) => {
                graduates += c.graduated || 0;
            });

            return graduates;
        }

        return 0;
    };

    const getOutcomeExamDetailByGraduationYear = (
        exam: CredentialingExamProgramOutcome,
        year: number,
    ): CredentialingExamProgramResult | undefined => {
        if (exam.results) {
            return exam.results.find((e) => e.graduatingYear === year);
        }

        return undefined;
    };

    const editResult = (year: number) => {
        if (selectedExam && selectedExam.results) {
            const result = selectedExam.results.find((e) => e.graduatingYear === year);

            if (result) {
                setSelectedResult({ ...result });
            } else {
                setSelectedResult({
                    graduatingYear: year,
                    id: 0,
                    outcomeTypeId: selectedExam.outcomeTypeId,
                    reportYear: +reportYear,
                    programId: props.program.programId,
                    numberOfGradsAttemptingExam: 0,
                    passing1stAttempt: 0,
                    passingSubsequentAttempts: 0,
                    total: 0,
                });
            }
            toggleModal();
        }
    };

    const showActionPlan = (exam: CredentialingExamProgramOutcome) => {
        return (
            (exam.reportWindowAverage || 0) < (exam.outcomeThreshold || 0) ||
            (exam.oneYearAverage || 0) < (exam.outcomeThreshold || 0)
        );
    };

    const isExamComplete = (exam: CredentialingExamProgramOutcome): boolean => {
        let isComplete = true;

        const result = exam?.results?.find((r) => r.graduatingYear === surveyYear);

        if (!result) {
            isComplete = false;
        }

        return isComplete;
    };

    const statusBadge = (exam: CredentialingExamProgramOutcome) => {
        const complete = isExamComplete(exam);
        return (
            <Badge color={complete ? 'success' : 'warning'} pill>
                {complete ? 'complete' : 'incomplete'}
            </Badge>
        );
    };

    const isFormComplete = (): boolean => {
        let isValid = true;
        if (filteredExams) {
            filteredExams.forEach((e) => {
                if (!isExamComplete(e)) {
                    isValid = false;
                }
            });
        }

        return isValid;
    };

    const handleAnalysisUpdate = (value: string) => {
        if (!filteredExams || !selectedExam) {
            return;
        }

        const copiedExams = [...filteredExams];
        const selectedExamCopy = copiedExams.find((e) => e.outcomeTypeId === selectedExam.outcomeTypeId);
        if (selectedExamCopy) {
            const selectedExamIndex = copiedExams.indexOf(selectedExamCopy);
            const newExam = {
                ...selectedExamCopy,
                detailedAnalysis: value,
            };
            copiedExams.splice(selectedExamIndex, 1, newExam);

            setFilteredExams([...copiedExams]);
            setSelectedExam({ ...selectedExamCopy });
        }
    };

    const handleActionPlanUpdate = (value: string) => {
        if (!filteredExams || !selectedExam) {
            return;
        }

        const copiedExams = [...filteredExams];
        const selectedExamCopy = copiedExams.find((e) => e.outcomeTypeId === selectedExam.outcomeTypeId);
        if (selectedExamCopy) {
            const selectedExamIndex = copiedExams.indexOf(selectedExamCopy);
            const newExam = {
                ...selectedExamCopy,
                actionPlan: value,
            };
            copiedExams.splice(selectedExamIndex, 1, newExam);

            setFilteredExams([...copiedExams]);
            setSelectedExam({ ...selectedExamCopy });
        }
    };

    const saveOutcomes = (validate: boolean) => {
        if (filteredExams) {
            if (validate && !isFormComplete()) {
                toast.error('Please complete all necessary exam results.');
                return;
            }

            setFormSubmitting(true);

            const examToastId = toast.info('Saving exam results...');
            filteredExams.map((e) => {
                e.results.map((r) => {
                    programService
                        .saveProgramCredentialingExams(r)
                        .then(() => {
                            toast.update(examToastId, {
                                type: 'success',
                                render: 'Exam results saved',
                            });
                        })
                        .catch(() => {
                            toast.update(examToastId, {
                                type: 'error',
                                render: 'Error saving exam results',
                            });
                        });
                });
            });
            const toastId = toast.info('Saving outcomes...');
            programService
                .saveProgramOutcomeDetail(
                    filteredExams.map((e) => {
                        return {
                            ...e,
                            createdBy: null,
                            lastEdited: null,
                            dateCreated: null,
                            editedBy: null,
                            outcomeType: null,
                            threshold: e.outcomeThreshold,
                        } as ProgramOutcomes;
                    }),
                )
                .then(() => {
                    toast.update(toastId, {
                        render: 'Outcomes saved successfully',
                        type: 'success',
                    });

                    if (!validate) {
                        setReloadExams(true);
                    } else {
                        props.onSavedSuccessfully(AnnualReportNavigationDirection.Forward);
                    }
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error saving outcomes',
                        type: 'error',
                    });
                })
                .finally(() => {
                    setFormSubmitting(false);
                });
        }
    };

    const handleResultSave = () => {
        setReloadExams(true);
        setSelectedResult(undefined);
        toggleModal();
    };

    return (
        <>
            <Card>
                <CardBody>
                    <h3 className="card-title">{`Credentialing Exams`}</h3>
                    <CardSubtitle>
                        {`Edit the details for each exam and a 'Continue' button will appear at the bottom of the
                        list to proceed to the next step.`}
                    </CardSubtitle>
                    <ListGroup className={'mt-3'}>
                        {filteredExams &&
                            filteredExams.map((exam, i) => (
                                <ListGroupItem className="justify-content-between" key={i}>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ flex: 1 }}>
                                            <ListGroupItemHeading>{exam.title} </ListGroupItemHeading>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <div>
                                                <Label>Threshold</Label>
                                            </div>
                                            <div>{exam.outcomeThreshold}%</div>
                                        </div>
                                        {exam.outcomeTypeId && (
                                            <div style={{ flex: 1.5 }}>
                                                <div className="mb-1">
                                                    <Label>Passing Average</Label>
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ marginRight: '8px' }}>
                                                        <Label className={'mr-1'}>{`${surveyYear}:`}</Label>
                                                        <span>{`${exam.oneYearAverage?.toFixed(0) || 0}%`}</span>
                                                    </div>
                                                    {(settings?.reportingWindow?.reportingWindow || 0) > 4 && (
                                                        <div>
                                                            <Label className={'mr-1'}>{`3-Year:`}</Label>
                                                            <span className={'mr-1'}>{`${
                                                                exam.threeYearAverage?.toFixed(0) || 0
                                                            }%`}</span>
                                                        </div>
                                                    )}
                                                    {(settings?.reportingWindow?.reportingWindow || 0) > 1 && (
                                                        <div>
                                                            <Label
                                                                className={'mr-1'}
                                                            >{`${settings.reportingWindow.reportingWindow}-Year:`}</Label>
                                                            <span>{`${
                                                                exam.reportWindowAverage?.toFixed(0) || 0
                                                            }%`}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        <div style={{ flex: 0.5 }}>
                                            <ListGroupItemHeading>{statusBadge(exam)}</ListGroupItemHeading>
                                        </div>
                                        <div className="text-right" style={{ flex: 0.5 }}>
                                            {selectedExam?.outcomeTypeId !== exam.outcomeTypeId && (
                                                <Button onClick={() => setSelectedExam(exam)} color="info">
                                                    <i className={'remixicon-pencil-line'}></i>
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                    <Collapse isOpen={selectedExam?.outcomeTypeId === exam.outcomeTypeId}>
                                        {selectedExam?.outcomeTypeId === exam.outcomeTypeId && (
                                            <>
                                                <Table responsive striped>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: '40%' }}>{`Graduation Year`}</th>
                                                            {getEachReportYear().map((year) => (
                                                                <th
                                                                    className={'text-center'}
                                                                    key={year}
                                                                    style={{
                                                                        width: `${
                                                                            60 /
                                                                            (props.settings.reportingWindow
                                                                                .reportingWindow || 1)
                                                                        }%`,
                                                                    }}
                                                                >
                                                                    {year}
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{`# Graduates`}</td>
                                                            {getEachReportYear().map((year) => (
                                                                <td className={'text-center'} key={year}>
                                                                    {getGraduatesByYear(year)}
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <tr>
                                                            <td>{`# Graduates Attempted`}</td>
                                                            {getEachReportYear().map((year) => (
                                                                <td
                                                                    className={'text-center'}
                                                                    key={`${year}:${
                                                                        getOutcomeExamDetailByGraduationYear(exam, year)
                                                                            ?.numberOfGradsAttemptingExam || 0
                                                                    }`}
                                                                >
                                                                    <span>
                                                                        {getOutcomeExamDetailByGraduationYear(
                                                                            exam,
                                                                            year,
                                                                        )?.numberOfGradsAttemptingExam || 0}
                                                                    </span>
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <tr>
                                                            <td>{`# Graduates Passed - 1st Attempt`}</td>
                                                            {getEachReportYear().map((year) => (
                                                                <td
                                                                    className={'text-center'}
                                                                    key={`${year}:${
                                                                        getOutcomeExamDetailByGraduationYear(exam, year)
                                                                            ?.passing1stAttempt || 0
                                                                    }`}
                                                                >
                                                                    <span>
                                                                        {getOutcomeExamDetailByGraduationYear(
                                                                            exam,
                                                                            year,
                                                                        )?.passing1stAttempt || 0}
                                                                    </span>
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <tr>
                                                            <td>{`# Graduates Passed - Subsequent Attempts`}</td>
                                                            {getEachReportYear().map((year) => (
                                                                <td
                                                                    className={'text-center'}
                                                                    key={`${year}:${
                                                                        getOutcomeExamDetailByGraduationYear(exam, year)
                                                                            ?.passingSubsequentAttempts || 0
                                                                    }`}
                                                                >
                                                                    <span>
                                                                        {getOutcomeExamDetailByGraduationYear(
                                                                            exam,
                                                                            year,
                                                                        )?.passingSubsequentAttempts || 0}
                                                                    </span>
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        {props.canEdit && (
                                                            <tr>
                                                                <td></td>
                                                                {getEachReportYear().map((year) => (
                                                                    <td className={'text-center'} key={year}>
                                                                        {exam && (
                                                                            <Button
                                                                                type={'button'}
                                                                                color={'info'}
                                                                                onClick={() => editResult(year)}
                                                                            >{`Edit`}</Button>
                                                                        )}
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                                {showActionPlan(exam) && (
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="detailedAnalysis">Detailed Analysis</Label>
                                                                <Input
                                                                    type="textarea"
                                                                    name="detailedAnalysis"
                                                                    rows={10}
                                                                    value={exam.detailedAnalysis || ''}
                                                                    onChange={(e) =>
                                                                        handleAnalysisUpdate(e.target.value)
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="actionPlan">Action Plan</Label>
                                                                <Input
                                                                    type="textarea"
                                                                    name="actionPlan"
                                                                    rows={10}
                                                                    value={exam.actionPlan || ''}
                                                                    onChange={(e) =>
                                                                        handleActionPlanUpdate(e.target.value)
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </>
                                        )}
                                    </Collapse>
                                </ListGroupItem>
                            ))}
                    </ListGroup>
                </CardBody>
                {props.canEdit && (
                    <CardFooter className={'d-flex justify-content-between'}>
                        <Button
                            type={'button'}
                            className={'btn btn-warning'}
                            onClick={() => props.onNavigate(AnnualReportNavigationDirection.Back)}
                        >
                            <i className={'mdi mdi-arrow-left'} />
                            <span className={'mr-1'}>{`Previous`}</span>
                        </Button>

                        {filteredExams && (
                            <Button
                                disabled={formSubmitting}
                                type={'submit'}
                                color={'primary'}
                                onClick={() => saveOutcomes(false)}
                            >
                                <i className={'mdi mdi-content-save'} />
                                <span className={'ml-1'}>{`Save`}</span>
                            </Button>
                        )}

                        {filteredExams && (
                            <Button
                                disabled={formSubmitting}
                                style={{ zIndex: 1 }}
                                className={'btn btn-info'}
                                onClick={() => saveOutcomes(true)}
                            >
                                <span className={'mr-1'}>{`Continue`}</span>
                                <i className={'mdi mdi-arrow-right'} />
                            </Button>
                        )}
                    </CardFooter>
                )}
            </Card>
            {selectedExam && selectedResult && (
                <Modal isOpen={showModal} toggle={toggleModal} centered={true}>
                    <ModalHeader>{`${selectedExam.title} - ${selectedResult.graduatingYear} Graduates`}</ModalHeader>
                    <ModalBody>
                        <ExamForm
                            result={selectedResult}
                            totalGraduates={getGraduatesByYear(selectedResult.graduatingYear || surveyYear)}
                            onResultUpdated={handleResultSave}
                            onCancel={toggleModal}
                        />
                    </ModalBody>
                </Modal>
            )}
        </>
    );
};

export default CredentialingExams;
