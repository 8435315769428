import React from 'react';
import { Button as StrapButton } from 'reactstrap';

type Props = {
    color?: string;
    outline?: boolean;
    children: React.ReactText | JSX.Element | JSX.Element[];
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

/**
 * Button
 * @param {Props} props
 */
const Button = (props: Props) => {
    return (
        <StrapButton color={props.color ?? 'primary'} {...props}>
            {props.children}
        </StrapButton>
    );
};

export default Button;
