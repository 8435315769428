import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Folder } from '../common/Types';
import styled from 'styled-components';
import { hubService } from '../services/HubService';

interface HubBreadcrumbProps {
    folder: Folder;
    structureLastChanged: Date;
}

const BreadcrumbLink = styled(NavLink)`
    color: #6c757d;
    font-size: 18px;
    cursor: pointer;
    padding: 5px 10px;

    &:hover {
        background-color: #dddddd;
        border-radius: 8px 8px 8px 8px;
    }
`;

const ChevronIcon = styled.i`
    font-size: 28px;
`;

const HubBreadcrumb = (props: HubBreadcrumbProps) => {
    const { folder, structureLastChanged } = props;
    const [folders, setFolders] = useState<Folder[]>();
    const [path, setPath] = useState<Folder[]>();

    useEffect(() => {
        hubService.getAllFolders().then((allFolders) => {
            setFolders(allFolders);
        });
    }, [structureLastChanged, setFolders]);

    useEffect(() => {
        if (!!folders) {
            let parentList: Folder[] = [];
            let currentFolder: Folder | undefined = folder;

            do {
                parentList = [...parentList, currentFolder];

                currentFolder = folders.find((f) => f.folderId === currentFolder?.parentFolderId);
            } while (!!currentFolder);

            setPath(parentList);
        }
    }, [folder, folders, setPath]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'flex-end',
                height: '70px',
                alignItems: 'center',
                borderBottom: 'solid 1px #DDDDDD',
                padding: '0px 20px',
            }}
        >
            {!!path &&
                path.map((f, i) => (
                    <div style={{ display: 'flex', alignItems: 'center' }} key={f.folderId}>
                        <BreadcrumbLink to={f.folderSlug}>
                            <span>{f.name}</span>
                        </BreadcrumbLink>
                        {i > 0 && <ChevronIcon className={`mdi mdi-chevron-right`} />}
                    </div>
                ))}
        </div>
    );
};

export default HubBreadcrumb;
