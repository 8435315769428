import { AmsDocument, DocumentUpload } from '../common/Types';
import {
    Badge,
    ButtonGroup,
    Card,
    CardBody,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { APIUriAms } from '../services/HttpHelper';
import Button from './Button';
import Dropzone from 'react-dropzone';
import Skeleton from 'react-loading-skeleton';
import { documentService } from '../services/DocumentService';
import { institutionService } from '../services/InstitutionService';
import { programService } from '../services/ProgramService';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { AmsPermissionClaimType, authService } from '../services/Auth';

const DropZoneContainer = styled.section`
    border: dashed 5px #cccccc;
    > div {
        height: 80px;
    }
`;

interface RelatedDocumentListProps {
    institutionId?: number;
    programId?: number;
}

const RelatedDocumentsList = (props: RelatedDocumentListProps) => {
    const { institutionId, programId } = props;

    const [documents, setDocuments] = useState<AmsDocument[]>();
    const [reloadDocuments, setReloadDocuments] = useState<boolean>(true);

    const [newDocument, setNewDocument] = useState<DocumentUpload>();
    const [showNewDocumentModal, setShowNewDocumentModal] = useState<boolean>(false);

    const [removeDocument, setRemoveDocument] = useState<AmsDocument>();
    const [showRemoveDocumentModal, setShowRemoveDocumentModal] = useState<boolean>(false);

    const toggleShowNewDocumentModal = () => setShowNewDocumentModal(!showNewDocumentModal);
    const toggleShowRemoveDocumentModal = () => setShowRemoveDocumentModal(!showRemoveDocumentModal);

    useEffect(() => {
        const getDocuments = () => {
            if (institutionId && !programId && reloadDocuments) {
                setReloadDocuments(false);
                institutionService
                    .getDocuments(institutionId)
                    .then((results) => {
                        setDocuments(results);
                    })
                    .catch(() => {
                        toast.error('Error loading documents');
                    });
            }

            if (institutionId && programId && reloadDocuments) {
                setReloadDocuments(false);
                programService
                    .getAmsProgramDocuments(programId)
                    .then((results) => {
                        setDocuments(results);
                    })
                    .catch(() => {
                        toast.error('Error loading documents');
                    });
            }
        };

        getDocuments();
    }, [institutionId, programId, setDocuments, reloadDocuments]);

    const startNewDocument = () => {
        setNewDocument({
            fileUpload: null,
            dto: {
                documentId: 0,
                institutionId: institutionId || 0,
                programId: programId,
                title: '',
                active: true,
                fileUrl: '',
                isAwardLetter: false,
            } as AmsDocument,
        });
        toggleShowNewDocumentModal();
    };

    const addStagedDoc = (dropData) => {
        if (newDocument && dropData && dropData[0]) {
            dropData.fileId = uuidv4();

            setNewDocument({
                fileUpload: dropData[0],
                dto: {
                    ...newDocument.dto,
                    fileUrl: dropData[0].path as string,
                } as AmsDocument,
            });
        }
    };

    const saveNewDocument = () => {
        if (newDocument) {
            const toastId = toast.info('Saving & uploading document...');

            documentService
                .saveAndUploadRelatedDocument(newDocument)
                .then((result) => {
                    toast.update(toastId, {
                        render: 'Document uploaded and saved successfully',
                        type: 'success',
                    });

                    setReloadDocuments(true);
                    toggleShowNewDocumentModal();
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error uploading and saving document',
                        type: 'error',
                    });
                });
        }
    };

    const confirmRemoveDocument = (document: AmsDocument) => {
        setRemoveDocument(document);
        toggleShowRemoveDocumentModal();
    };

    const completeRemoveDocument = () => {
        if (removeDocument) {
            const toastId = toast.info('Removing document...');

            documentService
                .deleteDocument(removeDocument)
                .then((result) => {
                    toast.update(toastId, {
                        render: 'Document removed succesfully',
                        type: 'success',
                    });

                    setReloadDocuments(true);
                    toggleShowRemoveDocumentModal();
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error removing document',
                        type: 'error',
                    });
                });
        }
    };

    return (
        <>
            {(institutionId || programId) && (
                <Card className={'d-flex flex-grow-1'}>
                    <CardBody className={`pb-0`}>
                        <h4 className={'card-title d-flex align-items-center justify-content-between'}>
                            <span>{`Related Documents`}</span>
                            {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') && (
                                <div>
                                    <Button type={'button'} color={'primary'} onClick={() => startNewDocument()}>
                                        <i className={`mdi mdi-plus`} />
                                        <span className={`ml-1`}>{`Add a Document`}</span>
                                    </Button>
                                </div>
                            )}
                        </h4>
                    </CardBody>
                    <div className={'table-responsive'} style={{ maxHeight: '350px', overflow: 'auto' }}>
                        <table className={`table mb-0`}>
                            <thead>
                                <tr>
                                    <td>{`Date Created`}</td>
                                    <th>{`Title`}</th>
                                    <th>{`File Name`}</th>
                                    <th>{``}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!documents && (
                                    <tr>
                                        <td colSpan={3}>
                                            <Skeleton count={5} />
                                        </td>
                                    </tr>
                                )}
                                {documents &&
                                    documents.length > 0 &&
                                    documents
                                        .sort(
                                            (a, b) =>
                                                moment.utc(a.dateCreated || new Date()).unix() -
                                                moment.utc(b.dateCreated || new Date()).unix(),
                                        )
                                        .map((d) => (
                                            <tr key={d.documentId}>
                                                <td>
                                                    {d.dateCreated && (
                                                        <span>
                                                            {moment
                                                                .utc(d.dateCreated)
                                                                .local()
                                                                .format(`MM/DD/YYYY hh:mm A`)}
                                                        </span>
                                                    )}
                                                    {!d.dateCreated && <span>{`Unknown`}</span>}
                                                </td>
                                                <td>
                                                    <span>{d.title}</span>
                                                    <span>{d.isAwardLetter}</span>
                                                    {d.isAwardLetter === true && (
                                                        <Badge
                                                            color={'primary'}
                                                            className={'ml-2'}
                                                        >{`award letter`}</Badge>
                                                    )}
                                                </td>
                                                <td>{d.fileDownloadName}</td>
                                                <td className={'text-right'}>
                                                    <ButtonGroup>
                                                        <a
                                                            className={'btn btn-success btn-sm'}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            href={`${APIUriAms}/document/download/${d.fileUrl}`}
                                                        >
                                                            <i className={`mdi mdi-download`} />
                                                        </a>
                                                        {authService.hasPermission(
                                                            AmsPermissionClaimType,
                                                            'systemadmin',
                                                        ) && (
                                                            <Button
                                                                color={'danger'}
                                                                className={'btn-sm'}
                                                                onClick={() => confirmRemoveDocument(d)}
                                                            >
                                                                <i className={`mdi mdi-trash-can-outline`} />
                                                            </Button>
                                                        )}
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        ))}
                                {documents && documents.length === 0 && (
                                    <tr>
                                        <td colSpan={3}>
                                            <label>{`No Documents to Display`}</label>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </Card>
            )}

            <Modal isOpen={showNewDocumentModal} toggle={toggleShowNewDocumentModal} className={`modal-lg`}>
                <ModalHeader>{`New Related Document`}</ModalHeader>
                {newDocument && (
                    <ModalBody>
                        <FormGroup>
                            <Label>{`Document Title`}</Label>
                            <Input
                                type={'text'}
                                onChange={(e) =>
                                    setNewDocument({
                                        ...newDocument,
                                        dto: { ...newDocument.dto, title: e.target.value } as AmsDocument,
                                    })
                                }
                            />
                        </FormGroup>
                        <FormGroup className={'d-flex flex-column'}>
                            <Label>{`File Upload`}</Label>
                            {newDocument?.dto?.fileUrl && <span className={'mb-2'}>{newDocument.dto.fileUrl}</span>}
                            <Dropzone onDrop={(acceptedFiles) => addStagedDoc(acceptedFiles)} multiple={false}>
                                {({ getRootProps, getInputProps }) => (
                                    <DropZoneContainer>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <p style={{ textAlign: 'center', lineHeight: '80px' }}>
                                                {`Drag & drop a file here, or click to select a file`}
                                            </p>
                                        </div>
                                    </DropZoneContainer>
                                )}
                            </Dropzone>
                        </FormGroup>
                    </ModalBody>
                )}
                <ModalFooter>
                    <Button
                        color={'primary'}
                        className={'mr-2'}
                        onClick={() => saveNewDocument()}
                        type={'button'}
                    >{`Upload & Save`}</Button>
                    <Button
                        color={'link'}
                        onClick={() => toggleShowNewDocumentModal()}
                        type={'button'}
                    >{`Cancel`}</Button>
                </ModalFooter>
            </Modal>

            {removeDocument && (
                <Modal isOpen={showRemoveDocumentModal} toggle={toggleShowRemoveDocumentModal} centered={true}>
                    <ModalHeader>{`Confirm Remove Related Document`}</ModalHeader>
                    <ModalBody>
                        <p>
                            Please confirm the removal of{' '}
                            <span className={'font-weight-bold'}>
                                {removeDocument.title || removeDocument.fileDownloadName}
                            </span>{' '}
                            by clicking the Confirm button below.
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color={'primary'}
                            className={'mr-2'}
                            onClick={() => completeRemoveDocument()}
                            type={'button'}
                        >{`Confirm`}</Button>
                        <Button
                            color={'link'}
                            onClick={() => toggleShowRemoveDocumentModal()}
                            type={'button'}
                        >{`Cancel`}</Button>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
};

export default RelatedDocumentsList;
