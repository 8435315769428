import { Button, Input } from '../../components';
import { Card, CardBody, CardFooter, CardTitle, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import Skeleton from 'react-loading-skeleton';
import { TabSetting } from '../../common/Types';
import { professionService } from '../../services/ProfessionAdministration';
import { toast } from 'react-toastify';

const ResourceTab = (props) => {
    const [resources, setResources] = useState<TabSetting[]>();
    const [editing, setEditing] = useState(false);
    const { control, handleSubmit, register, reset, setValue, getValues } = useForm();
    const { fields, append } = useFieldArray({
        control,
        name: 'resources',
    });

    const remove = (i) => {
        setValue(`resources[${i}]`, { isActive: false });
        reset(getValues({ nest: true }));
    };

    const toggleEdit = () => {
        setEditing(!editing);
        reset({ resources: resources });
    };

    const onSubmit = (formData) => {
        const toastId = toast.info('Saving information...');

        professionService
            .saveResources(
                props.coa.professionId,
                formData.resources.map((exp) => new TabSetting(exp)),
            )
            .then((response) => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Information Saved.',
                });
                toggleEdit();
                setResources(response);
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Failed to save information.',
                });
            });
    };

    useEffect(() => {
        const getResources = () => {
            professionService
                .getResources(props.coa.professionId)
                .then((response) => {
                    append(response);
                    setResources(response);
                })
                .catch(() => {
                    toast.error('Unable to get information.');
                });
        };

        getResources();
    }, [append, props.coa]);

    return (
        <>
            <Col md={6}>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h3>Resources Tab</h3>
                        </CardTitle>
                        {editing ? (
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup>
                                    <Label>Resources:</Label>
                                    {fields.map((expenditure, i) =>
                                        JSON.parse(expenditure.isActive) ? (
                                            <Card key={i}>
                                                <CardBody>
                                                    <div>
                                                        <FormGroup>
                                                            <Input
                                                                defaultValue={expenditure.title}
                                                                name={`resources[${i}].title`}
                                                                innerRef={register()}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <div className={'custom-control custom-checkbox'}>
                                                                <Input
                                                                    type="checkbox"
                                                                    id={`resources[${i}].requireDetail`}
                                                                    name={`resources[${i}].requireDetail`}
                                                                    innerRef={register()}
                                                                    defaultChecked={JSON.parse(
                                                                        expenditure.requireDetail,
                                                                    )}
                                                                    className={'custom-control-input'}
                                                                />
                                                                <Label
                                                                    for={`resources[${i}].requireDetail`}
                                                                    className={'custom-control-label'}
                                                                >
                                                                    Require detail of changes to resources?
                                                                </Label>
                                                            </div>
                                                        </FormGroup>
                                                        <input
                                                            type="hidden"
                                                            name={`resources[${i}].professionId`}
                                                            defaultValue={expenditure.professionId}
                                                            ref={register()}
                                                        />
                                                        <input
                                                            type="hidden"
                                                            name={`resources[${i}].isActive`}
                                                            defaultValue={expenditure.isActive}
                                                            ref={register()}
                                                        />
                                                        <input
                                                            type="hidden"
                                                            name={`resources[${i}].customId`}
                                                            defaultValue={expenditure.customId}
                                                            ref={register()}
                                                        />
                                                    </div>
                                                </CardBody>
                                                <CardFooter className={'p-0'}>
                                                    <Button
                                                        className="remove"
                                                        color={'info'}
                                                        onClick={() => remove(i)}
                                                        type={'button'}
                                                    >
                                                        <i className={'mdi mdi-close'} />
                                                        <span className={'ml-1'}>Remove</span>
                                                    </Button>
                                                </CardFooter>
                                            </Card>
                                        ) : (
                                            <div key={i}>
                                                <input
                                                    type="hidden"
                                                    name={`resources[${i}].title`}
                                                    defaultValue={expenditure.title}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`resources[${i}].professionId`}
                                                    defaultValue={expenditure.professionId}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`resources[${i}].isActive`}
                                                    defaultValue={expenditure.isActive}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`resources[${i}].customId`}
                                                    defaultValue={expenditure.customId}
                                                    ref={register()}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`resources[${i}].requireDetail`}
                                                    defaultValue={expenditure.requireDetail}
                                                    ref={register()}
                                                />
                                            </div>
                                        ),
                                    )}
                                    <div>
                                        <Button
                                            className="add"
                                            color={'info'}
                                            onClick={() =>
                                                append({
                                                    professionId: props.coa.professionId,
                                                    customId: 0,
                                                    isActive: true,
                                                    title: '',
                                                    requireDetail: false,
                                                })
                                            }
                                        >
                                            <i className="remixicon-add-line" />
                                            <span className="ml-1">Add Resource</span>
                                        </Button>
                                    </div>
                                </FormGroup>
                                <Row className="mt-3">
                                    <Col>
                                        <Button type="submit">Save</Button>&nbsp;
                                        <Button outline onClick={() => toggleEdit()}>
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        ) : (
                            <>
                                <Label>Resources:</Label>
                                {resources ? (
                                    <>
                                        {resources.map((resource, i) => {
                                            return (
                                                <Row key={i}>
                                                    <Col className="resource-title">{resource.title}</Col>
                                                </Row>
                                            );
                                        })}
                                        <Row className="mt-3">
                                            <Col>
                                                <Button onClick={() => toggleEdit()}>Edit</Button>
                                            </Col>
                                        </Row>
                                    </>
                                ) : (
                                    <Skeleton count={5} />
                                )}
                            </>
                        )}
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default ResourceTab;
