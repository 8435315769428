import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useLocation } from 'react-router-dom';

const InvoicePaymentThankYou = () => {
    const search = useLocation().search;
    const receiptUrl = new URLSearchParams(search).get('receipt_url');

    return (
        <>
            <Row className="justify-content-center">
                <Col>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col>
                                    <img
                                        style={{ width: `100%`, maxWidth: `400px` }}
                                        src="https://uploads-ssl.webflow.com/5f466098a462432df91fef63/5fa3462933aa311a701e3196_CAAHEP_Full_LBlue.gif"
                                    />
                                </Col>
                            </Row>
                            <Row className={`mt-3 mb-3`}>
                                <Col>
                                    <h1>Thank You!</h1>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>{`
                                        Your payment has been successfully processed. If you have any questions concerning this 
                                        payment or invoice, contact CAAHEP at (727) 210-2350 ext.102, or email receivables@CAAHEP.org.
                                    `}</p>
                                    <b>
                                        <a href={receiptUrl?.toString().replace(/['"]+/g, '')}>Payment Receipt</a>
                                    </b>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default InvoicePaymentThankYou;
