import { AnnualReportNavigationDirection, AnnualReportProps } from '../AnnualReport';
import {
    Badge,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    Col,
    Form,
    FormGroup,
    Label,
    ListGroupItem,
    Row,
} from 'reactstrap';
import { Collapse, Input } from '../../../components';
import { DocumentUpload, RelatedDocumentDto } from '../../../common/Types';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import { ApiUriArms } from '../../../services/HttpHelper';
import Dropzone from 'react-dropzone';
import { professionService } from '../../../services/ProfessionAdministration';
import { programService } from '../../../services/ProgramService';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

const DropZoneContainer = styled.section`
    border: dashed 5px #cccccc;
    > div {
        height: 80px;
    }
`;

const RelatedDocuments = (props: AnnualReportProps) => {
    const [newDocuments, setNewDocuments] = useState<DocumentUpload[]>([]);
    const [uploadedDocuments, setUploadedDocuments] = useState<RelatedDocumentDto[]>();
    const [reloadDocuments, setReloadDocuments] = useState<boolean>(true);
    const [categories, setCategories] = useState<any[]>();
    const [selectedDoc, setSelectedDoc] = useState<RelatedDocumentDto>();
    const [acknowledged, setAcknowledged] = useState(props.hasTabAlreadyBeenSubmitted);
    const {
        handleSubmit: handleEditSubmit,
        register: registerEdit,
        setValue: setEditValue,
        getValues: getEditValues,
        reset: resetEdit,
        formState: { isSubmitting: isEditSubmitting },
    } = useForm();
    const {
        control,
        handleSubmit,
        register,
        formState: { isSubmitting },
    } = useForm();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'docs',
    });

    const addStagedDoc = (dropData) => {
        if (dropData && Array.isArray(dropData)) {
            const docs: DocumentUpload[] = [];
            dropData.forEach((file, i) => {
                file.fileId = uuidv4();

                const relDocDto = new RelatedDocumentDto({
                    documentId: 0,
                    activeYear: props.reportYear,
                    programId: props.program.programId,
                    fileUrl: file.path,
                    isDeleted: false,
                    isAwardLetter: false,
                });

                append(relDocDto);

                const docUpload = new DocumentUpload({
                    fileUpload: file,
                    dto: relDocDto,
                });
                docs.push(docUpload);
            });

            setNewDocuments([...newDocuments, ...docs]);
        }
    };

    const uploadDocs = (docs): Promise<void> => {
        return new Promise((res) => {
            if (!docs) res();

            docs.forEach((doc) => {
                const toastId = toast.info(`Saving ${doc.dto?.fileUrl}...`);
                programService
                    .uploadProgramDocument(doc)
                    .then((response) => {
                        toast.update(toastId, {
                            type: 'success',
                            render: 'Document saved.',
                        });
                        const excludeExists = uploadedDocuments?.filter((x) => x.documentId !== response.documentId);

                        // if (!response.isDeleted) {
                        //     setUploadedDocuments([...(excludeExists ?? []), response]);
                        // } else {
                        //     setUploadedDocuments([...(excludeExists ?? [])]);
                        // }

                        setNewDocuments([]);
                        setSelectedDoc(undefined);
                        setAcknowledged(false);
                    })
                    .catch(() => {
                        toast.update(toastId, {
                            type: 'error',
                            render: `Failed to upload ${doc.dto?.fileUrl}.`,
                        });
                    })
                    .finally(() => {
                        res();
                    });
            });
        });
    };

    const onEditSubmit = async (formData): Promise<void> => {
        await uploadDocs([{ dto: new RelatedDocumentDto(formData) }]);
        setReloadDocuments(true);
        return;
    };

    const onSubmit = async (formData): Promise<void> => {
        const newDocs = newDocuments.map((doc) => {
            const inputVals = formData.docs.find((x) => x.fileUrl === doc.dto?.fileUrl);
            return {
                ...doc,
                dto: {
                    ...doc.dto,
                    title: inputVals.title,
                    categoryId: +inputVals.categoryId,
                },
            } as DocumentUpload;
        });
        setNewDocuments(newDocs);
        await uploadDocs(newDocs);
        setReloadDocuments(true);
        return;
    };

    // Get already uploaded documents
    useEffect(() => {
        const getDocs = () => {
            if (reloadDocuments) {
                setReloadDocuments(false);
                programService
                    .getProgramDocuments(props.program.programId, props.reportYear ?? new Date().getFullYear())
                    .then((response) => {
                        setUploadedDocuments(response);
                    })
                    .catch(() => {
                        toast.error('Error loading documents.');
                    });
            }
        };

        getDocs();
    }, [setUploadedDocuments, props.program.programId, props.reportYear, reloadDocuments]);

    // Get document categories
    useEffect(() => {
        const getDocumentCategories = () => {
            professionService
                .getDocumentCategories(props.program.professionId)
                .then((response) => {
                    setCategories(response);
                })
                .catch(() => {
                    toast.error('Error loading document categories.');
                });
        };

        getDocumentCategories();
    }, [setCategories, props.program.professionId]);

    // Remove staged document
    const removeDoc = (doc, index) => {
        setNewDocuments([...(newDocuments.filter((x) => x.dto?.fileUrl !== doc.fileUrl) ?? [])]);
        remove(index);
    };

    // Remove uploaded document
    const removeUploadedDoc = (doc) => {
        setEditValue('isDeleted', true);
        doc.isDeleted = true;
        setUploadedDocuments([...(uploadedDocuments?.filter((x) => x.documentId !== doc.documentId) ?? []), doc]);
    };

    return (
        <Card>
            <CardBody>
                <h3 className="card-title">Related Documents</h3>
                {props.canEdit && (
                    <Dropzone onDrop={(acceptedFiles) => addStagedDoc(acceptedFiles)}>
                        {({ getRootProps, getInputProps }) => (
                            <DropZoneContainer>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p style={{ textAlign: 'center', lineHeight: '80px' }}>
                                        Drag &apos;n&apos; drop some files here, or click to select files
                                    </p>
                                </div>
                            </DropZoneContainer>
                        )}
                    </Dropzone>
                )}
                <Form onSubmit={handleSubmit(onSubmit)}>
                    {newDocuments.length > 0 && (
                        <div className="mt-3">
                            <h4 className="mb-3">Staged Documents for {props.reportYear}</h4>
                            {fields.map((doc, i) => (
                                <ListGroupItem style={{ display: 'flex' }} key={i}>
                                    <FormGroup style={{ paddingRight: '8px' }}>
                                        <Label>Title</Label>
                                        <Input
                                            style={{ width: '250px' }}
                                            name={`docs[${i}].title`}
                                            innerRef={register()}
                                        />
                                    </FormGroup>
                                    <FormGroup style={{ paddingRight: '8px', flex: 1 }}>
                                        <Label>Category</Label>
                                        <Input
                                            type="select"
                                            name={`docs[${i}].categoryId`}
                                            style={{ width: '100%' }}
                                            innerRef={register()}
                                        >
                                            {categories?.map((cat, cati) => (
                                                <option
                                                    key={cati}
                                                    value={cat.categoryId}
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    {cat.title}
                                                </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                    <FormGroup style={{ paddingRight: '8px', flex: 1 }}>
                                        <Label>File Name</Label>
                                        <div style={{ lineHeight: '40px' }}>{doc.fileUrl}</div>
                                        <input
                                            type="hidden"
                                            name={`docs[${i}].fileUrl`}
                                            defaultValue={doc.fileUrl}
                                            ref={register()}
                                        />
                                    </FormGroup>
                                    <div className="text-right">
                                        <ButtonGroup>
                                            <Button color="danger" onClick={() => removeDoc(doc, i)}>
                                                <i className={'remixicon-delete-bin-line'}></i>
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </ListGroupItem>
                            ))}
                            {props.canEdit && (
                                <Row className="mt-3">
                                    <Col>
                                        <Button disabled={isSubmitting} color="primary" type="submit">
                                            Upload File(s)
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    )}
                </Form>
                <Form onSubmit={handleEditSubmit(onEditSubmit)}>
                    <div className="mt-3">
                        <h4 className="mb-3">Uploaded Documents for {props.reportYear}</h4>

                        {uploadedDocuments &&
                            uploadedDocuments
                                .sort((docA, docB) => {
                                    const dateA = new Date(docA.dateCreated ?? '');
                                    const dateB = new Date(docB.dateCreated ?? '');
                                    return (dateB as any) - (dateA as any);
                                })
                                .map((doc, i) => {
                                    return (
                                        <ListGroupItem key={doc.documentId ?? i}>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '50px' }}>
                                                    {doc.fileUrl && (
                                                        <a href={`${ApiUriArms}/document/download/${doc.fileUrl}`}>
                                                            <i
                                                                style={{ fontSize: '32px' }}
                                                                className={'remixicon-file-text-line'}
                                                            />
                                                        </a>
                                                    )}
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                                    <div style={{ flex: 1, display: 'flex' }}>
                                                        <h5>
                                                            {doc.title} <Badge>{doc.category?.title}</Badge>
                                                        </h5>
                                                        <div style={{ flex: 1 }} className="text-right">
                                                            <ButtonGroup>
                                                                {doc.documentId === selectedDoc?.documentId ? (
                                                                    JSON.parse(doc.isDeleted?.toString() ?? 'false') ? (
                                                                        <span className="text-danger">
                                                                            Click Save to delete
                                                                        </span>
                                                                    ) : (
                                                                        <Button
                                                                            color="danger"
                                                                            onClick={() => removeUploadedDoc(doc)}
                                                                        >
                                                                            <i
                                                                                className={'remixicon-delete-bin-line'}
                                                                            ></i>
                                                                        </Button>
                                                                    )
                                                                ) : (
                                                                    <Button
                                                                        color="info"
                                                                        onClick={() => setSelectedDoc(doc)}
                                                                    >
                                                                        <i className={'remixicon-pencil-line'}></i>
                                                                    </Button>
                                                                )}
                                                            </ButtonGroup>
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: 1, display: 'flex' }}>
                                                        <div style={{ flex: 1 }}>
                                                            {doc.fileUrl && (
                                                                <a
                                                                    href={`${ApiUriArms}/document/download/${doc.fileUrl}`}
                                                                >
                                                                    {doc.fileDownloadName}
                                                                </a>
                                                            )}
                                                        </div>
                                                        <div className="text-right">
                                                            Last Edited:{' '}
                                                            {doc.lastEdited
                                                                ? new Date(doc.lastEdited).toDateString()
                                                                : 'NA'}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Collapse isOpen={doc.documentId === selectedDoc?.documentId}>
                                                {doc.documentId === selectedDoc?.documentId && (
                                                    <div style={{ display: 'flex' }}>
                                                        <FormGroup style={{ paddingRight: '8px' }}>
                                                            <Label>Title</Label>
                                                            <Input
                                                                style={{ width: '250px' }}
                                                                name={`title`}
                                                                innerRef={registerEdit()}
                                                                defaultValue={doc.title}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup style={{ paddingRight: '8px' }}>
                                                            <Label>Category</Label>
                                                            <Input
                                                                type="select"
                                                                name={`categoryId`}
                                                                style={{ width: '100%' }}
                                                                innerRef={registerEdit()}
                                                                defaultValue={doc.categoryId}
                                                            >
                                                                {categories?.map((cat, cati) => (
                                                                    <option
                                                                        key={cati}
                                                                        value={cat.categoryId}
                                                                        style={{ whiteSpace: 'nowrap' }}
                                                                    >
                                                                        {cat.title}
                                                                    </option>
                                                                ))}
                                                            </Input>
                                                        </FormGroup>
                                                        <input
                                                            type="hidden"
                                                            name={`documentId`}
                                                            defaultValue={doc?.documentId ?? undefined}
                                                            ref={registerEdit()}
                                                        />
                                                        <input
                                                            type="hidden"
                                                            name={`activeYear`}
                                                            defaultValue={doc?.activeYear ?? undefined}
                                                            ref={registerEdit()}
                                                        />
                                                        <input
                                                            type="hidden"
                                                            name={`programId`}
                                                            defaultValue={doc?.programId ?? undefined}
                                                            ref={registerEdit()}
                                                        />
                                                        <input
                                                            type="hidden"
                                                            name={`fileUrl`}
                                                            defaultValue={doc?.fileUrl ?? undefined}
                                                            ref={registerEdit()}
                                                        />
                                                        <input
                                                            type="hidden"
                                                            name={`createdBy`}
                                                            defaultValue={doc?.createdBy ?? undefined}
                                                            ref={registerEdit()}
                                                        />
                                                        <input
                                                            type="hidden"
                                                            name={`dateCreated`}
                                                            defaultValue={doc?.dateCreated?.toString() ?? undefined}
                                                            ref={registerEdit()}
                                                        />
                                                        <input
                                                            type="hidden"
                                                            name={`lastEdited`}
                                                            defaultValue={doc?.lastEdited?.toString() ?? undefined}
                                                            ref={registerEdit()}
                                                        />
                                                        <input
                                                            type="hidden"
                                                            name={`editedBy`}
                                                            defaultValue={doc?.editedBy ?? undefined}
                                                            ref={registerEdit()}
                                                        />
                                                        <input
                                                            type="hidden"
                                                            name={`isDeleted`}
                                                            defaultValue={doc?.isDeleted?.toString() ?? undefined}
                                                            ref={registerEdit()}
                                                        />
                                                        <input
                                                            type="hidden"
                                                            name={`isAwardLetter`}
                                                            defaultValue={doc?.isAwardLetter?.toString() ?? undefined}
                                                            ref={registerEdit()}
                                                        />
                                                        <input
                                                            type="hidden"
                                                            name={`createdByString`}
                                                            defaultValue={doc?.createdByString ?? undefined}
                                                            ref={registerEdit()}
                                                        />
                                                        <input
                                                            type="hidden"
                                                            name={`editedByString`}
                                                            defaultValue={doc?.editedByString ?? undefined}
                                                            ref={registerEdit()}
                                                        />
                                                    </div>
                                                )}
                                                {props.canEdit && (
                                                    <Row>
                                                        <Col>
                                                            <Button
                                                                disabled={isEditSubmitting}
                                                                color="primary"
                                                                type="submit"
                                                            >
                                                                Save
                                                            </Button>
                                                            &nbsp;
                                                            <Button
                                                                onClick={() => {
                                                                    setSelectedDoc(undefined);
                                                                    doc.isDeleted = false;
                                                                    resetEdit();
                                                                }}
                                                                color="secondary"
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </Collapse>
                                        </ListGroupItem>
                                    );
                                })}
                    </div>
                </Form>
                <Card className={'mt-3'}>
                    <CardBody>
                        <h5 className={'card-title'}>{`Acknowledgment`}</h5>
                        <FormGroup>
                            <div className={'custom-control custom-checkbox'}>
                                <Input
                                    type="checkbox"
                                    checked={acknowledged}
                                    id={'acknowledgment'}
                                    onChange={() => setAcknowledged(!acknowledged)}
                                    className={'custom-control-input'}
                                />
                                {(uploadedDocuments?.length || 0) > 0 && (
                                    <Label
                                        for={`acknowledgment`}
                                        className={'custom-control-label'}
                                    >{`I have uploaded all required and appropriate documents.`}</Label>
                                )}
                                {(uploadedDocuments?.length || 0) === 0 && (
                                    <Label
                                        for={`acknowledgment`}
                                        className={'custom-control-label'}
                                    >{`I have no documents to upload.`}</Label>
                                )}
                            </div>
                        </FormGroup>
                    </CardBody>
                </Card>
            </CardBody>
            {props.canEdit && (
                <CardFooter className={'d-flex justify-content-between'}>
                    <Button
                        type={'button'}
                        className={'btn btn-warning'}
                        onClick={() => props.onNavigate(AnnualReportNavigationDirection.Back)}
                    >
                        <i className={'mdi mdi-arrow-left'} />
                        <span className={'mr-1'}>{`Previous`}</span>
                    </Button>

                    {acknowledged && (
                        <Button
                            style={{ zIndex: 1 }}
                            className={'btn btn-info'}
                            disabled={!acknowledged}
                            onClick={() => props.onSavedSuccessfully(AnnualReportNavigationDirection.Forward)}
                        >
                            <span className={'mr-1'}>{`Continue`}</span>
                            <i className={'mdi mdi-arrow-right'} />
                        </Button>
                    )}
                    {!acknowledged && (
                        <Label className={'text-danger'}>
                            {`Please check the acknowledgement checkbox above to continue.`}
                        </Label>
                    )}
                </CardFooter>
            )}
        </Card>
    );
};

export default RelatedDocuments;
