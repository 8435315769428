import React, { useEffect } from 'react';
import * as yup from 'yup';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useFieldArray, useForm } from 'react-hook-form';
import { Form, FormGroup, Label, Input, Table, InputGroupAddon, InputGroup, InputGroupText } from 'reactstrap';
import { InvoiceGenerationSuccessFailDto, InvoicingDto } from '../../types';
import { Button } from '../../components';
import { invoiceService } from '../../services/InvoiceService';
import { generateYears } from '../../common/Utils';

const GenerateAnnualInvoicesForm = () => {
    const schema: yup.ObjectSchema<InvoicingDto> = yup
        .object({
            id: yup.number().required().default(0),
            institutionId: yup.number().required().default(0),
            institutionTitle: yup.string().notRequired(),
            status: yup.number().required().default(3),
            fiscalYear: yup.string().notRequired(),
            description: yup.string().notRequired(),
            amount: yup.number().notRequired().nullable(),
            tiers: yup
                .array()
                .of(
                    yup
                        .object({
                            numProfessions: yup.number().required().min(1),
                            perProfessionFee: yup.number().required().min(0),
                        })
                        .defined(),
                )
                .required(),
            invoiceDate: yup.string().required(),
            automaticallySendOn: yup.string().notRequired(),
            sendAutomatically: yup.boolean().notRequired(),
            createDate: yup.string().notRequired(),
            lastUpdatedDate: yup.string().nullable(),
            rowVersion: yup.string().notRequired(),
            invoiceNumber: yup.string().notRequired(),
            lateFee: yup.number().notRequired().min(0),
            convenienceFee: yup.number().notRequired().min(0),
            dueAmount: yup.number().notRequired(),
            shortUrl: yup.string().notRequired(),
            programIds: yup.string().notRequired(),
            programTitles: yup.string().notRequired(),
            isDeleted: yup.boolean().required().default(false),
            invoiceType: yup.number().required().default(1),
            stripeInvoiceId: yup.string().notRequired(),
            recipientId: yup.number().nullable().notRequired(),
            stripeInvoiceUrl: yup.string().nullable().notRequired(),
            pdfUrl: yup.string().nullable().notRequired(),
            dueDate: yup.string().nullable().notRequired(),
        })
        .defined();

    const { handleSubmit, register, errors, control, watch, reset, setValue } = useForm({
        validationSchema: schema,
        defaultValues: {
            ...({} as InvoicingDto),
            description: 'CAAHEP Accredited Program Fee',
            invoiceDate: moment([new Date().getFullYear(), 5, 1]).format('YYYY-MM-DD'),
            lateFee: 0,
            convenienceFee: 0,
            tiers: [
                {
                    numProfessions: 1,
                    perProfessionFee: 600,
                },
                {
                    numProfessions: 2,
                    perProfessionFee: 500,
                },
                {
                    numProfessions: 3,
                    perProfessionFee: 350,
                },
                {
                    numProfessions: 4,
                    perProfessionFee: 250,
                },
                {
                    numProfessions: 5,
                    perProfessionFee: 200,
                },
            ],
        },
    });
    const { fields, append, remove } = useFieldArray({
        name: 'tiers',
        control,
    });
    const formValues = watch({ nest: true });

    useEffect(() => {
        if (!!formValues?.fiscalYear && +formValues?.fiscalYear > 0) {
            if (!!formValues?.invoiceDate) {
                const existingInvoiceDate = moment(formValues.invoiceDate);

                if (existingInvoiceDate.year() !== +formValues.fiscalYear) {
                    setValue(
                        'invoiceDate',
                        moment([
                            +formValues.fiscalYear,
                            existingInvoiceDate.month(),
                            existingInvoiceDate.date(),
                        ]).format('YYYY-MM-DD'),
                    );
                }
            } else {
                setValue('invoiceDate', moment([+formValues.fiscalYear, 5, 1]).format('YYYY-MM-DD'));
            }
        }
    }, [formValues?.fiscalYear, formValues?.invoiceDate]);

    const onSubmit = (values: InvoicingDto) => {
        const toastId = toast.info('Generating invoices...', {
            closeOnClick: false,
            draggable: false,
            autoClose: false,
        });

        invoiceService
            .generateAnnualInvoices(values)
            .then((response: InvoiceGenerationSuccessFailDto) => {
                if (response.failedInvoices === 0) {
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Invoices generated',
                    });
                } else if (response.failedInvoices > 0 && response.successfulInvoices > 0) {
                    toast.update(toastId, {
                        type: 'warning',
                        render: `${response.successfulInvoices} were successfully generated and ${response.failedInvoices} failed to generate.`,
                    });
                } else {
                    toast.update(toastId, {
                        type: 'error',
                        render: `No invoices were successfully generated and ${response.failedInvoices} failed to generate.`,
                    });
                }
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Error generating invoices',
                });
            });
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            {console.log(errors)}
            <FormGroup>
                <Label for="fiscalYear">Fiscal Year</Label>
                <Input type="select" innerRef={register()} name="fiscalYear">
                    <option value={''}>Select...</option>
                    {generateYears().map((ct) => (
                        <option key={ct} value={ct}>
                            {ct}
                        </option>
                    ))}
                </Input>
            </FormGroup>
            <FormGroup>
                <Label for="invoiceDate">Invoice Date</Label>
                <Input name="invoiceDate" innerRef={register()} type="date" />
            </FormGroup>
            <FormGroup>
                <Label for="description">Description</Label>
                <Input name="description" innerRef={register()} />
            </FormGroup>
            <FormGroup>
                <Label for="amount">Accredited Program Fee Structure</Label>
                <div className={`col-lg-6`}>
                    <Table>
                        <thead>
                            <tr>
                                <th>{`# Programs at Institution`}</th>
                                <th>{`Per Program Fee`}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {fields.map((f, i) => (
                                <tr key={f.id}>
                                    <td>{f.numProfessions}</td>
                                    <td>
                                        <InputGroup>
                                            <InputGroupText>$</InputGroupText>
                                            <Input name={`tiers.${i}.perProfessionFee`} innerRef={register()} />
                                            <Input
                                                name={`tiers.${i}.numProfessions`}
                                                type={`hidden`}
                                                innerRef={register()}
                                            />
                                        </InputGroup>
                                    </td>
                                    <td>
                                        <Button
                                            color={`danger`}
                                            type={'button'}
                                            onClick={() => {
                                                const oldTiers = [...formValues.tiers];
                                                oldTiers.splice(i, 1);
                                                reset({
                                                    ...formValues,
                                                    tiers: oldTiers.map((t, i) => {
                                                        return {
                                                            numProfessions: i + 1,
                                                            perProfessionFee: t.perProfessionFee,
                                                        };
                                                    }),
                                                });
                                            }}
                                        >
                                            <i className={`mdi mdi-close`} />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan={3}>
                                    <Button
                                        type={'button'}
                                        color={`primary`}
                                        onClick={() =>
                                            append({
                                                numProfessions: fields.length + 1,
                                                perProfessionFee: 0,
                                            })
                                        }
                                    >
                                        <i className={`mdi mdi-plus`} />
                                        <span className={`ml-2`}>{`Add a Fee Tier`}</span>
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </FormGroup>

            <div>
                <Button type="submit">Save</Button>
            </div>
        </Form>
    );
};

export default GenerateAnnualInvoicesForm;
