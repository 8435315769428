import React, { useEffect, useState } from 'react';
import { NavLink, Redirect, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Folder, HubDocument } from '../common/Types';
import { hubService } from '../services/HubService';
import HubBreadcrumb from './HubBreadcrumb';
import styled from 'styled-components';
import { FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Button, PageTitle } from '.';
import { AmsPermissionClaimType, authService } from '../services/Auth';
import Dropzone from 'react-dropzone';
import HubDocumentUpload from './HubDocumentUpload';
import HubDocumentItem from './HubDocumentItem';

interface HubExplorerProps {
    onStructureChanged: () => void;
}

const FolderItem = styled.div`
    border: 1px solid #dddddd;
    border-radius: 8px;
    padding: 10px 20px;
    margin: 10px 20px 10px 0px;
    flex-basis: 300px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: #dddddd;
    }

    & i {
        font-size: 32px;
        margin-right: 20px;
    }

    & span {
        font-weight: 500;
    }
`;

const FolderItemNav = styled(NavLink)`
    border: 1px solid #dddddd;
    border-radius: 8px;
    padding: 10px 20px;
    margin: 10px 20px 10px 0px;
    flex-basis: 300px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #6c757d;

    &:hover {
        background-color: #dddddd;
    }

    & i {
        font-size: 32px;
        margin-right: 20px;
    }

    & span {
        font-weight: 500;
    }
`;

const DropZoneContainer = styled.section`
    border: dashed 5px #cccccc;
    > div {
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const HubExplorer = (props: HubExplorerProps) => {
    const canManageStructure = authService.hasPermission(AmsPermissionClaimType, 'hubmanagestructure');
    const { onStructureChanged } = props;

    const { folderSlug } = useParams<{ folderSlug: string }>();
    const [folder, setFolder] = useState<Folder>();
    const [reload, setReload] = useState<boolean>(true);
    const [structureLastChanged, setStructureLastChanged] = useState<Date>(new Date());

    const [newFolderName, setNewFolderName] = useState<string>();

    const [showNewFolder, setShowNewFolder] = useState<boolean>(false);
    const toggleShowNewFolder = () => setShowNewFolder(!showNewFolder);

    const [showEditFolder, setShowEditFolder] = useState<boolean>(false);
    const toggleShowEditFolder = () => setShowEditFolder(!showEditFolder);

    const [showRemoveFolder, setShowRemoveFolder] = useState<boolean>(false);
    const toggleShowRemoveFolder = () => setShowRemoveFolder(!showRemoveFolder);

    const [showUpload, setShowUpload] = useState<boolean>(false);
    const toggleShowUpload = () => setShowUpload(!showUpload);

    const [stagedFiles, setStagedFiled] = useState<File[]>([]);
    const [sasToken, setSasToken] = useState<string>();

    useEffect(() => {
        if (folderSlug !== folder?.folderSlug) {
            setReload(true);
        }
    }, [folderSlug, folder, setReload]);

    useEffect(() => {
        if (!!folderSlug && reload) {
            setReload(false);
            hubService
                .getFolderBySlug(folderSlug)
                .then((folder) => {
                    setFolder(folder);
                })
                .catch((err) => {
                    toast.error('Error loading folder');
                });
        }
    }, [folderSlug, setFolder, reload, setReload]);

    if (!!!folderSlug) {
        return <Redirect to={`hub/folder/main`} />;
    }

    const isEmptyOrWhitespace = (str: string): boolean => {
        return !!!str || str.match(/^ *$/) !== null;
    };

    const createFolder = () => {
        if (!!folder && !!newFolderName) {
            const toastId = toast.info('Creating folder...');

            hubService
                .createFolder({
                    parentFolderId: folder.folderId,
                    name: newFolderName,
                })
                .then((newFolder) => {
                    setFolder({
                        ...folder,
                        childFolders: [...(folder.childFolders || []), newFolder],
                    });

                    toast.update(toastId, {
                        render: 'Folder created successfully',
                        type: 'success',
                    });

                    toggleShowNewFolder();

                    if (onStructureChanged) {
                        onStructureChanged();
                    }
                    setStructureLastChanged(new Date());
                })
                .catch((err) => {
                    let message = 'Error creating folder';

                    if (!!err?.message) {
                        message = err.message;
                    }

                    toast.update(toastId, {
                        render: message,
                        type: 'error',
                    });
                });
        }
    };

    const editFolder = () => {
        if (!!folder && !!newFolderName) {
            const toastId = toast.info('Updating folder...');

            hubService
                .updateFolder({
                    folderId: folder.folderId,
                    name: newFolderName,
                })
                .then((updatedFolder) => {
                    setFolder({
                        ...folder,
                        name: updatedFolder.name,
                        lastEdited: updatedFolder.lastEdited,
                        lastEditedString: updatedFolder.lastEditedString,
                    });

                    toast.update(toastId, {
                        render: 'Folder updated successfully',
                        type: 'success',
                    });

                    toggleShowEditFolder();

                    if (onStructureChanged) {
                        onStructureChanged();
                    }
                    setStructureLastChanged(new Date());
                })
                .catch((err) => {
                    let message = 'Error updating folder';

                    if (!!err?.message) {
                        message = err.message;
                    }

                    toast.update(toastId, {
                        render: message,
                        type: 'error',
                    });
                });
        }
    };

    const removeFolder = () => {
        if (!!folder) {
            const toastId = toast.info('Removing folder...');

            hubService
                .removeFolder({
                    folderId: folder.folderId,
                })
                .then((removedFolder) => {
                    setFolder({
                        ...folder,
                        isDeleted: removedFolder.isDeleted,
                        lastEdited: removedFolder.lastEdited,
                        lastEditedString: removedFolder.lastEditedString,
                    });

                    toast.update(toastId, {
                        render: 'Folder removed successfully',
                        type: 'success',
                    });

                    toggleShowRemoveFolder();

                    if (onStructureChanged) {
                        onStructureChanged();
                    }
                    setStructureLastChanged(new Date());
                })
                .catch((err) => {
                    toast.update(toastId, {
                        render: `Error removing folder`,
                        type: 'error',
                    });
                });
        }
    };

    const openUploadModal = () => {
        hubService.getSasUri().then((uri) => {
            setSasToken(uri);

            toggleShowUpload();
        });
    };

    const addStagedFiles = (dropData) => {
        setStagedFiled([...stagedFiles, ...dropData]);
    };

    function debounce(func, wait, immediate) {
        let timeout;
        return function () {
            // @ts-ignore: implicit any
            const context = this,
                args = arguments;
            const later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

    const handleDocumentCreated = debounce(
        (document: HubDocument) => {
            setReload(true);
        },
        250,
        false,
    );

    const handleDocumentRemoved = (hubDocumentId: number) => {
        if (folder) {
            const hubDocument = folder.hubDocuments.find((d) => d.hubDocumentId === hubDocumentId);

            if (hubDocument) {
                setFolder({
                    ...folder,
                    hubDocuments: [
                        ...folder.hubDocuments.filter((d) => d.hubDocumentId !== hubDocumentId),
                        {
                            ...hubDocument,
                            isDeleted: true,
                        },
                    ],
                });
            }
        }
    };

    if (!!folder && folder.isDeleted) {
        return <Redirect to={folder.parentFolder?.folderSlug || 'main'} />;
    }

    const activeChildFolders =
        folder?.childFolders?.filter((f) => !f.isDeleted)?.sort((a, b) => a.name.localeCompare(b.name)) || [];

    const activeHubDocuments =
        folder?.hubDocuments?.filter((d) => !d.isDeleted)?.sort((a, b) => a.name.localeCompare(b.name)) || [];

    return (
        <>
            <div
                style={{
                    borderLeft: 'solid 1px #DDDDDD',
                    flex: 1,
                    minHeight: '100%',
                    backgroundColor: '#FFFFFF',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {!!folder && <HubBreadcrumb folder={folder} structureLastChanged={structureLastChanged} />}
                <div
                    style={{
                        padding: '20px',
                    }}
                >
                    <PageTitle>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <h4>{folder?.name}</h4>
                            {!!folder && canManageStructure && (
                                <div>
                                    <Button color={`primary`} onClick={() => openUploadModal()}>
                                        <i className={`mdi mdi-upload mr-2`} />
                                        <span>{`Upload Files`}</span>
                                    </Button>
                                    {folder.folderSlug !== 'main' && (
                                        <>
                                            <Button
                                                className={`ml-3`}
                                                color={`info`}
                                                onClick={() => {
                                                    toggleShowEditFolder();
                                                    setNewFolderName(folder.name);
                                                }}
                                            >
                                                <i className={`mdi mdi-pencil mr-2`} />
                                                <span>{`Update Folder`}</span>
                                            </Button>
                                            <Button
                                                className={`ml-3`}
                                                color={`danger`}
                                                onClick={() => toggleShowRemoveFolder()}
                                            >
                                                <i className={`mdi mdi-delete mr-2`} />
                                                <span>{`Remove Folder`}</span>
                                            </Button>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </PageTitle>

                    <h6 className={`mt-3`}>{`Folders`}</h6>
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                        }}
                    >
                        {activeChildFolders.map((cf) => (
                            <FolderItemNav key={cf.folderId} to={cf.folderSlug}>
                                <i className={`mdi mdi-folder`} />
                                <span>{cf.name}</span>
                            </FolderItemNav>
                        ))}
                        {!canManageStructure && activeChildFolders.length === 0 && <label>{`Empty`}</label>}
                        {canManageStructure && (
                            <FolderItem
                                onClick={() => {
                                    setNewFolderName(undefined);
                                    toggleShowNewFolder();
                                }}
                            >
                                <i className={`mdi mdi-folder-plus`} />
                                <span>{`Create New Folder`}</span>
                            </FolderItem>
                        )}
                    </div>
                </div>
                <div
                    style={{
                        padding: '20px',
                    }}
                >
                    <h6>{`Files`}</h6>
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                        }}
                    >
                        {activeHubDocuments.length === 0 && <label>{`Empty`}</label>}
                        {folder &&
                            activeHubDocuments.map((d) => (
                                <HubDocumentItem
                                    key={d.hubDocumentId}
                                    hubDocument={d}
                                    folderSlug={folder?.folderSlug}
                                    onDocumentRemoved={handleDocumentRemoved}
                                />
                            ))}
                    </div>
                </div>
            </div>

            <Modal isOpen={showNewFolder} toggle={toggleShowNewFolder}>
                <ModalHeader>New Folder</ModalHeader>
                <ModalBody>
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                        <Label>Enter the name of the folder</Label>
                        <Input type="text" value={newFolderName} onChange={(e) => setNewFolderName(e.target.value)} />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color={'primary'}
                        className={'mr-2'}
                        type={'button'}
                        disabled={isEmptyOrWhitespace(newFolderName || '')}
                        onClick={() => createFolder()}
                    >{`Create Folder`}</Button>
                    <Button color={'link'} onClick={() => toggleShowNewFolder()} type={'button'}>{`Cancel`}</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={showEditFolder} toggle={toggleShowEditFolder}>
                <ModalHeader>Edit Folder</ModalHeader>
                <ModalBody>
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                        <Label>New Folder Name</Label>
                        <Input type="text" value={newFolderName} onChange={(e) => setNewFolderName(e.target.value)} />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color={'primary'}
                        className={'mr-2'}
                        type={'button'}
                        disabled={isEmptyOrWhitespace(newFolderName || '')}
                        onClick={() => editFolder()}
                    >{`Update Folder`}</Button>
                    <Button color={'link'} onClick={() => toggleShowEditFolder()} type={'button'}>{`Cancel`}</Button>
                </ModalFooter>
            </Modal>

            {!!folder && (
                <Modal isOpen={showRemoveFolder} toggle={toggleShowRemoveFolder}>
                    <ModalHeader>Are you sure?</ModalHeader>
                    <ModalBody>
                        {(folder.childFolders?.length > 0 || folder.hubDocuments?.length > 0) && (
                            <p>
                                {`Warning: removing this folder will also remove any files, sub-folders, and documents within those sub-folders.`}
                            </p>
                        )}
                        <p>{`If you would like to continue, pleae click 'Confirm' below.`}</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color={'primary'}
                            className={'mr-2'}
                            type={'button'}
                            onClick={() => removeFolder()}
                        >{`Confirm Remove`}</Button>
                        <Button
                            color={'link'}
                            onClick={() => toggleShowRemoveFolder()}
                            type={'button'}
                        >{`Cancel`}</Button>
                    </ModalFooter>
                </Modal>
            )}

            {folder && (
                <Modal
                    isOpen={showUpload}
                    toggle={toggleShowUpload}
                    centered
                    size={'lg'}
                    onClosed={() => setStagedFiled([])}
                >
                    <ModalHeader>Upload Files</ModalHeader>
                    <ModalBody>
                        <div
                            style={{
                                display: 'flex',
                            }}
                        >
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <h4>{`Add Files`}</h4>
                                <Dropzone onDrop={(acceptedFiles) => addStagedFiles(acceptedFiles)} multiple={true}>
                                    {({ getRootProps, getInputProps }) => (
                                        <DropZoneContainer>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <p style={{ textAlign: 'center', lineHeight: '80px' }}>
                                                    {`Drag & drop a file here, or click to select a file`}
                                                </p>
                                            </div>
                                        </DropZoneContainer>
                                    )}
                                </Dropzone>
                            </div>
                            <div
                                style={{
                                    flex: 1,
                                    padding: '0px 10px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <h4>{`Selected Files`}</h4>
                                {sasToken &&
                                    stagedFiles.map((f, i) => (
                                        <HubDocumentUpload
                                            key={i}
                                            file={f}
                                            folder={folder}
                                            sasToken={sasToken}
                                            onDocumentCreated={handleDocumentCreated}
                                        />
                                    ))}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color={'primary'} onClick={() => toggleShowUpload()} type={'button'}>{`Done`}</Button>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
};

export default HubExplorer;
