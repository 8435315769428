import { Button, Input } from '../../../components';
import { ButtonGroup, Card, CardBody, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import { ProgramAmsSatellite } from '../../../common/Types';
import Skeleton from 'react-loading-skeleton';
import { States } from '../../../common/Data';
import { programService } from '../../../services/ProgramService';
import { toast } from 'react-toastify';
import { AmsPermissionClaimType, authService } from '../../../services/Auth';

interface SatellitesProps {
    programId: number;
}

const Satellites = (props: SatellitesProps) => {
    const { programId } = props;

    const [satellites, setSatellites] = useState<ProgramAmsSatellite[]>();
    const [reloadSatellites, setReloadSatellites] = useState<boolean>(true);

    const [addEditSatellite, setAddEditSatellite] = useState<ProgramAmsSatellite>();
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const toggleEditModal = () => setShowEditModal(!showEditModal);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const toggleRemoveModal = () => setShowRemoveModal(!showRemoveModal);

    useEffect(() => {
        const loadSatellites = () => {
            if (programId && reloadSatellites) {
                setReloadSatellites(false);

                programService
                    .getSatellites(programId)
                    .then((results) => {
                        setSatellites(results.sort((a, b) => (a.title < b.title ? -1 : a.title > b.title ? 1 : 0)));
                    })
                    .catch(() => {
                        toast.error('Error loading satellites');
                    });
            }
        };

        loadSatellites();
    }, [setSatellites, programId, reloadSatellites]);

    const startEdit = (satellite: ProgramAmsSatellite) => {
        setAddEditSatellite({ ...satellite });
        toggleEditModal();
    };

    const startNew = () => {
        setAddEditSatellite({
            active: true,
            programId: programId,
            satelliteId: 0,
            title: '',
        });
        toggleEditModal();
    };

    const startRemove = (satellite: ProgramAmsSatellite) => {
        setAddEditSatellite({
            ...satellite,
            active: false,
        });
        toggleRemoveModal();
    };

    const submit = () => {
        if (addEditSatellite) {
            const toastId = toast.info('Saving Satellite...');

            programService
                .saveAmsSatellite(addEditSatellite)
                .then((result) => {
                    toast.update(toastId, {
                        render: 'Satellite saved',
                        type: 'success',
                    });
                    setReloadSatellites(true);
                    if (addEditSatellite.active === true) {
                        toggleEditModal();
                    } else {
                        toggleRemoveModal();
                    }
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error saving Satellite',
                        type: 'error',
                    });
                });
        }
    };

    return (
        <>
            <Card className={'d-flex flex-grow-1'}>
                <CardBody>
                    <h4 className={'card-title d-flex align-items-center justify-content-between'}>
                        <span>{`Satellites & Multiple Campuses`}</span>
                        {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') && (
                            <div>
                                <Button type={'button'} color={'primary'} onClick={() => startNew()}>
                                    <i className={`mdi mdi-plus`} />
                                </Button>
                            </div>
                        )}
                    </h4>
                    {!satellites && <Skeleton count={5} />}
                </CardBody>

                {satellites && (
                    <div className={'table-responsive'}>
                        <table className={'table hover'}>
                            <thead>
                                <tr>
                                    <th>{`Name`}</th>
                                    <th>{`City, State`}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {satellites.length === 0 && (
                                    <tr>
                                        <td colSpan={3}>{`No satellites added`}</td>
                                    </tr>
                                )}
                                {satellites.map((s) => (
                                    <tr key={s.satelliteId}>
                                        <td>{s.title}</td>
                                        <td>{`${s.city || ''}${
                                            s.state ? `${s.city ? `, ${s.state}` : s.state}` : ``
                                        }`}</td>
                                        <td className={'text-right'}>
                                            <ButtonGroup>
                                                <Button
                                                    type={'button'}
                                                    className={'btn-sm'}
                                                    onClick={() => startEdit(s)}
                                                    color={'info'}
                                                >
                                                    <i className={'mdi mdi-pencil'} />
                                                </Button>
                                                <Button
                                                    type={'button'}
                                                    className={'btn-sm'}
                                                    onClick={() => startRemove(s)}
                                                    color={'danger'}
                                                >
                                                    <i className={'mdi mdi-delete'} />
                                                </Button>
                                            </ButtonGroup>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </Card>
            {addEditSatellite && (
                <Modal isOpen={showEditModal} toggle={toggleEditModal} centered={true}>
                    <ModalHeader>{`${addEditSatellite.satelliteId > 0 ? 'Edit' : 'Add'} Satellite`}</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label>{`Title`}</Label>
                            <Input
                                type={'text'}
                                value={addEditSatellite.title}
                                onChange={(e) => setAddEditSatellite({ ...addEditSatellite, title: e.target.value })}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>{`City`}</Label>
                            <Input
                                type={'text'}
                                value={addEditSatellite.city}
                                onChange={(e) => setAddEditSatellite({ ...addEditSatellite, city: e.target.value })}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>{`State`}</Label>
                            <Input
                                type={'select'}
                                value={addEditSatellite.state}
                                onChange={(e) => setAddEditSatellite({ ...addEditSatellite, state: e.target.value })}
                            >
                                <option value={undefined}></option>
                                {States.map((s) => (
                                    <option key={s.abbreviation} value={s.abbreviation}>
                                        {s.abbreviation}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color={'primary'}
                            className={'mr-2'}
                            disabled={
                                addEditSatellite.title === undefined ||
                                addEditSatellite.title === null ||
                                addEditSatellite.title === ''
                            }
                            onClick={() => submit()}
                            type={'button'}
                        >{`Confirm`}</Button>
                        <Button color={'link'} onClick={() => toggleEditModal()} type={'button'}>{`Cancel`}</Button>
                    </ModalFooter>
                </Modal>
            )}
            {addEditSatellite && (
                <Modal isOpen={showRemoveModal} toggle={toggleRemoveModal} centered={true}>
                    <ModalHeader>{`Confirm Remove Satellite`}</ModalHeader>
                    <ModalBody>
                        <p>
                            {`Please confirm the removal of `}
                            <span className={'font-weight-bold'}>
                                {addEditSatellite.title || addEditSatellite.title}
                            </span>
                            {` by clicking the Confirm button below`}
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color={'primary'}
                            className={'mr-2'}
                            onClick={() => submit()}
                            type={'button'}
                        >{`Confirm`}</Button>
                        <Button color={'link'} onClick={() => toggleRemoveModal()} type={'button'}>{`Cancel`}</Button>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
};

export default Satellites;
