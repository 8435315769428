import { Alert, Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { AmsPermissionClaimType, authService } from '../../services/Auth';
import { Concentration, ProfessionDto, Program as ProgramModel, ProgramAddress, Satellite } from '../../common/Types';
import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { professionService } from '../../services/ProfessionAdministration';
import { programService } from '../../services/ProgramService';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { Countries, States } from '../../common/Data';

interface ProgramProps {
    program: ProgramModel;
    onSaved: () => void;
}

const Program = (props: ProgramProps) => {
    const [editing, setEditing] = useState(false);
    const [program, setProgram] = useState<ProgramModel>({ ...props.program });
    const [professions, setProfessions] = useState<ProfessionDto[]>();
    const [concentrations, setConcentrations] = useState<Concentration[]>();
    const [filteredConcentrations, setFilteredConcentrations] = useState<Concentration[]>();
    const { handleSubmit, register, watch, reset } = useForm();

    const watchProfessionId = watch('professionId');
    const watchProgram = watch({ nest: true });

    useEffect(() => {
        setProgram({
            ...props.program,
            dateFirstAccredited: props.program.dateFirstAccredited
                ? moment.utc(props.program.dateFirstAccredited).format('YYYY-MM-DD')
                : '',
            nextFullEvaluation: props.program.nextFullEvaluation
                ? moment.utc(props.program.nextFullEvaluation).format('YYYY-MM-DD')
                : '',
            prDue: props.program.prDue ? moment.utc(props.program.prDue).format('YYYY-MM-DD') : '',
            programAddress: props.program.programAddress
                ? props.program.programAddress
                : ({
                      country: 'United States of America',
                  } as ProgramAddress),
        });
    }, [setProgram, props.program]);

    useEffect(() => {
        professionService
            .getProfessions()
            .then((response) => {
                setProfessions(response);
                reset(program);
            })
            .catch(() => {
                toast.error('Failed to load professions.');
            });
    }, [setProfessions, reset, program]);

    useEffect(() => {
        professionService
            .getProfessionConcentrations([])
            .then((response) => {
                setConcentrations(response);
                if (program && program.professionId) {
                    setFilteredConcentrations(response.filter((c) => c.professionId === program.professionId));
                }
                reset(program);
            })
            .catch(() => {
                toast.error('Failed to load concentrations.');
            });
    }, [setConcentrations, program, reset]);

    useEffect(() => {
        if (watchProfessionId && watchProfessionId > 0) {
            setFilteredConcentrations(concentrations?.filter((c) => c.professionId === +watchProfessionId));
        } else {
            setFilteredConcentrations([]);
        }
    }, [watchProfessionId, concentrations]);

    const onSubmit = (program: ProgramModel) => {
        const toastId = toast.info('Saving program...');

        programService
            .saveProgram(program)
            .then((result) => {
                toast.update(toastId, {
                    render: 'Program saved successfully',
                    type: 'success',
                });

                setProgram({
                    ...result,
                    dateFirstAccredited: result.dateFirstAccredited
                        ? moment.utc(result.dateFirstAccredited).format('YYYY-MM-DD')
                        : '',
                    nextFullEvaluation: result.nextFullEvaluation
                        ? moment.utc(result.nextFullEvaluation).format('YYYY-MM-DD')
                        : '',
                    prDue: result.prDue ? moment.utc(result.prDue).format('YYYY-MM-DD') : '',
                });
                reset(result);
                setEditing(false);
                props.onSaved();
            })
            .catch(() => {
                toast.update(toastId, {
                    render: 'Error saving program',
                    type: 'error',
                });
            });
    };

    const selectedProfession = professions?.find((p) => p.professionId === +(watchProgram.professionId || -1));
    const selectedConcentration = concentrations?.find(
        (c) => c.concentrationId === +(watchProgram.concentrationId || -1),
    );

    const programTitle = `${selectedProfession?.title || ''}${
        selectedConcentration ? ` - ${selectedConcentration.title}` : ''
    }`;

    const editedByInfo =
        program.editedByString && program.editedByString.indexOf('{') > -1
            ? JSON.parse(program.editedByString)
            : undefined;

    return (
        <div className={'flex-grow-1'}>
            <Form className={'h-100'} onSubmit={handleSubmit((values) => onSubmit(new ProgramModel(values)))}>
                <input type="hidden" name={'programId'} ref={register({})} />
                <input type="hidden" name={'institutionId'} ref={register({})} />
                <input type="hidden" name={'createdBy'} ref={register({})} />
                <input type="hidden" name={'dateCreated'} ref={register({})} />
                <input type="hidden" name={'lastEdited'} ref={register({})} />
                <input type="hidden" name={'editedBy'} ref={register({})} />
                <input type="hidden" name={'active'} ref={register({})} />
                <input type="hidden" name={'onProbation'} ref={register({})} />
                <input type="hidden" name={'probationReason'} ref={register({})} />
                <input type="hidden" name={'probationStartDate'} ref={register({})} />
                <input type="hidden" name={'title'} ref={register()} value={programTitle} />
                <input type={'hidden'} name={'programAddressId'} ref={register()} />

                <div className={'d-flex flex-column justify-content-between h-100'}>
                    <div>
                        <Row className={'d-flex flex-column'}>
                            {program.onProbation === true && (
                                <Col className={'align-self-stretch'}>
                                    <Alert color={'warning'}>{`This program is on administrative probation.`}</Alert>
                                </Col>
                            )}
                            <Col className={'align-self-stretch'}>
                                <FormGroup className={'row'}>
                                    <Col xs={12}>
                                        <Label>CAAHEP Program Title</Label>
                                    </Col>
                                    {!editing && (
                                        <Col xs={12}>
                                            <span>{program.title}</span>
                                        </Col>
                                    )}
                                    {editing && (
                                        <Col xs={12}>
                                            <span>{programTitle}</span>
                                        </Col>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col className={'align-self-stretch'}>
                                <FormGroup className={'row'}>
                                    <Col xs={12}>
                                        <Label>Program Name</Label>
                                    </Col>
                                    {!editing && (
                                        <Col xs={12}>
                                            <span>{program.programName}</span>
                                        </Col>
                                    )}
                                    {editing && (
                                        <Col xs={12}>
                                            <div>
                                                <Input
                                                    type="text"
                                                    name="programName"
                                                    id="programName"
                                                    placeholder="Program Name"
                                                    innerRef={register()}
                                                    defaultValue={program.programName ?? undefined}
                                                />
                                            </div>
                                        </Col>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={'align-self-stretch'}>
                                <FormGroup className={'row'}>
                                    <Col xs={12}>
                                        <Label>Address</Label>
                                    </Col>
                                    {!editing && (
                                        <>
                                            <Col xs={12}>
                                                <div>{program.programAddress?.address || ''}</div>
                                                {program.programAddress?.address2 && (
                                                    <div>{program.programAddress?.address2}</div>
                                                )}
                                                {program.programAddress?.address3 && (
                                                    <div>{program.programAddress?.address3}</div>
                                                )}
                                                {program.programAddress?.address4 && (
                                                    <div>{program.programAddress?.address4}</div>
                                                )}
                                                <div>{`${program.programAddress?.city || ''}, ${
                                                    program.programAddress?.state || ''
                                                } ${program.programAddress?.zip || ''}`}</div>
                                                <div>{program.programAddress?.country || ''}</div>
                                            </Col>
                                        </>
                                    )}
                                    {editing && (
                                        <Col xs={12}>
                                            <div>
                                                <Input
                                                    type="text"
                                                    name="programAddress.address"
                                                    id="programAddress.address"
                                                    placeholder="Address"
                                                    innerRef={register()}
                                                    defaultValue={program.programAddress?.address ?? undefined}
                                                />
                                            </div>
                                            <div className="mt-1">
                                                <Input
                                                    type="text"
                                                    name="programAddress.address2"
                                                    id="programAddress.address2"
                                                    placeholder="Address 2"
                                                    innerRef={register()}
                                                    defaultValue={program.programAddress?.address2 ?? undefined}
                                                />
                                            </div>
                                            <div className="mt-1">
                                                <Input
                                                    type="text"
                                                    name="programAddress.address3"
                                                    id="programAddress.address3"
                                                    placeholder="Address 3"
                                                    innerRef={register()}
                                                    defaultValue={program.programAddress?.address3 ?? undefined}
                                                />
                                            </div>
                                            <div className="mt-1">
                                                <Input
                                                    type="text"
                                                    name="programAddress.address4"
                                                    id="programAddress.address4"
                                                    placeholder="Address 4"
                                                    innerRef={register()}
                                                    defaultValue={program.programAddress?.address4 ?? undefined}
                                                />
                                            </div>
                                            <div className="mt-1">
                                                <div className="d-inline-block w-50">
                                                    <Input
                                                        type="text"
                                                        name="programAddress.city"
                                                        id="programAddress.city"
                                                        placeholder="City"
                                                        innerRef={register()}
                                                        defaultValue={program.programAddress?.city ?? undefined}
                                                    />
                                                </div>
                                                <div className="d-inline-block w-25 p-1">
                                                    <Input
                                                        type={'select'}
                                                        name="programAddress.state"
                                                        id="programAddress.state"
                                                        defaultValue={program.programAddress?.state || undefined}
                                                        innerRef={register()}
                                                    >
                                                        <option value={undefined}>{``}</option>
                                                        {States &&
                                                            States.map((s) => (
                                                                <option key={s.abbreviation} value={s.abbreviation}>
                                                                    {s.abbreviation}
                                                                </option>
                                                            ))}
                                                    </Input>
                                                </div>
                                                <div className="d-inline-block w-25">
                                                    <Input
                                                        type="text"
                                                        name="programAddress.zip"
                                                        id="programAddress.zip"
                                                        placeholder="Zip"
                                                        innerRef={register()}
                                                        defaultValue={program.programAddress?.zip ?? undefined}
                                                    />
                                                </div>
                                                <div className="d-inline-block w-50 p-1">
                                                    <Input
                                                        type={'select'}
                                                        name="programAddress.country"
                                                        id="programAddress.country"
                                                        defaultValue={program.programAddress?.country || undefined}
                                                        innerRef={register()}
                                                    >
                                                        <option value={undefined}></option>
                                                        {Countries.map((c) => (
                                                            <option key={c} value={c}>
                                                                {c}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                </div>
                                                <input
                                                    type={'hidden'}
                                                    name={'programAddress.id'}
                                                    ref={register()}
                                                    defaultValue={program.programAddress?.id ?? 0}
                                                />
                                                <input
                                                    type={'hidden'}
                                                    name={'programAddress.dateCreated'}
                                                    ref={register()}
                                                />
                                                <input
                                                    type={'hidden'}
                                                    name={'programAddress.createdByString'}
                                                    ref={register()}
                                                />
                                            </div>
                                        </Col>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} lg={3}>
                                <FormGroup className={'row'}>
                                    <Col xs={12}>
                                        <Label>Degree</Label>
                                    </Col>
                                    <Col xs={12}>
                                        {!editing && program.degreeDiploma && (
                                            <span className={'d-block'}>Diploma</span>
                                        )}
                                        {!editing && program.degreeCertificate && (
                                            <span className={'d-block'}>Certificate</span>
                                        )}
                                        {!editing && program.degreeAssociate && (
                                            <span className={'d-block'}>Associate</span>
                                        )}
                                        {!editing && program.degreeBaccalaureate && (
                                            <span className={'d-block'}>Baccalaureate</span>
                                        )}
                                        {!editing && program.degreeMasters && (
                                            <span className={'d-block'}>Masters</span>
                                        )}
                                    </Col>
                                    {editing && (
                                        <>
                                            <Col xs={12}>
                                                <div className={'ml-1 custom-control custom-checkbox'}>
                                                    <Input
                                                        type="checkbox"
                                                        id={`degreeDiploma`}
                                                        name={`degreeDiploma`}
                                                        innerRef={register()}
                                                        className={'custom-control-input'}
                                                    />
                                                    <Label for={`degreeDiploma`} className={'custom-control-label'}>
                                                        Diploma
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col xs={12}>
                                                <div className={'ml-1 custom-control custom-checkbox'}>
                                                    <Input
                                                        type="checkbox"
                                                        id={`degreeCertificate`}
                                                        name={`degreeCertificate`}
                                                        innerRef={register()}
                                                        className={'custom-control-input'}
                                                    />
                                                    <Label for={`degreeCertificate`} className={'custom-control-label'}>
                                                        Certificate
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col xs={12}>
                                                <div className={'ml-1 custom-control custom-checkbox'}>
                                                    <Input
                                                        type="checkbox"
                                                        id={`degreeAssociate`}
                                                        name={`degreeAssociate`}
                                                        innerRef={register()}
                                                        className={'custom-control-input'}
                                                    />
                                                    <Label for={`degreeAssociate`} className={'custom-control-label'}>
                                                        Associate
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col xs={12}>
                                                <div className={'ml-1 custom-control custom-checkbox'}>
                                                    <Input
                                                        type="checkbox"
                                                        id={`degreeBaccalaureate`}
                                                        name={`degreeBaccalaureate`}
                                                        innerRef={register()}
                                                        className={'custom-control-input'}
                                                    />
                                                    <Label
                                                        for={`degreeBaccalaureate`}
                                                        className={'custom-control-label'}
                                                    >
                                                        Baccalaureate
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col xs={12}>
                                                <div className={'ml-1 custom-control custom-checkbox'}>
                                                    <Input
                                                        type="checkbox"
                                                        id={`degreeMasters`}
                                                        name={`degreeMasters`}
                                                        innerRef={register()}
                                                        className={'custom-control-input'}
                                                    />
                                                    <Label for={`degreeMasters`} className={'custom-control-label'}>
                                                        Masters
                                                    </Label>
                                                </div>
                                            </Col>
                                        </>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col sm={6} lg={9}>
                                <Row>
                                    <Col sm={12} lg={6}>
                                        <FormGroup className={'row'}>
                                            <Col xs={12}>
                                                <Label>Profession</Label>
                                            </Col>
                                            <Col xs={12}>
                                                {!editing && professions && (
                                                    <>
                                                        <span>
                                                            {
                                                                professions.find(
                                                                    (p) => p.professionId === program.professionId,
                                                                )?.title
                                                            }
                                                        </span>
                                                        <input type={'hidden'} name={'professionId'} ref={register()} />
                                                    </>
                                                )}
                                                {editing && (
                                                    <select
                                                        className={'select form-control'}
                                                        ref={register({ required: true })}
                                                        name={'professionId'}
                                                    >
                                                        <option value={''}>Select a Profession</option>
                                                        {professions &&
                                                            professions.map((profession) => (
                                                                <option
                                                                    key={profession.professionId}
                                                                    value={profession.professionId}
                                                                >
                                                                    {profession.title}
                                                                </option>
                                                            ))}
                                                    </select>
                                                )}
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} lg={6}>
                                        {filteredConcentrations && filteredConcentrations.length > 0 && (
                                            <FormGroup className={'row'}>
                                                <Col xs={12}>
                                                    <Label>Concentration</Label>
                                                </Col>
                                                <Col xs={12}>
                                                    {!editing && concentrations && program.concentrationId && (
                                                        <>
                                                            <span>
                                                                {
                                                                    concentrations.find(
                                                                        (c) =>
                                                                            c.concentrationId ===
                                                                            program.concentrationId,
                                                                    )?.title
                                                                }
                                                            </span>
                                                            <input
                                                                type={'hidden'}
                                                                name={'concentrationId'}
                                                                ref={register()}
                                                            />
                                                        </>
                                                    )}
                                                    {editing && (
                                                        <select
                                                            className={'select form-control'}
                                                            ref={register({})}
                                                            name={'concentrationId'}
                                                        >
                                                            <option value={''}>Select a Concentration</option>
                                                            {filteredConcentrations.map((concentration) => (
                                                                <option
                                                                    key={concentration.concentrationId}
                                                                    value={concentration.concentrationId}
                                                                >
                                                                    {concentration.title}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    )}
                                                </Col>
                                            </FormGroup>
                                        )}

                                        {(!filteredConcentrations || filteredConcentrations?.length === 0) && (
                                            <input type="hidden" name={'concentrationId'} ref={register({})} />
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} lg={3}></Col>
                            <Col sm={6} lg={9}>
                                <Row>
                                    <Col sm={12} lg={6}>
                                        <FormGroup className={'row'}>
                                            <Col xs={12}>
                                                <Label>Program Website</Label>
                                            </Col>
                                            <Col xs={12}>
                                                {!editing && program.website && (
                                                    <a
                                                        href={`${
                                                            program?.website?.indexOf('http://') > -1 ? '' : 'http://'
                                                        }${program.website}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {program.website}&nbsp;
                                                        <i className={'remixicon-links-line'}></i>
                                                    </a>
                                                )}
                                                {!editing && !program.website && 'N/A'}
                                                {editing && (
                                                    <Input name={'website'} type={'text'} innerRef={register({})} />
                                                )}
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} lg={6}>
                                        <FormGroup className={'row'}>
                                            <Col xs={12}>
                                                <Label>Outcomes Url</Label>
                                            </Col>
                                            <Col xs={12}>
                                                {!editing && program.outcomesUrl && (
                                                    <a
                                                        href={`${
                                                            program?.outcomesUrl?.indexOf('http://') > -1
                                                                ? ''
                                                                : 'http://'
                                                        }${program.outcomesUrl}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {program.outcomesUrl}&nbsp;
                                                        <i className={'remixicon-links-line'}></i>
                                                    </a>
                                                )}
                                                {!editing && !program.outcomesUrl && 'N/A'}
                                                {editing && (
                                                    <Input name={'outcomesUrl'} type={'text'} innerRef={register({})} />
                                                )}
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} lg={6}>
                                        <FormGroup className={'row'}>
                                            <Col xs={12}>
                                                <Label>Date First Accredited</Label>
                                            </Col>
                                            <Col xs={12}>
                                                {!editing && (
                                                    <span>
                                                        {program.dateFirstAccredited
                                                            ? moment(program.dateFirstAccredited).format('MM/DD/YYYY')
                                                            : ''}
                                                    </span>
                                                )}
                                                {editing && (
                                                    <Input
                                                        name={'dateFirstAccredited'}
                                                        type={'date'}
                                                        innerRef={register()}
                                                    />
                                                )}
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} lg={6}>
                                        <FormGroup className={'row'}>
                                            <Col xs={12}>
                                                <Label>Next Full Evaluation</Label>
                                            </Col>
                                            <Col xs={12}>
                                                {!editing && (
                                                    <span>
                                                        {program.nextFullEvaluation
                                                            ? moment(program.nextFullEvaluation).format('MM/DD/YYYY')
                                                            : ''}
                                                    </span>
                                                )}
                                                {editing && (
                                                    <Input
                                                        name={'nextFullEvaluation'}
                                                        type={'date'}
                                                        innerRef={register()}
                                                    />
                                                )}
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} lg={6}>
                                        <FormGroup className={'row'}>
                                            <Col xs={12}>
                                                <Label>PR Due</Label>
                                            </Col>
                                            <Col xs={12}>
                                                {!editing && (
                                                    <span>
                                                        {program.prDue
                                                            ? moment(program.prDue).format('MM/DD/YYYY')
                                                            : ''}
                                                    </span>
                                                )}
                                                {editing && (
                                                    <Input name={'prDue'} type={'date'} innerRef={register()} />
                                                )}
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} lg={6}>
                                        <FormGroup className={'row'}>
                                            <Col xs={12}>
                                                <Label>Credentialing Code</Label>
                                            </Col>
                                            <Col xs={12}>
                                                {!editing && <span>{program.credentialingCode}</span>}
                                                {editing && (
                                                    <Input
                                                        name={'credentialingCode'}
                                                        type={'text'}
                                                        innerRef={register({})}
                                                    />
                                                )}
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} lg={6}>
                                        <FormGroup className={'row'}>
                                            <Col xs={12}>
                                                {!editing && (
                                                    <>
                                                        <Label>Distance:</Label>
                                                        <span className={'ml-2'}>
                                                            {program.distance ? 'Yes' : 'No'}
                                                        </span>
                                                    </>
                                                )}
                                                {editing && (
                                                    <div className={'custom-control custom-checkbox'}>
                                                        <Input
                                                            type="checkbox"
                                                            id={`distance`}
                                                            name={`distance`}
                                                            innerRef={register({})}
                                                            className={'custom-control-input'}
                                                        />
                                                        <Label for={`distance`} className={'custom-control-label'}>
                                                            Distance?
                                                        </Label>
                                                    </div>
                                                )}
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} lg={6}>
                                        <FormGroup className={'row'}>
                                            <Col xs={12}>
                                                {!editing && (
                                                    <>
                                                        <Label>Military Only:</Label>
                                                        <span className={'ml-2'}>
                                                            {program.militaryPersonnelOnly ? 'Yes' : 'No'}
                                                        </span>
                                                    </>
                                                )}
                                                {editing && (
                                                    <div className={'custom-control custom-checkbox'}>
                                                        <Input
                                                            type="checkbox"
                                                            id={`militaryPersonnelOnly`}
                                                            name={`militaryPersonnelOnly`}
                                                            innerRef={register({})}
                                                            className={'custom-control-input'}
                                                        />
                                                        <Label
                                                            for={`militaryPersonnelOnly`}
                                                            className={'custom-control-label'}
                                                        >
                                                            Military Only?
                                                        </Label>
                                                    </div>
                                                )}
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} lg={6}>
                                        <FormGroup className={'row'}>
                                            <Col xs={12}>
                                                {!editing && (
                                                    <>
                                                        <Label>Visible:</Label>
                                                        <span className={'ml-2'}>{program.visible ? 'Yes' : 'No'}</span>
                                                    </>
                                                )}
                                                {editing && (
                                                    <div className={'custom-control custom-checkbox'}>
                                                        <Input
                                                            type="checkbox"
                                                            id={`visible`}
                                                            name={`visible`}
                                                            innerRef={register({})}
                                                            className={'custom-control-input'}
                                                        />
                                                        <Label for={`visible`} className={'custom-control-label'}>
                                                            Visible?
                                                        </Label>
                                                    </div>
                                                )}
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        {!editing && authService.hasPermission(AmsPermissionClaimType, 'systemadmin') && (
                            <Button type={'button'} color={'primary'} onClick={() => setEditing(!editing)}>
                                Edit
                            </Button>
                        )}
                        {editing && (
                            <>
                                <Button color={'primary'} type={'submit'}>
                                    Save
                                </Button>

                                <Button outline className={'ml-2'} onClick={() => setEditing(!editing)}>
                                    Cancel
                                </Button>
                            </>
                        )}
                    </div>
                    {(editedByInfo || program.lastEdited) && (
                        <div className={'mt-3'}>
                            <span>{`Last edited`}</span>
                            {editedByInfo && <span>{` by ${editedByInfo.name}`}</span>}
                            {program.lastEdited && (
                                <span>{` on ${moment
                                    .utc(program.lastEdited)
                                    .local()
                                    .format('MM/DD/YYYY [at] hh:mm A')}`}</span>
                            )}
                        </div>
                    )}
                </div>
            </Form>
        </div>
    );
};

export default Program;
