import { AmsPermissionClaimType, ArmsPermissionClaimType, authService } from '../../services/Auth';
import { Card, CardBody, Col, Form, FormGroup, Row } from 'reactstrap';
import { NavLink, Redirect } from 'react-router-dom';
import React, { ReactText, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button';
import FormError from '../../components/FormError';
import { IAuthState } from '../../store/auth/Types';
import Input from '../../components/Input';
import Logo from '../../assets/images/logo-blue.png';
import { login } from '../../store/auth/Actions';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

const Login = () => {
    const auth: IAuthState = useSelector((state) => state.auth);
    const { register, handleSubmit, errors } = useForm();
    const dispatch = useDispatch();
    const [toastId, setToastId] = useState('' as ReactText);
    const [redirect] = useState(`${localStorage.getItem('redirect') ? localStorage.getItem('redirect') : ''}`);
    const onSubmit = (formData) => {
        if (!toast.isActive(toastId)) {
            setToastId(toast.info('Checking Credentials...', { autoClose: false }));
        } else {
            toast.update(toastId ?? '', {
                type: 'info',
                render: 'Checking Credentials...',
            });
        }
        dispatch(login(formData));
    };

    useEffect(() => {
        if (toast.isActive(toastId)) {
            if (auth.authenticated) {
                toast.update(toastId ?? '', {
                    type: 'success',
                    render: 'Login successful.',
                });
            } else if (auth.authenticationError) {
                toast.update(toastId ?? '', {
                    type: 'error',
                    render: 'Invalid credentials.',
                });
            }
        }
    }, [auth, toastId]);

    useEffect(() => {
        localStorage.removeItem('redirect');
    }, [redirect]);

    const redirectToUrl = () => {
        if (redirect) {
            return redirect;
        }

        return authService.hasPermission(AmsPermissionClaimType, 'systemadmin')
            ? '/Institutions'
            : authService.hasPermission(ArmsPermissionClaimType, 'admin')
            ? '/annual-report/overview'
            : authService.hasPermission(ArmsPermissionClaimType, 'viewannualreport')
            ? '/annual-report/dashboard'
            : authService.hasPermission(AmsPermissionClaimType, 'hubviewdocuments')
            ? '/hub/folders/main'
            : '/profile';
    };

    return (
        <>
            {auth.authenticated && <Redirect to={redirectToUrl()} />}
            <Row className="justify-content-center">
                <Col md="8" lg="6" xl="5">
                    <Card>
                        <CardBody>
                            <div className="text-center w-75 m-auto">
                                <NavLink to="/">
                                    <span>
                                        <img src={Logo} alt="logo" height="150" />
                                    </span>
                                </NavLink>
                                <p className="text-muted mb-4 mt-3">Enter your email address and password.</p>
                            </div>

                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup className="mb-3">
                                    <label htmlFor="email">Email address</label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        id="email"
                                        name="email"
                                        innerRef={register({ required: true })}
                                        placeholder="Enter your email"
                                    />
                                    <FormError name="email" message="Please enter an email" errors={errors} />
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <label htmlFor="password">Password</label>
                                    <Input
                                        className="form-control"
                                        type="password"
                                        innerRef={register({ required: true })}
                                        id="password"
                                        name="password"
                                        placeholder="Enter your password"
                                    />
                                    <FormError name="password" message="Please enter a password" errors={errors} />
                                </FormGroup>
                                <FormGroup className="mb-0 text-center">
                                    <Button className="btn-block" type="submit">
                                        Log In
                                    </Button>
                                </FormGroup>
                            </Form>
                        </CardBody>
                    </Card>

                    <Row className="mt-3">
                        <Col className="text-center">
                            <p>
                                <NavLink to="/passwordreset/request" className="ml-1">
                                    Forgot your password?
                                </NavLink>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default Login;
