import { AnnualReportNavigationDirection, AnnualReportProps } from '../AnnualReport';
import { Button, Input } from '../../../components';
import { Card, CardBody, CardFooter, Form, FormGroup, Label } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import { ProgramComment } from '../../../common/Types';
import { programService } from '../../../services/ProgramService';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

const Comments = (props: AnnualReportProps) => {
    const [comment, setComment] = useState<ProgramComment>();
    const [submitDirection, setSubmitDirection] = useState<AnnualReportNavigationDirection>(
        AnnualReportNavigationDirection.Forward,
    );
    const {
        handleSubmit,
        register,
        errors,
        formState: { isSubmitting },
    } = useForm();

    const onSubmit = (formData): Promise<void> => {
        return new Promise((res) => {
            const toastId = toast.info('Saving comments...');

            programService
                .saveProgramComment(props.program.programId, {
                    ...new ProgramComment(formData),
                })
                .then(() => {
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Comments Saved.',
                    });

                    if (submitDirection === AnnualReportNavigationDirection.Forward) {
                        props.onSavedSuccessfully(AnnualReportNavigationDirection.Forward);
                    }
                })
                .catch(() => {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Failed to save comments.',
                    });
                })
                .finally(() => {
                    res();
                });
        });
    };

    useEffect(() => {
        const getComment = () => {
            programService
                .getProgramComment(props.program.programId, props.reportYear ?? new Date().getFullYear())
                .then((response) => {
                    setComment(response);
                })
                .catch(() => {
                    toast.error('Unable to get comments.');
                });
        };

        getComment();
    }, [props.program.programId, props.reportYear, setComment]);

    return (
        <Card>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                    <h4 className="card-title">Comments</h4>
                    <h5 className="card-subtitle mb-3">
                        {`Please provide your comments, suggestions or concerns relating specifically to your Program or
                        generally to ${props.profession.title} education.
                        
                        If you do not have any comments, please enter 'No comments'`}
                    </h5>
                    <FormGroup>
                        <Input
                            type="textarea"
                            name="comment"
                            innerRef={register({
                                required: `Comments are required, if you have no comment, enter 'No comments'`,
                            })}
                            defaultValue={comment?.comment ?? undefined}
                            rows={20}
                        />
                        {errors?.comment?.message && (
                            <div>
                                <Label className={'text-danger'}>{errors?.comment?.message}</Label>
                            </div>
                        )}
                        <input
                            type="hidden"
                            name={`commentId`}
                            defaultValue={comment?.commentId ?? 0}
                            ref={register()}
                        />
                        <input
                            type="hidden"
                            name={`programId`}
                            defaultValue={props.program.programId}
                            ref={register()}
                        />
                        <input type="hidden" name={`activeYear`} defaultValue={+props.reportYear} ref={register()} />
                        <input
                            type="hidden"
                            name={`createdBy`}
                            defaultValue={comment?.createdBy ?? undefined}
                            ref={register()}
                        />
                        <input
                            type="hidden"
                            name={`dateCreated`}
                            defaultValue={comment?.dateCreated?.toString() ?? undefined}
                            ref={register()}
                        />
                        <input
                            type="hidden"
                            name={`lastEdited`}
                            defaultValue={comment?.lastEdited?.toString() ?? undefined}
                            ref={register()}
                        />
                        <input
                            type="hidden"
                            name={`editedBy`}
                            defaultValue={comment?.editedBy ?? undefined}
                            ref={register()}
                        />
                    </FormGroup>
                </CardBody>
                {props.canEdit && (
                    <CardFooter className={'d-flex justify-content-between'}>
                        <Button
                            type={'button'}
                            color={'warning'}
                            onClick={() => props.onNavigate(AnnualReportNavigationDirection.Back)}
                        >
                            <i className={'mdi mdi-arrow-left'} />
                            <span className={'mr-1'}>{`Previous`}</span>
                        </Button>

                        <Button
                            disabled={isSubmitting}
                            type={'submit'}
                            color={'primary'}
                            onClick={() => setSubmitDirection(AnnualReportNavigationDirection.Stay)}
                        >
                            <i className={'mdi mdi-content-save'} />
                            <span className={'ml-1'}>{`Save`}</span>
                        </Button>

                        <Button
                            disabled={isSubmitting}
                            type={'submit'}
                            color={'info'}
                            onClick={() => setSubmitDirection(AnnualReportNavigationDirection.Forward)}
                        >
                            <span className={'mr-1'}>{`Save & Continue`}</span>
                            <i className={'mdi mdi-arrow-right'} />
                        </Button>
                    </CardFooter>
                )}
            </Form>
        </Card>
    );
};

export default Comments;
