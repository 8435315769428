import { AuthActionType, IAuthAction, IAuthState } from './Types';
import {
    IChangePasswordForm,
    IPasswordResetCompleteForm,
    IPasswordResetRequestForm,
    authService,
} from '../../services/Auth';

export const login = (values) => {
    return (dispatch) => {
        dispatch(authenticating());
        return authService
            .login(values)
            .then((loggedInUser) => {
                dispatch(authenticated(loggedInUser));
            })
            .catch((error) => {
                dispatch(authenticationError(error));
            });
    };
};

export const logout = () => {
    return (dispatch) => {
        authService.logout();
        dispatch(loggedOut());
    };
};

export const requestPasswordReset = (values: IPasswordResetRequestForm) => {
    return (dispatch) => {
        dispatch(passwordResetting());
        return authService
            .requestPasswordReset(values)
            .then(() => {
                dispatch(passwordReset(true));
            })
            .catch((error) => {
                dispatch(passwordReset(false, error.message));
            });
    };
};

export const completePasswordReset = (values: IPasswordResetCompleteForm) => {
    return (dispatch) => {
        dispatch(passwordResetting());
        return authService
            .completePasswordReset(values)
            .then(() => {
                dispatch(passwordReset(true));
            })
            .catch((error) => {
                let errorMessage = '';
                switch (error[0]?.code) {
                    case 'InvalidToken':
                        errorMessage = 'Your password reset token has expired. Please click below and request a new password reset link.'
                        break;
                    default:
                        errorMessage = 'We are unable to reset your password. Please verify your email address has been entered properly and try again. If that does not work, you may need to click below and request a new password reset link.'
                        break;
                }
                dispatch(passwordReset(false, errorMessage));
            });
    };
};

export const changePassword = (values: IChangePasswordForm, user: any) => {
    return (dispatch) => {
        dispatch(passwordResetting(true, user));
        return authService
            .changePassword(values)
            .then(() => {
                dispatch(passwordReset(true, undefined, true, user));
                dispatch(passwordReset(false, undefined, true, user));
            })
            .catch((error) => {
                dispatch(passwordReset(false, error[0].description, true, user));
            });
    };
};

const passwordReset = (
    resetStatus: boolean,
    errorMessage?: string,
    authenticated: boolean = false,
    user: any = null,
): IAuthAction<IAuthState> => {
    return {
        type: AuthActionType.PasswordReset,
        payload: {
            authenticated: authenticated,
            authenticating: false,
            user: user,
            passwordReset: resetStatus,
            passwordResetting: false,
            passwordResetError: errorMessage,
        },
    };
};

const passwordResetting = (authenticated: boolean = false, user: any = null): IAuthAction<IAuthState> => {
    return {
        type: AuthActionType.PasswordResetting,
        payload: {
            authenticated: authenticated,
            authenticating: false,
            user: user,
            passwordReset: false,
            passwordResetting: true,
            passwordResetError: undefined,
        },
    };
};

export const loggedOut = (): IAuthAction<IAuthState> => {
    return {
        type: AuthActionType.LoggedOut,
        payload: {
            authenticated: false,
            authenticating: false,
            user: null,
            passwordReset: false,
            passwordResetting: false,
        },
    };
};

const authenticating = (): IAuthAction<IAuthState> => {
    return {
        type: AuthActionType.Authenticating,
        payload: {
            authenticated: false,
            authenticating: true,
            passwordReset: false,
            passwordResetting: false,
            authenticationError: undefined,
        },
    };
};

const authenticated = (user): IAuthAction<IAuthState> => {
    return {
        type: AuthActionType.Authenticated,
        payload: {
            authenticated: true,
            authenticating: false,
            user: user,
            passwordReset: false,
            passwordResetting: false,
            authenticationError: undefined,
        },
    };
};

const authenticationError = (error): IAuthAction<IAuthState> => {
    return {
        type: AuthActionType.AuthenticationError,
        payload: {
            authenticated: false,
            authenticating: false,
            authenticationError: error.error_description,
            passwordReset: false,
            passwordResetting: false,
        },
    };
};
