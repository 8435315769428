import { AnnualReportNavigationDirection, AnnualReportProps } from '../AnnualReport';
import {
    Badge,
    Card,
    CardBody,
    CardFooter,
    Col,
    Collapse,
    FormGroup,
    Label,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Table,
} from 'reactstrap';
import { Button, Input, SurveyWorksheetResultsForm } from '../../../components';
import { ProgramOutcomes, Survey, SurveyType, SurveyWorksheetResult } from '../../../common/Types';
import React, { useEffect, useState } from 'react';

import SurveyWorksheetForm from '../../../components/SurveyWorksheetForm';
import moment from 'moment';
import { programService } from '../../../services/ProgramService';
import { toast } from 'react-toastify';

const SurveyWorksheet = (props: AnnualReportProps) => {
    const { settings, program, reportYear, canEdit, onNavigate, onSavedSuccessfully } = props;

    const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
    const [surveyYear, setSurveyYear] = useState<number>();
    const [worksheets, setWorksheets] = useState<Survey[]>();
    const [reloadWorksheets, setReloadWorksheets] = useState<boolean>(true);

    const [surveyTypes, setSurveyTypes] = useState<SurveyType[]>([]);
    const [surveyResults, setSurveyResults] = useState<SurveyWorksheetResult[]>([]);
    const [reloadResults, setReloadResults] = useState<boolean>(true);

    const [selectedType, setSelectedType] = useState<SurveyType>();
    const [selectedResults, setSelectedResults] = useState<SurveyWorksheetResult[]>();
    const [selectedYear, setSelectedYear] = useState<number>();

    const [surveyOutcomes, setSurveyOutcomes] = useState<ProgramOutcomes[]>();

    const [editWorksheet, setEditWorksheet] = useState<Survey>();
    const [showWorksheetModal, setWorksheetModal] = useState(false);
    const toggleWorksheetModal = () => setWorksheetModal(!showWorksheetModal);

    const [showResultsModal, setResultsModal] = useState(false);
    const toggleResultsModal = () => setResultsModal(!showResultsModal);

    const [graduateSentReturnedOutcome, setGraduateSentReturnedOutcome] = useState<ProgramOutcomes>();
    const [employerSentReturnedOutcome, setEmployerSentReturnedOutcome] = useState<ProgramOutcomes>();
    const [reloadOutcomes, setReloadOutcomes] = useState<boolean>(true);

    const initializeGraduateSentReturnedOutcome = (): ProgramOutcomes => {
        return {
            actionPlan: null,
            createdBy: null,
            dateCreated: null,
            detailId: 0,
            detailedAnalysis: null,
            editedBy: null,
            lastEdited: null,
            outcomeType: null,
            outcomeTypeId: 101,
            programId: program.programId,
            reportYear: +reportYear,
        } as ProgramOutcomes;
    };

    const initializeEmployerSentReturnedOutcome = (): ProgramOutcomes => {
        return {
            actionPlan: null,
            createdBy: null,
            dateCreated: null,
            detailId: 0,
            detailedAnalysis: null,
            editedBy: null,
            lastEdited: null,
            outcomeType: null,
            outcomeTypeId: 100,
            programId: program.programId,
            reportYear: +reportYear,
        } as ProgramOutcomes;
    };

    useEffect(() => {
        const surveyYear = () => {
            if (settings.reportingWindow?.yearAvarageStart) {
                setSurveyYear(+settings.reportingWindow.yearAvarageStart);
            } else {
                setSurveyYear(+(reportYear || moment().year()) - 1);
            }
        };

        surveyYear();
    }, [settings.reportingWindow, reportYear, setSurveyYear]);

    // Get survey types
    useEffect(() => {
        const getSurveyTypes = () => {
            programService.getSurveyTypes().then((response) => setSurveyTypes(response));
        };

        getSurveyTypes();
    }, [setSurveyTypes]);

    const getSurveyTypeSatisfactionThreshold = (surveyTypeId: number): number => {
        if (surveyTypeId >= 6 && surveyTypeId <= 8) {
            return settings.reportingWindow.employeeSurveySatisfactionThreshold || 0;
        }

        return settings.reportingWindow.graduateSurveySatisfactionThreshold || 0;
    };

    useEffect(() => {
        const getOutcomes = () => {
            if (
                program.programId &&
                (surveyTypes?.length || 0) > 0 &&
                reportYear &&
                !surveyOutcomes &&
                reloadOutcomes
            ) {
                setReloadOutcomes(false);
                programService
                    .getProgramOutcomeDetail(program.programId, +reportYear)
                    .then((results) => {
                        const outcomes = [] as ProgramOutcomes[];
                        surveyTypes.forEach((st) => {
                            const result = results.find((r) => r.outcomeTypeId === st.surveyTypeId);

                            if (result) {
                                outcomes.push({
                                    ...result,
                                });
                            } else {
                                outcomes.push({
                                    detailId: 0,
                                    outcomeTypeId: st.surveyTypeId,
                                    programId: program.programId,
                                    reportYear: +reportYear,
                                    actionPlan: null,
                                    createdBy: null,
                                    detailedAnalysis: null,
                                    outcomeType: null,
                                    lastEdited: null,
                                    dateCreated: null,
                                    editedBy: null,
                                    oneYearAverage: null,
                                    threeYearAverage: null,
                                    reportWindowAverage: null,
                                    reportWindowEndYear: surveyYear || +reportYear - 1,
                                    reportWindowStartYear:
                                        (surveyYear || +reportYear - 1) -
                                        ((settings.reportingWindow.reportingWindow || 1) + 1),
                                    threshold: getSurveyTypeSatisfactionThreshold(st.surveyTypeId),
                                });
                            }
                        });

                        setSurveyOutcomes(outcomes);

                        const graduateSentReturned = results.find((o) => o.outcomeTypeId === 101);
                        const employerSentReturned = results.find((o) => o.outcomeTypeId === 100);

                        setGraduateSentReturnedOutcome(
                            graduateSentReturned || {
                                actionPlan: null,
                                createdBy: null,
                                dateCreated: null,
                                detailId: 0,
                                detailedAnalysis: null,
                                editedBy: null,
                                lastEdited: null,
                                outcomeType: null,
                                outcomeTypeId: 101,
                                programId: program.programId,
                                reportYear: +reportYear,
                                oneYearAverage: null,
                                threeYearAverage: null,
                                reportWindowAverage: null,
                                reportWindowEndYear: surveyYear || +reportYear - 1,
                                reportWindowStartYear:
                                    (surveyYear || +reportYear - 1) -
                                    ((settings.reportingWindow.reportingWindow || 1) + 1),
                                threshold: settings.reportingWindow.graduateSurveysThreshold,
                            },
                        );

                        setEmployerSentReturnedOutcome(
                            employerSentReturned || {
                                actionPlan: null,
                                createdBy: null,
                                dateCreated: null,
                                detailId: 0,
                                detailedAnalysis: null,
                                editedBy: null,
                                lastEdited: null,
                                outcomeType: null,
                                outcomeTypeId: 100,
                                programId: program.programId,
                                reportYear: +reportYear,
                                oneYearAverage: null,
                                threeYearAverage: null,
                                reportWindowAverage: null,
                                reportWindowEndYear: surveyYear || +reportYear - 1,
                                reportWindowStartYear:
                                    (surveyYear || +reportYear - 1) -
                                    ((settings.reportingWindow.reportingWindow || 1) + 1),
                                threshold: settings.reportingWindow.employerSurveysThreshold,
                            },
                        );
                    })
                    .catch(() => {
                        toast.error('Error loading survey outcomes');
                    });
            }
        };

        getOutcomes();
    }, [
        setSurveyOutcomes,
        program,
        surveyTypes,
        reportYear,
        surveyOutcomes,
        setGraduateSentReturnedOutcome,
        setEmployerSentReturnedOutcome,
        surveyYear,
        reloadOutcomes,
    ]);

    // Get results
    useEffect(() => {
        const getResults = () => {
            if (
                reportYear &&
                surveyTypes &&
                surveyTypes.length > 0 &&
                settings.reportingWindow &&
                worksheets &&
                surveyYear &&
                reloadResults
            ) {
                setReloadResults(false);
                programService
                    .getSurveyWorksheetResult(
                        program.programId,
                        surveyYear,
                        settings.reportingWindow.reportingWindow || 1,
                    )
                    .then((response) => {
                        if (response) {
                            surveyTypes.forEach((type) => {
                                if (
                                    response.filter(
                                        (r) => r.surveyTypeId === type.surveyTypeId && r.graduatingYear === surveyYear,
                                    ).length === 0
                                ) {
                                    let numQuestions = 0;

                                    // 0: { surveyTypeId: 6, title: "Employer Surveys - Cognitive", prefix: "EC" }
                                    // 1: { surveyTypeId: 7, title: "Employer Surveys - Psychomotor", prefix: "EP" }
                                    // 2: { surveyTypeId: 8, title: "Employer Surveys - Affective", prefix: "EA" }
                                    // 3: { surveyTypeId: 9, title: "Graduate Survey - Cognitive", prefix: "GC" }
                                    // 4: { surveyTypeId: 10, title: "Graduate Survey - Psychomotor", prefix: "GP" }
                                    // 5: { surveyTypeId: 11, title: "Graduate Survey - Affective", prefix: "GA" }

                                    if (type.surveyTypeId === 6) {
                                        // ES Cognitive
                                        numQuestions = settings.reportingWindow.escognitiveQuestions;
                                    } else if (type.surveyTypeId === 7) {
                                        // ES Psychomotor
                                        numQuestions = settings.reportingWindow.espsychomotorQuestions;
                                    } else if (type.surveyTypeId === 8) {
                                        // ES Affective
                                        numQuestions = settings.reportingWindow.esaffectiveQuestions;
                                    } else if (type.surveyTypeId === 9) {
                                        // GS - Cognitive
                                        numQuestions = settings.reportingWindow.gscognitiveQuestions;
                                    } else if (type.surveyTypeId === 10) {
                                        // GS - Psychomotor
                                        numQuestions = settings.reportingWindow.gspsychomotorQuestions;
                                    } else if (type.surveyTypeId === 11) {
                                        // GS - Affective
                                        numQuestions = settings.reportingWindow.gsaffectiveQuestions;
                                    }

                                    for (let i = 1; i <= numQuestions; i++) {
                                        response.push({
                                            graduatingYear: surveyYear,
                                            programId: program.programId,
                                            surveyTypeId: type.surveyTypeId,
                                            surveyType: type,
                                            resultId: 0,
                                            questionId: i,
                                            number5: 0,
                                            numberNa: 0,
                                            numberOmitted: 0,
                                            surveysReturned:
                                                type.surveyTypeId >= 6 && type.surveyTypeId <= 8
                                                    ? worksheets.find((w) => w.graduatingYear === surveyYear)
                                                          ?.employerSurveysReturned || 0
                                                    : worksheets.find((w) => w.graduatingYear === surveyYear)
                                                          ?.graduateSurveysReturned || 0,
                                        } as SurveyWorksheetResult);
                                    }
                                }
                            });
                            setSurveyResults(response);
                        }
                    });
            }
        };

        getResults();
    }, [worksheets, program.programId, reportYear, settings, setSurveyResults, surveyTypes, surveyYear, reloadResults]);

    // Get worksheet
    useEffect(() => {
        const getWorksheet = () => {
            if (program && surveyYear && reportYear && settings && reloadWorksheets) {
                setReloadWorksheets(false);
                programService
                    .getSurveyWorksheet(
                        program.programId,
                        surveyYear,
                        +reportYear,
                        settings.reportingWindow?.reportingWindow || 1,
                    )
                    .then((response) => {
                        setWorksheets(response);
                    });
            }
        };

        getWorksheet();
    }, [setWorksheets, program, reportYear, surveyYear, settings, reloadWorksheets]);

    const getWorksheetClass = (rate, threshold): string => {
        return rate >= threshold ? 'text-success' : 'text-danger';
    };

    const handleWorksheetUpdated = (updated: Survey) => {
        setReloadWorksheets(true);
        setReloadResults(true);
        toggleWorksheetModal();
    };

    const handleResultsUpdated = () => {
        setReloadResults(true);
        setSelectedResults(undefined);
        toggleResultsModal();
    };

    const selectSurveyType = (surveyType: SurveyType) => {
        setSelectedType(surveyType);
    };

    const getSurveyTypeCutScore = (surveyTypeId: number): number => {
        if (surveyTypeId >= 6 && surveyTypeId <= 8) {
            return +(settings?.reportingWindow?.employeeSurveyCutScore || 0);
        }

        return +(settings?.reportingWindow?.graduateSurveyCutScore || 0);
    };

    const getNumberAboveCutScore = (cutScore: number, surveyResult: SurveyWorksheetResult): number => {
        let total = 0;

        switch (cutScore) {
            case 0:
            case 1:
                total +=
                    (surveyResult.number1 || 0) +
                    (surveyResult.number2 || 0) +
                    (surveyResult.number3 || 0) +
                    (surveyResult.number4 || 0) +
                    (surveyResult.number5 || 0);
                break;
            case 2:
                total +=
                    (surveyResult.number2 || 0) +
                    (surveyResult.number3 || 0) +
                    (surveyResult.number4 || 0) +
                    (surveyResult.number5 || 0);
                break;
            case 3:
                total += (surveyResult.number3 || 0) + (surveyResult.number4 || 0) + (surveyResult.number5 || 0);
                break;
            case 4:
                total += (surveyResult.number4 || 0) + (surveyResult.number5 || 0);
                break;
            case 5:
                total += surveyResult.number5 || 0;
                break;
            default:
                total +=
                    (surveyResult.number1 || 0) +
                    (surveyResult.number2 || 0) +
                    (surveyResult.number3 || 0) +
                    (surveyResult.number4 || 0) +
                    (surveyResult.number5 || 0);
                break;
        }

        return total;
    };

    const selectSurveyResults = (year: number) => {
        if (selectedType) {
            let filteredResults = [
                ...surveyResults?.filter(
                    (sr) => sr.surveyTypeId === selectedType.surveyTypeId && sr.graduatingYear === year,
                ),
            ];

            if (filteredResults.length === 0) {
                let numQuestions = 0;

                // 0: { surveyTypeId: 6, title: "Employer Surveys - Cognitive", prefix: "EC" }
                // 1: { surveyTypeId: 7, title: "Employer Surveys - Psychomotor", prefix: "EP" }
                // 2: { surveyTypeId: 8, title: "Employer Surveys - Affective", prefix: "EA" }
                // 3: { surveyTypeId: 9, title: "Graduate Survey - Cognitive", prefix: "GC" }
                // 4: { surveyTypeId: 10, title: "Graduate Survey - Psychomotor", prefix: "GP" }
                // 5: { surveyTypeId: 11, title: "Graduate Survey - Affective", prefix: "GA" }

                if (selectedType.surveyTypeId === 6) {
                    // ES Cognitive
                    numQuestions = settings.reportingWindow.escognitiveQuestions;
                } else if (selectedType.surveyTypeId === 7) {
                    // ES Psychomotor
                    numQuestions = settings.reportingWindow.espsychomotorQuestions;
                } else if (selectedType.surveyTypeId === 8) {
                    // ES Affective
                    numQuestions = settings.reportingWindow.esaffectiveQuestions;
                } else if (selectedType.surveyTypeId === 9) {
                    // GS - Cognitive
                    numQuestions = settings.reportingWindow.gscognitiveQuestions;
                } else if (selectedType.surveyTypeId === 10) {
                    // GS - Psychomotor
                    numQuestions = settings.reportingWindow.gspsychomotorQuestions;
                } else if (selectedType.surveyTypeId === 11) {
                    // GS - Affective
                    numQuestions = settings.reportingWindow.gsaffectiveQuestions;
                }

                for (let i = 1; i <= numQuestions; i++) {
                    filteredResults.push({
                        graduatingYear: year,
                        programId: program.programId,
                        surveyTypeId: selectedType.surveyTypeId,
                        surveyType: selectedType,
                        resultId: 0,
                        questionId: i,
                        number5: 0,
                        number4: 0,
                        number3: 0,
                        number2: 0,
                        number1: 0,
                        numberNa: 0,
                        numberOmitted: 0,
                        surveysReturned:
                            selectedType.surveyTypeId >= 6 && selectedType.surveyTypeId <= 8
                                ? worksheets?.find((w) => w.graduatingYear === year)?.employerSurveysReturned || 0
                                : worksheets?.find((w) => w.graduatingYear === year)?.graduateSurveysReturned || 0,
                    } as SurveyWorksheetResult);
                }
            }

            if (
                year < (settings?.reportingWindow?.yearAvarageStart || +reportYear - 1) &&
                getSurveyTypeCutScore(selectedType.surveyTypeId) > 0
            ) {
                filteredResults = filteredResults.map((r) => {
                    return {
                        ...r,
                        number5: getNumberAboveCutScore(getSurveyTypeCutScore(selectedType.surveyTypeId), r),
                    } as SurveyWorksheetResult;
                });
            }

            setSelectedResults(filteredResults);
            setSelectedYear(year);
            toggleResultsModal();
        }
    };

    const getEachReportYear = (): number[] => {
        if (surveyYear) {
            const years = [] as number[];

            for (let i = surveyYear; i > surveyYear - (props.settings.reportingWindow.reportingWindow || 1); i--) {
                years.push(i);
            }

            return years;
        }

        return [(settings.reportingWindow?.activeYear || moment().year()) - 1];
    };

    const totalGraduates = (): number => {
        let graduates = 0;

        getEachReportYear().forEach(
            (year) => (graduates += worksheets?.find((w) => w.graduatingYear === year)?.totalGraduates || 0),
        );

        return graduates;
    };

    const totalEmployedGraduates = (): number => {
        let graduates = 0;

        getEachReportYear().forEach(
            (year) => (graduates += worksheets?.find((w) => w.graduatingYear === year)?.numberOfGradsEmployed || 0),
        );

        return graduates;
    };

    const totalGraduateSurveysSent = (): number => {
        let sent = 0;

        getEachReportYear().forEach(
            (year) => (sent += worksheets?.find((w) => w.graduatingYear === year)?.graduateSurveysSent || 0),
        );

        return sent;
    };

    const totalGraduateSurveysReturned = (): number => {
        let returned = 0;

        getEachReportYear().forEach(
            (year) => (returned += worksheets?.find((w) => w.graduatingYear === year)?.graduateSurveysReturned || 0),
        );

        return returned;
    };

    const totalEmployerSurveysSent = (): number => {
        let sent = 0;

        getEachReportYear().forEach(
            (year) => (sent += worksheets?.find((w) => w.graduatingYear === year)?.employerSurveysSent || 0),
        );

        return sent;
    };

    const totalEmployerSurveysReturned = (): number => {
        let returned = 0;

        getEachReportYear().forEach(
            (year) => (returned += worksheets?.find((w) => w.graduatingYear === year)?.employerSurveysReturned || 0),
        );

        return returned;
    };

    const getPositiveItems = (year: number, surveyTypeId: number): number | undefined => {
        const surveyTypeResults = surveyResults.filter(
            (sr) => sr.graduatingYear === year && sr.surveyTypeId === surveyTypeId && sr.resultId > 0,
        );

        if (surveyTypeResults.length > 0) {
            const cutScore = getSurveyTypeCutScore(surveyTypeId);
            const threshold = getSurveyTypeSatisfactionThreshold(surveyTypeId);

            let positiveItems = 0;

            surveyTypeResults.forEach((sr) => {
                const numberAboveCut = getNumberAboveCutScore(cutScore, sr);
                const numberNaOrOmitted = (sr.numberNa || 0) + (sr.numberOmitted || 0);
                const calculationResponses = (sr.surveysReturned || 0) - numberNaOrOmitted;

                if (calculationResponses > 0) {
                    if ((numberAboveCut / calculationResponses) * 100 >= threshold) {
                        positiveItems += 1;
                    }
                } else {
                    positiveItems += 0;
                }
            });

            return positiveItems;
        }

        return undefined;
    };

    const getNegativeItems = (year: number, surveyTypeId: number): number | undefined => {
        const surveyTypeResults = surveyResults.filter(
            (sr) => sr.graduatingYear === year && sr.surveyTypeId === surveyTypeId && sr.resultId > 0,
        );

        if (surveyTypeResults.length > 0) {
            const cutScore = getSurveyTypeCutScore(surveyTypeId);
            const threshold = getSurveyTypeSatisfactionThreshold(surveyTypeId);

            let negativeItems = 0;

            surveyTypeResults.forEach((sr) => {
                const numberAboveCut = getNumberAboveCutScore(cutScore, sr);
                const numberNaOrOmitted = (sr.numberNa || 0) + (sr.numberOmitted || 0);
                const calculationResponses = (sr.surveysReturned || 0) - numberNaOrOmitted;

                if (calculationResponses > 0) {
                    if ((numberAboveCut / calculationResponses) * 100 < threshold) {
                        negativeItems += 1;
                    }
                }
            });

            return negativeItems;
        }

        return undefined;
    };

    const getPositiveNegativeClassByYear = (year: number, surveyTypeId: number): string | undefined => {
        const negativeItems = getNegativeItems(year, surveyTypeId);

        return negativeItems !== undefined
            ? negativeItems > 0
                ? 'mdi mdi-close-box text-danger'
                : 'mdi mdi-checkbox-marked'
            : undefined;
    };

    const isMissingData = (surveyTypeId: number): boolean => {
        const filteredResults = surveyResults.filter(
            (sr) => sr.surveyTypeId === surveyTypeId && sr.graduatingYear === surveyYear,
        );

        if (filteredResults) {
            if (filteredResults.filter((r) => r.resultId === 0).length > 0) {
                return true;
            } else {
                return false;
            }
        }

        return false;
    };

    const setAnalysis = (outcome: ProgramOutcomes, value: string) => {
        const newOutcomes = [...(surveyOutcomes || [])];
        const findOutcome = newOutcomes.find((o) => o.outcomeTypeId === outcome.outcomeTypeId);

        if (findOutcome) {
            const selectedIndex = newOutcomes.indexOf(findOutcome);

            const newOutcome = {
                ...findOutcome,
                detailedAnalysis: value,
            };

            newOutcomes.splice(selectedIndex, 1, newOutcome);

            setSurveyOutcomes([...newOutcomes]);
        }
    };

    const setActionPlan = (outcome: ProgramOutcomes, value: string) => {
        const newOutcomes = [...(surveyOutcomes || [])];
        const findOutcome = newOutcomes.find((o) => o.outcomeTypeId === outcome.outcomeTypeId);

        if (findOutcome) {
            const selectedIndex = newOutcomes.indexOf(findOutcome);

            const newOutcome = {
                ...findOutcome,
                actionPlan: value,
            };

            newOutcomes.splice(selectedIndex, 1, newOutcome);

            setSurveyOutcomes([...newOutcomes]);
        }
    };

    const showGraduateSentReturned = (): boolean => {
        if (graduateSentReturnedOutcome) {
            return (
                (graduateSentReturnedOutcome.oneYearAverage || 0) < (graduateSentReturnedOutcome.threshold || 0) ||
                (graduateSentReturnedOutcome.reportWindowAverage || 0) < (graduateSentReturnedOutcome.threshold || 0)
            );
        }

        return false;
    };

    const showEmployerSentReturned = (): boolean => {
        if (employerSentReturnedOutcome) {
            return (
                (employerSentReturnedOutcome.oneYearAverage || 0) < (employerSentReturnedOutcome.threshold || 0) ||
                (employerSentReturnedOutcome.reportWindowAverage || 0) < (employerSentReturnedOutcome.threshold || 0)
            );
        }

        return false;
    };

    const handleValidate = (): boolean => {
        const isValid = true;

        return isValid;
    };

    const saveOutcomes = (validate: boolean) => {
        if (surveyOutcomes) {
            const isValid = handleValidate();

            if (validate && !isValid) {
                toast.error('Please complete all Detailed Analysis and Action Plans before continuing.');
                return;
            }

            setFormSubmitting(true);
            const toastId = toast.info('Saving survey outcomes...');

            programService
                .saveProgramOutcomeDetail([
                    ...surveyOutcomes,
                    graduateSentReturnedOutcome || initializeGraduateSentReturnedOutcome(),
                    employerSentReturnedOutcome || initializeEmployerSentReturnedOutcome(),
                ])
                .then((results) => {
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Outcome update successful.',
                    });

                    if (validate) {
                        onSavedSuccessfully(AnnualReportNavigationDirection.Forward);
                    }

                    setSurveyOutcomes(results);
                })
                .catch(() => {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Error saving outcomes',
                    });
                })
                .finally(() => {
                    setFormSubmitting(false);
                });
        }
    };

    useEffect(() => {
        const updateOutcomeAverages = () => {
            if (worksheets && employerSentReturnedOutcome && graduateSentReturnedOutcome && settings?.reportingWindow) {
                let totalGraduates = 0;
                let oneYearGraduates = 0;
                let threeYearGraduates = 0;
                let totalGraduateSurveysSent = 0;
                let totalGraduateSurveysReturned = 0;
                let oneYearGraduateSurveysSent = 0;
                let oneYearGraduateSurveysReturned = 0;
                let threeYearGraduateSurveysSent = 0;
                let threeYearGraduateSurveysReturned = 0;

                let totalEmployedGraduates = 0;
                let oneYearEmployedGraduates = 0;
                let threeYearEmployedGraduates = 0;
                let totalEmployerSurveysSent = 0;
                let totalEmployerSurveysReturned = 0;
                let oneYearEmployerSurveysSent = 0;
                let oneYearEmployerSurveysReturned = 0;
                let threeYearEmployerSurveysSent = 0;
                let threeYearEmployerSurveysReturned = 0;

                worksheets.forEach((w) => {
                    totalGraduates += w.totalGraduates;
                    totalGraduateSurveysSent += w.graduateSurveysSent;
                    totalGraduateSurveysReturned += w.graduateSurveysReturned;

                    totalEmployedGraduates += w.numberOfGradsEmployed;
                    totalEmployerSurveysSent += w.employerSurveysSent;
                    totalEmployerSurveysReturned += w.employerSurveysReturned;

                    if (w.graduatingYear === surveyYear) {
                        oneYearGraduates += w.totalGraduates;
                        oneYearGraduateSurveysSent += w.graduateSurveysSent;
                        oneYearGraduateSurveysReturned += w.graduateSurveysReturned;

                        oneYearEmployedGraduates += w.numberOfGradsEmployed;
                        oneYearEmployerSurveysSent += w.employerSurveysSent;
                        oneYearEmployerSurveysReturned += w.employerSurveysReturned;
                    }

                    if (
                        w.graduatingYear <= (surveyYear || new Date().getFullYear()) &&
                        w.graduatingYear >= (surveyYear || new Date().getFullYear()) - 2
                    ) {
                        threeYearGraduates += w.totalGraduates;
                        threeYearGraduateSurveysSent += w.graduateSurveysSent;
                        threeYearGraduateSurveysReturned += w.graduateSurveysReturned;

                        threeYearEmployedGraduates += w.numberOfGradsEmployed;
                        threeYearEmployerSurveysSent += w.employerSurveysSent;
                        threeYearEmployerSurveysReturned += w.employerSurveysReturned;
                    }
                });

                if (settings.reportingWindow.graduateSurveyMeasureType?.toUpperCase() === 'SENT') {
                    setGraduateSentReturnedOutcome({
                        ...graduateSentReturnedOutcome,
                        oneYearAverage:
                            oneYearGraduates > 0 ? (oneYearGraduateSurveysSent / oneYearGraduates) * 100 : null,
                        threeYearAverage:
                            threeYearGraduates > 0 ? (threeYearGraduateSurveysSent / threeYearGraduates) * 100 : null,
                        reportWindowAverage: totalGraduates ? (totalGraduateSurveysSent / totalGraduates) * 100 : null,
                        threshold: settings.reportingWindow.graduateSurveysThreshold,
                    });
                } else {
                    setGraduateSentReturnedOutcome({
                        ...graduateSentReturnedOutcome,
                        oneYearAverage:
                            oneYearGraduates > 0 ? (oneYearGraduateSurveysReturned / oneYearGraduates) * 100 : null,
                        threeYearAverage:
                            threeYearGraduates > 0
                                ? (threeYearGraduateSurveysReturned / threeYearGraduates) * 100
                                : null,
                        reportWindowAverage: totalGraduates
                            ? (totalGraduateSurveysReturned / totalGraduates) * 100
                            : null,
                        threshold: settings.reportingWindow.graduateSurveysThreshold,
                    });
                }

                if (settings.reportingWindow.employeeSurveyMeasureType?.toUpperCase() === 'SENT') {
                    setEmployerSentReturnedOutcome({
                        ...employerSentReturnedOutcome,
                        oneYearAverage:
                            oneYearEmployedGraduates > 0
                                ? (oneYearEmployerSurveysSent / oneYearEmployedGraduates) * 100
                                : null,
                        threeYearAverage:
                            threeYearEmployedGraduates > 0
                                ? (threeYearEmployerSurveysSent / threeYearEmployedGraduates) * 100
                                : null,
                        reportWindowAverage: totalEmployedGraduates
                            ? (totalEmployerSurveysSent / totalEmployedGraduates) * 100
                            : null,
                        threshold: settings.reportingWindow.employerSurveysThreshold,
                    });
                } else {
                    setEmployerSentReturnedOutcome({
                        ...employerSentReturnedOutcome,
                        oneYearAverage:
                            oneYearEmployedGraduates > 0
                                ? (oneYearEmployerSurveysReturned / oneYearEmployedGraduates) * 100
                                : null,
                        threeYearAverage:
                            threeYearEmployedGraduates > 0
                                ? (threeYearEmployerSurveysReturned / threeYearEmployedGraduates) * 100
                                : null,
                        reportWindowAverage: totalEmployedGraduates
                            ? (totalEmployerSurveysReturned / totalEmployedGraduates) * 100
                            : null,
                        threshold: settings.reportingWindow.employerSurveysThreshold,
                    });
                }
            }
        };

        updateOutcomeAverages();
    }, [worksheets, settings]);

    const startWorksheetEdit = (year: number) => {
        const worksheet = worksheets?.find((w) => w.graduatingYear === year);

        if (worksheet) {
            setEditWorksheet({ ...worksheet });
            toggleWorksheetModal();
        } else {
            toast.error('Survey worksheet not found, please save and reload the page.');
        }
    };

    return (
        <>
            <Card>
                <CardBody>
                    {worksheets && worksheets.length > 0 && (
                        <>
                            <h4 className="card-title">
                                <span>{`Survey Worksheet`}</span>
                            </h4>
                            <Card>
                                <CardBody>
                                    <h5 className={'card-title'}>{`Graduate Surveys Sent / Returned`}</h5>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>{`Graduation Year`}</th>
                                                {getEachReportYear().map((year) => (
                                                    <th key={year} className={'text-center'}>
                                                        {year}
                                                    </th>
                                                ))}
                                            </tr>
                                            <tr>
                                                <th></th>
                                                {getEachReportYear().map((year) => (
                                                    <th key={year} className={'text-center'}>
                                                        {canEdit && (
                                                            <Button
                                                                type={'button'}
                                                                color={'primary'}
                                                                onClick={() => startWorksheetEdit(year)}
                                                            >{`Edit`}</Button>
                                                        )}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{`Total Graduates`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year} className={'text-center'}>
                                                        {
                                                            worksheets?.find((w) => w.graduatingYear === year)
                                                                ?.totalGraduates
                                                        }
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`Graduate Surveys Sent`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year} className={'text-center'}>
                                                        {
                                                            worksheets?.find((w) => w.graduatingYear === year)
                                                                ?.graduateSurveysSent
                                                        }
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`Graduate Surveys Returned`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year} className={'text-center'}>
                                                        {
                                                            worksheets?.find((w) => w.graduatingYear === year)
                                                                ?.graduateSurveysReturned
                                                        }
                                                    </td>
                                                ))}
                                            </tr>
                                            {settings?.reportingWindow?.graduateSurveyMeasureType.toUpperCase() ===
                                                'SENT' && (
                                                <tr>
                                                    <td>{`Graduate Survey Sent Rate (${settings?.reportingWindow?.graduateSurveysThreshold}% Threshold)`}</td>
                                                    {getEachReportYear().map((year) => (
                                                        <td
                                                            key={year}
                                                            className={`text-center font-weight-bold ${getWorksheetClass(
                                                                worksheets?.find((w) => w.graduatingYear === year)
                                                                    ?.graduateSurveysSentRate || 0,
                                                                settings.reportingWindow.graduateSurveysThreshold || 0,
                                                            )}`}
                                                        >
                                                            {`${
                                                                worksheets
                                                                    ?.find((w) => w.graduatingYear === year)
                                                                    ?.graduateSurveysSentRate?.toFixed(2) || 0
                                                            }%`}
                                                        </td>
                                                    ))}
                                                </tr>
                                            )}
                                            {settings?.reportingWindow?.graduateSurveyMeasureType.toUpperCase() !==
                                                'SENT' && (
                                                <tr>
                                                    <td>{`Graduate Survey Returned Rate (${settings?.reportingWindow?.graduateSurveysThreshold}% Threshold)`}</td>
                                                    {getEachReportYear().map((year) => (
                                                        <td
                                                            key={year}
                                                            className={`text-center font-weight-bold ${getWorksheetClass(
                                                                worksheets?.find((w) => w.graduatingYear === year)
                                                                    ?.graduateSurveysReturnRate || 0,
                                                                settings.reportingWindow.graduateSurveysThreshold || 0,
                                                            )}`}
                                                        >
                                                            {`${
                                                                worksheets
                                                                    ?.find((w) => w.graduatingYear === year)
                                                                    ?.graduateSurveysReturnRate?.toFixed(2) || 0
                                                            }%`}
                                                        </td>
                                                    ))}
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>

                                    {graduateSentReturnedOutcome && settings.reportingWindow && (
                                        <Row className={'mb-3'}>
                                            <Col className={'d-flex justify-content-center justify-content-around'}>
                                                <div>
                                                    <h4 className={`text-center`}>{totalGraduates()}</h4>
                                                    <span>{`Total Graduates`}</span>
                                                </div>
                                                {settings.reportingWindow.graduateSurveyMeasureType?.toUpperCase() ===
                                                    'SENT' && (
                                                    <div>
                                                        <h4 className={`text-center`}>{totalGraduateSurveysSent()}</h4>
                                                        <span>{`Surveys Sent`}</span>
                                                    </div>
                                                )}
                                                {settings.reportingWindow.graduateSurveyMeasureType?.toUpperCase() !==
                                                    'SENT' && (
                                                    <div>
                                                        <h4 className={`text-center`}>
                                                            {totalGraduateSurveysReturned()}
                                                        </h4>
                                                        <span>{`Surveys Returned`}</span>
                                                    </div>
                                                )}
                                                <div>
                                                    <h4
                                                        className={`text-center`}
                                                    >{`${settings.reportingWindow.graduateSurveysThreshold}%`}</h4>
                                                    <span>{`Threshold`}</span>
                                                </div>
                                                {(settings?.reportingWindow?.reportingWindow || 1) >= 5 && (
                                                    <div
                                                        className={`${
                                                            (graduateSentReturnedOutcome.threeYearAverage || 0) >=
                                                            (graduateSentReturnedOutcome.threshold || 0)
                                                                ? 'text-success'
                                                                : 'text-danger'
                                                        }`}
                                                    >
                                                        <h4
                                                            className={`text-center ${
                                                                (graduateSentReturnedOutcome.threeYearAverage || 0) >=
                                                                (graduateSentReturnedOutcome.threshold || 0)
                                                                    ? 'text-success'
                                                                    : 'text-danger'
                                                            }`}
                                                        >{`${(
                                                            graduateSentReturnedOutcome.threeYearAverage || 0
                                                        )?.toFixed(2)}%`}</h4>
                                                        <span>{`3-Year Average`}</span>
                                                    </div>
                                                )}
                                                <div
                                                    className={`${
                                                        (graduateSentReturnedOutcome.reportWindowAverage || 0) >=
                                                        (graduateSentReturnedOutcome.threshold || 0)
                                                            ? 'text-success'
                                                            : 'text-danger'
                                                    }`}
                                                >
                                                    <h4
                                                        className={`text-center ${
                                                            (graduateSentReturnedOutcome.reportWindowAverage || 0) >=
                                                            (graduateSentReturnedOutcome.threshold || 0)
                                                                ? 'text-success'
                                                                : 'text-danger'
                                                        }`}
                                                    >{`${(
                                                        graduateSentReturnedOutcome.reportWindowAverage || 0
                                                    )?.toFixed(2)}%`}</h4>
                                                    <span>{`${
                                                        settings?.reportingWindow?.reportingWindow || 1
                                                    }-Year Average`}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    )}

                                    {showGraduateSentReturned() && graduateSentReturnedOutcome && (
                                        <Row className={'mb-2'}>
                                            <Col className={'d-flex justify-content-between'}>
                                                <FormGroup style={{ flexBasis: 'calc(50% - 10px)' }}>
                                                    <Label>{`Detailed Analysis`}</Label>
                                                    <Input
                                                        type={'textarea'}
                                                        rows={10}
                                                        value={graduateSentReturnedOutcome.detailedAnalysis || ''}
                                                        onChange={(e) =>
                                                            setGraduateSentReturnedOutcome({
                                                                ...graduateSentReturnedOutcome,
                                                                detailedAnalysis: e.target.value,
                                                            })
                                                        }
                                                    />
                                                </FormGroup>
                                                <FormGroup style={{ flexBasis: 'calc(50% - 10px)' }}>
                                                    <Label>{`Action Plan`}</Label>
                                                    <Input
                                                        type={'textarea'}
                                                        rows={10}
                                                        value={graduateSentReturnedOutcome?.actionPlan || ''}
                                                        onChange={(e) =>
                                                            setGraduateSentReturnedOutcome({
                                                                ...graduateSentReturnedOutcome,
                                                                actionPlan: e.target.value,
                                                            })
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    )}

                                    <h5 className={'card-title'}>{`Employer Surveys Sent / Returned`}</h5>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>{`Graduation Year`}</th>
                                                {getEachReportYear().map((year) => (
                                                    <th key={year} className={'text-center'}>
                                                        {year}
                                                    </th>
                                                ))}
                                            </tr>
                                            <tr>
                                                <th></th>
                                                {getEachReportYear().map((year) => (
                                                    <th key={year} className={'text-center'}>
                                                        {canEdit && (
                                                            <Button
                                                                type={'button'}
                                                                color={'primary'}
                                                                onClick={() => startWorksheetEdit(year)}
                                                            >{`Edit`}</Button>
                                                        )}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{`Total Employed Graduates`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year} className={'text-center'}>
                                                        {
                                                            worksheets?.find((w) => w.graduatingYear === year)
                                                                ?.numberOfGradsEmployed
                                                        }
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`Employer Surveys Sent`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year} className={`text-center`}>
                                                        {
                                                            worksheets?.find((w) => w.graduatingYear === year)
                                                                ?.employerSurveysSent
                                                        }
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{`Employer Surveys Returned`}</td>
                                                {getEachReportYear().map((year) => (
                                                    <td key={year} className={`text-center`}>
                                                        {
                                                            worksheets?.find((w) => w.graduatingYear === year)
                                                                ?.employerSurveysReturned
                                                        }
                                                    </td>
                                                ))}
                                            </tr>
                                            {settings?.reportingWindow?.employeeSurveyMeasureType.toUpperCase() ===
                                                'SENT' && (
                                                <tr>
                                                    <td>{`Employer Survey Sent Rate (${settings?.reportingWindow?.employerSurveysThreshold}% Threshold)`}</td>
                                                    {getEachReportYear().map((year) => (
                                                        <td
                                                            key={year}
                                                            className={`text-center font-weight-bold ${getWorksheetClass(
                                                                worksheets?.find((w) => w.graduatingYear === year)
                                                                    ?.employerSurveysSentRate || 0,
                                                                settings.reportingWindow.employerSurveysThreshold || 0,
                                                            )}`}
                                                        >
                                                            {`${
                                                                worksheets
                                                                    ?.find((w) => w.graduatingYear === year)
                                                                    ?.employerSurveysSentRate?.toFixed(2) || 0
                                                            }%`}
                                                        </td>
                                                    ))}
                                                </tr>
                                            )}
                                            {settings?.reportingWindow?.employeeSurveyMeasureType.toUpperCase() !==
                                                'SENT' && (
                                                <tr>
                                                    <td>{`Employer Survey Returned Rate (${settings?.reportingWindow?.employerSurveysThreshold}% Threshold)`}</td>
                                                    {getEachReportYear().map((year) => (
                                                        <td
                                                            key={year}
                                                            className={`text-center font-weight-bold ${getWorksheetClass(
                                                                worksheets?.find((w) => w.graduatingYear === year)
                                                                    ?.employerSurveysReturnRate || 0,
                                                                settings.reportingWindow.employerSurveysThreshold || 0,
                                                            )}`}
                                                        >
                                                            {`${
                                                                worksheets
                                                                    ?.find((w) => w.graduatingYear === year)
                                                                    ?.employerSurveysReturnRate?.toFixed(2) || 0
                                                            }%`}
                                                        </td>
                                                    ))}
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>

                                    {employerSentReturnedOutcome && settings.reportingWindow && (
                                        <Row className={'mb-3'}>
                                            <Col className={'d-flex justify-content-center justify-content-around'}>
                                                <div>
                                                    <h4 className={`text-center`}>{totalEmployedGraduates()}</h4>
                                                    <span>{`Total Employed Graduates`}</span>
                                                </div>
                                                {settings.reportingWindow.graduateSurveyMeasureType?.toUpperCase() ===
                                                    'SENT' && (
                                                    <div>
                                                        <h4 className={`text-center`}>{totalEmployerSurveysSent()}</h4>
                                                        <span>{`Surveys Sent`}</span>
                                                    </div>
                                                )}
                                                {settings.reportingWindow.graduateSurveyMeasureType?.toUpperCase() !==
                                                    'SENT' && (
                                                    <div>
                                                        <h4 className={`text-center`}>
                                                            {totalEmployerSurveysReturned()}
                                                        </h4>
                                                        <span>{`Surveys Returned`}</span>
                                                    </div>
                                                )}
                                                <div>
                                                    <h4
                                                        className={`text-center`}
                                                    >{`${settings.reportingWindow.graduateSurveysThreshold}%`}</h4>
                                                    <span>{`Threshold`}</span>
                                                </div>
                                                {(settings?.reportingWindow?.reportingWindow || 1) >= 5 && (
                                                    <div
                                                        className={`${
                                                            (employerSentReturnedOutcome.threeYearAverage || 0) >=
                                                            (employerSentReturnedOutcome.threshold || 0)
                                                                ? 'text-success'
                                                                : 'text-danger'
                                                        }`}
                                                    >
                                                        <h4
                                                            className={`text-center ${
                                                                (employerSentReturnedOutcome.threeYearAverage || 0) >=
                                                                (employerSentReturnedOutcome.threshold || 0)
                                                                    ? 'text-success'
                                                                    : 'text-danger'
                                                            }`}
                                                        >{`${(
                                                            employerSentReturnedOutcome.threeYearAverage || 0
                                                        )?.toFixed(2)}%`}</h4>
                                                        <span>{`3-Year Average`}</span>
                                                    </div>
                                                )}
                                                <div
                                                    className={`${
                                                        (employerSentReturnedOutcome.reportWindowAverage || 0) >=
                                                        (employerSentReturnedOutcome.threshold || 0)
                                                            ? 'text-success'
                                                            : 'text-danger'
                                                    }`}
                                                >
                                                    <h4
                                                        className={`text-center ${
                                                            (employerSentReturnedOutcome.reportWindowAverage || 0) >=
                                                            (employerSentReturnedOutcome.threshold || 0)
                                                                ? 'text-success'
                                                                : 'text-danger'
                                                        }`}
                                                    >{`${(
                                                        employerSentReturnedOutcome.reportWindowAverage || 0
                                                    )?.toFixed(2)}%`}</h4>
                                                    <span>{`${
                                                        settings?.reportingWindow?.reportingWindow || 1
                                                    }-Year Average`}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    )}

                                    {showEmployerSentReturned() && employerSentReturnedOutcome && (
                                        <Row className={'mb-2'}>
                                            <Col className={'d-flex justify-content-between'}>
                                                <FormGroup style={{ flexBasis: 'calc(50% - 10px)' }}>
                                                    <Label>{`Detailed Analysis`}</Label>
                                                    <Input
                                                        type={'textarea'}
                                                        rows={10}
                                                        value={employerSentReturnedOutcome.detailedAnalysis || ''}
                                                        onChange={(e) =>
                                                            setEmployerSentReturnedOutcome({
                                                                ...employerSentReturnedOutcome,
                                                                detailedAnalysis: e.target.value,
                                                            })
                                                        }
                                                    />
                                                </FormGroup>
                                                <FormGroup style={{ flexBasis: 'calc(50% - 10px)' }}>
                                                    <Label>{`Action Plan`}</Label>
                                                    <Input
                                                        type={'textarea'}
                                                        rows={10}
                                                        value={employerSentReturnedOutcome?.actionPlan || ''}
                                                        onChange={(e) =>
                                                            setEmployerSentReturnedOutcome({
                                                                ...employerSentReturnedOutcome,
                                                                actionPlan: e.target.value,
                                                            })
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    )}
                                </CardBody>
                            </Card>
                        </>
                    )}

                    {worksheets && (
                        <>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">Graduate Survey Results</h4>

                                    {surveyTypes && (
                                        <Row>
                                            <Col>
                                                {surveyTypes
                                                    .filter((st) => st.surveyTypeId >= 9 && st.surveyTypeId <= 11)
                                                    .map((st) => (
                                                        <ListGroupItem
                                                            className="justify-content-between p-2"
                                                            key={st.surveyTypeId}
                                                        >
                                                            <div
                                                                className={
                                                                    'd-flex align-items-center justify-content-between'
                                                                }
                                                            >
                                                                <div>
                                                                    <Label>{st.title}</Label>
                                                                    {isMissingData(st.surveyTypeId) && (
                                                                        <Badge className={'ml-3 p-1'} color={'danger'}>
                                                                            {'Responses Incomplete'}
                                                                        </Badge>
                                                                    )}
                                                                </div>
                                                                <Button
                                                                    type={'button'}
                                                                    color={'info'}
                                                                    onClick={() => selectSurveyType(st)}
                                                                >{`Edit`}</Button>
                                                            </div>
                                                            <Collapse
                                                                isOpen={selectedType?.surveyTypeId === st.surveyTypeId}
                                                            >
                                                                {selectedType?.surveyTypeId === st.surveyTypeId && (
                                                                    <Table className={'mt-2'} responsive>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>{`Graduation Year`}</th>
                                                                                {getEachReportYear().map((year) => (
                                                                                    <th
                                                                                        key={year}
                                                                                        className={'text-center'}
                                                                                    >
                                                                                        {year}
                                                                                    </th>
                                                                                ))}
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    {`Postive / Negative Indicator`}
                                                                                </td>
                                                                                {getEachReportYear().map((year) => (
                                                                                    <td
                                                                                        key={year}
                                                                                        className={'text-center'}
                                                                                    >
                                                                                        <i
                                                                                            style={{
                                                                                                fontSize: '18px',
                                                                                            }}
                                                                                            className={getPositiveNegativeClassByYear(
                                                                                                year,
                                                                                                st.surveyTypeId,
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                ))}
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    {`# Positive Items (>= ${getSurveyTypeSatisfactionThreshold(
                                                                                        st.surveyTypeId,
                                                                                    )}%)`}
                                                                                </td>
                                                                                {getEachReportYear().map((year) => (
                                                                                    <td
                                                                                        key={year}
                                                                                        className={'text-center'}
                                                                                    >
                                                                                        {getPositiveItems(
                                                                                            year,
                                                                                            st.surveyTypeId,
                                                                                        )}
                                                                                    </td>
                                                                                ))}
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    {`# Negative Items (< ${getSurveyTypeSatisfactionThreshold(
                                                                                        st.surveyTypeId,
                                                                                    )}%)`}
                                                                                </td>
                                                                                {getEachReportYear().map((year) => (
                                                                                    <td
                                                                                        key={year}
                                                                                        className={'text-center'}
                                                                                    >
                                                                                        {getNegativeItems(
                                                                                            year,
                                                                                            st.surveyTypeId,
                                                                                        )}
                                                                                    </td>
                                                                                ))}
                                                                            </tr>
                                                                            <tr>
                                                                                <td></td>
                                                                                {getEachReportYear().map((year) => (
                                                                                    <td
                                                                                        key={year}
                                                                                        className={'text-center'}
                                                                                    >
                                                                                        {year === surveyYear && (
                                                                                            <Button
                                                                                                type={'button'}
                                                                                                color={'info'}
                                                                                                onClick={() =>
                                                                                                    selectSurveyResults(
                                                                                                        year,
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                {`Edit`}
                                                                                            </Button>
                                                                                        )}
                                                                                        {year !== surveyYear && (
                                                                                            <Button
                                                                                                type={'button'}
                                                                                                color={'info'}
                                                                                                onClick={() =>
                                                                                                    selectSurveyResults(
                                                                                                        year,
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                {`Edit`}
                                                                                            </Button>
                                                                                        )}
                                                                                    </td>
                                                                                ))}
                                                                            </tr>
                                                                        </tbody>
                                                                    </Table>
                                                                )}

                                                                <Row>
                                                                    <Col className={'d-flex justify-content-between'}>
                                                                        <div
                                                                            style={{
                                                                                flexBasis: 'calc(50% - 10px)',
                                                                            }}
                                                                        >
                                                                            <Label>{`Detailed Analysis`}</Label>
                                                                            <Input
                                                                                type={'textarea'}
                                                                                rows={10}
                                                                                value={
                                                                                    surveyOutcomes?.find(
                                                                                        (o) =>
                                                                                            o.outcomeTypeId ===
                                                                                            st.surveyTypeId,
                                                                                    )?.detailedAnalysis
                                                                                }
                                                                                style={{
                                                                                    flexBasis: 'calc(50% - 10%)',
                                                                                }}
                                                                                onChange={(e) =>
                                                                                    setAnalysis(
                                                                                        surveyOutcomes?.find(
                                                                                            (o) =>
                                                                                                o.outcomeTypeId ===
                                                                                                st.surveyTypeId,
                                                                                        )!,
                                                                                        e.target.value,
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                flexBasis: 'calc(50% - 10px)',
                                                                            }}
                                                                        >
                                                                            <Label>{`Action Plan`}</Label>
                                                                            <Input
                                                                                type={'textarea'}
                                                                                rows={10}
                                                                                value={
                                                                                    surveyOutcomes?.find(
                                                                                        (o) =>
                                                                                            o.outcomeTypeId ===
                                                                                            st.surveyTypeId,
                                                                                    )?.actionPlan
                                                                                }
                                                                                style={{
                                                                                    flexBasis: 'calc(50% - 10%)',
                                                                                }}
                                                                                onChange={(e) =>
                                                                                    setActionPlan(
                                                                                        surveyOutcomes?.find(
                                                                                            (o) =>
                                                                                                o.outcomeTypeId ===
                                                                                                st.surveyTypeId,
                                                                                        )!,
                                                                                        e.target.value,
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Collapse>
                                                        </ListGroupItem>
                                                    ))}
                                            </Col>
                                        </Row>
                                    )}
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">Employer Survey Results</h4>

                                    {surveyTypes && (
                                        <Row>
                                            <Col>
                                                {surveyTypes
                                                    .filter((st) => st.surveyTypeId >= 6 && st.surveyTypeId <= 8)
                                                    .map((st) => (
                                                        <ListGroupItem
                                                            className="justify-content-between p-2"
                                                            key={st.surveyTypeId}
                                                        >
                                                            <div
                                                                className={
                                                                    'd-flex align-items-center justify-content-between'
                                                                }
                                                            >
                                                                <div>
                                                                    <Label>{st.title}</Label>
                                                                    {isMissingData(st.surveyTypeId) && (
                                                                        <Badge className={'ml-3 p-1'} color={'danger'}>
                                                                            {'Responses Incomplete'}
                                                                        </Badge>
                                                                    )}
                                                                </div>
                                                                <Button
                                                                    type={'button'}
                                                                    color={'info'}
                                                                    onClick={() => selectSurveyType(st)}
                                                                >{`Edit`}</Button>
                                                            </div>
                                                            <Collapse
                                                                isOpen={selectedType?.surveyTypeId === st.surveyTypeId}
                                                            >
                                                                {selectedType?.surveyTypeId === st.surveyTypeId && (
                                                                    <Table className={'mt-2'} responsive>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>{`Graduation Year`}</th>
                                                                                {getEachReportYear().map((year) => (
                                                                                    <th
                                                                                        key={year}
                                                                                        className={'text-center'}
                                                                                    >
                                                                                        {year}
                                                                                    </th>
                                                                                ))}
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    {`Postive / Negative Indicator`}
                                                                                </td>
                                                                                {getEachReportYear().map((year) => (
                                                                                    <td
                                                                                        key={year}
                                                                                        className={'text-center'}
                                                                                    >
                                                                                        <i
                                                                                            style={{
                                                                                                fontSize: '18px',
                                                                                            }}
                                                                                            className={getPositiveNegativeClassByYear(
                                                                                                year,
                                                                                                st.surveyTypeId,
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                ))}
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    {`# Positive Items (>= ${getSurveyTypeSatisfactionThreshold(
                                                                                        st.surveyTypeId,
                                                                                    )}%)`}
                                                                                </td>
                                                                                {getEachReportYear().map((year) => (
                                                                                    <td
                                                                                        key={year}
                                                                                        className={'text-center'}
                                                                                    >
                                                                                        {getPositiveItems(
                                                                                            year,
                                                                                            st.surveyTypeId,
                                                                                        )}
                                                                                    </td>
                                                                                ))}
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    {`# Negative Items (< ${getSurveyTypeSatisfactionThreshold(
                                                                                        st.surveyTypeId,
                                                                                    )}%)`}
                                                                                </td>
                                                                                {getEachReportYear().map((year) => (
                                                                                    <td
                                                                                        key={year}
                                                                                        className={'text-center'}
                                                                                    >
                                                                                        {getNegativeItems(
                                                                                            year,
                                                                                            st.surveyTypeId,
                                                                                        )}
                                                                                    </td>
                                                                                ))}
                                                                            </tr>
                                                                            <tr>
                                                                                <td></td>
                                                                                {getEachReportYear().map((year) => (
                                                                                    <td
                                                                                        key={year}
                                                                                        className={'text-center'}
                                                                                    >
                                                                                        {year === surveyYear && (
                                                                                            <Button
                                                                                                type={'button'}
                                                                                                color={'info'}
                                                                                                onClick={() =>
                                                                                                    selectSurveyResults(
                                                                                                        year,
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                {`Edit`}
                                                                                            </Button>
                                                                                        )}
                                                                                        {year !== surveyYear && (
                                                                                            <Button
                                                                                                type={'button'}
                                                                                                color={'info'}
                                                                                                onClick={() =>
                                                                                                    selectSurveyResults(
                                                                                                        year,
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                {`Edit`}
                                                                                            </Button>
                                                                                        )}
                                                                                    </td>
                                                                                ))}
                                                                            </tr>
                                                                        </tbody>
                                                                    </Table>
                                                                )}

                                                                <Row>
                                                                    <Col className={'d-flex justify-content-between'}>
                                                                        <div
                                                                            style={{
                                                                                flexBasis: 'calc(50% - 10px)',
                                                                            }}
                                                                        >
                                                                            <Label>{`Detailed Analysis`}</Label>
                                                                            <Input
                                                                                type={'textarea'}
                                                                                rows={10}
                                                                                value={
                                                                                    surveyOutcomes?.find(
                                                                                        (o) =>
                                                                                            o.outcomeTypeId ===
                                                                                            st.surveyTypeId,
                                                                                    )?.detailedAnalysis
                                                                                }
                                                                                style={{
                                                                                    flexBasis: 'calc(50% - 10%)',
                                                                                }}
                                                                                onChange={(e) =>
                                                                                    setAnalysis(
                                                                                        surveyOutcomes?.find(
                                                                                            (o) =>
                                                                                                o.outcomeTypeId ===
                                                                                                st.surveyTypeId,
                                                                                        )!,
                                                                                        e.target.value,
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                flexBasis: 'calc(50% - 10px)',
                                                                            }}
                                                                        >
                                                                            <Label>{`Action Plan`}</Label>
                                                                            <Input
                                                                                type={'textarea'}
                                                                                rows={10}
                                                                                value={
                                                                                    surveyOutcomes?.find(
                                                                                        (o) =>
                                                                                            o.outcomeTypeId ===
                                                                                            st.surveyTypeId,
                                                                                    )?.actionPlan
                                                                                }
                                                                                style={{
                                                                                    flexBasis: 'calc(50% - 10%)',
                                                                                }}
                                                                                onChange={(e) =>
                                                                                    setActionPlan(
                                                                                        surveyOutcomes?.find(
                                                                                            (o) =>
                                                                                                o.outcomeTypeId ===
                                                                                                st.surveyTypeId,
                                                                                        )!,
                                                                                        e.target.value,
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Collapse>
                                                        </ListGroupItem>
                                                    ))}
                                            </Col>
                                        </Row>
                                    )}
                                </CardBody>
                            </Card>
                        </>
                    )}
                </CardBody>
                {canEdit && (
                    <CardFooter className={'d-flex justify-content-between'}>
                        <Button
                            style={{ zIndex: 1 }}
                            type={'button'}
                            className={'btn btn-warning'}
                            onClick={() => onNavigate(AnnualReportNavigationDirection.Back)}
                        >
                            <i className={'mdi mdi-arrow-left'} />
                            <span className={'mr-1'}>{`Previous`}</span>
                        </Button>

                        <Button
                            disabled={formSubmitting}
                            style={{ zIndex: 1 }}
                            className={'btn btn-primary'}
                            onClick={() => saveOutcomes(false)}
                        >
                            <i className={'mdi mdi-content-save'} />
                            <span className={'ml-1'}>{`Save`}</span>
                        </Button>

                        <Button
                            disabled={formSubmitting}
                            style={{ zIndex: 1 }}
                            className={'btn btn-info'}
                            onClick={() => saveOutcomes(true)}
                        >
                            <span className={'mr-1'}>{`Save & Continue`}</span>
                            <i className={'mdi mdi-arrow-right'} />
                        </Button>
                    </CardFooter>
                )}
            </Card>
            {editWorksheet && (
                <Modal isOpen={showWorksheetModal} toggle={toggleWorksheetModal} centered={true} size={'lg'}>
                    <ModalHeader>{`${editWorksheet.graduatingYear} Graduate & Employer Surveys Sent & Returned`}</ModalHeader>
                    <ModalBody>
                        <SurveyWorksheetForm
                            worksheet={editWorksheet}
                            settings={settings}
                            onWorksheetUpdated={handleWorksheetUpdated}
                            onCancel={toggleWorksheetModal}
                        />
                    </ModalBody>
                </Modal>
            )}
            <Modal isOpen={showResultsModal} toggle={toggleResultsModal} centered={true} size={'lg'}>
                <ModalHeader>{selectedType?.title}</ModalHeader>
                <ModalBody>
                    {selectedType && selectedResults && selectedYear && settings && (
                        <SurveyWorksheetResultsForm
                            settings={settings}
                            onResultsUpdated={handleResultsUpdated}
                            onCancel={toggleResultsModal}
                            surveyResults={selectedResults}
                            surveyType={selectedType}
                            selectedYear={selectedYear}
                        />
                    )}
                </ModalBody>
            </Modal>
        </>
    );
};

export default SurveyWorksheet;
