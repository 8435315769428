import { AmsPermissionClaimType, authService } from '../../../services/Auth';
import {
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import { Institution, InstitutionPeopleDto } from '../../../common/Types';
import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Button } from '../../../components';
import { Countries, InstitutionPersonRole, States } from '../../../common/Data';
import { formatPhoneNumber } from '../../../common/Utils';
import { institutionService } from '../../../services/InstitutionService';
import { personnelService } from '../../../services/Personnel';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';

interface InstitutionPersonnelProps {
    institution: Institution;
    type: InstitutionPersonRole;
    personnel: InstitutionPeopleDto[];
}

const InstitutionPersonnel = (props: InstitutionPersonnelProps) => {
    const [rolePeople, setRolePeople] = useState<InstitutionPeopleDto[]>(
        props.personnel.filter((r) => r.roleId === props.type && r.people),
    );
    const [person, setPerson] = useState<InstitutionPeopleDto>();
    const [editing, setEditing] = useState<boolean>(false);
    const [showModal, setModal] = useState<boolean>(false);

    const { handleSubmit, register, reset, control } = useForm();

    const toggleModal = () => setModal(!showModal);

    const reloadPersonnelFromServer = () => {
        if (props.institution) {
            personnelService
                .getAmsPersonnelByInsitutionId(props.institution.institutionId)
                .then((results) => {
                    setRolePeople(results.filter((r) => r.roleId === props.type && r.people));
                    setModal(false);
                })
                .catch(() => {
                    toast.error('Error loading personnel');
                });
        }
    };

    useEffect(() => {
        if (rolePeople.length > 0) {
            //set person if null or different than currently set person
            if (!person || rolePeople.find((rp) => rp.institutionPeopleId !== person?.institutionPeopleId)) {
                setPerson({ ...rolePeople[0] });
                reset(rolePeople[0]);
            } else if (person) {
                const selected = rolePeople.find((rp) => rp.institutionPeopleId === person.institutionPeopleId);
                if (selected) {
                    setPerson({ ...selected });
                    reset(selected);
                }
            }
            setEditing(false);
        } else {
            const emptyPerson = {
                institutionId: props.institution.institutionId,
                institutionPeopleId: 0,
                peopleId: 0,
                programId: null,
                roleId: props.type,
                dateCreated: new Date(),
                role: {
                    roleId: props.type,
                    title: InstitutionPersonRole[props.type],
                },
                active: true,
                people: {
                    active: true,
                    editedBy: '00000000-0000-0000-0000-000000000000',
                    createdBy: '00000000-0000-0000-0000-000000000000',
                    dateCreated: '',
                    lastEdited: '',
                    address: '',
                    address2: '',
                    address3: '',
                    address4: '',
                    city: '',
                    country: '',
                    credentials: '',
                    email: '',
                    extension: '',
                    fax: '',
                    firstName: '',
                    lastName: '',
                    peopleId: 0,
                    phone: '',
                    salutations: '',
                    state: '',
                    title: '',
                    zip: '',
                },
            } as InstitutionPeopleDto;

            setPerson(emptyPerson);
            reset(emptyPerson);
        }
    }, [setPerson, props, reset, rolePeople]);

    const cancelForm = () => {
        setEditing(!editing);
        reset(person);
    };

    const submitForm = (values) => {
        const toastId = toast.info('Saving person...');

        institutionService
            .saveInstitutionPerson(new InstitutionPeopleDto(values))
            .then(() => {
                reloadPersonnelFromServer();
                setEditing(!editing);
                setPerson(undefined);
                toast.update(toastId, {
                    type: 'success',
                    render: 'Person saved successfully',
                });
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Error saving person',
                });
            });
    };

    const removePerson = () => {
        if (person) {
            const toastId = toast.info('Removing person...');

            institutionService
                .removeInstitutionPerson(person)
                .then(() => {
                    reloadPersonnelFromServer();
                    setPerson(undefined);
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Person removed successfully',
                    });
                })
                .catch(() => {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Error removing person',
                    });
                });
        }
    };

    const editedByInfo =
        person?.people?.editedByString && person?.people?.editedByString.indexOf('{') > -1
            ? JSON.parse(person?.people?.editedByString)
            : undefined;

    return (
        <>
            <Form className={'flex-grow-1 d-flex'} onSubmit={handleSubmit(submitForm)}>
                <Card className={`align-self-stretch flex-grow-1`}>
                    {rolePeople.length > 1 && (
                        <CardHeader className={`bg-warning d-flex justify-content-between`}>
                            <div>
                                <h4 className={'mb-0'}>{InstitutionPersonRole[props.type]}</h4>
                            </div>
                            <div className={`d-flex flex-end align-items-center`}>
                                {rolePeople.map((rp) => (
                                    <Button
                                        key={rp.institutionPeopleId}
                                        type={'button'}
                                        className={`btn-sm ml-1`}
                                        onClick={() => {
                                            setPerson({ ...rp });
                                            reset({ ...rp });
                                        }}
                                        disabled={editing}
                                        color={`${
                                            person?.institutionPeopleId === rp.institutionPeopleId
                                                ? 'primary'
                                                : 'secondary'
                                        }`}
                                    >
                                        <span
                                            style={{ fontSize: '11px' }}
                                        >{`${rp.people?.firstName} ${rp.people?.lastName}`}</span>
                                    </Button>
                                ))}
                            </div>
                        </CardHeader>
                    )}
                    <CardBody>
                        {rolePeople.length <= 1 && (
                            <h4 className={'card-title d-flex align-items-center justify-content-between'}>
                                <span>{InstitutionPersonRole[props.type]}</span>
                            </h4>
                        )}
                        <input type={'hidden'} ref={register()} name={'institutionPeopleId'} />
                        <input type={'hidden'} ref={register()} name={'institutionId'} />
                        <input type={'hidden'} ref={register()} name={'programId'} />
                        <input type={'hidden'} ref={register()} name={'peopleId'} />
                        <input type={'hidden'} ref={register()} name={'roleId'} />
                        <input type={'hidden'} ref={register()} name={'dateCreated'} />
                        <input type={'hidden'} ref={register()} name={'people.peopleId'} />
                        <input type={'hidden'} ref={register()} name={'people.extension'} />
                        <input type={'hidden'} ref={register()} name={'people.createdBy'} />
                        <input type={'hidden'} ref={register()} name={'people.dateCreated'} />
                        <input type={'hidden'} ref={register()} name={'people.lastEdited'} />
                        <input type={'hidden'} ref={register()} name={'people.editedBy'} />
                        <input type={'hidden'} ref={register()} name={'people.active'} />

                        <Row>
                            <Col>
                                <FormGroup className={'row'}>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>Job Title</Label>
                                        {editing && (
                                            <>
                                                <Input innerRef={register()} name={'people.title'} type={'text'} />
                                            </>
                                        )}
                                        {!editing && <span>{person?.people?.title}</span>}
                                    </Col>
                                </FormGroup>
                                <FormGroup className={'row'}>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>Salutations</Label>
                                        {editing && (
                                            <>
                                                <Input
                                                    innerRef={register()}
                                                    name={'people.salutations'}
                                                    type={'text'}
                                                />
                                            </>
                                        )}
                                        {!editing && <span>{person?.people?.salutations}</span>}
                                    </Col>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>Credentials</Label>
                                        {editing && (
                                            <>
                                                <Input
                                                    innerRef={register()}
                                                    name={'people.credentials'}
                                                    type={'text'}
                                                />
                                            </>
                                        )}
                                        {!editing && <span>{person?.people?.credentials}</span>}
                                    </Col>
                                </FormGroup>
                                <FormGroup className={'row'}>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>First Name</Label>
                                        {editing && (
                                            <>
                                                <Input innerRef={register()} name={'people.firstName'} type={'text'} />
                                            </>
                                        )}
                                        {!editing && <span>{person?.people?.firstName}</span>}
                                    </Col>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>Last Name</Label>
                                        {editing && (
                                            <>
                                                <Input innerRef={register()} name={'people.lastName'} type={'text'} />
                                            </>
                                        )}
                                        {!editing && <span>{person?.people?.lastName}</span>}
                                    </Col>
                                </FormGroup>
                                <FormGroup className={'row'}>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>Phone</Label>
                                        {editing && (
                                            <>
                                                <Input innerRef={register()} name={'people.phone'} type={'text'} />
                                            </>
                                        )}
                                        {!editing && (
                                            <div>
                                                {formatPhoneNumber(person?.people?.phone)}{' '}
                                                {person?.people?.extension ? `ext ${person?.people?.extension}` : ''}
                                            </div>
                                        )}
                                    </Col>
                                    {editing && (
                                        <Col className={'d-flex flex-column'}>
                                            <Label>Extension</Label>
                                            <Input innerRef={register()} name={'people.extension'} type={'text'} />
                                        </Col>
                                    )}
                                </FormGroup>
                                <FormGroup className={'row'}>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>Fax</Label>
                                        {editing && (
                                            <>
                                                <Input innerRef={register()} name={'people.fax'} type={'text'} />
                                            </>
                                        )}
                                        {!editing && <span>{person?.people?.fax}</span>}
                                    </Col>
                                </FormGroup>
                                <FormGroup className={'row'}>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>Email</Label>
                                        {editing && (
                                            <>
                                                <Input
                                                    innerRef={register({
                                                        required:
                                                            InstitutionPersonRole['Program Director'] === props.type
                                                                ? true
                                                                : false,
                                                    })}
                                                    name={'people.email'}
                                                    type={'text'}
                                                />
                                            </>
                                        )}
                                        {!editing && <span>{person?.people?.email}</span>}
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className={'row'}>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>Address</Label>
                                        {editing && (
                                            <>
                                                <Input innerRef={register()} name={'people.address'} type={'text'} />
                                            </>
                                        )}
                                        {!editing && <span>{person?.people?.address}</span>}
                                    </Col>
                                </FormGroup>
                                <FormGroup className={'row'}>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>Address 2</Label>
                                        {editing && (
                                            <>
                                                <Input innerRef={register()} name={'people.address2'} type={'text'} />
                                            </>
                                        )}
                                        {!editing && <span>{person?.people?.address2}</span>}
                                    </Col>
                                </FormGroup>
                                <FormGroup className={'row'}>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>Address 3</Label>
                                        {editing && (
                                            <>
                                                <Input innerRef={register()} name={'people.address3'} type={'text'} />
                                            </>
                                        )}
                                        {!editing && <span>{person?.people?.address3}</span>}
                                    </Col>
                                </FormGroup>
                                <FormGroup className={'row'}>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>Address 4</Label>
                                        {editing && (
                                            <>
                                                <Input innerRef={register()} name={'people.address4'} type={'text'} />
                                            </>
                                        )}
                                        {!editing && <span>{person?.people?.address4}</span>}
                                    </Col>
                                </FormGroup>
                                <FormGroup className={'row'}>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>City</Label>
                                        {editing && (
                                            <>
                                                <Input innerRef={register()} name={'people.city'} type={'text'} />
                                            </>
                                        )}
                                        {!editing && <span>{person?.people?.city}</span>}
                                    </Col>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>State / Province</Label>
                                        {editing && (
                                            <>
                                                <Input type={'select'} name={'people.state'} innerRef={register()}>
                                                    <option value={undefined}>{``}</option>
                                                    {States &&
                                                        States.map((s) => (
                                                            <option key={s.abbreviation} value={s.abbreviation}>
                                                                {s.abbreviation}
                                                            </option>
                                                        ))}
                                                </Input>
                                            </>
                                        )}
                                        {!editing && <span>{person?.people?.state}</span>}
                                    </Col>
                                </FormGroup>
                                <FormGroup className={'row'}>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>Zip / Postal Code</Label>
                                        {editing && (
                                            <>
                                                <Input innerRef={register()} name={'people.zip'} type={'text'} />
                                            </>
                                        )}
                                        {!editing && <span>{person?.people?.zip}</span>}
                                    </Col>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>Country</Label>
                                        {editing && (
                                            <>
                                                <Controller
                                                    as={Input}
                                                    type={'select'}
                                                    control={control}
                                                    name={'people.country'}
                                                >
                                                    <option value={undefined}></option>
                                                    {Countries.map((c) => (
                                                        <option key={c} value={c}>
                                                            {c}
                                                        </option>
                                                    ))}
                                                </Controller>{' '}
                                            </>
                                        )}
                                        {!editing && <span>{person?.people?.country}</span>}
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                        {person && (person.peopleId || 0) > 0 && (person.people?.lastEdited || editedByInfo) && (
                            <Row>
                                <Col className="font-italic">
                                    <span>{`Last edited`}</span>
                                    {editedByInfo && <span>{` by ${editedByInfo.name}`}</span>}
                                    {person.people?.lastEdited && (
                                        <span>{` on ${moment
                                            .utc(person.people?.lastEdited)
                                            .local()
                                            .format('MM/DD/YYYY [at] hh:mm A')}`}</span>
                                    )}
                                </Col>
                            </Row>
                        )}
                    </CardBody>
                    {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') && (
                        <CardFooter className={'bg-white border-0 p-0'}>
                            <ButtonGroup className={'w-100'}>
                                {!editing && (
                                    <>
                                        {person && person.institutionPeopleId > 0 && (
                                            <>
                                                <Button
                                                    color={'info'}
                                                    type={'button'}
                                                    onClick={() => setEditing(!editing)}
                                                >
                                                    <i className={'mdi mdi-pencil'} />
                                                    <span className={'ml-1'}>Edit Person</span>
                                                </Button>
                                                <Button color={'danger'} type={'button'} onClick={toggleModal}>
                                                    <i className={'mdi mdi-delete'} />
                                                    <span className={'ml-1'}>Remove Person</span>
                                                </Button>
                                            </>
                                        )}
                                        {person && person.institutionPeopleId === 0 && (
                                            <Button color={'info'} type={'button'} onClick={() => setEditing(!editing)}>
                                                <i className={'mdi mdi-account-plus'} />
                                                <span className={'ml-1'}>Add Person</span>
                                            </Button>
                                        )}
                                    </>
                                )}
                                {editing && (
                                    <>
                                        <Button color={'success'} type={'submit'}>
                                            <i className={' mdi mdi-content-save'} />
                                            <span className={'ml-1'}>Submit</span>
                                        </Button>
                                        <Button color={'white'} type={'button'} onClick={() => cancelForm()}>
                                            <span className={'ml-1'}>Cancel</span>
                                        </Button>
                                    </>
                                )}
                            </ButtonGroup>
                        </CardFooter>
                    )}
                </Card>
            </Form>

            {person && person.people && person.people.peopleId > 0 && (
                <Modal isOpen={showModal} toggle={toggleModal} centered>
                    <ModalHeader>{`Confirm Remove ${person.people.firstName} ${person.people.lastName} (${
                        InstitutionPersonRole[props.type]
                    })`}</ModalHeader>
                    <ModalBody>
                        <p>
                            {`You have selected to remove ${person.people.firstName} ${
                                person.people.lastName
                            } from the role of ${InstitutionPersonRole[props.type]} at ${
                                props.institution.title
                            }. To continue, please click the Confirm button below.`}
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <div className={'d-flex justify-content-between flex-grow-1'}>
                            <Button color={'primary'} onClick={() => removePerson()} type={'button'}>
                                Confirm
                            </Button>
                            <Button color={'secondary'} onClick={toggleModal} type={'button'}>
                                Cancel
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
};

export default InstitutionPersonnel;
