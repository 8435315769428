import { APIUriAms, http, httpFile } from './HttpHelper';
import { CoaDto, DocumentUpload, NewCoaDto, SignatureDocument } from '../common/Types';

class CoaAdministration {
    public getCoa = async (coaId) => {
        const result = await http<CoaDto>(APIUriAms, `/coa/${coaId}`, undefined, undefined, null);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getCoaSignature = async (coaId): Promise<string | undefined> => {
        const result = await http<string | undefined>(APIUriAms, `/coa/${coaId}/signature`, undefined, undefined, null);

        if (result.ok) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };
    public uploadSignature = async (upload: DocumentUpload): Promise<string> => {
        if (upload?.dto && upload?.fileUpload) {
            const dto = upload.dto as SignatureDocument;

            const formData = new FormData();
            formData.append('dto', JSON.stringify(upload.dto));
            if (upload.fileUpload) {
                formData.append('fileUpload', upload.fileUpload);
            }

            const result = await httpFile<any>(APIUriAms, `/coa/${dto.coaId}/signature`, formData, undefined, 'PUT');
            if (result.parsedBody) {
                return result.parsedBody;
            } else {
                throw new Error(result.statusText);
            }
        }

        throw Error();
    };

    public getCoas = async () => {
        const result = await http<CoaDto[]>(APIUriAms, `/coa`, undefined, undefined, null);
        if (result.parsedBody) {
            //return result.parsedBody.sort((a, b) => a.title.localeCompare(b.title));
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getAllCoas = async () => {
        const result = await http<CoaDto[]>(APIUriAms, `/coa/all`, undefined, undefined, null);
        if (result.parsedBody) {
            return result.parsedBody.sort((a, b) => (a.coaTitle || '').localeCompare((b.coaTitle || '')));
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveCoas = async (coas: CoaDto[] | NewCoaDto[]) => {
        const result = await http<CoaDto[]>(APIUriAms, `/coa`, undefined, 'POST', coas);

        if (result.parsedBody) {
            return result.parsedBody.sort((a, b) => (a.coaTitle || '').localeCompare((b.coaTitle || '')));
        } else {
            throw new Error(result.statusText);
        }
    };
}

export const coaService = new CoaAdministration();
