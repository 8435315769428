import React, { useState, useEffect } from 'react';
import { Col, CardBody, CardTitle, Form, Row, Card, FormGroup, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { Button } from '../../components';
import { useForm, useFieldArray } from 'react-hook-form';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { IDocumentCategory, DocumentCategories as AsDocumentCategories } from '../../common/Types';
import { professionService } from '../../services/ProfessionAdministration';

const DocumentCategoriesTab = (props) => {
    const [categories, setCategories] = useState<IDocumentCategory[]>();
    const [editing, setEditing] = useState(false);
    const { handleSubmit, register, control, reset, setValue, getValues } = useForm();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'documentCategories',
    });

    const removeCategory = (i) => {
        const formValues = getValues({ nest: true });
        if (!formValues.documentCategories[i].title) {
            remove(i);
        } else {
            setValue(`documentCategories[${i}]`, { isActive: false });
            reset(getValues({ nest: true }));
        }
    };

    const toggleEdit = () => {
        setEditing(!editing);
        reset({ documentCategories: categories });
    };

    const onSubmit = (formData) => {
        const toastId = toast.info('Saving document categories...');
        professionService
            .saveDocumentCategories(
                props.coa.professionId,
                formData.documentCategories.map((category) => AsDocumentCategories(category)),
            )
            .then((response) => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Document categories saved.',
                });
                setCategories(response);
                toggleEdit();
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Failed to save categories.',
                });
            });
    };

    useEffect(() => {
        const getCategories = () => {
            professionService
                .getDocumentCategories(props.coa.professionId)
                .then((response) => {
                    setCategories(response);
                    append({ documentCategories: response });
                })
                .catch(() => {
                    toast.error('Unable to get document categories.');
                });
        };

        getCategories();
    }, [setCategories, append, props.coa]);

    return (
        <>
            <Col md={6} xl={5}>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h3>Document Categories</h3>
                        </CardTitle>
                        {editing ? (
                            <>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    {fields.map((category, i) => (
                                        <FormGroup
                                            key={category.id}
                                            className={JSON.parse(category.isActive) ? '' : 'd-none'}
                                        >
                                            <Col>
                                                <input
                                                    type="hidden"
                                                    name={`documentCategories[${i}].categoryId`}
                                                    defaultValue={`${category.categoryId}`}
                                                    ref={register({})}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`documentCategories[${i}].professionId`}
                                                    defaultValue={`${category.professionId}`}
                                                    ref={register({})}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`documentCategories[${i}].isActive`}
                                                    defaultValue={`${category.isActive}`}
                                                    ref={register({})}
                                                />
                                                <InputGroup>
                                                    <Input
                                                        type="text"
                                                        id={`documentCategories[${i}].title`}
                                                        name={`documentCategories[${i}].title`}
                                                        innerRef={register({ required: true })}
                                                        defaultValue={category.title}
                                                        disabled={!category.isActive}
                                                    />
                                                    <InputGroupAddon addonType="append">
                                                        <Button
                                                            className="remove"
                                                            color={'info'}
                                                            onClick={() => removeCategory(i)}
                                                        >
                                                            <i className="mdi mdi-close" />
                                                        </Button>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                    ))}
                                    <FormGroup>
                                        <Col>
                                            <Button
                                                className="add"
                                                color={'info'}
                                                onClick={() =>
                                                    append({
                                                        categoryId: 0,
                                                        professionId: Number(props.coa.professionId),
                                                        title: '',
                                                        isActive: true,
                                                    })
                                                }
                                            >
                                                <i className="mdi mdi-plus" />
                                                <span className="ml-1">Add Document Category</span>
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                    <Row className="mt-3">
                                        <Col>
                                            <div>
                                                <Button type="submit">Save</Button>&nbsp;
                                                <Button outline onClick={() => toggleEdit()}>
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </>
                        ) : (
                            <>
                                {categories ? (
                                    <>
                                        <Row>
                                            <Col>
                                                {categories.map((category, i) => (
                                                    <Row key={i}>
                                                        <Col className="doc-category-title">{category.title}</Col>
                                                    </Row>
                                                ))}
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col>
                                                <Button onClick={() => toggleEdit()}>Edit</Button>
                                            </Col>
                                        </Row>
                                    </>
                                ) : (
                                    <Skeleton count={5} />
                                )}
                            </>
                        )}
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default DocumentCategoriesTab;
