import { Col, Row } from 'reactstrap';
import React, { Children } from 'react';

type Props = {
    title?: string;
    children?: React.ReactText | JSX.Element | JSX.Element[];
    className?: string;
};

/**
 * Page title sits just under the app header
 * @param {Props} props
 */
const PageTitle = (props: Props) => {
    return (
        <Row>
            <Col>
                <div className={`page-title-box ${props.className}`}>
                    {props.title && <h4 className="page-title">{props.title}</h4>}
                    {/**
                     * https://github.com/nfl/react-helmet/issues/467
                     * <Helmet><title>{props.title}</title></Helmet>
                     */}
                    {props.children}
                </div>
            </Col>
        </Row>
    );
};

export default PageTitle;
