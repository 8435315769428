import { ApiUriIdentity, http } from './HttpHelper';
import { IdentityUser, ProgramDirectorUser, RolePermission, UserManagement } from '../common/Types';

class SecurityService {
    public getCaahepAndCoaUsers = async (): Promise<UserManagement[]> => {
        const result = await http<UserManagement[]>(
            ApiUriIdentity,
            `/usermanagement/`,
            undefined,
            undefined,
            null);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public removeUser = async (userId: string): Promise<void> => {
        await http<null>(
            ApiUriIdentity,
            `/usermanagement/`,
            undefined,
            'DELETE',
            {
                userId: userId
            });

        return;
    };

    public reinstateUser = async (userId: string): Promise<void> => {
        await http<null>(
            ApiUriIdentity,
            `/usermanagement/reinstate`,
            undefined,
            'PUT',
            {
                userId: userId
            });

        return;
    };

    public initiatePasswordReset = async (userId: string): Promise<void> => {
        await http<null>(
            ApiUriIdentity,
            `/usermanagement/initiate-password-reset`,
            undefined,
            'PUT',
            {
                userId: userId
            });

        return;
    };

    public createOrUpdateUser = async (user: UserManagement): Promise<void> => {
        await http<null>(
            ApiUriIdentity,
            `/usermanagement`,
            undefined,
            'PUT',
            user);

        return;
    };

    public getRolesAndPermissions = async (): Promise<RolePermission[]> => {
        const result = await http<RolePermission[]>(
            ApiUriIdentity,
            `/permission`,
            undefined,
            undefined,
            null);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public savePermissionsForRole = async (rolePermission: RolePermission): Promise<void> => {
        await http<void>(
            ApiUriIdentity,
            `/permission`,
            undefined,
            'PUT',
            rolePermission);
    };

    public updateProgramDirector = async (programDirector: ProgramDirectorUser): Promise<void> => {
        await http<void>(
            ApiUriIdentity,
            `/programdirector`,
            undefined,
            'PUT',
            programDirector
        );
    }

    public removeProgramDirector = async (programDirector: ProgramDirectorUser): Promise<void> => {
        await http<void>(
            ApiUriIdentity,
            `/programdirector`,
            undefined,
            'DELETE',
            programDirector
        );
    }

    public loadProgramDirector = async (email: string, programId: number): Promise<IdentityUser | undefined> => {
        const result = await http<IdentityUser>(
            ApiUriIdentity,
            `/programdirector/${encodeURI(email)}/${programId}`,
            undefined,
            'GET',
            undefined
        );

        return result.parsedBody;
    }
}

export const securityService = new SecurityService();
