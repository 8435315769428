import React, { useState } from 'react';
import { Card, CardBody, ListGroup, ListGroupItem, Form, FormGroup, Label, ButtonGroup } from 'reactstrap';
import {
    ProfessionStandardsDesignationDto,
    ProfessionStandardsDesignationSubStandardDto,
    ProfessionStandardsDesignationSubStandardRationaleDto,
} from '../../../common/Types';
import { Button, Input } from '../../../components';
import styled from 'styled-components';
import { useForm, useFieldArray } from 'react-hook-form';
import * as yup from 'yup';
import { professionService } from '../../../services/ProfessionAdministration';
import { toast } from 'react-toastify';

const StyledBackButton = styled(Button)`
    background: none;
    color: var(--caheep-primary);
    border: none;
    font-size: 24px;
    border-radius: 30px;
`;

type Props = {
    designation: ProfessionStandardsDesignationDto;
    close: Function;
};

const SubStandardsCard = (props: Props) => {
    const [standards, setStandards] = useState<ProfessionStandardsDesignationSubStandardDto[]>(
        props.designation.professionStandardsDesignationSubStandard?.filter((x) => !x.isDeleted) || [],
    );
    const [selectedStandard, setSelectedStandard] = useState<ProfessionStandardsDesignationSubStandardDto>();
    const [deletedRationale, setDeletedRationale] = useState<ProfessionStandardsDesignationSubStandardRationaleDto[]>(
        [],
    );

    const schema: yup.ObjectSchema<ProfessionStandardsDesignationSubStandardDto> = yup
        .object({
            subStandardId: yup.number().required(),
            standardsDesignationId: yup.number().required().default(0),
            description: yup.string().required(),
            isDeleted: yup.boolean().required().default(false),
            professionStandardsDesignationSubStandardRationale: yup
                .array()
                .of(yup.object<ProfessionStandardsDesignationSubStandardRationaleDto>().defined()),
        })
        .defined();

    const { control, handleSubmit, register, reset } = useForm({
        validationSchema: schema,
        defaultValues: {} as ProfessionStandardsDesignationSubStandardDto,
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'professionStandardsDesignationSubStandardRationale',
    });

    const onSubmit = (formData: ProfessionStandardsDesignationSubStandardDto) => {
        const subToastId = toast.info('Saving Sub Standard...');

        professionService
            .saveSubStandards([formData])
            .then((response) => {
                toast.update(subToastId, {
                    type: 'success',
                    render: 'Sub Standard saved successfuly',
                });
                reset();
                setSelectedStandard(undefined);
                setStandards([...(standards ?? []), ...response].filter((x) => !x.isDeleted && x.subStandardId > 0));
            })
            .catch(() =>
                toast.update(subToastId, {
                    type: 'error',
                    render: 'Error saving Sub Standard',
                }),
            );

        const rationaleSave = [
            ...(formData.professionStandardsDesignationSubStandardRationale ?? []),
            ...deletedRationale,
        ];

        if (rationaleSave.length) {
            const rationaleToastId = toast.info('Saving Rationale & Suggested Documentation...');

            professionService
                .saveRationale(rationaleSave)
                .then((response) => {
                    toast.update(rationaleToastId, {
                        type: 'success',
                        render: 'Rationale & Suggested Documentation saved successfuly',
                    });
                    reset();
                    setSelectedStandard(undefined);
                })
                .catch(() =>
                    toast.update(rationaleToastId, {
                        type: 'error',
                        render: 'Error saving Rationale & Suggested Documentation',
                    }),
                );
        }
    };

    const editSubStandard = (standard: ProfessionStandardsDesignationSubStandardDto) => {
        reset(standard);
        setSelectedStandard(standard);
    };

    const removeSubStandard = (standard) => {
        standard.isDeleted = true;
        const newStandards = [
            ...(standards?.filter((x) => x.subStandardId !== standard.subStandardId) ?? []),
            standard,
        ].sort((x, y) => {
            return x.subStandardId - y.subStandardId;
        });
        setSelectedStandard(undefined);
        setStandards(newStandards);
    };

    const removeRationale = (rationale, i) => {
        setDeletedRationale([...deletedRationale, rationale]);
        remove(i);
    };

    const addSub = () => {
        const newSub = {
            subStandardId: 0,
            standardsDesignationId: props.designation.standardsDesignationId,
            description: '',
            isDeleted: false,
            professionStandardsDesignationSubStandard: undefined,
        };

        setStandards([...(standards ?? []), newSub]);
        setSelectedStandard(newSub);
    };

    return (
        <Card>
            <CardBody>
                <div style={{ display: 'flex' }}>
                    <div className="mb-2" style={{ paddingRight: '8px' }}>
                        <StyledBackButton onClick={() => props.close()}>
                            <i className={'remixicon-arrow-left-line'}></i>
                        </StyledBackButton>
                    </div>
                    <div style={{ flex: 1 }}>
                        <h3 className="card-title" style={{ lineHeight: '52px' }}>
                            Sub Standards for {props.designation.designationTitle}
                        </h3>
                    </div>
                </div>
            </CardBody>
            <ListGroup>
                {standards?.map((standard, i) => (
                    <ListGroupItem key={i}>
                        {selectedStandard?.subStandardId !== standard.subStandardId ? (
                            <>
                                <div style={{ display: 'flex' }} className="mb-2">
                                    <p style={{ flex: 1 }}>{standard.description}</p>
                                </div>

                                <h5>Rationale &amp; Suggested Documentation</h5>
                                {!standard?.professionStandardsDesignationSubStandardRationale?.length && <p>None</p>}
                                {standard?.professionStandardsDesignationSubStandardRationale?.map(
                                    (rationale, index) => (
                                        <div key={index} style={{ display: 'flex' }}>
                                            <div style={{ flex: 1 }}>{rationale.rationale}</div>
                                            <div style={{ flex: 1 }}>{rationale.suggestedDocumentation}</div>
                                        </div>
                                    ),
                                )}
                                <div className="mt-2">
                                    <Button color="info" onClick={() => editSubStandard(standard)}>
                                        Edit
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup>
                                    <Label for="description">Sub Standard Description</Label>
                                    <Input
                                        name="description"
                                        type="textarea"
                                        defaultValue={standard.description}
                                        innerRef={register()}
                                    />
                                    <input
                                        type="hidden"
                                        ref={register()}
                                        name={`subStandardId`}
                                        defaultValue={standard.subStandardId}
                                    />
                                    <input
                                        type="hidden"
                                        name={`standardsDesignationId`}
                                        ref={register()}
                                        defaultValue={standard.standardsDesignationId}
                                    />
                                    <input
                                        type="hidden"
                                        name={`isDeleted`}
                                        ref={register()}
                                        defaultValue={standard.isDeleted?.toString() ?? 'false'}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    {fields.map((rsd, index) => (
                                        <div key={rsd.id} style={{ display: 'flex' }}>
                                            <FormGroup style={{ flex: 1 }}>
                                                <Label
                                                    for={`professionStandardsDesignationSubStandardRationale[${index}].rationale`}
                                                >
                                                    Rationale
                                                </Label>
                                                <Input
                                                    name={`professionStandardsDesignationSubStandardRationale[${index}].rationale`}
                                                    type="textarea"
                                                    innerRef={register()}
                                                    defaultValue={rsd.rationale}
                                                />
                                            </FormGroup>
                                            &nbsp;
                                            <FormGroup style={{ flex: 1, marginRight: '8px' }}>
                                                <Label
                                                    for={`professionStandardsDesignationSubStandardRationale[${index}].suggestedDocumentation`}
                                                >
                                                    Suggested Documentation
                                                </Label>
                                                <Input
                                                    name={`professionStandardsDesignationSubStandardRationale[${index}].suggestedDocumentation`}
                                                    type="textarea"
                                                    innerRef={register()}
                                                    defaultValue={rsd.suggestedDocumentation}
                                                />
                                            </FormGroup>
                                            <div className="text-right">
                                                <ButtonGroup style={{ marginTop: '29px' }}>
                                                    <Button
                                                        color="danger"
                                                        onClick={() => removeRationale(rsd, i)}
                                                        title="Delete Rationale and Suggested Documentation"
                                                    >
                                                        <i className={'remixicon-delete-bin-line'}></i>
                                                    </Button>
                                                </ButtonGroup>
                                            </div>
                                            <input
                                                type="hidden"
                                                name={`professionStandardsDesignationSubStandardRationale[${index}].rationaleId`}
                                                ref={register()}
                                                defaultValue={rsd.rationaleId}
                                            />
                                            <input
                                                type="hidden"
                                                name={`professionStandardsDesignationSubStandardRationale[${index}].subStandardId`}
                                                ref={register()}
                                                defaultValue={rsd.subStandardId}
                                            />
                                        </div>
                                    ))}
                                </FormGroup>

                                <div className="mb-3">
                                    <Button
                                        type="button"
                                        color="info"
                                        onClick={() =>
                                            append({
                                                rationaleId: 0,
                                                subStandardId: standard.subStandardId,
                                                isDeleted: false,
                                                professionStandardsDesignationSubStandardRationale: [],
                                            })
                                        }
                                    >
                                        Add Rationale
                                    </Button>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <Button type="submit" color="primary">
                                            Save
                                        </Button>
                                        &nbsp;
                                        <Button type="button" outline onClick={() => setSelectedStandard(undefined)}>
                                            Cancel
                                        </Button>
                                    </div>
                                    <div style={{ flex: 1 }} className="text-right">
                                        {standard.isDeleted ? (
                                            <p className="text-danger">Click Save to delete</p>
                                        ) : (
                                            <Button
                                                type="button"
                                                color="danger"
                                                onClick={() => removeSubStandard(standard)}
                                            >
                                                Delete Sub Standard
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </Form>
                        )}
                    </ListGroupItem>
                ))}
            </ListGroup>
            <CardBody>
                <Button color="primary" onClick={() => addSub()}>
                    Add Sub Standard
                </Button>
            </CardBody>
        </Card>
    );
};

export default SubStandardsCard;
