import {
    Card,
    CardBody,
    Col,
    Form,
    FormGroup,
    Label,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
} from 'reactstrap';
import React, { useEffect, useState } from 'react';

import { Button } from '../../components';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { AuspiceDto, InstitutionTypeDto, ProfitVsPublicDto } from '../../common/Types';
import { reportService } from '../../services/ReportService';
import moment from 'moment';
import MultiSelect from 'react-multi-select-component';
import styled from 'styled-components';
import { institutionService } from '../../services/InstitutionService';
import { DegreeTypes } from '../../common/Data';
import { PagingDto } from '../../types';

const StyledMultiSelect = styled(MultiSelect)`
    width: 300px;

    & .dropdown-container {
        width: 100%;
        max-width: 100%;
    }
    & .dropdown-content {
        z-index: 3;
    }
    & .select-item {
        display: block;
    }
`;
const ProfitVsPublicReport = () => {
    const [institutionTypes, setInstitutionTypes] = useState<InstitutionTypeDto[]>();
    const [selectedInstitutionTypes, setSelectedInstitutionTypes] = useState<[]>();
    const [auspices, setAuspices] = useState<AuspiceDto[]>();
    const [selectedAuspices, setSelectedAuspices] = useState<[]>();
    const [institutions, setInstitutions] = useState<ProfitVsPublicDto[]>();
    const [total, setTotal] = useState(0);
    const [paging, setPaging] = useState<PagingDto>({
        pageNumber: 1,
        maxPageSize: null,
        pageSize: 10,
    });

    useEffect(() => {
        const loadAuspices = () => {
            institutionService
                .getAuspice()
                .then((results) => {
                    setAuspices([
                        ...(results.filter((r) => r.isActive === true).sort((a, b) => a.title.localeCompare(b.title)) ||
                            []),
                    ]);
                })
                .catch(() => {
                    toast.error('Error loading auspices');
                });
        };
        const loadInstitutionTypes = () => {
            institutionService
                .getTypes()
                .then((results) => {
                    setInstitutionTypes([
                        ...(results.filter((r) => r.isActive === true).sort((a, b) => a.title.localeCompare(b.title)) ||
                            []),
                    ]);
                })
                .catch(() => {
                    toast.error('Error loading institution types');
                });
        };
        loadInstitutionTypes();
        loadAuspices();
    }, [setAuspices]);

    useEffect(() => {
        const toastId = toast.info('Searching...', {
            autoClose: false,
        });
        const getData = () => {
            reportService
                .getProfitVsPublicReport(
                    paging,
                    selectedInstitutionTypes?.map((a) => a['value']),
                    selectedAuspices?.map((a) => a['value']),
                )
                .then((response) => {
                    setInstitutions(response.records);
                    setTotal(response.total);
                    toast.update(toastId, {
                        type: 'success',
                        autoClose: 500,
                    });
                })
                .catch(() => toast.error('Failed to generate report'));
        };

        getData();
    }, [setInstitutions, paging]);

    const exportReport = () => {
        const toastId = toast.info('Generating report, this may take a minute...', {
            autoClose: false,
        });
        reportService
            .exportProfitVsPublicReport(
                selectedInstitutionTypes?.map((a) => a['value']),
                selectedAuspices?.map((a) => a['value']),
            )
            .then((report) => {
                if (report) {
                    const url = window.URL.createObjectURL(report);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `ProfitVsPublicReport.xlsx`;
                    a.click();
                    toast.update(toastId, {
                        render: 'Report generated successfully.',
                        type: 'success',
                        autoClose: 2500,
                    });
                }
            })
            .catch(() => {
                toast.error(`Unable to generate report`);
            });
    };
    const { register, handleSubmit } = useForm();

    const onSubmit = () => {
        setPaging({
            ...paging,
        });
    };

    return (
        <>
            <Card>
                <CardBody>
                    <Row>
                        <Col>
                            <div className="mb-3">
                                <h3>Profit vs. Public Report</h3>
                            </div>
                            <Form onSubmit={handleSubmit(onSubmit)} inline>
                                {auspices && auspices?.length > 0 && (
                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                        <Label for="auspice" className="mr-sm-2">
                                            Auspice
                                        </Label>
                                        <StyledMultiSelect
                                            options={auspices?.map((a) => {
                                                return {
                                                    label: a.title,
                                                    value: a.auspiceID,
                                                };
                                            })}
                                            disableSearch={true}
                                            hasSelectAll={true}
                                            value={selectedAuspices}
                                            onChange={setSelectedAuspices}
                                            required
                                        />
                                    </FormGroup>
                                )}
                                {institutionTypes && institutionTypes?.length > 0 && (
                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                        <Label for="auspice" className="mr-sm-2">
                                            Institution Type
                                        </Label>
                                        <StyledMultiSelect
                                            options={institutionTypes?.map((a) => {
                                                return {
                                                    label: a.title,
                                                    value: a.institutionTypeId,
                                                };
                                            })}
                                            disableSearch={true}
                                            hasSelectAll={true}
                                            value={selectedInstitutionTypes}
                                            onChange={setSelectedInstitutionTypes}
                                            required
                                        />
                                    </FormGroup>
                                )}
                                <div className="mb-2 mr-sm-2 mb-sm-2">
                                    <Button type="submit">Search</Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col style={{ display: 'flex' }}>
                            <div className="mr-2">
                                <Button color="info" onClick={() => exportReport()}>
                                    Export Report
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <div className="mt-3">
                        <h4>Institutions</h4>
                    </div>
                </CardBody>
                <Table>
                    <thead>
                        <tr>
                            <th>InstitutionId</th>
                            <th>Institution</th>
                            <th>Institution Type</th>
                            <th>Auspice</th>
                        </tr>
                    </thead>
                    <tbody>
                        {institutions && institutions.length > 0 ? (
                            institutions.map((institution, i) => (
                                <tr key={institution.institutionId ?? i}>
                                    <td>{institution.institutionId}</td>
                                    <td>{institution.institutionTitle}</td>
                                    <td>{institution.institutionType}</td>
                                    <td>{institution.auspiceTitle}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={7}>No Results</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                <CardBody>
                    <h4>Total: {total}</h4>
                    <Pagination size="sm" aria-label="Institutions Navigation">
                        <PaginationItem disabled={paging.pageNumber === 1}>
                            <PaginationLink
                                previous
                                href=""
                                onClick={() => setPaging({ ...paging, pageNumber: paging.pageNumber - 1 })}
                            />
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationLink
                                next
                                href=""
                                onClick={() => setPaging({ ...paging, pageNumber: paging.pageNumber + 1 })}
                            />
                        </PaginationItem>
                    </Pagination>
                </CardBody>
            </Card>
        </>
    );
};

export default ProfitVsPublicReport;
