import { loggedOut } from "../store/auth/Actions";
import { store } from '../index'

interface HttpResponse<T> extends Response {
    parsedBody?: T;
}

export const ApiUriIdentity: string = process.env.REACT_APP_IDENTITY_API_URI || '';
export const ApiUriArms: string = process.env.REACT_APP_ARMS_API || '';
export const APIUriAms: string = process.env.REACT_APP_AMS_API || '';

export async function http<T>(
    endpoint: string,
    path: string,
    contentType: string = 'application/json',
    method: string = 'GET',
    body: any = null,
    abortController: AbortController = new AbortController()
): Promise<HttpResponse<T>> {
    return new Promise(async (resolve, reject) => {
        const headers: HeadersInit = new Headers();
        headers.set('Content-Type', contentType);
        headers.set('Cache-Control', 'no-cache');

        if (localStorage.getItem('access_token')) {
            headers.set('Authorization', `Bearer ${localStorage.getItem('access_token')}`);
        }

        const response: HttpResponse<T> = await fetch(
            `${endpoint}${path}`,
            {
                method: method,
                body: body && contentType === 'application/json' ? JSON.stringify(body) : body,
                headers: headers,
                signal: abortController.signal
            });

        try {
            response.parsedBody = await response.json();
        } catch (ex) { }

        if (!response.ok) {
            if (response.parsedBody) {
                reject(response.parsedBody);
            } else {
                reject(response);
            }
        }

        if (response.status === 401) {
            localStorage.setItem('redirect', window.location.pathname);
            store.dispatch(loggedOut());
        }

        resolve(response);
    });
}
export async function httpFile<T>(
    endpoint: string,
    path: string,
    formData: any,
    abortController: AbortController = new AbortController(),
    method: string = 'POST'
): Promise<HttpResponse<T>> {
    return new Promise(async (resolve, reject) => {
        const headers: HeadersInit = new Headers();
        headers.set('Cache-Control', 'no-cache');
        if (localStorage.getItem('access_token')) {
            headers.set('Authorization', `Bearer ${localStorage.getItem('access_token')}`);
        }
        const response: HttpResponse<T> = await fetch(
            `${endpoint}${path}`,
            {
                method: method,
                body: formData,
                headers: headers,
                signal: abortController.signal
            });
        try {
            response.parsedBody = await response.json();
        } catch (ex) { }
        if (!response.ok) {
            if (response.parsedBody) {
                reject(response.parsedBody);
            } else {
                reject(response);
            }
        }
        if (response.status === 401) {
            localStorage.setItem('redirect', window.location.pathname);
            store.dispatch(loggedOut());
        }
        resolve(response);
    });
}
export async function httpDownloadFile(
    endpoint: string,
    path: string,
    formData: any,
    method: string = 'GET',
    contentType: string = 'application/text',
    abortController: AbortController = new AbortController()
): Promise<HttpResponse<Blob>> {
    return new Promise(async (resolve, reject) => {
        const headers: HeadersInit = new Headers();
        headers.set('Cache-Control', 'no-cache');
        headers.set('Content-Type', contentType);
        if (localStorage.getItem('access_token')) {
            headers.set('Authorization', `Bearer ${localStorage.getItem('access_token')}`);
        }
        const response: HttpResponse<Blob> = await fetch(
            `${endpoint}${path}`,
            {
                method: method,
                body: formData,
                headers: headers,
                signal: abortController.signal
            });
        try {
            response.parsedBody = await response.blob();
        } catch (ex) { }
        if (!response.ok) {
            if (response.parsedBody) {
                reject(response.parsedBody);
            } else {
                reject(response);
            }
        }
        if (response.status === 401) {
            localStorage.setItem('redirect', window.location.pathname);
            store.dispatch(loggedOut());
        }
        resolve(response);
    });
}