import React from 'react';
import { ToastContainer } from 'react-toastify';

type Props = {
    children: React.ReactNode;
};

/**
 * Blank Container
 * @param {Props} props
 */
const NavLessContainer = (props: Props) => {
    return (
        <div className="account-pages mt-5 mb-5">
            <ToastContainer hideProgressBar />
            <div className="container">{props.children}</div>
        </div>
    );
};

export default NavLessContainer;
