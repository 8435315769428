import { Button, Input } from '../../../components';
import {
    Card,
    CardBody,
    Col,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import React, { useEffect, useState } from 'react';

import { InstitutionPeopleDto, ProgramProbation, ProgramProbationActivity } from '../../../common/Types';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import { programService } from '../../../services/ProgramService';
import { toast } from 'react-toastify';
import { AmsPermissionClaimType, authService } from '../../../services/Auth';
import { InstitutionPersonRole, InvoiceType, InvoiceTypes, ProbationReasons } from '../../../common/Data';
import { personnelService } from '../../../services/Personnel';
import { register } from '../../../serviceWorker';

interface ProbationProps {
    programId: number;
    institutionId: number;
    isOnProbation: boolean;
    onProbationStatusChanged: () => void;
    programTitle: string;
}

const Probation = (props: ProbationProps) => {
    const { programTitle, programId, isOnProbation, onProbationStatusChanged, institutionId } = props;

    const [probationState, setProbationState] = useState<boolean>(isOnProbation);

    const [probations, setProbations] = useState<ProgramProbation[]>();
    const [logs, setLogs] = useState<ProgramProbationActivity[]>() || [];
    const [reloadLogs, setReloadLogs] = useState<boolean>(true);
    const [reloadProbations, setReloadProbations] = useState<boolean>(true);

    const [editProbation, setEditProbation] = useState<ProgramProbation>();
    const [probationReason, setProbationReason] = useState<string>();
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const toggleEditModal = () => setShowEditModal(!showEditModal);

    const [endingProbation, setEndingProbation] = useState<ProgramProbation>();
    const [showEndModal, setShowEndModal] = useState<boolean>(false);
    const toggleEndModal = () => setShowEndModal(!showEndModal);

    const [personnel, setPersonnel] = useState<InstitutionPeopleDto[]>();
    const [newAdditionalRecipient, setNewAdditionalRecipient] = useState<string>();

    const loggedInUser = authService.getProfile();

    //     const defaultBody = `${programTitle} has been placed on Administrative Probation${
    //         probationReason ? ` due to ${probationReason}` : ''
    //     }.
    //
    // If you believe this is an error in our records please contact please contact ${loggedInUser?.name} at ${
    //         loggedInUser?.email
    //     } immediately.
    //
    // Thank you.`;

    const defaultBody = `Our records indicate that payment for the ${programTitle} profession has not been received. Pursuant to policy 701.F section 3 failure to pay the Accredited Program Fee by 5pm EST on March 1st ${programTitle} will be placed on administrative probation and may result in a recommendation to the CAAHEP Board of Directors that accreditation be involuntarily withdrawn for all programs with unpaid Program Accreditation Fees. Such involuntary withdrawals are not appealable. `;

    useEffect(() => {
        const loadProbationHistory = () => {
            if (programId && reloadProbations) {
                setReloadProbations(false);

                programService
                    .getProbationHistory(programId)
                    .then((results) => {
                        setProbations(
                            results.sort(
                                (a, b) =>
                                    moment.utc(b.probationStartDate).unix() - moment.utc(a.probationStartDate).unix(),
                            ),
                        );
                    })
                    .catch(() => {
                        toast.error('Error loading probation history');
                    });
            }
        };
        loadProbationHistory();
    }, [setProbations, programId, reloadProbations]);

    useEffect(() => {
        const loadLogs = () => {
            if (programId && reloadLogs) {
                setReloadLogs(false);

                programService
                    .getProbationActivity(programId)
                    .then((results) => {
                        setLogs(results);
                    })
                    .catch(() => {
                        toast.error('Error loading history');
                    });
            }
        };

        loadLogs();
    }, [setLogs, programId, reloadLogs]);

    useEffect(() => {
        const loadPersonnel = () => {
            if (institutionId && programId) {
                personnelService
                    .getAmsPersonnelByInsitutionId(institutionId)
                    .then((results) => {
                        setPersonnel([
                            ...results.filter(
                                (p) =>
                                    (!p.programId || p.programId === programId) &&
                                    p.active &&
                                    p.people?.active === true &&
                                    (p.roleId === InstitutionPersonRole.CEO ||
                                        p.roleId === InstitutionPersonRole.Dean ||
                                        p.roleId === InstitutionPersonRole['Program Director']),
                            ),
                        ]);
                    })
                    .catch(() => {
                        toast.error('Error loading personnel');
                    });
            }
        };

        loadPersonnel();
    }, [setPersonnel, institutionId, programId]);
    const endProbation = () => {
        if (endingProbation) {
            const toastId = toast.info('Ending probation...');

            programService
                .endProgramProbation(endingProbation)
                .then((result) => {
                    toast.update(toastId, {
                        render: 'Probation ended',
                        type: 'success',
                    });
                    setProbationState(false);
                    onProbationStatusChanged();
                    setReloadProbations(true);
                    toggleEndModal();
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error ending probation',
                        type: 'error',
                    });
                });
        }
    };

    const startEdit = (probation: ProgramProbation) => {
        setEditProbation({
            ...probation,
            additionalRecipients: [],
            message: defaultBody,
            recipients: [],
            subject: 'Administrative Probation Notice',
        });
        toggleEditModal();
    };

    const startEnd = () => {
        if (probations && probations.length > 0) {
            setEndingProbation({ ...probations[0], probationEndDate: moment().toDate() });
            toggleEndModal();
        }
    };

    const startNew = () => {
        setEditProbation({
            probationStartDate: moment().toDate(),
            probationId: 0,
            probationReason: '',
            programId: programId,
            additionalRecipients: [],
            message: defaultBody,
            recipients: [],
            subject: 'Administrative Probation Notice',
        });
        toggleEditModal();
    };

    const addRecipient = (message: ProgramProbation, recipient: string) => {
        if (recipient && message) {
            if (!message?.additionalRecipients?.find((r) => r === recipient)) {
                message?.additionalRecipients?.push(recipient);
                setNewAdditionalRecipient('');
            }
        }
    };

    const removeRecipient = (message: ProgramProbation, setter: any, recipient: string) => {
        if (recipient && message) {
            const index = message.additionalRecipients?.indexOf(recipient, 0);
            if (index >= 0) {
                const newRecipients = [...message.additionalRecipients];
                newRecipients.splice(index, 1);

                setter({
                    ...message,
                    additionalRecipients: [...newRecipients],
                });
            }
        }
    };

    const getPersonByRoleId = (roleId: number): InstitutionPeopleDto | null => {
        if (personnel) {
            const person = personnel.find((p) => p.roleId === roleId);

            if (person?.people) {
                return person;
            }
        }

        return null;
    };

    const getPersonTextByRoleId = (roleId: number): string | null => {
        const person = getPersonByRoleId(roleId)?.people;

        if (person) {
            return `${person.firstName} ${person.lastName} (${person.email || 'Email Not Provided'})`;
        }

        return null;
    };

    const isPersonChecked = (message: ProgramProbation, roleId: number): boolean => {
        const person = getPersonByRoleId(roleId)?.people;

        if (person?.email && message?.recipients) {
            return message.recipients.find((r) => r === person.email) !== undefined;
        }

        return false;
    };

    const toggleSelectPerson = (message: ProgramProbation, setter: any, roleId: number) => {
        const person = getPersonByRoleId(roleId)?.people;

        if (person?.email && message?.recipients) {
            const index = message.recipients.indexOf(person.email);

            if (index < 0) {
                const newRecipients = [...message.recipients, person.email];
                setter({
                    ...message,
                    recipients: [...newRecipients],
                });
            } else {
                const newRecipients = [...message.recipients];
                newRecipients.splice(index, 1);
                setter({
                    ...message,
                    recipients: [...newRecipients],
                });
            }
        }
    };

    const submit = () => {
        if (editProbation) {
            if (editProbation.probationId === 0) {
                const toastId = toast.info('Starting probation...');
                programService
                    .startProgramProbation(editProbation)
                    .then((result) => {
                        toast.update(toastId, {
                            render: 'Probation status updated successfully',
                            type: 'success',
                        });
                        setReloadProbations(true);
                        setProbationState(true);
                        onProbationStatusChanged();
                        toggleEditModal();
                    })
                    .catch(() => {
                        toast.update(toastId, {
                            render: 'Error updating probation status',
                            type: 'error',
                        });
                    });
            } else {
                const toastId = toast.info('Updating probation...');
                programService
                    .updateProgramProbation(editProbation)
                    .then((result) => {
                        toast.update(toastId, {
                            render: 'Probation status updated successfully',
                            type: 'success',
                        });
                        setReloadProbations(true);
                        toggleEditModal();
                    })
                    .catch(() => {
                        toast.update(toastId, {
                            render: 'Error updating probation status',
                            type: 'error',
                        });
                    });
            }
        }
    };

    return (
        <>
            <Card className={'flex-grow-1 d-flex'}>
                <CardBody>
                    <h4 className={'card-title'}>{`Administrative Probation`}</h4>
                    <Row>
                        <Col xs={12} md={5}>
                            <Card>
                                <CardBody>
                                    <h5 className={'card-title d-flex align-items-center justify-content-between'}>
                                        <span>{`Probation History`}</span>
                                        {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') && (
                                            <div>
                                                {!probationState && (
                                                    <Button
                                                        type={'button'}
                                                        color={'warning'}
                                                        onClick={() => startNew()}
                                                    >
                                                        <i className={`mdi mdi-plus`} />
                                                        <span className={`ml-1`}>{'Start Probation'}</span>
                                                    </Button>
                                                )}
                                                {probationState && (
                                                    <Button type={'button'} color={'danger'} onClick={() => startEnd()}>
                                                        <i className={`mdi mdi-plus`} />
                                                        <span className={`ml-1`}>{'End Probation'}</span>
                                                    </Button>
                                                )}
                                            </div>
                                        )}
                                    </h5>
                                    <div className={'table-responsive'}>
                                        <table className={'table'}>
                                            <thead>
                                                <tr>
                                                    <th
                                                        style={{ whiteSpace: 'nowrap', width: '1%' }}
                                                    >{`Start Date`}</th>
                                                    <th style={{ whiteSpace: 'nowrap', width: '1%' }}>{`End Date`}</th>
                                                    <th>{`Reason`}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {probations &&
                                                    probations.length > 0 &&
                                                    probations.map((p) => (
                                                        <tr key={p.probationId}>
                                                            <td>{moment(p.probationStartDate).format('MM/DD/YYYY')}</td>
                                                            <td>
                                                                {p.probationEndDate &&
                                                                    moment(p.probationEndDate).format('MM/DD/YYYY')}
                                                            </td>
                                                            <td>{p.probationReason}</td>
                                                            <td className={'text-right'}>
                                                                {authService.hasPermission(
                                                                    AmsPermissionClaimType,
                                                                    'systemadmin',
                                                                ) && (
                                                                    <Button
                                                                        type={'button'}
                                                                        className={'btn-icon'}
                                                                        onClick={() => startEdit(p)}
                                                                        color={'info'}
                                                                    >
                                                                        <i className={'mdi mdi-pencil'} />
                                                                    </Button>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                {probations && probations.length === 0 && (
                                                    <tr>
                                                        <td colSpan={4}>{`No Probation History`}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12} md={7}>
                            <Card>
                                <CardBody>
                                    <h5 className={'card-title'}>{`Probation Activity`}</h5>
                                    {logs && (
                                        <div className={'table-responsive'} style={{ maxHeight: '532px' }}>
                                            <table className={'table table-striped'}>
                                                <thead>
                                                    <tr>
                                                        <th>Description</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {logs.length === 0 && (
                                                        <tr>
                                                            <td colSpan={2}>{'No history to display'}</td>
                                                        </tr>
                                                    )}
                                                    {logs.length > 0 &&
                                                        logs.map((l) => (
                                                            <tr key={l.id}>
                                                                <td>{l.message}</td>
                                                                <td>
                                                                    {moment
                                                                        .utc(l.timeStamp)
                                                                        .local()
                                                                        .format('MM/DD/YYYY hh:mm A')}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                    {!logs && <Skeleton count={10} />}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            {editProbation && (
                <Modal isOpen={showEditModal} toggle={toggleEditModal} centered={true} className={`modal-lg`}>
                    <ModalHeader>{`${editProbation.probationId > 0 ? 'Edit' : 'Start'} Probation`}</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label>{`Probation Start Date`}</Label>
                            <Input
                                type={'date'}
                                value={moment(editProbation.probationStartDate).format('YYYY-MM-DD')}
                                onChange={(e) =>
                                    setEditProbation({
                                        ...editProbation,
                                        probationStartDate: moment(e.target.value).toDate(),
                                    })
                                }
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>{`Probation Reason`}</Label>
                            {editProbation.probationId == 0 && (
                                <Input
                                    type={'select'}
                                    innerRef={register()}
                                    defaultValue={editProbation.probationReason}
                                    onChange={(e) => {
                                        setEditProbation({ ...editProbation, probationReason: e.target.value });
                                        setProbationReason(e.target.value);
                                    }}
                                >
                                    <option value="">Select Reason</option>
                                    {ProbationReasons.map((ct) => (
                                        <option key={ct} value={ProbationReasons[ct]}>
                                            {ct}
                                        </option>
                                    ))}
                                </Input>
                            )}
                            <Input
                                style={{ marginTop: '8px' }}
                                type={
                                    editProbation.probationId > 0 || editProbation.probationReason == 'Other'
                                        ? 'text'
                                        : 'hidden'
                                }
                                value={editProbation.probationReason}
                                onChange={(e) => {
                                    setEditProbation({ ...editProbation, probationReason: e.target.value });
                                    setProbationReason(e.target.value);
                                }}
                            />
                        </FormGroup>
                        <FormGroup className={'d-flex flex-column'}>
                            <Label>{`Probation Email Recipients`}</Label>
                            <div className={'d-flex flex-column'}>
                                <div className={'custom-control custom-checkbox d-flex align-items-center my-1'}>
                                    <input
                                        type="checkbox"
                                        id={'ceo'}
                                        className={'custom-control-input'}
                                        onChange={() =>
                                            toggleSelectPerson(
                                                editProbation,
                                                setEditProbation,
                                                InstitutionPersonRole.CEO,
                                            )
                                        }
                                        checked={isPersonChecked(editProbation, InstitutionPersonRole.CEO)}
                                        disabled={!getPersonByRoleId(InstitutionPersonRole.CEO)?.people?.email}
                                    />
                                    <Label for={'ceo'} className={'custom-control-label'}>
                                        CEO{' '}
                                        <span className={'font-weight-normal'}>{`${
                                            getPersonTextByRoleId(InstitutionPersonRole.CEO) || ''
                                        }`}</span>
                                    </Label>
                                </div>
                                <div className={'custom-control custom-checkbox d-flex align-items-center my-1'}>
                                    <input
                                        type="checkbox"
                                        id={'dean'}
                                        className={'custom-control-input'}
                                        onChange={() =>
                                            toggleSelectPerson(
                                                editProbation,
                                                setEditProbation,
                                                InstitutionPersonRole.Dean,
                                            )
                                        }
                                        checked={isPersonChecked(editProbation, InstitutionPersonRole.Dean)}
                                        disabled={!getPersonByRoleId(InstitutionPersonRole.Dean)?.people?.email}
                                    />
                                    <Label for={'dean'} className={'custom-control-label'}>
                                        Dean{' '}
                                        <span className={'font-weight-normal'}>{`${
                                            getPersonTextByRoleId(InstitutionPersonRole.Dean) || ''
                                        }`}</span>
                                    </Label>
                                </div>
                                <div className={'custom-control custom-checkbox d-flex align-items-center my-1'}>
                                    <input
                                        type="checkbox"
                                        id={'pd'}
                                        className={'custom-control-input'}
                                        onChange={() =>
                                            toggleSelectPerson(
                                                editProbation,
                                                setEditProbation,
                                                InstitutionPersonRole['Program Director'],
                                            )
                                        }
                                        checked={isPersonChecked(
                                            editProbation,
                                            InstitutionPersonRole['Program Director'],
                                        )}
                                        disabled={
                                            !getPersonByRoleId(InstitutionPersonRole['Program Director'])?.people?.email
                                        }
                                    />
                                    <Label for={'pd'} className={'custom-control-label'}>
                                        PD{' '}
                                        <span className={'font-weight-normal'}>{`${getPersonTextByRoleId(
                                            InstitutionPersonRole['Program Director'],
                                        )}`}</span>
                                    </Label>
                                </div>
                            </div>
                        </FormGroup>
                        <FormGroup className={'d-flex flex-column'}>
                            <Label>{`Additional Recipients`}</Label>
                            {editProbation?.additionalRecipients?.map((r) => (
                                <div key={r} className={'d-flex align-items-center my-1 ml-2'}>
                                    <span key={r} className={'mr-1'}>
                                        {r}
                                    </span>
                                    <Button
                                        type={'button'}
                                        color={'white'}
                                        className={'btn-icon btn-sm'}
                                        onClick={() => removeRecipient(editProbation, setEditProbation, r)}
                                    >
                                        <i className={'mdi mdi-delete'} />
                                    </Button>
                                </div>
                            ))}
                            <InputGroup className={'mt-2'}>
                                <Input
                                    type={'email'}
                                    value={newAdditionalRecipient}
                                    onChange={(e) => setNewAdditionalRecipient(e.target.value)}
                                />
                                <InputGroupAddon addonType={'append'}>
                                    <Button
                                        color={'info'}
                                        onClick={() => addRecipient(editProbation, newAdditionalRecipient || '')}
                                    >
                                        {`Add Recipient`}
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className={'d-flex flex-column'}>
                            <Label>{`Subject`}</Label>
                            <Input
                                type={'text'}
                                value={editProbation.subject}
                                onChange={(e) => setEditProbation({ ...editProbation, subject: e.target.value })}
                            />
                        </FormGroup>
                        <FormGroup className={'d-flex flex-column'}>
                            <Label>{`Message`}</Label>
                            <Input
                                type={'textarea'}
                                rows={10}
                                value={editProbation.message}
                                onChange={(e) => setEditProbation({ ...editProbation, message: e.target.value })}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color={'primary'}
                            className={'mr-2'}
                            disabled={
                                editProbation.probationReason === undefined ||
                                editProbation.probationReason === null ||
                                editProbation.probationReason === ''
                            }
                            onClick={() => submit()}
                            type={'button'}
                        >{`Confirm`}</Button>
                        <Button color={'link'} onClick={() => toggleEditModal()} type={'button'}>{`Cancel`}</Button>
                    </ModalFooter>
                </Modal>
            )}
            {endingProbation && endingProbation.probationEndDate && (
                <Modal isOpen={showEndModal} toggle={toggleEndModal} centered={true}>
                    <ModalHeader>{`End Probation`}</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label>{`Probation End Date`}</Label>
                            <Input
                                type={'date'}
                                value={moment(endingProbation.probationEndDate).format('YYYY-MM-DD')}
                                onChange={(e) =>
                                    setEndingProbation({
                                        ...endingProbation,
                                        probationEndDate: moment(e.target.value).toDate(),
                                    })
                                }
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color={'primary'}
                            className={'mr-2'}
                            disabled={!endingProbation.probationEndDate}
                            onClick={() => endProbation()}
                            type={'button'}
                        >{`Confirm`}</Button>
                        <Button color={'link'} onClick={() => toggleEditModal()} type={'button'}>{`Cancel`}</Button>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
};

export default Probation;
