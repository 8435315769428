import React, { useState } from 'react';
import { Header, LeftNav } from '../components';
import { ToastContainer } from 'react-toastify';

type Props = {
    children: React.ReactNode;
};

/**
 * Contianer showing left nav and authenticated header
 * @param {Props} props
 */
const AuthenticatedContainer = (props: Props) => {
    const [menuActive, setMenuActive] = useState(true);

    const toggleMenu = () => {
        document.body.classList.toggle('sidebar-enable');
        setMenuActive(!menuActive);
    };

    return (
        <div id="wrapper">
            <Header menuToggle={toggleMenu}></Header>
            <ToastContainer hideProgressBar className="toast-container" />
            <LeftNav></LeftNav>
            <div className="content-page">
                <div className="content">
                    <div className="container-fluid">{props.children}</div>
                </div>
            </div>
        </div>
    );
};

export default AuthenticatedContainer;
