import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Redirect, useLocation } from 'react-router-dom';
import { routes } from './router/Routes';
import PrivateRoute from './router/PrivateRoute';
import PublicRoute from './router/PublicRoute';
import { ErrorBoundary } from './components';

// Theme
import './assets/scss/DefaultTheme.scss';

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function App() {
    return (
        <ErrorBoundary>
            <BrowserRouter>
                <ScrollToTop />
                <Switch>
                    {routes.map((route, i) =>
                        route.private ? <PrivateRoute key={i} {...route} /> : <PublicRoute key={i} {...route} />,
                    )}
                    <Redirect from="/*" to="/login" />
                </Switch>
            </BrowserRouter>
        </ErrorBoundary>
    );
}

export default App;
