import { AmsPermissionClaimType, authService } from '../../services/Auth';
import { Badge, Card, CardBody, Pagination, PaginationItem, PaginationLink, Table } from 'reactstrap';
import { InvoicingDto, PagingDto } from '../../types';
import React, { useEffect, useState } from 'react';

import { InvoiceStatus } from '../../common/Data';
import { Link } from 'react-router-dom';
import { invoiceService } from '../../services/InvoiceService';
import { toast } from 'react-toastify';

interface InstitutionInvoicesProps {
    institutionId: number;
}

const InstitutionInvoices = (props: InstitutionInvoicesProps) => {
    const [invoices, setInvoices] = useState<InvoicingDto[]>();
    const [paging, setPaging] = useState<PagingDto>({
        pageNumber: 1,
        maxPageSize: null,
        pageSize: 10,
    });

    useEffect(() => {
        const getData = () => {
            invoiceService
                .getInvoices({
                    ...{
                        institutionId: props.institutionId,
                        invoiceNumber: null,
                        invoiceType: null,
                        paymentType: null,
                        year: null,
                        status: null,
                    },
                    paging: {
                        pageNumber: 1,
                        maxPageSize: null,
                        pageSize: 10,
                    },
                })
                .then((response) => {
                    setInvoices(response.invoices);
                })
                .catch(() => toast.error('Failed to get invoices'));
        };

        getData();
    }, [setInvoices, paging, props.institutionId]);

    return (
        <Card>
            <CardBody className={'pb-0'}>
                <h4 className={'card-title'}>{`Institution Invoices`}</h4>
            </CardBody>
            <Table>
                <thead>
                    <tr>
                        <th>Invoice Number</th>
                        <th>Institution</th>
                        <th>Invoice Date</th>
                        <th>Status</th>
                        <th>Amount</th>
                        <th>Administrative Fee</th>
                        <th>Due Amount</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {invoices &&
                        invoices.map((vInvoice) => (
                            <tr key={vInvoice.id}>
                                <td>{vInvoice.invoiceNumber}</td>
                                <td>{vInvoice.institutionTitle}</td>
                                <td>{new Date(vInvoice.invoiceDate || new Date()).toDateString()}</td>
                                <td>
                                    <Badge color={vInvoice.status === 1 ? 'success' : 'warning'}>
                                        {InvoiceStatus[vInvoice.status]}
                                    </Badge>
                                </td>
                                <td>${vInvoice.amount}</td>
                                <td>${vInvoice.lateFee ?? 0}</td>
                                <td>${vInvoice.dueAmount}</td>
                                <td>
                                    {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') && (
                                        <Link to={`/system-admin/invoice/${vInvoice.id}`}>View</Link>
                                    )}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
            <CardBody>
                <Pagination size="sm" aria-label="Invoice Navigation">
                    <PaginationItem disabled={paging.pageNumber === 1}>
                        <PaginationLink
                            previous
                            onClick={() => setPaging({ ...paging, pageNumber: paging.pageNumber - 1 })}
                        />
                    </PaginationItem>
                    <PaginationItem>
                        <PaginationLink
                            next
                            onClick={() => setPaging({ ...paging, pageNumber: paging.pageNumber + 1 })}
                        />
                    </PaginationItem>
                </Pagination>
            </CardBody>
        </Card>
    );
};

export default InstitutionInvoices;
